import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import './billing-summary.scss';
import { BillingModel } from './billing.model';


export interface CheckoutStateProps {
  useGravityAs?: number;
  clientToken?: string;
  billing?: BillingModel;
  isCheckoutLoading: boolean;
  paypalClientId?: string;
}

export interface CheckoutDispatchProps {
  checkout(billing: BillingModel, useGravityAs?: number): any;
  createOrder(amount: number): Promise<string>;
  startCheckout(): void;
  getPaypalClientId(): Promise<void>;
}


export const CheckoutComponent: React.FC<CheckoutStateProps & CheckoutDispatchProps & RouteComponentProps> = (
  { checkout, paypalClientId, createOrder, isCheckoutLoading, useGravityAs, billing, clientToken, history }
) => {
  if (!clientToken || !billing || !paypalClientId) {
    history.push('/');
    return null;
  }

  if (isCheckoutLoading) {
    return <FullPageLoadingComponent />
  }

  console.log(billing);
  const initialOptions = {
    "client-id": paypalClientId,
    "data-client-token": clientToken,
    currency: "EUR",
    intent: "capture"
  };

  return (
    <div className="container">
      <div className="stack stack-m">
        <div className="grid">
          <div className="gi-12">
            <div className="stack stack-xs jc-sb m-fd-cr">
              <p className="title title-l checkout-container">Checkout</p>
              <p className="checkout-container">Please select a payment method</p>
              <div className="checkout-container">
                <PayPalScriptProvider options={initialOptions}>
                  <PayPalButtons
                    createOrder={(data, actions) => {
                      return createOrder(billing.totalAmount!);
                    }}
                    onApprove={(data, actions) => {
                      return actions.order!.capture()
                      .then((details) => {
                        checkout(billing, useGravityAs);
                      })
                      .catch((err) => {
                        console.error(err);
                      })
                    }}
                  >
                  </PayPalButtons>
                </PayPalScriptProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};