import React from 'react';
import { AttributeType, SavedCompanyAttachmentModel, SavedCompanyModel } from '../saved-company.models';
import CompanyPropertyRevealBadge from './CompanyPropertyRevealBadge.contrainer';
import SourceIcon from '../SourceIcon.container';

export interface DocumentsAndAttachmentsTabProps {
  addPropertyToCart: (attachmentId: number, attachmentName: string, creditPrice: number) => void;
  savedCompany: SavedCompanyModel;
}

export const DocumentsAndAttachmentsTab: React.FC<DocumentsAndAttachmentsTabProps> = ({ savedCompany, addPropertyToCart }) => {
  const brandColorStyle = { '--background-color': 'var(--brand-color)' } as any as React.CSSProperties;

  let numberOfAvailableProperies = 0;
  let totalPriceOfProperties = 0;

  savedCompany.attachments.managementStructure
    .forEach((attachment) => {
      const isAvailableToBuy = !attachment.publicUrl;

      if (isAvailableToBuy) {
        numberOfAvailableProperies += 1;
        totalPriceOfProperties += attachment.creditPrice;
      }
    });
  savedCompany.attachments.otherDocument
    .forEach((attachment) => {
      const isAvailableToBuy = !attachment.publicUrl;

      if (isAvailableToBuy) {
        numberOfAvailableProperies += 1;
        totalPriceOfProperties += attachment.creditPrice;
      }
    });
  savedCompany.attachments.standardImages
    .forEach((attachment) => {
      const isAvailableToBuy = !attachment.publicUrl;

      if (isAvailableToBuy) {
        numberOfAvailableProperies += 1;
        totalPriceOfProperties += attachment.creditPrice;
      }
    });

  const revealAll = () => {
    savedCompany.attachments.managementStructure.forEach(attachment => {
      if (!attachment.publicUrl) {
        addPropertyToCart(attachment.attachmentId, attachment.attachmentName, attachment.creditPrice);
      }
    });
    savedCompany.attachments.otherDocument.forEach(attachment => {
      if (!attachment.publicUrl) {
        addPropertyToCart(attachment.attachmentId, attachment.attachmentName, attachment.creditPrice);
      }
    });
    savedCompany.attachments.standardImages.forEach(attachment => {
      if (!attachment.publicUrl) {
        addPropertyToCart(attachment.attachmentId, attachment.attachmentName, attachment.creditPrice);
      }
    });
  };

  const renderAttachment = (label: string, attachment: SavedCompanyAttachmentModel) => {
    return (
      <li key={attachment.attachmentId} className="attachment-list">
        <div className="attribute_source">
          <SourceIcon
            savedCompanyId={savedCompany.id}
            attribute={{
              attributeId: attachment.attachmentId,
              creditPrice: attachment.creditPrice,
              hasUpdate: false,
              required: false,
              disabled: false,
              value: attachment.publicUrl
            }}
            attributeName={attachment.attachmentName}
          />
        </div>
        <div className="item item--doc">
          <div className="h-stack h-stack-xs ai-c m-fd-c">
            <div className="col col--name">
              <p className="fw-b">{attachment.attachmentName}</p>
            </div>
            <div className="col col--tags">
              <div className="h-stack h-stack-xs">
                <span className="icon-chip icon-chip--label-left" style={brandColorStyle}>
                  <span className="icon-chip__inner">
                    <span className="label">{label}</span>
                  </span>
                </span>
              </div>
            </div>
            {
              attachment.publicUrl ?
                <div className="col col--icon" >
                  <a className="download" href={attachment.publicUrl}>
                    Download
                          <svg className="icon icon--m ">
                      <use xlinkHref="#icon-export" />
                    </svg>
                  </a>
                </div>
                :
                <CompanyPropertyRevealBadge
                  label={label}
                  attributeType={AttributeType.Attachment}
                  attributeId={attachment.attachmentId}
                  price={attachment.creditPrice} />
            }
          </div>
        </div>
      </li>
    )
  }

  const attachmentsList =
    <ul className="list">
      {
        savedCompany.attachments.standardImages.map((attachment: SavedCompanyAttachmentModel) => (
          renderAttachment("Standard image", attachment)
        ))
      }
      {
        savedCompany.attachments.managementStructure.map((attachment: SavedCompanyAttachmentModel) => (
          renderAttachment("Management Structure", attachment)
        ))
      }
      {
        savedCompany.attachments.otherDocument.map((attachment: SavedCompanyAttachmentModel) => (
          renderAttachment("Other document", attachment)
        ))
      }
    </ul>;

  return (
    <>
      <div className="info-count">
        {numberOfAvailableProperies > 0 ?
          <div className="h-stack h-stack-xs ai-c m-fd-c">
            <p className="annotation">{numberOfAvailableProperies} {numberOfAvailableProperies === 1 ? "piece" : "pieces"} of information available on this page</p>
            <button className="icon-chip icon-chip--label-left" style={brandColorStyle}
              onClick={() => revealAll()}
            >
              <span className="icon-chip__inner">
                <span className="label">Reveal all for <svg className="icon icon--m "><use xlinkHref="#icon-gravity-credit" /></svg> {totalPriceOfProperties}</span>
              </span>
            </button>
          </div> : ""}
      </div>
      <div className="source-info">
          <p className="annotation">Move your cursor on <img className="source" src={`/assets/img/source-up.png`} alt="Source" /> to show the source of the last upload</p>
      </div>
      <div className="source-info">
          <p className="annotation">View previously entered values on revealed fields by clicking on <img className="source" src={`/assets/img/source-down.png`} alt="Source" /></p>
      </div>
      {attachmentsList}
    </>
  );
};
