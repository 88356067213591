import { AppState } from '../root.reducer';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { authenticationErrorAction, authenticationLoadingAction, forgotPasswordSuccessAction, resetPasswordLoadedAction } from './authentication.actions';
import { authenticationService } from './authentication.service';
import { authenticationErrorSelector, isAuthenticationLoadingSelector, resetPasswordEmailSelector } from './authentication.selectors';
import { RouteComponentProps } from 'react-router-dom';
import { ResetPasswordComponentDispatchProps, ResetPasswordComponentStateProps, ResetPasswordPage } from './ResetPassword.component';
import { ResetPasswordModel } from './authentication.models';

const mapStateToProps = (state: AppState): ResetPasswordComponentStateProps => ({
  error: authenticationErrorSelector(state),
  isLoading: isAuthenticationLoadingSelector(state),
  email: resetPasswordEmailSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, {history}: RouteComponentProps): ResetPasswordComponentDispatchProps => ({
  resetPassword: async (model: ResetPasswordModel) => {
    dispatch(authenticationLoadingAction());

    try {
      const response = await authenticationService.resetPassword(model);

      if (response.status === 200) {
        dispatch(forgotPasswordSuccessAction());
        history.push('/resetpassword-confirm');
      }
    } catch {
      dispatch(authenticationErrorAction('We couldn’t find that email. Please try again.'));
    }
  },

  loadResetPassword: async(code: string) => {
    dispatch(authenticationLoadingAction());

    try {
      const response = await authenticationService.loadResetPassword(code);

      if (response.status === 200) {
        dispatch(resetPasswordLoadedAction(response.data));
      }else {
        dispatch(authenticationErrorAction('We couldn’t find that email. Please try again.'));
      }
    }
    catch {
      dispatch(authenticationErrorAction('We couldn’t find that email. Please try again.'));
    }
  }
});

export default connect<{}, ResetPasswordComponentDispatchProps, RouteComponentProps, AppState>(
  mapStateToProps, mapDispatchToProps
)(ResetPasswordPage);
