import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../root.reducer';
import { CartStateProps, CartDispatchProps, CartComponent } from './Cart.component';
import { cartSelector, cartSubmissionStateSelector } from './cart.selectors';
import { removeItemFromCartAction, submitCart, submitCartFail, resetCart } from './cart.actions';
import { CartItem, Cart } from './cart.models';
import { cartService } from './cart.service';
import { gravityUseAsSelector, userSelector } from '../auth/authentication.selectors';
import { savedCompanyLoadedAction, savedCompanyLoadingAction } from '../saved-company/saved-company.actions';
import { companyService } from '../saved-company/company.service';
import { authenticationService } from '../auth/authentication.service';
import { authenticationSuccessAction } from '../auth/authentication.actions';
import { workspaceSelector } from '../workspace-settings/workspace.selector';
import { workspaceService } from '../workspace-settings/workspace.service';
import { workspaceLoadedSuccessfulAction } from '../workspace-settings/workspace.actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { popupSetModelAction } from '../popup/popup.actions';
import { PopupDialogType } from '../popup/popup.models';
import { popupService } from '../popup/popup.service';
import { discountSelector, savedCompanySelector } from '../saved-company/company.selectors';

const mapStateToProps = (state: AppState): CartStateProps => ({
    cart: cartSelector(state),
    submissionState: cartSubmissionStateSelector(state),
    user: userSelector(state),
    workspace: workspaceSelector(state),
    useGravityAs: gravityUseAsSelector(state),
    discount: discountSelector(state),
    savedCompany: savedCompanySelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): CartDispatchProps => ({
    removeItemFromCart: (item: CartItem) => {
        dispatch(removeItemFromCartAction(item));

        const cartStorage = localStorage.getItem('savedCompanyCart');
        const cart = JSON.parse(cartStorage || '{items: []}');
        const updatedCart = {
            ...cart,
            items: cart.items.filter((i: any) => i.attributeId !== item.attributeId),
        };
        localStorage.setItem('savedCompanyCart', JSON.stringify(updatedCart));
    },
    submitCart: async (cart: Cart, savedCompanyId: number, hasEnoughCredit: boolean, useGravityAs?: number, price?: number) => {
        try {
            if (!hasEnoughCredit) {
                dispatch(popupSetModelAction({
                    title: '',
                    text: `Your credit balance is insufficient for this purchase.`,
                    type: PopupDialogType.Dialog,
                    okButtonText: 'Purchase credit',
                    noButtonText: 'Cancel',
                    hideCloseIcon: true,
                    cancelButtonAction: (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        popupService.hidePopup();
                    },
                    okButtonAction: async (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        popupService.hidePopup();
                        const url = useGravityAs ? 'workspace/' + useGravityAs : 'account';
                        history.push('/' + url);
                    }
                }));
                popupService.showPopup();
            } else {
                dispatch(submitCart());
                dispatch(savedCompanyLoadingAction());

                await cartService.submitCart(cart, savedCompanyId, useGravityAs, price);

                dispatch(resetCart());
                localStorage.setItem('savedCompanyCart', JSON.stringify({}));
                if (useGravityAs) {
                    const workspace = await workspaceService.getWorkspaceSettings(useGravityAs);
                    dispatch(workspaceLoadedSuccessfulAction(workspace));

                    const response = await companyService.getSavedWorkspaceCompany(savedCompanyId);
                    if (response.status !== 200) {
                        history.push('/');
                        return;
                    }
                    dispatch(savedCompanyLoadedAction(response.data));

                } else {
                    const user = await authenticationService.getUser();
                    dispatch(authenticationSuccessAction(user));
                    const profile = await companyService.getSavedUserCompany(savedCompanyId);
                    dispatch(savedCompanyLoadedAction(profile.data));
                }
            }
        } catch {
            dispatch(submitCartFail());
        }
    },
    resetCart: () => {
        dispatch(resetCart());
    }
});

export default withRouter(
    connect<CartStateProps, CartDispatchProps, RouteComponentProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(CartComponent)
);
