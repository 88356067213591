import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Dispatch } from 'redux'
import { accountLoadingAction, accountLoadingFinishedAction } from '../account-settings/account.actions'
import { isAccountLoadingSelector } from '../account-settings/account.selector'
import { accountService } from '../account-settings/account.service'
import { AppState } from '../root.reducer'
import { InvitationDispatchProps, InvitationPage, InvitationStateProps } from './Invitation.component'

const mapStateToProps = (state: AppState): InvitationStateProps => ({
  isLoading: isAccountLoadingSelector(state),
})

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): InvitationDispatchProps => ({
  invite: async (email: string) => {
    dispatch(accountLoadingAction());

    const response = await accountService.invite(email);
    
    dispatch(accountLoadingFinishedAction());

    return response;
  }
});

export default withRouter(
  connect<InvitationStateProps, InvitationDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps, mapDispatchToProps
  )(InvitationPage)
);
