import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { MainDispatchProps, Main } from './Main.component';
import { AppState } from './root.reducer';
import { loadCountriesAction, loadCountriesSuccessAction, loadCountriesErrorAction, loadCompanyCountSuccessAction } from './meta/meta.actions';
import { metaService } from './meta/meta.service';
import { searchService } from './search/search.service';

const mapDispatchToProps = (dispatch: Dispatch): MainDispatchProps => ({
  loadCountries: async () => {
    dispatch(loadCountriesAction());

    try {
      const countries = await metaService.getCountries();
      const companyCount = await searchService.getAllCompanyCount();

      dispatch(loadCountriesSuccessAction(countries));
      dispatch(loadCompanyCountSuccessAction(companyCount));
    } catch {
      dispatch(loadCountriesErrorAction());
    }
  }
});

export default connect<{}, MainDispatchProps, {}, AppState>(
  null, mapDispatchToProps
)(Main);
