import React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';


const ForgotPassswordResponseComp: React.FC<RouteComponentProps> = ({
  history
}) => {
  return (
    <div className="section section--content">
      <div className="container">
        <div className="grid">
          <div className="gi-4 gi-s-5 gi-e-9 t-gi-4 t-gi-s-3 t-gi-e-7 m-gi-4 m-gi-s-1 m-gi-e-4">
            <div className="stack stack-m">
              <div className="user-form">
                <div>
                  <h1 className="stack stack-xs ai-c">
                    <span className="logo logo--full logo--grey logo--color">
                      <svg className="logo__logo">
                        <use xlinkHref="#logo-full-grey-color" />
                      </svg>
                      <span className="visually-hidden">Gravity</span>
                    </span>
                    <span className="title title-m">Forgot my Password</span>
                  </h1>
                </div>
                <fieldset>
                  <div className="stack stack-xs ">
                    <div className="marginated">
                    Check your email for instructions to reset your password.
                    </div>
                  </div>
                </fieldset>
                <div className="buttons">
                  <div className="stack stack-m">
                    <div>
                      <div className="stack stack-xs">
                        <button className="button" onClick={() => history.push('/login')}>
                          <span className="button__inner">
                            <span>Log In</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export const ForgotPassswordResponseComponent = withRouter(ForgotPassswordResponseComp);