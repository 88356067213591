import { connect } from 'react-redux';
import { AppState } from '../root.reducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { CompanyUploadStateProps, CompanyUploadComponent, CompanyUploadDispatchProps } from './CompanyUpload.component';
import { companyUploadCredits, isCompanyUploadCreditsLoadingSelector, isCompanyUploadCurrenciesLoadingSelector, isCompanyUploadedSuccessSelector, isCompanyUploadingSelector, companyUploadCurrenciesSelector, companyUploadTouchedInputsSelector, companyUploadModelSelector } from './company-upload.selectors';
import { companyUploadCreditsLoadingAction, companyUploadCreditsLoadedAction, companyUploadedSuccessAction, companyUploadingAction, companyUploadFailedAction, companyUploadCurrenciesLoadingAction, companyUploadCurrenciesLoadedAction, companyUploadTouchInputsAction, companyUploadTouchFinancialInputsAction, companyUploadModelInitializeAction, companyUploadModelUpdateAction } from './company-upload.actions';
import { creditsService } from './credits.service';
import { CreateCompanyCommand, TouchedFinancialInputsModel } from './company-upload.models';
import { companyService } from '../saved-company/company.service';
import { authenticationService } from '../auth/authentication.service';
import { authenticationSuccessAction } from '../auth/authentication.actions';
import { popupService } from '../popup/popup.service';
import { popupSetModelAction } from '../popup/popup.actions';
import { PopupDialogType } from '../popup/popup.models';
import { CompanyUploadModel } from './CompanyUpload.model';
import { gravityUseAsSelector } from '../auth/authentication.selectors';
import { workspaceService } from '../workspace-settings/workspace.service';
import { workspaceLoadedSuccessfulAction } from '../workspace-settings/workspace.actions';

const mapStateToProps = (state: AppState): CompanyUploadStateProps => ({
  companyCredits: companyUploadCredits(state),
  isCompanyCreditsLoading: isCompanyUploadCreditsLoadingSelector(state),
  companyUploadedSuccess: isCompanyUploadedSuccessSelector(state),
  isCompanyUploading: isCompanyUploadingSelector(state),
  isCurrenciesLoading: isCompanyUploadCurrenciesLoadingSelector(state),
  currencies: companyUploadCurrenciesSelector(state),
  touchedInputs: companyUploadTouchedInputsSelector(state),
  uploadModel: companyUploadModelSelector(state),
  useGravityAs: gravityUseAsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): CompanyUploadDispatchProps => ({
  initializeCompany: async () => {
    dispatch(companyUploadCreditsLoadingAction());
    dispatch(companyUploadCurrenciesLoadingAction());

    const companyCredits = await creditsService.loadCompanyCredits();
    const currencies = await companyService.getCurrencies();

    const companyModel = new CompanyUploadModel(companyCredits);
    dispatch(companyUploadTouchInputsAction({
      financials: companyModel.financials.map((_: any) => ({
        incomeStatement: {},
        balanceSheet: {}
      })) ?? [],
    }));

    dispatch(companyUploadCurrenciesLoadedAction(currencies));
    dispatch(companyUploadCreditsLoadedAction(companyCredits));
    dispatch(companyUploadModelInitializeAction(companyModel));
  },

  updateCompany: (model: CompanyUploadModel) => {
    dispatch(companyUploadModelUpdateAction(model));
  },
  
  loadCompanyCredits: async () => {
    dispatch(companyUploadCreditsLoadingAction());

    const companyCredits = await creditsService.loadCompanyCredits();

    dispatch(companyUploadCreditsLoadedAction(companyCredits));
  },

  // loadCurrencies: async () => {
  //   dispatch(companyUploadCurrenciesLoadingAction());

  //   const currencies = await companyService.getCurrencies();

  //   dispatch(companyUploadCurrenciesLoadedAction(currencies));
  // },

  setTouchedFinancials: (financials: TouchedFinancialInputsModel[]) => {
    dispatch(companyUploadTouchFinancialInputsAction(financials));
  },

  uploadCompany: async (model: CompanyUploadModel, creditReward: number, useGravityAs?: number) => {
    dispatch(companyUploadingAction());

    if (model.summary.fullname.value && model.summary.countryOfFoundation.value) {
      let exists = null;
      try {
        exists = await companyService.existsCompany({
          companyName: model.summary.fullname.value,
          countryOfFoundationId: Number(model.summary.countryOfFoundation.value),
          identificationNumber: model.summary.identificationNumber.value,
          mainAddressZip: model.summary.mainAddress.zip.value,
          mainAddressCity: model.summary.mainAddress.city.value,
          mainAddressStreet: model.summary.mainAddress.street.value,
          businessAddressZip: model.summary.businessAddress.zip.value,
          businessAddressCity: model.summary.businessAddress.city.value,
          businessAddressStreet: model.summary.businessAddress.street.value,
          website: model.summary.otherContactDetails.website.value,
          industry: model.summary.industry.value,
          sector: model.summary.sector.value != null ? Number(model.summary.sector.value) : undefined,
          foundationYear: model.summary.foundationYear.value != null ? Number(model.summary.foundationYear.value) : undefined,
        });
      } catch (e) {
        console.log(e);
        dispatch(companyUploadFailedAction());
        dispatch(popupSetModelAction({
          title: "An error occured during the upload, please try again later",
          text: (e as any)?.response?.data?.Message,
          type: PopupDialogType.Dialog,
          okButtonText: 'Ok',
          okButtonAction: (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            popupService.hidePopup();
          }
        }));
        popupService.showPopup();
        return;
      }
      if (exists.data > 0) {
        dispatch(companyUploadFailedAction());

        const isHidden = exists.data === 2;
        const text = isHidden ?
          "Upon request of the affected company, it cannot be uploaded to Gravity. Please upload another company." :
          "The company you are trying to upload already exists in Gravity.";
        const title = isHidden ?
          "Removed Company" :
          "Existing Company";

        dispatch(popupSetModelAction({
          title: title,
          text: text,
          type: PopupDialogType.Confirm,
          okButtonText: 'Ok',
          okButtonAction: (evt) => {
            evt.preventDefault();
            evt.stopPropagation();

            popupService.hidePopup();
          },
          cancelButtonAction: (evt) => {
            evt.preventDefault();
            evt.stopPropagation();

            popupService.hidePopup();
          },
        }));
        popupService.showPopup();
      } else {
        const command: CreateCompanyCommand = {
          creditForUpload: creditReward,
          summary: {
            fullName: {
              value: model.summary.fullname.value
            },
            shortName: {
              value: model.summary.shortname.value,
            },
            introduction: {
              value: model.summary.introduction.value
            },
            industry: {
              value: Number(model.summary.industry.value),
            },
            sector: {
              value: Number(model.summary.sector.value)
            },
            formOfOrganization: {
              value: model.summary.formOfOrganization.value
            },
            countryOfFoundationId: {
              value: Number(model.summary.countryOfFoundation.value)
            },
            entityRelationshipStatus:  {
              value: model.summary.entityRelationshipStatus ? Number(model.summary.entityRelationshipStatus.value) : undefined
            },
            identificationNumber: {
              value: model.summary.identificationNumber.value
            },
            foundationYear: {
              value: Number(model.summary.foundationYear.value)
            },
            numberOfEmployees: {
              value: model.summary.numberOfEmployees.value !== '' ? Number(model.summary.numberOfEmployees.value) : undefined
            },
            mainAddress: {
              countryId: {
                value: Number(model.summary.mainAddress.countryId.value)
              },
              city: {
                value: model.summary.mainAddress.city.value
              },
              zip: {
                value: model.summary.mainAddress.zip.value
              },
              houseNumber: {
                value: model.summary.mainAddress.houseNumber.value
              },
              street: {
                value: model.summary.mainAddress.street.value
              },
              floor: {
                value: model.summary.mainAddress.floor.value
              },
              door: {
                value: model.summary.mainAddress.door.value
              },
            },
            businessAddress: {
              countryId: {
                value: Number(model.summary.businessAddress.countryId.value)
              },
              city: {
                value: model.summary.businessAddress.city.value
              },
              zip: {
                value: model.summary.businessAddress.zip.value
              },
              houseNumber: {
                value: model.summary.businessAddress.houseNumber.value
              },
              street: {
                value: model.summary.businessAddress.street.value
              },
              floor: {
                value: model.summary.businessAddress.floor.value
              },
              door: {
                value: model.summary.businessAddress.door.value
              },
            },
            otherContactDetails: {
              contactEmailAddress: {
                value: model.summary.otherContactDetails.contactEmailAddress.value
              },
              mainTelephoneNumber: {
                value: model.summary.otherContactDetails.mainTelephoneNumber.value
              },
              website: {
                value: model.summary.otherContactDetails.website.value
              },
            }
          },
          productsAndServices: {
            mainServices: {
              value: model.productsAndServices.services?.value?.join(';'),
            },
            mainProducts: {
              value: model.productsAndServices.products?.value?.join(';'),
            },
            brands: {
              value: model.productsAndServices.brands?.value?.join(';'),
            }
          },
          documentAndAttachments: {
            standardImages: model.documentsAndAttachments.standardImages.value || [],
            managementStructure: model.documentsAndAttachments.managementStructure.value || [],
            otherDocuments: model.documentsAndAttachments.otherDocuments.value || [],
          },
          managementContacts: model.managementContacts.map(managementContact => ({
            individual: {
              name: {
                value: managementContact.individual.name.value,
              },
              position: {
                value: managementContact.individual.position.value,
              },
              location: {
                value: managementContact.individual.location.value,
              },
              functions: {
                value: managementContact.individual.functions.value?.join(';'),
              },
              telephoneNumber: {
                value: managementContact.individual.telephoneNumber.value,
              },
              emailAddress: {
                value: managementContact.individual.emailAddress.value,
              },
              linkedIn: {
                value: managementContact.individual.linkedIn.value,
              },
            },
            frontDeskOrDepartment: {
              location: {
                value: managementContact.frontDeskOrDepartment.location.value
              },
              telephoneNumber: {
                value: managementContact.frontDeskOrDepartment.telephoneNumber.value
              },
              emailAddress: {
                value: managementContact.frontDeskOrDepartment.emailAddress.value
              },
              department: {
                value: managementContact.frontDeskOrDepartment.department.value
              },
              departmentOther: {
                value: managementContact.frontDeskOrDepartment.departmentOther.value
              }
            }
          })),
          financialMultiplier: model.financialMultiplier,
          financialCurrency: model.financialCurrency === "" || !model.financialMultiplier ? 'EUR' : model.financialCurrency,
          financials: model.financials
            .map(financial => ({
              year: financial.year,
              incomeStatement: {
                netSales: {
                  value: financial.incomeStatement.netSales.value?.toString(),
                },
                extraordinaryIncome: {
                  value: financial.incomeStatement.extraordinaryIncome.value?.toString(),
                },
                financialIncome: {
                  value: financial.incomeStatement.financialIncome.value?.toString(),
                },
                otherIncome: {
                  value: financial.incomeStatement.otherIncome.value?.toString(),
                },
                materials: {
                  value: financial.incomeStatement.materials.value?.toString(),
                },
                personnel: {
                  value: financial.incomeStatement.personnel.value?.toString(),
                },
                utilities: {
                  value: financial.incomeStatement.utilities.value?.toString(),
                },
                overhead: {
                  value: financial.incomeStatement.overhead.value?.toString(),
                },
                financialExpenses: {
                  value: financial.incomeStatement.financialExpenses.value?.toString(),
                },
                sellingAndAdministrative: {
                  value: financial.incomeStatement.sellingAndAdministrative.value?.toString(),
                },
                depreciationAndAmortization: {
                  value: financial.incomeStatement.depreciationAndAmortization.value?.toString(),
                },
                otherExpenses: {
                  value: financial.incomeStatement.otherExpenses.value?.toString(),
                },
                totalNetRevenues: {
                  value: financial.incomeStatement.totalNetRevenues.value?.toString(),
                },
                totalExpenses: {
                  value: financial.incomeStatement.totalExpenses.value?.toString(),
                },
                netIncomeBeforeTaxes: {
                  value: financial.incomeStatement.netIncomeBeforeTaxes.value?.toString(),
                },
                corporateIncomeTaxes: {
                  value: financial.incomeStatement.corporateIncomeTaxes.value?.toString(),
                },
                netIncomeAfterTaxes: {
                  value: financial.incomeStatement.netIncomeAfterTaxes.value?.toString(),
                },
                dividensPaid: {
                  value: financial.incomeStatement.dividensPaid.value?.toString(),
                },
                retainedEarningsForTheYear: {
                  value: financial.incomeStatement.retainedEarningsForTheYear.value?.toString(),
                },
              },
              balanceSheet: {
                cashAndEquivalents: {
                  value: financial.balanceSheet.cashAndEquivalents.value?.toString(),
                },
                accountsReceivable: {
                  value: financial.balanceSheet.accountsReceivable.value?.toString(),
                },
                inventories: {
                  value: financial.balanceSheet.inventories.value?.toString(),
                },
                otherCurrentAssets: {
                  value: financial.balanceSheet.otherCurrentAssets.value?.toString(),
                },
                propertyPlantAndEquipment: {
                  value: financial.balanceSheet.propertyPlantAndEquipment.value?.toString(),
                },
                proprietaryRights: {
                  value: financial.balanceSheet.proprietaryRights.value?.toString(),
                },
                accumulatedDepreciation: {
                  value: financial.balanceSheet.accumulatedDepreciation.value?.toString(),
                },
                otherFixedAssets: {
                  value: financial.balanceSheet.otherFixedAssets.value?.toString(),
                },
                accountsPayable: {
                  value: financial.balanceSheet.accountsPayable.value?.toString(),
                },
                shortTermLoans: {
                  value: financial.balanceSheet.shortTermLoans.value?.toString(),
                },
                taxesPayable: {
                  value: financial.balanceSheet.taxesPayable.value?.toString(),
                },
                otherCurrentLiabilities: {
                  value: financial.balanceSheet.otherCurrentLiabilities.value?.toString(),
                },
                longTermLoanes: {
                  value: financial.balanceSheet.longTermLoanes.value?.toString(),
                },
                shareHoldersLoans: {
                  value: financial.balanceSheet.shareHoldersLoans.value?.toString(),
                },
                deferredIncomeTaxes: {
                  value: financial.balanceSheet.deferredIncomeTaxes.value?.toString(),
                },
                otherLongTermLiabilites: {
                  value: financial.balanceSheet.otherLongTermLiabilites.value?.toString(),
                },
                registeredShares: {
                  value: financial.balanceSheet.registeredShares.value?.toString(),
                },
                additionalPaidInCapital: {
                  value: financial.balanceSheet.additionalPaidInCapital.value?.toString(),
                },
                accumulatedRetainedEarnings: {
                  value: financial.balanceSheet.accumulatedRetainedEarnings.value?.toString(),
                },
                otherOwnersEquity: {
                  value: financial.balanceSheet.otherOwnersEquity.value?.toString(),
                },
                totalAssets: {
                  value: financial.balanceSheet.totalAssets.value?.toString(),
                },
                totalLiabilites: {
                  value: financial.balanceSheet.totalLiabilites.value?.toString(),
                },
                ownersEquity: {
                  value: financial.balanceSheet.ownersEquity.value?.toString(),
                },
                liabilitiesAndOwnersEquity: {
                  value: financial.balanceSheet.liabilitiesAndOwnersEquity.value?.toString(),
                },
              }
            }))
        };

        try {
          if (useGravityAs) {
            await companyService.createWorkspaceCompany(command);
          } else {
            await companyService.createUserCompany(command);
          }
        } catch (e) {
          console.log(e);
          dispatch(companyUploadFailedAction());
          dispatch(popupSetModelAction({
            title: "An error occured during the upload, please try again later",
            text: (e as any)?.response?.data?.Message,
            type: PopupDialogType.Dialog,
            okButtonText: 'Ok',
            okButtonAction: (evt) => {
              evt.preventDefault();
              evt.stopPropagation();

              popupService.hidePopup();
            }
          }));
          popupService.showPopup();
          return;
        }

        if (useGravityAs) {
          const workspace = await workspaceService.getWorkspaceSettings(useGravityAs);
          dispatch(workspaceLoadedSuccessfulAction(workspace));
        } else {
          const user = await authenticationService.getUser();
          dispatch(authenticationSuccessAction(user));
        }

        dispatch(companyUploadedSuccessAction());

        dispatch(popupSetModelAction({
          title: "Company Upload",
          text: "Company successfully uploaded.",
          type: PopupDialogType.Dialog,
          okButtonText: 'Ok',
          okButtonAction: (evt) => {
            evt.preventDefault();
            evt.stopPropagation();

            popupService.hidePopup();

            history.push('/saved-companies');
          }
        }));
        popupService.showPopup();
      }

    }
  }
});

export default withRouter(
  connect<CompanyUploadStateProps, CompanyUploadDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyUploadComponent)
);
