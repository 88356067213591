import { AccountingAndFinance, Agriculture, BuildingAndConstruction, Commerce, Education, Entertainment, Government, HealthCare, Hospitality, Mining, Organizations, ProcessingAndManufacturing, RealEstate, Services, TechnologyMediaAndTelecom, TransportationAndWarehousing, Utilities } from './industries.model';


export type LabeledEnum = { [key: string]: { value?: number, label: string }; };
export type LabeledEnumArray<T extends LabeledEnum> = { key: keyof T, value?: number, label: string }[];

export const RevenueCategories: LabeledEnum = {
  All: { value: undefined, label: 'All Revenue Categories' },
  BelowFiveMillion: { value: 1, label: 'Below EUR 5M (~USD 6M)' },
  FiveToFiftyMillion: { value: 2, label: 'EUR 5M-50M (~USD 6M-60M)' },
  FiftyToTwoHundredMillion: { value: 3, label: 'EUR 50M-200M (~USD 60M-240M)' },
  TwoHundredToFiveHundredMillion: { value: 4, label: 'EUR 200M-500M (~USD 240M-600M)' },
  AboveFiveHundredMillion: { value: 5, label: 'Above EUR 500M (~USD 600M)' },
};

export const Industries: LabeledEnum = {
  All: { value: undefined, label: 'All Industries' },
  AccountingAndFinance: { value: 1, label: "Accounting and Finance"},
  Agriculture: { value: 10, label: "Agriculture"},
  BuildingAndConstruction: { value: 20, label: "Building and Construction"},
  Commerce: { value: 30, label: "Commerce"},
  Education: { value: 40, label: "Education"},
  Entertainment: { value: 50, label: "Entertainment"},
  Government: { value: 60, label: "Government"},
  HealthCare: { value: 70, label: "Health Care"},
  Hospitality: { value: 80, label: "Hospitality"},
  Mining: { value: 90, label: "Mining"},
  Organizations: { value: 100, label: "Organizations"},
  ProcessingAndManufacturing: { value: 110, label: "Processing And Manufacturing"},
  RealEstate: { value: 120, label: "Real Estate"},
  Services: { value: 130, label: "Services"},
  TechnologyMediaAndTelecom: { value: 140, label: "Technology, Media and Telecom"},
  TransportationAndWarehousing: { value: 150, label: "Transportation and Warehousing"},
  Utilities : { value: 160, label: "Utilities"},
};

export const EntityRelationshipStatus: LabeledEnum = {
  All: { value: undefined, label: 'All Entity Relationship Statuses' },
  Independent: { value: 1, label: 'Independent' },
  HoldingCompany: { value: 2, label: 'Holding company' },
  Subsidiary: { value: 3, label: 'Subsidiary' },
  PartOfGroup: { value: 4, label: 'Part of group' },
  Franchise: { value: 3, label: 'Franchise' }
};

export const ManagementContactInfoDepartment: LabeledEnum = {
  ExecutiveManagement: { value: 1, label: 'Executive Management"'},
  HumanResources: { value: 2, label: 'Human Resources'},
  AccountingAndFinance: { value: 3, label: 'Accounting and Finance'},
  Legal: { value: 4, label: 'Legal'},
  PlanningResearchAndDevelopment: { value: 5, label: 'Planning, Research and Development'},
  EngineeringAndDesign: { value: 6, label: 'Engineering and Design'},
  ProductionAndManufacturing: { value: 7, label: 'Production and Manufacturing'},
  InformationTechnology: { value: 8, label: 'Information Technology'},
  Purchasing: { value: 9, label: 'Purchasing'},
  MarketingPRAndPromotions: { value: 10, label: 'Marketing, PR, and Promotions'},
  Sales: { value: 11, label: 'Sales'},
  DistributionLogisticsAndWarehousing: { value: 12, label: 'Distribution, Logistics and Warehousing'},
  WholesaleTrade: { value: 13, label: 'Wholesale Trade'},
  SupplyChain: { value: 14, label: 'Supply Chain'},
  Administration: { value: 15, label: 'Administration'},
  PropertyManagementAndMaintenance: { value: 16, label: 'Property Management and Maintenance'},
  Other: { value: 20, label: 'Other'},
};

export const RevenueCategoriesArray: LabeledEnumArray<typeof RevenueCategories> = arrayifyEnum(RevenueCategories);
export const IndustriesArray: LabeledEnumArray<typeof Industries> = arrayifyEnum(Industries);

export const EntityRelationshipStatusArray: LabeledEnumArray<typeof EntityRelationshipStatus> = arrayifyEnum(EntityRelationshipStatus);
export const ManagementContactInfoDepartmentArray: LabeledEnumArray<typeof ManagementContactInfoDepartment> = arrayifyEnum(ManagementContactInfoDepartment);

function arrayifyEnum(labeledEnum: LabeledEnum) {
  return Object.keys(labeledEnum)
    .map(key => ({ key, value: labeledEnum[key].value, label: labeledEnum[key].label }));
}

export function selectSectors(industry: number) {
  switch (industry) {
    case Industries.AccountingAndFinance.value:
      return arrayifyEnum(AccountingAndFinance);
    case Industries.Agriculture.value:
      return arrayifyEnum(Agriculture);
    case Industries.BuildingAndConstruction.value:
      return arrayifyEnum(BuildingAndConstruction);
    case Industries.Commerce.value:
      return arrayifyEnum(Commerce);
    case Industries.Education.value:
      return arrayifyEnum(Education);
    case Industries.Entertainment.value:
      return arrayifyEnum(Entertainment);
    case Industries.Government.value:
      return arrayifyEnum(Government);
    case Industries.HealthCare.value:
      return arrayifyEnum(HealthCare);
    case Industries.Hospitality.value:
      return arrayifyEnum(Hospitality);
    case Industries.Mining.value:
      return arrayifyEnum(Mining);
    case Industries.Organizations.value:
      return arrayifyEnum(Organizations);
    case Industries.ProcessingAndManufacturing.value:
      return arrayifyEnum(ProcessingAndManufacturing);
    case Industries.RealEstate.value:
      return arrayifyEnum(RealEstate);
    case Industries.Services.value:
      return arrayifyEnum(Services);
    case Industries.TechnologyMediaAndTelecom.value:
      return arrayifyEnum(TechnologyMediaAndTelecom);
    case Industries.TransportationAndWarehousing.value:
      return arrayifyEnum(TransportationAndWarehousing);
    case Industries.Utilities.value:
      return arrayifyEnum(Utilities);
  }

  return [];
}

export enum RequestState {
  Initial,
  Loading,
  Succeeded,
  Failed
}

export enum CompanyAttachmentType {
  StandardImage = 1,
  ManagementStructure = 2,
  OtherDocument = 3
}

export interface SectorModel {
  name: string;
  value: string | number;
}