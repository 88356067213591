import { connect } from 'react-redux';
import { AppState } from '../../root.reducer';
import { SearchResultsComponent, SearchResultsComponentStateProps, SearchResultsComponentDispatchProps } from './SearchResults.component';
import { isSearchingSelector, companySearchCountResultsSelector, lastSearchFieldsSelector } from '../search.selectors';
import { Dispatch } from 'redux';
import { payForSearchResultsAction, payForSearchResultsErrorAction, loadAllCompanyCount, paySearchLoadingAction, payForSearchResultsSuccessAction } from '../search.actions';
import { searchService } from '../search.service';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { gravityUseAsSelector, userSelector } from '../../auth/authentication.selectors';
import { authenticationService } from '../../auth/authentication.service';
import { authenticationSuccessAction } from '../../auth/authentication.actions';
import { workspaceSelector } from '../../workspace-settings/workspace.selector';
import { CompanySearchState } from '../../saved-companies/saved-companies.models';
import { popupSetModelAction } from '../../popup/popup.actions';
import { PopupDialogType } from '../../popup/popup.models';
import { popupService } from '../../popup/popup.service';
import { workspaceService } from '../../workspace-settings/workspace.service';
import { workspaceLoadedSuccessfulAction } from '../../workspace-settings/workspace.actions';
import { resetCurrentPageAction } from '../../saved-companies/saved-companies.actions';

const mapStateToProps = (state: AppState): SearchResultsComponentStateProps => ({
    isLoading: isSearchingSelector(state),
    countResult: companySearchCountResultsSelector(state),
    user: userSelector(state),
    workspace: workspaceSelector(state),
    useGravityAs: gravityUseAsSelector(state),
});

const payForSearchActionCreator = (history: RouteComponentProps['history'], useGravityAs?: number) => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(paySearchLoadingAction());
    dispatch(payForSearchResultsAction());

    const state = getState();
    const searchQuery = lastSearchFieldsSelector(state);
    const searchCount = companySearchCountResultsSelector(state);

    const creditCountToSpend = useGravityAs ? Number(searchCount?.workspaceCreditCount) : searchCount?.userCreditCount;

    if (searchQuery === undefined || creditCountToSpend === undefined) {
        dispatch(payForSearchResultsErrorAction());
        return;
    }

    try {
        const response = await searchService.purchaseSearchResults({
            ...searchQuery,
            creditCountToSpend
        }, useGravityAs);

        if (response.savedSearchId) {
            dispatch(resetCurrentPageAction());

            if (useGravityAs) {
                const workspace = await workspaceService.getWorkspaceSettings(useGravityAs);
                dispatch(workspaceLoadedSuccessfulAction(workspace));
            } else {
                const user = await authenticationService.getUser();
                dispatch(authenticationSuccessAction(user));
            }

            if (response.searchState === CompanySearchState.Completed) {
                history.push(`/saved-search/${response.savedSearchId}`);
                dispatch(payForSearchResultsSuccessAction());
            } else {
                dispatch(popupSetModelAction({
                    title: 'Due to high volume, your purchase will be completed in a few minutes. Thank you for your patience.',
                    type: PopupDialogType.Dialog,
                    okButtonText: 'Ok',
                    okButtonAction: async (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        popupService.hidePopup();
                        history.push(`/saved-search/${response.savedSearchId}`);
                        //history.push(`/saved-companies`);
                        dispatch(payForSearchResultsSuccessAction());
                    }
                }));
                popupService.showPopup();
            }
        }

    } catch {
        dispatch(payForSearchResultsErrorAction());
    }
};

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): SearchResultsComponentDispatchProps => ({
    onPayForSearchResults: async (useGravityAs?: number) => {
        dispatch(payForSearchActionCreator(history, useGravityAs) as any);
    },

    loadAllCompaniesCount: async () => {
        const companyCount = await searchService.getAllCompanyCount();
        dispatch(loadAllCompanyCount(companyCount));
    }
});

export default withRouter(connect<SearchResultsComponentStateProps, SearchResultsComponentDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps,
    mapDispatchToProps
)(SearchResultsComponent));
