import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { User } from '../../auth/authentication.models';
import { FullPageLoadingComponent } from '../../FullpageLoading.component';
import { SaveWorkspaceModel, WorkspaceModel } from '../workspace.models';
import { WorkspaceFormComponent } from '../WorkspaceForm.component';

export interface WorkspaceFormStateProps {
  user: User | undefined;
  workspace: WorkspaceModel | undefined;
}

export interface WorkspaceFormDispatchProps {
  saveWorkspace(workspace: SaveWorkspaceModel): Promise<void>;
  initWorkspace(): void;
}

const WorkspaceFormPageComponent: React.FC<WorkspaceFormStateProps & WorkspaceFormDispatchProps & RouteComponentProps> =
  ({
    saveWorkspace,
    initWorkspace,
    workspace,
    user }) => {
    useEffect(() => {
      initWorkspace();
    }, [initWorkspace]);

    if (!user || !workspace) {
      return <FullPageLoadingComponent />;
    }
    
    return (
      <div className="container">
        <div className="stack stack-m">
          <div className="grid">
            <div className="gi-12">
              <div className="h-stack h-stack-xs jc-sb m-fd-cr">
                <p className="title title-l">Register Workspace</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="gi-6 col col--left">
              <form className="stack stack-m">
                <WorkspaceFormComponent
                  workspace={workspace}
                  saveWorkspace={saveWorkspace}
                  deleteWorkspace={() => { }} />
              </form>
            </div>
          </div>
        </div>
      </div>);
  };

export const WorkspaceFormPage = withRouter(WorkspaceFormPageComponent);