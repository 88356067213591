import React, { useState, useEffect } from 'react';
import { CreditBadgeComponent } from './CreditBadge.component';
import "./tagarea.scss";
import { creditsService } from './credits.service';

export interface TagAreaWithPriceComponentProps {
    value: string[];
    disabled?: boolean;
    uploadValueKey: string; // CompanyConstants
    label: string;
    isRequired?: boolean;
    isTouched?: boolean;
    onChange: (values: string[]) => void;
    isEditMode?: boolean;
    originalValue?: string[];
    onlyAlphabet?: boolean;
}

export const TagAreaWithPriceComponent: React.FC<TagAreaWithPriceComponentProps> = ({
    label,
    uploadValueKey,
    value,
    disabled,
    onChange,
    isRequired,
    isTouched,
    isEditMode,
    originalValue,
    onlyAlphabet
}) => {
    const [textValue, setTextValue] = useState('');
    const [tags, setTags] = useState<string[]>([]);


    useEffect(() => {
        if (textValue && textValue.endsWith(',')) {
            const newTag = textValue.split(',')[0].trim();
            if (isValid(newTag)) {
                onChange([...tags, newTag.trim()]);
                setTextValue('');
            }
        }
    }, [textValue, tags, setTags, setTextValue, onChange]);

    useEffect(() => {
        setTags(value || []);
    }, [value]);


    const isValid = (newTag: string) => {
        return newTag && !!firstValidChar(newTag) && newTag !== 'n/a' && newTag !== 'N/A';
    }

    const split = () => {
        if (textValue) {
            const newTag = textValue.trim();
            if (isValid(newTag)) {
                onChange([...tags, newTag.trim()]);
                setTextValue('');
            }
        }
    };

    const removeTagAtIndex = (index: number) => {
        const tagsValue = [
            ...tags.slice(0, index),
            ...tags.slice(index + 1, tags.length)
        ];
        setTags(tagsValue);
        onChange(tagsValue);
    };

    const validate = () => {
        return isRequired && isTouched && tags.length === 0;
    };

    const errorProps = validate() ? { error: 'error' } : {};
    const requiredStar = isRequired ? <span className="required-star" title="Required">*</span> : null;

    const hasCredit = isEditMode
        ? !disabled && tags.length > 0 && ((originalValue && originalValue.length !== tags.length) || ((originalValue?.join(',')) || '') !== tags.join(','))
        : tags.length > 0;

    const firstValidChar = (newTag: string) => {
        const chars = 'abcdefghijklmnopqrstvwqyzABCDEFGHIJKLMNOPQRSTVWQYZ';
        return chars.split('').find(c => newTag.includes(c));
    }

    return (
        <div className="stack stack-xs tagarea">
            <div>
                <div className="label">{label}{requiredStar}</div>
                <div className="field field--input" {...errorProps as any}>
                    {
                        !disabled ? tags.map((tag, index) => (
                            <span key={index} className="icon-chip icon-chip--label-left" style={{ '--background-color': 'var(--brand-color)' } as any}>
                                <span className="icon-chip__inner">
                                    <span className="label">{tag}</span>
                                    <svg className="icon icon--s" onClick={() => removeTagAtIndex(index)}>
                                        <use xlinkHref="#icon-close" />
                                    </svg>
                                </span>
                            </span>)
                        ) : null
                    }
                    <input
                        className="field__field"
                        disabled={disabled}
                        placeholder={`Type in ${label} separated by commas (,)`}
                        value={textValue}
                        onChange={(evt) => {
                            const newValue = evt.target.value;
                            if (newValue === ',') return;
                            if (onlyAlphabet && (newValue === '' || newValue.match(/^[A-Za-z ,]+$/))) {
                                setTextValue(evt.target.value);
                            } else if (!onlyAlphabet) {
                                setTextValue(evt.target.value);
                            }
                        }}
                        onKeyDown={(evt) => {
                            if (textValue === '' && evt.key === 'Backspace' && tags.length) {
                                removeTagAtIndex(tags.length - 1);
                            }
                        }}
                        onKeyUp={(evt) => {
                            if (evt.key === 'Enter') {
                                split();
                            }
                        }} />
                </div>
            </div>
            <div>
                <CreditBadgeComponent price={creditsService.getUploadValue(uploadValueKey)} hasValue={hasCredit} />
            </div>
        </div>
    );
};
