import React, { useState, useEffect } from "react";

import {
  CompanyUploadSummary,
  CreditMap,
  TouchedFinancialInputsModel,
  TouchedInputsModel,
} from "./company-upload.models";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { CompanyUploadPage } from "./CompanyUploadPage.component";
import { creditsService } from "./credits.service";
import { FullPageLoadingComponent } from "../FullpageLoading.component";
import { useDispatch } from "react-redux";
import { popupSetModelAction } from "../popup/popup.actions";
import { PopupDialogType } from "../popup/popup.models";
import { popupService } from "../popup/popup.service";
import { CompanyUploadModel } from './CompanyUpload.model';

export interface CompanyUploadStateProps {
  uploadModel?: CompanyUploadModel;
  isCompanyCreditsLoading: boolean;
  isCompanyUploading: boolean;
  companyCredits: CreditMap | undefined;
  companyUploadedSuccess: boolean;
  isCurrenciesLoading: boolean;
  currencies: string[] | undefined;
  touchedInputs: TouchedInputsModel | undefined;
  useGravityAs?: number | undefined;
}

export interface CompanyUploadDispatchProps {
  initializeCompany(): Promise<void>;
  updateCompany(model: CompanyUploadModel): void;
  loadCompanyCredits(): void;
  // loadCurrencies(): void;
  uploadCompany(model: CompanyUploadModel, creditReward: number, useGravityAs?: number): void;
  setTouchedFinancials: (financials: TouchedFinancialInputsModel[]) => void;
}

const CompanyUpload: React.FC<
  CompanyUploadStateProps & CompanyUploadDispatchProps & RouteComponentProps
> = ({
  isCompanyCreditsLoading,
  isCompanyUploading,
  companyCredits,
  uploadModel,
  initializeCompany,
  updateCompany,
  // loadCurrencies,
  loadCompanyCredits,
  isCurrenciesLoading,
  uploadCompany,
  useGravityAs,
  touchedInputs,
  setTouchedFinancials,
  companyUploadedSuccess,
  currencies,
}) => {
  useEffect(() => {
    initializeCompany();
    loadCompanyCredits();
    // if (companyCredits) {
    //   setNewCompanyModel(new CompanyUploadModel(companyCredits));
    // }
  }, [initializeCompany, loadCompanyCredits]);
  
    const [isFormValid, setIsFormValid] = useState(true);
    // const [newCompanyModel, setNewCompanyModel] = useState<
    //   CompanyUploadModel | undefined
    // >();
    const dispatch = useDispatch();


    // useEffect(() => {
    //   setNewCompanyModel(uploadModel);
    // }, [uploadModel]);

    if (
        isCompanyCreditsLoading ||
        !companyCredits ||
        !uploadModel ||
        isCurrenciesLoading ||
        !currencies ||
        !touchedInputs
    ) {
        return <FullPageLoadingComponent />;
    }

    const requiredSummaryFields: (keyof CompanyUploadSummary)[] = [
      "fullname",
      "countryOfFoundation",
      "industry",
    ];

    const requiredSummaryMainAddressFields: (keyof CompanyUploadModel["summary"]["mainAddress"])[] = [
      "countryId",
      "city",
    ];

    const requiredSummaryOtherContactsFields: (keyof CompanyUploadModel["summary"]["otherContactDetails"])[] = [

    ];

    const checkIsSummaryFormValid = () => {
      if (!uploadModel) {
        return false;
      }

      const isSummaryValid = requiredSummaryFields.reduce(
        (isValidSoFar, fieldName) => {
          if (!isValidSoFar) {
            return false;
          }
          return !!uploadModel.summary[fieldName].value;
        },
        true
      );

      return isSummaryValid;
    }

    const checkIsMainAddressFormValid = () => {
      if (!uploadModel) {
        return false;
      }

      const isValid = requiredSummaryMainAddressFields.reduce(
        (isValidSoFar, fieldName) => {
          if (!isValidSoFar) {
            return false;
          }
          return !!uploadModel.summary.mainAddress[fieldName].value;
        },
        true
      );

      return isValid;
    }

    const checkIsBusinessAddressFormValid = () => {
      if (!uploadModel) {
        return false;
      }
      const isValid = true;
      return isValid;
    }

    const checkIsOtherContactDetailsFormValid = () => {
      if (!uploadModel) {
        return false;
      }

      const isValid = requiredSummaryOtherContactsFields.reduce(
        (isValidSoFar, fieldName) => {
          if (!isValidSoFar) {
            return false;
          }
          return !!uploadModel.summary.otherContactDetails[fieldName].value;
        },
        true
      );

      return isValid;
    }

    const checkFinancialsFormValid = () => {
      if (!uploadModel) {
        return false;
      }

      const allowNegativeKeys = [
        "netIncomeBeforeTaxes",
        "netIncomeAfterTaxes",
        'retainedEarningsForTheYear',
        "additionalPaidInCapital",
        "accumulatedRetainedEarnings",
        "otherOwnersEquity",
        "ownersEquity"
      ];

      const anyValueNegative = (obj: any) => {
        return (
          Object.keys(obj).filter(
            (key) => obj[key] && obj[key].value && ((!allowNegativeKeys.includes(key) && obj[key].value < 0) || false)
          ).length !== 0
        );
      };

      const isFinancialsValid =
        uploadModel.financials.filter(
          (yearlyDetail) =>
            anyValueNegative(yearlyDetail.incomeStatement) ||
            anyValueNegative(yearlyDetail.balanceSheet)
        ).length === 0;

      return isFinancialsValid;
    }

    const checkManagementContactsValid = () => {
      if (!uploadModel) {
        return false;
      }

      const isValid =
        uploadModel.managementContacts.filter(
          (mc) => {
            const phoneNumberFilled = mc.individual.telephoneNumber.isValid && (mc.individual.telephoneNumber.value || '') !== '';
            const emailFilled = mc.individual.emailAddress && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mc.individual.emailAddress.value || '');

            if (!phoneNumberFilled && !emailFilled) {
              mc.individual.telephoneNumber.required = true;
              mc.individual.emailAddress.required = true;
            } else if (phoneNumberFilled) {
              mc.individual.emailAddress.required = false;
            } else if (emailFilled) {
              mc.individual.telephoneNumber.required = false;
            }

            return (mc.individual.name?.value || '') === ''
              || (mc.individual.position?.value || '') === ''
              || !(emailFilled || phoneNumberFilled);
          }
        ).length === 0;
      return isValid;
    }

    // set form valid state, set all inputs touched to make them red, then save if form is valid
    const trySaveCompany = (creditReward: number) => {
      const isValid =
        checkIsSummaryFormValid() &&
        checkFinancialsFormValid() &&
        checkIsMainAddressFormValid() &&
        checkIsOtherContactDetailsFormValid() &&
        checkIsBusinessAddressFormValid() &&
        checkManagementContactsValid();

      setIsFormValid(isValid);

      if (isValid) {
        dispatch(
          popupSetModelAction({
            title: "Confirmation",
            body: (<>
              <p>I hereby confirm that to the best of my knowledge, all information provided by me is true and valid. The purpose of my upload is providing valuable information, not to discredit any person or company.</p>
              <p className="bold">Please note</p>
              <p className="italic">In the event you are uploading information on a natural person other than yourself, he or she will be notified prior to publishing any personal data. Your identity will not be disclosed.</p>
            </>),
            type: PopupDialogType.Dialog,
            okButtonText: "Upload",
            noButtonText: "Cancel",
            okButtonAction: async (evt) => {
              evt.preventDefault();
              evt.stopPropagation();

              popupService.hidePopup();
              createCompany(creditReward);
            },
            cancelButtonAction: (evt) => {
              evt.preventDefault();
              evt.stopPropagation();

              popupService.hidePopup();
            },
          })
        );
        popupService.showPopup();
      }
    };

    const createCompany = (creditReward: number) => {
      uploadCompany(uploadModel, creditReward, useGravityAs);
    };

    const updateCurrency = (currency: string, multiplier: number) => {
      updateCompany({
        ...uploadModel,
        financialCurrency: currency,
        financialMultiplier: multiplier,
      } as any)
    };

    return (
        <>
            <CompanyUploadPage
            originRoute={`/`}
            company={uploadModel}
            updateCurrency={updateCurrency}
            setCompany={updateCompany}
            currencies={currencies}
            requiredFields={requiredSummaryFields}
            requiredMainAddressFields={requiredSummaryMainAddressFields}
            requiredBusinessAddressFields={[]}
            requiredOtherContactFields={requiredSummaryOtherContactsFields}
            isFormValid={isFormValid}
            touchedInputs={touchedInputs}
            setTouchedFinancials={setTouchedFinancials}
            sectors={[]}
            checkIsSummaryFormValid={checkIsSummaryFormValid}
            checkIsMainAddressFormValid={checkIsMainAddressFormValid}
            checkIsBusinessAddressFormValid={checkIsBusinessAddressFormValid}
            checkIsOtherContactDetailsFormValid={checkIsOtherContactDetailsFormValid}
            checkIsManagementContactsFormValid={checkManagementContactsValid}
            companyCredits={companyCredits}
            trySaveCompany={trySaveCompany}
            rewardSummaryCalculationMethod={(companyUploadModel) =>
                creditsService.getTotalUploadValue(companyUploadModel)
            }
        />
            {isCompanyUploading && <FullPageLoadingComponent />}
        </>
    );
  };

export const CompanyUploadComponent = withRouter(CompanyUpload);
