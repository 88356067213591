import React, { useState, useEffect } from "react";
import { SavedCompaniesListModel, SavedCompanySearch, SearchResultsTabsHeader } from "./saved-companies.models";
import { SavedCompaniesListComponent } from './saved-companies/SavedCompaniesList.component';
import { SavedSearchesListComponent } from './saved-searches/SavedSearchesList.component';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { User } from '../auth/authentication.models';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import { Utils } from '../utils/utils';

enum ActiveTab {
    SavedCompanies,
    UploadedCompanies,
    SavedSearches
}

export interface SavedCompaniesPageStateProps {
    user?: User;

    headers?: SearchResultsTabsHeader;
    areSavedCompaniesLoading: boolean;
    savedCompanyList?: SavedCompaniesListModel | undefined;

    areUploadedCompaniesLoading: boolean;
    uploadedCompanyList?: SavedCompaniesListModel | undefined;

    areSavedSearchesLoading: boolean;
    savedSearchesList?: SavedCompanySearch[];

    useGravityAs: number | undefined;
    page: number;
}

export interface SavedCompaniesPageDispatchProps {
    loadHeader(useGravityAs?: number): void;
    loadSavedCompanies(page: number, useGravityAs?: number): void;
    loadUploadedCompanies(page: number, useGravityAs?: number): void;
    loadSavedSearches(useGravityAs?: number): void;
    nextPage(maxPage: number): void;
    previousPage(): void;
    resetCurrentPage(): void;
}

export const SavedCompaniesPage: React.FC<SavedCompaniesPageStateProps & SavedCompaniesPageDispatchProps & RouteComponentProps> = ({
    user,
    headers,
    areUploadedCompaniesLoading,
    uploadedCompanyList,
    areSavedCompaniesLoading,
    savedCompanyList,
    areSavedSearchesLoading,
    savedSearchesList,
    history,
    loadHeader,
    loadSavedCompanies,
    loadUploadedCompanies,
    loadSavedSearches,
    useGravityAs,
    page,
    nextPage,
    previousPage,
    resetCurrentPage
}) => {
    const [activeTab, setActiveTab] = useState(ActiveTab.SavedCompanies);

    useEffect(() => {
        if(localStorage.getItem('activeTab') === null)
        {
            localStorage.setItem('activeTab', '0');
        }
        const storedActiveTab = localStorage.getItem('activeTab');
        loadHeader(useGravityAs);
        if (storedActiveTab === "0") {
            setActiveTab(ActiveTab.SavedCompanies);
            loadSavedCompanies(page, useGravityAs);
        } else if (storedActiveTab === "1") {
            setActiveTab(ActiveTab.UploadedCompanies);
            loadUploadedCompanies(page, useGravityAs);
        } else if (storedActiveTab === "2") {
            setActiveTab(ActiveTab.SavedSearches);
            loadSavedSearches(useGravityAs)
        }
    }, [useGravityAs, loadHeader, loadSavedCompanies, loadUploadedCompanies, loadSavedSearches, page, activeTab]);

    if (!user || areSavedCompaniesLoading || areSavedSearchesLoading || !headers || (!savedCompanyList && !uploadedCompanyList && !savedSearchesList)) {
        return <FullPageLoadingComponent />;
    }

    const goToUploadPage = () => {
        history.push('/upload-company');
    };

    const renderContent = () => {
        const savedCompaniesActiveProps = activeTab === ActiveTab.SavedCompanies ? { active: 'active', selected: 'selected' } as any : {};
        const uploadedCompaniesActiveProps = activeTab === ActiveTab.UploadedCompanies ? { active: 'active', selected: 'selected' } as any : {};
        const savedSearchesActiveProps = activeTab === ActiveTab.SavedSearches ? { active: 'active', selected: 'selected' } as any : {};

        const tabContent =
            {
                [ActiveTab.SavedCompanies as any]:
                    <SavedCompaniesListComponent
                        isLoading={areSavedCompaniesLoading}
                        savedCompanyList={savedCompanyList}
                        page={page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                    />,
                [ActiveTab.UploadedCompanies as any]:
                    <SavedCompaniesListComponent
                        isLoading={areUploadedCompaniesLoading}
                        savedCompanyList={uploadedCompanyList}
                        page={page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                    />,
                [ActiveTab.SavedSearches as any]:
                    <SavedSearchesListComponent
                        isLoading={areSavedSearchesLoading}
                        savedSearchesList={savedSearchesList}
                        useGravityAs={useGravityAs}
                    />,
            }[activeTab];


        const changeTab = (tab: ActiveTab) => {
            localStorage.setItem('activeTab', tab.toString());
            resetCurrentPage();
            setActiveTab(tab);
        }

        return (
            <div className="section section--content">
                <div className="container">
                    <div className="stack stack-m">
                        <nav className="tab-navigation ">
                            <div className="tab-navigation__desktop">
                                <a className="tab" onClick={() => changeTab(ActiveTab.SavedCompanies)} {...savedCompaniesActiveProps}>
                                    <span
                                        className="tab__details h-stack h-stack-xs jc-c ai-c">
                                        <span className="tab__label label">Saved Companies</span>
                                        <span className="tab__counter counter">{Utils.formatNumber(headers?.savedCompanies)}</span>
                                    </span>
                                </a>
                                <a className="tab" onClick={() => changeTab(ActiveTab.UploadedCompanies)} {...uploadedCompaniesActiveProps}>
                                    <span
                                        className="tab__details h-stack h-stack-xs jc-c ai-c">
                                        <span className="tab__label label">Uploaded Companies</span>
                                        <span className="tab__counter counter">{Utils.formatNumber(headers?.uploadedCompanies)}</span>
                                    </span>
                                </a>
                                <a className="tab" onClick={() => changeTab(ActiveTab.SavedSearches)} {...savedSearchesActiveProps}>
                                    <span className="tab__details h-stack h-stack-xs jc-c ai-c">
                                        <span className="tab__label label">Saved Search Results</span>
                                        <span className="tab__counter counter">{Utils.formatNumber(headers?.savedSearchResults)}</span>
                                    </span>
                                </a>
                            </div>
                            <div className="tab-navigation__mobile">
                                <div className="field field--select ">
                                    <select className="field__field" name="sc-tab-nav" id="sc-tab-nav"
                                        onChange={(evt) => {
                                            if (evt.target.value === ActiveTab.SavedCompanies.toString()) changeTab(ActiveTab.SavedCompanies)
                                            if (evt.target.value === ActiveTab.UploadedCompanies.toString()) changeTab(ActiveTab.UploadedCompanies)
                                            if (evt.target.value === ActiveTab.SavedSearches.toString()) changeTab(ActiveTab.SavedSearches)
                                        }}
                                        value={activeTab}
                                        placeholder="">
                                        <option value={ActiveTab.SavedCompanies}>Saved Companies</option>
                                        <option value={ActiveTab.UploadedCompanies} >Uploaded Companies</option>
                                        <option value={ActiveTab.SavedSearches} >Saved Search Results</option>
                                    </select>
                                    <div className="field__icon field__icon--right">
                                        <svg className="icon icon--m ">
                                            <use xlinkHref="#icon-arrow-down" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="tab-content">
                            {tabContent}
                        </div>
                    </div>
                </div>
            </div >
        );
    };

    const renderHeader = () => {
        return (
            <div className="section section--header">
                <div className="container">
                    <div className="stack stack-m">
                        <div className="grid">
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="h-stack h-stack-xs jc-sb m-fd-c">
                                    <div>
                                        <h1 className="title title-l">Search Results</h1>
                                        <p className="title fw-sb">Saved searches and companies</p>
                                    </div>
                                    <div>
                                        <button className="button" onClick={() => goToUploadPage()}>
                                            <span className="button__inner">
                                                <span>Upload a Company</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    };

    return (
        <>
            {renderHeader()}
            {renderContent()}
        </>
    );
};

export const SavedCompaniesPageComponent = withRouter(SavedCompaniesPage);