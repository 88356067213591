import { SavedCompanySearch, SavedCompaniesListModel, SearchResultsTabsHeader } from "./saved-companies.models";
import { actionCreator } from '../actions';

export enum SavedCompaniesActionTypes {
    SAVED_COMPANIES_LOADING = 'savedCompanies/loading',
    SAVED_COMPANIES_LOADING_SUCCESS = 'savedCompanies/loading/success',
    SEARCH_RESULTS_TABS_HEADER_LOADED_SUCCESS = 'savedCompanies/header/loading/success',
    SAVED_COMPANIES_LOADING_ERROR = 'savedCompanies/loading/error',
    SAVED_SEARCHES_LOADING = 'savedSearches/loading',
    SAVED_SEARCH_EXPORT_LOADING = 'savedSearch/export/loading',
    SAVED_SEARCH_EXPORT_LOADED = 'savedSearch/export/loaded',
    SAVED_SEARCHES_LOADING_SUCCESS = 'savedSearches/loading/success',
    SAVED_SEARCHES_LOADING_ERROR = 'savedSearches/loading/error',
    UPLOADED_COMPANIES_LOADING = 'uploadedCompanies/loading',
    UPLOADED_COMPANIES_LOADING_SUCCESS = 'uploadedCompanies/loading/success',
    UPLOADED_COMPANIES_LOADING_ERROR = 'uploadedCompanies/loading/error',
    SOURCE_LOADING = 'source/loading',
    SOURCE_LOADED_SUCCESS = 'source/loaded/success',
    SOURCE_CLEAR = 'source/clear',
    PAGE_NEXT = 'page/next',
    PAGE_PREVIOUS = 'page/previous',
    RESET_PAGE = 'page/reset',
    SET_PAGE = 'page/set',
    SET_PAGESIZE = 'page/setsize',
}

export const savedCompaniesLoadingAction = actionCreator<SavedCompaniesActionTypes.SAVED_COMPANIES_LOADING, void>(SavedCompaniesActionTypes.SAVED_COMPANIES_LOADING);

export const searchResultsTabsHeaderLoadedSuccessAction = actionCreator<SavedCompaniesActionTypes.SEARCH_RESULTS_TABS_HEADER_LOADED_SUCCESS, SearchResultsTabsHeader>(SavedCompaniesActionTypes.SEARCH_RESULTS_TABS_HEADER_LOADED_SUCCESS);

export const savedCompaniesLoadingSuccessAction = actionCreator<SavedCompaniesActionTypes.SAVED_COMPANIES_LOADING_SUCCESS, SavedCompaniesListModel>(SavedCompaniesActionTypes.SAVED_COMPANIES_LOADING_SUCCESS);
export const savedCompaniesLoadingErrorAction = actionCreator<SavedCompaniesActionTypes.SAVED_COMPANIES_LOADING_ERROR, void>(SavedCompaniesActionTypes.SAVED_COMPANIES_LOADING_ERROR);

export const uploadedCompaniesLoadingAction = actionCreator<SavedCompaniesActionTypes.UPLOADED_COMPANIES_LOADING, void>(SavedCompaniesActionTypes.UPLOADED_COMPANIES_LOADING);
export const uploadedCompaniesLoadingSuccessAction = actionCreator<SavedCompaniesActionTypes.UPLOADED_COMPANIES_LOADING_SUCCESS, SavedCompaniesListModel>(SavedCompaniesActionTypes.UPLOADED_COMPANIES_LOADING_SUCCESS);
export const uploadedCompaniesLoadingErrorAction = actionCreator<SavedCompaniesActionTypes.UPLOADED_COMPANIES_LOADING_ERROR, void>(SavedCompaniesActionTypes.UPLOADED_COMPANIES_LOADING_ERROR);

export const savedSearchExportLoadingAction = actionCreator<SavedCompaniesActionTypes.SAVED_SEARCH_EXPORT_LOADING, void>(SavedCompaniesActionTypes.SAVED_SEARCH_EXPORT_LOADING);
export const savedSearchExportLoadedAction = actionCreator<SavedCompaniesActionTypes.SAVED_SEARCH_EXPORT_LOADED, void>(SavedCompaniesActionTypes.SAVED_SEARCH_EXPORT_LOADED);

export const savedSearchesLoadingAction = actionCreator<SavedCompaniesActionTypes.SAVED_SEARCHES_LOADING, void>(SavedCompaniesActionTypes.SAVED_SEARCHES_LOADING);
export const savedSearchesLoadingSuccessAction = actionCreator<SavedCompaniesActionTypes.SAVED_SEARCHES_LOADING_SUCCESS, SavedCompanySearch[]>(SavedCompaniesActionTypes.SAVED_SEARCHES_LOADING_SUCCESS);
export const savedSearchesLoadingErrorAction = actionCreator<SavedCompaniesActionTypes.SAVED_SEARCHES_LOADING_ERROR, void>(SavedCompaniesActionTypes.SAVED_SEARCHES_LOADING_ERROR);

export const sourceLoadingAction = actionCreator<SavedCompaniesActionTypes.SOURCE_LOADING, void>(SavedCompaniesActionTypes.SOURCE_LOADING);
export const sourceLoadedSuccessAction = actionCreator<SavedCompaniesActionTypes.SOURCE_LOADED_SUCCESS, any>(SavedCompaniesActionTypes.SOURCE_LOADED_SUCCESS);
export const sourceClearAction = actionCreator<SavedCompaniesActionTypes.SOURCE_CLEAR, void>(SavedCompaniesActionTypes.SOURCE_CLEAR);

export const pageNextAction = actionCreator<SavedCompaniesActionTypes.PAGE_NEXT, number>(SavedCompaniesActionTypes.PAGE_NEXT);
export const pagePreviousAction = actionCreator<SavedCompaniesActionTypes.PAGE_PREVIOUS, void>(SavedCompaniesActionTypes.PAGE_PREVIOUS);
export const resetCurrentPageAction = actionCreator<SavedCompaniesActionTypes.RESET_PAGE, void>(SavedCompaniesActionTypes.RESET_PAGE);
export const setPageAction = actionCreator<SavedCompaniesActionTypes.SET_PAGE, number>(SavedCompaniesActionTypes.SET_PAGE);
export const setPageSizeAction = actionCreator<SavedCompaniesActionTypes.SET_PAGESIZE, number>(SavedCompaniesActionTypes.SET_PAGESIZE);

export type SavedCompaniesActions =
    ReturnType<typeof savedCompaniesLoadingAction> |
    ReturnType<typeof searchResultsTabsHeaderLoadedSuccessAction> |
    ReturnType<typeof savedCompaniesLoadingSuccessAction> |
    ReturnType<typeof savedCompaniesLoadingErrorAction> |
    ReturnType<typeof uploadedCompaniesLoadingAction> |
    ReturnType<typeof uploadedCompaniesLoadingSuccessAction> |
    ReturnType<typeof uploadedCompaniesLoadingErrorAction> |
    ReturnType<typeof savedSearchExportLoadingAction> |
    ReturnType<typeof savedSearchExportLoadedAction> |
    ReturnType<typeof savedSearchesLoadingAction> |
    ReturnType<typeof savedSearchesLoadingSuccessAction> |
    ReturnType<typeof savedSearchesLoadingErrorAction> |
    ReturnType<typeof sourceLoadingAction> |
    ReturnType<typeof sourceLoadedSuccessAction> |
    ReturnType<typeof sourceClearAction> |
    ReturnType<typeof pageNextAction> |
    ReturnType<typeof setPageAction> |
    ReturnType<typeof setPageSizeAction> |
    ReturnType<typeof pagePreviousAction> |
    ReturnType<typeof resetCurrentPageAction>;