import React from "react";
import { SavedCompanySearch, CompanySearchState } from "../saved-companies.models";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { popupSetModelAction } from '../../popup/popup.actions';
import { PopupDialogType } from '../../popup/popup.models';
import { popupService } from '../../popup/popup.service';

export interface SavedSearchListItemComponentProps {
    savedCompanySearch: SavedCompanySearch;
    useGravityAs?: number;
}

export interface SavedSearchListItemDispatchProps {
    deleteSearch: (savedSearchId: number, useGravityAs?: number) => void;
    exportSearch: (savedSearchId: number) => Promise<void>;
    resetCurrentPage(): void;
}

const SavedSearchListItem: React.FC<SavedSearchListItemComponentProps & SavedSearchListItemDispatchProps & RouteComponentProps> =
    ({ savedCompanySearch, deleteSearch, useGravityAs, resetCurrentPage, exportSearch, history }) => {
        const brandColorStyle = { '--background-color': 'var(--brand-color)' } as any as React.CSSProperties;

        const dispatch = useDispatch();
        const openResults = () => {
            resetCurrentPage();
            history.push(`/saved-search/${savedCompanySearch.id}`);
        };

        const openTableResults = () => {
            resetCurrentPage();
            history.push(`/saved-search-table/${savedCompanySearch.id}`);
        };

        const onExport = async (savedSearchId: number) => {
            await exportSearch(savedSearchId);
        }

        const onDelete = (savedSearchId: number) => {
            dispatch(
                popupSetModelAction({
                    title: "Delete saved search",
                    text: 'Are you sure to delete search?',
                    type: PopupDialogType.Dialog,
                    okButtonText: "Delete",
                    noButtonText: "Cancel",
                    okButtonAction: (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        popupService.hidePopup();
                        deleteSearch(savedSearchId, useGravityAs);
                    },
                    cancelButtonAction: (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        popupService.hidePopup();
                    },
                })
            );
            popupService.showPopup();
        }

        return (
            <li>
                <div className="item item--scs">
                    <div className="h-stack h-stack-xs ai-c">
                        <div className="col col--name">
                            <div className="stack stack-xs">
                                <h3 className="title title-s" >{savedCompanySearch.name}</h3>
                                <p className="label">{savedCompanySearch.resultCount} items</p>
                            </div>
                        </div>
                        <div className="col col--tags">
                            <div className="stack stack-xs">
                                <p className="title title-s">Search parameters</p>
                                <ul className="parameters h-stack h-stack-xs">
                                    {
                                        savedCompanySearch.searchParameters.map((parameter, index) => (
                                            <li key={index}>
                                                <span className="icon-chip icon-chip--label-left" style={brandColorStyle}>
                                                    <span className="icon-chip__inner">
                                                        <span className="label">{parameter}</span>
                                                    </span>
                                                </span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col col--actions">
                            <div className="stack stack-xs ai-fe">
                                {
                                    savedCompanySearch.state === CompanySearchState.Completed ?
                                        <>
                                            <button className="icon-button" onClick={() => openResults()}>
                                                <span
                                                    className="icon-button__inner">
                                                    <svg className="icon icon--m">
                                                        <use xlinkHref="#icon-eye" />
                                                    </svg>
                                                    <span className="label">Open results</span>
                                                </span>
                                            </button>
                                            {/* <button className="icon-button" onClick={() => openTableResults()}>
                                                <span
                                                    className="icon-button__inner">
                                                    <svg className="icon icon--m">
                                                        <use xlinkHref="#icon-revenue" />
                                                    </svg>
                                                    <span className="label">Table</span>
                                                </span>
                                            </button> */}
                                            <button className="icon-button" onClick={() => onExport(savedCompanySearch.id)}>
                                                <span
                                                    className="icon-button__inner">
                                                    <svg className="icon icon--m">
                                                        <use xlinkHref="#icon-export" />
                                                    </svg>
                                                    <span className="label">Export</span>
                                                </span>
                                            </button>
                                            <button className="icon-button" onClick={() => onDelete(savedCompanySearch.id)}>
                                                <span className="icon-button__inner">
                                                    <svg className="icon icon--m">
                                                        <use xlinkHref="#icon-trash" />
                                                    </svg>
                                                    <span className="label">Delete</span>
                                                </span>
                                            </button>
                                        </> :
                                        <>
                                            <span
                                                className="icon-button__inner">
                                                <span className="label">Results will be available shortly.</span>
                                            </span>
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </li >
        );
    };

export const SavedSearchListItemComponent = withRouter(SavedSearchListItem);