export interface PopupModel {
  title?: string;
  text?: string;
  body?: any;
  type: PopupDialogType;
  okButtonText?: string;
  okButtonType?: string;
  noButtonText?: string;
  hideCloseIcon?: boolean;
  okButtonAction?: (evt: any) => void;
  cancelButtonAction?: (evt: any) => void;
  buttonSpacing?: string;
}

export enum PopupDialogType {
  Confirm,
  Dialog,
}