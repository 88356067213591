import React from 'react';
import { SearchKeywordsFields } from '../search.models';

interface SearchInKeywordComponentProps {
    propertyName: keyof SearchKeywordsFields;
    checked: boolean;
    onChange: (value: boolean) => void;
}

export const SearchInKeywordComponent: React.FC<SearchInKeywordComponentProps> = ({ propertyName, checked, onChange }) => {
    const switcherDisplayTexts: any = {
        'companynames': 'Company Names',
        'contacts': 'Contacts',
        'addresses': 'Addresses',
        'productsAndServices': 'Products & Services',
        'allOther': 'All Other Fields'
    };

    return (
        <div>
            <label className="switch switch--toggle switch--label-right">
                <input className="visually-hidden"
                    checked={checked}
                    onChange={evt => onChange(evt.target.checked)}
                    type="checkbox"
                />
                <span className="switch__switch" onTouchEnd={(evt) => { onChange(!checked) }}>
                    <span className="dot" />
                </span>
                <span className="switch__label label">{switcherDisplayTexts[propertyName]}</span>
            </label>
        </div>
    );
};
