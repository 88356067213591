import React, { useState, useEffect } from 'react';
import { CreditBadgeComponent } from './CreditBadge.component';
import { useDebounce } from '../utils/debounce-hook';
import { CompanyUploadModel } from './CompanyUpload.model';

export interface RewardSummaryProps {
    company: CompanyUploadModel;
    calculationMethod: (company: CompanyUploadModel) => number;
}

export const RewardSummaryComponent: React.FC<RewardSummaryProps> = ({ company, calculationMethod }) => {
    const [creditValue, setCreditValue] = useState(0);

    const debouncedCompany = useDebounce(company, 500);

    useEffect(() => {
        const value = calculationMethod(debouncedCompany);
        setCreditValue(value);
    }, [debouncedCompany, calculationMethod]);

    return (
        <div className="stack stack-m sticky">
            <div className="info-side">
                <div className="stack stack-xs">
                    <p className="title title-s">Your credit reward total</p>
                    <p>
                        <CreditBadgeComponent price={creditValue} hasValue={creditValue > 0} />
                    </p>
                </div>
            </div>
        </div >
    );
};
