import React, { useState } from 'react';
import { SavedCompanyModel, SavedCompanyAttributeModel, AttributeType } from '../saved-company.models';
import { SummaryTab } from './SummaryTab.component';
import { ProductsAndServicesTab } from './ProductsAndServicesTab.component';
import { CartItem } from '../../cart/cart.models';
import { FinancialDetailsTab } from './FinancialDetailsTab.component';
import { ManagementContactsTab } from './ManagementContactsTab.component';
import { DocumentsAndAttachmentsTab } from './DocumentsAndAttachmentsTab.component';

enum SavedCompanyTabs {
    Summary = 'summary',
    ProductServices = 'productServices',
    ManagementContacts = 'managementContacts',
    DocumentsAttachments = 'documentsAttachments',
    Financials = 'financials',
}

export interface SavedCompanyTabsComponentProps {
    savedCompany: SavedCompanyModel;
}

export interface SavedCompanyComponentDispatchProps {
    addCartItem: (item: CartItem) => void;
}

export const CompanyProfileTabsComponent: React.FC<SavedCompanyTabsComponentProps & SavedCompanyComponentDispatchProps> = ({ savedCompany, addCartItem }) => {
    const [selectedTab, setSelectedTab] = useState(SavedCompanyTabs.Summary);

    const tabs = {
        [SavedCompanyTabs.Summary]: {
            header: 'Summary',
            component:
                <SummaryTab
                    savedCompany={savedCompany}
                    addPropertyToCart={(attribute: SavedCompanyAttributeModel, label: string, propertyName: string) => {
                        addCartItem({
                            propertyName: propertyName,
                            label: label || '',
                            attributeType: AttributeType.Summary,
                            attributeId: attribute.attributeId,
                            price: attribute!.creditPrice
                        });
                    }}
                />
        },
        [SavedCompanyTabs.ProductServices]: {
            header: 'Products & Services',
            component:
                <ProductsAndServicesTab
                    savedCompany={savedCompany}
                    addPropertyToCart={(attribute: SavedCompanyAttributeModel, label: string, propertyName: string) => {
                        addCartItem({
                            propertyName: propertyName,
                            label: label || '',
                            attributeType: AttributeType.ProductsAndServices,
                            attributeId: attribute.attributeId,
                            price: attribute!.creditPrice
                        });
                    }}
                />
        },
        [SavedCompanyTabs.ManagementContacts]: {
            header: 'Management Contacts',
            component: <ManagementContactsTab
                savedCompany={savedCompany}
                addPropertyToCart={(attribute: SavedCompanyAttributeModel, label: string, propertyName: string) => {
                    addCartItem({
                        label: label || '',
                        propertyName: propertyName,
                        attributeType: AttributeType.ManagementContact,
                        attributeId: attribute.attributeId,
                        price: attribute!.creditPrice
                    });
                }}
            />
        },
        [SavedCompanyTabs.Financials]: {
            header: 'Financials',
            component:
                <FinancialDetailsTab
                    savedCompany={savedCompany}
                    addPropertyToCart={(attribute: SavedCompanyAttributeModel, label: string, propertyName: string) => {
                        addCartItem({
                            propertyName: propertyName,
                            label: label || '',
                            attributeType: AttributeType.Financial,
                            attributeId: attribute.attributeId,
                            price: attribute!.creditPrice
                        });
                    }}
                />
        },
        [SavedCompanyTabs.DocumentsAttachments]: {
            header: 'Supporting Documents',
            component: <DocumentsAndAttachmentsTab
                savedCompany={savedCompany}
                addPropertyToCart={(attachmentId: number, attachmentName: string, creditPrice: number) => {
                    addCartItem({
                        label: attachmentName || '',
                        attributeType: AttributeType.Attachment,
                        attributeId: attachmentId,
                        price: creditPrice
                    });
                }}
            />
        },
    };

    const renderTabHeader = (tab: SavedCompanyTabs) => (
        // @ts-ignore
        <div className="tab" key={tab} onClick={() => setSelectedTab(tab)} active={selectedTab === tab ? 'active' : null}>
            <span className="tab__details h-stack h-stack-xs jc-c ai-c">
                <span className="tab__label label">{tabs[tab].header}</span>
            </span>
        </div>
    );

    const renderTab = (tab: SavedCompanyTabs) => tabs[tab].component;

    return (
        <div className="stack stack-m">
            <nav className="tab-navigation">
                <div className="tab-navigation__desktop">
                    {Object.keys(tabs).map(tab => renderTabHeader(tab as any as SavedCompanyTabs))}
                </div>
                <div className="tab-navigation__mobile">
                    <div className="field field--select ">
                        <select className="field__field" name="cp-tab-nav" id="cp-tab-nav" placeholder="">
                            {Object.keys(tabs).map((tab, index) => (
                                <option key={index} value="basic-infos">{tabs[tab as SavedCompanyTabs].header}</option>
                            ))}
                        </select>
                        <div className="field__icon field__icon--right">
                            <svg className="icon icon--m ">
                                <use xlinkHref="#icon-arrow-down" />
                            </svg>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="tab-content">
                <div className="stack stack-m">
                    {renderTab(selectedTab)}
                </div >
            </div >
        </div >
    );
};