import { AxiosInstance } from "axios";
import { AttachmentType, CompanyAttachmentModel } from "./company-upload.models";
import { axiosInstance } from "../axios";

class FileUploadService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    async uploadFile(type: AttachmentType, file: File): Promise<CompanyAttachmentModel> {
        const url = `/api/companies/upload-attachment/${type}`;
        const formData = new FormData();
        formData.append('file', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        const response = await this.axios.post<CompanyAttachmentModel>(url, formData, config);
        return response.data;
    }
}

export const fileUploadService = new FileUploadService();