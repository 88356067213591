import React from 'react';
import { CreditBadgeComponent } from './CreditBadge.component';
import { creditsService } from './credits.service';

export interface SelectWithPriceComponentProps {
    label: string;
    value?: string | number;
    disabled?: boolean;
    isRequired?: boolean;
    isTouched?: boolean;
    uploadValueKey: string; // CompanyConstants
    placeholder?: string;
    options: { name: string; value: any }[];
    onBlur(value: any): void;
    isEditMode?: boolean;
    originalValue?: string | number;
}

export const SelectWithPriceComponent: React.FC<SelectWithPriceComponentProps> = 
({ label, options, value, disabled, isRequired, isTouched, uploadValueKey, placeholder, onBlur, isEditMode, originalValue }) => {
    const placeholderText = placeholder || `Select ${label}`;
    const errorProps = isRequired && isTouched && !value ? { error: 'error' } : {};
    const requiredStar = isRequired ? <span className="required-star" title="Required">*</span> : null;

    const hasCredit = isEditMode 
        ? (originalValue !== undefined && value === undefined) || (originalValue === undefined && value !== undefined) || (originalValue !== value)
        : !!value;

    return (
        <div className="stack stack-xs">
            <div>
                <div className="label">{label}{requiredStar}</div>
                <div className="field field--select" {...errorProps as any}>
                    <select
                        disabled={disabled}
                        className="field__field"
                        defaultValue={value}
                        onBlur={(evt) => onBlur(evt.target.value || undefined)}>
                        <option value={''}>{placeholderText}</option>
                        {options.map(option =>
                            <option key={option.name} value={option.value}>{option.name}</option>
                        )}
                    </select>
                    <div className="field__icon field__icon--right">
                        <svg className="icon icon--m ">
                            <use xlinkHref="#icon-arrow-down" />
                        </svg>
                    </div>
                </div>
            </div>
            <div>
                <CreditBadgeComponent price={creditsService.getUploadValue(uploadValueKey)} hasValue={hasCredit} />
            </div>
        </div>
    );
};
