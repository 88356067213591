import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { CartItem } from '../../cart/cart.models';
import { CompanyProfileTabsComponent, SavedCompanyComponentDispatchProps, SavedCompanyTabsComponentProps } from './SavedCompanyTabs.component';
import { addPropertyToCartAction } from '../../cart/cart.actions';
import { AppState } from '../../root.reducer';

const mapDispatchToProps = (dispatch: Dispatch): SavedCompanyComponentDispatchProps => ({
    addCartItem: (item: CartItem) => {
        dispatch(addPropertyToCartAction(item));
        
        const cartStorage = localStorage.getItem('savedCompanyCart');
        const cart = JSON.parse(cartStorage || '{items: []}');
        cart.items.push(item);
        localStorage.setItem('savedCompanyCart', JSON.stringify(cart));
    }
});

export default connect<{}, SavedCompanyComponentDispatchProps, SavedCompanyTabsComponentProps, AppState>(
    null,
    mapDispatchToProps
)(CompanyProfileTabsComponent);