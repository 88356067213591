import { actionCreator } from '../actions';
import { WorkspaceModel, InvitedWorkspaceModel, WorkspaceDeleteConfirmationModel } from './workspace.models';

export enum WorkspaceActionTypes {
    WORKSPACE_LOADING = 'workspace/loading',
    WORKSPACE_LOADED_SUCCESSFUL = 'workspace/loaded/successful',
    WORKSPACE_LOADING_STOP = 'workspace/loading/stop',
    WORKSPACE_INITIALIZED_SUCCESSFUL = 'workspace/initialized/successful',
    WORKSPACE_INVITATIONS_LOADED_SUCCESSFUL = 'workspace/invitations/loaded/successful',
    WORKSPACE_SAVED_SUCCESSFUL = 'workspace/save/successful',
    WORKSPACE_DELETE_SUCCESSFUL = 'workspace/delete/successful',
    WORKSPACE_DELETE_CONFIRM = 'workspace/delete/confirm',
    WORKSPACE_OWNERSHIP_LOADED_SUCCESSFUL = 'workspace/ownership/loaded/successful',
    WORKSPACE_ERROR = 'workspace/error',
}

export const workspaceLoadingAction = actionCreator<WorkspaceActionTypes.WORKSPACE_LOADING, void>(WorkspaceActionTypes.WORKSPACE_LOADING);
export const workspaceLoadedSuccessfulAction = actionCreator<WorkspaceActionTypes.WORKSPACE_LOADED_SUCCESSFUL, WorkspaceModel>(WorkspaceActionTypes.WORKSPACE_LOADED_SUCCESSFUL);
export const workspaceOwnershipLoadedSuccessfulAction = actionCreator<WorkspaceActionTypes.WORKSPACE_OWNERSHIP_LOADED_SUCCESSFUL, WorkspaceModel>(WorkspaceActionTypes.WORKSPACE_OWNERSHIP_LOADED_SUCCESSFUL);
export const workspaceInvitationsLoadedSuccessfulAction = actionCreator<WorkspaceActionTypes.WORKSPACE_INVITATIONS_LOADED_SUCCESSFUL, InvitedWorkspaceModel[]>(WorkspaceActionTypes.WORKSPACE_INVITATIONS_LOADED_SUCCESSFUL);
export const workspaceLoadingStopAction = actionCreator<WorkspaceActionTypes.WORKSPACE_LOADING_STOP, void>(WorkspaceActionTypes.WORKSPACE_LOADING_STOP);
export const workspaceInitializedSuccessfulAction = actionCreator<WorkspaceActionTypes.WORKSPACE_INITIALIZED_SUCCESSFUL, void>(WorkspaceActionTypes.WORKSPACE_INITIALIZED_SUCCESSFUL);
export const workspaceSavedSuccessfulAction = actionCreator<WorkspaceActionTypes.WORKSPACE_SAVED_SUCCESSFUL, void>(WorkspaceActionTypes.WORKSPACE_SAVED_SUCCESSFUL);
export const workspaceDeletedSuccessfulAction = actionCreator<WorkspaceActionTypes.WORKSPACE_DELETE_SUCCESSFUL, void>(WorkspaceActionTypes.WORKSPACE_DELETE_SUCCESSFUL);
export const workspaceDeleteConfirmAction = actionCreator<WorkspaceActionTypes.WORKSPACE_DELETE_CONFIRM, WorkspaceDeleteConfirmationModel[]>(WorkspaceActionTypes.WORKSPACE_DELETE_CONFIRM);

export const workspaceErrorAction = actionCreator<WorkspaceActionTypes.WORKSPACE_ERROR, any>(WorkspaceActionTypes.WORKSPACE_ERROR);

export type WorkspaceActions = ReturnType<typeof workspaceLoadingAction> |
  ReturnType<typeof workspaceLoadedSuccessfulAction> |
  ReturnType<typeof workspaceOwnershipLoadedSuccessfulAction> |
  ReturnType<typeof workspaceDeleteConfirmAction> |
  ReturnType<typeof workspaceInvitationsLoadedSuccessfulAction> |
  ReturnType<typeof workspaceLoadingStopAction> |
  ReturnType<typeof workspaceInitializedSuccessfulAction> |
  ReturnType<typeof workspaceErrorAction> |
  ReturnType<typeof workspaceSavedSuccessfulAction> |
  ReturnType<typeof workspaceDeletedSuccessfulAction>;