import React from 'react';
import { TextFieldWithPriceComponent } from '../TextFieldWithPrice.component';
import { CompanyAttributeKey } from '../../company.constants';
import { ManagementContactInfoDepartmentArray } from '../../models';
import { SelectWithPriceComponent } from '../SelectWithPrice.component';
import { TagAreaWithPriceComponent } from '../TagAreaWithPrice.component';
import { CompanyUploadModel } from '../CompanyUpload.model';
import { TouchedManagementContactInputsModel } from '../company-upload.models';
import { PhoneNumberComponent } from '../../components/phone-number/PhoneNumberComponent';

export interface ManagementContactProps {
    isEditMode?: boolean;
    originalValue?: CompanyUploadModel['managementContacts'][0];
    managementContact: CompanyUploadModel['managementContacts'][0];
    touchedInputs: TouchedManagementContactInputsModel;
    updateIndividual: (key: keyof CompanyUploadModel['managementContacts'][0]['individual'], value: any) => void;
    updateFrontDesk: (key: keyof CompanyUploadModel['managementContacts'][0]['frontDeskOrDepartment'], value: any) => void;
    index: number;
    isOpen: boolean;
    addContact: () => void;
    toggleCollapse: () => void;
    deleteContact: () => void;
    isValid: () => boolean;
}

export const ManagementContactComponent: React.FC<ManagementContactProps> = ({
    isEditMode,
    index,
    originalValue,
    managementContact,
    updateIndividual,
    updateFrontDesk,
    isOpen,
    addContact,
    toggleCollapse,
    deleteContact,
    touchedInputs,
    isValid
}) => {
    const managementDepartments = ManagementContactInfoDepartmentArray.map((r) => (
        {
            name: r.label || '',
            value: r.value || '',
        }
    ));

    const renderHeader = () => (
        <div className={"h-stack h-stack-xs ai-c "} >
            <div className="col col--name" style={{ flex: '1' }}>
                <p className="fw-b" onClick={() => toggleCollapse()}>
                    <span className="icon-button__inner" style={{cursor: 'pointer'}}>
                        {managementContact.individual.name.value || managementContact.individual.position.value || 'Contact ' + index}
                        <svg className="icon icon--m ">
                            <use xlinkHref={isOpen ? "#icon-arrow-right" : "#icon-arrow-up"} />
                        </svg>
                    </span>
                </p>
            </div>
            {
                !isEditMode || !managementContact.id ?
                    <div className="col col--icon">
                        <button className="icon-button" onClick={() => deleteContact()}>
                            <span className="icon-button__inner">
                                <svg className="icon icon--m ">
                                    <use xlinkHref="#icon-trash" />
                                </svg>
                                <span>Delete this entry</span>
                            </span>
                        </button>
                    </div> : null
            }
        </div>
    );

    const renderForm = () => {
        return (
            <>
                <div className="grid">
                    <div className="gi-8">
                        <p className="title title-s">Personal Information</p>
                    </div>
                    <div className="gi-4" style={{ textAlign: 'right' }}>
                        <button className="button button--secondary" onClick={() => addContact()}>
                            <span className="button__inner">
                                <svg className="icon icon--m">
                                    <use xlinkHref="#icon-add" />
                                </svg>
                                <span>Add Another Contact</span>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="grid">
                    <div className="gi-4">
                        <TextFieldWithPriceComponent type="text" label="Name"
                            disabled={managementContact.individual.name.disabled}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.Individual.Name}
                            value={managementContact.individual.name.value}
                            onBlur={(value) => updateIndividual('name', value)}
                            isRequired={managementContact.individual.name.required}
                            isEditMode={isEditMode}
                            originalValue={originalValue?.individual.name.value}
                            isTouched={touchedInputs.individual.name}
                        />
                    </div>
                    <div className="gi-4">
                        <TextFieldWithPriceComponent type="text" label="Position"
                            disabled={managementContact.individual.position.disabled}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.Individual.Position}
                            value={managementContact.individual.position.value}
                            onBlur={(value) => {
                                const regExpression = /^[a-zA-Z\s]*$/;
                                if (regExpression.test(value ?? "")) {
                                    updateIndividual('position', value);
                                } else {
                                    updateIndividual('position', "fggfh");
                                }
                            }}
                            isRequired={managementContact.individual.position.required}
                            isEditMode={isEditMode}
                            originalValue={originalValue?.individual.position.value}
                            isTouched={touchedInputs.individual.position}
                            onlyAlphabet={true}
                        />
                    </div>
                    <div className="gi-4">
                        <TextFieldWithPriceComponent type="text" label="Location"
                            disabled={managementContact.individual.location.disabled}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.Individual.Location}
                            value={managementContact.individual.location.value}
                            onBlur={(value) => updateIndividual('location', value)}
                            isRequired={managementContact.individual.location.required}
                            isEditMode={isEditMode}
                            originalValue={originalValue?.individual.location.value}
                            isTouched={touchedInputs.individual.location}
                            enabledRegex={"^[A-Za-z,./ ]+$"}
                        />
                    </div>
                </div>
                <div className="grid">
                    <div className="gi-12">
                        <TagAreaWithPriceComponent
                            label="Functions"
                            disabled={managementContact.individual.functions.disabled}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.Individual.Functions}
                            value={managementContact.individual.functions.value || []}
                            onChange={value => updateIndividual('functions', value)}
                            isRequired={managementContact.individual.functions.required}
                            isEditMode={isEditMode}
                            originalValue={originalValue?.individual.functions.value}
                            isTouched={touchedInputs.individual.functions}
                            onlyAlphabet={true}
                        />
                    </div>
                </div>
                <div className="grid">
                    <div className="gi-6">
                        <PhoneNumberComponent
                            label="Telephone Number"
                            isRequired={managementContact.individual.telephoneNumber.required}
                            isTouched={touchedInputs.individual.telephoneNumber}
                            onBlur={(value: any) => {
                                updateIndividual('telephoneNumber', value);
                            }}
                            disabled={managementContact.individual.telephoneNumber.disabled}
                            value={managementContact.individual.telephoneNumber.value}
                            originalValue={originalValue?.individual.telephoneNumber.value}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.Individual.TelephoneNumber}
                            isEditMode={isEditMode}
                            property={managementContact.individual.telephoneNumber}
                        />
                    </div>
                    <div className="gi-4">
                        <TextFieldWithPriceComponent type="text"
                            label="Email Address"
                            placeholder="Enter Email Address (must contain @)"
                            customValidation={(value) => {
                                return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
                            }}
                            disabled={managementContact.individual.emailAddress.disabled}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.Individual.EmailAddress}
                            value={managementContact.individual.emailAddress.value}
                            onBlur={(value) => updateIndividual('emailAddress', value)}
                            isRequired={managementContact.individual.emailAddress.required}
                            isEditMode={isEditMode}
                            originalValue={originalValue?.individual.emailAddress.value}
                            isTouched={touchedInputs.individual.emailAddress}
                        />
                    </div>
                    <div className="gi-4">
                        <TextFieldWithPriceComponent type="text"
                            label="LinkedIn"
                            placeholder="Enter LinkedIn Url"
                            disabled={managementContact.individual.linkedIn.disabled}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.Individual.LinkedIn}
                            value={managementContact.individual.linkedIn.value}
                            onBlur={(value) => updateIndividual('linkedIn', value)}
                            isRequired={managementContact.individual.linkedIn.required}
                            isEditMode={isEditMode}
                            originalValue={originalValue?.individual.linkedIn.value}
                            isTouched={touchedInputs.individual.linkedIn}
                            enabledRegex={"^[A-Za-z,:./]+$"}
                        />
                    </div>
                </div>
                <p className="title title-s">Front Desk or Department</p>
                <div className="grid">
                    <div className="gi-4">
                        <TextFieldWithPriceComponent type="text" label="Location"
                            disabled={managementContact.frontDeskOrDepartment.location.disabled}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.FrontDeskOrDepartment.Location}
                            value={managementContact.frontDeskOrDepartment.location.value}
                            onBlur={(value) => updateFrontDesk('location', value)}
                            isRequired={managementContact.frontDeskOrDepartment.location.required}
                            isEditMode={isEditMode}
                            originalValue={originalValue?.frontDeskOrDepartment.location.value}
                            isTouched={touchedInputs.frontDeskOrDepartment.location}
                            enabledRegex={"^[A-Za-z,./]+$"}
                        />
                    </div>
                    <div className="gi-6">
                        <PhoneNumberComponent
                            label="Telephone Number"
                            isRequired={managementContact.frontDeskOrDepartment.telephoneNumber.required}
                            isTouched={touchedInputs.frontDeskOrDepartment.telephoneNumber}
                            onBlur={(value: any) => {
                                updateFrontDesk('telephoneNumber', value);
                            }}
                            disabled={managementContact.frontDeskOrDepartment.telephoneNumber.disabled}
                            value={managementContact.frontDeskOrDepartment.telephoneNumber.value}
                            originalValue={originalValue?.frontDeskOrDepartment.telephoneNumber.value}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.FrontDeskOrDepartment.TelephoneNumber}
                            isEditMode={isEditMode}
                            property={managementContact.frontDeskOrDepartment.telephoneNumber}
                        />
                    </div>
                </div>
                <div className="grid">
                    <div className="gi-4">
                        <TextFieldWithPriceComponent type="text"
                            label="Email Address"
                            placeholder="Enter Email Address (must contain @)"
                            disabled={managementContact.frontDeskOrDepartment.emailAddress.disabled}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.FrontDeskOrDepartment.EmailAddress}
                            value={managementContact.frontDeskOrDepartment.emailAddress.value}
                            onBlur={(value) => updateFrontDesk('emailAddress', value)}
                            isRequired={managementContact.frontDeskOrDepartment.emailAddress.required}
                            isEditMode={isEditMode}
                            originalValue={originalValue?.frontDeskOrDepartment.emailAddress.value}
                            isTouched={touchedInputs.frontDeskOrDepartment.emailAddress}
                        />
                    </div>
                    <div className="gi-4">
                        <SelectWithPriceComponent label="Department"
                            options={managementDepartments}
                            disabled={managementContact.frontDeskOrDepartment.department.disabled}
                            uploadValueKey={CompanyAttributeKey.ManagementContact.FrontDeskOrDepartment.Department}
                            value={managementContact.frontDeskOrDepartment.department.value}
                            onBlur={(value) => updateFrontDesk('department', value)}
                            isRequired={managementContact.frontDeskOrDepartment.department.required}
                            isEditMode={isEditMode}
                            originalValue={originalValue?.frontDeskOrDepartment.department.value}
                            isTouched={touchedInputs.frontDeskOrDepartment.department}
                        />
                    </div>
                    {
                        managementContact.frontDeskOrDepartment.department.value === "20" ?
                            <div className="gi-4">
                                <TextFieldWithPriceComponent label="Other"
                                    type="text"
                                    disabled={managementContact.frontDeskOrDepartment.departmentOther.disabled}
                                    uploadValueKey={CompanyAttributeKey.ManagementContact.FrontDeskOrDepartment.DepartmentOther}
                                    value={managementContact.frontDeskOrDepartment.departmentOther.value}
                                    onBlur={(value) => updateFrontDesk('departmentOther', value)}
                                    isRequired={managementContact.frontDeskOrDepartment.departmentOther.required}
                                    isEditMode={isEditMode}
                                    originalValue={originalValue?.frontDeskOrDepartment.departmentOther.value}
                                    isTouched={touchedInputs.frontDeskOrDepartment.departmentOther}
                                    onlyAlphabet={true}
                                />
                            </div> : ""
                    }
                </div>
            </>
        );
    };

    return (
        <div className="item item--scs">
            <div className={isOpen ? "stack stack-m" : "stack h-stack"} style={isOpen ? { width: '100%' } : {}}>
                {renderHeader()}
                {
                    isOpen ? renderForm() : null
                }
            </div>
        </div>
    );
};