import { CartItem } from '../cart/cart.models';
import { AttributeType, SavedCompanyAttachmentsModel, SavedCompanyFinancialModel, SavedCompanyManagementContactModel, SavedCompanyProductsAndServicesModel, SavedCompanySummaryModel } from './saved-company.models';

class SavedCompanyService {
    summaryAttributes = [
        "shortName",
        "fullName",
        "industry",
        "sector",
        "introduction",
        "countryOfFoundation",
        "entityRelationshipStatus",
        "formOfOrganization",
        "foundationYear",
        "identificationNumber",
        "numberOfEmployees",
        "mainAddressCountryId",
        "mainAddressCity",
        "mainAddressZip",
        "mainAddressStreet",
        "mainAddressDoor",
        "mainAddressFloor",
        "mainAddressHouseNumber",
        "businessAddressCountryId",
        "businessAddressCity",
        "businessAddressZip",
        "businessAddressStreet",
        "businessAddressDoor",
        "businessAddressFloor",
        "businessAddressHouseNumber",
        "otherContactDetailsWebsite",
        "otherContactDetailsContactEmailAddress",
        "otherContactDetailsMainTelephoneNumber",
    ];

    productsAndServicesAttributes = [
        "mainServices",
        "mainProducts",
        "brands"
    ];

    individual = [
        "name",
        "position",
        "location",
        "functions",
        "telephoneNumber",
        "emailAddress",
        "linkedIn",
    ];

    frontDeskOrDepartment = [
        "location",
        "telephoneNumber",
        "emailAddress",
        "department",
        "departmentOther",
    ];

    financials = [
        "incomeStatementNetSales",
        "incomeStatementExtraordinaryIncome",
        "incomeStatementFinancialIncome",
        "incomeStatementOtherIncome",
        "incomeStatementMaterials",
        "incomeStatementPersonnel",
        "incomeStatementUtilities",
        "incomeStatementOverhead",
        "incomeStatementFinancialExpenses",
        "incomeStatementSellingAndAdministrative",
        "incomeStatementDepreciationAndAmortization",
        "incomeStatementOtherExpenses",
        "incomeStatementTotalNetRevenues",
        "incomeStatementTotalExpenses",
        "incomeStatementNetIncomeBeforeTaxes",
        "incomeStatementCorporateIncomeTaxes",
        "incomeStatementNetIncomeAfterTaxes",
        "incomeStatementDividensPaid",
        "incomeStatementRetainedEarningsForTheYear",
        "balanceSheetCashAndEquivalents",
        "balanceSheetAccountsReceivable",
        "balanceSheetInventories",
        "balanceSheetOtherCurrentAssets",
        "balanceSheetPropertyPlantAndEquipment",
        "balanceSheetProprietaryRights",
        "balanceSheetAccumulatedDepreciation",
        "balanceSheetOtherFixedAssets",
        "balanceSheetAccountsPayable",
        "balanceSheetShortTermLoans",
        "balanceSheetTaxesPayable",
        "balanceSheetOtherCurrentLiabilities",
        "balanceSheetLongTermLoanes",
        "balanceSheetShareHoldersLoans",
        "balanceSheetDeferredIncomeTaxes",
        "balanceSheetOtherLongTermLiabilites",
        "balanceSheetRegisteredShares",
        "balanceSheetAdditionalPaidInCapital",
        "balanceSheetAccumulatedRetainedEarnings",
        "balanceSheetOtherOwnersEquity",
        "balanceSheetTotalAssets",
        "balanceSheetTotalLiabilites",
        "balanceSheetOwnersEquity",
        "balanceSheetLiabilitiesAndOwnersEquity",
    ];

    compare = (array1: string[], array2: string[]) => {
        const array2Sorted = array2.slice().sort();
        return (array1.length === array2.length && array1.slice().sort().every(function (value, index) {
            return value === array2Sorted[index];
        }));
    }

    isAllSummarySelected = (summary: SavedCompanySummaryModel, cartItems: CartItem[]) => {
        const summaryAllAvailable = savedCompanyService.summaryAttributes
            .reduce((allAvailable, propertyName) => {
                const model = summary as any;
                const isAvailableToBuy = model[propertyName] && (!model[propertyName]?.value || model[propertyName].hasUpdate);

                if (isAvailableToBuy) {
                    allAvailable.push(propertyName);
                }

                return allAvailable;
            }, [] as string[]);

        const summaryCartItems = cartItems.filter(c => c.attributeType === AttributeType.Summary);

        return summaryCartItems.length > 0 && summaryAllAvailable.length === summaryCartItems.length;
    }

    isAllProductsAndServicesSelected = (summary: SavedCompanyProductsAndServicesModel, _cartItems: CartItem[]) => {
        const allAvailable = savedCompanyService.productsAndServicesAttributes
            .reduce((_allAvailable, propertyName) => {
                const model = summary as any;
                const isAvailableToBuy = model[propertyName] && (!model[propertyName]?.value || model[propertyName].hasUpdate);

                if (isAvailableToBuy) {
                    _allAvailable.push(propertyName);
                }

                return _allAvailable;
            }, [] as string[]);

        const cartItems = _cartItems.filter(c => c.attributeType === AttributeType.ProductsAndServices);
        return cartItems.length > 0 && allAvailable.length === cartItems.length;
    }

    isAllManagementContactSelected = (managementContacts: SavedCompanyManagementContactModel[], _cartItems: CartItem[]) => {
        let _allAvailable = [];
        managementContacts
            .forEach((managementContact) => {
                savedCompanyService.individual.forEach((propertyName) => {
                    const model = managementContact.individual as any;
                    const isAvailableToBuy = model[propertyName] && (!model[propertyName]?.value || model[propertyName].hasUpdate);

                    if (isAvailableToBuy) {
                        _allAvailable.push(propertyName);
                    }
                });
                savedCompanyService.frontDeskOrDepartment.forEach((propertyName) => {
                    const model = managementContact.frontDeskOrDepartment as any;
                    const isAvailableToBuy = model[propertyName] && (!model[propertyName]?.value || model[propertyName].hasUpdate);

                    if (isAvailableToBuy) {
                        _allAvailable.push(propertyName);
                    }
                });
            });

        const cartItems = _cartItems.filter(c => c.attributeType === AttributeType.ManagementContact);
        return cartItems.length > 0 && _allAvailable.length === cartItems.length;
    }

    isAllFinancialsSelected = (financials: SavedCompanyFinancialModel[], _cartItems: CartItem[]) => {
        let _allAvailable = [];
        financials
            .forEach((financial) => {
                savedCompanyService.financials.forEach((propertyName) => {
                    const model = financial as any;
                    const isAvailableToBuy = model[propertyName] && (!model[propertyName]?.value || model[propertyName].hasUpdate);

                    if (isAvailableToBuy) {
                        _allAvailable.push(propertyName);
                    }
                });
            });

        const cartItems = _cartItems.filter(c => c.attributeType === AttributeType.Financial);
        return cartItems.length > 0 && _allAvailable.length === cartItems.length;
    }

    isAllDocumentsSelected = (documents: SavedCompanyAttachmentsModel, _cartItems: CartItem[]) => {
        let _allAvailable = [];
        documents.managementStructure
            .forEach((attachment) => {
                const isAvailableToBuy = !attachment.publicUrl;

                if (isAvailableToBuy) {
                    _allAvailable.push('1');
                }
            });
        documents.otherDocument
            .forEach((attachment) => {
                const isAvailableToBuy = !attachment.publicUrl;

                if (isAvailableToBuy) {
                    _allAvailable.push('1');
                }
            });
        documents.standardImages
            .forEach((attachment) => {
                const isAvailableToBuy = !attachment.publicUrl;

                if (isAvailableToBuy) {
                    _allAvailable.push('1');
                }
            });
        const cartItems = _cartItems.filter(c => c.attributeType === AttributeType.Attachment);
        return cartItems.length > 0 && _allAvailable.length === cartItems.length;
    }

}

export const savedCompanyService = new SavedCompanyService();