import React from "react";
import HomePageHeader from './HomePageHeader.container';
import { HomePageContentComponent } from './HomePageContent.component';

export const HomePageComponent: React.FC = () => {
    return (
        <>
            <HomePageHeader/>
            <HomePageContentComponent/>
        </>
    );
};

