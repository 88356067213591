import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SocialRegisterModel, SocialConfirmFields } from './social-register.models';
import queryString from 'query-string';

export interface LinkedInConfirmDispatchProps {
  register(model: SocialConfirmFields): void;
}

const parseRegisterModelFromQuery = (query: string) => {
  const params = queryString.parse(query);

  const model: SocialRegisterModel = {
    firstName: params?.firstName?.toString() || '',
    lastName: params?.lastName?.toString() || '',
    accessToken: params?.accessToken?.toString() || '',
    email: params?.email?.toString() || ''
  };

  return model;
};

const LinkedInConfirmComponent: React.FC<LinkedInConfirmDispatchProps & RouteComponentProps> =
  ({ location, register }) => {
    const registerModel = parseRegisterModelFromQuery(location.search);

    const [password, setPassword] = useState('');

    const onRegister = async (evt: any) => {
      evt.preventDefault();
      evt.stopPropagation();

      const model = {
        accessToken: registerModel.accessToken,
        password
      };

      await register(model);
    };

    return (
      <div className="section section--content">
        <div className="container">
          <div className="grid">
            <div className="gi-4 gi-s-5 gi-e-9 t-gi-4 t-gi-s-3 t-gi-e-7 m-gi-4 m-gi-s-1 m-gi-e-4">
              <div className="stack stack-m">
                <div className="user-form">
                  <form className="stack stack-xl">
                    <div>
                      <h1 className="stack stack-xs ai-c">
                        <span className="logo logo--full logo--grey logo--color">
                          <svg className="logo__logo">
                            <use xlinkHref="#logo-full-grey-color" />
                          </svg>
                          <span className="visually-hidden">Gravity</span>
                        </span>
                        <span className="title title-m">LinkedIn confirm</span>
                      </h1>
                    </div>
                    <fieldset>
                      <div className="stack stack-xs">
                        <div>
                          <p className="label">First Name</p>
                          <div className="field field--input">
                            <input className="field__field" name="firstName" id="firstName"
                              defaultValue={registerModel.firstName} disabled={true} />
                          </div>
                        </div>
                        <div>
                          <p className="label">Last Name</p>
                          <div className="field field--input">
                            <input className="field__field" name="lastName" id="lastName"
                              defaultValue={registerModel.lastName} disabled={true} />
                          </div>
                        </div>
                        <div>
                          <p className="label">E-mail address</p>
                          <div className="field field--input">
                            <input className="field__field" name="email" id="email"
                              defaultValue={registerModel.email} disabled={true} />
                          </div>
                        </div>
                        <div>
                          <p className="label">Current password</p>
                          <div className="field field--input">
                            <input className="field__field" name="password" id="password"
                              value={password} onChange={(evt) => setPassword(evt.target.value)} />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div className="buttons">
                      <div className="stack stack-m">
                        <div>
                          <div className="stack stack-xs">
                            <button className="button" onClick={onRegister}>
                              <span className="button__inner">
                                <span>Register</span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div >
        </div >
      </div >
    );
  };

export const LinkedInConfirmPage = withRouter(LinkedInConfirmComponent);