import React from "react";
import { RouteComponentProps } from 'react-router-dom';
import { TermsAndConditionsComponent } from "../auth/TermsAndConditionsComponent";

export const TermsAndConditionsPage: React.FC<RouteComponentProps> = ({
    history
}) => {
    return (
        <>
            <div className="section section--header">
                <div className="container">
                    <div className="stack stack-m">
                        <div className="grid">
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="h-stack h-stack-xs jc-sb m-fd-c">
                                    <div>
                                        <h1 className="title title-l">Terms and Conditions of Service</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="section section--content faq">
                <div className="container">
                    <TermsAndConditionsComponent />
                </div>
            </div>
        </>
    );
};