import React from 'react';

interface CreditBadgeComponentProps {
    price: number;
    hasValue?: boolean;
}

export const CreditBadgeComponent: React.FC<CreditBadgeComponentProps> = ({ price, hasValue }) => {
    const brandColorStyle: any = { "--background-color": "var(--brand-color)" };
    const disabledColorStyle: any = { "--background-color": "var(--disabled-color)" };

    return (
        <span className="icon-chip icon-chip--label-left " id="" style={hasValue ? brandColorStyle : disabledColorStyle}>
            <span className="icon-chip__inner">
                <svg className="icon icon--m "><use xlinkHref="#icon-gravity-credit" /></svg>
                <span className="label">{price}</span>
            </span>
        </span>
    );
};