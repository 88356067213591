import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { InvitedWorkspaceModel, WorkspaceDeleteConfirmationModel } from '../workspace.models';
import { FullPageLoadingComponent } from '../../FullpageLoading.component';
import { popupService } from '../../popup/popup.service';
import { useDispatch } from 'react-redux';
import { workspaceLoadingAction } from '../workspace.actions';
import { accountService } from '../../account-settings/account.service';
import { PopupDialogType } from '../../popup/popup.models';
import { logoutAction } from '../../auth/authentication.actions';
import { popupSetModelAction } from '../../popup/popup.actions';

export interface WorkspaceDeleteConfirmComponentStateProps {
    isLoading: boolean;
    workspaces?: WorkspaceDeleteConfirmationModel[];
}

export interface WorkspaceDeleteConfirmComponentDispatchProps {
    loadWorkspaces: () => Promise<void>;
}

const WorkspaceDeleteConfirmComp: React.FC<WorkspaceDeleteConfirmComponentStateProps & WorkspaceDeleteConfirmComponentDispatchProps & RouteComponentProps> = ({
    isLoading,
    workspaces,
    loadWorkspaces,
    history
}) => {
    useEffect(() => {
        loadWorkspaces();
    }, [loadWorkspaces]);

    const dispatch = useDispatch();

    if (isLoading || !workspaces) {
        return <FullPageLoadingComponent />;
    }

    const workspaceList = workspaces.map(w => (
        <div className="gi-12 grid row" key={w.workspaceId}>
            <div className="gi-4 column">
                {w.workspaceName}
            </div>
            <div className="gi-4 column">
                {w.numberOfAvailableCredits}
            </div>
            <div className="gi-4 column">
                {w.savedDownloadsOrSearchResults ? "Yes" : "No"}
            </div>
        </div>
    ));

    const onNo = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();

        history.push('/');
    };

    const onYes = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();

        popupService.hidePopup();

        dispatch(workspaceLoadingAction());
        accountService.deleteAccount().then(response => {
            if (response.status === 200) {
                dispatch(popupSetModelAction({
                    title: 'Account has been successfully deleted',
                    body: (
                        <div>
                            Check <a href="/privacy-policy#section7">Section 7</a> of the Privacy Policy for Gravity’s data retention procedures.
                        </div>
                    ),
                    type: PopupDialogType.Dialog,
                    okButtonText: 'Ok',
                    okButtonAction: async (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        popupService.hidePopup();
                        dispatch(logoutAction());
                    }
                }));
                popupService.showPopup();
            }
        })
    };

    return (
        <div className="section section--content">
            <div className="container">
                <div className="grid">
                    <div className="gi-12">
                        <div className="stack stack-m">
                            <div className="user-form">
                                <form className="stack stack-xl">
                                    <h1 className="stack stack-xs">
                                        <span className="title title-m">Confirm your Membership Cancellation</span>
                                    </h1>
                                    <fieldset>
                                        <div className="stack stack-xs">
                                            You are the administrator of the below workspace(s):
                                        </div>
                                    </fieldset>
                                    <div className="workspace-confirmation-table">
                                        <div className="gi-12 grid row">
                                            <div className="gi-4 column title">
                                                Workspace Name
                                            </div>
                                            <div className="gi-4 column title">
                                                Number of Available Credits
                                            </div>
                                            <div className="gi-4 column title">
                                                Saved Downloads and/or Search Results
                                            </div>
                                        </div>
                                        {workspaceList}
                                    </div>
                                    <div style={{ 'textAlign': 'justify' }}>
                                        You may delete any or all these workspaces prior to canceling your Gravity membership. If
                                        you cancel your membership now, all other members, if any, of each of the above workspaces
                                        will be notified and offered the opportunity to become the administrator of the workspace
                                        they are members of. The person clicks on accepting to become the administrator of the
                                        workspace first, will be appointed to be your successor in that workspace. If no one responds
                                        or accepts the appointment within 72 hours, the workspace will be automatically deleted, and
                                        all credits, search results and downloads will be permanently lost.
                                    </div>
                                    <div>
                                        Do you still want to cancel your Gravity membership?
                                    </div>
                                    <div className="h-stack h-stack-xs m-fd-cr">
                                        <button className="button" onClick={(evt) => onYes(evt)}>
                                            <span className="button__inner">
                                                <span>Yes</span>
                                            </span>
                                        </button>
                                        <button className="button button--terminate " onClick={(evt) => onNo(evt)}>
                                            <span className="button__inner">
                                                <span>No</span>
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
};

export const WorkspaceDeleteConfirmComponent = withRouter(WorkspaceDeleteConfirmComp);

