import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Store } from "redux";
import { gravityUseAsSelector } from "./auth/authentication.selectors";
import { AppState } from "./root.reducer";

const USE_WORKSPACE_HEADER = 'X-Workspace-Id';

export const axiosInstance: AxiosInstance = axios.create({
    withCredentials: true
});

export const registerAxiosInterceptor = (store: Store<AppState>) => {
    axiosInstance.interceptors.request.use(workspaceRequestInterceptor(store));
};

const workspaceRequestInterceptor = (store: Store<AppState>) => (config: AxiosRequestConfig) => {
    const useGravityAs = gravityUseAsSelector(store.getState());

    if (useGravityAs) {
        config.headers[USE_WORKSPACE_HEADER] = useGravityAs;
    } else {
        delete config.headers[USE_WORKSPACE_HEADER];
    }

    return config;
}