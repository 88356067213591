import { CountryModel } from "./meta.models";
import { MetaActions, MetaActionTypes } from './meta.actions';

export interface MetaState {
    isCountriesLoading: boolean;
    countries?: CountryModel[];
    companyCount?: number;
    isMobilMenuOpen?: boolean;
}

const initialState: MetaState = {
    isCountriesLoading: false,
    countries: undefined,
    companyCount: undefined,
    isMobilMenuOpen: false,
};

export const metaReducer = (state: MetaState = initialState, action: MetaActions): MetaState => {
    switch (action.type) {
        case MetaActionTypes.LOAD_COUNTRIES: {
          return {
            ...state,
            isCountriesLoading: true,
            countries: undefined
          };
        }
        case MetaActionTypes.LOAD_COUNTRIES_SUCCESS: {
          return {
            ...state,
            isCountriesLoading: false,
            countries: action.payload
          };
        }
        case MetaActionTypes.LOAD_COMPANY_COUNT_SUCCESS: {
          return {
            ...state,
            companyCount: action.payload,
          };
        }
        case MetaActionTypes.LOAD_COUNTRIES_ERROR: {
          return {
            ...state,
            isCountriesLoading: false,
            countries: undefined
          };
        }
        case MetaActionTypes.MOBILE_MENU_CHANGE: {
          return {
            ...state,
            isMobilMenuOpen: action.payload
          };
        }
        default:
            return state;
    }
};