import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { AppRoutes } from '../routes';
import UserBadge from "./UserBadge.container";

export interface HeaderStateProps {
    isLoggedIn: boolean;
}

export interface HeaderDispatchProps {
    openMobilMenu: () => void;
    resetCurrentPage: () => void;
}

const HeaderComp: React.FC<HeaderStateProps & HeaderDispatchProps & RouteComponentProps> = ({ isLoggedIn, resetCurrentPage, openMobilMenu, history }) => {
    const [scrolled, setScrolled] = useState(false);

    const scrolledProp = {
        scrollable: 'scrollable',
        scrolled: scrolled ? 'scrolled' : null
    };

    const handleScroll = () => {
        const doc = document.documentElement;
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top >= 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    const onClick = (event: any, route: string) => {
        resetCurrentPage();
        event.preventDefault();
        event.stopPropagation();
        history.push(route);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onMenu = () => {
        openMobilMenu();
    };

    return (
        <nav className="nav" id="nav" {...scrolledProp}>
            <div className="container">
                <div className="gravity_menu h-stack h-stack-l ai-c no-tablet">
                    <Link to="/" style={{ lineHeight: '25px' }}>
                        <span className="logo logo--full logo--white logo--color">
                            <svg className="logo__logo">
                                <use xlinkHref="#logo-full-white-color" />
                            </svg>
                            <span className="visually-hidden">Gravity</span>
                        </span>
                    </Link>
                    <div className="nav__items h-stack h-stack-m">
                        <Link to={AppRoutes.HowItWorks}>How it Works</Link>
                        <Link to="/search">Search for Companies</Link>
                        {isLoggedIn ? <a onClick={(e) => onClick(e, '/saved-companies')} href="/">Search Results</a> : null}
                        <Link to="/contact-us">Contact Us</Link>
                    </div>
                    {
                        isLoggedIn ? (
                            <div className="split-right">
                                <UserBadge />
                            </div>
                        ) : (
                            <div className="account split-right">
                                <div className="h-stack h-stack-m ai-c">
                                    <Link to="/register">Register</Link>
                                    <Link className="button button--secondary" to="/login">
                                        <span className="button__inner">
                                            <span>Log In</span>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="h-stack ai-c jc-sb no-desktop">
                    <button
                        className="button-icon"
                        aria-controls="menu"
                        onClick={() => onMenu()}
                    >
                        <span className="h-stack h-stack-xs button__inner">
                            <svg className="icon icon--m">
                                <use xlinkHref="#icon-menu" />
                            </svg>
                            <span className="label">Menu</span>
                        </span>
                    </button>
                    <span className="logo logo--s logo--white logo--color" onClick={() => window.location.href = "/"}>
                        <svg className="logo__logo">
                            <use xlinkHref="#logo-s-white-color" />
                        </svg>
                        <span className="visually-hidden">Gravity</span>
                    </span>
                    {
                        isLoggedIn ? (
                            <UserBadge />
                        ) : (
                            <Link to="/login" className="button button--secondary" >
                                <span className="button__inner">
                                    <span>Log In</span>
                                </span>
                            </Link>
                        )
                    }
                </div>
            </div >
        </nav >
    );
};


export const Header = withRouter(HeaderComp);