import { connect } from 'react-redux';
import { AppState } from '../root.reducer';
import { SavedCompanyComponent, SavedCompanyStateProps, SavedCompanyDispatchProps } from './SavedCompany.component';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { companyService } from './company.service';
import { isSavedCompaniesLoadingSelector, savedSearchIdSelector } from '../saved-companies/saved-companies.selectors';
import { discountSelector, savedCompanySelector } from './company.selectors';
import { discountLoadedAction, resetSavedCompanyAction, savedCompanyLoadedAction, savedCompanyLoadingAction } from './saved-company.actions';
import { gravityUseAsSelector } from '../auth/authentication.selectors';
import { CartItem } from '../cart/cart.models';
import { addPropertyToCartAction } from '../cart/cart.actions';

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<{ id: string }>): SavedCompanyStateProps => ({
    companyId: match.params.id as any as number,
    isLoading: isSavedCompaniesLoadingSelector(state),
    savedSearchId: savedSearchIdSelector(state),
    savedCompany: savedCompanySelector(state),
    useGravityAs: gravityUseAsSelector(state),
    discount: discountSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps<{ id: string }>): SavedCompanyDispatchProps => ({
    loadCompany: async (id: number, useGravityAs?: number) => {
        dispatch(savedCompanyLoadingAction());
        if (useGravityAs) {
            const response = await companyService.getSavedWorkspaceCompany(id);
            if (response.status !== 200) {
                history.push('/');
                return;
            }
            dispatch(savedCompanyLoadedAction(response.data));
        } else {
            const response = await companyService.getSavedUserCompany(id);
            if (response.status !== 200) {
                history.push('/');
                return;
            }
            dispatch(savedCompanyLoadedAction(response.data));
        }
    },
    loadDiscount: async () => {
        const discount = await companyService.getDiscount();
        dispatch(discountLoadedAction(discount));
    },
    resetCompany: () => {
        dispatch(resetSavedCompanyAction());
    },
    addPropertiesToCart: (items: CartItem[]) => {
        items.forEach(item => dispatch(addPropertyToCartAction(item)));
    }
});

export default withRouter(
    connect<SavedCompanyStateProps, SavedCompanyDispatchProps, RouteComponentProps<{ id: string }>, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(SavedCompanyComponent)
);