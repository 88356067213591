import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  WorkspaceModel,
  SaveWorkspaceFormModel,
  UpdateWorkspaceModel,
  InviteUserToWorkspaceModel,
} from "./workspace.models";
import { User } from "../auth/authentication.models";
import { WorkspaceFormComponent } from "./WorkspaceForm.component";
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import { AppRoutes } from '../routes';
import { popupSetModelAction } from '../popup/popup.actions';
import { PopupDialogType } from '../popup/popup.models';
import { popupService } from '../popup/popup.service';
import { useDispatch } from 'react-redux';
import { Utils } from '../utils/utils';

export interface WorkspaceSettingsComponentProps {
  workspace: WorkspaceModel;
  user: User;
  updateWorkspace(workspace: UpdateWorkspaceModel): Promise<void>;
  deleteWorkspace(workspaceId: number): Promise<void>;
  removeTeamMember(workspaceId: number, email: string): Promise<void>;
  cancelMembership(): Promise<void>;
  removeInvite(workspaceId: number, email: string): Promise<void>;
  inviteUser(workspaceId: number, user: InviteUserToWorkspaceModel): Promise<void>;
  uploadCredit(
    workspaceId: number,
    amount: number,
    creditValue: number
  ): Promise<void>;
}

const WorkspaceSettingsComp: React.FC<
  WorkspaceSettingsComponentProps & RouteComponentProps
> = ({
  workspace,
  user,
  history,
  updateWorkspace,
  deleteWorkspace,
  removeTeamMember,
  cancelMembership,
  removeInvite,
  inviteUser,
  uploadCredit,
}) => {
    const dispatch = useDispatch();
    const [inviteEmail, setInviteEmail] = useState<string>('');
    const [inviteEmailTouched, setInviteEmailTouched] = useState(false);
    const [creditQuantity, setCreditQuantity] = useState(1000);
    const [creditValue, setCreditValue] = useState(creditQuantity * 0.01);

    if (!workspace || !workspace.id) {
      return <FullPageLoadingComponent />;
    }


    const onRemoveTeamMember = async (evt: any, email: string) => {
      evt.preventDefault();
      evt.stopPropagation();

      await removeTeamMember(workspace.id!, email);
    };

    const onCancelMembership = async (evt: any) => {
      evt.preventDefault();
      evt.stopPropagation();

      dispatch(popupSetModelAction({
        title: 'Are you sure, you want to cancel this workspace membership?',
        type: PopupDialogType.Dialog,
        okButtonText: 'Yes',
        noButtonText: 'Cancel',
        okButtonAction: async (evt) => {
          evt.preventDefault();
          evt.stopPropagation();

          await cancelMembership();

          popupService.hidePopup();
        },
        cancelButtonAction: (evt) => {
          evt.preventDefault();
          evt.stopPropagation();

          popupService.hidePopup();
        }
      }));
      popupService.showPopup();
    };

    const onRemoveInvite = async (evt: any, email: string) => {
      evt.preventDefault();
      evt.stopPropagation();

      await removeInvite(workspace.id!, email);
    };

    const onSave = async (form: SaveWorkspaceFormModel) => {
      const model = {
        ...form,
        id: workspace.id as number,
      };

      await updateWorkspace(model);
    };

    const emailHasError = () => {
      return (
        !inviteEmail ||
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inviteEmail)
      );
    };

    const onInvite = async (evt: any) => {
      evt.preventDefault();
      evt.stopPropagation();

      if (!inviteEmail || emailHasError()) {
        setInviteEmailTouched(true);
        return;
      }

      await inviteUser(workspace.id!, {
        email: inviteEmail,
      });
    };

    const onUploadBalance = async (evt: any) => {
      evt.preventDefault();
      evt.stopPropagation();

      if (workspace.id) {
        await uploadCredit(workspace.id, creditValue, creditQuantity);
      }
    };

    const onCreditHistory = () => {
      history.push(AppRoutes.CreditHistory);
    };

    const enviteEmailProps =
      inviteEmailTouched && emailHasError() ? { error: "error" } : {};

    const teamMembers = workspace.users.map((teamMember) => (
      <li key={teamMember.email}>
        <div className="item item--usr">
          <div className="h-stack h-stack-xs ai c">
            <div className="col col--icon col--image">
              <svg className="icon icon--l">
                <use xlinkHref="#icon-users" />
              </svg>
            </div>
            <div className="col col--name">
              <p>{teamMember.name} {workspace.isCurrentUserCreator ? `(${teamMember.spentCredits} credits spent)` : null}</p>
              <p className="annotation">{teamMember.email}</p>
            </div>
            <div className="col col--icon">
              {workspace.isCurrentUserCreator ? (
                <button
                  className="icon-button"
                  id=""
                  onClick={(evt) => onRemoveTeamMember(evt, teamMember.email)}
                >
                  <span className="icon-button__inner">
                    <svg className="icon icon--m">
                      <use xlinkHref="#icon-close" />
                    </svg>
                    <span className="visually-hidden">Remove</span>
                  </span>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </li>
    ));

    const team =
      workspace.users.length > 0 ? <ul className="list">{teamMembers}</ul> : "";

    const invitedUserList = workspace.invitedUsers.map((teamMember) => (
      <li key={teamMember.email}>
        <div className="item item--usr">
          <div className="h-stack h-stack-xs ai c">
            <div className="col col--icon col--image">
              <svg className="icon icon--l">
                <use xlinkHref="#icon-users" />
              </svg>
            </div>
            <div className="col col--name">
              <p className="annotation">{teamMember.email}</p>
              <p className="annotation">
                Invite available {teamMember.inviteAvailable}
              </p>
            </div>
            <div className="col col--icon">
              {workspace.isCurrentUserCreator ? (
                <button
                  className="icon-button"
                  id=""
                  onClick={(evt) => onRemoveInvite(evt, teamMember.email)}
                >
                  <span className="icon-button__inner">
                    <svg className="icon icon--m">
                      <use xlinkHref="#icon-close" />
                    </svg>
                    <span className="visually-hidden">Remove</span>
                  </span>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </li>
    ));

    const invitedUsers =
      workspace.invitedUsers.length > 0 ? (
        <ul className="list">{invitedUserList}</ul>
      ) : (
        ""
      );

    return (
      <div className="container">
        <div className="stack stack-m">
          <div className="grid">
            <div className="gi-12">
              <div className="h-stack h-stack-xs jc-sb m-fd-cr">
                <p className="title title-l">Workspace Settings</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="gi-4 col col--left">
              <form className="stack stack-m">
                <WorkspaceFormComponent
                  workspace={workspace}
                  deleteWorkspace={deleteWorkspace}
                  saveWorkspace={(form) => onSave(form)}
                />
              </form>
              <div style={{ marginTop: '15px' }}>
                <button className="button button--secondary" onClick={onCreditHistory} >
                  <span className="button__inner">
                    <span>Credit History</span>
                  </span>
                </button>
              </div>
            </div>
            <div className="gi-4 col col--center">
              <div className="stack stack-m">
                <header className="col-header">
                  <div className="stack stack-xxs">
                    <p className="title title-m">Team Members</p>
                    <p className="annotation">
                      {workspace.users.length === 0
                        ? "No team members in your Workspace" : null}
                      {workspace.users.length === 1
                        ? "1 team member in your Workspace"
                        : workspace.users.length > 1 ? workspace.users.length + " team members in your Workspace" : null}
                    </p>
                  </div>
                </header>
                {team}
                {workspace.isCurrentUserCreator ? (
                  <>
                    <hr />
                    <div className="stack stack-m">
                      <header className="col-header">
                        <div className="stack stack-xs">
                          <p className="title title-m">Invite Members</p>
                          <div className="h-stack h-stack-xs ai-fe">
                            <div>
                              <label className="label" htmlFor="invite-email">
                                Email Address
                              </label>
                              <div
                                className="field field--input"
                                {...enviteEmailProps}
                              >
                                <input
                                  className="field__field"
                                  value={inviteEmail}
                                  onChange={(evt) =>
                                    setInviteEmail(evt.target.value)
                                  }
                                  name=""
                                  id="invite-email"
                                  placeholder="Enter email"
                                />
                              </div>
                            </div>
                            <button
                              className="button button--secondary"
                              onClick={(evt) => onInvite(evt)}
                            >
                              <span className="button__inner">
                                <span>Invite</span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </header>
                    </div>
                    <hr />
                  </>
                ) : (null)}
                <header className="col-header">
                  <div className="stack stack-xxs">
                    <p className="title title-m">Invited Users</p>
                    <p className="annotation">
                      {workspace.invitedUsers.length === 0
                        ? "There are no" : workspace.invitedUsers.length}
                      {workspace.invitedUsers.length === 1
                        ? " invited user "
                        : " invited users "}
                      for your workspace
                    </p>
                  </div>
                </header>
                {invitedUsers}
                <hr />
                <div>
                  <button className="button button--terminate" onClick={(evt) => onCancelMembership(evt)} disabled={workspace.isCurrentUserCreator}>
                    <span className="button__inner">
                      <span>Cancel this Workspace Membership</span>
                    </span>
                  </button>
                </div>
                {
                  workspace.isCurrentUserCreator ? <span>You are the workspace admin, you cannot cancel this membership.</span> : null
                }
              </div>
            </div>
            <div className="gi-4 col col--right">
              <div className="stack stack-m">
                <header className="col-header">
                  <div className="stack stack-xxs">
                    <p className="title title-m">Shared Credit Balance</p>
                    <p className="annotation">
                      Sharing with {workspace.users.length} members
                    </p>
                  </div>
                </header>
                <div>
                  <div className="stack stack-xs">
                    <span className="icon-header icon-header--header-right " id="">
                      <span className="icon-header__inner">
                        <svg className="icon icon--l ">
                          <use xlinkHref="#icon-gravity-credit" />
                        </svg>
                        <span className="title title-l">{Utils.formatNumber(workspace.creditCount)}</span>
                      </span>
                    </span>
                  </div>
                </div>
                <hr />
                {workspace.isCurrentUserCreator ? (
                  <div>
                    <div className="stack stack-xs">
                      <p className="title title-s">
                        Buy more credit for you workspace
                      </p>
                      <p>
                        Top up you credit balance with your debit or credit card.
                      </p>
                      <div className="h-stack h-stack-xs ai-fe">
                        <div className="quantity">
                          <div className="field field--input">
                            <input
                              className="field__field"
                              type="number"
                              value={creditQuantity}
                              onChange={(evt) => {
                                setCreditQuantity(Number(evt.target.value));
                                setCreditValue(Number((Number(evt.target.value) * 0.01).toFixed(2)));
                              }}
                              name="worspace_credit_quantity"
                              id="worspace_credit_quantity"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="fill annotation">EUR 0.01 / Credit</p>
                      </div>
                      <div>
                        <button className="button" onClick={(evt) => onUploadBalance(evt)} disabled={creditValue < 5}>
                          <span className="button__inner">
                            <span>Pay EUR {Utils.formatNumber(creditValue)} + VAT</span>
                          </span>
                        </button>
                      </div>
                      {creditValue < 5 ?
                        <div style={{ marginTop: 0 }}>
                          <span style={{ fontSize: '12px' }}>Min. upload is 5 EUR</span>
                        </div>
                        : null}
                      <div className="cards">
                        <div className="h-stack h-stack-xxs ai-c">
                          <svg className="icon icon--m">
                            <use xlinkHref="#icon-bank-card-visa" />
                          </svg>
                          <svg className="icon icon--m">
                            <use xlinkHref="#icon-bank-card-mastercard" />
                          </svg>
                          <img src="/assets/img/amex-24.png" alt="Amex" />
                          <img src="/assets/img/paypal-3-24.png" alt="PayPal" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (null)}
                <div>
                  <div className="stack stack-xs">
                    <p className="title title-s">Earn credit</p>
                    <div>
                      <button
                        className="button button--secondary"
                        onClick={() => history.push("/upload-company")}
                      >
                        <span className="button__inner">
                          <span>Upload a Company</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  };

export const WorkspaceSettingsComponent = withRouter(WorkspaceSettingsComp);

