import React from "react";
import { useSelector } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import "./howitworks.scss";

export const HowItWorksComponent: React.FC<RouteComponentProps> = ({
  history,
}) => {
  const useGravityAs = useSelector(state => (state as any).authentication.useGravityAs);

  let account = '';
  if (useGravityAs) {
    account = '/workspace/' + useGravityAs;
  } else {
    account = '/account';
  }
  return (
    <>
      <div className="section section--header">
        <div className="container">
          <div className="stack stack-m">
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="h-stack h-stack-xs jc-sb m-fd-c">
                  <div>
                    <h1 className="title title-l">How it Works</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section--content how-it-works">
        <div className="container">
          <div className="stack stack-xl">
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-l">
                    Crowdsourced Global Company Database
                  </h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <p>
                    Gravity is an ever-growing crowdsourced global company
                    database. Users can earn valuable credits for uploading
                    companies they know, and use those credits to download
                    information on other companies. This assures that the
                    database is continually updated and expanding. Company
                    information is an essential tool for direct marketing, and
                    it also helps assessing potential customers and suppliers.
                  </p>
                </div>
              </div>
              <div className="gi-6 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <div>
                    Advantages of using Gravity:
                  </div>
                  <div className="advantages-list">
                    <ul>
                      <li>No annual fee; pay as you go</li>
                      <li>No credit card is required</li>
                      <li>Free registration with sign-up bonuses</li>
                      <li>Use it absolutely free if you upload data</li>
                      <li>Only pay for information you need</li>
                      <li>Constantly updated, expanding database</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h2 className="title title-m">
                    How does Gravity differ from other databases?
                  </h2>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <p>
                    Gravity allows you to download information piece by piece,
                    so you only have to spend credits on the information
                    you really need. For example, you can just download
                    marketing expenses or inventory from three years ago, or find
                    the telephone number of the vice president for finance.
                    Gravity lists companies from all over the world. If you
                    cannot find a company today, check back in a few weeks.
                    Chances are, it will be uploaded by someone soon.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h2 className="title title-m">Cost</h2>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <p>
                    Gravity has no annual fees, while most other professional
                    databases will charge you the price of a new car each year.
                    Downloading is also cost effective; each piece of
                    information costs a just a few cents, which you can also pay
                    with credits you earned for uploading. You will also receive
                    an initial free credit bonus from Gravity upon joining or
                    inviting another user. If you need more credits, just use
                    your bank card or PayPal to top up your credit balance.
                  </p>
                  <button
                    className="button"
                    onClick={() => history.push("/upload-company")}
                    id="register-anchor"
                  >
                    <span className="button__inner">
                      <span>Get started with free credits</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-3 separator"></div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-l">Registration</h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <p>
                    Registration is absolutely free. The following easy steps will get you started:
                  </p>
                </div>
              </div>
              <div className="gi-8 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <div className="registration-list">
                    <ul>
                      <li>
                        <div className="listing">
                          <span>1)</span>
                          <p>
                            Fill out the registration form. On certain browsers, pop-up windows will have to be enabled for the registration window to appear.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="listing"><span>2)</span><p>Make sure to fill in all boxes marked with a star. (*)</p></div>
                      </li>
                      <li>
                        <div className="listing"><span>3)</span><p>Password must contain at least 8 characters, including one upper case letter and a special symbol.</p></div>
                      </li>
                      <li>
                        <div className="listing"><span>4)</span><p>The system will ask you to click on the “I am not a Robot” icon, and for
                          added security, click on the pictures satisfying the criteria written above in the
                          same window.</p></div>
                      </li>
                      <li>
                        <div className="listing"><span>5)</span><p>Click on "<a href="/register">Register</a>"</p></div>
                      </li>
                      <li>
                        <div className="listing"><span>6)</span><p>Accept the <a href="/terms-and-conditions">Terms and Conditions</a> (T&C) by clicking the two boxes at the bottom of the T&C window.</p></div>
                      </li>
                      <li>
                        <div className="listing"><span>7)</span>
                          <p>You may also use your LinkedIn or Google account to sign up</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <p>Upon completing the registration, you will receive your initial signup bonus credits.
                    You will also receive additional credits for filling in the optional fields on the Registration Form.</p>
                </div>
              </div>
              <div className="gi-4 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <div className="video-container">
                    <iframe
                      title="sample"
                      src="https://www.youtube.com/embed/jZX2eQm0Bww"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-3 separator"></div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-l">Credit System</h1>
                </div>
              </div>
              <div className="gi-8 t-gi-8 m-gi-4">
                <div className="gi-12 t-gi-8 m-gi-4">
                  <div className="stack stack-s ai-fs">
                    <p>
                      Credits are the units of value and payment on Gravity.
                      Credits can be received as signup and other bonuses,
                      earned on uploads, or purchased using a major debit/credit card
                      or PayPal.
                    </p>
                    <p>
                      Purchasing Credits:
                    </p>
                  </div>
                </div>
              </div>
              <div className="gi-8 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <div className="registration-list">
                    <ul>
                      <li>
                        <div className="listing">
                          <span>1)</span>
                          <p>
                            Go to your account, click on <a href={account}>Earn or buy credits</a>.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="listing"><span>2)</span><p>
                          Fill in the number of credits you want to purchase. Each credit has the value of 1 Euro Cent.
                          The minimum purchase is 500 credits costing 5 Euros, approximately 6 US dollars plus applicable VAT taxes depending on the country where you or your company is located.</p></div>
                      </li>
                      <li>
                        <div className="listing"><span>3)</span><p>
                          Upon completing your credit purchase, fill out the Billing Information Sheet.
                          Your entries will determine if you have to be charged European Value Added Tax (VAT), and it also serves as an input for the invoice to be issued. VAT might be applicable based on your tax residency and whether you are making the purchase as a private individual or a company.</p></div>
                      </li>
                      <li>
                        <div className="listing"><span>4)</span><p>Click on Continue to Payment,</p></div>
                      </li>
                      <li>
                        <div className="listing"><span>5)</span><p>You will be redirected to PayPal, the sole payment processor of Gravity, also processing credit and debit card payments.
                          PayPal is using a secure system specially developed for payment processing,</p></div>
                      </li>
                      <li>
                        <div className="listing"><span>6)</span><p>Select the method of payment, PayPal or debit/credit card,</p></div>
                      </li>
                      <li>
                        <div className="listing"><span>7)</span>
                          <p>If PayPal is selected, enter your login details or register as a new PayPal user,</p>
                        </div>
                      </li>
                      <li>
                        <div className="listing"><span>8)</span>
                          <p>When selecting debit/credit card, fill in your card details, including the card billing address, telephone number and the three-digit security code.
                            As your card is processed by PayPal, your card details will not be seen by Gravity.</p>
                        </div>
                      </li>
                      <li>
                        <div className="listing">
                          <span>9)</span>
                          <p>Your purchase will show up in your Credit History listing all changes in your credit balance including:
                          </p>
                        </div>
                        <div className="credit-system-list">
                          <ul style={{ listStyleType: 'disc' }}>
                            <li>Credit purchases</li>
                            <li>Registration and other credit bonuses</li>
                            <li>Credits earned for uploading</li>
                            <li>Credits spent on searches and downloads</li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="gi-4 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <div className="video-container">
                    <iframe
                      title="sample"
                      src="https://www.youtube.com/embed/YU5_5GlLhoI"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-3 separator"></div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-l">Search for Companies</h1>
                </div>
              </div>
              <div className="gi-8 t-gi-8 m-gi-4 right-inner">
                <div className="gi-12 t-gi-8 m-gi-4">
                  <div className="stack stack-s ai-fs">
                    <p style={{ fontWeight: "bold" }}>Quick search</p>
                    <p>
                      An easy-to-use search engine is placed directly on the
                      landing page. Select industry, country and the range of
                      revenues to create a company list based on these
                      parameters.
                    </p>
                    <p>
                      Gravity uses Industries as main categories and Sub-Sectors for more specific activities.
                    </p>
                    <p style={{ fontWeight: "bold" }}>Choosing the Data Set you are searching:</p>
                    <p>
                      Select the searched data set, whether you are running a search only on the latest entries or on all available data.
                    </p>
                    <p>
                      Gravity stores up to seven entries of the same piece of information if they were
                      already modified after being originally entered, including the latest publicly available
                      information, data uploaded by an employee or an officer in addition to the last and
                      the original entry that was made.
                    </p>
                    <p>
                      You may choose to run a search on all stored entries or just on the entries that were
                      last uploaded. Even though Gravity requires users to only upload true and valid data,
                      there is still no guarantee that the entry last uploaded was correct. Running the
                      search on a larger database will increases your chances of finding the company you
                      are looking for, but the list will be probably longer, and you have to spend more
                      credits on downloading it.
                    </p>
                    <p style={{ fontWeight: "bold" }}>Advanced Search</p>
                    <p>
                      You may want to narrow your search based on additional
                      criteria. Click on "<a href="/search/advanced">Advanced Search</a>" to add more search
                      parameters or relevant keywords for a detailed search.
                    </p>
                    <p>
                      When using keywords, hits will contain all keywords you
                      have entered, narrowing the list you would be getting
                      otherwise. Once you ran either a Quick Search or Advanced
                      Search, Gravity will show you the number of available
                      companies satisfying your criteria, and the number of
                      credits you would spend on downloading or viewing the
                      list.
                    </p>
                    <p>You will only see the actual company names once you purchased the list.
                      If you feel the list is too
                      long or prefer spending less credits, you may repeatedly
                      narrow your search until you arrive at the right list
                      length and credit amount.
                    </p>
                    <p style={{ fontWeight: "bold" }}>Searching for a Specific Company</p>
                    <p>
                      If you were looking for a particular company, and it was not in your Search Results, it has probably not yet been uploaded to Gravity.
                      Companies are uploaded on a regular basis.  Check back in a few weeks; chances are it will be uploaded by someone soon.
                    </p>
                    <p>
                      If you need information on this company sooner, you may upload its name yourself to encourage the company to complete its page.
                    </p>
                    <ul className="specific-company-list1">
                      <li>Click on Upload a Company from the main menu. </li>
                      <li>Fill in the required boxes marked with a star  </li>
                      <ul>
                        <li>a.	Enter the company's full name</li>
                        <li>
                          b.	Select the industry from the list</li>
                        <li>
                          c.	Select the country of foundation or registration</li>
                      </ul>
                      <li>Fill in an email address and/or a telephone number for the company.</li>
                    </ul>
                    <p>
                      Whenever new contact information is entered, the company or the person the contact details belong to will be notified by email or text message. He or she will have an opportunity to review the company page, and complete it with additional information.
                    </p>
                    <p>
                      Next time you run a Search, chances are you will already find this company and the information you have been looking for.
                    </p>
                  </div>
                </div>
              </div>
              <div className="gi-4 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <div className="video-container">
                    <iframe
                      title="sample"
                      src="https://www.youtube.com/embed/qWG5FT0F-x8"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-3 separator"></div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-l">Uploading a Company</h1>
                </div>
              </div>
              <div className="gi-8 t-gi-8 m-gi-4 right-inner">
                <div className="gi-12 t-gi-8 m-gi-4">
                  <div className="stack stack-s ai-fs">
                    <p>Gravity can be used absolutely free by allocating credits earned for uploading. </p>
                    <p>
                      Click on "<a href="/upload-company">Try it for Free</a>" or "<a href="/upload-company">Upload a Company</a>". <br />
                      You will be navigated to the upload page where you can choose data to be entered in one of these categories:
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>1) Summary</span>
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>2) Fundamental information</span>,  such as company name, address,
                      etc. Fields marked with a star (*) are required, while others are optional.
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        3) Products & Services
                      </span>
                      <br />
                      Products and services are separated by commas (,) or by pressing Enter
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        4) Brands
                      </span>
                      <br />
                      List brands belonging to the company are separated by commas (,) or by pressing Enter.
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        5) Management Contacts
                      </span>
                      <br />
                      If you know the company’s managers or have their business cards, enter their contact information here.  You may enter details of up to ten individuals.
                    </p>
                    <p>Please note that prior to publishing any personal information, Gravity will send an e-mail or text message to the affected individuals to 
                      receive authorization for publishing their contact details, as required by General Data Protection Regulation (GDPR) of the European Union. 
                      Your credit award  for uploading management contacts may be delayed until Gravity publishes the information.</p>
                  </div>
                </div>
              </div>
              <div className="gi-4 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <div className="video-container">
                    <iframe
                      title="sample"
                      src="https://www.youtube.com/embed/2HYA1hIF94E"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-m">Financials</h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <p>
                  Add any entry from the company’s balance sheet or income statement (P&L). You will already receive credit for entering just one number.
                </p>
                <ul className="specific-company-list1">
                  <li>Choose the correct currency.  You may upload financials in any currency, so use the one you have the information in. Generally, financial statements are issued in the country’s local currency or in global currencies such as dollars, Euros or RMBs.  Gravity uses Euros as base currency, so once you made the uploads, Gravity will convert all figures into Euros in order to unify financials-based searches.  </li>
                  <li>Select entry scale, such as units, thousands or millions prior to inputting the actual value. This allows entering the optimal number of digits. For large companies figures might be too long, so selecting a larger scale allows you to show the magnitude rather than displaying negligible amounts.   </li>
                </ul>
              </div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-m">Supporting Documents</h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <p>Upload additional relevant documents:</p>
                <br />
                <div className="supporting_documents-list">
                  <ul>
                    <li>Audited financial statements</li>
                    <li>Product pictures</li>
                    <li>Certificates</li>
                    <li>Organizational charts</li>
                    <li>Awards</li>
                    <li>Employee or group photos</li>
                  </ul>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                Supported document formats: .doc, .docx, .xls, .xlsx, .pdf, .jpg
              </div>
            </div>
            <div className="grid">
              <div className="gi-8 t-gi-8 m-gi-4 right-inner">
                <div className="gi-12 t-gi-8 m-gi-4">
                  <div className="stack stack-s ai-fs">
                    <h2 className="title title-m">Uploading</h2>
                    <p>
                      Once you filled in all available information, complete your upload by clicking the "Upload" button.
                    </p>
                    <p>
                      For bulk uploads of more than 10 companies, click on the <a href="mailto:techsupport@gravity.surf">Bulk Upload Here</a> button.
                      This way, you do not have to upload companies one by one. Gravity will send you a Bulk Upload template. If you cannot complete the template, just send the available information to Gravity, and a Gravity associate will do the work for you. You will automatically receive the credits for the upload, just as if you were uploading the companies one-by-one.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="gi-4 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <div className="video-container">
                    <iframe
                      title="sample"
                      src="https://www.youtube.com/embed/OsnlI-OD7Tc"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="grid">
              <div className="gi-8 t-gi-8 m-gi-4 right-inner">
                <div className="gi-12 t-gi-8 m-gi-4">
                  <div className="stack stack-s ai-fs">
                    <h2 className="title title-m">Modification</h2>
                    <p>
                      If you believe the company profile you found on Gravity is incomplete or outdated, and you have the correct data, you may modify, complete or update it. If there is a value entered earlier,
                       and you would like to modify it, the old value will also remain in the database and can be still seen by users. 
                       This way, users can determine which entry they trust most. Each entry will also be marked with its source, depending on who uploaded it. 
                       Possible sources are publicly available information, uploaded by an employee or officer of the company, or uploaded by any other user.
                    </p>
                    <p>
                      Go into the company profile and click on "Add or Modify". See instructions under "How to modify or update a company on Gravity".
                    </p>
                  </div>
                </div>
              </div>
              <div className="gi-4 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <div className="video-container">
                    <iframe
                      title="sample"
                      src="https://www.youtube.com/embed/OsnlI-OD7Tc"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-3 separator"></div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-l">Downloading Company Information</h1>
                </div>
              </div>
              <div className="gi-8 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <p>
                    Once you have completed your search, you will be able to view and purchase company information on your search list.
                  </p>
                  <div className="list">
                    <div className="listing">
                      <span>1)</span>
                      <p>Click on the name of a company on your list to see its profile. You can purchase information piece by piece. 
                        Only the availability will be shown, the actual value of the fields will only be revealed once you paid for them using your available credits.
                      </p>
                    </div>
                    <div className="listing">
                      <span>2)</span>
                      <p>Select the pieces of information you need by clicking on the entries. Then, the green buttons will turn orange and their credit value is added to your shopping cart.
                      </p>
                    </div>
                    <div className="listing">
                      <span>3)</span>
                      <p>You may also check the source of the information by putting your cursor on the source icon. Possible sources are publicly available information, uploaded by an employee of officer, or entered by any other user.
                      </p>
                    </div>
                    <div className="listing">
                      <span>4)</span>
                      <p>	You may also click on the "Reveal all" button to purchase all available entries belonging to this company, allowing you to save both time and credits.
                      </p>
                    </div>
                    <div className="listing">
                      <span>5)</span>
                      <p>Your cart's content will be shown on the right-hand side.
                      </p>
                    </div>
                    <div className="listing">
                      <span>6)</span>
                      <p>Click on "Reveal Company Data" to purchase the information.
                      </p>
                    </div>
                  </div>
                  <p>
                    On Gravity, users may modify outdated or incorrect information. Up to seven entries for the same piece of information will be continually published in the event it was already modified after being originally entered.
                  </p>
                </div>
              </div>
              <div className="gi-4 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <div className="video-container">
                    <iframe
                      title="sample"
                      src="https://www.youtube.com/embed/Kk4cgHe9zIk"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-m">Exporting Financials</h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <p>
                    Once you downloaded the information, you can export it to include in a document you are working on, 
                    or to prepare financial statements for the company. Both search results and company data may be exported in CSV format.
                  </p>
                  <p>
                    You may convert CVS into Excel in 7 easy steps.
                  </p>
                  <ul className="specific-company-list1">
                    <li>Go to Search Results</li>
                    <li>Click on the company name which you want to download</li>
                    <li>On the company page click on export </li>
                    <li>Open a new Excel document</li>
                    <li>In the blank Excel Sheet, click on Data</li>
                    <li>Click on From File</li>
                    <li>Click on From Text/CSV</li>
                  </ul>
                  <p>
                    Your data will be shown in an easy-to-use Excel format.
                  </p>
                  <p>
                    If all entries are completed you may create a basic balance sheet and income (P&L) statement from these entries. Please note, you will only be able to create an income statement or a balance sheet from the downloaded data if all balance sheet and income statement items are available.
                  </p>
                  <p>
                    Gravity has created an instructional Excel sheet, showing where different financials can be entered to arrive at an income statement and/or a balance sheet.
                    Click <a href="/api/how-it-works/exporting-financials-sheet">here</a> to download the Excel sheet and follow the steps shown the video <a href="https://youtu.be/T-j22qu5Jbc">How to Download Company Information on Gravity</a>.
                  </p>
                  <p>
                    The order of entries may not be the same as you find in other Financial Statements. They are conveniently grouped for easy understanding and use.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-m">Source</h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                Putting  your cursor on the{" "}
                <img
                  src={`/assets/img/source-up.png`}
                  alt="Source"
                  style={{ width: "20px" }}
                />{" "}
                icon to see, the source of the last entry for the piece of data will be displayed prior to making a decision to purchase. <br /><br />
                While Gravity attempts to supply only reliable information, most data is uploaded by users Gravity cannot control. Make sure you check the source information before downloading.
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">Alternative sources:</div>
              <div className="gi-12 t-gi-8 m-gi-4  list">
                <ul className='specific-company-list2'>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Publicly available information:
                    </span>{" "}
                    Information that is obtained from government registries, open databases or the official website of the companies.</li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Employee or officer of the company:
                    </span>{" "}
                    Data was uploaded by an employee or officer of the company who
                    provided sufficient proof of being an employee or an officer.</li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Independent user:
                    </span>{" "}
                    A user of Gravity who has no employment or other proven legal
                    relationship to the company.</li>
                </ul>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <p>
                  Checking the source of the last uploaded piece of information will help you make a decision whether it is worth spending credits on purchasing it. 
                  Upon allocating credits, each piece of information is revealed, and Gravity will show you up to seven previous uploads and modifications for the value of each filed. Values shown if available:
                </p>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4 list">
                <div className="listing"><span>1)</span><p>Original upload</p></div>
                <div className="listing"><span>2)</span><p>Last uploaded publicly available information</p></div>
                <div className="listing"><span>3)</span><p>Last uploaded by an employee or officer of the company</p></div>
                <div className="listing"><span>4)</span><p>Latest modification</p></div>
                <div className="listing"><span>5)</span><p>The latest modified values other than the four above increasing the number of values to seven</p></div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <p>
                  Gravity takes no responsibility for the accuracy of any information available for downloads.
                </p>
              </div>
            </div>
            <div className="grid">
              <div className="gi-3 separator"></div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-l">Your Account</h1>
                </div>
              </div>
              <div className="gi-8 t-gi-8 m-gi-4 right-inner">
                <div className="gi-12 t-gi-8 m-gi-4">
                  <div className="stack stack-s ai-fs">
                    <p>
                      Items in <a href={account}>your account</a>, such as search results and company data can be viewed and exported any time free of charge, as long as your account remains active. You may download company information to your device and convert it to Word or Excel, which you can also email to your friends, colleagues or business associates.
                    </p>
                    <p>
                      You may close your Gravity account any time. Upon deregistration, saved items as well as remaining available credits will be permanently lost. Make sure to export your saved searches and downloads, and spend all available credits prior to closing your account.
                    </p>
                  </div>
                </div>
              </div>
              <div className="gi-4 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <div className="video-container">
                    <iframe
                      title="sample"
                      src="https://www.youtube.com/embed/cFoaUeRTyk4"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-3 separator"></div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-l">Work in Groups - Workspace</h1>
                </div>
              </div>
              <div className="gi-8 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <p>
                    Workspace is a group of users sharing search results, and earning or spending credits together. 
                    The lists and data you purchased with workspace credits will be available for each workspace member to download. 
                    Credits you earned for the workspace will also be available for all members to use. 
                    You can create a workspace or join existing ones being invited by the administrator of the particular workspace. 
                    You can be a member of up to five workspaces at the same time. Workspaces are useful for business organizations, schools or other teams working on mutual projects.
                  </p>
                  <h2 className="title title-m">Creating a Workspace</h2>
                  <p>
                    When you create a workspace, you will automatically become its administrator, meaning you will be able to amend or delete it, or purchase credits on its behalf.
                  </p>
                  <p>Steps: </p>
                  <div className="list">
                    <div className="listing"><span>1)</span><p>Go to "<a href={account}>Account Settings</a>"</p></div>
                    <div className="listing"><span>2)</span><p>Click on "Create a Workspace"</p></div>
                    <div className="listing"><span>3)</span><p>Fill out the Registration Form</p></div>
                    <div className="listing"><span>4)</span><p>Save the Workspace</p></div>
                  </div>
                </div>
              </div>
              <div className="gi-4 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <div className="video-container">
                    <iframe
                      title="sample"
                      src="https://www.youtube.com/embed/Nmup7zLZVy8"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-m">
                    Inviting Members to the workspace
                  </h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <p>
                  Any Workspace member can invite others to join. Gravity will send an invitation email to the person with a registration link.
                  If the invited person was is not yet registered to Gravity and now decides to register, both of you will receive additional bonus credits.
                </p>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="list">
                  <div className="listing"><span>1)</span><p>Go to "<a href={account}>Invite Members</a>"</p></div>
                  <div className="listing"><span>2)</span><p>Type in the invited person's email address</p></div>
                  <div className="listing"><span>3)</span><p>Click on "Invite"</p></div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-m">
                    Purchasing Credits for the Workspace
                  </h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="list">
                  <div className="listing"><span>1)</span><p>Go to Workspace Settings and top up your shared credit balance using PayPal or a major debit/credit card. </p></div>
                  <div className="listing"><span>2)</span><p>Select the workspace you want to make the purchase for, and your additional credits will be shared with members of that Workspace. </p></div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-m">Deleting a Workspace</h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <p>
                  Only the administrator of the Workspace can delete it by clicking on "Delete Workspace".
                </p>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <p>
                  Make sure you save all downloads and spend available credits before deleting the Workspace; otherwise credits and downloads will be permanently lost.
                </p>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <p>
                  In the event the Workspace administrator stops being a member of Gravity, all other workspace members will be offered the opportunity to become the administrator of that Workspace. The person first accepts the nomination will become the new administrator.
                </p>
              </div>
            </div>
            <div className="grid">
              <div className="gi-3 separator"></div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-l">Legal Responsibility</h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <p>
                    You are legally responsible for the accuracy of the
                    information you are uploading, including but not limited to
                    the following:
                  </p>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <p>
                    <span style={{ fontWeight: "bold" }}>Registration:</span>
                    <br />
                    Gravity is trying to avoid the registration of robots and fictitious persons. This will not only improve the quality of data on Gravity, 
                    but also helps preventing fraud and intentional sabotage of the database. 
                    Upon registration, you will have to declare that you are a natural person and all personal data you provided is true and valid.
                  </p>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <p>
                    <span style={{ fontWeight: "bold" }}>Uploads:</span>
                    <br />
                    Whenever you upload information to Gravity, you are not only earning credits, but also help other users in their searches. It is your legal and ethical responsibility to only upload true and valid information, ensuring data quality for others to download.
                  </p>
                  <p>Make sure your actions are not harming other users. Upon uploading, you will have to certify that your intention is not to discredit or otherwise intentionally harm the reputation of any person or company. In addition, prior to uploading details of a private individual, seek to receive approval from the person for publishing his or her details. Gravity will also contact all individuals for approval prior to publishing their personal information.</p>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      Blocking, terminating or banning a user:
                    </span>
                    <br />
                    <br />
                    Users can be temporarily blocked, terminated or permanently
                    banned from using Gravity for the following reasons:
                  </p>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s ai-fs">
                  <div className="listing"><span>1)</span><p>Registering fictitious persons or using false registration information</p></div>
                  <div className="listing"><span>2)</span><p>Using a robot as a user</p></div>
                  <div className="listing"><span>3)</span><p>Intentionally sabotaging Gravity or its database</p></div>
                  <div className="listing"><span>4)</span><p>Intentionally trying to discredit a company or an individual</p></div>
                  <div className="listing"><span>5)</span><p>Otherwise not following the <a href="/terms-and-conditions">Terms and Conditions</a> you accepted at registration</p></div>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <p>For more details visit our <a href="/terms-and-conditions">Terms and Conditions</a></p>
              </div>
            </div>
            <div className="grid">
              <div className="gi-3 separator"></div>
            </div>
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="stack stack-s">
                  <h1 className="title title-l" id="contactus">Contact Us</h1>
                </div>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <p>
                  Feel free to send us your comments and suggestions. You may reach the appropriate Gravity representative at the following email addresses:
                </p>
              </div>
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="grid">
                  <div className="gi-12">
                    Upload and Download - <a href="mailto:techsupport@gravity.surf">techsupport@gravity.surf</a>
                  </div>
                  <div className="gi-12">
                    Payment - <a href="mailto:finance@gravity.surf">finance@gravity.surf</a>
                  </div>
                  <div className="gi-12">
                    Credit - <a href="mailto:finance@gravity.surf">finance@gravity.surf</a>
                  </div>
                  <div className="gi-12">
                    Workspace - <a href="mailto:techsupport@gravity.surf">techsupport@gravity.surf</a>
                  </div>
                  <div className="gi-12">
                    Notifications and Errors - <a href="mailto:techsupport@techsupport.surf">techsupport@gravity.surf</a>
                  </div>
                  <div className="gi-12">
                    Suggestions - <a href="mailto:customerservice@gravity.surf">customerservice@gravity.surf</a>
                  </div>
                  <div className="gi-12">
                    Other - <a href="mailto:customerservice@gravity.surf">customerservice@gravity.surf</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="gi-12 t-gi-8 m-gi-4">
              <h2
                className="title title-m"
                style={{ color: "#04dead", fontStyle: "italic" }}
              >
                When uploading, you not only earn credits, but also help others!
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
