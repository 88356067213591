import React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';

const HomePageContentComp: React.FC<RouteComponentProps> = ({ history }) => {
    return (
        <div className="section section--content">
            <div className="container">
                <div className="stack stack-xl">
                    <div className="grid">
                        <div className="section-image no-desktop t-gi-8 m-gi-4">
                            <span className="image image--about image--about-1" id=""
                                role="presentation">
                                <span className="image__inner">
                                    <img src="/assets/img/mockups.png"
                                        alt="" />
                                </span>
                            </span>
                        </div>
                        <div className="gi-6 t-gi-8 m-gi-4 section-content">
                            <div className="stack stack-s">
                                <h2 className="title title-l"><br />Crowdsourced Global Company Database</h2>
                                <p className="title title-m">
                                    Users themselves are the best source of information.<br />
                                    Go beyond data found in the registrar or other official documents.</p>
                                <br /><br />
                                <p className="title title-m">On Gravity, you can find the company you are really looking for based on size, location, true activities or products.</p>
                            </div>
                        </div>
                        <div className="gi-6 section-image no-tablet">
                            <span className="image image--about image--about-2" id=""
                                role="presentation">
                                <span className="image__inner">
                                    <img src="/assets/img/mockups.png" alt="" />
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="gi-6 t-gi-8 m-gi-4">
                            <div className="stack stack-xs">
                                <h2 className="title title-l">How it Works</h2>
                                <p className="title title-m">Find a list of companies you can approach<br/>
                                Earn valuable credits by entering information on any company</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid steps">
                        <div className="gi-4 t-gi-8 m-gi-4">
                            <div className="stack stack-m">
                                <span className="image image--about image--about-3" id="" role="presentation">
                                    <span
                                        className="image__inner">
                                        <img src="/assets/img/about-5.png" alt="" />
                                    </span>
                                </span>
                                <div className="text">
                                    <div className="stack stack-xs">
                                        <h3 className="title title-m">Run a Search</h3>
                                        <p className="title title-s">Set your own search criteria to find the company you are looking for. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gi-4 t-gi-8 m-gi-4">
                            <div className="stack stack-m">
                                <span className="image image--about image--about-4" id="" role="presentation">
                                    <span
                                        className="image__inner">
                                        <img src="/assets/img/about-4.png" alt="" />
                                    </span>
                                </span>
                                <div className="text">
                                    <div className="stack stack-xs">
                                        <h3 className="title title-m">See Valuable Details</h3>
                                        <p className="title title-s">Reveal available information individually on the selected company.<br/>
                                        Shop with credits purchased or earned.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gi-4 t-gi-8 m-gi-4">
                            <div className="stack stack-m">
                                <span className="image image--about image--about-5" id="" role="presentation">
                                    <span
                                        className="image__inner">
                                        <img src="/assets/img/about-3v2.png" alt="" />
                                    </span>
                                </span>
                                <div className="text">
                                    <div className="stack stack-xs">
                                        <h3 className="title title-m">Earn Valuable Credits</h3>
                                        <p className="title title-s">Go through your business cards and email contacts.<br/>
                                        Upload information on any company you know.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="gi-12 t-gi-8 m-gi-4">
                            <div className="stack stack-xs ai-c">
                                <button className="button" onClick={() => history.push('/upload-company')}>
                                    <span className="button__inner">
                                        <span>Get started with free credits</span>
                                    </span>
                                </button>
                                <p className="annotation">No credit card required.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const HomePageContentComponent = withRouter(HomePageContentComp);