import { AxiosInstance } from "axios";
import { User, RegisterModel, ResetPasswordModel } from "./authentication.models";
import qs from 'qs';
import { SocialRegisterFields, SocialConfirmFields } from './SocialRegister/social-register.models';
import { axiosInstance } from "../axios";

class AuthenticationService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    async getUser(): Promise<User> {
        const response = await this.axios({
            url: '/api/me',
            headers: {
                //'Content-Type': 'application/json',
                //Accept: 'application/json'
            },
            method: 'GET'
        });

        // const userData = JSON.parse(response.headers['x-user']) as { Type: string; Value: string; }[];
        // const email = userData.filter(key => key.Type === 'EMail')[0].Value;

        return response.data;
    }

    async login(username: string, password: string): Promise<any> {
        console.log('start');
        const response = await this.axios({
            method: 'POST',
            url: '/api/account/login',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                username,
                password
            })
        });
        console.log(response);
        const hasWorkspaceInvite = response.data.hasWorkspaceInvite;

        const user = await this.getUser();

        return {
            hasWorkspaceInvite,
            ...user
        };
    }

    async register(model: RegisterModel, code?: string) {
        return await this.axios({
            method: 'POST',
            url: `/api/account/register?code=${code}`,
            data: model,
        }).catch(err => {
            return err.response;
        });
    }

    async forgotPassword(email: string, captchaToken: string) {
        const model = {
            email,
            captchaToken
        };
        return await this.axios({
            method: 'POST',
            url: '/api/account/forgotpassword',
            data: model,
        });
    }

    async resetPassword(model: ResetPasswordModel) {
        return await this.axios({
            method: 'POST',
            url: '/api/account/resetpassword',
            data: model,
        });
    }

    async loadResetPassword(code: string) {
        return await this.axios({
            method: 'GET',
            url: '/api/account/resetpassword?code=' + code
        });
    }

    async registerLinkedin(model: SocialRegisterFields) {
        return await this.axios({
            method: 'POST',
            url: '/api/account/register-linkedin',
            data: model,
        });
    }

    async confirmLinkedin(model: SocialConfirmFields) {
        return await this.axios({
            method: 'POST',
            url: '/api/account/confirm-linkedin',
            data: model,
        });
    }

    async registerGoogle(model: SocialRegisterFields, code?: string) {
        return await this.axios({
            method: 'POST',
            url: `/api/account/register-google`,
            data: model,
        });
    }
    async confirmGoogle(model: SocialConfirmFields) {
        return await this.axios({
            method: 'POST',
            url: '/api/account/confirm-google',
            data: model,
        });
    }

    async logout() {
        return await this.axios({
            method: 'POST',
            url: '/api/account/logout',
        });
    }
}

export const authenticationService = new AuthenticationService();
