import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

export interface MobilMenuStateProps {
  isLoggedIn: boolean;
  isOpen: boolean | undefined;
}

export interface MobilMenuDispatchrops {
  close: () => void;
  resetCurrentPage: () => void;
}

export const MobilMenu: React.FC<MobilMenuStateProps & MobilMenuDispatchrops & RouteComponentProps> = ({ isLoggedIn, resetCurrentPage, isOpen, close, history }) => {
  const props = isOpen ? { open: true } : {};

  const onClick = (event: any, route: string) => {
    resetCurrentPage();
    event.preventDefault();
    event.stopPropagation();
    close();
    history.push(route);
  };

  return (
    <div className="menu " id="menu" aria-hidden="true" {...props}>
      <div className="menu__inner">
        <ul className="stack stack-m fill">
          <li>
            <a {...{ eee: "active" }}>
              <span className="logo logo--full logo--white logo--color ">
                <svg className="logo__logo">
                  <use xlinkHref="#logo-full-white-color" />
                </svg>
                <span className="visually-hidden">Gravity</span>
              </span>
            </a>
          </li>
          <li>
            <a onClick={(e) => onClick(e, '/how-it-works')} href="/">
              How it Works
            </a>
          </li>
          <li>
            <a onClick={(e) => onClick(e, '/search')} href="/">
              Search for Companies
            </a>
          </li>
          {isLoggedIn ?
            <>
              <li>
                <a onClick={(e) => onClick(e, '/saved-companies')} href="/">
                  Search Results
                </a>
              </li>
              <li>
                <a onClick={(e) => onClick(e, '/upload-company')} href="/">
                  Upload a Company
                </a>
              </li>
              <li>
                <a onClick={(e) => onClick(e, '/invite')} href="/">
                  Invite a Colleague
                </a>
              </li>
            </>
            : null
          }
          <li>
            <a onClick={(e) => onClick(e, '/contact-us')} href="/">
              Contact Us
            </a>
          </li>
          {
            !isLoggedIn ?
              <>
                <li className="split-bottom">
                  <a onClick={(e) => onClick(e, '/register')} href="/">
                    Register
                  </a>
                </li>
                <li>
                  <button className="button button--secondary " onClick={(e) => onClick(e, '/login')}>
                    <span className="button__inner">
                      <span>Log In</span>
                    </span>
                  </button>
                </li>
              </> : ""
          }
        </ul>
      </div>
      <button className="menu__close" onClick={() => close()}>
        <svg className="icon icon--m ">
          <use xlinkHref="#icon-close" />
        </svg>
      </button>
    </div >
  );
};

export const MobilMenuComponent = withRouter(MobilMenu);