import { AppState } from '../root.reducer';

export const isSearchingSelector = (state: AppState) => state.search.isSearching;
export const companySearchCountResultsSelector = (state: AppState) => state.search.searchCountResult;
export const lastSearchFieldsSelector = (state: AppState) => state.search.searchFields;

export const isLoadingSavedSearchSelector = (state: AppState) => state.search.isLoadingSavedSearch;
export const savedSearchSelector = (state: AppState) => state.search.savedSearch;

export const companyCountSelector = (state: AppState) => state.search.companyCount;

export const searchFieldsSelector = (state: AppState) => state.search.searchFields;
