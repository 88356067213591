import React from 'react';
import { SavedCompanyModel, CompanyPropertyLabelMap, SavedCompanyFinancialModel, AttributeType, SavedCompanyAttributeModel } from '../saved-company.models';
import { CompanyPropertyComponent } from './CompanyProperty.component';
import { CompanyAttributeKey } from '../../company.constants';

export interface FinancialDetailsTabProps {
    addPropertyToCart: (attribute: SavedCompanyAttributeModel, label: any, attributeName: string) => void;
    savedCompany: SavedCompanyModel;
}

export const FinancialDetailsTab: React.FC<FinancialDetailsTabProps> = ({ savedCompany, addPropertyToCart }) => {
    const brandColorStyle = { '--background-color': 'var(--brand-color)' } as any as React.CSSProperties;

    let [numberOfAvailableProperies, totalPriceOfProperties] = [0, 0];

    const attributes = [
        "incomeStatementNetSales",
        "incomeStatementExtraordinaryIncome",
        "incomeStatementFinancialIncome",
        "incomeStatementOtherIncome",
        "incomeStatementMaterials",
        "incomeStatementPersonnel",
        "incomeStatementUtilities",
        "incomeStatementOverhead",
        "incomeStatementFinancialExpenses",
        "incomeStatementSellingAndAdministrative",
        "incomeStatementDepreciationAndAmortization",
        "incomeStatementOtherExpenses",
        "incomeStatementTotalNetRevenues",
        "incomeStatementTotalExpenses",
        "incomeStatementNetIncomeBeforeTaxes",
        "incomeStatementCorporateIncomeTaxes",
        "incomeStatementNetIncomeAfterTaxes",
        "incomeStatementDividensPaid",
        "incomeStatementRetainedEarningsForTheYear",
        "balanceSheetCashAndEquivalents",
        "balanceSheetAccountsReceivable",
        "balanceSheetInventories",
        "balanceSheetOtherCurrentAssets",
        "balanceSheetPropertyPlantAndEquipment",
        "balanceSheetProprietaryRights",
        "balanceSheetAccumulatedDepreciation",
        "balanceSheetOtherFixedAssets",
        "balanceSheetAccountsPayable",
        "balanceSheetShortTermLoans",
        "balanceSheetTaxesPayable",
        "balanceSheetOtherCurrentLiabilities",
        "balanceSheetLongTermLoanes",
        "balanceSheetShareHoldersLoans",
        "balanceSheetDeferredIncomeTaxes",
        "balanceSheetOtherLongTermLiabilites",
        "balanceSheetRegisteredShares",
        "balanceSheetAdditionalPaidInCapital",
        "balanceSheetAccumulatedRetainedEarnings",
        "balanceSheetOtherOwnersEquity",
        "balanceSheetTotalAssets",
        "balanceSheetTotalLiabilites",
        "balanceSheetOwnersEquity",
        "balanceSheetLiabilitiesAndOwnersEquity",
    ];

    savedCompany.financials
        .forEach((financial) => {
            attributes.forEach((propertyName) => {
                const model = financial as any;
                const isAvailableToBuy = model[propertyName] && (!model[propertyName]?.value || model[propertyName].hasUpdate);

                if (isAvailableToBuy) {
                    numberOfAvailableProperies += 1;
                    totalPriceOfProperties += model[propertyName]?.creditPrice || 0;
                }
            });
        });

    const addPropertyToCartIfAvailable = (attribute: SavedCompanyAttributeModel | undefined, label: any, propertyName: string) => {
        if (attribute && (attribute.value == null || attribute.value === "" || attribute.value === undefined || attribute.hasUpdate)) {
            addPropertyToCart(attribute, label, propertyName);
        }
    };

    const revealAll = () => {
        savedCompany.financials.forEach(financial => {
            addPropertyToCartIfAvailable(financial.incomeStatementNetSales, CompanyPropertyLabelMap.incomeStatementNetSales, "incomeStatementNetSales");
            addPropertyToCartIfAvailable(financial.incomeStatementExtraordinaryIncome, CompanyPropertyLabelMap.incomeStatementExtraordinaryIncome, "incomeStatementExtraordinaryIncome");
            addPropertyToCartIfAvailable(financial.incomeStatementFinancialIncome, CompanyPropertyLabelMap.incomeStatementFinancialIncome, "incomeStatementFinancialIncome");
            addPropertyToCartIfAvailable(financial.incomeStatementOtherIncome, CompanyPropertyLabelMap.incomeStatementOtherIncome, "incomeStatementOtherIncome");
            addPropertyToCartIfAvailable(financial.incomeStatementMaterials, CompanyPropertyLabelMap.incomeStatementMaterials, "incomeStatementMaterials");
            addPropertyToCartIfAvailable(financial.incomeStatementPersonnel, CompanyPropertyLabelMap.incomeStatementPersonnel, "incomeStatementPersonnel");
            addPropertyToCartIfAvailable(financial.incomeStatementUtilities, CompanyPropertyLabelMap.incomeStatementUtilities, "incomeStatementUtilities");
            addPropertyToCartIfAvailable(financial.incomeStatementOverhead, CompanyPropertyLabelMap.incomeStatementOverhead, "incomeStatementOverhead");
            addPropertyToCartIfAvailable(financial.incomeStatementFinancialExpenses, CompanyPropertyLabelMap.incomeStatementFinancialExpenses, "incomeStatementFinancialExpenses");
            addPropertyToCartIfAvailable(financial.incomeStatementSellingAndAdministrative, CompanyPropertyLabelMap.incomeStatementSellingAndAdministrative, "incomeStatementSellingAndAdministrative");
            addPropertyToCartIfAvailable(financial.incomeStatementDepreciationAndAmortization, CompanyPropertyLabelMap.incomeStatementDepreciationAndAmortization, "incomeStatementDepreciationAndAmortization");
            addPropertyToCartIfAvailable(financial.incomeStatementOtherExpenses, CompanyPropertyLabelMap.incomeStatementOtherExpenses, "incomeStatementOtherExpenses");
            addPropertyToCartIfAvailable(financial.incomeStatementTotalNetRevenues, CompanyPropertyLabelMap.incomeStatementTotalNetRevenues, "incomeStatementTotalNetRevenues");
            addPropertyToCartIfAvailable(financial.incomeStatementTotalExpenses, CompanyPropertyLabelMap.incomeStatementTotalExpenses, "incomeStatementTotalExpenses");
            addPropertyToCartIfAvailable(financial.incomeStatementNetIncomeBeforeTaxes, CompanyPropertyLabelMap.incomeStatementNetIncomeBeforeTaxes, "incomeStatementNetIncomeBeforeTaxes");
            addPropertyToCartIfAvailable(financial.incomeStatementCorporateIncomeTaxes, CompanyPropertyLabelMap.incomeStatementCorporateIncomeTaxes, "incomeStatementCorporateIncomeTaxes");
            addPropertyToCartIfAvailable(financial.incomeStatementNetIncomeAfterTaxes, CompanyPropertyLabelMap.incomeStatementNetIncomeAfterTaxes, "incomeStatementNetIncomeAfterTaxes");
            addPropertyToCartIfAvailable(financial.incomeStatementDividensPaid, CompanyPropertyLabelMap.incomeStatementDividensPaid, "incomeStatementDividensPaid");
            addPropertyToCartIfAvailable(financial.incomeStatementRetainedEarningsForTheYear, CompanyPropertyLabelMap.incomeStatementRetainedEarningsForTheYear, "incomeStatementRetainedEarningsForTheYear");
            addPropertyToCartIfAvailable(financial.balanceSheetCashAndEquivalents, CompanyPropertyLabelMap.balanceSheetCashAndEquivalents, "balanceSheetCashAndEquivalents");
            addPropertyToCartIfAvailable(financial.balanceSheetAccountsReceivable, CompanyPropertyLabelMap.balanceSheetAccountsReceivable, "balanceSheetAccountsReceivable");
            addPropertyToCartIfAvailable(financial.balanceSheetInventories, CompanyPropertyLabelMap.balanceSheetInventories, "balanceSheetInventories");
            addPropertyToCartIfAvailable(financial.balanceSheetOtherCurrentAssets, CompanyPropertyLabelMap.balanceSheetOtherCurrentAssets, "balanceSheetOtherCurrentAssets");
            addPropertyToCartIfAvailable(financial.balanceSheetPropertyPlantAndEquipment, CompanyPropertyLabelMap.balanceSheetPropertyPlantAndEquipment, "balanceSheetPropertyPlantAndEquipment");
            addPropertyToCartIfAvailable(financial.balanceSheetProprietaryRights, CompanyPropertyLabelMap.balanceSheetProprietaryRights, "balanceSheetProprietaryRights");
            addPropertyToCartIfAvailable(financial.balanceSheetAccumulatedDepreciation, CompanyPropertyLabelMap.balanceSheetAccumulatedDepreciation, "balanceSheetAccumulatedDepreciation");
            addPropertyToCartIfAvailable(financial.balanceSheetOtherFixedAssets, CompanyPropertyLabelMap.balanceSheetOtherFixedAssets, "balanceSheetOtherFixedAssets");
            addPropertyToCartIfAvailable(financial.balanceSheetAccountsPayable, CompanyPropertyLabelMap.balanceSheetAccountsPayable, "balanceSheetAccountsPayable");
            addPropertyToCartIfAvailable(financial.balanceSheetShortTermLoans, CompanyPropertyLabelMap.balanceSheetShortTermLoans, "balanceSheetShortTermLoans");
            addPropertyToCartIfAvailable(financial.balanceSheetTaxesPayable, CompanyPropertyLabelMap.balanceSheetTaxesPayable, "balanceSheetTaxesPayable");
            addPropertyToCartIfAvailable(financial.balanceSheetOtherCurrentLiabilities, CompanyPropertyLabelMap.balanceSheetOtherCurrentLiabilities, "balanceSheetOtherCurrentLiabilities");
            addPropertyToCartIfAvailable(financial.balanceSheetLongTermLoanes, CompanyPropertyLabelMap.balanceSheetLongTermLoanes, "balanceSheetLongTermLoanes");
            addPropertyToCartIfAvailable(financial.balanceSheetShareHoldersLoans, CompanyPropertyLabelMap.balanceSheetShareHoldersLoans, "balanceSheetShareHoldersLoans");
            addPropertyToCartIfAvailable(financial.balanceSheetDeferredIncomeTaxes, CompanyPropertyLabelMap.balanceSheetDeferredIncomeTaxes, "balanceSheetDeferredIncomeTaxes");
            addPropertyToCartIfAvailable(financial.balanceSheetOtherLongTermLiabilites, CompanyPropertyLabelMap.balanceSheetOtherLongTermLiabilites, "balanceSheetOtherLongTermLiabilites");
            addPropertyToCartIfAvailable(financial.balanceSheetRegisteredShares, CompanyPropertyLabelMap.balanceSheetRegisteredShares, "balanceSheetRegisteredShares");
            addPropertyToCartIfAvailable(financial.balanceSheetAdditionalPaidInCapital, CompanyPropertyLabelMap.balanceSheetAdditionalPaidInCapital, "balanceSheetAdditionalPaidInCapital");
            addPropertyToCartIfAvailable(financial.balanceSheetAccumulatedRetainedEarnings, CompanyPropertyLabelMap.balanceSheetAccumulatedRetainedEarnings, "balanceSheetAccumulatedRetainedEarnings");
            addPropertyToCartIfAvailable(financial.balanceSheetOtherOwnersEquity, CompanyPropertyLabelMap.balanceSheetOtherOwnersEquity, "balanceSheetOtherOwnersEquity");
            addPropertyToCartIfAvailable(financial.balanceSheetTotalAssets, CompanyPropertyLabelMap.balanceSheetTotalAssets, "balanceSheetTotalAssets");
            addPropertyToCartIfAvailable(financial.balanceSheetTotalLiabilites, CompanyPropertyLabelMap.balanceSheetTotalLiabilites, "balanceSheetTotalLiabilites");
            addPropertyToCartIfAvailable(financial.balanceSheetOwnersEquity, CompanyPropertyLabelMap.balanceSheetOwnersEquity, "balanceSheetOwnersEquity");
            addPropertyToCartIfAvailable(financial.balanceSheetLiabilitiesAndOwnersEquity, CompanyPropertyLabelMap.balanceSheetLiabilitiesAndOwnersEquity, "balanceSheetLiabilitiesAndOwnersEquity");
        });
    };


    const renderFinancial = (financial: SavedCompanyFinancialModel) => {
        return (
            <div key={financial.year}>
                <div className="stack stack-s">
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <svg className="icon icon--l ">
                                <use xlinkHref="#icon-general-details" />
                            </svg>
                            <span className="title title-s">Income Statement (P&L) - {financial.year}</span>
                        </span>
                    </span>
                    <div>Values shown in EUR 1</div>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Revenues</span>
                        </span>
                    </span>
                    <ul className="data-grid">
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementNetSales"}
                            attribute={financial.incomeStatementNetSales}
                            labelName={CompanyPropertyLabelMap.incomeStatementNetSales}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/netSales`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementExtraordinaryIncome"}
                            attribute={financial.incomeStatementExtraordinaryIncome}
                            labelName={CompanyPropertyLabelMap.incomeStatementExtraordinaryIncome}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/extraordinaryIncome`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementFinancialIncome"}
                            attribute={financial.incomeStatementFinancialIncome}
                            labelName={CompanyPropertyLabelMap.incomeStatementFinancialIncome}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/financialIncome`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementOtherIncome"}
                            attribute={financial.incomeStatementOtherIncome}
                            labelName={CompanyPropertyLabelMap.incomeStatementOtherIncome}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/otherIncome`} />
                    </ul>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Expenses</span>
                        </span>
                    </span>
                    <ul className="data-grid">
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementMaterials"}
                            attribute={financial.incomeStatementMaterials}
                            labelName={CompanyPropertyLabelMap.incomeStatementMaterials}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/materials`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementPersonnel"}
                            attribute={financial.incomeStatementPersonnel}
                            labelName={CompanyPropertyLabelMap.incomeStatementPersonnel}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/personnel`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementUtilities"}
                            attribute={financial.incomeStatementUtilities}
                            labelName={CompanyPropertyLabelMap.incomeStatementUtilities}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/utilities`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementOverhead"}
                            attribute={financial.incomeStatementOverhead}
                            labelName={CompanyPropertyLabelMap.incomeStatementOverhead}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/overhead`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementFinancialExpenses"}
                            attribute={financial.incomeStatementFinancialExpenses}
                            labelName={CompanyPropertyLabelMap.incomeStatementFinancialExpenses}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/financialExpenses`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementSellingAndAdministrative"}
                            attribute={financial.incomeStatementSellingAndAdministrative}
                            labelName={CompanyPropertyLabelMap.incomeStatementSellingAndAdministrative}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/sellingAndAdministrative`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementDepreciationAndAmortization"}
                            attribute={financial.incomeStatementDepreciationAndAmortization}
                            labelName={CompanyPropertyLabelMap.incomeStatementDepreciationAndAmortization}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/depreciationAndAmortization`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementOtherExpenses"}
                            attribute={financial.incomeStatementOtherExpenses}
                            labelName={CompanyPropertyLabelMap.incomeStatementOtherExpenses}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/otherExpenses`} />
                    </ul>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Summary</span>
                        </span>
                    </span>
                    <ul className="data-grid">
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementTotalNetRevenues"}
                            attribute={financial.incomeStatementTotalNetRevenues}
                            labelName={CompanyPropertyLabelMap.incomeStatementTotalNetRevenues}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/totalNetRevenues`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementTotalExpenses"}
                            attribute={financial.incomeStatementTotalExpenses}
                            labelName={CompanyPropertyLabelMap.incomeStatementTotalExpenses}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/totalExpenses`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementNetIncomeBeforeTaxes"}
                            attribute={financial.incomeStatementNetIncomeBeforeTaxes}
                            labelName={CompanyPropertyLabelMap.incomeStatementNetIncomeBeforeTaxes}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/netIncomeBeforeTaxes`} />
                    </ul>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Earnings</span>
                        </span>
                    </span>
                    <ul className="data-grid">
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementCorporateIncomeTaxes"}
                            attribute={financial.incomeStatementCorporateIncomeTaxes}
                            labelName={CompanyPropertyLabelMap.incomeStatementCorporateIncomeTaxes}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/corporateIncomeTaxes`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementNetIncomeAfterTaxes"}
                            attribute={financial.incomeStatementNetIncomeAfterTaxes}
                            labelName={CompanyPropertyLabelMap.incomeStatementNetIncomeAfterTaxes}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/netIncomeAfterTaxes`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementDividensPaid"}
                            attribute={financial.incomeStatementDividensPaid}
                            labelName={CompanyPropertyLabelMap.incomeStatementDividensPaid}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/dividensPaid`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"incomeStatementRetainedEarningsForTheYear"}
                            attribute={financial.incomeStatementRetainedEarningsForTheYear}
                            labelName={CompanyPropertyLabelMap.incomeStatementRetainedEarningsForTheYear}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/retainedEarningsForTheYear`} />
                    </ul>
                </div>
                <div className="stack stack-s">
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <svg className="icon icon--l ">
                                <use xlinkHref="#icon-general-details" />
                            </svg>
                            <span className="title title-s">Balance Sheet - {financial.year}</span>
                        </span>
                    </span>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Assets</span>
                        </span>
                    </span>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Current Assets</span>
                        </span>
                    </span>
                    <ul className="data-grid">
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetCashAndEquivalents"}
                            attribute={financial.balanceSheetCashAndEquivalents}
                            labelName={CompanyPropertyLabelMap.balanceSheetCashAndEquivalents}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/cashAndEquivalents`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetAccountsReceivable"}
                            attribute={financial.balanceSheetAccountsReceivable}
                            labelName={CompanyPropertyLabelMap.balanceSheetAccountsReceivable}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/accountsReceivable`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetInventories"}
                            attribute={financial.balanceSheetInventories}
                            labelName={CompanyPropertyLabelMap.balanceSheetInventories}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/inventories`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetOtherCurrentAssets"}
                            attribute={financial.balanceSheetOtherCurrentAssets}
                            labelName={CompanyPropertyLabelMap.balanceSheetOtherCurrentAssets}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/otherCurrentAssets`} />
                    </ul>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Fixed Assets</span>
                        </span>
                    </span>
                    <ul className="data-grid">
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetPropertyPlantAndEquipment"}
                            attribute={financial.balanceSheetPropertyPlantAndEquipment}
                            labelName={CompanyPropertyLabelMap.balanceSheetPropertyPlantAndEquipment}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/propertyPlantAndEquipment`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetProprietaryRights"}
                            attribute={financial.balanceSheetProprietaryRights}
                            labelName={CompanyPropertyLabelMap.balanceSheetProprietaryRights}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/proprietaryRights`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetAccumulatedDepreciation"}
                            attribute={financial.balanceSheetAccumulatedDepreciation}
                            labelName={CompanyPropertyLabelMap.balanceSheetAccumulatedDepreciation}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/accumulatedDepreciation`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetOtherFixedAssets"}
                            attribute={financial.balanceSheetOtherFixedAssets}
                            labelName={CompanyPropertyLabelMap.balanceSheetOtherFixedAssets}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/otherFixedAssets`} />
                    </ul>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Liabilities and Owner's Equity</span>
                        </span>
                    </span>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Liabilities</span>
                        </span>
                    </span>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Current Liabilities</span>
                        </span>
                    </span>
                    <ul className="data-grid">
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetAccountsPayable"}
                            attribute={financial.balanceSheetAccountsPayable}
                            labelName={CompanyPropertyLabelMap.balanceSheetAccountsPayable}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/accountsPayable`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetShortTermLoans"}
                            attribute={financial.balanceSheetShortTermLoans}
                            labelName={CompanyPropertyLabelMap.balanceSheetShortTermLoans}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/shortTermLoans`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetTaxesPayable"}
                            attribute={financial.balanceSheetTaxesPayable}
                            labelName={CompanyPropertyLabelMap.balanceSheetTaxesPayable}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/taxesPayable`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetOtherCurrentLiabilities"}
                            attribute={financial.balanceSheetOtherCurrentLiabilities}
                            labelName={CompanyPropertyLabelMap.balanceSheetOtherCurrentLiabilities}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/otherCurrentLiabilities`} />
                    </ul>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Long - Term Liabilities</span>
                        </span>
                    </span>
                    <ul className="data-grid">
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetLongTermLoanes"}
                            attribute={financial.balanceSheetLongTermLoanes}
                            labelName={CompanyPropertyLabelMap.balanceSheetLongTermLoanes}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/longTermLoanes`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetShareHoldersLoans"}
                            attribute={financial.balanceSheetShareHoldersLoans}
                            labelName={CompanyPropertyLabelMap.balanceSheetShareHoldersLoans}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/shareHoldersLoans`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetDeferredIncomeTaxes"}
                            attribute={financial.balanceSheetDeferredIncomeTaxes}
                            labelName={CompanyPropertyLabelMap.balanceSheetDeferredIncomeTaxes}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/deferredIncomeTaxes`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetOtherLongTermLiabilites"}
                            attribute={financial.balanceSheetOtherLongTermLiabilites}
                            labelName={CompanyPropertyLabelMap.balanceSheetOtherLongTermLiabilites}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/otherLongTermLiabilites`} />
                    </ul>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Owner's Equity</span>
                        </span>
                    </span>
                    <ul className="data-grid">
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetRegisteredShares"}
                            attribute={financial.balanceSheetRegisteredShares}
                            labelName={CompanyPropertyLabelMap.balanceSheetRegisteredShares}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/registeredShares`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetAdditionalPaidInCapital"}
                            attribute={financial.balanceSheetAdditionalPaidInCapital}
                            labelName={CompanyPropertyLabelMap.balanceSheetAdditionalPaidInCapital}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/additionalPaidInCapital`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetAccumulatedRetainedEarnings"}
                            attribute={financial.balanceSheetAccumulatedRetainedEarnings}
                            labelName={CompanyPropertyLabelMap.balanceSheetAccumulatedRetainedEarnings}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/accumulatedRetainedEarnings`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetOtherOwnersEquity"}
                            attribute={financial.balanceSheetOtherOwnersEquity}
                            labelName={CompanyPropertyLabelMap.balanceSheetOtherOwnersEquity}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/otherOwnersEquity`} />
                    </ul>
                    <span className="icon-header icon-header--header-right">
                        <span className="icon-header__inner">
                            <span className="title title-s">Summary</span>
                        </span>
                    </span>
                    <ul className="data-grid">
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetTotalAssets"}
                            attribute={financial.balanceSheetTotalAssets}
                            labelName={CompanyPropertyLabelMap.balanceSheetTotalAssets}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/totalAssets`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetTotalLiabilites"}
                            attribute={financial.balanceSheetTotalLiabilites}
                            labelName={CompanyPropertyLabelMap.balanceSheetTotalLiabilites}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/totalLiabilites`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetOwnersEquity"}
                            attribute={financial.balanceSheetOwnersEquity}
                            labelName={CompanyPropertyLabelMap.balanceSheetOwnersEquity}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/otherOwnersEquity`} />
                        <CompanyPropertyComponent
                            savedCompanyId={savedCompany.id}
                            preFix={'€'}
                            attributeType={AttributeType.Financial}
                            key={"balanceSheetLiabilitiesAndOwnersEquity"}
                            attribute={financial.balanceSheetLiabilitiesAndOwnersEquity}
                            labelName={CompanyPropertyLabelMap.balanceSheetLiabilitiesAndOwnersEquity}
                            attributeName={`${CompanyAttributeKey.Financials.FinancialItem}/${financial.year}/liabilitiesAndOwnersEquity`} />
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="info-count">
                {numberOfAvailableProperies > 0 ?
                    <div className="h-stack h-stack-xs ai-c m-fd-c">
                        <p className="annotation">{numberOfAvailableProperies} {numberOfAvailableProperies === 1 ? "piece" : "pieces"} of information available on this page</p>
                        <button className="icon-chip icon-chip--label-left" style={brandColorStyle}
                            onClick={() => revealAll()}
                        >
                            <span className="icon-chip__inner">
                                <span className="label">Reveal all for <svg className="icon icon--m "><use xlinkHref="#icon-gravity-credit" /></svg>
                                    {totalPriceOfProperties}
                                </span>
                            </span>
                        </button>
                    </div> : ""}
            </div>
            <div className="source-info">
                <p className="annotation">Move your cursor on <img className="source" src={`/assets/img/source-up.png`} alt="Source" /> to show the source of the last upload</p>
            </div>
            <div className="source-info">
                <p className="annotation">View previously entered values on revealed fields by clicking on <img className="source" src={`/assets/img/source-down.png`} alt="Source" /></p>
            </div>
            {
                savedCompany.financials.map((financial) => (
                    renderFinancial(financial)
                ))
            }
        </>
    );
};
