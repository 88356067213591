import { connect } from 'react-redux';
import { AppState } from '../root.reducer';
import { CompanyEditComponent, CompanyEditStateProps, CompanyEditDispatchProps } from './CompanyEdit.component';
import { companyUploadCreditsLoadedAction, companyUploadCreditsLoadingAction, companyUploadCurrenciesLoadedAction, companyUploadCurrenciesLoadingAction, companyUploadTouchFinancialInputsAction, companyUploadTouchInputsAction } from '../company-upload/company-upload.actions';
import { companyUploadCredits, companyUploadCurrenciesSelector, companyUploadTouchedInputsSelector, isCompanyUploadCreditsLoadingSelector, isCompanyUploadCurrenciesLoadingSelector, isCompanyUploadedSuccessSelector } from '../company-upload/company-upload.selectors';
import { Dispatch } from 'redux';
import { creditsService } from '../company-upload/credits.service';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { resetSavedCompanyAction, savedCompanyLoadedAction, savedCompanyLoadingAction, savedCompanyUploadFailedAction, savedCompanyUploadSuccessAction } from '../saved-company/saved-company.actions';
import { companyService } from '../saved-company/company.service';
import { companyEditService } from './company-edit.service';
import { SavedCompanyModel } from '../saved-company/saved-company.models';
import { isSavedCompanyLoadingSelector, savedCompanySelector } from '../saved-company/company.selectors';
import { CompanyUploadModel } from '../company-upload/CompanyUpload.model';
import { gravityUseAsSelector } from '../auth/authentication.selectors';
import { authenticationService } from '../auth/authentication.service';
import { authenticationSuccessAction } from '../auth/authentication.actions';
import { popupSetModelAction } from '../popup/popup.actions';
import { PopupDialogType } from '../popup/popup.models';
import { popupService } from '../popup/popup.service';
import { workspaceService } from '../workspace-settings/workspace.service';
import { workspaceLoadedSuccessfulAction } from '../workspace-settings/workspace.actions';
import { TouchedFinancialInputsModel } from '../company-upload/company-upload.models';


const mapStateToProps = (state: AppState, { match }: RouteComponentProps<{ id: string }>): CompanyEditStateProps => ({
    companyId: match.params.id as any as number,
    company: savedCompanySelector(state),
    isSavedCompanyLoading: isSavedCompanyLoadingSelector(state),

    companyCredits: companyUploadCredits(state),
    isCompanyCreditsLoading: isCompanyUploadCreditsLoadingSelector(state),
    companyUploadedSuccess: isCompanyUploadedSuccessSelector(state),

    currencies: companyUploadCurrenciesSelector(state),
    touchedInputs: companyUploadTouchedInputsSelector(state),

    isCurrenciesLoading: isCompanyUploadCurrenciesLoadingSelector(state),

    useGravityAs: gravityUseAsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { match, history }: RouteComponentProps<{ id: string }>): CompanyEditDispatchProps => ({
    loadCompanyCredits: async () => {
        dispatch(companyUploadCreditsLoadingAction());

        const companyCredits = await creditsService.loadCompanyCredits();

        dispatch(companyUploadCreditsLoadedAction(companyCredits));
    },

    loadCompany: async (id: number, useGravityAs?: number) => {
        dispatch(savedCompanyLoadingAction());

        if (useGravityAs) {
            const response = await companyService.getSavedWorkspaceCompany(id);
            if (response.status !== 200) {
                history.push('/');
                return;
            }
            const savedCompany = response.data;
            if (savedCompany.isBlocked) {
                dispatch(resetSavedCompanyAction());
                history.push('/saved-companies');
            } else {
                dispatch(savedCompanyLoadedAction(savedCompany));
            }
        } else {
            const response = await companyService.getSavedUserCompany(id);
            if (response.status !== 200) {
                history.push('/');
                return;
            }
            const savedCompany = response.data;
            if (savedCompany.isBlocked) {
                dispatch(resetSavedCompanyAction());
                history.push('/saved-companies');
            } else {
                dispatch(savedCompanyLoadedAction(savedCompany));
            }
        }
    },
    
    setTouchedFinancials: (financials: TouchedFinancialInputsModel[]) => {
        dispatch(companyUploadTouchFinancialInputsAction(financials));
    },

    loadCurrencies: async () => {
        dispatch(companyUploadCurrenciesLoadingAction());

        const currencies = await companyService.getCurrencies();

        dispatch(companyUploadCurrenciesLoadedAction(currencies));
    },

    updateCompany: async (originalCompany: SavedCompanyModel, editCompanyModel: CompanyUploadModel, creditReward: number, useGravityAs?: number) => {
        dispatch(savedCompanyLoadingAction());

        const savedCompanyId = Number(match.params.id);
        const command = companyEditService.createUpdateCompanyCommand(savedCompanyId, originalCompany, editCompanyModel, creditReward);

        try {
            if (useGravityAs) {
                await companyService.updateWorkspaceCompany(command);
                const workspace = await workspaceService.getWorkspaceSettings(useGravityAs);
                dispatch(workspaceLoadedSuccessfulAction(workspace));
            } else {
                await companyService.updateUserCompany(command);
            }

            const user = await authenticationService.getUser();
            dispatch(authenticationSuccessAction(user));

            dispatch(savedCompanyUploadSuccessAction());

            dispatch(popupSetModelAction({
                title: "Company Modification",
                text: "Company successfully modified.",
                type: PopupDialogType.Dialog,
                okButtonText: 'Ok',
                okButtonAction: (evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();

                    popupService.hidePopup();

                    history.push('/company/' + savedCompanyId);
                }
            }));
            popupService.showPopup();

        } catch (e) {
            console.log(e);
            dispatch(savedCompanyUploadFailedAction());
            dispatch(popupSetModelAction({
                title: "An error occured during the upload, please try again later",
                text: (e as any)?.response?.data?.Message,
                type: PopupDialogType.Dialog,
                okButtonText: 'Ok',
                okButtonAction: (evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();

                    popupService.hidePopup();
                }
            }));
            popupService.showPopup();
        }
    }
});

export default withRouter(connect<CompanyEditStateProps, CompanyEditDispatchProps, RouteComponentProps<{ id: string }>, AppState>(
    mapStateToProps,
    mapDispatchToProps
)(CompanyEditComponent));