import React from 'react';
import { CompanyUploadModel } from '../CompanyUpload.model';
import { CompanyAttributeKey } from '../../company.constants';
import { TextFieldWithPriceComponent } from '../TextFieldWithPrice.component';
import { CompanyPropertyLabelMap } from '../../saved-company/saved-company.models';
import { TouchedFinancialInputsModel } from '../company-upload.models';

export interface FinancialDetailsComponentProps {
  isEditMode?: boolean;
  originalValue?: CompanyUploadModel['financials'][0];
  financial: CompanyUploadModel['financials'][0];
  touchedInputs: TouchedFinancialInputsModel;
  updateIncomeStatement: (key: keyof CompanyUploadModel['financials'][0]['incomeStatement'], value: any) => void;
  updateBalanceSheet: (key: keyof CompanyUploadModel['financials'][0]['balanceSheet'], value: any) => void;
  isOpen: boolean;
  toggleCollapse: () => void;
}

export const FinancialDetailsComponent: React.FC<FinancialDetailsComponentProps> = ({
  isEditMode,
  originalValue,
  financial,
  touchedInputs,
  updateIncomeStatement,
  updateBalanceSheet,
  isOpen,
  toggleCollapse
}) => {
  const renderHeader = () => (
    <div className="h-stack h-stack-xs ai-c" style={{cursor: 'pointer'}}>
      <div className="col col--name" style={{ flex: '1' }}>
        <p className="fw-b" onClick={() => toggleCollapse()}>
          <span className="icon-button__inner">
            {financial.year}
            <svg className="icon icon--m ">
              <use xlinkHref={isOpen ? "#icon-arrow-right" : "#icon-arrow-up"} />
            </svg>
          </span>
        </p>
      </div>
    </div>
  );

  const renderForm = () => (
    <>
      <span className="icon-header icon-header--header-right " id="">
        <span className="icon-header__inner">
          <span className="title title-s"><b>Income Statement ({financial.year})</b></span>
        </span>
      </span>
      <span className="title title-s"><b>Revenues</b></span>
      <div className="grid">
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.netSales.disabled}
            isRequired={financial.incomeStatement.netSales.required}
            label={CompanyPropertyLabelMap.incomeStatementNetSales as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.netSales.value}
            onBlur={(value) => updateIncomeStatement('netSales', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.netSales.value}
            isTouched={touchedInputs.incomeStatement.netSales}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.extraordinaryIncome.disabled}
            isRequired={financial.incomeStatement.extraordinaryIncome.required}
            label={CompanyPropertyLabelMap.incomeStatementExtraordinaryIncome as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.extraordinaryIncome.value}
            onBlur={(value) => updateIncomeStatement('extraordinaryIncome', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.extraordinaryIncome.value}
            isTouched={touchedInputs.incomeStatement.extraordinaryIncome}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.financialIncome.disabled}
            isRequired={financial.incomeStatement.financialIncome.required}
            label={CompanyPropertyLabelMap.incomeStatementFinancialIncome as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.financialIncome.value}
            onBlur={(value) => updateIncomeStatement('financialIncome', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.financialIncome.value}
            isTouched={touchedInputs.incomeStatement.financialIncome}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.otherIncome.disabled}
            isRequired={financial.incomeStatement.otherIncome.required}
            label={CompanyPropertyLabelMap.incomeStatementOtherIncome as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.otherIncome.value}
            onBlur={(value) => updateIncomeStatement('otherIncome', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.otherIncome.value}
            isTouched={touchedInputs.incomeStatement.otherIncome}
          />
        </div>
      </div>
      <span className="title title-s"><b>Expenses</b></span>
      <div className="grid">
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.materials.disabled}
            isRequired={financial.incomeStatement.materials.required}
            label={CompanyPropertyLabelMap.incomeStatementMaterials as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.materials.value}
            onBlur={(value) => updateIncomeStatement('materials', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.materials.value}
            isTouched={touchedInputs.incomeStatement.materials}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.personnel.disabled}
            isRequired={financial.incomeStatement.personnel.required}
            placeholder={'Enter Personnel Expenses'}
            label={CompanyPropertyLabelMap.incomeStatementPersonnel as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.personnel.value}
            onBlur={(value) => updateIncomeStatement('personnel', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.personnel.value}
            isTouched={touchedInputs.incomeStatement.personnel}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.utilities.disabled}
            isRequired={financial.incomeStatement.utilities.required}
            label={CompanyPropertyLabelMap.incomeStatementUtilities as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.utilities.value}
            onBlur={(value) => updateIncomeStatement('utilities', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.utilities.value}
            isTouched={touchedInputs.incomeStatement.utilities}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.overhead.disabled}
            isRequired={financial.incomeStatement.overhead.required}
            label={CompanyPropertyLabelMap.incomeStatementOverhead as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.overhead.value}
            onBlur={(value) => updateIncomeStatement('overhead', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.overhead.value}
            isTouched={touchedInputs.incomeStatement.overhead}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.financialExpenses.disabled}
            isRequired={financial.incomeStatement.financialExpenses.required}
            label={CompanyPropertyLabelMap.incomeStatementFinancialExpenses as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.financialExpenses.value}
            onBlur={(value) => updateIncomeStatement('financialExpenses', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.financialExpenses.value}
            isTouched={touchedInputs.incomeStatement.financialExpenses}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.sellingAndAdministrative.disabled}
            isRequired={financial.incomeStatement.sellingAndAdministrative.required}
            label={CompanyPropertyLabelMap.incomeStatementSellingAndAdministrative as string}
            type="number"
            placeholder={'Enter Selling and Administrative Expenses'}
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.sellingAndAdministrative.value}
            onBlur={(value) => updateIncomeStatement('sellingAndAdministrative', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.sellingAndAdministrative.value}
            isTouched={touchedInputs.incomeStatement.sellingAndAdministrative}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.depreciationAndAmortization.disabled}
            isRequired={financial.incomeStatement.depreciationAndAmortization.required}
            label={CompanyPropertyLabelMap.incomeStatementDepreciationAndAmortization as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.depreciationAndAmortization.value}
            onBlur={(value) => updateIncomeStatement('depreciationAndAmortization', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.depreciationAndAmortization.value}
            isTouched={touchedInputs.incomeStatement.depreciationAndAmortization}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.otherExpenses.disabled}
            isRequired={financial.incomeStatement.otherExpenses.required}
            label={CompanyPropertyLabelMap.incomeStatementOtherExpenses as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.otherExpenses.value}
            onBlur={(value) => updateIncomeStatement('otherExpenses', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.otherExpenses.value}
            isTouched={touchedInputs.incomeStatement.otherExpenses}
          />
        </div>
      </div>
      <span className="title title-s"><b>Summary</b></span>
      <div className="grid">
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.totalNetRevenues.disabled}
            isRequired={financial.incomeStatement.totalNetRevenues.required}
            label={CompanyPropertyLabelMap.incomeStatementTotalNetRevenues as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.totalNetRevenues.value}
            onBlur={(value) => updateIncomeStatement('totalNetRevenues', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.totalNetRevenues.value}
            isTouched={touchedInputs.incomeStatement.totalNetRevenues}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.totalExpenses.disabled}
            isRequired={financial.incomeStatement.totalExpenses.required}
            label={CompanyPropertyLabelMap.incomeStatementTotalExpenses as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.totalExpenses.value}
            onBlur={(value) => updateIncomeStatement('totalExpenses', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.totalExpenses.value}
            isTouched={touchedInputs.incomeStatement.totalExpenses}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.netIncomeBeforeTaxes.disabled}
            isRequired={financial.incomeStatement.netIncomeBeforeTaxes.required}
            label={CompanyPropertyLabelMap.incomeStatementNetIncomeBeforeTaxes as string}
            type="number"
            allowNegative={true}
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.netIncomeBeforeTaxes.value}
            onBlur={(value) => updateIncomeStatement('netIncomeBeforeTaxes', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.netIncomeBeforeTaxes.value}
            isTouched={touchedInputs.incomeStatement.netIncomeBeforeTaxes}
          />
        </div>
      </div><span className="title title-s"><b>Earnings</b></span>
      <div className="grid">
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.corporateIncomeTaxes.disabled}
            isRequired={financial.incomeStatement.corporateIncomeTaxes.required}
            label={CompanyPropertyLabelMap.incomeStatementCorporateIncomeTaxes as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.corporateIncomeTaxes.value}
            onBlur={(value) => updateIncomeStatement('corporateIncomeTaxes', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.corporateIncomeTaxes.value}
            isTouched={touchedInputs.incomeStatement.corporateIncomeTaxes}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.netIncomeAfterTaxes.disabled}
            isRequired={financial.incomeStatement.netIncomeAfterTaxes.required}
            label={CompanyPropertyLabelMap.incomeStatementNetIncomeAfterTaxes as string}
            type="number"
            allowNegative={true}
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.netIncomeAfterTaxes.value}
            onBlur={(value) => updateIncomeStatement('netIncomeAfterTaxes', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.netIncomeAfterTaxes.value}
            isTouched={touchedInputs.incomeStatement.netIncomeAfterTaxes}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.dividensPaid.disabled}
            isRequired={financial.incomeStatement.dividensPaid.required}
            label={CompanyPropertyLabelMap.incomeStatementDividensPaid as string}
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.dividensPaid.value}
            onBlur={(value) => updateIncomeStatement('dividensPaid', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.dividensPaid.value}
            isTouched={touchedInputs.incomeStatement.dividensPaid}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.incomeStatement.retainedEarningsForTheYear.disabled}
            isRequired={financial.incomeStatement.retainedEarningsForTheYear.required}
            label={CompanyPropertyLabelMap.incomeStatementRetainedEarningsForTheYear as string}
            type="number"
            allowNegative={true}
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.incomeStatement.retainedEarningsForTheYear.value}
            onBlur={(value) => updateIncomeStatement('retainedEarningsForTheYear', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.incomeStatement.retainedEarningsForTheYear.value}
            isTouched={touchedInputs.incomeStatement.retainedEarningsForTheYear}
          />
        </div>
      </div>
      <span className="icon-header icon-header--header-right " id="">
        <span className="icon-header__inner">
          <span className="title title-s"><b>Balance sheets ({financial.year})</b></span>
        </span>
      </span>
      <span className="title title-s"><b>Assets</b></span>
      <div className="gi-12">
        <span className="title title-s"><b>Current Assets</b></span>
      </div>
      <div className="grid">
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.cashAndEquivalents.disabled}
            isRequired={financial.balanceSheet.cashAndEquivalents.required}
            label={CompanyPropertyLabelMap.balanceSheetCashAndEquivalents as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.cashAndEquivalents.value}
            onBlur={(value) => updateBalanceSheet('cashAndEquivalents', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.cashAndEquivalents.value}
            isTouched={touchedInputs.balanceSheet.cashAndEquivalents}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.accountsReceivable.disabled}
            isRequired={financial.balanceSheet.accountsReceivable.required}
            label={CompanyPropertyLabelMap.balanceSheetAccountsReceivable as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.accountsReceivable.value}
            onBlur={(value) => updateBalanceSheet('accountsReceivable', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.accountsReceivable.value}
            isTouched={touchedInputs.balanceSheet.accountsReceivable}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.inventories.disabled}
            isRequired={financial.balanceSheet.inventories.required}
            label={CompanyPropertyLabelMap.balanceSheetInventories as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.inventories.value}
            onBlur={(value) => updateBalanceSheet('inventories', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.inventories.value}
            isTouched={touchedInputs.balanceSheet.inventories}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.otherCurrentAssets.disabled}
            isRequired={financial.balanceSheet.otherCurrentAssets.required}
            label={CompanyPropertyLabelMap.balanceSheetOtherCurrentAssets as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.otherCurrentAssets.value}
            onBlur={(value) => updateBalanceSheet('otherCurrentAssets', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.otherCurrentAssets.value}
            isTouched={touchedInputs.balanceSheet.otherCurrentAssets}
          />
        </div>
      </div>
      <div className="gi-12">
        <span className="title title-s"><b>Fixed Assets</b></span>
      </div>
      <div className="grid">
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.propertyPlantAndEquipment.disabled}
            isRequired={financial.balanceSheet.propertyPlantAndEquipment.required}
            label={CompanyPropertyLabelMap.balanceSheetPropertyPlantAndEquipment as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.propertyPlantAndEquipment.value}
            onBlur={(value) => updateBalanceSheet('propertyPlantAndEquipment', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.propertyPlantAndEquipment.value}
            isTouched={touchedInputs.balanceSheet.propertyPlantAndEquipment}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.proprietaryRights.disabled}
            isRequired={financial.balanceSheet.proprietaryRights.required}
            label={CompanyPropertyLabelMap.balanceSheetProprietaryRights as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.proprietaryRights.value}
            onBlur={(value) => updateBalanceSheet('proprietaryRights', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.proprietaryRights.value}
            isTouched={touchedInputs.balanceSheet.proprietaryRights}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.accumulatedDepreciation.disabled}
            isRequired={financial.balanceSheet.accumulatedDepreciation.required}
            label={CompanyPropertyLabelMap.balanceSheetAccumulatedDepreciation as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.accumulatedDepreciation.value}
            onBlur={(value) => updateBalanceSheet('accumulatedDepreciation', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.accumulatedDepreciation.value}
            isTouched={touchedInputs.balanceSheet.accumulatedDepreciation}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.otherFixedAssets.disabled}
            isRequired={financial.balanceSheet.otherFixedAssets.required}
            label={CompanyPropertyLabelMap.balanceSheetOtherFixedAssets as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.otherFixedAssets.value}
            onBlur={(value) => updateBalanceSheet('otherFixedAssets', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.otherFixedAssets.value}
            isTouched={touchedInputs.balanceSheet.otherFixedAssets}
          />
        </div>
      </div>
      <span className="title title-s"><b>Liabilities and Owner's Equity</b></span>
      <div className="gi-12">
        <span className="title title-s"><b>Liabilities</b></span>
      </div>
      <div className="gi-12">
        <span className="title title-s"><b>Current Liabilities</b></span>
      </div>
      <div className="grid">
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.accountsPayable.disabled}
            isRequired={financial.balanceSheet.accountsPayable.required}
            label={CompanyPropertyLabelMap.balanceSheetAccountsPayable as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.accountsPayable.value}
            onBlur={(value) => updateBalanceSheet('accountsPayable', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.accountsPayable.value}
            isTouched={touchedInputs.balanceSheet.accountsPayable}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.shortTermLoans.disabled}
            isRequired={financial.balanceSheet.shortTermLoans.required}
            label={CompanyPropertyLabelMap.balanceSheetShortTermLoans as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.shortTermLoans.value}
            onBlur={(value) => updateBalanceSheet('shortTermLoans', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.shortTermLoans.value}
            isTouched={touchedInputs.balanceSheet.shortTermLoans}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.taxesPayable.disabled}
            isRequired={financial.balanceSheet.taxesPayable.required}
            label={CompanyPropertyLabelMap.balanceSheetTaxesPayable as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.taxesPayable.value}
            onBlur={(value) => updateBalanceSheet('taxesPayable', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.taxesPayable.value}
            isTouched={touchedInputs.balanceSheet.taxesPayable}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.otherCurrentLiabilities.disabled}
            isRequired={financial.balanceSheet.otherCurrentLiabilities.required}
            label={CompanyPropertyLabelMap.balanceSheetOtherCurrentLiabilities as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.otherCurrentLiabilities.value}
            onBlur={(value) => updateBalanceSheet('otherCurrentLiabilities', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.otherCurrentLiabilities.value}
            isTouched={touchedInputs.balanceSheet.otherCurrentLiabilities}
          />
        </div>
      </div>
      <div className="gi-12">
        <span className="title title-s"><b>Long - Term Liabilities</b></span>
      </div>
      <div className="grid">
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.longTermLoanes.disabled}
            isRequired={financial.balanceSheet.longTermLoanes.required}
            label={CompanyPropertyLabelMap.balanceSheetLongTermLoanes as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.longTermLoanes.value}
            onBlur={(value) => updateBalanceSheet('longTermLoanes', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.longTermLoanes.value}
            isTouched={touchedInputs.balanceSheet.longTermLoanes}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.shareHoldersLoans.disabled}
            isRequired={financial.balanceSheet.shareHoldersLoans.required}
            label={CompanyPropertyLabelMap.balanceSheetShareHoldersLoans as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.shareHoldersLoans.value}
            onBlur={(value) => updateBalanceSheet('shareHoldersLoans', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.shareHoldersLoans.value}
            isTouched={touchedInputs.balanceSheet.shareHoldersLoans}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.deferredIncomeTaxes.disabled}
            isRequired={financial.balanceSheet.deferredIncomeTaxes.required}
            label={CompanyPropertyLabelMap.balanceSheetDeferredIncomeTaxes as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.deferredIncomeTaxes.value}
            onBlur={(value) => updateBalanceSheet('deferredIncomeTaxes', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.deferredIncomeTaxes.value}
            isTouched={touchedInputs.balanceSheet.deferredIncomeTaxes}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.otherLongTermLiabilites.disabled}
            isRequired={financial.balanceSheet.otherLongTermLiabilites.required}
            label={CompanyPropertyLabelMap.balanceSheetOtherLongTermLiabilites as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.otherLongTermLiabilites.value}
            onBlur={(value) => updateBalanceSheet('otherLongTermLiabilites', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.otherLongTermLiabilites.value}
            isTouched={touchedInputs.balanceSheet.otherLongTermLiabilites}
          />
        </div>
      </div>
      <div className="gi-12">
        <span className="title title-s"><b>Owner' Equity</b></span>
      </div>
      <div className="grid">
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.registeredShares.disabled}
            isRequired={financial.balanceSheet.registeredShares.required}
            label={CompanyPropertyLabelMap.balanceSheetRegisteredShares as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.registeredShares.value}
            onBlur={(value) => updateBalanceSheet('registeredShares', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.registeredShares.value}
            isTouched={touchedInputs.balanceSheet.registeredShares}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.additionalPaidInCapital.disabled}
            isRequired={financial.balanceSheet.additionalPaidInCapital.required}
            label={CompanyPropertyLabelMap.balanceSheetAdditionalPaidInCapital as string}
            type="number"
            allowNegative={true}
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.additionalPaidInCapital.value}
            onBlur={(value) => updateBalanceSheet('additionalPaidInCapital', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.additionalPaidInCapital.value}
            isTouched={touchedInputs.balanceSheet.additionalPaidInCapital}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.accumulatedRetainedEarnings.disabled}
            isRequired={financial.balanceSheet.accumulatedRetainedEarnings.required}
            label={CompanyPropertyLabelMap.balanceSheetAccumulatedRetainedEarnings as string}
            type="number"
            allowNegative={true}
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.accumulatedRetainedEarnings.value}
            onBlur={(value) => updateBalanceSheet('accumulatedRetainedEarnings', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.accumulatedRetainedEarnings.value}
            isTouched={touchedInputs.balanceSheet.accumulatedRetainedEarnings}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.otherOwnersEquity.disabled}
            isRequired={financial.balanceSheet.otherOwnersEquity.required}
            label={CompanyPropertyLabelMap.balanceSheetOtherOwnersEquity as string}
            type="number"
            allowNegative={true}
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.otherOwnersEquity.value}
            onBlur={(value) => updateBalanceSheet('otherOwnersEquity', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.otherOwnersEquity.value}
            isTouched={touchedInputs.balanceSheet.otherOwnersEquity}
          />
        </div>
      </div>
      <div className="gi-12">
        <span className="title title-s"><b>Summary</b></span>
      </div>
      <div className="grid">
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.totalAssets.disabled}
            isRequired={financial.balanceSheet.totalAssets.required}
            label={CompanyPropertyLabelMap.balanceSheetTotalAssets as string} type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.totalAssets.value}
            onBlur={(value) => updateBalanceSheet('totalAssets', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.totalAssets.value}
            isTouched={touchedInputs.balanceSheet.totalAssets}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.totalLiabilites.disabled}
            isRequired={financial.balanceSheet.totalLiabilites.required}
            label={CompanyPropertyLabelMap.balanceSheetTotalLiabilites as string} 
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.totalLiabilites.value}
            onBlur={(value) => updateBalanceSheet('totalLiabilites', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.totalLiabilites.value}
            isTouched={touchedInputs.balanceSheet.totalLiabilites}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.ownersEquity.disabled}
            isRequired={financial.balanceSheet.ownersEquity.required}
            label={CompanyPropertyLabelMap.balanceSheetOwnersEquity as string}
            type="number"
            allowNegative={true}
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.ownersEquity.value}
            onBlur={(value) => updateBalanceSheet('ownersEquity', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.ownersEquity.value}
            isTouched={touchedInputs.balanceSheet.ownersEquity}
          />
        </div>
        <div className="gi-3">
          <TextFieldWithPriceComponent
            disabled={financial.balanceSheet.liabilitiesAndOwnersEquity.disabled}
            isRequired={financial.balanceSheet.liabilitiesAndOwnersEquity.required}
            label={CompanyPropertyLabelMap.balanceSheetLiabilitiesAndOwnersEquity as string} 
            type="number"
            uploadValueKey={CompanyAttributeKey.Financials.FinancialItem}
            value={financial.balanceSheet.liabilitiesAndOwnersEquity.value}
            onBlur={(value) => updateBalanceSheet('liabilitiesAndOwnersEquity', value)}
            isEditMode={isEditMode}
            originalValue={originalValue?.balanceSheet.liabilitiesAndOwnersEquity.value}
            isTouched={touchedInputs.balanceSheet.liabilitiesAndOwnersEquity}
          />
        </div>
      </div>
    </ >
  );

  return (
    <div className="item item--scs">
      <div className={isOpen ? "stack stack-m" : "stack h-stack"} style={isOpen ? { width: '100%' } : {}} >
        {renderHeader()}
        {
          isOpen ? renderForm() : null
        }
      </div>
    </div>
  );
};