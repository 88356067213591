import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { AppState } from "../root.reducer";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CreditHistoryDispatchProps, CreditHistoryPage, CreditHistoryStateProps } from './CreditHistoryPage.component';
import { creditHistorySelector, isCreditHistoryLoadingSelector } from './credit-history.selector';
import { creditHistoryLoadedSuccessfulAction, creditHistoryLoadingAction } from './credit-history.actions';
import { accountService } from '../account-settings/account.service';
import { gravityUseAsSelector } from '../auth/authentication.selectors';
import { workspaceService } from '../workspace-settings/workspace.service';

const mapStateToProps = (state: AppState): CreditHistoryStateProps => ({
  isLoading: isCreditHistoryLoadingSelector(state),
  creditHistory: creditHistorySelector(state),
  useGravityAs: gravityUseAsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): CreditHistoryDispatchProps => ({
  getHistory: async (useGravityAs?: number) => {
    dispatch(creditHistoryLoadingAction());

    if (useGravityAs) {
      const creditHistory = await workspaceService.getCreditHistory();
      dispatch(creditHistoryLoadedSuccessfulAction(creditHistory));
    } else {
      const creditHistory = await accountService.getCreditHistory();
      dispatch(creditHistoryLoadedSuccessfulAction(creditHistory));
    }

  }
});

export default withRouter(
  connect<CreditHistoryStateProps, CreditHistoryDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps, mapDispatchToProps
  )(CreditHistoryPage)
);
