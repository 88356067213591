import React, { useState } from 'react';
import { FinancialDetailsCompanyUploadTabComponent } from './company-upload-tabs/FinancialDetailsCompanyUploadTab.component';
import { ManagementContactInfoCompanyUploadTabComponent } from './company-upload-tabs/ManagementContactInfoCompanyUploadTab.component';
import { ProductServicesCompanyUploadTabComponent } from './company-upload-tabs/ProductServicesCompanyUploadTab.component';
import { DocumentsAttachmentsUploadTabComponent } from './company-upload-tabs/DocumentsAttachmentsUploadTab.component';
import SummaryUploadTab from './company-upload-tabs/SummaryUploadTab.container';
import { CompanyUploadSummary, CreditMap, TouchedFinancialInputsModel, TouchedFinancialsModel, TouchedInputsModel, TouchedManagementContactsModel, TouchedProductsServicesInputsModel, TouchedSummaryInputsModel } from './company-upload.models';
import { smoothScrollToTop } from '../scrollhelper';
import { RewardSummaryComponent } from './RewardSummary.component';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SectorModel } from '../models';
import { CompanyUploadModel } from './CompanyUpload.model';

enum CompanyUploadTabs {
    Summary = 'summary',
    ProductServices = 'productServices',
    ManagementContactInfo = 'managementContactInfo',
    FinancialDetails = 'financialDetails',
    DocumentsAttachments = 'documentsAttachments'
}

export interface CompanyUploadPageComponentProps {
    company: CompanyUploadModel;
    originalCompany?: CompanyUploadModel;
    isEditMode?: boolean;

    currencies: string[];
    updateCurrency: (currency: string, multiplier: number) => void;

    touchedInputs: TouchedInputsModel;
    setCompany: (company: CompanyUploadModel) => void;
    setTouchedFinancials: (financials: TouchedFinancialInputsModel[]) => void;

    isFormValid: boolean;
    checkIsSummaryFormValid: (model: any) => boolean;
    checkIsMainAddressFormValid: (model: any) => boolean;
    checkIsBusinessAddressFormValid: (model: any) => void;
    checkIsOtherContactDetailsFormValid: (model: any) => void;
    checkIsManagementContactsFormValid: (model: any) => boolean;

    originRoute?: string;
    requiredFields: string[];
    requiredMainAddressFields: string[];
    requiredBusinessAddressFields: string[];
    requiredOtherContactFields: string[];
    companyCredits: CreditMap;
    sectors: SectorModel[];

    trySaveCompany: (creditReward: number) => void;
    rewardSummaryCalculationMethod: (company: CompanyUploadModel) => number;
}

const CompanyUploadPageComponent: React.FC<CompanyUploadPageComponentProps & RouteComponentProps> = ({
    originRoute,
    company,
    originalCompany,
    isEditMode,
    updateCurrency,
    setCompany,
    currencies,
    companyCredits,
    requiredFields,
    requiredMainAddressFields,
    requiredBusinessAddressFields,
    requiredOtherContactFields,
    trySaveCompany,
    touchedInputs,
    setTouchedFinancials,
    checkIsSummaryFormValid,
    checkIsMainAddressFormValid,
    checkIsBusinessAddressFormValid,
    checkIsOtherContactDetailsFormValid,
    checkIsManagementContactsFormValid,
    isFormValid,
    sectors,
    rewardSummaryCalculationMethod,
    history
}) => {
    const [touchedSummaryInputs, setTouchedSummaryInputs] = useState<TouchedSummaryInputsModel>({
        summary: {},
        mainAddress: {},
        businessAddress: {},
        otherContactDetails: {},
    });

    const [touchedProductsServicesInputs, setTouchedProductsServicesInputs] = useState<TouchedProductsServicesInputsModel>({
        products: false,
        services: false,
        brands: false,
    });
    const [touchedManagementContacts, setTouchedManagementContacts] = useState<TouchedManagementContactsModel>({
        managementContacts: originalCompany?.managementContacts.map(_ => ({
            individual: {},
            frontDeskOrDepartment: {}
        })) ?? [],
    });

    // const [touchedFinancials, setTouchedFinancials] = useState<TouchedFinancialsModel>({
    //     financials: company?.financials.map(_ => ({
    //         incomeStatement: {},
    //         balanceSheet: {}
    //     })) ?? [],
    // });


    const [selectedTab, setSelectedTab] = useState(CompanyUploadTabs.Summary);

    const disabledStyle: any = {
        "--color": "var(--text-alt-color)",
        "--background-color": "var(--disabled-color)"
    };

    const successColor: any = {
        "--color": "var(--text-alt-color)",
        "--background-color": "var(--success-color)"
    };

    function shallowEqual(required: any, model: any) {
        const requiredKeys = Object.keys(required);

        for (let key of requiredKeys) {
            if (required[key] !== model[key]) {
                return false;
            }
        }

        return true;
    }

    const tabs = {
        [CompanyUploadTabs.Summary]: {
            header: 'Summary',
            component: <SummaryUploadTab
                isEditMode={isEditMode}
                originalValue={originalCompany?.summary}
                requiredFields={requiredFields}
                requiredMainAddressFields={requiredMainAddressFields}
                requiredBusinessAddressFields={requiredBusinessAddressFields}
                requiredOtherContactFields={requiredOtherContactFields}
                touchedInputs={touchedSummaryInputs}
                companyCredits={companyCredits}
                summary={company.summary}
                sectorList={sectors}
                isValid={() => {
                    const allFields = getAllSummaryFields();
                    const allTouched1 = shallowEqual(allFields.summary, touchedSummaryInputs.summary);
                    const allTouched2 = shallowEqual(allFields.businessAddress, touchedSummaryInputs.businessAddress);
                    const allTouched3 = shallowEqual(allFields.mainAddress, touchedSummaryInputs.mainAddress);
                    const allTouched4 = shallowEqual(allFields.otherContactDetails, touchedSummaryInputs.otherContactDetails);
                    const allTouched = allTouched1 && allTouched2 && allTouched3 && allTouched4;
                    return (allTouched && checkIsSummaryFormValid(company) && checkIsMainAddressFormValid(company) && checkIsOtherContactDetailsFormValid(company)) || !allTouched;
                }}
                updateSummary={
                    (key: keyof CompanyUploadSummary, value) => {
                        const newValue = company.summary;
                        newValue[key].value = value;

                        if (!touchedSummaryInputs.summary[key]) {
                            setTouchedSummaryInputs({
                                ...touchedSummaryInputs,
                                summary: {
                                    ...touchedSummaryInputs.summary,
                                    [key]: true
                                }
                            });
                        }

                        checkIsSummaryFormValid(company);

                        setCompany({
                            ...company,
                            summary: newValue
                        } as any);
                    }
                }
                updateMainAddress={
                    (key: keyof CompanyUploadModel['summary']['mainAddress'], value: any) => {
                        const newValue = company.summary.mainAddress;
                        newValue[key].value = value;

                        if (!touchedSummaryInputs.mainAddress[key]) {
                            setTouchedSummaryInputs({
                                ...touchedSummaryInputs,
                                mainAddress: {
                                    ...touchedSummaryInputs.mainAddress,
                                    [key]: true
                                }
                            });
                        }

                        checkIsMainAddressFormValid(company);

                        setCompany({
                            ...company,
                            summary: {
                                ...company.summary,
                                mainAddress: newValue,
                            }
                        } as any);
                    }
                }
                updateBusinessAddress={
                    (key: keyof CompanyUploadModel['summary']['businessAddress'], value: any) => {
                        const newValue = company.summary.businessAddress;
                        newValue[key].value = value;

                        if (!touchedSummaryInputs.businessAddress[key]) {
                            setTouchedSummaryInputs({
                                ...touchedSummaryInputs,
                                businessAddress: {
                                    ...touchedSummaryInputs.businessAddress,
                                    [key]: true
                                }
                            });
                        }

                        checkIsBusinessAddressFormValid(company);

                        setCompany({
                            ...company,
                            summary: {
                                ...company.summary,
                                businessAddress: newValue,
                            }
                        } as any);
                    }
                }
                updateOtherContactDetails={
                    (key: keyof CompanyUploadModel['summary']['otherContactDetails'], value: any) => {
                        const newValue = company.summary.otherContactDetails;
                        newValue[key].value = value;

                        if (!touchedSummaryInputs.otherContactDetails[key]) {
                            setTouchedSummaryInputs({
                                ...touchedSummaryInputs,
                                otherContactDetails: {
                                    ...touchedSummaryInputs.otherContactDetails,
                                    [key]: true
                                }
                            });
                        }

                        checkIsOtherContactDetailsFormValid(company);

                        setCompany({
                            ...company,
                            summary: {
                                ...company.summary,
                                otherContactDetails: newValue,
                            }
                        } as any);
                    }
                }
            />
        },
        [CompanyUploadTabs.ProductServices]: {
            header: 'Products & Services',
            component:
                <ProductServicesCompanyUploadTabComponent
                    isEditMode={isEditMode}
                    originalValue={originalCompany?.productsAndServices}
                    touchedInputs={touchedProductsServicesInputs}
                    productsAndServices={company.productsAndServices}
                    updateDetail={
                        (key: keyof CompanyUploadModel['productsAndServices'], value: any) => {
                            const newValue = company.productsAndServices;
                            newValue[key]!.value = value;

                            if (!touchedProductsServicesInputs[key]) {
                                setTouchedProductsServicesInputs({
                                    ...touchedProductsServicesInputs,
                                    [key]: true
                                });
                            }

                            setCompany({
                                ...company,
                                productsAndServices: newValue
                            } as any);
                        }
                    }
                />
        },
        [CompanyUploadTabs.ManagementContactInfo]: {
            header: 'Management Contacts',
            component: <ManagementContactInfoCompanyUploadTabComponent
                isEditMode={isEditMode}
                isValid={() => checkIsManagementContactsFormValid(company)}
                originalValue={originalCompany?.managementContacts}
                touchedInputs={touchedManagementContacts}
                deleteManagementContact={(index: number) => {
                    setCompany({ ...company } as any);

                    setTouchedManagementContacts({
                        managementContacts: touchedManagementContacts.managementContacts.splice(index, 1)
                    });
                }}
                addManagementContact={() => {
                    CompanyUploadModel.addManagementContact(company);
                    setCompany({ ...company } as any);

                    setTouchedManagementContacts({
                        managementContacts:
                            touchedManagementContacts.managementContacts.concat([
                                {
                                    individual: {},
                                    frontDeskOrDepartment: {}
                                }
                            ])
                    })
                }}
                updateIndividual={(key: keyof CompanyUploadModel['managementContacts'][0]['individual'], index: number, value: any) => {
                    setTouchedManagementContacts({
                        managementContacts:
                            touchedManagementContacts.managementContacts.map((mc, _index) => {
                                if (index === _index) {
                                    return {
                                        ...mc,
                                        individual: {
                                            ...mc.individual,
                                            [key]: true
                                        }
                                    };
                                }
                                return mc;
                            })
                    })

                    setCompany({
                        ...company,
                        managementContacts: company.managementContacts.map((mc, _index) => {
                            if (index === _index) {
                                return {
                                    ...mc,
                                    individual: {
                                        ...mc.individual,
                                        [key]: value
                                    }
                                };
                            }
                            return mc;
                        })
                    } as any);
                }}
                updateFrontDesk={(key: keyof CompanyUploadModel['managementContacts'][0]['frontDeskOrDepartment'], index: number, value: any) => {
                    setTouchedManagementContacts({
                        managementContacts:
                            touchedManagementContacts.managementContacts.map((mc, _index) => {
                                if (index === _index) {
                                    return {
                                        ...mc,
                                        frontDeskOrDepartment: {
                                            ...mc.frontDeskOrDepartment,
                                            [key]: true
                                        }
                                    };
                                }
                                return mc;
                            })
                    })

                    setCompany({
                        ...company,
                        managementContacts: company.managementContacts.map((mc, _index) => {
                            if (index === _index) {
                                return {
                                    ...mc,
                                    frontDeskOrDepartment: {
                                        ...mc.frontDeskOrDepartment,
                                        [key]: value
                                    }
                                };
                            }
                            return mc;
                        })
                    } as any);
                }}
                managementContacts={company.managementContacts}
            />
        },
        [CompanyUploadTabs.FinancialDetails]: {
            header: 'Financials',
            component: <FinancialDetailsCompanyUploadTabComponent
                isEditMode={isEditMode}
                originalValue={originalCompany?.financials}
                touchedInputs={touchedInputs}
                currencies={currencies}
                // updateCurrency={(currency: string, multiplier: number) => {
                //     setCompany({
                //         ...company,
                //         financialCurrency: currency,
                //         financialMultiplier: multiplier,
                //     } as any)
                // }}
                updateCurrency={updateCurrency}
                updateBalanceSheet={(key: keyof CompanyUploadModel['financials'][0]['balanceSheet'], index: number, value: any) => {
                    setTouchedFinancials(
                        touchedInputs.financials.map((f, _index) => {
                            if (index === _index) {
                                return {
                                    ...f,
                                    balanceSheet: {
                                        ...f.balanceSheet,
                                        [key]: true
                                    }
                                };
                            }
                            return f;
                        })
                    )

                    setCompany({
                        ...company,
                        financials: company.financials.map((f, _index) => {
                            if (index === _index) {
                                return {
                                    ...f,
                                    balanceSheet: {
                                        ...f.balanceSheet,
                                        [key]: value
                                    }
                                };
                            }
                            return f;
                        })
                    } as any);
                }}
                updateIncomeStatement={(key: keyof CompanyUploadModel['financials'][0]['incomeStatement'], index: number, value: any) => {
                    setTouchedFinancials(
                        touchedInputs.financials.map((f, _index) => {
                            if (index === _index) {
                                return {
                                    ...f,
                                    incomeStatement: {
                                        ...f.incomeStatement,
                                        [key]: true
                                    }
                                };
                            }
                            return f;
                        })
                    )

                    setCompany({
                        ...company,
                        financials: company.financials.map((f, _index) => {
                            if (index === _index) {
                                return {
                                    ...f,
                                    incomeStatement: {
                                        ...f.incomeStatement,
                                        [key]: value
                                    }
                                };
                            }
                            return f;
                        })
                    } as any);
                }}
                financialDetails={company.financials}
            />
        },
        [CompanyUploadTabs.DocumentsAttachments]: {
            header: 'Supporting Documents',
            component: <DocumentsAttachmentsUploadTabComponent
                documentsAndAttachments={company.documentsAndAttachments}
                updateDocumentsAndAttachments={(documentsAndAttachments: CompanyUploadModel['documentsAndAttachments']) => {
                    setCompany({
                        ...company,
                        documentsAndAttachments
                    } as any);
                }}
            />
        },
    };

    const tabKeys = Object.keys(tabs);
    const currentTabIndex = tabKeys.indexOf(selectedTab);

    const renderTabHeader = (tab: CompanyUploadTabs) => {
        const tabComponent = tabs[tab];
        const component = tabComponent.component;
        const error = (component.props.isValid && component.props.isValid()) || !component.props.isValid ? '' : 'error';
        return (
            <li className={`pointer ${error}`} key={tabComponent.header} onClick={() => setSelectedTab(tab)}>
                <div className="h-stack h-stack-xs ai-c">
                    <span className="counter counter--rounded" style={selectedTab === tab ? successColor : disabledStyle}>{tabKeys.indexOf(tab) + 1}</span>
                    <p className="fw-b">{tabComponent.header}</p>
                </div>
            </li >
        );
    };

    const renderTab = (tab: CompanyUploadTabs) => tabs[tab].component;

    const moveToNextTab = () => {
        if (currentTabIndex < tabKeys.length - 1) {
            smoothScrollToTop();
            setSelectedTab(tabKeys[currentTabIndex + 1] as any);
        }
    };

    const moveToPreviousTab = () => {
        if (currentTabIndex > 0) {
            smoothScrollToTop();
            setSelectedTab(tabKeys[currentTabIndex - 1] as any);
        }
    };

    const getAllSummaryFields = () => {
        const allTouched = {
            summary: {},
            mainAddress: {},
            businessAddress: {},
            otherContactDetails: {},
        } as any;

        requiredFields.forEach((field: string) => {
            allTouched.summary[field] = true;
        });
        requiredMainAddressFields.forEach((field: string) => {
            allTouched.mainAddress[field] = true;
        });
        requiredMainAddressFields.forEach((field: string) => {
            allTouched.businessAddress[field] = true;
        });
        requiredOtherContactFields.forEach((field: string) => {
            allTouched.otherContactDetails[field] = true;
        });

        return allTouched;
    }

    const onSendForReview = () => {
        const allTouched = getAllSummaryFields();

        const creditReward = rewardSummaryCalculationMethod(company);

        setTouchedSummaryInputs(allTouched);

        setTouchedManagementContacts({
            managementContacts: company.managementContacts.map(mc => ({
                individual: {
                    name: !mc.individual.name.disabled,
                    position: !mc.individual.position.disabled,
                    emailAddress: (mc.individual.emailAddress.required || false) && !(mc.individual.emailAddress.disabled || true),
                    telephoneNumber: (mc.individual.telephoneNumber.required || false) && !(mc.individual.telephoneNumber.disabled || true),
                },
                frontDeskOrDepartment: {}
            })),
        })
        trySaveCompany(creditReward);
    };

    const lastPage = currentTabIndex >= tabKeys.length - 1;

    return <div className="section section--content">
        <div className="container">
            <div className="stack stack-m">
                <div className="grid">
                    <div className="gi-12">
                        <div className="stack stack-m">
                            <header>
                                <div className="h-stack h-stack-xs jc-sb m-fd-c">
                                    <div>
                                        <div className="stack stack-xs">
                                            <h1 className="title title-l">
                                                {
                                                    !isEditMode ? "Enter a New Company" : `Edit company - ${originalCompany?.summary.fullname.value}`
                                                }
                                            </h1>
                                            <p>Enter anything you know about the company. Each piece of information will be rewarded by the number of credits shown.</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="h-stack h-stack-xs m-fd-cr">
                                            <button className="button" onClick={() => onSendForReview()}>
                                                <span className="button__inner">
                                                    <span>Upload</span>
                                                </span>
                                            </button>
                                            <button className="button button--neutral" onClick={(e) => history.push(originRoute ?? '/')}>
                                                <span className="button__inner">
                                                    <span>Discard</span>
                                                </span>
                                            </button>
                                        </div>
                                        <div className="upload-batch">
                                            Click <a href="mailto:techsupport@gravity.surf">HERE</a> if you would like to upload<br />more than 10 companies in a batch.
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
                <div className="stack stack-m">
                    <div className="grid">
                        <div className="gi-12">
                            <div className="progress">
                                <div className="h-stack h-stack-s ai-c jc-sb fill">
                                    <ul className="h-stack h-stack-s no-mobile">
                                        {Object.keys(tabs).map(tab => renderTabHeader(tab as any as CompanyUploadTabs))}
                                    </ul>
                                    <div className="progress__sec">
                                        <div className="2 h-stack h-stack-xs ai-c">
                                            <button className="icon-button" onClick={() => moveToPreviousTab()}>
                                                <span className="icon-button__inner">
                                                    <svg className="icon icon--m">
                                                        <use xlinkHref="#icon-arrow-nav-back" />
                                                    </svg>
                                                    <span className="visually-hidden">Back</span>
                                                </span>
                                            </button>
                                            <p className="annotation">Step {currentTabIndex + 1}/5</p>
                                            <button className="icon-button" onClick={() => moveToNextTab()}>
                                                <span className="icon-button__inner">
                                                    <svg className="icon icon--m">
                                                        <use xlinkHref="#icon-arrow-nav-forward" />
                                                    </svg>
                                                    <span className="visually-hidden">Next</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid credit-reward credit-reward ">
                        <div className="gi-9">
                            <div className="upload-form">
                                {
                                    renderTab(selectedTab)
                                }
                            </div>
                        </div>
                        <div className="gi-3">
                            <RewardSummaryComponent company={company} calculationMethod={rewardSummaryCalculationMethod} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="gi-12">
                            <nav className="progress-nav">
                                <div className="h-stack h-stack-s fill ai-c jc-sb m-fd-c">
                                    <button className="button button--secondary" disabled={currentTabIndex <= 0} onClick={() => moveToPreviousTab()}>
                                        <span className="button__inner">
                                            <span>Previous Step</span>
                                        </span>
                                    </button>
                                    <p className="annotation">Step {currentTabIndex + 1}/5</p>
                                    {!lastPage ?
                                        <button className="button button--secondary" onClick={() => moveToNextTab()}>
                                            <span className="button__inner">
                                                <span>Next Step</span>
                                            </span>
                                        </button> :
                                        <button className="button button--secondary" onClick={() => onSendForReview()}>
                                            <span className="button__inner">
                                                <span>Upload</span>
                                            </span>
                                        </button>
                                    }
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export const CompanyUploadPage = withRouter(CompanyUploadPageComponent);