import React from "react";
import { CompanyListItemModel } from "../saved-companies.models";
import { withRouter, RouteComponentProps } from 'react-router-dom';

export interface SavedCompanyListItemProps {
    company: CompanyListItemModel;
    isBlurred?: boolean;
}

const SavedCompanyListItem: React.FC<SavedCompanyListItemProps & RouteComponentProps> = ({ company, isBlurred, history }) => {
    const selectedProp: any = { selected: 'selected', blurred: isBlurred ? 'blurred' : null };

    const openCompany = (id: number) => {
        history.push(`/company/${id}`);
    };

    return (
        <li className="company-list-item"
            onClick={() => openCompany(company.savedCompanyId as number)}
        >
            <div className="item item--com" {...selectedProp}>
                <div className="h-stack h-stack-xs ai-c">
                    <div className="col col--name">
                        <div className="stack stack-xs">
                            <h3 className="title title-s">
                                {
                                    company.fullName.length > 30 ?
                                        `${company.fullName.substr(0, 30)}...` : company.fullName
                                }
                            </h3>

                            <ul className="h-stack h-stack-xs">
                                <li>
                                    <span className="chip">
                                        <a className="label" href="/">{company.industry ? company.industry : "Industry not specified"}</a>
                                    </span>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="col col--data">
                        <div className="stack stack-xs">
                            <span className="icon-label icon-label--label-right">
                                <span
                                    className="icon-label__inner">
                                    <svg className="icon icon--m">
                                        <use xlinkHref="#icon-location" />
                                    </svg>
                                    <span className="label">{company.countryOfFoundation}</span>
                                </span>
                            </span>
                            {/* <div className="fw-b">
                                <span className="icon-label icon-label--label-right">
                                    <span
                                        className="icon-label__inner">
                                        <svg className="icon icon--m">
                                            <use xlinkHref="#icon-gravity-credit" />
                                        </svg>
                                        <span className="label">{company.updatesAvailable > 0
                                            ? `${company.updatesAvailable} more company data is available to reveal with your credits`
                                            : 'No updates available'}  </span> 
                                    </span>
                                </span>
                            </div>*/}
                        </div>
                    </div>
                    <div className="col col--data">
                        <div className="stack stack-xs">
                            <div className="h-stack h-stack-xs m-fd-c">
                                {company.totalNetRevenues ?
                                    <div className="fill">
                                        <span className="icon-label icon-label--label-right">
                                            <span
                                                className="icon-label__inner">
                                                <svg className="icon icon--m">
                                                    <use xlinkHref="#icon-revenue" />
                                                </svg>
                                                <span className="label">{company.totalNetRevenues}</span>
                                            </span>
                                        </span>
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="col col--icon col--arrow">
                        <svg className="icon icon--m">
                            <use xlinkHref="#icon-arrow-nav-forward" />
                        </svg>
                    </div>
                </div>
            </div>
        </li>
    );
};


export const SavedCompanyListItemComponent = withRouter(SavedCompanyListItem);