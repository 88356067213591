import React from 'react';
import { AttributeType, CompanyPropertyLabelMap, SavedCompanyAttributeModel, SavedCompanyModel } from '../saved-company.models';
import { CompanyPropertyComponent } from './CompanyProperty.component';

export interface ManagementContactsTabProps {
    addPropertyToCart: (attribute: SavedCompanyAttributeModel, label: any, attributeName: string) => void;
    savedCompany: SavedCompanyModel;
}

export const ManagementContactsTab: React.FC<ManagementContactsTabProps> = ({ savedCompany, addPropertyToCart }) => {
    const brandColorStyle = { '--background-color': 'var(--brand-color)' } as any as React.CSSProperties;

    let numberOfAvailableProperies = 0;
    let totalPriceOfProperties = 0;

    const individual = [
        "name",
        "position",
        "location",
        "functions",
        "telephoneNumber",
        "emailAddress",
        "linkedIn",
    ];
    const frontDeskOrDepartment = [
        "location",
        "telephoneNumber",
        "emailAddress",
        "department",
        "departmentOther",
    ];

    savedCompany.managementContacts
        .forEach((managementContact) => {
            individual.forEach((propertyName) => {
                const model = managementContact.individual as any;
                const isAvailableToBuy = model[propertyName] && (!model[propertyName]?.value || model[propertyName].hasUpdate);

                if (isAvailableToBuy) {
                    numberOfAvailableProperies += 1;
                    totalPriceOfProperties += model[propertyName]?.creditPrice || 0;
                }
            });
            frontDeskOrDepartment.forEach((propertyName) => {
                const model = managementContact.frontDeskOrDepartment as any;
                const isAvailableToBuy = model[propertyName] && (!model[propertyName]?.value || model[propertyName].hasUpdate);

                if (isAvailableToBuy) {
                    numberOfAvailableProperies += 1;
                    totalPriceOfProperties += model[propertyName]?.creditPrice || 0;
                }
            });
        });

    const addPropertyToCartIfAvailable = (attribute: SavedCompanyAttributeModel, label: any, propertyName: string) => {
        if (attribute && (attribute.value == null || attribute.value === "" || attribute.value === undefined || attribute.hasUpdate)) {
            addPropertyToCart(attribute, label, propertyName);
        }
    };

    const revealAll = () => {
        savedCompany.managementContacts.forEach(managementContact => {
            addPropertyToCartIfAvailable(managementContact.individual.name, CompanyPropertyLabelMap.managementContactIndividualName, "managementContactIndividualName");
            addPropertyToCartIfAvailable(managementContact.individual.position, CompanyPropertyLabelMap.managementContactIndividualPosition, "managementContactIndividualPosition");
            addPropertyToCartIfAvailable(managementContact.individual.location, CompanyPropertyLabelMap.managementContactIndividualLocation, "managementContactIndividualLocation");
            addPropertyToCartIfAvailable(managementContact.individual.functions, CompanyPropertyLabelMap.managementContactIndividualFunctions, "managementContactIndividualFunctions");
            addPropertyToCartIfAvailable(managementContact.individual.telephoneNumber, CompanyPropertyLabelMap.managementContactIndividualTelephoneNumber, "managementContactIndividualTelephoneNumber");
            addPropertyToCartIfAvailable(managementContact.individual.emailAddress, CompanyPropertyLabelMap.managementContactIndividualEmailAddress, "managementContactIndividualEmailAddress");
            addPropertyToCartIfAvailable(managementContact.individual.linkedIn, CompanyPropertyLabelMap.managementContactIndividualLinkedIn, "managementContactIndividualLinkedIn");

            addPropertyToCartIfAvailable(managementContact.frontDeskOrDepartment.location, CompanyPropertyLabelMap.managementContactFrontDeskOrDepartmentLocation, "managementContactFrontDeskOrDepartmentLocation");
            addPropertyToCartIfAvailable(managementContact.frontDeskOrDepartment.telephoneNumber, CompanyPropertyLabelMap.managementContactFrontDeskOrDepartmentTelephoneNumber, "managementContactFrontDeskOrDepartmentTelephoneNumber");
            addPropertyToCartIfAvailable(managementContact.frontDeskOrDepartment.emailAddress, CompanyPropertyLabelMap.managementContactFrontDeskOrDepartmentEmailAddress, "managementContactFrontDeskOrDepartmentEmailAddress");
            addPropertyToCartIfAvailable(managementContact.frontDeskOrDepartment.department, CompanyPropertyLabelMap.managementContactFrontDeskOrDepartmentDepartment, "managementContactFrontDeskOrDepartmentDepartment");
            addPropertyToCartIfAvailable(managementContact.frontDeskOrDepartment.departmentOther, CompanyPropertyLabelMap.managementContactFrontDeskOrDepartmentDepartmentOther, "managementContactFrontDeskOrDepartmentDepartmentOther");
        });
    };

    const list = savedCompany.managementContacts.map(managementContact => (
        <div key={managementContact.id}>
            <div className="stack stack-s">
                <span className="icon-header icon-header--header-right">
                    <span className="icon-header__inner">
                        <svg className="icon icon--l ">
                            <use xlinkHref="#icon-general-details" />
                        </svg>
                        <span className="title title-s">Management Contact</span>
                    </span>
                </span>
                <span className="icon-header icon-header--header-right">
                    <span className="icon-header__inner">
                        <span className="title title-s">Individual</span>
                    </span>
                </span>
                <ul className="data-grid space">
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"individualName"}
                        attribute={managementContact.individual.name}
                        labelName={CompanyPropertyLabelMap["managementContactIndividualName"]}
                        attributeName={`managementContacts/${managementContact.id}/individual/name`} />
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"individualPosition"}
                        attribute={managementContact.individual.position}
                        labelName={CompanyPropertyLabelMap["managementContactIndividualPosition"]}
                        attributeName={`managementContacts/${managementContact.id}/individual/position`} />
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"individualLocation"}
                        attribute={managementContact.individual.location}
                        labelName={CompanyPropertyLabelMap["managementContactIndividualLocation"]}
                        attributeName={`managementContacts/${managementContact.id}/individual/location`} />
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        splitBySemicolon={true}
                        attributeType={AttributeType.ManagementContact}
                        key={"individualFunctions"}
                        attribute={managementContact.individual.functions}
                        labelName={CompanyPropertyLabelMap["managementContactIndividualFunctions"]}
                        attributeName={`managementContacts/${managementContact.id}/individual/functions`} />
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"individualTelephoneNumber"}
                        attribute={managementContact.individual.telephoneNumber}
                        labelName={CompanyPropertyLabelMap["managementContactIndividualTelephoneNumber"]}
                        attributeName={`managementContacts/${managementContact.id}/individual/telephoneNumber`}
                        format={(val) => {
                            if (val === '') return '';
                            const splitted = val.split('-');
                            return `+${splitted[0]}-${splitted[1]}-${splitted[2].replace(/\B(?=(\d{4})+(?!\d))/g, " ")}`;
                        }} />
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"individualEmailAddress"}
                        attribute={managementContact.individual.emailAddress}
                        labelName={CompanyPropertyLabelMap["managementContactIndividualEmailAddress"]}
                        attributeName={`managementContacts/${managementContact.id}/individual/emailAddress`} />
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"individualLinkedIn"}
                        attribute={managementContact.individual.linkedIn}
                        labelName={CompanyPropertyLabelMap["managementContactIndividualLinkedIn"]}
                        attributeName={`managementContacts/${managementContact.id}/individual/linkedIn`} />
                </ul>
                <span className="icon-header icon-header--header-right">
                    <span className="icon-header__inner">
                        <span className="title title-s">Front Desk or Department</span>
                    </span>
                </span>
                <ul className="data-grid">
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"frontDeskOrDepartmentLocation"}
                        attribute={managementContact.frontDeskOrDepartment.location}
                        labelName={CompanyPropertyLabelMap["managementContactFrontDeskOrDepartmentLocation"]}
                        attributeName={`managementContacts/${managementContact.id}/frontDeskOrDepartment/location`} />
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"frontDeskOrDepartmentTelephoneNumber"}
                        attribute={managementContact.frontDeskOrDepartment.telephoneNumber}
                        labelName={CompanyPropertyLabelMap["managementContactFrontDeskOrDepartmentTelephoneNumber"]}
                        attributeName={`managementContacts/${managementContact.id}/frontDeskOrDepartment/telephoneNumber`} />
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"frontDeskOrDepartmentEmailAddress"}
                        attribute={managementContact.frontDeskOrDepartment.emailAddress}
                        labelName={CompanyPropertyLabelMap["managementContactFrontDeskOrDepartmentEmailAddress"]}
                        attributeName={`managementContacts/${managementContact.id}/frontDeskOrDepartment/emailAddress`} />
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"frontDeskOrDepartmentDepartment"}
                        attribute={managementContact.frontDeskOrDepartment.department}
                        labelName={CompanyPropertyLabelMap["managementContactFrontDeskOrDepartmentDepartment"]}
                        attributeName={`managementContacts/${managementContact.id}/frontDeskOrDepartment/department`} />
                    <CompanyPropertyComponent
                        savedCompanyId={savedCompany.id}
                        attributeType={AttributeType.ManagementContact}
                        key={"frontDeskOrDepartmentDepartmentOther"}
                        attribute={managementContact.frontDeskOrDepartment.departmentOther}
                        labelName={CompanyPropertyLabelMap["managementContactFrontDeskOrDepartmentDepartmentOther"]}
                        attributeName={`managementContacts/${managementContact.id}/frontDeskOrDepartment/departmentOther`} />
                </ul>
            </div>
        </div>
    ));

    return (
        <>
            <div className="info-count">
                {numberOfAvailableProperies > 0 ?
                    <div className="h-stack h-stack-xs ai-c m-fd-c">
                        <p className="annotation">{numberOfAvailableProperies} {numberOfAvailableProperies === 1 ? "piece" : "pieces"} of information available on this page</p>
                        <button className="icon-chip icon-chip--label-left" style={brandColorStyle}
                            onClick={() => revealAll()}
                        >
                            <span className="icon-chip__inner">
                                <span className="label">Reveal all for <svg className="icon icon--m "><use xlinkHref="#icon-gravity-credit" /></svg> {totalPriceOfProperties}</span>
                            </span>
                        </button>
                    </div> : ""}
            </div>
            <div className="source-info">
                <p className="annotation">Move your cursor on <img className="source" src={`/assets/img/source-up.png`} alt="Source" /> to show the source of the last upload</p>
            </div>
            <div className="source-info">
                <p className="annotation">View previously entered values on revealed fields by clicking on <img className="source" src={`/assets/img/source-down.png`} alt="Source" /></p>
            </div>
            {list}
        </>
    );
};
