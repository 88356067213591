import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { AppState } from '../../root.reducer';
import { SocialConfirmFields } from './social-register.models';
import { authenticationService } from '../authentication.service';
import { RouteComponentProps } from 'react-router-dom';
import { GoogleConfirmDispatchProps, GoogleConfirmPage } from './GoogleConfirm.component';
import { AppRoutes } from '../../routes';
import { LoginResponse } from '../authentication.models';
import { accountLoadingAction } from '../../account-settings/account.actions';

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): GoogleConfirmDispatchProps => ({
  register: async (model: SocialConfirmFields) => {
    dispatch(accountLoadingAction());
    const response = await authenticationService.confirmGoogle(model);

    const loginResponse = response.data as LoginResponse;

    if (response.status === 200) {
      if (loginResponse.hasWorkspaceInvites) {
        window.location.href = `${AppRoutes.WorkspaceResponse}`;
      } else {
        window.location.href = '/';
      }
    }
  }
});

export default connect<{}, GoogleConfirmDispatchProps, RouteComponentProps, AppState>(
  null, mapDispatchToProps
)(GoogleConfirmPage);
