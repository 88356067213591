import React from "react";
import { SavedCompanySearch } from '../saved-companies.models';
import SavedSearchListItem from './SavedSearchListItem.container';
import { FullPageLoadingComponent } from '../../FullpageLoading.component';

export interface SavedSearchesListComponentProps {
  isLoading: boolean;
  savedSearchesList?: SavedCompanySearch[];
  useGravityAs?: number;
}

export const SavedSearchesListComponent: React.FC<SavedSearchesListComponentProps> = ({ isLoading, savedSearchesList, useGravityAs }) => {
  if (isLoading) {
    return <FullPageLoadingComponent/>;
  }

  if (!savedSearchesList?.length) {
    return <p>No saved searches.</p>;
  }

  return (
    <ul className="list">
      {savedSearchesList.map((savedSearch) => (
        <SavedSearchListItem
          key={savedSearch.id}
          savedCompanySearch={savedSearch}
          useGravityAs={useGravityAs}
        />
      ))}
    </ul >
  );
};
