import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import { WorkspaceModel, UpdateWorkspaceModel, InviteUserToWorkspaceModel } from './workspace.models';
import { WorkspaceSettingsComponent } from './WorkspaceSettings.component';
import { User } from '../auth/authentication.models';

export interface WorkspaceSettingsStateProps {
  workspaceId: number;
  isLoading: boolean;
  workspace: WorkspaceModel | undefined;
  user: User | undefined;
  error?: any;
}

export interface WorkspaceSettingsDispatchProps {
  getWorkspace(workspaceId: number): Promise<void>;
  updateWorkspace(workspace: UpdateWorkspaceModel): Promise<void>;
  deleteWorkspace(workspaceId: number): Promise<void>;
  inviteUser(workspaceId: number, user: InviteUserToWorkspaceModel): Promise<void>;
  removeTeamMember(workspaceId: number, email: string): Promise<void>;
  removeInvite(workspaceId: number, email: string): Promise<void>;
  cancelMembership(): Promise<void>;
  uploadCredit(workspaceId: number, amount: number, creditValue: number): Promise<void>;
}

const WorkspaceSettingsPageComponent: React.FC<WorkspaceSettingsStateProps & WorkspaceSettingsDispatchProps & RouteComponentProps> =
  ({
    workspaceId,
    isLoading,
    error,
    getWorkspace,
    updateWorkspace,
    deleteWorkspace,
    inviteUser,
    removeTeamMember,
    removeInvite,
    cancelMembership,
    workspace,
    uploadCredit,
    user }) => {
    useEffect(() => {
      getWorkspace(workspaceId);
    }, [getWorkspace, workspaceId]);

    if (isLoading || !workspace || !user) {
      return <FullPageLoadingComponent />;
    }

    return (
      <WorkspaceSettingsComponent
        user={user}
        workspace={workspace}
        updateWorkspace={updateWorkspace}
        deleteWorkspace={deleteWorkspace}
        inviteUser={inviteUser}
        cancelMembership={cancelMembership}
        removeTeamMember={removeTeamMember}
        removeInvite={removeInvite}
        uploadCredit={uploadCredit}
      />
    );
  };

export const WorkspaceSettingsPage = withRouter(WorkspaceSettingsPageComponent);