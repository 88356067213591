export const USE_WORKSPACE_AS_TOKEN = 'gravity_use_workspace_as';

export interface User {
    name: string;
    email: string;
    creditCount: number;
    workspaces: { name: string; id: number }[];
    deactivatedWorkspaces: { name: string; id: number }[];
}

export interface RegisterModel {
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
    password: string | undefined;
    passwordConfirm: string | undefined;
    industry: number | undefined;
    captchaToken: string | null;
    code?: string;
}

export interface LoginResponse {
    email: string;
    hasWorkspaceInvites: boolean;
}

export interface ResetPasswordModel {
    email: string;
    code: string;
    password: string;
    passwordConfirm: string;
}

export interface CompanyListModel {
    companyId: number;
    fullName: string;
}