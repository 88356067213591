import { SavedCompanyActions, CompanyActionTypes } from "./saved-company.actions";
import { SavedCompanyModel } from './saved-company.models';

export interface SavedCompanyState {
    isSavedCompanyLoading: boolean;
    savedCompany?: SavedCompanyModel;
    savedSearchId?: number;
    discount: number;
}

const initialState: SavedCompanyState = {
    isSavedCompanyLoading: false,
    savedCompany: undefined,
    savedSearchId: undefined,
    discount: 0
};

export const savedCompanyReducer = (state: SavedCompanyState = initialState, action: SavedCompanyActions): SavedCompanyState => {
    switch (action.type) {
        case CompanyActionTypes.SAVED_COMPANY_FROM_SEARCH:
            return {
                ...state,
                savedSearchId: action.payload
            };
        case CompanyActionTypes.SAVED_COMPANY_LOADING:
            return {
                ...state,
                isSavedCompanyLoading: true
            };
        case CompanyActionTypes.SAVED_COMPANY_SUCCESS:
            return {
                ...state,
                isSavedCompanyLoading: false,
                savedCompany: action.payload
            };
        case CompanyActionTypes.SAVED_COMPANY_UPLOAD_FAILED:
        case CompanyActionTypes.SAVED_COMPANY_UPLOAD_SUCCESS:
            return {
                ...state,
                isSavedCompanyLoading: false
            };
        case CompanyActionTypes.RESET_COMPANY_PROFILE:
            return {
                ...state,
                isSavedCompanyLoading: false,
                savedCompany: undefined
            };
        case CompanyActionTypes.DISCOUNT_LOADED:
            return {
                ...state,
                discount: action.payload
            };
        default:
            return state;
    }
};