import React from 'react';
import { AttributeType, SavedCompanyAttribute } from '../saved-company.models';
import CompanyPropertyRevealBadge from './CompanyPropertyRevealBadge.contrainer';
import SourceIcon from '../SourceIcon.container';

export interface CompanyPropertyComponentProps {
    savedCompanyId: number;
    attribute?: SavedCompanyAttribute;
    attributeName: any;
    labelName: string;
    attributeType: AttributeType;
    postFix?: string;
    preFix?: string;
    format?: (val: string) => string;
    splitBySemicolon?: boolean;
}

export const CompanyPropertyComponent: React.FC<CompanyPropertyComponentProps> = ({ savedCompanyId, attribute, attributeType, labelName, preFix, postFix, format, splitBySemicolon }) => {
    if (!attribute || !attribute.attributeId ) {
        return null;
    }

    const attributeValue = attribute.valueDescription ?? attribute.value;

    let description = attributeValue;
    if (attributeType === AttributeType.Financial) {
        description = attributeValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } 
    
    if (splitBySemicolon) {
        description = attributeValue.split(';').map((item: string) => (<li key={item}>{item}</li>));
    }

    if (format) {
        description = format(attributeValue);
    }

    return (
        <li>
            <div className="item item--def without-overflow">
                <dl className="stack stack-xs">
                    <dt className="fw-b attribute_source">
                        <div>
                            {labelName}
                        </div>
                        <SourceIcon
                            savedCompanyId={savedCompanyId}
                            attribute={attribute}
                            attributeName={labelName}
                            format={format}
                        />
                    </dt>
                    <dd>
                        {
                            !attribute.value?
                                (
                                    <CompanyPropertyRevealBadge
                                        label={labelName!}
                                        attributeType={attributeType}
                                        attributeId={attribute.attributeId}
                                        price={attribute.creditPrice}
                                    />
                                ) : (
                                    <div>
                                        <span className="value">
                                            {!Array.isArray(description) ?
                                                <span>{preFix} {description} {postFix}</span> :
                                                <ul>
                                                    {preFix} {description} {postFix}
                                                </ul>
                                            }
                                        </span>
                                        {
                                            attribute.hasUpdate ?
                                                <CompanyPropertyRevealBadge
                                                    isUpdate={true}
                                                    label={labelName!}
                                                    attributeType={attributeType}
                                                    attributeId={attribute.attributeId}
                                                    price={attribute.creditPrice} />
                                                : ""
                                        }
                                    </div>
                                )
                        }
                    </dd>
                </dl>
            </div>
        </li>
    );
};
