import { AppState } from '../root.reducer';

export const searchResultsTabsHeaderSelector = (state: AppState) => state.savedCompanies.headers;

export const isSavedCompaniesLoadingSelector = (state: AppState) => state.savedCompanies.isSavedCompaniesLoading;
export const savedCompaniesListSelector = (state: AppState) => state.savedCompanies.savedCompaniesList;
export const savedSearchIdSelector = (state: AppState) => state.savedCompany.savedSearchId;

export const isUploadedCompaniesLoadingSelector = (state: AppState) => state.savedCompanies.isUploadedCompaniesLoading;
export const uploadedCompaniesListSelector = (state: AppState) => state.savedCompanies.uploadedCompanyList;

export const isSavedSearchesLoadingSelector = (state: AppState) => state.savedCompanies.isSavedSearchesLoading;
export const savedSearchesListSelector = (state: AppState) => state.savedCompanies.savedSearchesList;

export const pageSelector = (state: AppState) => state.savedCompanies.page;
export const pageSizeSelector = (state: AppState) => state.savedCompanies.pageSize;

export const sourceDataSelector = (state: AppState) => state.savedCompanies.sourceData;
