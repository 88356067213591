import React, { useState } from "react";
import { SearchResultComponent } from "../SearchResult.component";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SavedCompanyListModel, SavedCompanyModel } from '../../saved-company/saved-company.models';
import { CompanySearchState } from '../../saved-companies/saved-companies.models';

export interface SavedSearchResultsComponentStateProps {
    savedSearchId: number;
    results: SavedCompanyListModel;
    page: number;
    nextPage(maxPage: number): void;
    previousPage(): void;
    resetCurrentPage(): void;
    loadSavedSearch: () => void;
}

const SavedSearchResults: React.FC<SavedSearchResultsComponentStateProps & RouteComponentProps> = ({ savedSearchId, results, page, nextPage, previousPage, loadSavedSearch, history }) => {
    const sort = (a: SavedCompanyModel, b: SavedCompanyModel) => {
        const textA = a.summary.fullName?.valueDescription!.toUpperCase();
        const textB = b.summary.fullName?.valueDescription!.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    };
    const sortByDesc = (a: SavedCompanyModel, b: SavedCompanyModel) => -1 * sort(a, b);

    const [order, setOrder] = useState('asc');
    const [reloadCounter, setReloadCounter] = useState(10);

    const disabledColorStyle = {
        "--color": "var(--text-alt-color)",
        "--background-color": "var(--disabled-color)"
    } as any;


    const goToUploadPage = () => {
        history.push('/upload-company');
    };

    const pageSize = 50;
    const pagesLength = Math.trunc(results.count / pageSize);
    const maxPageNumber = results.count % pageSize > 0 ? pagesLength + 1 : pagesLength;

    const onNext = (evt: any) => {
        evt.preventDefault();
        nextPage(maxPageNumber);
    }

    const onPrev = (evt: any) => {
        evt.preventDefault();
        previousPage();
    }

    const renderResults = () => {
        return <div className="stack stack-xl">
            <div className="grid">
                <div className="gi-6 t-gi-8">
                    <div className="h-stack h-stack-xs ai-c">
                        <h2 className="title title-s">Results</h2>
                        <span className="counter counter--normal" style={disabledColorStyle}>{results.count}</span>
                    </div>
                </div>
                {/* <div className="results__sort gi-6 t-gi-8">
                            <div className="h-stack h-stack-xs ai-c">
                                <div className="h-stack h-stack-xs ai-c">
                                    <p>Sort by</p>
                                    <div className="field field--select">
                                        <select
                                            className="field__field"
                                            name="sortresults"
                                            onChange={(evt) => setOrder(evt.target.value)}
                                            placeholder="Company Name A-Z">
                                            <option value="asc">Company Name A-Z</option>
                                            <option value="desc">Company Name Z-A</option>
                                        </select>
                                        <div className="field__icon field__icon--right">
                                            <svg className="icon icon--m">
                                                <use xlinkHref="#icon-arrow-down" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="no-mobile">
                                    <button className="button button--secondary" onClick={() => goToUploadPage()}>
                                        <span className="button__inner">
                                            <span>Upload a Company</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div> */}
            </div>
            <div className="grid">
                <div className="results__sort gi-12 t-gi-8">
                    <div className="pager">
                        <div className="2 h-stack h-stack-xs ai-c jc-c">
                            <button className="icon-button" onClick={(evt) => onPrev(evt)}>
                                <span className="icon-button__inner">
                                    <svg className="icon icon--m">
                                        <use xlinkHref="#icon-arrow-nav-back" />
                                    </svg>
                                    <span className="visually-hidden">Back</span>
                                </span>
                            </button>
                            <p className="annotation">Page {page}/{maxPageNumber}</p>
                            <button className="icon-button" onClick={(evt) => onNext(evt)}>
                                <span className="icon-button__inner">
                                    <svg className="icon icon--m">
                                        <use xlinkHref="#icon-arrow-nav-forward" />
                                    </svg>
                                    <span className="visually-hidden">Next</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list">
                {
                    results
                        .list
                        .sort((a, b) => order === 'asc' ? sort(a, b) : sortByDesc(a, b))
                        .map(result => (
                            <SearchResultComponent
                                key={result.id}
                                savedSearchId={savedSearchId}
                                searchResult={result} />
                        ))
                }
            </ul>
        </div>
    }

    const reload = (evt: any) => {
        evt.preventDefault();
        loadSavedSearch();
    }

    const renderRunningState = () => {
        return <div style={{ 'textAlign': 'center' }}>
            Results will be available shortly. <br /> Your browser will reload automatically in {reloadCounter} seconds... <br /><br />
            <button className="button button--secondary" onClick={(evt) => reload(evt)}>
                <span className="button__inner">
                    <span>Reload</span>
                </span>
            </button>
        </div>
    }

    if (results.state === CompanySearchState.Created) {
        setTimeout(() => {
            setReloadCounter(reloadCounter - 1);
            if (reloadCounter === 1) {
                loadSavedSearch();
                setReloadCounter(10);
            }
        }, 1000);
    }

    return (
        <div className="section section--results">
            <div className="container">
                {
                    results.state === CompanySearchState.Completed ? renderResults() : renderRunningState()
                }
            </div>
        </div>
    );
};

export const SavedSearchResultsComponent = withRouter(SavedSearchResults);