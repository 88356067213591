import { LabeledEnum } from './models';

export const AccountingAndFinance: LabeledEnum = {
  AccountingBookkeepingAndAuditing: { value: 1, label: "Accounting, Bookkeeping and Auditing" },
  AgenciesAndLoanBrokerages: { value: 2, label: "Agencies and Loan Brokerages" },
  Banks: { value: 3, label: "Banks" },
  BusinessAngels: { value: 4, label: "Business Angels" },
  BusinessBrokers: { value: 5, label: "Business Brokers" },
  BusinessValuationsAndPlanning: { value: 6, label: "Business Valuations and Planning" },
  CollectionAgencies: { value: 7, label: "Collection Agencies" },
  CreditUnions: { value: 8, label: "Credit Unions" },
  CryptoCurrencies: { value: 9, label: "Crypto Currencies" },
  Fiduciaries: { value: 10, label: "Fiduciaries" },
  FinancialConsultingAndPlanning: { value: 11, label: "Financial Consulting and Planning" },
  GrantApplications: { value: 12, label: "Grant Applications" },
  GrantmakingOrganizations: { value: 13, label: "Grantmaking Organizations" },
  Insurance: { value: 14, label: "Insurance" },
  Leasing: { value: 15, label: "Leasing" },
  ManagementAndAdministrationOfSharesAndBonds: { value: 16, label: "Management and Administration of Shares and Bonds" },
  MergersAndAcquisitions: { value: 17, label: "Mergers and Acquisitions" },
  MortgageBrokers: { value: 18, label: "Mortgage Brokers" },
  MutualFundsAndFundManagement: { value: 19, label: "Mutual Funds and Fund Management" },
  OnlinePaymentServices: { value: 20, label: "Online Payment Services" },
  PayrollServices: { value: 21, label: "Payroll Services" },
  PensionFunds: { value: 22, label: "Pension Funds" },
  RealEstateInvestmentTrusts: { value: 23, label: "Real Estate Investment Trusts" },
  SavingsAndLoans: { value: 24, label: "Savings and Loans" },
  StockAndBondBrokerages: { value: 25, label: "Stock and Bond Brokerages" },
  TaxPreparation: { value: 26, label: "Tax Preparation" },
  TrustsAndTrustRegistration: { value: 27, label: "Trusts and Trust Registration" },
  VentureCapital: { value: 28, label: "Venture Capital" },
  OtherFinancialServices: { value: 29, label: "Other Financial Services" },
  OtherInsuranceRelatedActivities: { value: 30, label: "Other Insurance Related Activities" },
  Other: { value: 31, label: "Other" },
}


export const Agriculture: LabeledEnum = {
  AgriculturalSupportServices: { value: 1, label: "Agricultural Support Services" },
  AnimalFarming: { value: 2, label: "Animal Farming" },
  AnimalFeed: { value: 3, label: "Animal Feed" },
  AnimalHealth: { value: 4, label: "Animal Health" },
  CropProduction: { value: 5, label: "Crop Production" },
  FishingAndFishFarming: { value: 6, label: "Fishing and Fish Farming" },
  ForestryAndLogging: { value: 7, label: "Forestry and Logging" },
  FruitAndVegetableFarming: { value: 8, label: "Fruit and Vegetable Farming" },
  GatheringOfWildGrowingNonWoodProducts: { value: 9, label: "Gathering of Wild Growing Non-Wood Products" },
  HuntingAndTrapping: { value: 10, label: "Hunting and Trapping" },
  LivestockBreeding: { value: 11, label: "Livestock Breeding" },
  MixedFarming: { value: 12, label: "Mixed Farming" },
  Other: { value: 13, label: "Other" },
};

export const BuildingAndConstruction: LabeledEnum = {
  AirportConstruction: { value: 1, label: "Airport Construction"},
  ArchitecturalAndEngineeringServices: { value: 2, label: "Architectural and Engineering Services"},
  BridgeAndViaductConstruction: { value: 3, label: "Bridge and Viaduct Construction"},
  BuildingEngineering: { value: 4, label: "Building Engineering"},
  BuildingInsulation: { value: 5, label: "Building Insulation"},
  CivilEngineering: { value: 6, label: "Civil Engineering"},
  CommercialSpaceConstruction: { value: 7, label: "Commercial Space Construction"},
  ConstructionEquipmentRentals: { value: 8, label: "Construction Equipment Rentals"},
  DoorsAndWindows: { value: 9, label: "Doors and Windows"},
  ElectriciansAndElectricInstallations: { value: 10, label: "Electricians and Electric Installations"},
  ElevatorsAndEscalators: { value: 11, label: "Elevators and Escalators"},
  GeneralContractors: { value: 12, label: "General Contractors"},
  HeatingAndAirConditioning: { value: 13, label: "Heating and Air Conditioning"},
  HeavyEngineeringConstruction: { value: 14, label: "Heavy Engineering Construction"},
  IndustrialBuildingConstruction: { value: 15, label: "Industrial Building Construction"},
  MetrorailAndTunnelConstruction: { value: 16, label: "Metrorail and Tunnel Construction"},
  PlanningAndDesign: { value: 17, label: "Planning and Design"},
  Plumbing: { value: 18, label: "Plumbing"},
  RailroadConstruction: { value: 19, label: "Railroad Construction"},
  ResidentialBuildingConstruction: { value: 20, label: "Residential Building Construction"},
  RetailSpaceConstruction: { value: 21, label: "Retail Space Construction"},
  RoadConstruction: { value: 22, label: "Road Construction"},
  SpecialtyTradeContractors: { value: 23, label: "Specialty Trade Contractors"},
  UtilitiesInstallations: { value: 24, label: "Utilities Installations"},
  WellDrilling: { value: 25, label: "Well Drilling"},
  Other: { value: 26, label: "Other"},
};

export const Commerce: LabeledEnum = {
  AgriculturalEquipmentAndSupplies: { value: 1, label: "Agricultural Equipment and Supplies"},
  AgriculturalProductsAndLiveAnimals: { value: 2, label: "Agricultural Products and Live Animals"},
  ApparelAndAccessories: { value: 3, label: "Apparel and Accessories"},
  ApplianceStores: { value: 4, label: "Appliance Stores"},
  Auctioneers: { value: 5, label: "Auctioneers"},
  Beverage: { value: 6, label: "Beverage"},
  BoatsYachtsAndShips: { value: 7, label: "Boats, Yachts and Ships"},
  Chemicals: { value: 8, label: "Chemicals"},
  ClothingAccessories: { value: 9, label: "Clothing Accessories"},
  ComputerHardware: { value: 10, label: "Computer Hardware"},
  ComputerSoftware: { value: 11, label: "Computer Software"},
  ConstructionMaterialsAndHardware: { value: 12, label: "Construction Materials and Hardware"},
  DepartmentStores: { value: 13, label: "Department Stores"},
  DurableGoods: { value: 14, label: "Durable Goods"},
  ElectronicMarketsAndPlatforms: { value: 15, label: "Electronic Markets and Platforms"},
  ElectronicsAndLighting: { value: 16, label: "Electronics and Lighting"},
  FoodWholesaleAndRetail: { value: 17, label: "Food Wholesale and Retail"},
  FuelWholesaleAndRetail: { value: 18, label: "Fuel Wholesale and Retail"},
  FurnitureFixturesFloorAndWallCovering: { value: 19, label: "Furniture, Fixtures, Floor and Wall Covering"},
  GeneralMerchandiseStores: { value: 20, label: "General Merchandise Stores"},
  HouseholdGoods: { value: 21, label: "Household Goods"},
  IndustrialPurposeMachinery: { value: 22, label: "Industrial Purpose Machinery"},
  JewelleryAndWatches: { value: 23, label: "Jewellery and Watches"},
  LuggageBagsAndLeatherProducts: { value: 24, label: "Luggage, Bags and Leather Products"},
  MachineryAndEquipment: { value: 25, label: "Machinery and Equipment"},
  MedicalEquipmentAndSupplies: { value: 26, label: "Medical Equipment and Supplies"},
  MetalsMetalOresAndMinerals: { value: 27, label: "Metals, Metal Ores, and Minerals"},
  MotorAndMotorVehicleParts: { value: 28, label: "Motor and Motor Vehicle Parts"},
  MotorVehicles: { value: 29, label: "Motor Vehicles"},
  MusicalInstruments: { value: 30, label: "Musical Instruments"},
  NondurableGoods: { value: 31, label: "Nondurable Goods"},
  NonStoreRetailers: { value: 32, label: "Non-store Retailers"},
  OfficeSupplies: { value: 33, label: "Office Supplies"},
  PaperAndPaperProducts: { value: 34, label: "Paper and Paper Products"},
  PerfumeCosmeticsHouseholdChemicals: { value: 35, label: "Perfume Cosmetics, Household Chemicals"},
  PetsAndPetFood: { value: 36, label: "Pets and Pet Food"},
  PhotographicAndOpticalGoods: { value: 37, label: "Photographic and Optical Goods"},
  Publications: { value: 38, label: "Publications"},
  SaleOfMusicAndVideoRecordings: { value: 39, label: "Sale of Music and Video Recordings"},
  SecondHandGoodsAndAntiques: { value: 40, label: "Second-Hand Goods and Antiques"},
  ShoppingMalls: { value: 41, label: "Shopping Malls"},
  SpecialtyGoods: { value: 42, label: "Specialty Goods"},
  SportingGoods: { value: 43, label: "Sporting Goods"},
  SupermarketsAndSupermarketChains: { value: 44, label: "Supermarkets and Supermarket Chains"},
  TelecommunicationEquipmentAndSupplies: { value: 45, label: "Telecommunication Equipment and Supplies"},
  TobaccoProducts: { value: 46, label: "Tobacco Products"},
  ToysAndGaming: { value: 47, label: "Toys and Gaming"},
  VideoRentals: { value: 48, label: "Video Rentals"},
  WholesaleAgents: { value: 49, label: "Wholesale Agents"},
  WholesaleBrokers: { value: 50, label: "Wholesale Brokers"},
  WholesaleOfWasteAndScrap: { value: 51, label: "Wholesale of Waste and Scrap"},
  WoodAndWoodProducts: { value: 52, label: "Wood and Wood Products"},
  OtherCommercialGoods: { value: 53, label: "Other Commercial Goods"},
  OtherConsumerGoods: { value: 54, label: "Other Consumer Goods"},
  OtherRetail: { value: 55, label: "Other Retail"},
  Other: { value: 56, label: "Other"},
};

export const Education: LabeledEnum = {
  AdultEducation: { value: 1, label: "Adult Education"},
  ArtsEducation: { value: 2, label: "Arts Education"},
  ChildDayCareServices: { value: 3, label: "Child Day Care Services"},
  DanceAndPerformingArtsEducation: { value: 4, label: "Dance and Performing Arts Education"},
  DrivingBoatingAndFlightSchools: { value: 5, label: "Driving, Boating and Flight Schools"},
  GamingEducation: { value: 6, label: "Gaming Education"},
  PerformingArtCenters: { value: 7, label: "Performing Art Centers"},
  PrivateEducation: { value: 8, label: "Private Education"},
  PublicEducation: { value: 9, label: "Public Education"},
  SpecialEducation: { value: 10, label: "Special Education"},
  SportsEducations: { value: 11, label: "Sports Educations"},
  UniversitiesAndHigherEducation: { value: 12, label: "Universities and Higher Education"},
  OtherEducationalServices: { value: 13, label: "Other Educational Services"},
  Other: { value: 14, label: "Other"},
};

export const Entertainment: LabeledEnum = {
  AdultEntertainment: { value: 1, label: "Adult Entertainment"},
  CinemasMovieTheaters: { value: 2, label: "Cinemas / Movie Theaters"},
  Concerts: { value: 3, label: "Concerts"},
  DanceHalls: { value: 4, label: "Dance Halls"},
  DiscothequesAndClubs: { value: 5, label: "Discotheques and Clubs"},
  Gambling: { value: 6, label: "Gambling"},
  GamingCenters: { value: 7, label: "Gaming Centers"},
  Museums: { value: 8, label: "Museums"},
  Theaters: { value: 9, label: "Theaters"},
  ThemeParks: { value: 10, label: "Theme Parks"},
  ZooAquariumBotanicalGarden: { value: 11, label: "Zoo, Aquarium, Botanical Garden"},
  OtherAmusementAndRecreation: { value: 12, label: "Other Amusement and Recreation."},
  Other: { value: 13, label: "Other"},
};

export const Government: LabeledEnum = {
  BusinessLicensing: { value: 1, label: "Business Licensing"},
  CentralBanksAndFinancialRegulators: { value: 2, label: "Central Banks and Financial Regulators"},
  CourtsAndJustice: { value: 3, label: "Courts and Justice"},
  EmbassiesConsulatesAndForeignRepresentativeOffices: { value: 4, label: "Embassies, Consulates and Foreign Representative Offices"},
  GovernmentLoans: { value: 5, label: "Government Loans"},
  ImmigrationAndNaturalizationServices: { value: 6, label: "Immigration and Naturalization Services"},
  ManufacturingOfMonetaryInstruments: { value: 7, label: "Manufacturing of Monetary Instruments"},
  MinistriesAndOtherGovernmentAgencies: { value: 8, label: "Ministries and other Government Agencies"},
  MotorVehicleRegistration: { value: 9, label: "Motor Vehicle Registration"},
  MunicipalGovernments: { value: 10, label: "Municipal Governments"},
  PersonalIdentificationAndLicensing: { value: 11, label: "Personal Identification and Licensing"},
  PublicLibraries: { value: 12, label: "Public Libraries"},
  PublicTransportation: { value: 13, label: "Public Transportation"},
  RegulatoryOrganizations: { value: 14, label: "Regulatory Organizations"},
  SocialServices: { value: 15, label: "Social Services"},
  SpaceAgenciesAndSpaceTravel: { value: 16, label: "Space Agencies and Space Travel"},
  OtherGovernmentOrganizations: { value: 17, label: "Other Government Organizations"},
  OtherGovernmentServices: { value: 18, label: "Other Government Services"},
  Other: { value: 19, label: "Other"},
};

export const HealthCare: LabeledEnum = {
  AmbulanceAndPatientTransportation: { value: 1, label: "Ambulance and Patient Transportation"},
  AmbulatoryHealthCareServices: { value: 2, label: "Ambulatory Health Care Services"},
  DentalLaboratories: { value: 3, label: "Dental Laboratories"},
  Dentists: { value: 4, label: "Dentists"},
  HomeHealthCareServices: { value: 5, label: "Home Health Care Services"},
  MedicalHospitals: { value: 6, label: "Medical Hospitals"},
  MedicalLaboratories: { value: 7, label: "Medical Laboratories"},
  NursingFacilities: { value: 8, label: "Nursing Facilities"},
  OutpatientCareCenters: { value: 9, label: "Outpatient Care Centers"},
  PersonalCare: { value: 10, label: "Personal Care"},
  Pharmaceuticals: { value: 11, label: "Pharmaceuticals"},
  Pharmacies: { value: 12, label: "Pharmacies"},
  Physicians: { value: 13, label: "Physicians"},
  PsychiatricHospitals: { value: 14, label: "Psychiatric Hospitals"},
  ResidentialCare: { value: 15, label: "Residential Care"},
  SpecializedMedicalTreatment: { value: 16, label: "Specialized Medical Treatment"},
  SpecialtyHospitals: { value: 17, label: "Specialty Hospitals"},
  SubstanceAbuseHospitals: { value: 18, label: "Substance Abuse Hospitals"},
  SurgicalHospitals: { value: 19, label: "Surgical Hospitals"},
  OtherHealthServices: { value: 20, label: "Other Health Services"},
  Other: { value: 21, label: "Other"},
};

export const Hospitality: LabeledEnum = {
  AmusementAndRecreationActivities: { value: 1, label: "Amusement and Recreation Activities"},
  BarsAndBeverageService: { value: 2, label: "Bars and Beverage Service"},
  CampsitesAndRecreationFacilities: { value: 3, label: "Campsites and Recreation Facilities"},
  FoodServices: { value: 4, label: "Food Services"},
  HotelsMotelsGuestHouses: { value: 5, label: "Hotels, Motels, Guest Houses"},
  PastryShopsAndCoffeeHouses: { value: 6, label: "Pastry Shops and Coffee Houses"},
  Playrooms: { value: 7, label: "Playrooms"},
  Restaurants: { value: 8, label: "Restaurants"},
  SightseeingTransportation: { value: 9, label: "Sightseeing Transportation"},
  SpasSaunasSolariums: { value: 10, label: "Spas, Saunas, Solariums"},
  SportsActivities: { value: 11, label: "Sports Activities"},
  StudentAccommodationAndYouthHostels: { value: 12, label: "Student Accommodation and Youth Hostels"},
  TouristOfficesAndGuides: { value: 13, label: "Tourist Offices and Guides"},
  TravelAgenciesAndTourOperators: { value: 14, label: "Travel Agencies and Tour Operators"},
  TravelInsurance: { value: 15, label: "Travel Insurance"},
  TravelerAccommodationServices: { value: 16, label: "Traveler Accommodation Services"},
  Other: { value: 17, label: "Other"},
};

export const Mining: LabeledEnum = {
  Aluminum: { value: 1, label: "Aluminum"},
  Coal: { value: 2, label: "Coal"},
  Copper: { value: 3, label: "Copper"},
  DataMining: { value: 4, label: "Data Mining"},
  DiamondsAndPreciousStones: { value: 5, label: "Diamonds and Precious Stones"},
  GoldSilverAndOtherPreciousMetals: { value: 6, label: "Gold, Silver and other Precious Metals"},
  GravelAndSand: { value: 7, label: "Gravel and Sand"},
  MetalOre: { value: 8, label: "Metal Ore"},
  NonmetallicMineralQuarrying: { value: 9, label: "Nonmetallic Mineral Quarrying"},
  NonMetallicMinerals: { value: 10, label: "Non-Metallic Minerals"},
  OilAndGasExtraction: { value: 11, label: "Oil and Gas Extraction"},
  SupportActivitiesForGasOperations: { value: 12, label: "Support Activities for Gas Operations"},
  SupportActivitiesForOilOperations: { value: 13, label: "Support Activities for Oil Operations"},
  SupportActivitiesForSolidMineralOperations: { value: 14, label: "Support Activities for Solid Mineral Operations"},
  OtherMinerals: { value: 15, label: "Other Minerals"},
  Other: { value: 16, label: "Other"},
};

export const Organizations: LabeledEnum = {
  BusinessAndProfessionalMembershipOrganizations: { value: 1, label: "Business and Professional Membership Organizations"},
  ChambersOfCommerce: { value: 2, label: "Chambers of Commerce"},
  GovernmentalAlliances: { value: 3, label: "Governmental Alliances"},
  InternationalOrganizations: { value: 4, label: "International Organizations"},
  NonGovernmentalOrganizations: { value: 5, label: "Non-Governmental Organizations"},
  NonprofitOrganizations: { value: 6, label: "Nonprofit Organizations"},
  ReligiousOrganizations: { value: 7, label: "Religious Organizations"},
  TradeUnions: { value: 8, label: "Trade Unions"},
  OtherOrganizations: { value: 9, label: "Other Organizations"},
  Other: { value: 10, label: "Other"},
};

export const ProcessingAndManufacturing: LabeledEnum = {
  AdhesivesAndGlues: { value: 1, label: "Adhesives and Glues"},
  AerospaceProductsAndParts: { value: 2, label: "Aerospace Products and Parts"},
  AgriculturalChemicals: { value: 3, label: "Agricultural Chemicals"},
  AgriculturalMachinery: { value: 4, label: "Agricultural Machinery"},
  AirFlowControlMachinery: { value: 5, label: "Air-Flow Control Machinery"},
  AnimalFeed: { value: 6, label: "Animal Feed"},
  ApparelsAndAccessories: { value: 7, label: "Apparels and Accessories"},
  AudioEquipment: { value: 8, label: "Audio Equipment"},
  AutomobilesAndAutomotiveParts: { value: 9, label: "Automobiles and Automotive Parts"},
  BagsSuitcasesLeatherGoods: { value: 10, label: "Bags Suitcases, Leather Goods"},
  Beverages: { value: 11, label: "Beverages"},
  CablesRopesChainsAndSprings: { value: 12, label: "Cables, Ropes, Chains and Springs"},
  Chemicals: { value: 13, label: "Chemicals"},
  CommercialEquipment: { value: 14, label: "Commercial Equipment"},
  CommunicationsEquipment: { value: 15, label: "Communications Equipment"},
  CommunicationsSignalEvaluationEquipment: { value: 16, label: "Communications Signal Evaluation Equipment"},
  CommunicationsSignalTestingEquipment: { value: 17, label: "Communications Signal Testing Equipment"},
  ComputerEquipment: { value: 18, label: "Computer Equipment"},
  ConstructionMaterials: { value: 19, label: "Construction Materials"},
  ControlInstruments: { value: 20, label: "Control Instruments"},
  DoItYourselfMachinesAndTools: { value: 21, label: "Do It Yourself Machines and Tools"},
  ElectricPowerGenerationEquipment: { value: 22, label: "Electric Power Generation Equipment"},
  ElectricalAppliances: { value: 23, label: "Electrical Appliances"},
  ElectricalComponents: { value: 24, label: "Electrical Components"},
  ElectricalEquipment: { value: 25, label: "Electrical Equipment"},
  ElectronicComponents: { value: 26, label: "Electronic Components"},
  FabricatedMetalProducts: { value: 27, label: "Fabricated Metal Products"},
  Ferroalloy: { value: 28, label: "Ferroalloy"},
  FerrousFoundries: { value: 29, label: "Ferrous Foundries"},
  Food: { value: 30, label: "Food"},
  FurnitureFixturesFloorAndWallCovering: { value: 31, label: "Furniture, Fixtures, Floor and Wall Covering"},
  GeneralPurposeMachinery: { value: 32, label: "General Purpose Machinery"},
  GlassProducts: { value: 33, label: "Glass Products"},
  HouseholdGoods: { value: 34, label: "Household Goods"},
  IndustrialPurposeMachinery: { value: 35, label: "Industrial Purpose Machinery"},
  IronMills: { value: 36, label: "Iron Mills"},
  JewelleryAndWatches: { value: 37, label: "Jewellery and Watches"},
  LeatherAndFur: { value: 38, label: "Leather and Fur"},
  MagneticMedia: { value: 39, label: "Magnetic Media"},
  MeasuringInstruments: { value: 40, label: "Measuring Instruments"},
  MedicalInstrumentsAndEquipment: { value: 41, label: "Medical Instruments and Equipment"},
  MedicalSupplies: { value: 42, label: "Medical Supplies"},
  MetalComponents: { value: 43, label: "Metal Components"},
  MetalWorking: { value: 44, label: "Metal Working"},
  MiningMachinery: { value: 45, label: "Mining Machinery"},
  MotorAndVehicleBody: { value: 46, label: "Motor and Vehicle Body"},
  MotorVehicleParts: { value: 47, label: "Motor Vehicle Parts"},
  MotorVehicles: { value: 48, label: "Motor Vehicles"},
  MotorsAndMotorParts: { value: 49, label: "Motors and Motor Parts"},
  MusicalInstruments: { value: 50, label: "Musical Instruments"},
  NailsScrewsOtherHardware: { value: 51, label: "Nails, Screws other Hardware"},
  NavigationalInstruments: { value: 52, label: "Navigational Instruments"},
  NonferrousFoundries: { value: 53, label: "Nonferrous Foundries"},
  NonferrousMetals: { value: 54, label: "Nonferrous Metals"},
  OpticalAndPhotographicEquipment: { value: 55, label: "Optical and Photographic Equipment"},
  OpticalMedia: { value: 56, label: "Optical Media"},
  PackagingMaterials: { value: 57, label: "Packaging Materials"},
  Paint: { value: 58, label: "Paint"},
  Paper: { value: 59, label: "Paper"},
  PeripheralEquipment: { value: 60, label: "Peripheral Equipment"},
  Pesticide: { value: 61, label: "Pesticide"},
  Petroleum: { value: 62, label: "Petroleum"},
  Plastics: { value: 63, label: "Plastics"},
  PowerTransmissionEquipment: { value: 64, label: "Power Transmission Equipment"},
  RadioBroadcastingEquipment: { value: 65, label: "Radio Broadcasting Equipment"},
  ReproducingMagneticMedia: { value: 66, label: "Reproducing Magnetic Media"},
  ReproducingOpticalMedia: { value: 67, label: "Reproducing Optical Media"},
  Resin: { value: 68, label: "Resin"},
  RubberProducts: { value: 69, label: "Rubber Products"},
  SemiconductorComponents: { value: 70, label: "Semiconductor Components"},
  ServiceIndustryEquipment: { value: 71, label: "Service Industry Equipment"},
  ShipsYachtsAndBoats: { value: 72, label: "Ships, Yachts and Boats"},
  SportingGoods: { value: 73, label: "Sporting Goods"},
  SteelMillsAndSteelProducts: { value: 74, label: "Steel Mills and Steel Products"},
  SyntheticRubber: { value: 75, label: "Synthetic Rubber"},
  TelecommunicationsEquipment: { value: 76, label: "Telecommunications Equipment"},
  TemperatureControlEquipment: { value: 77, label: "Temperature Control Equipment"},
  TextileAndKnitwear: { value: 78, label: "Textile and Knitwear"},
  Tobacco: { value: 79, label: "Tobacco"},
  ToiletriesSoapAndCleaningSupplies: { value: 80, label: "Toiletries, Soap and Cleaning Supplies"},
  ToysAndGaming: { value: 81, label: "Toys and Gaming"},
  Trailers: { value: 82, label: "Trailers"},
  TransportationEquipmentAndParts: { value: 83, label: "Transportation Equipment and Parts"},
  Turbine: { value: 84, label: "Turbine"},
  VideoAndBroadcastingEquipment: { value: 85, label: "Video and Broadcasting Equipment"},
  Weapons: { value: 86, label: "Weapons"},
  WoodProducts: { value: 87, label: "Wood Products"},
  YarnThreadAndFibers: { value: 88, label: "Yarn, Thread and Fibers"},
  OtherIndustrialGoods: { value: 89, label: "Other Industrial Goods"},
  OtherNonmetallicMineralProducts: { value: 90, label: "Other Nonmetallic Mineral Products"},
  Other: { value: 91, label: "Other"},
};

export const RealEstate: LabeledEnum = {
  Appraisal: { value: 1, label: "Appraisal"},
  BuildingMaintenance: { value: 2, label: "Building Maintenance"},
  HomeImprovement: { value: 3, label: "Home Improvement"},
  InteriorDesign: { value: 4, label: "Interior Design"},
  InvestigationsToDwellings: { value: 5, label: "Investigations to Dwellings"},
  LandscapingAndGardening: { value: 6, label: "Landscaping and Gardening"},
  LeasingAndRenting: { value: 7, label: "Leasing and Renting"},
  PropertyManagement: { value: 8, label: "Property Management"},
  RealEstateAgencies: { value: 9, label: "Real Estate Agencies"},
  RealEstateAuctioneers: { value: 10, label: "Real Estate Auctioneers"},
  RealEstateBrokers: { value: 11, label: "Real Estate Brokers"},
  RealEstateDevelopment: { value: 12, label: "Real Estate Development"},
  RealEstateInspection: { value: 13, label: "Real Estate Inspection"},
  RealEstateInvestmentAndTrade: { value: 14, label: "Real Estate Investment and Trade"},
  RezoningAndDevelopmentServices: { value: 15, label: "Rezoning and Development Services"},
  SecurityToBuildings: { value: 16, label: "Security to Buildings"},
  SecurityToDwellings: { value: 17, label: "Security to Dwellings"},
  SeniorCitizenResidencesAndHomes: { value: 18, label: "Senior Citizen Residences and Homes"},
  ServicesToBuildings: { value: 19, label: "Services to Buildings"},
  ServicesToDwellings: { value: 20, label: "Services to Dwellings"},
  TitleInsurance: { value: 21, label: "Title Insurance"},
  TitleSearchAndOtherLegalServices: { value: 22, label: "Title Search and Other Legal Services"},
  Other: { value: 23, label: "Other"},
};

export const Services: LabeledEnum = {
  AdministrationAndSupportServices: { value: 1, label: "Administration and Support Services"},
  AdvertisingAndRelatedServices: { value: 2, label: "Advertising and Related Services"},
  ArtsAndArtisticCreations: { value: 3, label: "Arts and Artistic Creations"},
  AutomotiveEquipmentLeasingAndRentals: { value: 4, label: "Automotive Equipment Leasing and Rentals"},
  BeautyServices: { value: 5, label: "Beauty Services"},
  BusinessConsulting: { value: 6, label: "Business Consulting"},
  BusinessSupport: { value: 7, label: "Business Support"},
  CommunicationServices: { value: 8, label: "Communication Services"},
  ComputerSystemsDesign: { value: 9, label: "Computer Systems Design"},
  ConstructionAndRenovationServices: { value: 10, label: "Construction and Renovation Services"},
  DeathCare: { value: 11, label: "Death Care"},
  Disposal: { value: 12, label: "Disposal"},
  DryCleaningAndLaundry: { value: 13, label: "Dry Cleaning and Laundry"},
  Employment: { value: 14, label: "Employment"},
  EngineeringAndRelatedServices: { value: 15, label: "Engineering and Related Services"},
  EquipmentRentalsAndLeasing: { value: 16, label: "Equipment Rentals and Leasing"},
  EventManagement: { value: 17, label: "Event Management"},
  IndustrialServices: { value: 18, label: "Industrial Services"},
  Investigations: { value: 19, label: "Investigations"},
  LegalServices: { value: 20, label: "Legal Services"},
  ManagementOfCompanies: { value: 21, label: "Management of Companies"},
  MarketResearchAndMarketing: { value: 22, label: "Market Research and Marketing"},
  OfficeFacilities: { value: 23, label: "Office Facilities"},
  PersonalServices: { value: 24, label: "Personal Services"},
  Photography: { value: 25, label: "Photography"},
  PrintingAndPublishing: { value: 26, label: "Printing and Publishing"},
  PrivateLibrariesAndArchiving: { value: 27, label: "Private Libraries and Archiving"},
  Programing: { value: 28, label: "Programing"},
  RegistrationAndLeasingOfIntellectualProperties: { value: 29, label: "Registration and Leasing of Intellectual Properties"},
  Remediation: { value: 30, label: "Remediation"},
  RentingAndLeasingAircraftBoatsAndOtherVehicles: { value: 31, label: "Renting and Leasing Aircraft, Boats and Other Vehicles"},
  RentingAndLeasingHouseholdGoods: { value: 32, label: "Renting and Leasing Household Goods"},
  RepairsAndMaintenanceExceptVehicles: { value: 33, label: "Repairs and Maintenance Except Vehicles"},
  ReproductionOfRecordedMedia: { value: 34, label: "Reproduction of Recorded Media"},
  ScientificDevelopmentServices: { value: 35, label: "Scientific Development Services"},
  ScientificResearchServices: { value: 36, label: "Scientific Research Services"},
  SecurityServicesAndBodyGuards: { value: 37, label: "Security Services and Body Guards"},
  SpecializedDesign: { value: 38, label: "Specialized Design"},
  TechnicalTestingAndAnalysis: { value: 39, label: "Technical Testing and Analysis"},
  TranslationAndInterpretation: { value: 40, label: "Translation and Interpretation"},
  VehicleRepairsAndMaintenance: { value: 41, label: "Vehicle Repairs and Maintenance"},
  WasteCollectionAndManagement: { value: 42, label: "Waste Collection and Management"},
  OtherSupportServices: { value: 43, label: "Other Support Services"},
  Other: { value: 44, label: "Other"},
};

export const TechnologyMediaAndTelecom: LabeledEnum = {
  ArtificialIntelligence: { value: 1, label: "Artificial Intelligence"},
  BookPublishing: { value: 2, label: "Book Publishing"},
  CableAndOtherProgramDistribution: { value: 3, label: "Cable and Other Program Distribution"},
  ComputerNetworkEngineering: { value: 4, label: "Computer Network Engineering"},
  ComputerSystemsDesignAndRelatedServices: { value: 5, label: "Computer Systems Design and Related Services"},
  CryptoCurrencyMiningAndDevelopment: { value: 6, label: "Crypto Currency Mining and Development"},
  DataProcessing: { value: 7, label: "Data Processing"},
  Databases: { value: 8, label: "Databases"},
  DirectoryPublishing: { value: 9, label: "Directory Publishing"},
  HostingAndRelatedServices: { value: 10, label: "Hosting and Related Services"},
  InformationTechnology: { value: 11, label: "Information Technology"},
  InternetServiceProviders: { value: 12, label: "Internet Service Providers"},
  MotionPicture: { value: 13, label: "Motion Picture"},
  NewsServicesAndAgencies: { value: 14, label: "News Services and Agencies"},
  PeriodicalsAndNewspaperPublishing: { value: 15, label: "Periodicals and Newspaper Publishing"},
  PrintingAndRelatedSupportActivities: { value: 16, label: "Printing and Related Support Activities"},
  Radio: { value: 17, label: "Radio"},
  SatelliteTelecommunications: { value: 18, label: "Satellite Telecommunications"},
  SoftwarePublishing: { value: 19, label: "Software Publishing"},
  SoundRecording: { value: 20, label: "Sound Recording"},
  TelecommunicationsResellers: { value: 21, label: "Telecommunications Resellers"},
  TelevisionBroadcasting: { value: 22, label: "Television Broadcasting"},
  WebDesignAndInternetServices: { value: 23, label: "Web Design and Internet Services"},
  WiredTelecommunicationsCarriers: { value: 24, label: "Wired Telecommunications Carriers"},
  WirelessTelecommunicationsCarriers: { value: 25, label: "Wireless Telecommunications Carriers"},
  OtherInformationServices: { value: 26, label: "Other Information Services"},
  OtherPublishing: { value: 27, label: "Other Publishing"},
  OtherSubscriptionProgramming: { value: 28, label: "Other Subscription Programming"},
  OtherTelecommunications: { value: 29, label: "Other Telecommunications"},
  Other: { value: 30, label: "Other"},
};

export const TransportationAndWarehousing: LabeledEnum = {
  AirCargoAndIncidentalAirServices: { value: 1, label: "Air Cargo and Incidental Air Services"},
  AirlinesAndPassengerAirTransport: { value: 2, label: "Airlines and Passenger Air Transport"},
  AirportsAndAirportServices: { value: 3, label: "Airports and Airport Services"},
  BusServices: { value: 4, label: "Bus Services"},
  CarParksAndGarages: { value: 5, label: "Car Parks and Garages"},
  CustomsClearance: { value: 6, label: "Customs Clearance"},
  FreightInspection: { value: 7, label: "Freight Inspection"},
  FreightInsurance: { value: 8, label: "Freight Insurance"},
  HazardousWasteTransportation: { value: 9, label: "Hazardous Waste Transportation"},
  LogisticsAndFreightForwarding: { value: 10, label: "Logistics and Freight Forwarding"},
  PackagingAndCrating: { value: 11, label: "Packaging and Crating"},
  PassengerRailway: { value: 12, label: "Passenger Railway"},
  PassengerWaterTransport: { value: 13, label: "Passenger Water Transport"},
  PipelineTransportation: { value: 14, label: "Pipeline Transportation"},
  PortsAndRelatedServices: { value: 15, label: "Ports and Related Services"},
  PostalMessengerAndCurrierServices: { value: 16, label: "Postal, Messenger and Currier Services"},
  PublicTransportation: { value: 17, label: "Public Transportation"},
  RailTransportation: { value: 18, label: "Rail Transportation"},
  RailwayStations: { value: 19, label: "Railway Stations"},
  RoadAndWaterwayOperations: { value: 20, label: "Road and Waterway Operations"},
  ScenicTransportation: { value: 21, label: "Scenic Transportation"},
  SpaceTransport: { value: 22, label: "Space Transport"},
  SpecialtyFreightTransport: { value: 23, label: "Specialty Freight Transport"},
  Storage: { value: 24, label: "Storage"},
  SupportActivitiesForTransportation: { value: 25, label: "Support Activities for Transportation"},
  TaxisAndCarSharing: { value: 26, label: "Taxis and Car Sharing"},
  TruckingAndFreightTransportOnRoad: { value: 27, label: "Trucking and Freight Transport on Road"},
  Warehousing: { value: 28, label: "Warehousing"},
  WaterFreight: { value: 29, label: "Water Freight"},
  OtherFreightTransportation: { value: 30, label: "Other Freight Transportation"},
  OtherPassengerTransport: { value: 31, label: "Other Passenger Transport"},
  Other: { value: 32, label: "Other"},
};

export const Utilities: LabeledEnum = {
  Biomass: { value: 1, label: "Biomass"},
  CentralHeatingAndAirConditioning: { value: 2, label: "Central Heating and Air Conditioning"},
  ElectricPowerDistribution: { value: 3, label: "Electric Power Distribution"},
  ElectricPowerGeneration: { value: 4, label: "Electric Power Generation"},
  ElectricPowerTransmission: { value: 5, label: "Electric Power Transmission"},
  FirewoodAndCoalDistribution: { value: 6, label: "Firewood and Coal Distribution"},
  HeatingOilDistribution: { value: 7, label: "Heating Oil Distribution"},
  NaturalGasDistribution: { value: 8, label: "Natural Gas Distribution"},
  NuclearFuelAndPower: { value: 9, label: "Nuclear Fuel and Power"},
  Recycling: { value: 10, label: "Recycling"},
  RenewableEnergy: { value: 11, label: "Renewable Energy"},
  Sewage: { value: 12, label: "Sewage"},
  WasteRemoval: { value: 13, label: "Waste Removal"},
  WasteWaterTreatment: { value: 14, label: "Waste Water Treatment"},
  WaterDistribution: { value: 15, label: "Water Distribution"},
  OtherUtilitiesDistribution: { value: 16, label: "Other Utilities Distribution"},
  Other: { value: 17, label: "Other"},
};