import { actionCreator } from '../actions';
import { ManagementContactActivationModel } from './models';

export enum ManagementContactActivationActionTypes {
    MANAGEMENTCONTACT_ACTIVATION_LOADING = 'mc-activation/loading',
    MANAGEMENTCONTACT_ACTIVATION_LOADED = 'mc-activation/loaded',
}

export const activationLoadingAction = actionCreator<ManagementContactActivationActionTypes.MANAGEMENTCONTACT_ACTIVATION_LOADING, void>(ManagementContactActivationActionTypes.MANAGEMENTCONTACT_ACTIVATION_LOADING);
export const activationLoadedSuccessfulAction = actionCreator<ManagementContactActivationActionTypes.MANAGEMENTCONTACT_ACTIVATION_LOADED, ManagementContactActivationModel>(ManagementContactActivationActionTypes.MANAGEMENTCONTACT_ACTIVATION_LOADED);

export type ManagementContactActivationActions =
  ReturnType<typeof activationLoadingAction> |
  ReturnType<typeof activationLoadedSuccessfulAction>;