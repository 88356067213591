import React, { useState } from "react";
import { SimpleSearchFields, SearchKeywordsFields } from "./search.models";
import { Link } from "react-router-dom";
import { SearchInKeywordComponent } from "./SearchFields/SearchInKeyword.component";
import { RevenueCategoriesArray, IndustriesArray } from '../models';
import { CountryModel } from '../meta/meta.models';

export interface SearchFieldsComponentStateProps {
    countries?: CountryModel[];
    fields: SimpleSearchFields | undefined;
}

export interface SearchFieldsComponenProps {
    fromHome: boolean;
}

export interface SearchFieldsComponentDispatchProps {
    searchCompanies: (fields: SimpleSearchFields) => void;
}

export const SearchFieldsComponent: React.FC<SearchFieldsComponentStateProps & SearchFieldsComponentDispatchProps> = ({ countries, fields, searchCompanies }) => {
    const [keyword, setKeyword] = useState(fields?.keyword || '');
    const [industry, setIndustry] = useState(fields?.industry || IndustriesArray[0].value);
    const [revenueCategory, setRevenueCategory] = useState(fields?.revenueCategory || RevenueCategoriesArray[0].value);

    const defaultCountry = countries ? countries[0].id : undefined;
    const [countryOfFoundationId, setCountry] = useState(fields?.countryOfFoundationId ? Number(fields?.countryOfFoundationId) : defaultCountry);
    const [searchInAllAvailableData, setSearchInAllAvailableData] = useState(false);

    const [searchKeywordsInFields, setSearchKeywordsInFields] = useState<SearchKeywordsFields>({
        companynames: fields?.searchKeywordsInFields?.companynames ?? true,
        contacts: fields?.searchKeywordsInFields?.contacts ?? false,
        addresses: fields?.searchKeywordsInFields?.addresses ?? false,
        productsAndServices: fields?.searchKeywordsInFields?.productsAndServices ?? false,
        allOther: fields?.searchKeywordsInFields?.allOther ?? false
    });

    const search = () => {
        searchCompanies({
            keyword,
            countryOfFoundationId,
            industry,
            revenueCategory,
            searchKeywordsInFields,
            searchInAllAvailableData
        });
    };

    const renderSearchInKeywordCheckbox = (propertyName: keyof SearchKeywordsFields) => (
        <SearchInKeywordComponent
            propertyName={propertyName}
            checked={searchKeywordsInFields[propertyName]}
            onChange={(value) => {
                if (propertyName === 'allOther') {
                    setSearchKeywordsInFields({
                        companynames: false,
                        addresses: false,
                        contacts: false,
                        productsAndServices: false,
                        allOther: value
                    });
                    if (value === false) {
                        setSearchKeywordsInFields({ ...searchKeywordsInFields, companynames: true, allOther: false });
                    }
                } else {
                    setSearchKeywordsInFields({ ...searchKeywordsInFields, [propertyName]: value, allOther: false });
                }

                const fields = {
                    ...searchKeywordsInFields,
                    [propertyName]: value
                };
                if (Object.keys(fields).every((k) => !(fields as any)[k])) {
                    setSearchKeywordsInFields({
                        ...fields,
                        companynames: true,
                    });
                }
            }}
        />
    ); 
    
    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            search();
        }
    }

    return (
        <div className="section section--header">
            <div className="container">
                <div className="stack stack-m">
                    <div className="grid">
                        <div className="gi-12 t-gi-8 m-gi-4">
                            <h1 className="title title-l">Search for Companies</h1>
                            <p className="title fw-sb">Narrow your search</p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="gi-12">
                            <div className="search-form search-form--extended">
                                <div className="stack stack-xs">
                                    <div>
                                        <div className="h-stack h-stack-xs t-fd-c">
                                            <div className="h-stack h-stack-xs m-fd-c fill">
                                                <div className="fill">
                                                    <div className="label">Keyword</div>
                                                    <div className="field field--input">
                                                        <input className="field__field"
                                                            value={keyword}
                                                            onKeyPress={(evt) => handleKeyPress(evt)}
                                                            onChange={(evt) => setKeyword(evt.target.value)} name="search-keyword" id="search-keyword"
                                                            placeholder="Type in a name, address etc..." />
                                                    </div>
                                                </div>
                                                <div className="fill">
                                                    <div className="label">Industry</div>
                                                    <div className="field field--select">
                                                        <select className="field__field"
                                                            value={industry} onChange={(evt) => setIndustry(Number(evt.target.value))}
                                                            name="search-industry" id="search-industry"
                                                            placeholder="Any industry">
                                                            {
                                                                IndustriesArray.map((industryOption) => (
                                                                    <option key={industryOption.key} value={industryOption.value}>{industryOption.label}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <div className="field__icon field__icon--right">
                                                            <svg className="icon icon--m">
                                                                <use xlinkHref="#icon-arrow-down" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="h-stack h-stack-xs m-fd-c fill">
                                                <div className="fill">
                                                    <div className="label">Revenues</div>
                                                    <div className="field field--select">
                                                        <select className="field__field"
                                                            value={revenueCategory} onChange={(evt) => setRevenueCategory(Number(evt.target.value))}
                                                            name="search-revenue" id="search-revenue"
                                                            placeholder="Any revenue category">
                                                            {
                                                                RevenueCategoriesArray.map((revenueOption) => (
                                                                    <option key={revenueOption.key} value={revenueOption.value}>{revenueOption.label}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <div className="field__icon field__icon--right">
                                                            <svg className="icon icon--m">
                                                                <use xlinkHref="#icon-arrow-down" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="fill">
                                                    <div className="label">Country</div>
                                                    <div className="field field--select">
                                                        {
                                                            countries ? (
                                                                <select className="field__field" placeholder=""
                                                                    value={countryOfFoundationId} onChange={(evt) => setCountry(Number(evt.target.value) || undefined)}
                                                                >
                                                                    {
                                                                        countries.map((countryOption, index) => (
                                                                            <option key={index} value={countryOption.id}>{countryOption.name}</option>
                                                                        ))
                                                                    }
                                                                </select>) : null
                                                        }
                                                        <div className="field__icon field__icon--right">
                                                            <svg className="icon icon--m">
                                                                <use xlinkHref="#icon-arrow-down" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="stack stack-xs jc-fe">
                                                <button className="button" onClick={() => search()}>
                                                    <span className="button__inner">
                                                        <span>Search</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="search-form__filter h-stack h-stack-xs m-fd-c">
                                            <div className="h-stack h-stack-xs">
                                                <div>
                                                    <div className="label fw-b">Search Keyword(s) in:</div>
                                                </div>
                                            </div>
                                            <div className="search-form__filter-toggles h-stack h-stack-xs fill">
                                                {renderSearchInKeywordCheckbox('companynames')}
                                                {renderSearchInKeywordCheckbox('contacts')}
                                                {renderSearchInKeywordCheckbox('addresses')}
                                                {renderSearchInKeywordCheckbox('productsAndServices')}
                                                {renderSearchInKeywordCheckbox('allOther')}
                                            </div>
                                            <div className="search-form__open split-right">
                                                <Link to="/search/advanced">Advanced Search</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h-stack h-stack-xs t-fd-c">
                                        <div>
                                            <div className="label fw-b">Search in:</div>
                                        </div>
                                        <div className="search-form__filter-toggles h-stack h-stack-xs ">
                                            <div>
                                                <label className="switch switch--toggle switch--label-multiple" style={{width: 'max-content'}}>
                                                    <span className="switch__left_label label">Latest Modifications</span>
                                                    <input className="visually-hidden"
                                                        checked={searchInAllAvailableData}
                                                        onChange={evt => setSearchInAllAvailableData(evt.target.checked)}
                                                        type="checkbox"
                                                    />
                                                    <span className="switch__switch" onTouchEnd={(evt) => { setSearchInAllAvailableData(!searchInAllAvailableData) }}>
                                                        <span className="dot" />
                                                    </span>
                                                    <span className="switch__right_label label">All Available Data</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </div >
    );

};
