import React, { useEffect } from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import LoginPage from './auth/LoginPage.container';
import PrivateRoute from './auth/PrivateRoute.container';
import SavedCompany from './saved-company/SavedCompany.container';
import RegisterPage from './auth/RegisterPage.container';
import { HomePageComponent } from './home/HomePage.component';
import CompanyUpload from './company-upload/CompanyUpload.container';
import { smoothScrollToTop } from './scrollhelper';
import SavedCompaniesPage from './saved-companies/SavedCompaniesPage.container';
import SavedSearch from './search/saved-search-page/SavedSearchPage.container';
import SavedSearchTable from './search/saved-search-table/SavedSearchTable.container';
import AdvancedSearch from './search/advanced-search-page/AdvancedSearchpage.container';
import { SearchPageComponent } from './search/search-page/SearchPage.component';
import LinkedInRegisterPage from './auth/SocialRegister/LinkedInRegister.container';
import LinkedInConfirmPage from './auth/SocialRegister/LinkedInConfirm.container';
import GoogleRegisterPage from './auth/SocialRegister/GoogleRegister.container';
import GoogleConfirmPage from './auth/SocialRegister/GoogleConfirm.container';
import AccountSettingsPage from './account-settings/AccountSettingsPage.container';
import CreditHistoryPage from './credit-history/CreditHistoryPage.container';
import CompanyEdit from './company-edit/CompanyEdit.container';
import { AppRoutes } from './routes';
import WorkspaceSettingsPage from './workspace-settings/WorkspaceSettingsPage.container';
import WorkspaceResponse from './workspace-settings/workspace-response/WorkspaceResponse.container';
import WorkspaceFormPage from './workspace-settings/workspace-form/WorkspaceFormPage.container';
import ForgotPasswordPage from './auth/ForgotPassword.container';
import { ForgotPassswordResponseComponent } from './auth/ForgotPasswordConfirm.component';
import ResetPasswordPage from './auth/ResetPassword.container';
import { ResetPassswordResponseComponent } from './auth/ResetPasswordConfirm.component';
import BillingInformation from './billing-information/BillingInformation.container';
import WorkspaceDeleteConfirm from './workspace-settings/delete-confirm/WorkspaceDeleteConfirm.container';
import WorkspaceAcceptOwnership from './workspace-settings/accept-ownership/WorkspaceAcceptOwnership.container';
import Checkout from './billing-information/Checkout.container';
import InvitePage from './invitation/Invitation.container';
import { MarkedForDeletedUserComponent } from './account-settings/MarkedForDeletedUser.component';
import ContactUsComponent from './contact-us/ContactUs.component';
import ManagementContactActivation from './management-contact/ManagementContactActiationPage.container';
import { HowItWorksComponent } from './how-it-works/HowItWorks.component';
import { FrequentlyAskedQuestionsComponent } from './how-it-works/FrequentlyAskedQuestions';
import { TermsAndConditionsPage } from './how-it-works/TermsAndConditionsPage';
import { PrivacyPolicyComponent } from './how-it-works/PrivacyPolicy';

export interface MainDispatchProps {
    loadCountries: () => void;
}

const MainComponent: React.FC<MainDispatchProps & RouteComponentProps<any>> = ({ location, loadCountries, history }) => {
    useEffect(() => {
        smoothScrollToTop();
    }, [location.pathname]);

    useEffect(() => {
        loadCountries();
    }, [loadCountries]);

    const mainClassNames: any = {
        '/search': 'main--search',
        '/saved-search': 'main--search',
        '/saved-search-table': 'main--search',
        '/saved-companies': 'main--search',
        '/login': 'main--user-form',
        '/linkedin': 'main--user-form',
        '/invite': 'main--search',
        '/contact-us': 'main--search',
        '/how-it-works': 'main--search',
        '/faq': 'main--search',
        '/terms-and-conditions': 'main--search',
        '/privacy-policy': 'main--search',
        '/google': 'main--user-form',
        '/register': 'main--user-form',
        '/forgotpassword': 'main--user-form',
        '/forgotpassword-confirm': 'main--user-form',
        '/resetpassword': 'main--user-form',
        '/company': 'main--company-profile',
        '/upload-company': 'main--company-upload',
        '/edit-company': 'main--company-upload',
        '/account': 'main--company-workspace',
        '/account/workspace-delete-confirm': 'main--company-workspace',
        '/workspace': 'main--company-workspace',
        '/$': 'main--home',
    };

    const pathMatch = Object.keys(mainClassNames)
        .filter(key => location.pathname.match(key));

    const mainClassName = pathMatch ? mainClassNames[pathMatch[0]] : '';

    return (
        <div className={`main ${mainClassName}`} id="main">
            <Switch>
                <Route path="/login" exact={true}>
                    <LoginPage />
                </Route>
                <PrivateRoute path={AppRoutes.SavedCompany} exact={true} component={SavedCompany} />
                <PrivateRoute path={AppRoutes.AdvancedSearch} exact={true} component={AdvancedSearch} />
                <PrivateRoute path={AppRoutes.Search} exact={true} component={SearchPageComponent} />
                <PrivateRoute path={AppRoutes.UploadCompany} exact={true} component={CompanyUpload} />
                <PrivateRoute path={AppRoutes.EditCompany()} exact={true} component={CompanyEdit} />
                <PrivateRoute path={AppRoutes.SavedCompanies} exact={true} component={SavedCompaniesPage} />
                <PrivateRoute path={AppRoutes.SavedSearch} exact={true} component={SavedSearch} />
                <PrivateRoute path={AppRoutes.SavedSearchTable} exact={true} component={SavedSearchTable} />
                <PrivateRoute path={AppRoutes.AccountSettings} exact={true} component={AccountSettingsPage} />
                <PrivateRoute path={AppRoutes.CreditHistory} exact={true} component={CreditHistoryPage} />
                <PrivateRoute path={AppRoutes.WorkspaceForm} exact={true} component={WorkspaceFormPage} />
                <PrivateRoute path={AppRoutes.WorkspaceSettings()} exact={true} component={WorkspaceSettingsPage} />
                <PrivateRoute path={AppRoutes.WorkspaceResponse} exact={true} component={WorkspaceResponse} />
                <PrivateRoute path={AppRoutes.BillingInformation} exact={true} component={BillingInformation} />
                <PrivateRoute path={AppRoutes.Checkout} exact={true} component={Checkout} />
                <PrivateRoute path={AppRoutes.Invite} exact={true} component={InvitePage} />
                <PrivateRoute path={AppRoutes.WorkspaceDeleteConfirm} exact={true} component={WorkspaceDeleteConfirm} />
                <PrivateRoute path={AppRoutes.WorkspaceAcceptOwnership} exact={true} component={WorkspaceAcceptOwnership} />
                <Route path={AppRoutes.MarkedForDeleted} exact={true} component={MarkedForDeletedUserComponent} />
                <Route path={AppRoutes.GoogleRegister} exact={true} component={GoogleRegisterPage} />
                <Route path={AppRoutes.GoogleConfirm} exact={true} component={GoogleConfirmPage} />
                <Route path={AppRoutes.LinkedInRegier} exact={true} component={LinkedInRegisterPage} />
                <Route path={AppRoutes.LinkedInConfirm} exact={true} component={LinkedInConfirmPage} />
                <Route path={AppRoutes.Register} exact={true} component={RegisterPage} />
                <Route path={AppRoutes.ForgotPassword} exact={true} component={ForgotPasswordPage} />
                <Route path={AppRoutes.ForgotPasswordConfirm} exact={true} component={ForgotPassswordResponseComponent} />
                <Route path={AppRoutes.ResetPassword} exact={true} component={ResetPasswordPage} />
                <Route path={AppRoutes.ResetPasswordConfirm} exact={true} component={ResetPassswordResponseComponent} />
                <Route path={AppRoutes.ContactUs} exact={true} component={ContactUsComponent} />
                <Route path={AppRoutes.ManagementContactActivation} exact={true} component={ManagementContactActivation} />
                <Route path={AppRoutes.HowItWorks} exact={true} component={HowItWorksComponent} />
                <Route path={AppRoutes.Faq} exact={true} component={FrequentlyAskedQuestionsComponent} />
                <Route path={AppRoutes.TermsAndConditions} exact={true} component={TermsAndConditionsPage} />
                <Route path={AppRoutes.PrivacyPolicy} exact={true} component={PrivacyPolicyComponent} />
                <Route path="/" exact={true} component={HomePageComponent} />
            </Switch>
        </div>
    );
};

export const Main = withRouter(MainComponent);