import React, { useState } from 'react';
import { TextFieldWithPriceComponent } from '../TextFieldWithPrice.component';
import { SelectWithPriceComponent } from '../SelectWithPrice.component';
import { CompanyUploadSummary, CreditMap, TouchedSummaryInputsModel } from '../company-upload.models';
import { CompanyAttributeKey } from '../../company.constants';
import { CountryModel } from '../../meta/meta.models';
import { IndustriesArray, EntityRelationshipStatusArray, SectorModel } from '../../models';
import { FullPageLoadingComponent } from '../../FullpageLoading.component';
import { companyEditService } from '../../company-edit/company-edit.service';
import { CompanyUploadModel } from '../CompanyUpload.model';
import PhoneNumberComponent from '../../components/phone-number/PhoneNumberComponent';

export interface SummaryUploadTabStateProps {
    countries?: CountryModel[];
}

export interface SummaryUploadTabProps {
    originalValue?: CompanyUploadModel['summary'];
    isEditMode?: boolean;
    requiredFields: string[];
    requiredMainAddressFields: string[];
    requiredBusinessAddressFields: string[];
    requiredOtherContactFields: string[];
    sectorList: SectorModel[];
    companyCredits?: CreditMap;
    summary: CompanyUploadModel['summary'];
    updateSummary: (key: keyof CompanyUploadSummary, value: any) => void;
    updateMainAddress: (key: keyof CompanyUploadModel['summary']['mainAddress'], value: any) => void;
    updateBusinessAddress: (key: keyof CompanyUploadModel['summary']['businessAddress'], value: any) => void;
    updateOtherContactDetails: (key: keyof CompanyUploadModel['summary']['otherContactDetails'], value: any) => void;
    touchedInputs: TouchedSummaryInputsModel;
    isValid: () => boolean;
}

export const SummaryUploadTabComponent: React.FC<SummaryUploadTabProps & SummaryUploadTabStateProps>
    = ({
        summary,
        originalValue,
        isEditMode,
        companyCredits,
        updateSummary,
        updateMainAddress,
        updateBusinessAddress,
        updateOtherContactDetails,
        countries,
        sectorList,
        touchedInputs,
        requiredFields,
        requiredMainAddressFields,
        requiredBusinessAddressFields,
        requiredOtherContactFields }) => {
        const [sectors, setSectors] = useState<SectorModel[]>(sectorList);

        if (!summary || companyCredits === undefined || !countries) {
            return <FullPageLoadingComponent />;
        }

        const countriesOptions = countries.filter(c => c.id).map(c => ({
            name: c.name || '',
            value: c.id || '',
        }));

        const industries = IndustriesArray.filter(i => i.value !== undefined).map((industryOption) => ({
            name: industryOption.label || '',
            value: industryOption.value === 0 ? 0 : industryOption.value || '',
        }));

        const entityRelationshipStatuses = EntityRelationshipStatusArray.filter(i => i.value !== undefined).map((r) => ({
            name: r.label || '',
            value: r.value === 0 ? 0 : r.value || '',
        }));

        const getSummaryFormProps = (fieldname: keyof CompanyUploadSummary) => ({
            isRequired: requiredFields.includes(fieldname),
            isTouched: touchedInputs.summary[fieldname],
            onBlur: (value: any) => updateSummary(fieldname, value)
        });

        const getMainAddressFormProps = (fieldname: keyof CompanyUploadModel['summary']['mainAddress']) => ({
            isRequired: requiredMainAddressFields.includes(fieldname),
            isTouched: touchedInputs.mainAddress[fieldname],
            onBlur: (value: any) => updateMainAddress(fieldname, value)
        });

        const getBusinessAddressFormProps = (fieldname: keyof CompanyUploadModel['summary']['businessAddress']) => ({
            isRequired: requiredBusinessAddressFields.includes(fieldname),
            isTouched: touchedInputs.businessAddress[fieldname],
            onBlur: (value: any) => updateBusinessAddress(fieldname, value)
        });

        const getOtherContactDetailsFormProps = (fieldname: keyof CompanyUploadModel['summary']['otherContactDetails']) => ({
            isRequired: requiredOtherContactFields.includes(fieldname),
            isTouched: touchedInputs.otherContactDetails[fieldname],
            onBlur: (value: any) => updateOtherContactDetails(fieldname, value)
        });

        return (
            <div className="stack stack-m">
                <div className="upload-form__section">
                    <div className="stack stack-m">
                        <span className="icon-header icon-header--header-right">
                            <span className="icon-header__inner">
                                <svg className="icon icon--l ">
                                    <use xlinkHref="#icon-edit" />
                                </svg>
                                <span className="title title-s">Name and Introduction</span>
                            </span>
                        </span>
                        <div className="grid">
                            <div className="gi-12">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.fullname.disabled}
                                    label="Company's Full Name"
                                    {...getSummaryFormProps('fullname')}
                                    uploadValueKey={CompanyAttributeKey.Summary.FullName}
                                    value={summary.fullname.value}
                                    originalValue={originalValue?.fullname.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-12">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.shortname.disabled}
                                    label="Company's Short Name"
                                    {...getSummaryFormProps('shortname')}
                                    uploadValueKey={CompanyAttributeKey.Summary.ShortName}
                                    value={summary.shortname.value}
                                    originalValue={originalValue?.shortname.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-12">
                                <TextFieldWithPriceComponent
                                    type="textarea"
                                    disabled={summary.introduction.disabled}
                                    label="Introduction"
                                    {...getSummaryFormProps('introduction')}
                                    isMultiline={true}
                                    uploadValueKey={CompanyAttributeKey.Summary.Introduction}
                                    value={summary.introduction.value}
                                    originalValue={originalValue?.introduction.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-6">
                                <SelectWithPriceComponent
                                    disabled={summary.industry.disabled}
                                    label="Industry"
                                    options={industries}
                                    isRequired={requiredFields.includes('industry')}
                                    isTouched={touchedInputs.summary.industry}
                                    onBlur={(value: any) => {
                                        const selectedSectors = companyEditService.selectSectorsByIndustry(Number(value));
                                        setSectors(selectedSectors);
                                        updateSummary('industry', value);
                                        updateSummary('sector', undefined);
                                    }}
                                    uploadValueKey={CompanyAttributeKey.Summary.Industry}
                                    value={summary.industry.value}
                                    originalValue={originalValue?.industry.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-6">
                                <SelectWithPriceComponent
                                    disabled={summary.sector.disabled}
                                    label="Sub-Sector"
                                    options={sectors}
                                    {...getSummaryFormProps('sector')}
                                    uploadValueKey={CompanyAttributeKey.Summary.Sector}
                                    value={summary.sector.value}
                                    originalValue={originalValue?.sector.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-6">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.formOfOrganization.disabled}
                                    label="Form of Organization"
                                    {...getSummaryFormProps('formOfOrganization')}
                                    uploadValueKey={CompanyAttributeKey.Summary.FormOfOrganization}
                                    value={summary.formOfOrganization.value}
                                    originalValue={originalValue?.formOfOrganization.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-6">
                                <SelectWithPriceComponent
                                    disabled={summary.countryOfFoundation.disabled}
                                    label="Country of Foundation" options={countriesOptions}
                                    {...getSummaryFormProps('countryOfFoundation')}
                                    uploadValueKey={CompanyAttributeKey.Summary.CountryOfFoundation}
                                    value={summary.countryOfFoundation.value}
                                    originalValue={originalValue?.countryOfFoundation.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-6">
                                <SelectWithPriceComponent
                                    disabled={summary.entityRelationshipStatus.disabled}
                                    label="Entity Relationship Status" options={entityRelationshipStatuses}
                                    {...getSummaryFormProps('entityRelationshipStatus')}
                                    uploadValueKey={CompanyAttributeKey.Summary.EntityRelationshipStatus}
                                    value={summary.entityRelationshipStatus.value}
                                    originalValue={originalValue?.entityRelationshipStatus.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-6">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.identificationNumber.disabled}
                                    label="Identification Number"
                                    {...getSummaryFormProps('identificationNumber')}
                                    uploadValueKey={CompanyAttributeKey.Summary.IdentificationNumber}
                                    value={summary.identificationNumber.value}
                                    onBlur={(value) => updateSummary('identificationNumber', value)}
                                    originalValue={originalValue?.identificationNumber.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-6">
                                <TextFieldWithPriceComponent
                                    disabled={summary.foundationYear.disabled}
                                    label="Foundation Year"
                                    {...getSummaryFormProps('foundationYear')}
                                    type="number"
                                    uploadValueKey={CompanyAttributeKey.Summary.FoundationYear}
                                    value={summary.foundationYear.value}
                                    originalValue={originalValue?.foundationYear.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-6">
                                <TextFieldWithPriceComponent type="number"
                                    disabled={summary.numberOfEmployees.disabled}
                                    label="Number of Employees"
                                    {...getSummaryFormProps('numberOfEmployees')}
                                    uploadValueKey={CompanyAttributeKey.Summary.NumberOfEmployees}
                                    value={summary.numberOfEmployees.value}
                                    originalValue={originalValue?.numberOfEmployees.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="upload-form__section">
                    <div className="stack stack-xs">
                        <p className="title title-s">Main Address</p>
                        <div className="grid">
                            <div className="gi-6">
                                <SelectWithPriceComponent
                                    disabled={summary.mainAddress.countryId.disabled}
                                    label="Country"
                                    options={countriesOptions}
                                    isRequired={true}
                                    isTouched={touchedInputs.mainAddress.countryId}
                                    onBlur={(value: any) => {
                                        updateMainAddress('countryId', value);

                                        // if (value && value !== '' && !summary.otherContactDetails.mainTelephoneNumber.disabled) {
                                        //     const mainTelephoneNumber = summary.otherContactDetails.mainTelephoneNumber;
                                        //     const codes = mainTelephoneNumber.value?.split('-');
                                        //     const countryCode = countries.find(c => c.id === Number(value))?.code;
                                        //     const areaCode = codes?.[1] ?? '';
                                        //     const phoneNumber = codes?.[2] ?? '';
                                        //     const newValue = `${countryCode}-${areaCode}-${phoneNumber}`;
                                        //     updateOtherContactDetails('mainTelephoneNumber', newValue);
                                        // }
                                    }}
                                    uploadValueKey={CompanyAttributeKey.MainAddress.Country}
                                    value={summary.mainAddress.countryId.value}
                                    originalValue={originalValue?.mainAddress.countryId.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-6">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.mainAddress.zip.disabled}
                                    label="Postal (ZIP) Code"
                                    {...getMainAddressFormProps('zip')}
                                    uploadValueKey={CompanyAttributeKey.MainAddress.Zip}
                                    value={summary.mainAddress.zip.value}
                                    originalValue={originalValue?.mainAddress.zip.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-6">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.mainAddress.city.disabled}
                                    label="City"
                                    {...getMainAddressFormProps('city')}
                                    uploadValueKey={CompanyAttributeKey.MainAddress.City}
                                    value={summary.mainAddress.city.value}
                                    originalValue={originalValue?.mainAddress.city.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-6">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.mainAddress.street.disabled}
                                    label="Street"
                                    {...getMainAddressFormProps('street')}
                                    uploadValueKey={CompanyAttributeKey.MainAddress.Street}
                                    value={summary.mainAddress.street.value}
                                    originalValue={originalValue?.mainAddress.street.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-4">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.mainAddress.houseNumber.disabled}
                                    label="House Number"
                                    {...getMainAddressFormProps('houseNumber')}
                                    uploadValueKey={CompanyAttributeKey.MainAddress.HouseNumber}
                                    value={summary.mainAddress.houseNumber.value}
                                    originalValue={originalValue?.mainAddress.houseNumber.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-4">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.mainAddress.floor.disabled}
                                    label="Floor"
                                    {...getMainAddressFormProps('floor')}
                                    uploadValueKey={CompanyAttributeKey.MainAddress.Floor}
                                    value={summary.mainAddress.floor.value}
                                    originalValue={originalValue?.mainAddress.floor.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-4">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.mainAddress.door.disabled}
                                    label="Suite or Door"
                                    {...getMainAddressFormProps('door')}
                                    uploadValueKey={CompanyAttributeKey.MainAddress.Door}
                                    value={summary.mainAddress.door.value}
                                    originalValue={originalValue?.mainAddress.door.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="upload-form__section">
                    <div className="stack stack-xs">
                        <p className="title title-s">Business Address</p>
                        <div className="grid">
                            <div className="gi-6">
                                <SelectWithPriceComponent
                                    disabled={summary.businessAddress.countryId.disabled}
                                    label="Country" options={countriesOptions}
                                    {...getBusinessAddressFormProps('countryId')}
                                    uploadValueKey={CompanyAttributeKey.BusinessAddress.Country}
                                    value={summary.businessAddress.countryId.value}
                                    originalValue={originalValue?.businessAddress.countryId.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-6">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.businessAddress.zip.disabled}
                                    label="Postal (ZIP) Code"
                                    {...getBusinessAddressFormProps('zip')}
                                    uploadValueKey={CompanyAttributeKey.BusinessAddress.Zip}
                                    value={summary.businessAddress.zip.value}
                                    originalValue={originalValue?.businessAddress.zip.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-6">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.businessAddress.city.disabled}
                                    label="City"
                                    {...getBusinessAddressFormProps('city')}
                                    uploadValueKey={CompanyAttributeKey.BusinessAddress.City}
                                    value={summary.businessAddress.city.value}
                                    originalValue={originalValue?.businessAddress.city.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-6">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.businessAddress.street.disabled}
                                    label="Street"
                                    {...getBusinessAddressFormProps('street')}
                                    uploadValueKey={CompanyAttributeKey.BusinessAddress.Street}
                                    value={summary.businessAddress.street.value}
                                    originalValue={originalValue?.businessAddress.street.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-4">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.businessAddress.houseNumber.disabled}
                                    label="House Number"
                                    {...getBusinessAddressFormProps('houseNumber')}
                                    uploadValueKey={CompanyAttributeKey.BusinessAddress.HouseNumber}
                                    value={summary.businessAddress.houseNumber.value}
                                    originalValue={originalValue?.businessAddress.houseNumber.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-4">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.businessAddress.floor.disabled}
                                    label="Floor"
                                    {...getBusinessAddressFormProps('floor')}
                                    uploadValueKey={CompanyAttributeKey.BusinessAddress.Floor}
                                    value={summary.businessAddress.floor.value}
                                    originalValue={originalValue?.businessAddress.floor.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-4">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.businessAddress.door.disabled}
                                    label="Suite or Door"
                                    {...getBusinessAddressFormProps('door')}
                                    uploadValueKey={CompanyAttributeKey.BusinessAddress.Door}
                                    value={summary.businessAddress.door.value}
                                    originalValue={originalValue?.businessAddress.door.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="upload-form__section">
                    <div className="stack stack-xs">
                        <p className="title title-s">Other Contact Details</p>
                        <div className="grid">
                            <div className="gi-12">
                                <TextFieldWithPriceComponent
                                    type="text"
                                    customValidation={(value) => {
                                        return value && value.includes('.');
                                    }}
                                    disabled={summary.otherContactDetails.website.disabled}
                                    label="Website"
                                    {...getOtherContactDetailsFormProps('website')}
                                    uploadValueKey={CompanyAttributeKey.OtherContactDetails.Website}
                                    value={summary.otherContactDetails.website.value}
                                    originalValue={originalValue?.otherContactDetails.website.value}
                                    isEditMode={isEditMode}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-4">
                                <TextFieldWithPriceComponent type="text"
                                    disabled={summary.otherContactDetails.contactEmailAddress.disabled}
                                    value={summary.otherContactDetails.contactEmailAddress.value}
                                    originalValue={originalValue?.otherContactDetails.contactEmailAddress.value}
                                    label="Email Address"
                                    {...getOtherContactDetailsFormProps('contactEmailAddress')}
                                    uploadValueKey={CompanyAttributeKey.OtherContactDetails.ContactEmailAddress}
                                    customValidation={(value) => {
                                        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
                                    }}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="gi-6">
                                <PhoneNumberComponent
                                    label="Main Telephone Number"
                                    isRequired={requiredOtherContactFields.includes('mainTelephoneNumber')}
                                    isTouched={touchedInputs.otherContactDetails.mainTelephoneNumber}
                                    onBlur={(value: any) => {
                                        updateOtherContactDetails('mainTelephoneNumber', value);
                                    }}
                                    disabled={summary.otherContactDetails.mainTelephoneNumber.disabled}
                                    value={summary.otherContactDetails.mainTelephoneNumber.value}
                                    originalValue={originalValue?.otherContactDetails.mainTelephoneNumber.value}
                                    uploadValueKey={CompanyAttributeKey.OtherContactDetails.MainTelephoneNumber}
                                    isEditMode={isEditMode}
                                    property={summary.otherContactDetails.mainTelephoneNumber}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    };