import React from "react";
import "./common.scss";

export const PrivacyPolicyComponent: React.FC = () => {
  return (
    <>
      <div className="section section--header">
        <div className="container">
          <div className="stack stack-m">
            <div className="grid">
              <div className="gi-12 t-gi-8 m-gi-4">
                <div className="h-stack h-stack-xs jc-sb m-fd-c">
                  <div>
                    <h1 className="title title-l">Privacy Policy</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section--content privacy-policy">
        <div className="container">
          <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
            <b>Privacy Notice</b>
          </h1>
          <br />
          <h2 style={{ textAlign: "center" }}>Last updated: December 16, 2021</h2>
          <br />
          <p>
            This Privacy Policy (“<b>Privacy Policy</b>”) details the personal
            data Digital Media Kft. “<b>Service Provider</b>”, “<b>we</b>”, “
            <b>us</b>” or “<b>our</b>”) receives about you, how we process it
            while it provides you the Service through the website
            https://gravity.surf (“Website”) and your rights and obligations in
            relation to your personal data. Service Provider registered at 1051
            Budapest, Vörösmarty tér 1., Hungary is the data controller of the personal
            data processed by the Service as defined in the Terms and Conditions
            (“T&C”) for the purposes of the General Data Protection Regulation
            (“<b>GDPR</b>”) and any relevant local legislation (“
            <b>Data Protection Laws</b>”).
            <br />
            <br />
          </p>
          <p>
            By registration to the Service, you agree to the terms of this
            Privacy Policy. Capitalized terms not defined here have the meanings
            set forth in the <b>Terms and Conditions</b>. We may update our
            Privacy Policy to reflect changes to our data processing practices.
            If we do this and the changes are material, we will post a notice on
            changes we have made to this Privacy Policy on the Website for at
            least 15 days before the changes are made, and we will indicate the
            date these terms were last revised at the top of the Privacy Policy.
            Any revisions to this Privacy Policy will become effective at the
            end of that 15 day period.
          </p>
          <br />
          <p>
            We recommend that you read this Privacy Policy in full to ensure you
            are fully informed. If you have any questions about this Privacy
            Policy or Service Provider's data collection, use, and disclosure
            practices, please contact us at{" "}
            <a href="mailto:policies@gravity.surf">policies@gravity.surf.</a>
          </p>
          <br />
          <p style={{ color: "#031b4e" }}>1. INFORMATION WE COLLECT</p>
          <br />
          <h2 style={{ fontWeight: "bold" }}>A. Information You Provide</h2>
          <br />
          <p>
            <b>Account Registration</b>. When you register for an account to the
            Service, we ask for your contact information (name, email address,
            and industry you work in selected from a drop-down list of the
            registration form). You may share with us the name of the company you
            work for, the location country of its registered seat and additional
            contact information for extra starting Credit(s), including your
            telephone number, LinkedIn or Google account if you use them for
            signing into the Service. The data that we request at the time of
            registration is necessary for the provision of our Services.
          </p>
          <br />
          <p>
            Your contact information will help the Service to get in touch with
            you when needed, and will not be published on the Website, unless
            you specifically authorize the Service Provider to do so.
          </p>
          <br />
          <p>
            <b>Profile.</b> After registering to the Service, your profile (“
            <b>User Account</b>”) will store (i) a copy of all Company
            Information purchased by you (<b>Purchased Company Information</b>);
            (ii) the number of available Credits and information concerning past
            transactions and (iii) the information connected to the Workspace(s)
            where you are a member or Workspace Manager.
          </p>
          <br />
          <p>
            <b>Account Settings.</b> Within Account Settings you can indicate
            the industry that you work in and the company you work for and
            additional contact information (phone number).
          </p>
          <br />
          <p>
            <b>Billing Information.</b> At the time of Company Information
            purchase, you need to provide certain billing information. You might
            also provide payment information, such as payment card details,
            which we collect via secure payment processing services. This data
            is necessary to provide you with our Services.
          </p>
          <br />
          <p>
            <b>Other Information Related to the Provision of the Service. </b>
            You may decide to share further information, including personal
            data, with us when you contact us via e-mail, submit forms on our
            Website or otherwise communicate with us. It is solely your decision
            to share any data with us during such communications, so our
            processing of such data will be based on your consent.
          </p>
          <br />
          <p>
            <b>Entitlement to act.</b> If you are an Enterprise Representative
            you must demonstrate your authorization to act on behalf of the
            Enterprise. You can verify your signatory rights by sending a copy
            of the company excerpt that you are representing showing your
            signatory rights or any other document authorizing you to represent
            the company issued by the person listed in the official excerpt as
            having signatory rights on behalf of the Enterprise.
          </p>
          <br />
          <h2 style={{ fontWeight: "bold" }}>
            B. Information We Collect When You Use Our Services.
          </h2>
          <br />
          <p>
            <b>Logs.</b> As most websites and services provided through the
            Internet, we gather certain information and store it in log files
            when you interact with our Service. This information includes
            internet protocol (IP) addresses, identification data associated
            with you, time of access, and error logs.
          </p>
          <br />
          <p>
            <b>Activity Data.</b> The data generated by your activity (e.g.
            transaction data, number of Credit(s), source indication, initiation
            of Company Information modification) will be stored within the
            Service and depending on the data, will be anonymously indicated in
            the Database (source).
            <br />
            Please, note that Service Provider may
            collect anonymized data (statistical data) on your activities for
            its own use for the development of your user experience.
          </p>
          <br />
          <p>
            Also, for security purposes, certain information (such as your IP
            address, approx. location) will be checked and logged for the
            verification of your location and the safe provision of the Service.
          </p>
          <br />
          <h2 style={{ fontWeight: "bold" }}>
            C. Information provided by a User about you
          </h2>
          <br />
          <p>
            Your data might be uploaded to the Database of the Service by any
            User if the User uploads and publishes the contact and/or business
            information (name, position, location, functions, telephone number,
            e-mail address) that holds about you as a person acting in
            managerial position (<b>Manager</b>) at an Enterprise. Your personal
            data will only be temporarily stored and published by the Service
            for 3 months after the data's upload unless you approve the use of
            personal data concerning you responding to Service provider's e-mail
            or text notification. During the first 7 days of this 3 month-period,
            the Service does not disclose your personal data to the Public at
            all. Service provider will contact you several times via e-mail or
            text message to inform you about the upload and publication of your
            personal data to the Database of the Service and provide you the
            opportunity to give instructions on the use or deletion of your
            personal data. Please, note that you can prohibit the future use of
            your personal data by the Service at any time by contacting the
            Service Provider.
          </p>
          <br />
          <h2 style={{ fontWeight: "bold" }}>
            D. Cookies and Other Tracking Technologies.
          </h2>
          <br />
          <p>
            We do not gather information automatically by the use of cookies;
            however, we may use some log files for the safe operations of the
            Service or collect certain information automatically from your
            device. This information may include internet protocol (IP)
            addresses, browser type, internet service provider (ISP), operating
            system, and the type, size and resolution of the screen on which you
            are viewing the platform.
          </p>
          <br />
          <p style={{ color: "#031b4e" }}>2. HOW WE USE INFORMATION</p>
          <br />
          <h2>
            We strictly use the information we collect for the proper operation
            of the Service, including:
          </h2>
          <br />
          <ul style={{ paddingLeft: "20px" }}>
            <li>
              Provide, operate, maintain and improve the performance and
              utilization of our Service;
            </li>
            <li>Develop new features, functionality or other tools;</li>
            <li>
              Communicate with you to provide you with updates and other
              information relating to the Service or request your instructions
              on the use of your personal data;
            </li>
            <li>
              Find and prevent fraud; and protect the security and safety of our
              Service; identify violations of our Terms of Service;
            </li>
            <li>
              For compliance purposes, including enforcing our Terms of Service,
              or other legal rights, or as may be required by applicable laws
              and regulations (e.g. billing);
            </li>
            <li>
              Keep our Database accurate and up-to-date in order to provide
              reliable Service to the general public. (Information clearance);
            </li>
            <li>
              We also use this data to develop aggregate analysis and business
              intelligence that enable us to operate, protect, make informed
              decisions, and report on the performance of our business;
            </li>
            <li>
              Adjust the formatting of the page to the size and type of your
              screen and its resolution.{" "}
            </li>
          </ul>
          <br />
          <p style={{ color: "#031b4e" }}>3. HOW WE SHARE INFORMATION</p>
          <br />
          <p>
            We will share your personal data with third parties only in
            accordance with this policy. We will never sell your personal data
            to third parties. However, we may share the information we collect,
            with third parties including the following:
          </p>
          <br />
          <p>
            A.{" "}
            <span style={{ paddingLeft: "12px" }}>
              As Required By Law and Similar Disclosures. We may share
              information to (i) comply with any applicable law, regulation,
              legal process, or governmental request; (ii) enforce this Privacy
              Policy and our Terms of Service, including investigation of
              potential violations hereof; (iii) detect, prevent, or otherwise
              address fraud, security, or technical issues; (iv) respond to your
              requests; or (v) protect our rights. This might include providing
              access and exchanging information with other companies, lawyers,
              government entities.
            </span>
            <br />
            B.{" "}
            <span style={{ paddingLeft: "12px" }}>
              When we utilize service suppliers for processing any personal
              data, we implement contractual protections limiting the use of
              that personal data to the provision of services to Us and service
              suppliers (data processors) only have access to personal data for
              a limited purpose and time according to our instructions. We will
              provide personal data to our hosting provider, support provider or
              other service providers Bilingo, and PayPal.).{" "}
            </span>
            <br />
            C.{" "}
            <span style={{ paddingLeft: "12px" }}>
              If you use the Service as a Workspace member, some of your
              activity data will be shared with the Workspace manager and other
              members of the Workspace.
            </span>
          </p>
          <br />
          <p style={{ color: "#031b4e" }}>
            4. LEGAL BASIS FOR PROCESSING PERSONAL INFORMATION
          </p>
          <br />
          <p>
            Our legal basis for collecting and using the personal data described
            above
          </p>
          <br />
          <p>
            (i){" "}
            <span style={{ paddingLeft: "12px" }}>
              <b>in section 1. A – B.</b> is the performance of the contract
              (T&C) agreed with you; (GDPR 6. § (1) c)), or our legitimate
              interest that we keep the quality of the Service and the
              reliability of the information in the Database (Information
              clearance, verification of authorization), while
            </span>
            <br />
            (ii){" "}
            <span style={{ paddingLeft: "12px" }}>
              for personal data indicated in section <b>1. C.</b> is our
              legitimate interest (GDPR 6. § (1) f)) or your affirmative consent
              when you explicitly give it to us (GDPR 6. § (1) a)).
            </span>
          </p>
          <br />
          <p style={{ color: "#031b4e" }}>5. SECURITY</p>
          <br />
          <p  id="section7"></p>
          <p>
            We have implemented administrative and technical safeguards it
            believes are appropriate to protect the confidentiality, integrity
            and availability of your personal data processed. We employ a
            variety of security technologies and measures designed to protect
            information from unauthorized access, use, or disclosure. The
            measures we use are designed to provide a level of security
            appropriate to the risk of processing your personal information.
          </p>
          <br />
          <p >
            The communication between the Website and the Database is protected
            by SSL encryption. For the protection of the User passwords, Service
            Provider has implemented cryptographic hash function. The Database
            access is based on JSON Web Token identification.
          </p>
          <br />
          <p style={{ color: "#031b4e" }} >6. DATA RETENTION</p>
          <br />
          <p>
            We retain personal data we collect from you in line with our
            contractual obligations under the T&C so that we can provide you
            with the Service or comply with applicable legal, tax, or accounting
            requirements.
            <br />
            <br />
            In the event that you terminate the use of the Service and delete
            your account (User account) voluntarily, your contact data (name,
            e-mail address and phone number together with the information stored
            on your account (including Purchased Company Information and
            Credits) will be either deleted or anonymized within 30 days after
            termination. In case you breach the provisions of the T&C and we
            decide to close your account, your User Account will be immediately
            closed without further communication to you after our decision taken
            on the consequences of such breach in line with the T&C. We reserve
            the right to keep your User Account and access it for our legitimate
            purpose which is to take actions and/or use remedies available to us
            under the T&C or local law.
            <br />
            <br />
            You have the right terminate any Workspace membership that you may
            hold. Disconnecting from a Workspace means that you will have no
            further access to the given Workspace account while you may keep
            your User Account.
          </p>
          <br />
          <p style={{ color: "#031b4e" }}>7. YOUR USER ACCOUNT</p>
          <br />
          <p>
            Accessing your User Account, you may encounter the personal data
            associated with you within the Service. You can manage your account
            settings or modify some part of the personal data to update or
            correct the information associated to you. Please, note that
            transaction data or Credit numbers are automatically generated by the
            Service and not modifiable by you.
            <br />
            <br />
            The data in your User Account or generated during the use of the
            Service will be stored and archived periodically by us according to
            backup processes conducted in the ordinary course of business for
            disaster recovery purposes. Your ability to access and correct your
            Account information may be temporarily limited where access and
            correction could: inhibit Service Provider's ability to comply with
            a legal obligation; inhibit Service Provider's ability to
            investigate, make or defend legal claims; or result in breach of a
            contract or disclosure of trade secrets or other proprietary
            business information belonging to Service Provider or a third party.
          </p>
          <br />
          <p style={{ color: "#031b4e" }}>
            8. YOUR DATA PROTECTION RIGHTS UNDER THE GENERAL DATA PROTECTION
            REGULATION (GDPR)
          </p>
          <br />
          <p>
            If you are a resident of the EEA, you have the following data
            protection rights:
          </p>
          <br />
          <ul style={{ paddingLeft: "20px" }}>
            <li>
              If you wish to access, correct, update, or request deletion of
              your personal information, you can do so at any time by contacting
              the Service Provider using the pre-established forms.
            </li>
            <li>
              In addition, you can object to the processing of your personal
              information, ask us to restrict the processing of your personal
              information, or request portability of your personal information.
              You can exercise these rights by emailing <a href="mailto:policies@gravity.surf">policies@gravity.surf.</a>
            </li>
            <li>
              If we have collected and process your personal information with
              your consent, you can withdraw your consent any time. Withdrawing
              your consent will not affect the lawfulness of any processing we
              conducted prior to your withdrawal, nor will it affect the
              processing of your personal information conducted in reliance on
              lawful processing grounds other than consent.
            </li>
          </ul>
          <br />
          <p>
            We respond to requests we receive from individuals wishing to
            exercise their data protection rights without undue delay and in any
            event within one month of receipt of the request. That period may be
            extended by two further months where necessary, taking into account
            the complexity and number of the requests. Please note that some of
            these rights are not absolute. In some cases, we may refuse a
            request to exercise particular rights if complying with it meant
            that we are no longer able to meet our statutory obligation
            including the provision of the Service. We will keep you informed as
            to the actions that we take when you make your request.
            <br />
            You may also have the right to make a GDPR complaint to the relevant
            Supervisory Authority. A list of Supervisory Authorities is
            available here:  <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            . If you need further assistance regarding your rights, please
            contact us using the contact information provided below and we will
            consider your request in accordance with applicable law.
          </p>
          <br />
          <p style={{ color: "#031b4e" }}>CONTACT US</p>
          <br />
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please feel free to email us at{" "}
            <a href="mailto:policies@gravity.surf">policies@gravity.surf.</a>
          </p>
        </div>
      </div>
    </>
  );
};
