import { actionCreator } from '../actions';
import { SearchCountResult, SimpleSearchFields } from './search.models';
import { SavedCompanyListModel } from '../saved-company/saved-company.models';

export enum SearchActionTypes {
    SEARCH_COMPANY = 'search/load',
    SEARCH_COMPANY_SUCCESS = 'search/success',
    PAY_FOR_SEARCH_RESULTS = 'search/pay',
    PAY_FOR_SEARCH_RESULTS_ERROR = 'search/pay/error',
    PAY_FOR_SEARCH_RESULTS_SUCCESS = 'search/pay/success',

    PAY_SEARCH_LOADING = 'search/pay/load',

    LOAD_SAVED_SEARCH = 'search/saved/load',
    LOAD_SAVED_SEARCH_SUCCESS = 'search/saved/load/success',
    LOAD_SAVED_SEARCH_ERROR = 'search/saved/load/error',

    LOAD_ALL_COMPANY_COUNT = 'search/allcompanycount'
}

export const searchCompanyAction = actionCreator<SearchActionTypes.SEARCH_COMPANY, SimpleSearchFields>(SearchActionTypes.SEARCH_COMPANY);
export const searchSuccessAction = actionCreator<SearchActionTypes.SEARCH_COMPANY_SUCCESS, SearchCountResult>(SearchActionTypes.SEARCH_COMPANY_SUCCESS);

export const paySearchLoadingAction = actionCreator<SearchActionTypes.PAY_SEARCH_LOADING, void>(SearchActionTypes.PAY_SEARCH_LOADING);

export const payForSearchResultsAction = actionCreator<SearchActionTypes.PAY_FOR_SEARCH_RESULTS, void>(SearchActionTypes.PAY_FOR_SEARCH_RESULTS);
export const payForSearchResultsSuccessAction = actionCreator<SearchActionTypes.PAY_FOR_SEARCH_RESULTS_SUCCESS, void>(SearchActionTypes.PAY_FOR_SEARCH_RESULTS_SUCCESS);
export const payForSearchResultsErrorAction = actionCreator<SearchActionTypes.PAY_FOR_SEARCH_RESULTS_ERROR, void>(SearchActionTypes.PAY_FOR_SEARCH_RESULTS_ERROR);

export const loadSavedSearch = actionCreator<SearchActionTypes.LOAD_SAVED_SEARCH, void>(SearchActionTypes.LOAD_SAVED_SEARCH);
export const loadSavedSearchSuccess = actionCreator<SearchActionTypes.LOAD_SAVED_SEARCH_SUCCESS, SavedCompanyListModel>(SearchActionTypes.LOAD_SAVED_SEARCH_SUCCESS);
export const loadSavedSearchError = actionCreator<SearchActionTypes.LOAD_SAVED_SEARCH_ERROR, void>(SearchActionTypes.LOAD_SAVED_SEARCH_ERROR);

export const loadAllCompanyCount = actionCreator<SearchActionTypes.LOAD_ALL_COMPANY_COUNT, number>(SearchActionTypes.LOAD_ALL_COMPANY_COUNT);

export type SearchActions =
    ReturnType<typeof searchCompanyAction> |
    ReturnType<typeof searchSuccessAction> |
    ReturnType<typeof paySearchLoadingAction> |
    ReturnType<typeof payForSearchResultsAction> |
    ReturnType<typeof payForSearchResultsSuccessAction> |
    ReturnType<typeof payForSearchResultsErrorAction> |
    ReturnType<typeof loadSavedSearch>|
    ReturnType<typeof loadSavedSearchSuccess>|
    ReturnType<typeof loadSavedSearchError> |
    ReturnType<typeof loadAllCompanyCount>
;