export enum CompanySearchState {
    Created = 1,
    Completed = 2,
    Deleted = 3,
}

export interface SavedCompaniesListModel {
    count: number;
    list: CompanyListItemModel[];
}

export interface CompanyListItemModel {
    savedCompanyId?: number;
    shortName?: string;
    fullName: string;
    countryOfFoundation: string;
    industry: string;
    totalNetRevenues?: string;
}

export interface SavedCompanySearch {
    id: number;
    name: string;
    resultCount: number;
    searchParameters: string[];
    spentCredits: number;
    state: CompanySearchState;
}

export interface SavedCompany {
    savedCompanyId: number;
    totalNetRevenues: string;
    countryOfFoundation: string;
    industry: string;
    fullName: string;
    shortName?: string;
    updatesAvailable: number;
}

export interface SearchResultsTabsHeader {
    savedCompanies: number;
    uploadedCompanies: number;
    savedSearchResults: number;
    userId: number;
}