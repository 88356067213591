import { connect } from 'react-redux';
import { SummaryUploadTabComponent, SummaryUploadTabStateProps, SummaryUploadTabProps } from './SummaryUploadTab.component';
import { countriesSelector } from '../../meta/meta.selectors';
import { AppState } from '../../root.reducer';

const mapStateToProps = (state: AppState, ): SummaryUploadTabStateProps => ({
    countries: countriesSelector(state)
});

export default connect<SummaryUploadTabStateProps, {}, SummaryUploadTabProps, AppState>(
    mapStateToProps
)(SummaryUploadTabComponent);
