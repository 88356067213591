import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SocialRegisterModel, SocialRegisterFields } from './social-register.models';
import queryString from 'query-string';
import { IndustriesArray } from '../../models';
import { TermsAndConditionsComponent } from '../TermsAndConditionsComponent';

export interface LinkedInRegisterDispatchProps {
  register(model: SocialRegisterFields): void;
}

const parseRegisterModelFromQuery = (query: string) => {
  const params = queryString.parse(query);

  const model: SocialRegisterModel = {
    firstName: params?.firstName?.toString() || '',
    lastName: params?.lastName?.toString() || '',
    accessToken: params?.accessToken?.toString() || '',
    email: params?.email?.toString() || '',
    code: params?.code?.toString() || ''
  };

  return model;
};

const LinkedInRegisterComponent: React.FC<LinkedInRegisterDispatchProps & RouteComponentProps> =
  ({ location, register }) => {
    const registerModel = parseRegisterModelFromQuery(location.search);

    const [industry, setIndustry] = useState(IndustriesArray[0].value);
    const [isTouched, setIsTouched] = useState(false);

    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [termsAndConditionTouched, setTermsAndConditionTouched] = useState(false);
    const [naturalPerson, setNaturalPerson] = useState(false);
    const [naturalPersonTouched, setNaturalPersonTouched] = useState(false);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [scrolledToEnd, setScrolledToEnd] = useState(false);

    const onRegister = async (evt: any) => {
      evt.preventDefault();
      evt.stopPropagation();

      setIsTouched(true);
      setNaturalPersonTouched(true);
      setTermsAndConditionTouched(true);

      if (industry != null && naturalPersonTouched && naturalPerson && termsAndConditionTouched && termsAndConditions) {
        const model = {
          accessToken: registerModel.accessToken,
          industry: industry || 0,
          code: registerModel.code,
        };

        await register(model);
      }
    };

    const isValid = (): any => {
      return industry == null && isTouched ? { error: 'error' } : {};
    };

    const openTermsAndConditions = () => {
      document.body.classList.add('register-popup');
      setShowTermsAndConditions(true);
    }

    const closeTermsAndConditions = (evt: any) => {
      evt.preventDefault();
      document.body.classList.remove('register-popup');
      setShowTermsAndConditions(false);
    }

    const handleScroll = (event: any) => {
      var node = event.target;
      const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
      if (bottom) {
        setScrolledToEnd(true);
      }
    }

    const termsAndConditionsPopUp =
      <div className="register-popup-dialog">
        <div className="dialog__dialog">
          <div className="stack stack-xs">
            <button className="icon-button dialog__close" id="" onClick={(evt) => closeTermsAndConditions(evt)}>
              <span className="icon-button__inner">
                <svg className="icon icon--m ">
                  <use xlinkHref="#icon-close" />
                </svg>
                <span className="visually-hidden">Close</span>
              </span>
            </button>
            <div className="terms-and-conditions" onScroll={(e) => handleScroll(e)}>
              <div>
                <TermsAndConditionsComponent />
              </div>
              <div>
                <label className="switch switch--checkbox switch--label-right" {...(termsAndConditionTouched && !termsAndConditions ? { ...{ error: "error" } } : {})}>
                  <input
                    className="visually-hidden"
                    type="checkbox"
                    id="cb-2"
                    name="cb-2"
                    checked={termsAndConditions}
                    onChange={evt => {
                      setTermsAndConditions(evt.target.checked);
                      setTermsAndConditionTouched(true);
                    }}
                  />
                  <span className="switch__switch">
                    <span className="dot"></span>
                  </span>
                  <div className="switch__label label" style={{ fontSize: '12px', paddingTop: '10px', paddingBottom: '10px' }}>
                    I have read and agree to Gravity's Terms and Conditions, including Privacy Policy and Cookie Policy.
                  </div>
                </label>
              </div>
              <div>
                <label className="switch switch--checkbox switch--label-right"  {...(naturalPersonTouched && !naturalPerson ? { ...{ error: "error" } } : {})}>
                  <input
                    className="visually-hidden"
                    type="checkbox"
                    id="cb-2"
                    name="cb-2"
                    checked={naturalPerson}
                    onChange={evt => {
                      setNaturalPerson(evt.target.checked);
                      setNaturalPersonTouched(true);
                    }}
                  />
                  <span className="switch__switch">
                    <span className="dot"></span>
                  </span>
                  <div className="switch__label label" style={{ fontSize: '12px' }}>
                    I hereby declare that I am a natural person and all my personal data provided herein is true and valid.
                  </div>
                </label>
              </div>
            </div>
            <div className="stack stack-m ai-c">
              <button className="button" onClick={(evt) => onRegister(evt)}>
                <span className="button__inner">
                  <span>Register to Gravity</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div >

    return (
      <div className="section section--content">
        <div className="container">
          <div className="grid">
            <div className="gi-4 gi-s-5 gi-e-9 t-gi-4 t-gi-s-3 t-gi-e-7 m-gi-4 m-gi-s-1 m-gi-e-4">
              <div className="stack stack-m">
                <div className="user-form">
                  <form className="stack stack-xl">
                    <div>
                      <h1 className="stack stack-xs ai-c">
                        <span className="logo logo--full logo--grey logo--color">
                          <svg className="logo__logo">
                            <use xlinkHref="#logo-full-grey-color" />
                          </svg>
                          <span className="visually-hidden">Gravity</span>
                        </span>
                        <span className="title title-m">LinkedIn register</span>
                      </h1>
                    </div>
                    <fieldset>
                      <div className="stack stack-xs">
                        <div>
                          <p className="label">First Name</p>
                          <div className="field field--input">
                            <input className="field__field" name="firstName" id="firstName"
                              defaultValue={registerModel.firstName} disabled={true} />
                          </div>
                        </div>
                        <div>
                          <p className="label">Last Name</p>
                          <div className="field field--input">
                            <input className="field__field" name="lastName" id="lastName"
                              defaultValue={registerModel.lastName} disabled={true} />
                          </div>
                        </div>
                        <div>
                          <p className="label">E-mail address</p>
                          <div className="field field--input">
                            <input className="field__field" name="email" id="email"
                              defaultValue={registerModel.email} disabled={true} />
                          </div>
                        </div>
                        <div>
                          <p className="label">Industry (you are working in)</p>
                          <div className="field field--input"  {...isValid()}>
                            <select className="field__field"
                              value={industry}
                              onChange={(evt) => setIndustry(Number(evt.target.value))}
                              name="register-industry" id="register-industry"
                              placeholder="Any industry">
                              <option value="">Please select</option>
                              {
                                IndustriesArray.filter(i => i.value !== undefined).map((industryOption) => (
                                  <option key={industryOption.key} value={industryOption.value}>{industryOption.label}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div className="buttons">
                      <div className="stack stack-m">
                        <div>
                          <div className="stack stack-xs">
                            <button className="button" onClick={(evt) => {
                              evt.preventDefault();
                              if (industry !== undefined) {
                                openTermsAndConditions();
                              } else {
                                setIsTouched(true);
                              }
                            }}>
                              <span className="button__inner">
                                <span>Register</span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div >
        </div >
        {showTermsAndConditions ? termsAndConditionsPopUp : null}
      </div >
    );
  };

export const LinkedInRegisterPage = withRouter(LinkedInRegisterComponent);