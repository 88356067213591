import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import { ResetPasswordModel } from './authentication.models';
import queryString from 'query-string';

export interface ResetPasswordComponentStateProps {
  error?: any;
  isLoading: boolean;
  email?: string;
}

export interface ResetPasswordComponentDispatchProps {
  loadResetPassword(code?: string): Promise<void>;
  resetPassword(model: ResetPasswordModel): Promise<void>;
}

const ResetPasswordComponent: React.FC<ResetPasswordComponentStateProps & ResetPasswordComponentDispatchProps & RouteComponentProps> = ({
  isLoading,
  location,
  history,
  loadResetPassword,
  error,
  email,
  resetPassword
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [localError, setLocalError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const queryParams = queryString.parse(location.search);
  const code = queryParams?.code as string;

  useEffect(() => {
    loadResetPassword(code);
  }, [loadResetPassword, code]);

  if (!code) {
    if (isLoading) {
      return (
        <div className="main">
          Error
        </div>
      );
    }
  }

  const errorProps = (textField?: string) => {
    return (!textField || textField === '' || textField.length < 6) && isTouched ? { error: "error" } : {};
  };

  const onSubmit = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsTouched(true);

    if (password === '' || passwordConfirm === '') {
      setLocalError('');
    } else if (password !== passwordConfirm) {
      setLocalError('Passwords does not match');
    } else {
      resetPassword({
        code: queryParams.code as string,
        email: email as string,
        password,
        passwordConfirm,
      });
    }

  };

  if (isLoading) {
    return (
      <div className="main">
        <div className="container">
          <FullPageLoadingComponent /></div>
      </div>
    );
  }

  return (
    <div className="section section--content">
      <div className="container">
        <div className="grid">
          <div className="gi-4 gi-s-5 gi-e-9 t-gi-4 t-gi-s-3 t-gi-e-7 m-gi-4 m-gi-s-1 m-gi-e-4">
            <div className="stack stack-m">
              <div className="user-form">
                <form className="stack stack-xl" onSubmit={(evt) => { onSubmit(evt); }}>
                  <div>
                    <h1 className="stack stack-xs ai-c">
                      <span className="logo logo--full logo--grey logo--color">
                        <svg className="logo__logo">
                          <use xlinkHref="#logo-full-grey-color" />
                        </svg>
                        <span className="visually-hidden">Gravity</span>
                      </span>
                      <span className="title title-m">Reset password</span>
                    </h1>
                  </div>
                  <fieldset>
                    <div className="stack stack-xs">
                      <div>
                        <p className="label">Email Address</p>
                        <div className="field field--input">
                          <input className="field__field" name="email" id="email" defaultValue={email} disabled={true} />
                        </div>
                      </div>
                      {
                        !error ?
                          (<>
                            <div>
                              <p className="label">New password (min. 6 characters)</p>
                              <div className="field field--input" {...errorProps(password)}>
                                <input className="field__field" name="password" id="password" value={password} type="password"
                                  placeholder="New password"
                                  onChange={(evt) => setPassword(evt.target.value)} />
                              </div>
                            </div>
                            <div>
                              <p className="label">New password confirm</p>
                              <div className="field field--input"  {...errorProps(passwordConfirm)}>
                                <input className="field__field" name="passwordconfirm" id="passwordconfirm" value={passwordConfirm} type="password"
                                  placeholder="New password confirm"
                                  onChange={(evt) => setPasswordConfirm(evt.target.value)} />
                              </div>
                            </div>
                          </>) : ''
                      }
                      {!error ? "" : <p {...{ style: { color: "red" } }}>{error}</p>}
                      {localError === "" ? "" : <p {...{ style: { color: "red" } }}>{localError}</p>}
                    </div>
                  </fieldset>
                  <div className="buttons">
                    <div className="stack stack-m">
                      <div>
                        <div className="stack stack-xs">
                          <button className="button">
                            <span className="button__inner">
                              <span>Send</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export const ResetPasswordPage = withRouter(ResetPasswordComponent);