import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { AppState } from "../root.reducer";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { isAccountLoadingSelector, accountSelector } from './account.selector';
import { accountService } from './account.service';
import { accountLoadedSuccessfulAction, accountSavedSuccessfulAction, accountLoadingAction } from './account.actions';
import { AccountSettingsStateProps, AccountSettingsDispatchProps, AccountSettingsPage } from './AccountSettingsPage.component';
import { SaveAccountModel } from './account.models';
import { registerCredits, userSelector } from '../auth/authentication.selectors';
import { authenticationSuccessAction, changeUseGravityAsAction, registerCreditsLoadingAction, registerCreditsSuccessAction, workspaceCreatedAction } from '../auth/authentication.actions';
import { workspaceInitializedSuccessfulAction, workspaceLoadedSuccessfulAction } from '../workspace-settings/workspace.actions';
import { BillingModel } from '../billing-information/billing.model';
import { billingDataSuppliedAction } from '../billing-information/billing.actions';
import { AppRoutes } from '../routes';
import { authenticationService } from '../auth/authentication.service';
import { countriesSelector } from '../meta/meta.selectors';
import { creditsService } from '../company-upload/credits.service';
import { workspaceService } from '../workspace-settings/workspace.service';

const mapStateToProps = (state: AppState): AccountSettingsStateProps => ({
  isLoading: isAccountLoadingSelector(state),
  account: accountSelector(state),
  user: userSelector(state),
  countries: countriesSelector(state),
  credits: registerCredits(state)
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): AccountSettingsDispatchProps => ({
  initWorkspace: () => {
    dispatch(workspaceInitializedSuccessfulAction());
  },

  changeUseGravityAs: async (redirectUrl: string, id?: number) => {
    document.querySelector('body')?.click();
    dispatch(changeUseGravityAsAction(id));

    if (id) {
      const workspace = await workspaceService.getWorkspaceSettings(id);
      dispatch(workspaceLoadedSuccessfulAction(workspace));
    } else {
      const user = await authenticationService.getUser();
      dispatch(authenticationSuccessAction(user));
    }

    history.push(redirectUrl);
  },

  loadRegisterCredits: async () => {
    dispatch(registerCreditsLoadingAction());

    const credits = await creditsService.getRegisterCredits();

    dispatch(registerCreditsSuccessAction(credits));
  },

  getProfile: async () => {
    dispatch(accountLoadingAction());
    const profile = await accountService.getAccountSettings();
    dispatch(accountLoadedSuccessfulAction(profile));
  },

  saveProfile: async (account: SaveAccountModel) => {
    dispatch(accountLoadingAction());
    const status = await accountService.saveAcount(account);

    if (status === 204) {
      const user = await authenticationService.getUser();
      dispatch(authenticationSuccessAction(user));

      const profile = await accountService.getAccountSettings();
      dispatch(accountLoadedSuccessfulAction(profile));
    }
  },

  respondWorkspaceInvite: async (workspaceId: number, response: boolean) => {
    dispatch(accountLoadingAction());

    const status = await accountService.respondWorkspaceInvite(workspaceId, response);

    if (status === 204) {
      dispatch(accountSavedSuccessfulAction());

      if (response) {
        dispatch(workspaceCreatedAction());
      }

      const user = await authenticationService.getUser();
      dispatch(authenticationSuccessAction(user));

      history.push(`/workspace/${workspaceId}`);
    }
  },

  uploadCredit: async (amount: number, creditValue: number) => {
    //dispatch(accountLoadingAction());

    const billing: BillingModel = {
      amount,
      creditValue
    };

    dispatch(billingDataSuppliedAction(billing));
    history.push(AppRoutes.BillingInformation)
  }
});

export default withRouter(
  connect<AccountSettingsStateProps, AccountSettingsDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps, mapDispatchToProps
  )(AccountSettingsPage)
);
