import { AppState } from '../root.reducer';
import { SearchFieldsComponent, SearchFieldsComponentDispatchProps, SearchFieldsComponentStateProps } from './SearchFields.component';
import { SimpleSearchFields } from './search.models';
import { Dispatch } from 'redux';
import { searchCompanyAction, searchSuccessAction } from './search.actions';
import { searchService } from './search.service';
import { countriesSelector } from '../meta/meta.selectors';
import { searchFieldsSelector } from './search.selectors';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state: AppState): SearchFieldsComponentStateProps => ({
    countries: countriesSelector(state),
    fields: searchFieldsSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): SearchFieldsComponentDispatchProps => ({
    searchCompanies: async (filters: SimpleSearchFields) => {
        dispatch(searchCompanyAction(filters));

        const resultsCount = await searchService.searchCompanies(filters);

        history.push('/search');
        dispatch(searchSuccessAction(resultsCount));
    }
});

export default withRouter(connect<SearchFieldsComponentStateProps, SearchFieldsComponentDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps,
    mapDispatchToProps
)(SearchFieldsComponent));
