import { AxiosInstance, AxiosResponse } from 'axios';
import { GetAccountQueryModel, SaveAccountModel } from './account.models';
import { axiosInstance } from '../axios';
import { CreditHistoryModel } from '../credit-history/credit-history.models';
import { CompanyListModel } from '../auth/authentication.models';
import { IpDetails } from '../billing-information/billing.model';

class AccountService {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axiosInstance;
  }

  async getAccountSettings(): Promise<GetAccountQueryModel> {
    const response = await this.axios({
      url: '/api/account',
      method: 'GET'
    });

    return response.data;
  }

  async saveAcount(account: SaveAccountModel): Promise<number> {
    const response = await this.axios({
      url: '/api/account/me',
      method: 'PUT',
      data: account,
    });

    return response.status;
  }

  async respondWorkspaceInvite(workspaceId: number, response: boolean): Promise<number> {
    const httpResponse = await this.axios({
      url: `/api/workspaces/${workspaceId}/invite-response?response=${response}`,
      method: 'POST',
    });

    return httpResponse.status;
  }  

  async invite(email: string): Promise<number> {
    const status = await this.axios({
      url: `/api/account/invite`,
      method: 'POST',
      data: {
        email: email
      }
    })
    .then(resp => {
      return resp.status;
    })
    .catch((err) => {
      const resp = err.response;
      return resp.status;
    });

    return status;
  }

  async uploadCredit(amount: number, creditValue: number): Promise<AxiosResponse<any>> {
    const response = await this.axios({
      url: `/api/account/upload-credit?amount=${amount}&creditValue=${creditValue}`,
      method: 'POST',
    });

    return response;
  }

  async deleteAccount(): Promise<AxiosResponse<any>> {
    const response = await this.axios({
      url: `/api/account`,
      method: 'DELETE',
    });

    return response;
  }

  async getCreditHistory(): Promise<CreditHistoryModel> {
    const response = await this.axios({
      url: `/api/account/credit-history`,
      method: 'GET',
    });

    return response.data;
  }

  async loadCompanies(name: string): Promise<CompanyListModel[]> {
    const response = await this.axios({
      method: 'GET',
      url: `/api/account/companies?name=${encodeURIComponent(name)}`
    });

    return response.data;
  }

  async checkIp(): Promise<IpDetails> {
    const response = await this.axios({
      method: 'GET',
      url: `/api/account/check-ip`
    });

    return response.data;
  }
}

export const accountService = new AccountService();