
import { Dispatch } from 'redux';
import { AppState } from '../root.reducer';
import { searchResultsTabsHeaderSelector, isSavedCompaniesLoadingSelector, savedCompaniesListSelector, isSavedSearchesLoadingSelector, savedSearchesListSelector, isUploadedCompaniesLoadingSelector, uploadedCompaniesListSelector, pageSelector } from './saved-companies.selectors';
import { SavedCompaniesPageStateProps, SavedCompaniesPageDispatchProps, SavedCompaniesPageComponent } from './SavedCompaniesPage.component';
import { connect } from 'react-redux';
import { savedCompaniesLoadingAction, savedCompaniesLoadingSuccessAction, savedCompaniesLoadingErrorAction, savedSearchesLoadingAction, savedSearchesLoadingSuccessAction, savedSearchesLoadingErrorAction, uploadedCompaniesLoadingSuccessAction, uploadedCompaniesLoadingAction, uploadedCompaniesLoadingErrorAction, pageNextAction, pagePreviousAction, resetCurrentPageAction, searchResultsTabsHeaderLoadedSuccessAction } from './saved-companies.actions';
import { companyService } from '../saved-company/company.service';
import { gravityUseAsSelector, userSelector } from '../auth/authentication.selectors';

const mapStateToProps = (state: AppState): SavedCompaniesPageStateProps => ({
    user: userSelector(state),

    headers: searchResultsTabsHeaderSelector(state),

    areSavedCompaniesLoading: isSavedCompaniesLoadingSelector(state),
    savedCompanyList: savedCompaniesListSelector(state),

    areUploadedCompaniesLoading: isUploadedCompaniesLoadingSelector(state),
    uploadedCompanyList: uploadedCompaniesListSelector(state),

    areSavedSearchesLoading: isSavedSearchesLoadingSelector(state),
    savedSearchesList: savedSearchesListSelector(state),
    
    page: pageSelector(state),

    useGravityAs: gravityUseAsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): SavedCompaniesPageDispatchProps => ({
    loadHeader: async (useGravityAs?: number) => {
        dispatch(savedCompaniesLoadingAction());
        try {
            if (useGravityAs) {
                const headers = await companyService.loadSearchResultsWorkspaceTabsHeader();
                dispatch(searchResultsTabsHeaderLoadedSuccessAction(headers));
            } else {
                const headers = await companyService.loadSearchResultsUserTabsHeader();
                dispatch(searchResultsTabsHeaderLoadedSuccessAction(headers));
            }
        } catch {
            dispatch(savedCompaniesLoadingErrorAction());
        }
    },

    loadSavedCompanies: async (page: number, useGravityAs?: number) => {
        dispatch(savedCompaniesLoadingAction());

        try {
            if (useGravityAs) {
                const savedCompanies = await companyService.loadSavedWorkspaceCompanies(page - 1);
                dispatch(savedCompaniesLoadingSuccessAction(savedCompanies));
            } else {
                const savedCompanies = await companyService.loadSavedUserCompanies(page - 1);
                dispatch(savedCompaniesLoadingSuccessAction(savedCompanies));
            }
        } catch {
            dispatch(savedCompaniesLoadingErrorAction());
        }
    },

    loadUploadedCompanies: async (page: number, useGravityAs?: number) => {
        dispatch(uploadedCompaniesLoadingAction());

        try {
            if (useGravityAs) {
                const savedCompanies = await companyService.loadUploadedWorkspaceCompanies(page - 1);
                dispatch(uploadedCompaniesLoadingSuccessAction(savedCompanies));
            } else {
                const savedCompanies = await companyService.loadUploadedUserCompanies(page - 1);
                dispatch(uploadedCompaniesLoadingSuccessAction(savedCompanies));
            }
        } catch {
            dispatch(uploadedCompaniesLoadingErrorAction());
        }
    },

    loadSavedSearches: async (useGravityAs?: number) => {
        dispatch(savedSearchesLoadingAction());
        
        try {
            if (useGravityAs) {
                const savedSearches = await companyService.loadSavedWorkspaceSearches();
                dispatch(savedSearchesLoadingSuccessAction(savedSearches));
            } else {
                const savedSearches = await companyService.loadSavedUserCompanySearches();
                dispatch(savedSearchesLoadingSuccessAction(savedSearches));
            }
        } catch {
            dispatch(savedSearchesLoadingErrorAction());
        }
    },

    nextPage: (maxPage: number) => {
        dispatch(pageNextAction(maxPage));
    },

    previousPage: () => {
        dispatch(pagePreviousAction());
    },

    resetCurrentPage: () => {
        dispatch(resetCurrentPageAction());
    }
});

export default connect<SavedCompaniesPageStateProps, SavedCompaniesPageDispatchProps, {}, AppState>(
    mapStateToProps,
    mapDispatchToProps
)(SavedCompaniesPageComponent);