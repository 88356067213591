import { AppState } from '../../root.reducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from "react-redux";
import { WorkspaceResponseComponent, WorkspaceResponseComponentDispatchProps, WorkspaceResponseComponentStateProps } from './WorkspaceResponseComponent';
import { workspaceInvitationsSelector, isWorkspaceLoadingSelector } from '../workspace.selector';
import { Dispatch } from 'redux';
import { workspaceService } from '../workspace.service';
import { workspaceInvitationsLoadedSuccessfulAction, workspaceLoadingAction, workspaceSavedSuccessfulAction } from '../workspace.actions';
import { accountService } from '../../account-settings/account.service';
import { authenticationSuccessAction, workspaceCreatedAction } from '../../auth/authentication.actions';
import { authenticationService } from '../../auth/authentication.service';

const mapStateToProps = (state: AppState): WorkspaceResponseComponentStateProps => ({
  isLoading: isWorkspaceLoadingSelector(state),
  workspaceInvitations: workspaceInvitationsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): WorkspaceResponseComponentDispatchProps => ({
  getInvitedWorkspaces: async () => {
    dispatch(workspaceLoadingAction());
    const response = await workspaceService.getInvitedWorkspaceSettings();

    if (response.status === 200) {
      dispatch(workspaceSavedSuccessfulAction());
      dispatch(workspaceInvitationsLoadedSuccessfulAction(response.data));
    }
  },

  respondWorkspaceInvite: async (workspaceId: number, response: boolean) => {
    dispatch(workspaceLoadingAction());

    const status = await accountService.respondWorkspaceInvite(workspaceId, response);

    if (status === 204) {
      dispatch(workspaceSavedSuccessfulAction());

      if (response) {
        dispatch(workspaceCreatedAction());
      }
      
      const user = await authenticationService.getUser();
      dispatch(authenticationSuccessAction(user));

      history.push('/');
    }
  }
});

export default withRouter(
  connect<WorkspaceResponseComponentStateProps, WorkspaceResponseComponentDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps, mapDispatchToProps
  )(WorkspaceResponseComponent)
);
