import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { AppState } from "../root.reducer";
import { authenticationService } from './authentication.service';
import { RegisterPageComponentDispatchProps, RegisterPage, RegisterPageComponentStateProps } from './RegisterPage.component';
import { RegisterModel } from './authentication.models';
import { authenticationLoadingAction, registerCreditsLoadingAction, registerCreditsSuccessAction, registerErrorAction, registerSuccessAction } from './authentication.actions';
import { isAuthenticatedSelector, isAuthenticationLoadingSelector, registerCredits } from './authentication.selectors';
import { RouteComponentProps } from 'react-router-dom';
import { countriesSelector } from '../meta/meta.selectors';
import { creditsService } from '../company-upload/credits.service';

const mapStateProps = (state: AppState): RegisterPageComponentStateProps => ({
    isLoading: isAuthenticationLoadingSelector(state),
    isAuthenticatied: isAuthenticatedSelector(state),
    countries: countriesSelector(state),
    credits: registerCredits(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history, }: RouteComponentProps): RegisterPageComponentDispatchProps => ({
    register: async (model: RegisterModel) => {
        dispatch(authenticationLoadingAction());

        const response = await authenticationService.register(model);

        if (response.status === 204) {
            dispatch(registerSuccessAction());
        } else if (response.status === 409) {
            dispatch(registerErrorAction());
        } else {
            dispatch(registerErrorAction());
        }

        return response.status;
    },
    loadRegisterCredits: async () => {
        dispatch(registerCreditsLoadingAction());

        const credits = await creditsService.getRegisterCredits();

        dispatch(registerCreditsSuccessAction(credits));
    }
});

export default connect<RegisterPageComponentStateProps, RegisterPageComponentDispatchProps, RouteComponentProps, AppState>(
    mapStateProps, mapDispatchToProps
)(RegisterPage);
