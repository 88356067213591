import { AxiosInstance } from 'axios';
import { Cart } from './cart.models';
import { axiosInstance } from '../axios';
import { AttributeType } from '../saved-company/saved-company.models';

interface BuyCompanyAttributes {
    summaryAttributes: {
        attributeId: number,
        creditPrice: number
    }[];
    productsAndServicesAttributes: {
        attributeId: number,
        creditPrice: number
    }[];
    managementContactAttributes: {
        attributeId: number,
        creditPrice: number
    }[];
    financialAttributes: {
        attributeId: number,
        creditPrice: number
    }[];
    attachmentAttributes: {
        attributeId: number,
        creditPrice: number
    }[];

    price?: number;
}

class CartService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    async submitCart(cart: Cart, savedCompanyId: number, useGravityAs?: number, price?: number): Promise<any> {
        const cartData: BuyCompanyAttributes = {
            summaryAttributes: cart.items
                .filter(a => a.attributeType === AttributeType.Summary)
                .map(c => ({
                    attributeId: c.attributeId,
                    creditPrice: c.price
                })),
            productsAndServicesAttributes: cart.items
                .filter(a => a.attributeType === AttributeType.ProductsAndServices)
                .map(c => ({
                    attributeId: c.attributeId,
                    creditPrice: c.price
                })),
            managementContactAttributes: cart.items
                .filter(a => a.attributeType === AttributeType.ManagementContact)
                .map(c => ({
                    attributeId: c.attributeId,
                    creditPrice: c.price
                })),
            financialAttributes: cart.items
                .filter(a => a.attributeType === AttributeType.Financial)
                .map(c => ({
                    attributeId: c.attributeId,
                    creditPrice: c.price
                })),
            attachmentAttributes: cart.items
                .filter(a => a.attributeType === AttributeType.Attachment)
                .map(c => ({
                    attributeId: c.attributeId,
                    creditPrice: c.price
                })),
            price: price
        };

        const url = useGravityAs ? 'workspace' : 'user';
        return await this.axios.post(`/api/companies/${url}/saved-companies/${savedCompanyId}`, cartData);
    }
}

export const cartService = new CartService();