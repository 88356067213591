import React, { useState } from "react";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { RevenueCategoriesArray, IndustriesArray } from '../models';
import { CountryModel } from '../meta/meta.models';
import { SimpleSearchFields } from '../search/search.models';
import { Utils } from '../utils/utils';

export interface HomePageSearchComponentStateProps {
    countries?: CountryModel[];
    companyCount?: number;
}

export interface HomePageSearchComponentDispatchProps {
    searchCompanies: (fields: SimpleSearchFields) => void;
}

export const HomePageSearch: React.FC<HomePageSearchComponentStateProps & HomePageSearchComponentDispatchProps & RouteComponentProps>
    = ({ countries, searchCompanies, companyCount, history }) => {
        const [industry, setIndustry] = useState(IndustriesArray[0].value);
        const [revenueCategory, setRevenueCategory] = useState(RevenueCategoriesArray[0].value);
        const [countryOfFoundationId, setCountry] = useState(countries ? countries[0].id : undefined);
        const [searchInAllAvailableData, setSearchInAllAvailableData] = useState(false);

        const search = () => {
            history.push('/search');

            searchCompanies({
                countryOfFoundationId,
                industry,
                revenueCategory,
                searchInAllAvailableData
            });
        };

        return (
            <form className="search-form" onSubmit={() => search()}>
                <div className="stack stack-xs">
                    <div>
                        <h2 className="title title-m">Search for Companies</h2>
                        {companyCount ?
                            <p>Companies already in Gravity: {Utils.formatNumber(companyCount)}</p> : ""
                        }
                    </div>
                    <div className="h-stack h-stack-xs t-fd-c">
                        <div className="fill">
                            <p>Industry</p>
                            <div className="field field--select ">
                                <select className="field__field" placeholder=""
                                    value={industry} onChange={(evt) => setIndustry(Number(evt.target.value))}
                                >
                                    {
                                        IndustriesArray.map((industryOption) => (
                                            <option key={industryOption.key} value={industryOption.value}>{industryOption.label}</option>
                                        ))
                                    }
                                </select>
                                <div className="field__icon field__icon--right">
                                    <svg className="icon icon--m ">
                                        <use xlinkHref="#icon-arrow-down" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="fill">
                            <p>Total Net Revenues</p>
                            <div className="field field--select ">
                                <select className="field__field" placeholder=""
                                    value={revenueCategory} onChange={(evt) => setRevenueCategory(Number(evt.target.value))}
                                >
                                    {
                                        RevenueCategoriesArray.map((revenueOption) => (
                                            <option key={revenueOption.key} value={revenueOption.value}>{revenueOption.label}</option>
                                        ))
                                    }
                                </select>
                                <div className="field__icon field__icon--right">
                                    <svg className="icon icon--m ">
                                        <use xlinkHref="#icon-arrow-down" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="fill">
                            <p>Country</p>
                            <div className="field field--select ">
                                {
                                    countries ? (
                                        <select className="field__field" placeholder=""
                                            value={countryOfFoundationId} onChange={(evt) => setCountry(Number(evt.target.value))}
                                        >
                                            {
                                                countries.map((countryOption, index) => (
                                                    <option key={index} value={countryOption.id}>{countryOption.name}</option>
                                                ))
                                            }
                                        </select>) : null
                                }

                                <div className="field__icon field__icon--right">
                                    <svg className="icon icon--m ">
                                        <use xlinkHref="#icon-arrow-down" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-stack h-stack-xs t-fd-c">
                        <div>
                            <div className="label fw-b">Search in:</div>
                        </div>
                        <div className="search-form__filter-toggles h-stack h-stack-xs ">
                            <div>
                                <label className="switch switch--toggle switch--label-multiple" style={{width: 'max-content'}}>
                                    <span className="switch__left_label label">Latest Modifications</span>
                                    <input className="visually-hidden"
                                        checked={searchInAllAvailableData}
                                        onChange={evt => setSearchInAllAvailableData(evt.target.checked)}
                                        type="checkbox"
                                    />
                                    <span className="switch__switch" onTouchEnd={(evt) => { setSearchInAllAvailableData(!searchInAllAvailableData) }}>
                                        <span className="dot" />
                                    </span>
                                    <span className="switch__right_label label">All Available Data</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <button className="button">
                        <span className="button__inner">
                            <span>Search</span>
                        </span>
                    </button>
                </div>
            </form>
        );
    };

export const HomePageSearchComponent = withRouter(HomePageSearch);