import {AxiosInstance, AxiosResponse} from 'axios';
import {
    SavedCompaniesListModel,
    SavedCompanySearch,
    SearchResultsTabsHeader
} from '../saved-companies/saved-companies.models';
import {CreateCompanyCommand} from '../company-upload/company-upload.models';
import {axiosInstance} from '../axios';

type ExistsCompanyParams = {
    companyName: string, countryOfFoundationId: number, identificationNumber?: string,
    mainAddressZip?: string, mainAddressCity?: string, mainAddressStreet?: string, businessAddressZip?: string,
    businessAddressCity?: string, businessAddressStreet?: string, website?: string, industry?: number, sector?: number,
    foundationYear?: number
};

class CompanyService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    async getCurrencies(): Promise<string[]> {
        const respones = await this.axios({
            url: `/api/companies/currencies`,
            method: 'GET'
        });

        return respones.data;
    }

    async loadSearchResultsUserTabsHeader(): Promise<SearchResultsTabsHeader> {
        const response = await this.axios({
            url: `/api/companies/user/search-results-tabs-header`,
            method: 'GET',
        });

        return response.data;
    }

    async loadSearchResultsWorkspaceTabsHeader(): Promise<SearchResultsTabsHeader> {
        const response = await this.axios({
            url: `/api/companies/workspace/search-results-tabs-header`,
            method: 'GET',
        });

        return response.data;
    }

    async loadSavedUserCompanies(page: number): Promise<SavedCompaniesListModel> {
        const response = await this.axios({
            url: `/api/companies/user/saved-companies?page=${page}`,
            method: 'GET',
        });

        return response.data;
    }

    async loadSavedWorkspaceCompanies(page: number): Promise<SavedCompaniesListModel> {
        const response = await this.axios({
            url: `/api/companies/workspace/saved-companies?page=${page}`,
            method: 'GET',
        });

        return response.data;
    }

    async loadUploadedUserCompanies(page: number): Promise<SavedCompaniesListModel> {
        const response = await this.axios({
            url: `/api/companies/user/uploaded-companies?page=${page}`,
            method: 'GET',
        });

        return response.data;
    }

    async loadUploadedWorkspaceCompanies(page: number): Promise<SavedCompaniesListModel> {
        const response = await this.axios({
            url: `/api/companies/workspace/uploaded-companies?page=${page}`,
            method: 'GET',
        });

        return response.data;
    }

    async loadSavedUserCompanySearches(): Promise<SavedCompanySearch[]> {
        const response = await this.axios({
            url: `/api/companies/user/saved-searches`,
            method: 'GET',
        });

        return response.data.list;
    }

    async loadSavedWorkspaceSearches(): Promise<SavedCompanySearch[]> {
        const response = await this.axios({
            url: `/api/companies/workspace/saved-searches`,
            method: 'GET',
        });

        return response.data.list;
    }

    async getSavedUserCompany(id: number): Promise<AxiosResponse> {
        const response = await this.axios({
            url: `/api/companies/user/saved-companies/${id}`,
            method: 'GET',
        }).catch(err => err.response);

        return response;
    }

    async getSavedWorkspaceCompany(id: number): Promise<AxiosResponse> {
        const response = await this.axios({
            url: `/api/companies/workspace/saved-companies/${id}`,
            method: 'GET',
        }).catch(err => err.response);

        return response;
    }

    async createUserCompany(company: CreateCompanyCommand): Promise<AxiosResponse> {
        const response = await this.axios({
            url: `/api/companies/user`,
            method: 'POST',
            data: company,
        });

        return response;
    }

    async createWorkspaceCompany(company: CreateCompanyCommand): Promise<AxiosResponse> {
        const response = await this.axios({
            url: `/api/companies/workspace`,
            method: 'POST',
            data: company,
        });

        return response;
    }

    async updateUserCompany(company: any): Promise<void> {
        await this.axios({
            url: `/api/companies/user`,
            method: 'PUT',
            data: company
        });
    }

    async updateWorkspaceCompany(company: any): Promise<void> {
        await this.axios({
            url: `/api/companies/workspace`,
            method: 'PUT',
            data: company
        });
    }

    async existsCompany(query: ExistsCompanyParams
    ): Promise<AxiosResponse<number>> {
        Object.keys(query).forEach(key => query[key as keyof ExistsCompanyParams] === undefined
            && delete query[key as keyof ExistsCompanyParams]);
        const params = new URLSearchParams(query as unknown as URLSearchParams);
        return await this.axios({
            url: `/api/companies/exists?${params.toString()}`,
            method: 'GET'
        });
    }

    async getUserSavedAttributeSource(savedCompanyId: number, attributeId: number): Promise<any> {
        return await this.axios({
            url: `/api/companies/user/saved-companies/${savedCompanyId}/source/${attributeId}`,
            method: 'GET'
        });
    }

    async getWorkspaceSavedAttributeSource(savedCompanyId: number, attributeId: number): Promise<any> {
        return await this.axios({
            url: `/api/companies/workspace/saved-companies/${savedCompanyId}/source/${attributeId}`,
            method: 'GET'
        });
    }

    async getDiscount(): Promise<number> {
        const response = await this.axios({
            url: `/api/companies/discount`,
            method: 'GET'
        });

        if (response.status === 200) {
            return response.data as number;
        }

        return 0;
    }

    async getUserAttributeSource(attributeId: number): Promise<string> {
        const response = await this.axios({
            url: `/api/companies/user/source/${attributeId}`,
            method: 'GET'
        });

        if (response.status === 200) {
            return response.data as string;
        }

        return "";
    }

    async getWorkspaceAttributeSource(attributeId: number): Promise<string> {
        const response = await this.axios({
            url: `/api/companies/workspace/source/${attributeId}`,
            method: 'GET'
        });

        if (response.status === 200) {
            return response.data as string;
        }

        return "";
    }

    async exportSavedCompany(savedCompanyId: number): Promise<void> {
        const response = await this.axios({
            url: `/api/companies/saved-companies/${savedCompanyId}/export`,
            method: 'POST',
            responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Gravity_export.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
}

export const companyService = new CompanyService();
