import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import { ManagementContactActivationModel } from './models';
import queryString from 'query-string';

export interface ManagementContactActiationComponentStateProps {
  isLoading: boolean;
  activation: ManagementContactActivationModel | undefined;
}

export interface ManagementContactActiationComponentDispatchProps {
  getActivation: (code: string) => Promise<void>;
  response: (code: string, accept: boolean) => Promise<void>;
}

const ManagementContactActiationComp: React.FC<ManagementContactActiationComponentStateProps & ManagementContactActiationComponentDispatchProps & RouteComponentProps> = ({
  isLoading,
  history,
  activation: managementContact,
  getActivation,
  response
}) => {
  const code = queryString.parse(window.location.search)?.code?.toString();
  if (!code) {
    history.push('/');
  }
  
  useEffect(() => {
    if (code) {
      getActivation(code);
    }
  }, [getActivation, code]);

  if (isLoading || !managementContact) {
    return <FullPageLoadingComponent />;
  }

  const onResponse = async (evt: any, accept: boolean) => {
    evt.preventDefault();
    evt.stopPropagation();

    response(code!, accept);
  };

  const activation =
    <ul className="list">
      <li key={managementContact.code}>
        <div className="item item--usr company-invite">
          <div className="h-stack h-stack-xs ai c">
            <div className="col col--name">
              <p className="annotation">Company name: <span className="bold">{managementContact.companyName}</span></p>
              <p className="annotation">Person name: <span className="bold">{managementContact.personName}</span></p>
              <p className="annotation">Postition: <span className="bold">{managementContact.position}</span></p>
              {managementContact.emailAddress ? <p className="annotation">Email Address: <span className="bold">{managementContact.emailAddress}</span></p> : null}
              {managementContact.location ? <p className="annotation">Location: <span className="bold">{managementContact.location}</span></p> : null}
              {managementContact.functions ? <p className="annotation">Functions: <span className="bold">{managementContact.functions}</span></p> : null}
              {managementContact.telephoneNumber ? <p className="annotation">Telephone Number: <span className="bold">{managementContact.telephoneNumber}</span></p> : null}
              {managementContact.linkedIn ? <p className="annotation">LinkedIn: <span className="bold">{managementContact.linkedIn}</span></p> : null}
            </div>
            <div className="col col--icon vertical">
              <button className="button" onClick={(evt) => onResponse(evt, true)}>
                <span className="button__inner">
                  <span>Accept</span>
                </span>
              </button>
              <button className="button button--terminate" onClick={(evt) => onResponse(evt, false)}>
                <span className="button__inner">
                  <span>Discard</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>;

  return (
    <div className="section section--content">
      <div className="container">
        <div className="grid">
          <div className="gi-4 gi-s-5 gi-e-9 t-gi-4 t-gi-s-3 t-gi-e-7 m-gi-4 m-gi-s-1 m-gi-e-4">
            <div className="stack stack-m">
              <div className="user-form">
                <form className="stack stack-xl">
                  <div>
                    <h1 className="stack stack-xs">
                      <span className="title title-m">Company Management Contact Activation</span>
                    </h1>
                  </div>
                  <fieldset>
                    <div className="stack stack-xs">
                      <p>You have activation: to the following company management contact:</p>
                      <div>
                        {activation}
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export const ManagementContactActivationPage = withRouter(ManagementContactActiationComp);

