import React from "react";
import { HashLink } from 'react-router-hash-link';
import { Link, RouteComponentProps } from 'react-router-dom';
import './faq.scss'
import { useSelector } from 'react-redux';

export const FrequentlyAskedQuestionsComponent: React.FC<RouteComponentProps> = ({
    history
}) => {
    const useGravityAs = useSelector(state => (state as any).authentication.useGravityAs);

    let account = '';
    if (useGravityAs) {
        account = '/workspace/' + useGravityAs;
    } else {
        account = '/account';
    }

    return (
        <>
            <div className="section section--header">
                <div className="container">
                    <div className="stack stack-m">
                        <div className="grid">
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="h-stack h-stack-xs jc-sb m-fd-c">
                                    <div>
                                        <h1 className="title title-l">Frequently Asked Questions</h1>
                                        <p className="title fw-sb">Learn more in details about Gravity.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="section section--content faq">
                <div className="container">
                    <div className="stack stack-xl">
                        <div className="grid">
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="stack stack-s">
                                    <h2 className="title title-m">Registration</h2>
                                </div>
                            </div>
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="stack stack-s">
                                    <ul className="faq-list">
                                        <li>How do I create a Gravity account?</li>
                                        <li className="level-1">
                                            <ul>
                                                <li>
                                                    Click on <Link to="/register">Register</Link> and follow the steps in the Register section of How it Works: <HashLink to="https://localhost/how-it-works#register-anchor">howitworks/register</HashLink>.
                                                    If you or someone is already logged in to Gravity on your device, you have to log out prior to registering a different user.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>How do I log into my account?</li>
                                        <li className="level-1">
                                            <ul>
                                                <li>
                                                    When you are not logged in, click on the <Link to="/login">Log In</Link> button in the upper right corner of any page on Gravity.
                                                    Enter your email address and password.
                                                    If you are already logged in, you have to log out before you can register or log in a different user.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>How do I modify my account?</li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Roll down the arrow in your profile box located in the upper right corner of the landing page,</li>
                                                <li>Your profile page will come up on the screen,</li>
                                                <li>Click on <Link to={account}>Account Settings</Link>,</li>
                                                <li>Modify any field in your profile,</li>
                                                <li>Click on Save Account Details.</li>
                                            </ul>
                                        </li>
                                        <li>I have difficulties logging in to Gravity.</li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Click on <Link to="contact-us">Contact Us</Link> from the menu located at the top of the landing page,</li>
                                                <li>Choose Notifications and Errors,</li>
                                                <li>Send an email to the address provided,</li>
                                                <li>Gravity will locate the problem and send a response to your email address registered with Gravity.</li>
                                            </ul>
                                        </li>
                                        <li>How do I change or reset my Gravity password?</li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Roll down the arrow in your profile box located in the upper right corner of the landing page,</li>
                                                <li>Your profile page will come up,</li>
                                                <li>Click on <Link to={account}>Account Settings</Link>,</li>
                                                <li>Go to Security Settings,</li>
                                                <li>Change your password,</li>
                                                <li>Make sure your new password contains at least 8 characters including one upper case letter, a number and a special symbol,</li>
                                                <li>Click on Save Account Details.</li>
                                            </ul>
                                        </li>
                                        <li>What characters do I need to use in my password?</li>
                                        <li className="level-1">
                                            <ul>
                                                <li>
                                                    All Gravity passwords must contain at least 8 characters including one upper case letter, a number and a special symbol.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>How do I delete my Gravity account?</li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Roll down the arrow in your profile box located in the upper right corner of the landing page,</li>
                                                <li>Your profile will come up on the screen,</li>
                                                <li>Click on <Link to={account}>Account Settings</Link>,</li>
                                                <li>Click on Delete Account at the bottom of the window.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="stack stack-s">
                                    <h2 className="title title-m">Credits</h2>
                                </div>
                            </div>
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="stack stack-s">
                                    <ul className="faq-list">
                                        <li>
                                            How can I earn free credits?<br /><br />
                                            You will earn Gravity credits for the following:
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li><Link to="upload-company">Uploading a company</Link> not yet on Gravity,</li>
                                                <li>Modifying, completing or updating information on a company existing on Gravity,</li>
                                                <li><Link to={account}>Inviting other users</Link> registering to Gravity,</li>
                                                <li>Receive free credits upon joining or filling out optional fields on your registration form,</li>
                                                <li>Other bonus credits from time to time during Gravity marketing campaigns.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Is there limit to the number of free credits I can receive?
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>
                                                    There is no limit to the number of free credits you can receive.  The more you upload, and the more people you invite, the more credits you will earn.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have not received my credits after uploading.
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Click on <Link to="contact-us">Contact Us</Link> from the menu located at the top of the landing page,</li>
                                                <li>Choose Credit,</li>
                                                <li>Send us an email to the address provided,</li>
                                                <li>Gravity will locate the problem and respond to your email registered with Gravity.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            How do I purchase credits if I run out of my earned and bonus credits?
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Roll down the arrow in your profile box located in the upper right corner of the landing page,</li>
                                                <li>Your profile page will come up on the screen,</li>
                                                <li>Click on <a href={account}>Earn or Buy Credits</a>,</li>
                                                <li>Fill in the number of Credits you want to purchase.  The minimum is 500 credits costing a pre-tax amount of 5 Euros, approximately US$6,</li>
                                                <li>Click on the green button starting the payment process,</li>
                                                <li>Select the purchaser on the Billing Information Sheet to tell Gravity if you or your company should be the one that is being billed,</li>
                                                <li>Based on your selection, a second page will come up. If you selected Company, you will have to provide your name, company name, company address and the company’s European Tax Identification Number if it has one.
                                                    Your applicable Value Added Tax if any will be added to your purchase and the total amount will be shown at the bottom. If you select to make the purchase as an individual, you have to fill out a form similar to the company billing form.</li>
                                                <li>Click on Continue to Payment,</li>
                                                <li>You will be redirected to PayPal, the sole payment processor of Gravity, also processing credit and debit card payments. PayPal is using a secure system specially developed for payment processing,</li>
                                                <li>Select the method of payment, PayPal or debit/credit card,</li>
                                                <li>If PayPal is selected, enter your login details or register as a new PayPal user,</li>
                                                <li>When selecting debit/credit card, fill in your card details, including the card billing address, telephone number and the three-digit security code. As your card is processed by PayPal, your card details will not be seen by Gravity.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Do I have to pay Value Added Taxes (VAT)?
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>
                                                    As a general rule, online data purchases are taxed in the country where the User is located.
                                                    Gravity will automatically calculate the VAT applicable in your country. <br />
                                                    No VAT is charged when the purchaser is tax resident outside the European Economic Area, or those entering a valid European VAT number.
                                                    For more information on invoicing rules in the European Union visit:<br />
                                                    <a href="https://ec.europa.eu/taxation_customs/business/vat/eu-vat-rules-topic/vat-invoicing-rules_en" target="_blank" rel="noopener noreferrer">
                                                        https://ec.europa.eu/taxation_customs/business/vat/eu-vat-rules-topic/vat-invoicing-rules_en
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="stack stack-s">
                                    <h2 className="title title-m">Downloading</h2>
                                </div>
                            </div>
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="stack stack-s">
                                    <ul className="faq-list">
                                        <li>
                                            I am having trouble downloading.
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Click on <Link to="contact-us">Contact Us</Link> in the menu located at the top of the landing page,</li>
                                                <li>Choose Upload and Download,</li>
                                                <li>Send us an email to the address provided,</li>
                                                <li>Gravity will locate the problem and send a response to your email address with Gravity.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            My download stopped or interrupted after payment.
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>
                                                    There are two usual reasons for the interruption:
                                                    <ul className="innerList">
                                                        <li className="filled">The selected piece of information was updated while you were making the purchase,</li>
                                                        <li className="filled">Your internet access was temporarily disrupted.</li>
                                                    </ul>
                                                    In both cases, you will have to restart your purchase.
                                                    If the internet you are using seems unstable, it is recommended to first purchase a sufficient number of credits to your <Link to={account}>Gravity account</Link>,
                                                    and then start purchasing the information from your available balance.
                                                    This will reduce the risk of interruption and enables you to view and download company information instantly.<br />
                                                    If you continue having this problem, contact Gravity using the <Link to="contact-us">Contact Us</Link> page. Gravity will locate the problem and send a response to your email address registered with Gravity.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            How can I save downloads to my device?
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Click on the <Link to="saved-companies">Search Results</Link> button in the menu located at the top of the landing page,</li>
                                                <li>Click on <Link to="saved-companies">Saved Companies</Link>,</li>
                                                <li>Click on the name of the company you would like to export,</li>
                                                <li>Click on the Export button on the right-hand side,</li>
                                                <li>The company will be downloaded onto your device in CSV format, which you can convert to an easy-to-use Excel format.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            How often is the online database updated?
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>
                                                    Gravity is a crowdsourced database.
                                                    Users continuously update companies to earn free credits.
                                                    This assures that the database is updated and expanding even on weekends and night-time.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            What sources does the information come from?
                                        </li>
                                        <li className="level-1">
                                            <div>Alternative sources:</div>
                                            <br />
                                            <ul >
                                                <li >Publicly available information: Obtained from government registries, company websites or open databases,</li>
                                                <li>Employee or officer of the company: Data is uploaded by an employee or officer of the company who provided proof of being an employee or an officer,</li>
                                                <li>Independent user: A user of Gravity who has no employment or other legal relationship to the company.</li>
                                            </ul>
                                            <div>Prior to purchasing a piece of data, you can put the cursor on <img src={`/assets/img/source-up.png`} alt="Source" style={{ width: '20px' }} /> and you will see the source of the last uploaded value. This will help you make a decision whether it is worth for you to spend credits on this piece of information.
                                            </div>
                                            <br />
                                            <div>
                                                Once you allocated credits for the data, Gravity will show you up to seven previous uploads and modifications for the value of each filed. Values shown if available:
                                            </div>
                                            <br />
                                            <div style={{ paddingLeft: '15px' }}>
                                                <div>1. Originally uploaded entry,</div>
                                                <div>2. Last uploaded publicly available information,</div>
                                                <div>3. Last value uploaded by an employee or officer of the company,</div>
                                                <div>4. Latest modification,</div>
                                                <div>5. The latest modifications other than the four above increasing the number of values to seven.</div>
                                            </div>
                                        </li>
                                        <li>
                                            What exchange rates does Gravity use?
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>
                                                    Gravity uses the year-end middle rates for the year the uploaded financial statement is covering.
                                                    In case the financial year for a company is different from the calendar year,
                                                    Gravity will use the year-end middle exchange rate at the end of the calendar year the company’s financial year ended.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="stack stack-s">
                                    <h2 className="title title-m">Uploads</h2>
                                </div>
                            </div>
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="stack stack-s">
                                    <ul className="faq-list">
                                        <li>
                                            How can I bulk upload companies?
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Select <a href="/upload-company">Upload a Company</a> from the menu at the top of the landing page,</li>
                                                <li>Below the Upload Button, click on <a href="mailto:techsupport@gravity.surf">HERE</a> to upload more than 10 companies in a batch,</li>
                                                <li>You will be navigated to the Mail To page,</li>
                                                <li>Gravity will email you a blank upload template, which you can fill out and send back,</li>
                                                <li>If for some reason, you are unable to use the template, you may also send the available information by email to Gravity, and one of our associates will figure out the most efficient way to upload the companies,</li>
                                                <li>If bulk uploads are made by Gravity associates, you will still receive the same number of credits as if you uploaded the companies one-by-one,</li>
                                                <li>Gravity will send you an email notifying about the number of credits you received,</li>
                                                <li>If some of the companies you want to upload in bulk are already on Gravity (previously uploaded companies), you will receive an email listing the companies that were considered to be uploaded by you, and for which you received credits,</li>
                                                <li>If you find that the previously uploaded companies have outdated information, you may individually modify those and receive credits for the modifications separately from your bulk uploads,</li>
                                                <li>Please note that issuing credits for bulk uploads might take a few days depending on the amount, format and quality of the uploaded data and the overall demand for bulk uploads.</li>
                                                <li>Gravity cannot guarantee that the file format you are using is compatible. As incompatible file formats cannot be uploaded to Gravity, you may be asked to convert it to another file format, or try to upload the information manually.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            How many companies constitute bulk uploads?
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>If you are uploading 11 companies or more, you already have the option for bulk upload. However, independently from the number of companies, you always have the option of uploading them one-by-one.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            How does Gravity proceed with the publication of data uploaded by users?
                                        </li>
                                        <li className="level-1">
                                            <div>
                                                Company information will be automatically published upon uploading.
                                                The only exception is personal information generally found in Management Contacts, which usually requires the consent of the affected individual prior to publishing.
                                                To obtain consent, Gravity will send an e-mail or text message to the affected individuals to receive authorization for publishing their contact details,
                                                as required by General Data Protection Regulation (GDPR) of the European Union.
                                                Such personal contact information will only be published if:
                                            </div>
                                            <br />
                                            <ul>
                                                <li>The person consents to his or her contact details being published,</li>
                                                <li>Does not respond to the email or text message for a week. Then, the information is temporarily published until a request is received for the removal of the contact information,</li>
                                                <li>If no response is received to the first email or text message, Gravity will send new emails or text messages at least on a monthly bases to seek subsequent approval.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            What entries are published on Gravity?
                                        </li>
                                        <li className="level-1">
                                            <div>
                                                In the event data is modified, some of the previous values or entries will remain published on the system for the same field. The following values will be shown if available for each piece of information:
                                            </div>
                                            <br />
                                            <ul className="decimal">
                                                <li>Originally uploaded entry,</li>
                                                <li>Last uploaded publicly available information,</li>
                                                <li>Last value uploaded by an employee or officer of the company,</li>
                                                <li>Latest modification,</li>
                                                <li>The latest modified values other than the four above increasing the number of values to seven.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="stack stack-s">
                                    <h2 className="title title-m">Contact</h2>
                                </div>
                            </div>
                            <div className="gi-12 t-gi-8 m-gi-4">
                                <div className="stack stack-s">
                                    <ul className="faq-list">
                                        <li>
                                            How can I contact Gravity?
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Click on <Link to="contact-us">Contact Us</Link> in the menu located at the top of the landing page,</li>
                                                <li>Choose the category that describes your issue the most,</li>
                                                <li>Send us an email to the address provided,</li>
                                                <li>Gravity will respond to your email address registered with Gravity.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            How can I report errors or bugs?
                                        </li>
                                        <li className="level-1">
                                            <ul>
                                                <li>Click on <Link to="contact-us">Contact Us</Link> in the menu located at the top of the landing page,</li>
                                                <li>Choose Notifications and Errors,</li>
                                                <li>Send us an email to the address provided,</li>
                                                <li>Gravity will locate the problem and send a response to your email registered with Gravity.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Can I send Gravity suggestions for improving the service or the database?
                                        </li>
                                        <li className="level-1">
                                            <div>
                                                Gravity is always on the lookout for ways to serve you better.  Your suggestions are definitely appreciated.
                                            </div>
                                            <br />
                                            <ul>
                                                <li>Click on <Link to="contact-us">Contact Us</Link> in the menu located at the top of the landing page,</li>
                                                <li>Choose Suggestions,</li>
                                                <li>Send us an email to the address provided,</li>
                                                <li>Gravity will send you a confirmation.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};