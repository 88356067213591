import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CreditHistoryModel } from './credit-history.models';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import React from 'react';
import { Utils } from '../utils/utils';

export interface CreditHistoryStateProps {
  isLoading: boolean;
  creditHistory: CreditHistoryModel | undefined;
  useGravityAs?: number;
}

export interface CreditHistoryDispatchProps {
  getHistory(useGravityAs?: number): Promise<void>;
}

const CreditHistoryPageComponent: React.FC<CreditHistoryStateProps & CreditHistoryDispatchProps & RouteComponentProps> =
  ({ isLoading, creditHistory, useGravityAs, getHistory, history }) => {
    useEffect(() => {
      getHistory(useGravityAs);
    }, [getHistory, useGravityAs]);


    if (isLoading || creditHistory === undefined) {
      return <FullPageLoadingComponent />;
    }

    const list = creditHistory.historyList.map((history, i) => (
      <div className="grid credit-history" key={i}>
        <div className="gi-3">
          <span>{history.date}</span>
        </div>
        <div className="gi-3">
          <span>{history.activity}</span>
        </div>
        {
          useGravityAs ?
            <div className="gi-3">
              <span>{history.userName}</span>
            </div> : null
        }
        <div className="gi-3">
          <span>{Utils.formatNumber(history.creditAmount)}</span>
        </div>
      </div>
    ));


    const onClose = (evt: any) => {
      evt.preventDefault();
      evt.stopPropagation();
      if (useGravityAs) {
        history.push('/workspace/' + useGravityAs);
      } else {
        history.push('/account');
      }
    };

    return (
      <div className="container">
        <div className="stack stack-m">
          <div className="grid">
            <div className="gi-12">
              <div className="h-stack h-stack-xs jc-sb m-fd-cr">
                <h1 className="title title-l">Credit History</h1>
                <button className="icon-button" onClick={(evt) => onClose(evt)}>
                  <span className="icon-button__inner">
                    <svg className="icon icon--m ">
                      <use xlinkHref="#icon-close" />
                    </svg>
                    <span className="label">Close Credit History</span>
                  </span>
                </button>
              </div>
              <div className="h-stack">
                Currently available balance
              </div>
              <div className="h-stack ai-fe">
                <span>
                  <svg className="icon icon--m ">
                    <use xlinkHref="#icon-gravity-credit" />
                  </svg>
                </span>
                <span>{Utils.formatNumber(creditHistory.currentlyAvailableBalance)}</span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="stack stack-m">
          <div className="grid">
            <div className="gi-3">
              <span className="fw-b">Date</span>
            </div>
            <div className="gi-3">
              <span className="fw-b">Activity</span>
            </div>
            {
              useGravityAs ?
                <div className="gi-3">
                  <span className="fw-b">Modifier User</span>
                </div> : null
            }
            <div className="gi-3">
              <span className="fw-b">Credit Amount</span>
            </div>
          </div>
          {list}
        </div>
      </div>
    );

  };

export const CreditHistoryPage = withRouter(CreditHistoryPageComponent);