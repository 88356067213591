import React, { useState } from "react";
import "./Tooltip.scss";

export const Tooltip: React.FC<any> = (props) => {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
      if (props.onShow) {
        props.onShow();
      }
    }, props.delay || 100);
  };

  const hideTip = () => {
    clearTimeout(timeout);
    setActive(false);
    if (props.onHide) {
      props.onHide();
    }
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {active && (
        <div className={`Tooltip-Tip ${props.direction || "top"} ${props.hasSavedAttribute ? "" : "small"} ${props.isLoading ? "isLoading" : ""}`} >
          <div
            className="close-icon"
            onClick={(evt) => {
              evt.stopPropagation();
              hideTip();
            }}
          >
            <svg className="icon icon--s">
              <use xlinkHref="#icon-close" />
            </svg>
          </div>
          {props.content}
        </div>
      )}
    </div>
  );
};
