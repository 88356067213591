import React, { useState } from 'react';
import { PropertyWithPrice, TouchedFinancialsModel } from '../company-upload.models';
import { CompanyUploadModel } from '../CompanyUpload.model';
import { FinancialDetailsComponent } from './FinancialDetails.component';

export interface FinancialDetailsCompanyUploadTabProps {
  isEditMode?: boolean;
  originalValue?: CompanyUploadModel['financials'];
  financialDetails: CompanyUploadModel['financials'];
  touchedInputs: TouchedFinancialsModel;
  currencies: string[];
  updateCurrency: (currency: string, multiplier: number) => void;
  updateBalanceSheet: (key: keyof CompanyUploadModel['financials'][0]['balanceSheet'], index: number, value: any) => void;
  updateIncomeStatement: (key: keyof CompanyUploadModel['financials'][0]['incomeStatement'], index: number, value: any) => void;
}

export const FinancialDetailsCompanyUploadTabComponent: React.FC<FinancialDetailsCompanyUploadTabProps> = ({
  isEditMode,
  originalValue,
  touchedInputs,
  financialDetails,
  currencies,
  updateCurrency,
  updateBalanceSheet,
  updateIncomeStatement
}) => {
  const [openInfoIndex, setOpenInfoIndex] = useState(-1);
  const [currency, setCurrency] = useState('');
  const [multiplier, setMultiplier] = useState(1);

  const onMultiplierChange = (evt: any, value: number) => {
    setMultiplier(value);
    updateCurrency(currency, value);
    if (isEditMode) {
      updateCreditsOnMultiplerChange();
    }
  }

  const updateCreditsOnMultiplerChange = () => {

  }

  return (
    <div className="stack stack-m">
      <div className="upload-form__section">
        <div className="stack stack-m">
          <span className="icon-header icon-header--header-right">
            <span className="icon-header__inner">
              <svg className="icon icon--l ">
                <use xlinkHref="#icon-edit" />
              </svg>
              <span className="title title-s">Financials</span>
            </span>
          </span>
          <div className="currency">
            <div className="row">
              <div className="col first">
                Currency
              </div>
              <div className="col field field--input">
                <select className="field__field"
                  value={currency}
                  onChange={(evt) => {
                    const currency = evt.target.value;
                    setCurrency(currency);
                    updateCurrency(currency, multiplier);
                  }}
                  name="currency"
                  disabled={isEditMode}
                  id="currency"
                  placeholder="Currency">
                  <option value="EUR">EUR</option>
                  {
                    currencies.map((currency) => (
                      <option key={currency} value={currency}>{currency}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            {!isEditMode ? <div className="row">
              <div className="col first"></div>
              <div className="col multiplier">
                <label className="switch switch--checkbox switch--label-left">
                  <input className="visually-hidden" type="checkbox"
                    checked={multiplier === 1}
                    onChange={evt => {
                      onMultiplierChange(evt, 1);
                    }}
                  />
                  <span className="switch__switch"><span className="dot"></span></span>
                  <span className="switch__label label">1</span>
                </label>
                <label className="switch switch--checkbox switch--label-left">
                  <input className="visually-hidden" type="checkbox"
                    checked={multiplier === 1000}
                    onChange={evt => {
                      onMultiplierChange(evt, 1000);
                    }}
                  />
                  <span className="switch__switch"><span className="dot"></span></span>
                  <span className="switch__label label">1000</span>
                </label>
                <label className="switch switch--checkbox switch--label-left">
                  <input className="visually-hidden" type="checkbox"
                    checked={multiplier === 1000000}
                    onChange={evt => {
                      onMultiplierChange(evt, 1000000);
                    }} />
                  <span className="switch__switch"><span className="dot"></span></span>
                  <span className="switch__label label">1M</span>
                </label>
              </div>
            </div> : null}

          </div>
          <ul className="list">
            {
              financialDetails.map((financialDetail, index) => (
                <li key={index}>
                  <FinancialDetailsComponent
                    isEditMode={isEditMode}
                    originalValue={originalValue?.[index]}
                    touchedInputs={touchedInputs.financials[index]}
                    financial={financialDetail}
                    updateBalanceSheet={(key: keyof CompanyUploadModel['financials'][0]['balanceSheet'], value: any) => {
                      const property = financialDetail.balanceSheet[key] as PropertyWithPrice<string>;
                      property.value = value;
                      updateBalanceSheet(key, index, property)
                    }}
                    updateIncomeStatement={(key: keyof CompanyUploadModel['financials'][0]['incomeStatement'], value: any) => {
                      const property = financialDetail.incomeStatement[key] as PropertyWithPrice<string>;
                      property.value = value;
                      updateIncomeStatement(key, index, property)
                    }}
                    isOpen={index === openInfoIndex}
                    toggleCollapse={() => {
                      setOpenInfoIndex(openInfoIndex === index ? -1 : index);
                    }}
                  />
                </li>
              ))
            }
          </ul>
        </div >
      </div >
    </div >
  );
};