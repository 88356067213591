import { CompanyAttachmentType } from '../models';
import { CompanySearchState } from '../saved-companies/saved-companies.models';
import { SearchState } from '../search/search.reducer';

export type SavedCompanyAttribute = undefined | {
    attributeId: number;
    savedAttributeId?: number;
    value: any;
    valueDescription?: string;
    required: boolean;
    disabled: boolean;
    hasUpdate: boolean;
    creditPrice: number;
};

export enum AttributeType {
    Summary = 1,
    ProductsAndServices = 2,
    ManagementContact = 3,
    Financial = 4,
    Attachment = 5,
}

export interface GetSourceQuery {
    attributeId: number;
    type: AttributeType;
}

export interface CreditModel {
    key: string;
    displayName: string;
    uploadValue: number;
    downloadValue: string;
}

export interface SavedCompanyAttachmentAttributeModel {
    attachmentId: number;
    attachmentName: string;
    attachmentType: CompanyAttachmentType;
    publicUrl?: string;
    creditPrice: number;
}

export interface SavedCompanySummaryAttribute {
    id?: number;
    companySummaryAttributeId?: number;
    key?: string;
    value?: string;
}

export interface SavedCompanyProductsServicesAttribute {
    id?: number;
    companyProductsAndServicesAttributeId?: number;
    key?: string;
    value?: string;
}

export interface SavedCompanyFinancialAttribute {
    id?: number;
    companyFinancialAttributeId?: number;
    key?: string;
    value?: string;
}

export interface SavedCompanyAttachmentAttribute {
    id?: number;
    attachmentId?: number;
    publicUrl?: string;
}

export interface SavedCompanyManagementContactAttribute {
    id?: number;
    managementContactAttributeId?: number;
    managementContactId?: number;
    key?: string;
    value?: string;
}

export interface SavedCompanyAttributeModel {
    attributeId: number;
    savedAttributeId?: number;
    value: string | undefined;
    valueDescription: string;
    disabled: boolean;
    required: boolean;
    hasUpdate: boolean;
    creditPrice: number;
    touched?: boolean;
}

export interface SavedCompanySummaryModel {
    fullName: SavedCompanyAttributeModel;
    shortName: SavedCompanyAttributeModel;
    industry: SavedCompanyAttributeModel;
    sector: SavedCompanyAttributeModel | undefined;
    introduction: SavedCompanyAttributeModel;
    countryOfFoundation: SavedCompanyAttributeModel;
    entityRelationshipStatus: SavedCompanyAttributeModel;
    formOfOrganization: SavedCompanyAttributeModel;
    foundationYear: SavedCompanyAttributeModel;
    identificationNumber?: SavedCompanyAttributeModel;
    numberOfEmployees?: SavedCompanyAttributeModel;
    mainAddressCountryId: SavedCompanyAttributeModel;
    mainAddressCity: SavedCompanyAttributeModel;
    mainAddressZip: SavedCompanyAttributeModel;
    mainAddressStreet: SavedCompanyAttributeModel;
    mainAddressDoor?: SavedCompanyAttributeModel;
    mainAddressFloor?: SavedCompanyAttributeModel;
    mainAddressHouseNumber: SavedCompanyAttributeModel;
    businessAddressCountryId?: SavedCompanyAttributeModel;
    businessAddressCity?: SavedCompanyAttributeModel;
    businessAddressZip?: SavedCompanyAttributeModel;
    businessAddressStreet?: SavedCompanyAttributeModel;
    businessAddressDoor?: SavedCompanyAttributeModel;
    businessAddressFloor?: SavedCompanyAttributeModel;
    businessAddressHouseNumber?: SavedCompanyAttributeModel;
    otherContactDetailsWebsite: SavedCompanyAttributeModel;
    otherContactDetailsContactEmailAddress?: SavedCompanyAttributeModel;
    otherContactDetailsMainTelephoneNumber?: SavedCompanyAttributeModel;
}

export interface SavedCompanyProductsAndServicesModel {
    mainServices: SavedCompanyAttributeModel;
    mainProducts: SavedCompanyAttributeModel;
    brands: SavedCompanyAttributeModel;
}

export interface SavedCompanyManagementContactIndividualModel {
    name: SavedCompanyAttributeModel;
    position: SavedCompanyAttributeModel;
    location: SavedCompanyAttributeModel;
    functions: SavedCompanyAttributeModel;
    telephoneNumber: SavedCompanyAttributeModel;
    emailAddress: SavedCompanyAttributeModel;
    linkedIn: SavedCompanyAttributeModel;
}

export interface SavedCompanyManagementContactFrontDeskOrDepartmentModel {
    location: SavedCompanyAttributeModel;
    telephoneNumber: SavedCompanyAttributeModel;
    emailAddress: SavedCompanyAttributeModel;
    department: SavedCompanyAttributeModel;
    departmentOther: SavedCompanyAttributeModel;
}
export interface SavedCompanyManagementContactModel {
    id: number;
    individual: SavedCompanyManagementContactIndividualModel;
    frontDeskOrDepartment: SavedCompanyManagementContactFrontDeskOrDepartmentModel;
}

export interface SavedCompanyAttachmentModel {
    attachmentId: number;
    attachmentName: string;
    savedCompanyAttributeId?: number;
    publicUrl?: string;
    creditPrice: number;
}

export interface SavedCompanyAttachmentsModel {
    standardImages: SavedCompanyAttachmentModel[];
    managementStructure: SavedCompanyAttachmentModel[];
    otherDocument: SavedCompanyAttachmentModel[];
}

export interface SavedCompanyFinancialModel {
    financialId?: number;
    year: number;
    incomeStatementNetSales?: SavedCompanyAttributeModel;
    incomeStatementExtraordinaryIncome?: SavedCompanyAttributeModel;
    incomeStatementFinancialIncome?: SavedCompanyAttributeModel;
    incomeStatementOtherIncome?: SavedCompanyAttributeModel;
    incomeStatementMaterials?: SavedCompanyAttributeModel;
    incomeStatementPersonnel?: SavedCompanyAttributeModel;
    incomeStatementUtilities?: SavedCompanyAttributeModel;
    incomeStatementOverhead?: SavedCompanyAttributeModel;
    incomeStatementFinancialExpenses?: SavedCompanyAttributeModel;
    incomeStatementSellingAndAdministrative?: SavedCompanyAttributeModel;
    incomeStatementDepreciationAndAmortization?: SavedCompanyAttributeModel;
    incomeStatementOtherExpenses?: SavedCompanyAttributeModel;
    incomeStatementTotalNetRevenues?: SavedCompanyAttributeModel;
    incomeStatementTotalExpenses?: SavedCompanyAttributeModel;
    incomeStatementNetIncomeBeforeTaxes?: SavedCompanyAttributeModel;
    incomeStatementCorporateIncomeTaxes?: SavedCompanyAttributeModel;
    incomeStatementNetIncomeAfterTaxes?: SavedCompanyAttributeModel;
    incomeStatementDividensPaid?: SavedCompanyAttributeModel;
    incomeStatementRetainedEarningsForTheYear?: SavedCompanyAttributeModel;
    balanceSheetCashAndEquivalents?: SavedCompanyAttributeModel;
    balanceSheetAccountsReceivable?: SavedCompanyAttributeModel;
    balanceSheetInventories?: SavedCompanyAttributeModel;
    balanceSheetOtherCurrentAssets?: SavedCompanyAttributeModel;
    balanceSheetPropertyPlantAndEquipment?: SavedCompanyAttributeModel;
    balanceSheetProprietaryRights?: SavedCompanyAttributeModel;
    balanceSheetAccumulatedDepreciation?: SavedCompanyAttributeModel;
    balanceSheetOtherFixedAssets?: SavedCompanyAttributeModel;
    balanceSheetAccountsPayable?: SavedCompanyAttributeModel;
    balanceSheetShortTermLoans?: SavedCompanyAttributeModel;
    balanceSheetTaxesPayable?: SavedCompanyAttributeModel;
    balanceSheetOtherCurrentLiabilities?: SavedCompanyAttributeModel;
    balanceSheetLongTermLoanes?: SavedCompanyAttributeModel;
    balanceSheetShareHoldersLoans?: SavedCompanyAttributeModel;
    balanceSheetDeferredIncomeTaxes?: SavedCompanyAttributeModel;
    balanceSheetOtherLongTermLiabilites?: SavedCompanyAttributeModel;
    balanceSheetRegisteredShares?: SavedCompanyAttributeModel;
    balanceSheetAdditionalPaidInCapital?: SavedCompanyAttributeModel;
    balanceSheetAccumulatedRetainedEarnings?: SavedCompanyAttributeModel;
    balanceSheetOtherOwnersEquity?: SavedCompanyAttributeModel;
    balanceSheetTotalAssets?: SavedCompanyAttributeModel;
    balanceSheetTotalLiabilites?: SavedCompanyAttributeModel;
    balanceSheetOwnersEquity?: SavedCompanyAttributeModel;
    balanceSheetLiabilitiesAndOwnersEquity?: SavedCompanyAttributeModel;
}

export interface SavedCompanyListModel {    
    count: number;
    state: CompanySearchState;
    list: SavedCompanyModel[];
    name: string;
}

export interface SavedCompanyModel {
    id: number;
    savedSearchId?: number;
    isBlocked: boolean;
    updatesAvailableCount?: number;
    summary: SavedCompanySummaryModel;
    productsAndServices: SavedCompanyProductsAndServicesModel;
    managementContacts: SavedCompanyManagementContactModel[];
    attachments: SavedCompanyAttachmentsModel;
    financials: SavedCompanyFinancialModel[];
}

export const CompanyPropertyLabelMap = {
    fullName: "Company's Full Name",
    shortName: "Company's Short Name",
    introduction: "Brief Introduction",
    industry: "Industry",
    sector: "Sub-Sector",
    formOfOrganization: "Form of Organization",
    countryOfFoundation: "Country of Foundation",
    entityRelationshipStatus: "Entity Relationship Status",
    identificationNumber: "Identification number",
    foundationYear: "Year of Foundation",
    numberOfEmployees: "Number of Employees",

    mainAddressCountryId: "Country",
    mainAddressZip: "Postal (ZIP) Code",
    mainAddressCity: "City",
    mainAddressStreet: "Street",
    mainAddressHouseNumber: "House Number",
    mainAddressFloor: "Floor",
    mainAddressDoor: "Suite or Door",

    businessAddressCountryId: "Country",
    businessAddressZip: "Postal (ZIP) Code",
    businessAddressCity: "City",
    businessAddressStreet: "Street",
    businessAddressHouseNumber: "House Number",
    businessAddressFloor: "Floor",
    businessAddressDoor: "Suite or Door",

    otherContactDetailsWebsite: "Website",
    otherContactDetailsContactEmailAddress: "Email Address",
    otherContactDetailsMainTelephoneNumber: "Main Telephone Number",

    mainProducts: 'Main products',
    mainServices: 'Main services',
    brands: 'Brands',

    managementContactFrontDeskOrDepartmentLocation: "Location",
    managementContactFrontDeskOrDepartmentTelephoneNumber: "Telephone Number",
    managementContactFrontDeskOrDepartmentEmailAddress: "Email Address",
    managementContactFrontDeskOrDepartmentDepartment: "Department",
    managementContactFrontDeskOrDepartmentDepartmentOther: "Department - Other",

    managementContactIndividualName: "Name",
    managementContactIndividualPosition: "Position",
    managementContactIndividualLocation: "Location",
    managementContactIndividualFunctions: "Functions",
    managementContactIndividualTelephoneNumber: "Telephone Number",
    managementContactIndividualEmailAddress: "Email Address",
    managementContactIndividualLinkedIn: "LinkedIn",

    // Financials
    // Income Statement

    incomeStatementNetSales: 'Net Sales',
    incomeStatementExtraordinaryIncome: 'Extraordinary Income',
    incomeStatementFinancialIncome: 'Financial Income',
    incomeStatementOtherIncome: 'Other Income',
    incomeStatementMaterials: 'Materials',
    incomeStatementPersonnel: 'Personnel',
    incomeStatementUtilities: 'Utilities',
    incomeStatementOverhead: 'Overhead',
    incomeStatementFinancialExpenses: 'Financial Expenses',
    incomeStatementSellingAndAdministrative: 'Selling and Administrative',
    incomeStatementDepreciationAndAmortization: 'Depreciation and Amortization',
    incomeStatementOtherExpenses: 'Other Expenses',
    incomeStatementTotalNetRevenues: 'Total Net Revenues',
    incomeStatementTotalExpenses: 'Total Expenses',
    incomeStatementNetIncomeBeforeTaxes: 'Net Income Before Taxes',
    incomeStatementCorporateIncomeTaxes: 'Corporate Income Taxes',
    incomeStatementNetIncomeAfterTaxes: ' Net Income After Taxes',
    incomeStatementDividensPaid: 'Dividends paid',
    incomeStatementRetainedEarningsForTheYear: 'Retained Earnings for the Year',

    // Balance Sheet
    balanceSheetCashAndEquivalents: "Cash and Equivalents",
    balanceSheetAccountsReceivable: "Accounts Receivable",
    balanceSheetInventories: "Inventories",
    balanceSheetOtherCurrentAssets: "Other Current Assets",
    balanceSheetPropertyPlantAndEquipment: "Property, Plant and Equipment",
    balanceSheetProprietaryRights: "Proprietary Rights",
    balanceSheetAccumulatedDepreciation: "Accumulated Depreciation",
    balanceSheetOtherFixedAssets: "Other Fixed Assets",
    balanceSheetAccountsPayable: "Accounts Payable",
    balanceSheetShortTermLoans: "Short - Term Loans",
    balanceSheetTaxesPayable: "Taxes Payable",
    balanceSheetOtherCurrentLiabilities: "Other Current Liabilities",
    balanceSheetLongTermLoanes: "Long - Term Loans",
    balanceSheetShareHoldersLoans: "Shareholders Loans",
    balanceSheetDeferredIncomeTaxes: "Deferred Income Taxes",
    balanceSheetOtherLongTermLiabilites: "Other Long - Term Liabilities",
    balanceSheetRegisteredShares: "Registered Shares",
    balanceSheetAdditionalPaidInCapital: "Additional Paid-in Capital",
    balanceSheetAccumulatedRetainedEarnings: "Accumulated Retained Earnings",
    balanceSheetOtherOwnersEquity: "Other Owner's Equity",
    balanceSheetTotalAssets: "Total Assets",
    balanceSheetTotalLiabilites: "Total Liabilities",
    balanceSheetOwnersEquity: "Owner's Equity",
    balanceSheetLiabilitiesAndOwnersEquity: "Liabilities and Owner's Equity",
};