import { connect } from "react-redux";
import { AppState } from "../root.reducer";
import {
  UserBadgeStateProps,
  UserBadgeDispatchProps,
  UserBadge,
} from "./UserBadge.component";
import { gravityUseAsSelector, userSelector } from "../auth/authentication.selectors";
import { Dispatch } from "redux";
import { authenticationService } from "../auth/authentication.service";
import {
  authenticationSuccessAction,
  changeUseGravityAsAction,
  logoutAction,
} from "../auth/authentication.actions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { workspaceInitializedSuccessfulAction, workspaceLoadedSuccessfulAction, workspaceLoadingAction } from '../workspace-settings/workspace.actions';
import { workspaceService } from '../workspace-settings/workspace.service';
import { workspaceSelector } from '../workspace-settings/workspace.selector';
import { resetCurrentPageAction } from '../saved-companies/saved-companies.actions';

const mapStateToProps = (state: AppState): UserBadgeStateProps => ({
  user: userSelector(state),
  useGravityAs: gravityUseAsSelector(state),
  workspace: workspaceSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): UserBadgeDispatchProps => ({
  logout: async () => {
    const result = await authenticationService.logout();

    if (result.status === 200) {
      dispatch(logoutAction());
      dispatch(resetCurrentPageAction());
      
      history.push('/');
    }
  },
  changeUseGravityAs: async (id?: number) => {
    document.querySelector('body')?.click();
    dispatch(changeUseGravityAsAction(id));

    if (id) {
      const workspace = await workspaceService.getWorkspaceSettings(id);
      dispatch(workspaceLoadedSuccessfulAction(workspace));
    } else {
      const user = await authenticationService.getUser();
      dispatch(authenticationSuccessAction(user));
    }

    history.push('/saved-companies');
  },

  openWorkspaceSettings: async (workspaceId: number) => {
    const url = `/workspace/${workspaceId}`;
    if (history.location.pathname !== url) {
      dispatch(workspaceInitializedSuccessfulAction());
      dispatch(workspaceLoadingAction());

      history.push(url);
    }
  },
});

export default withRouter(
  connect<
    UserBadgeStateProps,
    UserBadgeDispatchProps,
    RouteComponentProps,
    AppState
  >(
    mapStateToProps,
    mapDispatchToProps
  )(UserBadge)
);
