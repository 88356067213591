import { Cart } from './cart.models';
import { CartActions, CartActionTypes } from './cart.actions';
import { RequestState } from '../models';
import { AttributeType } from '../saved-company/saved-company.models';

export interface CartState {
    cart: Cart;
    submissionState: RequestState;
}

const initialState: CartState = {
    cart: {
        items: [],
    },
    submissionState: RequestState.Initial
};

export const cartReducer = (state: CartState = initialState, action: CartActions): CartState => {
    const isInCart = (attributeType: AttributeType, attributeId: number) => {
        const payload = (action.payload as any);
        return payload.attributeType === attributeType && state.cart.items.find(item => item.attributeType === attributeType && item.attributeId === attributeId);
    };

    switch (action.type) {
        case CartActionTypes.ADD_CART_ITEM:
            if (isInCart(action.payload.attributeType, action.payload.attributeId)) {
                return state;
            }

            return {
                ...state,
                cart: {
                    ...state.cart,
                    items: [
                        ...state.cart.items,
                        action.payload
                    ]
                }
            };
        case CartActionTypes.REMOVE_ITEM:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    items: state.cart.items.filter(item => item !== action.payload)
                }
            };
        case CartActionTypes.SUBMIT_CART:
            return {
                ...state,
                submissionState: RequestState.Loading
            };
        case CartActionTypes.SUBMIT_CART_SUCCESS:
            return {
                ...state,
                submissionState: RequestState.Succeeded
            };
        case CartActionTypes.SUBMIT_CART_FAIL:
            return {
                ...state,
                submissionState: RequestState.Failed
            };
        case CartActionTypes.RESET_CART:
            return {
                ...initialState
            };
        default:
            return state;
    }
};