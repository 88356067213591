import React, { useState } from "react";
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import queryString from 'query-string';

export interface LoginComponentStateProps {
    isLoggedIn: boolean;
    isLoading: boolean;
    error?: any;
}

export interface LoginComponentDispatchProps {
    login(username: string, password: string, returnUrl?: string): void;
}

const LoginPageComponent: React.FC<LoginComponentStateProps & LoginComponentDispatchProps & RouteComponentProps> = ({ login, isLoading, error, isLoggedIn, history, location }) => {
    const returnUrl = queryString.parse(location.search)?.returnUrl?.toString() ?? '';
    const confirmError = queryString.parse(location.search)?.confirmError?.toString() ?? '';

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isTouched, setIsTouched] = useState(false);

    if (isLoggedIn) {
        return (<Redirect to={returnUrl ?? '/'} />);
    }

    if (isLoading) {
        return (
            <div className="main">
                <div className="container">
                    <FullPageLoadingComponent /></div>
            </div>
        );
    }

    const passwordHasError = () => {
        return !password;
    };

    const passwordErrorProps = () => {
        return passwordHasError() && isTouched ? { error: "error" } : {};
    };

    const emailHasError = () => {
        return !username || !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username));
    };

    const emailErrorProps = () => {
        return emailHasError() && isTouched ? { error: "error" } : {};
    };

    let auth2: gapi.auth2.GoogleAuth;

    const loginAndRedirect = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (!username || !password || emailHasError() || passwordHasError()) {
            setIsTouched(true);
            return;
        }

        login(username, password, returnUrl);
    };

    const loginWithLinkedin = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();

        const host = window.location.origin;
        const redirectUri = host + `/api/account/linkedin/callback?returnUrl=${returnUrl}`;
        const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77u22bj8xfwl5e&redirect_uri=${redirectUri}&scope=r_liteprofile%20r_emailaddress`;

        window.location.href = url;
    };

    const loginWithGoogle = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();

        gapi.load('auth2', () => {
            auth2 = gapi.auth2.init({
                client_id: '52656969569-hk3uk6it7k769ljmn4dchcbfvl461vu2.apps.googleusercontent.com',
            });
            const idToken = auth2.currentUser.get().getAuthResponse().id_token;
            if (idToken === undefined) {
                auth2.signIn().then(user => {
                    const newIdToken = user.getAuthResponse().id_token;
                    const url = `/api/account/verify-google?idToken=${newIdToken}&returnUrl=${returnUrl}`;
                    window.location.href = url;
                });
            } else {
                const url = `/api/account/verify-google?idToken=${idToken}&returnUrl=${returnUrl}`;
                window.location.href = url;
            }
        });
    };

    return (
        <div className="section section--content">
            <div className="container">
                <div className="grid">
                    <div className="gi-4 gi-s-5 gi-e-9 t-gi-4 t-gi-s-3 t-gi-e-7 m-gi-4 m-gi-s-1 m-gi-e-4">
                        <div className="stack stack-m">
                            <div className="user-form">
                                <form className="stack stack-xl" onSubmit={(evt) => { loginAndRedirect(evt); }}>
                                    <div>
                                        <h1 className="stack stack-xs ai-c">
                                            <span className="logo logo--full logo--grey logo--color">
                                                <svg className="logo__logo">
                                                    <use xlinkHref="#logo-full-grey-color" />
                                                </svg>
                                                <span className="visually-hidden">Gravity</span>
                                            </span>
                                            <span className="title title-m">Log In</span>
                                        </h1>
                                    </div>
                                    <fieldset>
                                        <div className="stack stack-xs">
                                            <div>
                                                <p className="label">Email Address</p>
                                                <div className="field field--input" {...emailErrorProps()}>
                                                    <input className="field__field" name="username" id="username" placeholder="example@example.com"
                                                        value={username} onChange={(evt) => setUsername(evt.target.value)} />
                                                </div>
                                            </div>
                                            <div>
                                                <p className="label">Password</p>
                                                <div className="field field--input"  {...passwordErrorProps()}>
                                                    <input className="field__field" name="password" id="password" placeholder="Password" type="password"
                                                        value={password} onChange={(evt) => setPassword(evt.target.value)} />
                                                </div>
                                            </div>
                                            {!error ? "" : <p {...{ style: { color: "red" } }}>{error}</p>}
                                            {!confirmError ? "" : <p {...{ style: { color: "red" } }}>Error during e-mail confirm. Please try the Forgot my Password function!</p>}
                                        </div>
                                    </fieldset>
                                    <div className="buttons">
                                        <div className="stack stack-m">
                                            <div>
                                                <div className="stack stack-xs">
                                                    <button className="button">
                                                        <span className="button__inner">
                                                            <span>Log In</span>
                                                        </span>
                                                    </button>
                                                    <p className="sep">or Log In with</p>
                                                    <div className="alternative-options h-stack h-stack-xs jc-c">
                                                        <button onClick={loginWithGoogle} className="button button--secondary">
                                                            <span className="button__inner">
                                                                <svg className="icon icon--m">
                                                                    <use xlinkHref="#icon-google" />
                                                                </svg>
                                                                <span>Google</span>
                                                            </span>
                                                        </button>
                                                        <button onClick={loginWithLinkedin} className="button button--secondary">
                                                            <span className="button__inner">
                                                                <svg className="icon icon--m">
                                                                    <use xlinkHref="#icon-linkedin" />
                                                                </svg>
                                                                <span>LinkedIn</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="forgot h-stack jc-c">
                                                    <button className="button button--neutral" onClick={() => history.push('/forgotpassword')}>
                                                        <span className="button__inner">
                                                            <span>Forgot my Password</span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="user-form">
                                <div className="stack stack-m ai-c">
                                    <h2 className="title title-m">New to Gravity?</h2>
                                    <div>
                                        <div className="stack stack-xs ai-c">
                                            <p className="annotation">Receive 300 registration bonus credits for free</p>
                                            <button className="button button--secondary" onClick={() => history.push('/register')}>
                                                <span className="button__inner">
                                                    <span>Register for Free</span>
                                                </span>
                                            </button>
                                            <button className="button button--neutral" onClick={() => history.push('/')}>
                                                <span className="button__inner">
                                                    <span>Check out how it works</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
};

export const LoginPage = withRouter(LoginPageComponent);

