import { AxiosInstance } from 'axios';
import { axiosInstance } from '../axios';
import { CountryModel } from '../meta/meta.models';

class MetaService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    async getCountries(): Promise<CountryModel[]> {
        const countries = await this.axios.get<CountryModel[]>('/api/countries');

        return [
            {
                name: 'All Countries'
            } as any,
            ...countries.data
        ];
    }
}

export const metaService = new MetaService();