// import { createStore } from 'redux';
// import { popupSetModelAction } from './popup.actions';
// import { PopupDialogType } from './popup.models';
// import { popupReducer } from './popup.reducer';

class PopupService {
    showPopup() {
        // const store = createStore(popupReducer);
        // store.dispatch(popupSetModelAction({
        //     text: _text,
        //     type: PopupDialogType.Confirm,
        // }));
        document.body.classList.add('popup');
    }

    hidePopup() {
        document.body.classList.remove('popup');
    }
}

export const popupService = new PopupService();