import { AppState } from '../../root.reducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from "react-redux";
import { isWorkspaceLoadingSelector, workspaceDeleteConfirmationSelector } from '../workspace.selector';
import { Dispatch } from 'redux';
import { workspaceService } from '../workspace.service';
import { workspaceDeleteConfirmAction, workspaceLoadingAction } from '../workspace.actions';
import { WorkspaceDeleteConfirmComponentDispatchProps, WorkspaceDeleteConfirmComponentStateProps, WorkspaceDeleteConfirmComponent } from './WorkspaceDeleteConfirm.component';

const mapStateToProps = (state: AppState): WorkspaceDeleteConfirmComponentStateProps => ({
    isLoading: isWorkspaceLoadingSelector(state),
    workspaces: workspaceDeleteConfirmationSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): WorkspaceDeleteConfirmComponentDispatchProps => ({
    loadWorkspaces: async () => {
        dispatch(workspaceLoadingAction());
        const response = await workspaceService.getDeleteConfirmations();

        if (response.status === 200) {
            dispatch(workspaceDeleteConfirmAction(response.data));
        }
    },
});

export default withRouter(
    connect<WorkspaceDeleteConfirmComponentStateProps, WorkspaceDeleteConfirmComponentDispatchProps, RouteComponentProps, AppState>(
        mapStateToProps, mapDispatchToProps
    )(WorkspaceDeleteConfirmComponent)
);
