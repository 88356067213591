import { ManagementContactActivationModel } from './models';
import { ManagementContactActivationActions, ManagementContactActivationActionTypes} from './activation.actions';

export interface AuthState {
  isLoading: boolean;
  activation?: ManagementContactActivationModel;
}

const initialState: AuthState = {
  isLoading: true,
  activation: undefined
};

export const managementContactActivationReducer = (state: AuthState = initialState, action: ManagementContactActivationActions): AuthState => {
  switch (action.type) {
    case ManagementContactActivationActionTypes.MANAGEMENTCONTACT_ACTIVATION_LOADING:
      return {
        ...state,
        isLoading: true,
        activation: undefined,
      };
    case ManagementContactActivationActionTypes.MANAGEMENTCONTACT_ACTIVATION_LOADED:
        return {
          ...state,
          isLoading: false,
          activation: action.payload,
        };
      
    default:
      return state;
  }
};