import React from 'react';
import {  AttachmentType, CompanyAttachmentModel } from '../company-upload.models';
import { DocumentUploadWithPriceTagComponent } from './DocumentUploadWithPriceTag.component';
import { CompanyAttributeKey } from '../../company.constants';
import { CompanyUploadModel } from '../CompanyUpload.model';

export interface DocumentsAttachmentsUploadTabComponentProps {
    documentsAndAttachments: CompanyUploadModel['documentsAndAttachments'];
    updateDocumentsAndAttachments: (value: CompanyUploadModel['documentsAndAttachments']) => void;
}

export const DocumentsAttachmentsUploadTabComponent: React.FC<DocumentsAttachmentsUploadTabComponentProps> = ({
    documentsAndAttachments, updateDocumentsAndAttachments
}) => {

    const attachmentComponentProps = (key: keyof CompanyUploadModel['documentsAndAttachments']) => ({
        value: documentsAndAttachments[key].value,
        disabled: documentsAndAttachments[key].disabled,
        uploadValueKey: CompanyAttributeKey.Attachment,
        onFileUploaded: (fileData: CompanyAttachmentModel) => {
            if (!documentsAndAttachments[key].value) {
                documentsAndAttachments[key].value = [];
            }
            (documentsAndAttachments[key].value as any).push(fileData);
            updateDocumentsAndAttachments(documentsAndAttachments);
        },
        onDeleteItem: (index: number) => {
            const document = documentsAndAttachments[key].value![index];
            if (!document) {
                return;
            }

            if (document.isTemporary) {
                documentsAndAttachments[key].value?.splice(index, 1);
            } else {
                documentsAndAttachments[key].value![index].isMarkedForDelete = true;
                updateDocumentsAndAttachments(documentsAndAttachments);
            }
            updateDocumentsAndAttachments(documentsAndAttachments);
        }
    });
    
    return (
        <div className="stack stack-m">
            <div className="upload-form__section">
                <div className="stack stack-m">
                    <span className="icon-header icon-header--header-right " id="">
                        <span className="icon-header__inner">
                            <svg className="icon icon--l ">
                                <use xlinkHref="#icon-edit" />
                            </svg>
                            <span className="title title-s">Supporting Documents</span>
                        </span>
                    </span>
                    <div className="grid">
                        <div className="gi-12">
                            <DocumentUploadWithPriceTagComponent
                                attachmentType={AttachmentType.ManagementStructure}
                                label="Management Structure"
                                {...attachmentComponentProps('managementStructure')}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="gi-12">
                            <DocumentUploadWithPriceTagComponent
                                attachmentType={AttachmentType.StandardImage}
                                label="Images"
                                {...attachmentComponentProps('standardImages')}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="gi-12">
                            <DocumentUploadWithPriceTagComponent attachmentType={AttachmentType.OtherDocument} label="Other Documents"
                                {...attachmentComponentProps('otherDocuments')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};