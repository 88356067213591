import { connect } from 'react-redux';
import { AppState } from '../../root.reducer';
import { isLoadingSavedSearchSelector, isSearchingSelector, savedSearchSelector } from '../search.selectors';
import { SavedSearchTableComponent, SavedSearchTableStateProps, SavedSearchTableDispatchProps } from './SavedSearchTable.component';
import { Dispatch } from 'redux';
import { loadSavedSearch, loadSavedSearchSuccess, loadSavedSearchError } from '../search.actions';
import { searchService } from '../search.service';
import { RouteComponentProps } from 'react-router-dom';
import { gravityUseAsSelector } from '../../auth/authentication.selectors';
import { pageSelector, pageSizeSelector } from '../../saved-companies/saved-companies.selectors';
import { pageNextAction, pagePreviousAction, resetCurrentPageAction, setPageAction, setPageSizeAction } from '../../saved-companies/saved-companies.actions';
import { authenticationService } from '../../auth/authentication.service';
import { authenticationSuccessAction } from '../../auth/authentication.actions';
import { workspaceService } from '../../workspace-settings/workspace.service';
import { workspaceLoadedSuccessfulAction } from '../../workspace-settings/workspace.actions';

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<{ id: string }>): SavedSearchTableStateProps => ({
    isLoadingSavedSearch: isLoadingSavedSearchSelector(state),
    savedSearch: savedSearchSelector(state),
    savedSearchId: Number(match.params.id),
    useGravityAs: gravityUseAsSelector(state),
    isSearching: isSearchingSelector(state),
    // Convert page to pageIndex
    pageIndex: pageSelector(state) - 1,
    pageSize: pageSizeSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch): SavedSearchTableDispatchProps => ({
    loadSavedSearch: async (id: number, page: number, pageSize: number, useGravityAs?: number) => {
        dispatch(loadSavedSearch());

        try {
            if (useGravityAs) {
                const savedSearch = await searchService.loadSavedWorkspaceSearch(id, page, pageSize);
                dispatch(loadSavedSearchSuccess(savedSearch));
                const workspace = await workspaceService.getWorkspaceSettings(useGravityAs);
                dispatch(workspaceLoadedSuccessfulAction(workspace));
            } else {
                const savedSearch = await searchService.loadSavedUserSearch(id, page, pageSize);
                dispatch(loadSavedSearchSuccess(savedSearch));
                const user = await authenticationService.getUser();
                dispatch(authenticationSuccessAction(user));
            }
        } catch (e) {
            dispatch(loadSavedSearchError());
        }
    },

    setPage: (page: number) => {
        dispatch(setPageAction(page));
    },

    setPageSize: (pageSize: number) => {
        dispatch(setPageSizeAction(pageSize));
    },

    resetCurrentPage: () => {
        dispatch(resetCurrentPageAction());
    }
});

export default connect<SavedSearchTableStateProps, SavedSearchTableDispatchProps, RouteComponentProps<{ id: string }>, AppState>(
    mapStateToProps,
    mapDispatchToProps
)(SavedSearchTableComponent);