import { CompanyBalanceSheetUpdateModel, CompanyIncomeStatementUpdateModel, CompanyManagementContactFrontDeskOrDepartmentUpdateModel, CompanyManagementContactIndividualUpdateModel, CompanyManagementContactUpdateModel, CreditMap, FinancialsUpdatePublicModel, PropertyWithPrice, UpdateCompanyCommand } from '../company-upload/company-upload.models';
import { SavedCompanyModel, SavedCompanyAttributeModel } from '../saved-company/saved-company.models';
import { selectSectors } from '../models';
import { CompanyUploadModel } from '../company-upload/CompanyUpload.model';

class CompanyEditService {
    createEditModel(companyCredits: CreditMap, savedCompany: SavedCompanyModel) {
        const editModel = new CompanyUploadModel(companyCredits);

        const setValue = (original: PropertyWithPrice<string | number | string[]>, property: SavedCompanyAttributeModel | undefined) => {
            if (property?.value !== null && property?.value !== "" && !property?.hasUpdate) {
                original.value = property?.value;
            }
            if (property && (property.disabled || property.hasUpdate)) {
                original.disabled = true;
            }
            if (property?.required === true) {
                original.required = true;
            }
        };

        setValue(editModel.summary.fullname, savedCompany.summary.fullName);
        setValue(editModel.summary.shortname, savedCompany.summary.shortName);
        setValue(editModel.summary.industry, savedCompany.summary.industry);
        setValue(editModel.summary.sector, savedCompany.summary.sector);
        setValue(editModel.summary.introduction, savedCompany.summary.introduction);
        setValue(editModel.summary.formOfOrganization, savedCompany.summary.formOfOrganization);
        setValue(editModel.summary.countryOfFoundation, savedCompany.summary.countryOfFoundation);
        setValue(editModel.summary.foundationYear, savedCompany.summary.foundationYear);
        setValue(editModel.summary.numberOfEmployees, savedCompany.summary.numberOfEmployees);
        setValue(editModel.summary.entityRelationshipStatus, savedCompany.summary.entityRelationshipStatus);
        setValue(editModel.summary.identificationNumber, savedCompany.summary.identificationNumber);

        setValue(editModel.summary.mainAddress.countryId, savedCompany.summary.mainAddressCountryId);
        setValue(editModel.summary.mainAddress.zip, savedCompany.summary.mainAddressZip);
        setValue(editModel.summary.mainAddress.city, savedCompany.summary.mainAddressCity);
        setValue(editModel.summary.mainAddress.street, savedCompany.summary.mainAddressStreet);
        setValue(editModel.summary.mainAddress.houseNumber, savedCompany.summary.mainAddressHouseNumber);
        setValue(editModel.summary.mainAddress.floor, savedCompany.summary.mainAddressFloor);
        setValue(editModel.summary.mainAddress.door, savedCompany.summary.mainAddressDoor);

        setValue(editModel.summary.businessAddress.countryId, savedCompany.summary.businessAddressCountryId);
        setValue(editModel.summary.businessAddress.zip, savedCompany.summary.businessAddressZip);
        setValue(editModel.summary.businessAddress.city, savedCompany.summary.businessAddressCity);
        setValue(editModel.summary.businessAddress.street, savedCompany.summary.businessAddressStreet);
        setValue(editModel.summary.businessAddress.houseNumber, savedCompany.summary.businessAddressHouseNumber);
        setValue(editModel.summary.businessAddress.floor, savedCompany.summary.businessAddressFloor);
        setValue(editModel.summary.businessAddress.door, savedCompany.summary.businessAddressDoor);


        setValue(editModel.summary.otherContactDetails.contactEmailAddress, savedCompany.summary.otherContactDetailsContactEmailAddress);
        setValue(editModel.summary.otherContactDetails.website, savedCompany.summary.otherContactDetailsWebsite);
        setValue(editModel.summary.otherContactDetails.mainTelephoneNumber, savedCompany.summary.otherContactDetailsMainTelephoneNumber);

        const mainProducts = savedCompany.productsAndServices.mainProducts?.value;
        const mainServices = savedCompany.productsAndServices.mainServices?.value;
        const brands = savedCompany.productsAndServices.brands?.value;

        if (mainProducts) {
            editModel.productsAndServices.products!.value = mainProducts.split(';');
        }
        if (savedCompany.productsAndServices.mainProducts?.disabled || savedCompany.productsAndServices.mainProducts?.hasUpdate) {
            editModel.productsAndServices.products!.disabled = true;
        }
        if (savedCompany.productsAndServices.mainProducts?.required === true) {
            editModel.productsAndServices.products!.required = true;
        }

        if (mainServices) {
            editModel.productsAndServices.services!.value = mainServices.split(';');
        }
        if (savedCompany.productsAndServices.mainServices?.disabled || savedCompany.productsAndServices.mainServices?.hasUpdate) {
            editModel.productsAndServices.services!.disabled = true;
        }
        if (savedCompany.productsAndServices.mainServices?.required === true) {
            editModel.productsAndServices.services!.required = true;
        }

        if (brands) {
            editModel.productsAndServices.brands!.value = brands.split(';');
        }
        if (savedCompany.productsAndServices.brands?.disabled || savedCompany.productsAndServices.brands?.hasUpdate) {
            editModel.productsAndServices.brands!.disabled = true;
        }
        if (savedCompany.productsAndServices.brands?.required === true) {
            editModel.productsAndServices.brands!.required = true;
        }

        savedCompany.financials.forEach((financial) => {
            const yearlyDetail = editModel.financials.find(y => y.year === financial.year);
            if (yearlyDetail) {
                setValue(yearlyDetail.incomeStatement.netSales, financial.incomeStatementNetSales);
                setValue(yearlyDetail.incomeStatement.extraordinaryIncome, financial.incomeStatementExtraordinaryIncome);
                setValue(yearlyDetail.incomeStatement.financialIncome, financial.incomeStatementFinancialIncome);
                setValue(yearlyDetail.incomeStatement.otherIncome, financial.incomeStatementOtherIncome);
                setValue(yearlyDetail.incomeStatement.materials, financial.incomeStatementMaterials);
                setValue(yearlyDetail.incomeStatement.personnel, financial.incomeStatementPersonnel);
                setValue(yearlyDetail.incomeStatement.utilities, financial.incomeStatementUtilities);
                setValue(yearlyDetail.incomeStatement.overhead, financial.incomeStatementOverhead);
                setValue(yearlyDetail.incomeStatement.financialExpenses, financial.incomeStatementFinancialExpenses);
                setValue(yearlyDetail.incomeStatement.sellingAndAdministrative, financial.incomeStatementSellingAndAdministrative);
                setValue(yearlyDetail.incomeStatement.depreciationAndAmortization, financial.incomeStatementDepreciationAndAmortization);
                setValue(yearlyDetail.incomeStatement.otherExpenses, financial.incomeStatementOtherExpenses);
                setValue(yearlyDetail.incomeStatement.totalNetRevenues, financial.incomeStatementTotalNetRevenues);
                setValue(yearlyDetail.incomeStatement.totalExpenses, financial.incomeStatementTotalExpenses);
                setValue(yearlyDetail.incomeStatement.netIncomeBeforeTaxes, financial.incomeStatementNetIncomeBeforeTaxes);
                setValue(yearlyDetail.incomeStatement.corporateIncomeTaxes, financial.incomeStatementCorporateIncomeTaxes);
                setValue(yearlyDetail.incomeStatement.netIncomeAfterTaxes, financial.incomeStatementNetIncomeAfterTaxes);
                setValue(yearlyDetail.incomeStatement.dividensPaid, financial.incomeStatementDividensPaid);
                setValue(yearlyDetail.incomeStatement.retainedEarningsForTheYear, financial.incomeStatementRetainedEarningsForTheYear);

                setValue(yearlyDetail.balanceSheet.cashAndEquivalents, financial.balanceSheetCashAndEquivalents);
                setValue(yearlyDetail.balanceSheet.accountsReceivable, financial.balanceSheetAccountsReceivable);
                setValue(yearlyDetail.balanceSheet.inventories, financial.balanceSheetInventories);
                setValue(yearlyDetail.balanceSheet.otherCurrentAssets, financial.balanceSheetOtherCurrentAssets);
                setValue(yearlyDetail.balanceSheet.propertyPlantAndEquipment, financial.balanceSheetPropertyPlantAndEquipment);
                setValue(yearlyDetail.balanceSheet.proprietaryRights, financial.balanceSheetProprietaryRights);
                setValue(yearlyDetail.balanceSheet.accumulatedDepreciation, financial.balanceSheetAccumulatedDepreciation);
                setValue(yearlyDetail.balanceSheet.otherFixedAssets, financial.balanceSheetOtherFixedAssets);
                setValue(yearlyDetail.balanceSheet.accountsPayable, financial.balanceSheetAccountsPayable);
                setValue(yearlyDetail.balanceSheet.shortTermLoans, financial.balanceSheetShortTermLoans);
                setValue(yearlyDetail.balanceSheet.taxesPayable, financial.balanceSheetTaxesPayable);
                setValue(yearlyDetail.balanceSheet.otherCurrentLiabilities, financial.balanceSheetOtherCurrentLiabilities);
                setValue(yearlyDetail.balanceSheet.longTermLoanes, financial.balanceSheetLongTermLoanes);
                setValue(yearlyDetail.balanceSheet.shareHoldersLoans, financial.balanceSheetShareHoldersLoans);
                setValue(yearlyDetail.balanceSheet.deferredIncomeTaxes, financial.balanceSheetDeferredIncomeTaxes);
                setValue(yearlyDetail.balanceSheet.otherLongTermLiabilites, financial.balanceSheetOtherLongTermLiabilites);
                setValue(yearlyDetail.balanceSheet.registeredShares, financial.balanceSheetRegisteredShares);
                setValue(yearlyDetail.balanceSheet.additionalPaidInCapital, financial.balanceSheetAdditionalPaidInCapital);
                setValue(yearlyDetail.balanceSheet.accumulatedRetainedEarnings, financial.balanceSheetAccumulatedRetainedEarnings);
                setValue(yearlyDetail.balanceSheet.otherOwnersEquity, financial.balanceSheetOtherOwnersEquity);
                setValue(yearlyDetail.balanceSheet.totalAssets, financial.balanceSheetTotalAssets);
                setValue(yearlyDetail.balanceSheet.totalLiabilites, financial.balanceSheetTotalLiabilites);
                setValue(yearlyDetail.balanceSheet.ownersEquity, financial.balanceSheetOwnersEquity);
                setValue(yearlyDetail.balanceSheet.liabilitiesAndOwnersEquity, financial.balanceSheetLiabilitiesAndOwnersEquity);
            }
        });

        savedCompany.managementContacts.forEach(managementContact => {
            const newManagementContact = CompanyUploadModel.addManagementContact(editModel);
            newManagementContact.id = managementContact.id;

            setValue(newManagementContact.individual.name, managementContact.individual.name);
            setValue(newManagementContact.individual.position, managementContact.individual.position);
            setValue(newManagementContact.individual.location, managementContact.individual.location);
            setValue(newManagementContact.individual.telephoneNumber, managementContact.individual.telephoneNumber);
            if (newManagementContact.individual.telephoneNumber?.value !== '') {
                newManagementContact.individual.telephoneNumber.isValid = true;
            }
            setValue(newManagementContact.individual.emailAddress, managementContact.individual.emailAddress);
            if (newManagementContact.individual.emailAddress?.value !== '') {
                newManagementContact.individual.emailAddress.isValid = true;
            }
            setValue(newManagementContact.individual.linkedIn, managementContact.individual.linkedIn);
            if (managementContact.individual.functions?.value) {
                newManagementContact.individual.functions.value = managementContact.individual.functions.value.split(';');
            }
            if (managementContact.individual.functions?.disabled || managementContact.individual.functions?.hasUpdate) {
                newManagementContact.individual.functions!.disabled = true;
            }
            if (managementContact.individual.functions?.required === true) {
                newManagementContact.individual.functions!.required = true;
            }
            setValue(newManagementContact.frontDeskOrDepartment.location, managementContact.frontDeskOrDepartment.location);
            setValue(newManagementContact.frontDeskOrDepartment.telephoneNumber, managementContact.frontDeskOrDepartment.telephoneNumber);
            setValue(newManagementContact.frontDeskOrDepartment.emailAddress, managementContact.frontDeskOrDepartment.emailAddress);
            setValue(newManagementContact.frontDeskOrDepartment.department, managementContact.frontDeskOrDepartment.department);
            setValue(newManagementContact.frontDeskOrDepartment.departmentOther, managementContact.frontDeskOrDepartment.departmentOther);
        });

        savedCompany.attachments.managementStructure.forEach(attachment => {
            const propertyName = "managementStructure";

            editModel.documentsAndAttachments[propertyName].value!.push({
                id: attachment.attachmentId,
                url: attachment.publicUrl!,
                fileName: attachment.attachmentName,
                isTemporary: false,
            });
            editModel.documentsAndAttachments[propertyName].disabled = true;
        });
        savedCompany.attachments.standardImages.forEach(attachment => {
            const propertyName = "standardImages";

            editModel.documentsAndAttachments[propertyName].value!.push({
                id: attachment.attachmentId,
                url: attachment.publicUrl!,
                fileName: attachment.attachmentName,
                isTemporary: false,
            });
            editModel.documentsAndAttachments[propertyName].disabled = true;
        });
        savedCompany.attachments.otherDocument.forEach(attachment => {
            const propertyName = "otherDocuments";

            editModel.documentsAndAttachments[propertyName].value!.push({
                id: attachment.attachmentId,
                url: attachment.publicUrl!,
                fileName: attachment.attachmentName,
                isTemporary: false,
            });
            editModel.documentsAndAttachments[propertyName].disabled = true;
        });

        return editModel;
    }

    createCommandValue(original: SavedCompanyAttributeModel | undefined, editProperty: PropertyWithPrice<string | number> | undefined): SavedCompanyAttributeModel | undefined {
        if (!original && editProperty?.value && (editProperty?.isValid !== false)) {
            return {
                disabled: false,
                value: editProperty.value
            } as any;
        } else if (original && !original.disabled && !original.hasUpdate && (editProperty?.isValid !== false)) {
            return {
                ...original,
                value: editProperty?.value?.toString() ?? original.value
            };
        }
        return {
            disabled: true
        } as any;
    }

    createUpdateCompanyCommand(savedCompanyId: number, originalCompany: SavedCompanyModel, editCompanyModel: CompanyUploadModel, creditReward: number): UpdateCompanyCommand {
        const command = {
            creditForUpload: creditReward,
            savedCompanyId: savedCompanyId,
            financialMultiplier: editCompanyModel.financialMultiplier,
            summary: {
                industry: {
                    ...originalCompany.summary.industry,
                    value: editCompanyModel.summary.industry.value?.toString() ?? originalCompany.summary.industry.value?.toString(),
                },
                entityRelationshipStatus: {
                    ...originalCompany.summary.entityRelationshipStatus,
                    value: editCompanyModel.summary.entityRelationshipStatus?.value ?? originalCompany.summary.entityRelationshipStatus?.value,
                },
                formOfOrganization: {
                    ...originalCompany.summary.formOfOrganization,
                    value: editCompanyModel.summary.formOfOrganization?.value ?? originalCompany.summary.formOfOrganization?.value,
                },
                countryOfFoundationId: {
                    ...originalCompany.summary.countryOfFoundation,
                    value: editCompanyModel.summary.countryOfFoundation.value ?? originalCompany.summary.countryOfFoundation.value,
                },
                introduction: {
                    ...originalCompany.summary.introduction,
                    value: editCompanyModel.summary.introduction?.value ?? originalCompany.summary.introduction?.value,
                },
                mainAddress: {},
                businessAddress: {},
                otherContactDetails: {
                    contactEmailAddress: {                        
                        ...originalCompany.summary.otherContactDetailsContactEmailAddress,
                        value: editCompanyModel.summary.otherContactDetails.contactEmailAddress?.value ?? originalCompany.summary.otherContactDetailsContactEmailAddress?.value,
                    },
                    mainTelephoneNumber: {                        
                        ...originalCompany.summary.otherContactDetailsMainTelephoneNumber,
                        value: editCompanyModel.summary.otherContactDetails.mainTelephoneNumber?.value ?? originalCompany.summary.otherContactDetailsMainTelephoneNumber?.value,
                    },
                    website: {                        
                        ...originalCompany.summary.otherContactDetailsWebsite,
                        value: editCompanyModel.summary.otherContactDetails.website?.value ?? originalCompany.summary.otherContactDetailsWebsite?.value,
                    },
                }
            },
            productsAndServices: {},
            managementContacts: [] as any,
            documentAndAttachments: {
                otherDocuments: editCompanyModel.documentsAndAttachments.otherDocuments.value?.map(a => ({
                    attachmentId: a.id,
                    attachmentName: a.fileName,
                    creditPrice: 1
                })) ?? []
                ,
                managementStructure:
                    editCompanyModel.documentsAndAttachments.managementStructure.value?.map(a => ({
                        attachmentId: a.id,
                        attachmentName: a.fileName,
                        creditPrice: 1
                    })) ?? []
                ,
                standardImages:
                    editCompanyModel.documentsAndAttachments.standardImages.value?.map(a => ({
                        attachmentId: a.id,
                        attachmentName: a.fileName,
                        creditPrice: 1
                    })) ?? []
                ,
            },
        } as UpdateCompanyCommand;

        command.summary.sector = this.createCommandValue(originalCompany.summary.sector, editCompanyModel.summary.sector);
        command.summary.foundationYear = this.createCommandValue(originalCompany.summary.foundationYear, editCompanyModel.summary.foundationYear);
        command.summary.identificationNumber = this.createCommandValue(originalCompany.summary.identificationNumber, editCompanyModel.summary.identificationNumber);
        command.summary.numberOfEmployees = this.createCommandValue(originalCompany.summary.numberOfEmployees, editCompanyModel.summary.numberOfEmployees);

        command.summary.mainAddress.countryId =
            this.createCommandValue(originalCompany.summary.mainAddressCountryId, editCompanyModel.summary.mainAddress.countryId);
        command.summary.mainAddress.city =
            this.createCommandValue(originalCompany.summary.mainAddressCity, editCompanyModel.summary.mainAddress.city);
        command.summary.mainAddress.door =
            this.createCommandValue(originalCompany.summary.mainAddressDoor, editCompanyModel.summary.mainAddress.door);
        command.summary.mainAddress.floor =
            this.createCommandValue(originalCompany.summary.mainAddressFloor, editCompanyModel.summary.mainAddress.floor);
        command.summary.mainAddress.houseNumber =
            this.createCommandValue(originalCompany.summary.mainAddressHouseNumber, editCompanyModel.summary.mainAddress.houseNumber);
        command.summary.mainAddress.street =
            this.createCommandValue(originalCompany.summary.mainAddressStreet, editCompanyModel.summary.mainAddress.street);
        command.summary.mainAddress.zip =
            this.createCommandValue(originalCompany.summary.mainAddressZip, editCompanyModel.summary.mainAddress.zip);

        command.summary.businessAddress.countryId =
            this.createCommandValue(originalCompany.summary.businessAddressCountryId, editCompanyModel.summary.businessAddress.countryId);
        command.summary.businessAddress.city =
            this.createCommandValue(originalCompany.summary.businessAddressCity, editCompanyModel.summary.businessAddress.city);
        command.summary.businessAddress.door =
            this.createCommandValue(originalCompany.summary.businessAddressDoor, editCompanyModel.summary.businessAddress.door);
        command.summary.businessAddress.floor =
            this.createCommandValue(originalCompany.summary.businessAddressFloor, editCompanyModel.summary.businessAddress.floor);
        command.summary.businessAddress.houseNumber =
            this.createCommandValue(originalCompany.summary.businessAddressHouseNumber, editCompanyModel.summary.businessAddress.houseNumber);
        command.summary.businessAddress.street =
            this.createCommandValue(originalCompany.summary.businessAddressStreet, editCompanyModel.summary.businessAddress.street);
        command.summary.businessAddress.zip =
            this.createCommandValue(originalCompany.summary.businessAddressZip, editCompanyModel.summary.businessAddress.zip);


        command.productsAndServices.brands =
            this.createCommandValue(originalCompany.productsAndServices.brands, {
                value: editCompanyModel.productsAndServices.brands?.value?.join(';'),
                price: editCompanyModel.productsAndServices.brands?.price ?? 0
            });
        command.productsAndServices.mainServices =
            this.createCommandValue(originalCompany.productsAndServices.mainServices, {
                value: editCompanyModel.productsAndServices.services?.value?.join(';'),
                price: editCompanyModel.productsAndServices.services?.price ?? 0
            });
        command.productsAndServices.mainProducts =
            this.createCommandValue(originalCompany.productsAndServices.mainProducts, {
                value: editCompanyModel.productsAndServices.products?.value?.join(';'),
                price: editCompanyModel.productsAndServices.products?.price ?? 0
            });

        const managementContacts = editCompanyModel.managementContacts.map(managementContact => {
            const originalManagementContact = originalCompany.managementContacts.find(mc => mc.id === managementContact.id);
            const individual: CompanyManagementContactIndividualUpdateModel = {};
            individual.name = this.createCommandValue(originalManagementContact?.individual.name, managementContact.individual.name);
            individual.position = this.createCommandValue(originalManagementContact?.individual.position, managementContact.individual.position);
            individual.location = this.createCommandValue(originalManagementContact?.individual.location, managementContact.individual.location);
            individual.functions = this.createCommandValue(originalManagementContact?.individual.functions, {
                value: managementContact.individual.functions?.value?.join(';'),
                price: managementContact.individual.functions?.price
            });
            individual.telephoneNumber = this.createCommandValue(originalManagementContact?.individual.telephoneNumber, managementContact.individual.telephoneNumber);
            individual.emailAddress = this.createCommandValue(originalManagementContact?.individual.emailAddress, managementContact.individual.emailAddress);
            individual.linkedIn = this.createCommandValue(originalManagementContact?.individual.linkedIn, managementContact.individual.linkedIn);

            const frontDeskOrDepartment: CompanyManagementContactFrontDeskOrDepartmentUpdateModel = {};
            frontDeskOrDepartment.location = this.createCommandValue(originalManagementContact?.frontDeskOrDepartment.location, managementContact.frontDeskOrDepartment.location);
            frontDeskOrDepartment.telephoneNumber = this.createCommandValue(originalManagementContact?.frontDeskOrDepartment.telephoneNumber, managementContact.frontDeskOrDepartment.telephoneNumber);
            frontDeskOrDepartment.emailAddress = this.createCommandValue(originalManagementContact?.frontDeskOrDepartment.emailAddress, managementContact.frontDeskOrDepartment.emailAddress);
            frontDeskOrDepartment.department = this.createCommandValue(originalManagementContact?.frontDeskOrDepartment.department, managementContact.frontDeskOrDepartment.department);
            frontDeskOrDepartment.departmentOther = this.createCommandValue(originalManagementContact?.frontDeskOrDepartment.departmentOther, managementContact.frontDeskOrDepartment.departmentOther);

            return {
                id: managementContact.id === 0 ? null : managementContact.id,
                individual,
                frontDeskOrDepartment
            } as CompanyManagementContactUpdateModel;
        });

        if (managementContacts.length > 0) {
            command.managementContacts = managementContacts;
        }

        const financials = editCompanyModel.financials.map(financial => {
            const originalFinancial = originalCompany.financials.find(f => f.year === financial.year);
            const incomeStatement: CompanyIncomeStatementUpdateModel = {};

            incomeStatement.netSales = this.createCommandValue(originalFinancial?.incomeStatementNetSales, financial.incomeStatement.netSales);
            incomeStatement.extraordinaryIncome = this.createCommandValue(originalFinancial?.incomeStatementExtraordinaryIncome, financial.incomeStatement.extraordinaryIncome);
            incomeStatement.financialIncome = this.createCommandValue(originalFinancial?.incomeStatementFinancialIncome, financial.incomeStatement.financialIncome);
            incomeStatement.otherIncome = this.createCommandValue(originalFinancial?.incomeStatementOtherIncome, financial.incomeStatement.otherIncome);
            incomeStatement.materials = this.createCommandValue(originalFinancial?.incomeStatementMaterials, financial.incomeStatement.materials);
            incomeStatement.personnel = this.createCommandValue(originalFinancial?.incomeStatementPersonnel, financial.incomeStatement.personnel);
            incomeStatement.utilities = this.createCommandValue(originalFinancial?.incomeStatementUtilities, financial.incomeStatement.utilities);
            incomeStatement.overhead = this.createCommandValue(originalFinancial?.incomeStatementOverhead, financial.incomeStatement.overhead);
            incomeStatement.financialExpenses = this.createCommandValue(originalFinancial?.incomeStatementFinancialExpenses, financial.incomeStatement.financialExpenses);
            incomeStatement.sellingAndAdministrative = this.createCommandValue(originalFinancial?.incomeStatementSellingAndAdministrative, financial.incomeStatement.sellingAndAdministrative);
            incomeStatement.depreciationAndAmortization = this.createCommandValue(originalFinancial?.incomeStatementDepreciationAndAmortization, financial.incomeStatement.depreciationAndAmortization);
            incomeStatement.otherExpenses = this.createCommandValue(originalFinancial?.incomeStatementOtherExpenses, financial.incomeStatement.otherExpenses);
            incomeStatement.totalNetRevenues = this.createCommandValue(originalFinancial?.incomeStatementTotalNetRevenues, financial.incomeStatement.totalNetRevenues);
            incomeStatement.totalExpenses = this.createCommandValue(originalFinancial?.incomeStatementTotalExpenses, financial.incomeStatement.totalExpenses);
            incomeStatement.netIncomeBeforeTaxes = this.createCommandValue(originalFinancial?.incomeStatementNetIncomeBeforeTaxes, financial.incomeStatement.netIncomeBeforeTaxes);
            incomeStatement.corporateIncomeTaxes = this.createCommandValue(originalFinancial?.incomeStatementCorporateIncomeTaxes, financial.incomeStatement.corporateIncomeTaxes);
            incomeStatement.netIncomeAfterTaxes = this.createCommandValue(originalFinancial?.incomeStatementNetIncomeAfterTaxes, financial.incomeStatement.netIncomeAfterTaxes);
            incomeStatement.dividensPaid = this.createCommandValue(originalFinancial?.incomeStatementDividensPaid, financial.incomeStatement.dividensPaid);
            incomeStatement.retainedEarningsForTheYear = this.createCommandValue(originalFinancial?.incomeStatementRetainedEarningsForTheYear, financial.incomeStatement.retainedEarningsForTheYear);

            const balanceSheet: CompanyBalanceSheetUpdateModel = {};
            balanceSheet.cashAndEquivalents = this.createCommandValue(originalFinancial?.balanceSheetCashAndEquivalents, financial.balanceSheet.cashAndEquivalents);
            balanceSheet.accountsReceivable = this.createCommandValue(originalFinancial?.balanceSheetAccountsReceivable, financial.balanceSheet.accountsReceivable);
            balanceSheet.inventories = this.createCommandValue(originalFinancial?.balanceSheetInventories, financial.balanceSheet.inventories);
            balanceSheet.otherCurrentAssets = this.createCommandValue(originalFinancial?.balanceSheetOtherCurrentAssets, financial.balanceSheet.otherCurrentAssets);
            balanceSheet.propertyPlantAndEquipment = this.createCommandValue(originalFinancial?.balanceSheetPropertyPlantAndEquipment, financial.balanceSheet.propertyPlantAndEquipment);
            balanceSheet.proprietaryRights = this.createCommandValue(originalFinancial?.balanceSheetProprietaryRights, financial.balanceSheet.proprietaryRights);
            balanceSheet.accumulatedDepreciation = this.createCommandValue(originalFinancial?.balanceSheetAccumulatedDepreciation, financial.balanceSheet.accumulatedDepreciation);
            balanceSheet.otherFixedAssets = this.createCommandValue(originalFinancial?.balanceSheetOtherFixedAssets, financial.balanceSheet.otherFixedAssets);
            balanceSheet.accountsPayable = this.createCommandValue(originalFinancial?.balanceSheetAccountsPayable, financial.balanceSheet.accountsPayable);
            balanceSheet.shortTermLoans = this.createCommandValue(originalFinancial?.balanceSheetShortTermLoans, financial.balanceSheet.shortTermLoans);
            balanceSheet.taxesPayable = this.createCommandValue(originalFinancial?.balanceSheetTaxesPayable, financial.balanceSheet.taxesPayable);
            balanceSheet.otherCurrentLiabilities = this.createCommandValue(originalFinancial?.balanceSheetOtherCurrentLiabilities, financial.balanceSheet.otherCurrentLiabilities);
            balanceSheet.longTermLoanes = this.createCommandValue(originalFinancial?.balanceSheetLongTermLoanes, financial.balanceSheet.longTermLoanes);
            balanceSheet.shareHoldersLoans = this.createCommandValue(originalFinancial?.balanceSheetShareHoldersLoans, financial.balanceSheet.shareHoldersLoans);
            balanceSheet.deferredIncomeTaxes = this.createCommandValue(originalFinancial?.balanceSheetDeferredIncomeTaxes, financial.balanceSheet.deferredIncomeTaxes);
            balanceSheet.otherLongTermLiabilites = this.createCommandValue(originalFinancial?.balanceSheetOtherLongTermLiabilites, financial.balanceSheet.otherLongTermLiabilites);
            balanceSheet.registeredShares = this.createCommandValue(originalFinancial?.balanceSheetRegisteredShares, financial.balanceSheet.registeredShares);
            balanceSheet.additionalPaidInCapital = this.createCommandValue(originalFinancial?.balanceSheetAdditionalPaidInCapital, financial.balanceSheet.additionalPaidInCapital);
            balanceSheet.accumulatedRetainedEarnings = this.createCommandValue(originalFinancial?.balanceSheetAccumulatedRetainedEarnings, financial.balanceSheet.accumulatedRetainedEarnings);
            balanceSheet.otherOwnersEquity = this.createCommandValue(originalFinancial?.balanceSheetOtherOwnersEquity, financial.balanceSheet.otherOwnersEquity);
            balanceSheet.totalAssets = this.createCommandValue(originalFinancial?.balanceSheetTotalAssets, financial.balanceSheet.totalAssets);
            balanceSheet.totalLiabilites = this.createCommandValue(originalFinancial?.balanceSheetTotalLiabilites, financial.balanceSheet.totalLiabilites);
            balanceSheet.ownersEquity = this.createCommandValue(originalFinancial?.balanceSheetOwnersEquity, financial.balanceSheet.ownersEquity);
            balanceSheet.liabilitiesAndOwnersEquity = this.createCommandValue(originalFinancial?.balanceSheetLiabilitiesAndOwnersEquity, financial.balanceSheet.liabilitiesAndOwnersEquity);

            return {
                year: financial.year,
                incomeStatement,
                balanceSheet
            } as FinancialsUpdatePublicModel;
        });

        if (financials.length > 0) {
            command.financials = financials;
        }

        return command;
    }

    selectSectorsByIndustry = (industry: number) => {
        const _sectors = selectSectors(industry);
        return _sectors.map((sector) => ({
            name: sector.label || '',
            value: sector.value === 0 ? 0 : sector.value || '',
        }));
    }
}

export const companyEditService = new CompanyEditService();