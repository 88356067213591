import { AxiosInstance, AxiosResponse } from 'axios';
import { axiosInstance } from '../axios';
import { UploadFinalizeCommand, ViesCheckVatResponse } from './billing.model';

class BillingService {
  private axios: AxiosInstance;

  constructor() {
      this.axios = axiosInstance;
  }

  async generatePaypalClientToken(): Promise<string> {
    const response = await this.axios({
        url: '/api/payment/generate-token',
        method: 'POST'
    });

    return response.data;
  }

  async vatCheck(vatNumber: string): Promise<AxiosResponse<ViesCheckVatResponse>> {
    const response = await this.axios({
        url: '/api/payment/vat-check',
        method: 'POST',
        data: {
          vat: vatNumber
        }
    }).catch(err => err.response);

    return response;
  }

  async accountUploadFinalize(command: UploadFinalizeCommand): Promise<any> {
    return await this.axios({
        url: '/api/payment/account/finalize',
        method: 'POST',
        data: command
    }).catch(err => err.response);
  }

  async workspaceUploadFinalize(command: UploadFinalizeCommand): Promise<any> {
    return await this.axios({
        url: '/api/payment/workspace/finalize',
        method: 'POST',
        data: command
    }).catch(err => err.response);
  }

  async createOrder(amount: number): Promise<string> {
    const response = await this.axios({
        url: '/api/payment/create-order?amount=' + amount,
        method: 'POST'
    }).catch(err => err.response);

    return response.data;
  }

  async getPaypalClientId(): Promise<string> {
    const response = await this.axios({
        url: '/api/payment/paypal-clientid',
        method: 'GET'
    }).catch(err => err.response);

    return response.data;
  }
}

export const billingService = new BillingService();