import React from "react";
import SearchFields from '../SearchFields.container';
import SearchResults from './SearchResults.container';

export const SearchPageComponent: React.FC = () => {

    return (
        <>
            <SearchFields />
            <SearchResults />
        </>
    );
};
