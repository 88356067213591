import React from 'react';
import { AttributeType, CompanyPropertyLabelMap, SavedCompanyAttributeModel, SavedCompanyModel } from '../saved-company.models';
import { CompanyPropertyComponent } from './CompanyProperty.component';
import './tabs.scss';

export interface BasicDetailsProfileTabProps {
    addPropertyToCart: (attribute: SavedCompanyAttributeModel, label: any, attributeName: string) => void;
    savedCompany: SavedCompanyModel;
}

export const SummaryTab: React.FC<BasicDetailsProfileTabProps> = ({ savedCompany, addPropertyToCart }) => {
    const brandColorStyle = { '--background-color': 'var(--brand-color)' } as any as React.CSSProperties;

    const attributes = [
        "shortName",
        "fullName",
        "industry",
        "sector",
        "introduction",
        "countryOfFoundation",
        "entityRelationshipStatus",
        "formOfOrganization",
        "foundationYear",
        "identificationNumber",
        "numberOfEmployees",
        "mainAddressCountryId",
        "mainAddressCity",
        "mainAddressZip",
        "mainAddressStreet",
        "mainAddressDoor",
        "mainAddressFloor",
        "mainAddressHouseNumber",
        "businessAddressCountryId",
        "businessAddressCity",
        "businessAddressZip",
        "businessAddressStreet",
        "businessAddressDoor",
        "businessAddressFloor",
        "businessAddressHouseNumber",
        "otherContactDetailsWebsite",
        "otherContactDetailsContactEmailAddress",
        "otherContactDetailsMainTelephoneNumber",
    ];

    const { numberOfAvailableProperies, totalPriceOfProperties } = attributes
        .reduce((sum, propertyName) => {
            const model = savedCompany.summary as any;
            const isAvailableToBuy = model[propertyName] && (!model[propertyName]?.value || model[propertyName].hasUpdate);

            if (isAvailableToBuy) {
                sum.numberOfAvailableProperies += 1;
                sum.totalPriceOfProperties += model[propertyName]?.creditPrice || 0;
                sum.allAvailable.push(propertyName);
            }

            return sum;
        }, {  numberOfAvailableProperies: 0, totalPriceOfProperties: 0, allAvailable: [] as string[] });


    const addPropertyToCartIfAvailable = (property: SavedCompanyAttributeModel | undefined, label: any, propertyName: string) => {
        if (property && (property.value == null || property.value === "" || property.value === undefined || property.hasUpdate)) {
            addPropertyToCart(property, label, propertyName);
        }
    };

    const revealAll = () => {
        addPropertyToCartIfAvailable(savedCompany.summary.shortName, CompanyPropertyLabelMap.shortName, "shortName");
        addPropertyToCartIfAvailable(savedCompany.summary.fullName, CompanyPropertyLabelMap.fullName, "fullName");
        addPropertyToCartIfAvailable(savedCompany.summary.industry, CompanyPropertyLabelMap.industry, "industry");
        addPropertyToCartIfAvailable(savedCompany.summary.sector, CompanyPropertyLabelMap.sector, "sector");
        addPropertyToCartIfAvailable(savedCompany.summary.introduction, CompanyPropertyLabelMap.introduction, "introduction");
        addPropertyToCartIfAvailable(savedCompany.summary.countryOfFoundation, CompanyPropertyLabelMap.countryOfFoundation, "countryOfFoundation");
        addPropertyToCartIfAvailable(savedCompany.summary.entityRelationshipStatus, CompanyPropertyLabelMap.entityRelationshipStatus, "entityRelationshipStatus");
        addPropertyToCartIfAvailable(savedCompany.summary.formOfOrganization, CompanyPropertyLabelMap.formOfOrganization, "formOfOrganization");
        addPropertyToCartIfAvailable(savedCompany.summary.foundationYear, CompanyPropertyLabelMap.foundationYear, "foundationYear");
        addPropertyToCartIfAvailable(savedCompany.summary.identificationNumber, CompanyPropertyLabelMap.identificationNumber, "identificationNumber");
        addPropertyToCartIfAvailable(savedCompany.summary.numberOfEmployees, CompanyPropertyLabelMap.numberOfEmployees, "numberOfEmployees");
        addPropertyToCartIfAvailable(savedCompany.summary.mainAddressCountryId, CompanyPropertyLabelMap.mainAddressCountryId, "mainAddressCountryId");
        addPropertyToCartIfAvailable(savedCompany.summary.mainAddressCity, CompanyPropertyLabelMap.mainAddressCity, "mainAddressCity");
        addPropertyToCartIfAvailable(savedCompany.summary.mainAddressZip, CompanyPropertyLabelMap.mainAddressZip, "mainAddressZip");
        addPropertyToCartIfAvailable(savedCompany.summary.mainAddressStreet, CompanyPropertyLabelMap.mainAddressStreet, "mainAddressStreet");
        addPropertyToCartIfAvailable(savedCompany.summary.mainAddressDoor, CompanyPropertyLabelMap.mainAddressDoor, "mainAddressDoor");
        addPropertyToCartIfAvailable(savedCompany.summary.mainAddressFloor, CompanyPropertyLabelMap.mainAddressFloor, "mainAddressFloor");
        addPropertyToCartIfAvailable(savedCompany.summary.mainAddressHouseNumber, CompanyPropertyLabelMap.mainAddressHouseNumber, "mainAddressHouseNumber");

        addPropertyToCartIfAvailable(savedCompany.summary.businessAddressCountryId, CompanyPropertyLabelMap.businessAddressCountryId, "businessAddressCountryId");
        addPropertyToCartIfAvailable(savedCompany.summary.businessAddressCity, CompanyPropertyLabelMap.businessAddressCity, "businessAddressCity");
        addPropertyToCartIfAvailable(savedCompany.summary.businessAddressZip, CompanyPropertyLabelMap.businessAddressZip, "businessAddressZip");
        addPropertyToCartIfAvailable(savedCompany.summary.businessAddressStreet, CompanyPropertyLabelMap.businessAddressStreet, "businessAddressStreet");
        addPropertyToCartIfAvailable(savedCompany.summary.businessAddressDoor, CompanyPropertyLabelMap.businessAddressDoor, "businessAddressDoor");
        addPropertyToCartIfAvailable(savedCompany.summary.businessAddressFloor, CompanyPropertyLabelMap.businessAddressFloor, "businessAddressFloor");
        addPropertyToCartIfAvailable(savedCompany.summary.businessAddressHouseNumber, CompanyPropertyLabelMap.businessAddressHouseNumber, "businessAddressHouseNumber");


        addPropertyToCartIfAvailable(savedCompany.summary.otherContactDetailsWebsite, CompanyPropertyLabelMap.otherContactDetailsWebsite, "otherContactDetailsWebsite");
        addPropertyToCartIfAvailable(savedCompany.summary.otherContactDetailsContactEmailAddress, CompanyPropertyLabelMap.otherContactDetailsContactEmailAddress, "otherContactDetailsContactEmailAddress");
        addPropertyToCartIfAvailable(savedCompany.summary.otherContactDetailsMainTelephoneNumber, CompanyPropertyLabelMap.otherContactDetailsMainTelephoneNumber, "otherContactDetailsMainTelephoneNumber");
    };

    return (
        <>
            {numberOfAvailableProperies > 0 ?
                <div className="info-count">
                    <div className="h-stack h-stack-xs ai-c m-fd-c">
                        <p className="annotation">{numberOfAvailableProperies} {numberOfAvailableProperies === 1 ? "piece" : "pieces"} of information available on this page</p>
                        <button className="icon-chip icon-chip--label-left" style={brandColorStyle} onClick={() => revealAll()}
                        >
                            <span className="icon-chip__inner">
                                <span className="label">Reveal all for <svg className="icon icon--m "><use xlinkHref="#icon-gravity-credit" /></svg>
                                    {totalPriceOfProperties}</span>
                            </span>
                        </button>
                    </div>

                </div> : ""}
            <div className="source-info">
                <p className="annotation">Move your cursor on <img className="source" src={`/assets/img/source-up.png`} alt="Source" /> to show the source of the last upload</p>
            </div>
            <div className="source-info">
                <p className="annotation">View previously entered values on revealed fields by clicking on <img className="source" src={`/assets/img/source-down.png`} alt="Source" /></p>
            </div>
            <div className="stack stack-m">
                <div>
                    <div className="stack stack-s">
                        <span className="icon-header icon-header--header-right">
                            <span className="icon-header__inner">
                                <svg className="icon icon--l ">
                                    <use xlinkHref="#icon-general-details" />
                                </svg>
                                <span className="title title-s">Summary</span>
                            </span>
                        </span>
                        <ul className="data-grid">
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"shortName"}
                                labelName={CompanyPropertyLabelMap["shortName"]}
                                attribute={savedCompany.summary.shortName}
                                attributeName={"shortName"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"fullName"}
                                labelName={CompanyPropertyLabelMap["fullName"]}
                                attribute={savedCompany.summary.fullName}
                                attributeName={"fullName"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"industry"}
                                labelName={CompanyPropertyLabelMap["industry"]}
                                attribute={savedCompany.summary.industry}
                                attributeName={"industry"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"sector"}
                                labelName={CompanyPropertyLabelMap["sector"]}
                                attribute={savedCompany.summary.sector}
                                attributeName={"sector"} />
                        </ul>
                        {savedCompany.summary.introduction?.value != null ?
                            <ul className="data-grid full-width">
                                <CompanyPropertyComponent
                                    savedCompanyId={savedCompany.id}
                                    attributeType={AttributeType.Summary}
                                    key={"introduction"}
                                    labelName={CompanyPropertyLabelMap["introduction"]}
                                    attribute={savedCompany.summary.introduction}
                                    attributeName={"introduction"} />

                            </ul> : ""}
                        <ul className="data-grid">
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"countryOfFoundation"}
                                labelName={CompanyPropertyLabelMap["countryOfFoundation"]}
                                attribute={savedCompany.summary.countryOfFoundation}
                                attributeName={"countryOfFoundation"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"entityRelationshipStatus"}
                                labelName={CompanyPropertyLabelMap["entityRelationshipStatus"]}
                                attribute={savedCompany.summary.entityRelationshipStatus}
                                attributeName={"entityRelationshipStatus"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"formOfOrganization"}
                                labelName={CompanyPropertyLabelMap["formOfOrganization"]}
                                attribute={savedCompany.summary.formOfOrganization}
                                attributeName={"formOfOrganization"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"foundationYear"}
                                labelName={CompanyPropertyLabelMap["foundationYear"]}
                                attribute={savedCompany.summary.foundationYear}
                                attributeName={"foundationYear"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"identificationNumber"}
                                labelName={CompanyPropertyLabelMap["identificationNumber"]}
                                attribute={savedCompany.summary.identificationNumber}
                                attributeName={"identificationNumber"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"numberOfEmployees"}
                                labelName={CompanyPropertyLabelMap["numberOfEmployees"]}
                                attribute={savedCompany.summary.numberOfEmployees}
                                attributeName={"numberOfEmployees"} />
                        </ul>
                        <span className="icon-header icon-header--header-right">
                            <span className="icon-header__inner">
                                <svg className="icon icon--l ">
                                    <use xlinkHref="#icon-general-details" />
                                </svg>
                                <span className="title title-s">Main Address</span>
                            </span>
                        </span>
                        <ul className="data-grid">
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"mainAddressCountryId"}
                                labelName={CompanyPropertyLabelMap["mainAddressCountryId"]}
                                attribute={savedCompany.summary.mainAddressCountryId}
                                attributeName={"mainAddressCountryId"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"mainAddressCity"}
                                labelName={CompanyPropertyLabelMap["mainAddressCity"]}
                                attribute={savedCompany.summary.mainAddressCity}
                                attributeName={"mainAddressCity"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"mainAddressZip"}
                                labelName={CompanyPropertyLabelMap["mainAddressZip"]}
                                attribute={savedCompany.summary.mainAddressZip}
                                attributeName={"mainAddressZip"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"mainAddressStreet"}
                                labelName={CompanyPropertyLabelMap["mainAddressStreet"]}
                                attribute={savedCompany.summary.mainAddressStreet}
                                attributeName={"mainAddressStreet"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"mainAddressDoor"}
                                labelName={CompanyPropertyLabelMap["mainAddressDoor"]}
                                attribute={savedCompany.summary.mainAddressDoor}
                                attributeName={"mainAddressDoor"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"mainAddressFloor"}
                                labelName={CompanyPropertyLabelMap["mainAddressFloor"]}
                                attribute={savedCompany.summary.mainAddressFloor}
                                attributeName={"mainAddressFloor"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"mainAddressHouseNumber"}
                                labelName={CompanyPropertyLabelMap["mainAddressHouseNumber"]}
                                attribute={savedCompany.summary.mainAddressHouseNumber}
                                attributeName={"mainAddressHouseNumber"} />
                        </ul>
                        <span className="icon-header icon-header--header-right">
                            <span className="icon-header__inner">
                                <svg className="icon icon--l ">
                                    <use xlinkHref="#icon-general-details" />
                                </svg>
                                <span className="title title-s">Business Address</span>
                            </span>
                        </span>
                        <ul className="data-grid">
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"businessAddressCountryId"}
                                labelName={CompanyPropertyLabelMap["businessAddressCountryId"]}
                                attribute={savedCompany.summary.businessAddressCountryId}
                                attributeName={"businessAddressCountryId"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"businessAddressCity"}
                                labelName={CompanyPropertyLabelMap["businessAddressCity"]}
                                attribute={savedCompany.summary.businessAddressCity}
                                attributeName={"businessAddressCity"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"businessAddressZip"}
                                labelName={CompanyPropertyLabelMap["businessAddressZip"]}
                                attribute={savedCompany.summary.businessAddressZip}
                                attributeName={"businessAddressZip"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"businessAddressStreet"}
                                labelName={CompanyPropertyLabelMap["businessAddressStreet"]}
                                attribute={savedCompany.summary.businessAddressStreet}
                                attributeName={"businessAddressStreet"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"businessAddressDoor"}
                                labelName={CompanyPropertyLabelMap["businessAddressDoor"]}
                                attribute={savedCompany.summary.businessAddressDoor}
                                attributeName={"businessAddressDoor"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"businessAddressFloor"}
                                labelName={CompanyPropertyLabelMap["businessAddressFloor"]}
                                attribute={savedCompany.summary.businessAddressFloor}
                                attributeName={"businessAddressFloor"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"businessAddressHouseNumber"}
                                labelName={CompanyPropertyLabelMap["businessAddressHouseNumber"]}
                                attribute={savedCompany.summary.businessAddressHouseNumber}
                                attributeName={"businessAddressHouseNumber"} />
                        </ul>
                        <span className="icon-header icon-header--header-right">
                            <span className="icon-header__inner">
                                <svg className="icon icon--l ">
                                    <use xlinkHref="#icon-general-details" />
                                </svg>
                                <span className="title title-s">Other Contact Details</span>
                            </span>
                        </span>
                        <ul className="data-grid">
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"otherContactDetailsWebsite"}
                                labelName={CompanyPropertyLabelMap["otherContactDetailsWebsite"]}
                                attribute={savedCompany.summary.otherContactDetailsWebsite}
                                attributeName={"otherContactDetailsWebsite"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"otherContactDetailsContactEmailAddress"}
                                labelName={CompanyPropertyLabelMap["otherContactDetailsContactEmailAddress"]}
                                attribute={savedCompany.summary.otherContactDetailsContactEmailAddress}
                                attributeName={"otherContactDetailsContactEmailAddress"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                attributeType={AttributeType.Summary}
                                key={"otherContactDetailsMainTelephoneNumber"}
                                labelName={CompanyPropertyLabelMap["otherContactDetailsMainTelephoneNumber"]}
                                attribute={savedCompany.summary.otherContactDetailsMainTelephoneNumber}
                                attributeName={"otherContactDetailsMainTelephoneNumber"}
                                format={(val) => {
                                    if (val === '') return '';
                                    const splitted = val.split('-');
                                    return `+${splitted[0]}-${splitted[1]}-${splitted[2]}`;
                                }}
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
