export interface BillingModel {
  amount: number;
  creditValue: number;
  vatCharged?: number;
  vatRate?: number;
  vatNumber?: string;
  totalAmount?: number;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  email?: string;
  countryCode?: string;
  zip?: string;
  city?: string;
  state?: string;
  street?: string;
  houseNumber?: string;
  issuedType?: BillingIssuedType;
}

export enum BillingIssuedType {
  Personal = 1,
  Company = 2,
}

export interface ViesCheckVatResponse {
  countryCode: string;
  vatNumber: string;
  requestDate: string;
  name: string;
  address: string;
  isValid: boolean;
}

export interface UploadFinalizeCommand {
  amount: number;
  billing: BillingModel;
}

export interface IpDetails {
  city: string;
  continentCode: string;
  continentName: string;
  countryCode: string;
  countryName: string;
  ip: string;
  isEu: boolean;
}