import React, { Component } from 'react';
import './contact-us.scss';

let onButtonclick = (evt: any, email: string, subject: string) => {
  evt.preventDefault();

  window.location.href = `mailto:${email}?subject=${subject}`;
};

class ContactUsComponent extends Component {
  render() {
    return (
      <>
        <div className="section section--header">
          <div className="container">
            <div className="stack stack-m">
              <div className="grid">
                <div className="gi-12 t-gi-8 m-gi-4">
                  <div className="h-stack h-stack-xs jc-sb m-fd-c">
                    <div>
                      <h1 className="title title-l">Contact Us</h1>
                      <p className="title fw-sb">Gravity welcomes your comments and suggestions</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="section section--content">
          <div className="container">
            <div className="stack stack-m">
              <div className="grid">
                <div className="gi-12">
                </div>
              </div>
              <div className="grid">
                <div className="gi-12">
                  <div className="stack stack-xs jc-sb ai-c m-fd-cr">
                    <p className="title title-l">Contact Us</p>
                    <p>Select a Category</p>
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="gi-4 m-gi-0"></div>
                <div className="gi-4">
                  <div className="h-stack h-stack-xs jc-c m-fd-cr">
                    <button className="button button--primary contact-us-button" onClick={(e) => onButtonclick(e, 'techsupport@gravity.surf', 'Upload and Download')}>
                      <span className="button__inner">
                        <span>Upload and Download</span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="gi-4 m-gi-0"></div>
              </div>
              <div className="grid">
                <div className="gi-4 m-gi-0"></div>
                <div className="gi-4">
                  <div className="h-stack h-stack-xs jc-c m-fd-cr">
                    <button className="button button--primary contact-us-button" onClick={(e) => onButtonclick(e, 'finance@gravity.surf', 'Payment')}>
                      <span className="button__inner">
                        <span>Payment</span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="gi-4 m-gi-0"></div>
              </div>
              <div className="grid">
                <div className="gi-4  m-gi-0"></div>
                <div className="gi-4">
                  <div className="h-stack h-stack-xs jc-c m-fd-cr">
                    <button className="button button--primary contact-us-button" onClick={(e) => onButtonclick(e, 'finance@gravity.surf', 'Credit')}>
                      <span className="button__inner">
                        <span>Credit</span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="gi-4 m-gi-0"></div>
              </div>
              <div className="grid">
                <div className="gi-4  m-gi-0"></div>
                <div className="gi-4">
                  <div className="h-stack h-stack-xs jc-c m-fd-cr">
                    <button className="button button--primary contact-us-button" onClick={(e) => onButtonclick(e, 'techsupport@gravity.surf', 'Workspace')}>
                      <span className="button__inner">
                        <span>Workspace</span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="gi-4 m-gi-0"></div>
              </div>
              <div className="grid">
                <div className="gi-4  m-gi-0"></div>
                <div className="gi-4">
                  <div className="h-stack h-stack-xs jc-c m-fd-cr">
                    <button className="button button--primary contact-us-button" onClick={(e) => onButtonclick(e, 'techsupport@gravity.surf', 'Notifications and Errors')}>
                      <span className="button__inner">
                        <span>Notifications and Errors</span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="gi-4 m-gi-0"></div>
              </div>
              <div className="grid">
                <div className="gi-4  m-gi-0"></div>
                <div className="gi-4">
                  <div className="h-stack h-stack-xs jc-c m-fd-cr">
                    <button className="button button--primary contact-us-button" onClick={(e) => onButtonclick(e, 'customerservice@gravity.surf', 'Suggestions')}>
                      <span className="button__inner">
                        <span>Suggestions</span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="gi-4 m-gi-0"></div>
              </div>
              <div className="grid">
                <div className="gi-4  m-gi-0"></div>
                <div className="gi-4">
                  <div className="h-stack h-stack-xs jc-c m-fd-cr">
                    <button className="button button--primary contact-us-button" onClick={(e) => onButtonclick(e, 'customerservice@gravity.surf', 'Other')}>
                      <span className="button__inner">
                        <span>Other</span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="gi-4 m-gi-0"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactUsComponent;