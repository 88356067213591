import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import React from 'react';
import { useDispatch } from 'react-redux';
import { popupSetModelAction } from '../popup/popup.actions';
import { popupService } from '../popup/popup.service';
import { PopupDialogType } from '../popup/popup.models';

export interface InvitationStateProps {
  isLoading: boolean;
}

export interface InvitationDispatchProps {
  invite(email: string): Promise<number>;
}

const InvitationComponent: React.FC<InvitationStateProps & InvitationDispatchProps & RouteComponentProps> =
  ({ isLoading, invite, history }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [isTouched, setIsTouched] = useState(false);

    if (isLoading) {
      return <FullPageLoadingComponent />
    }

    const validateEmail = (email: string) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email.toLowerCase());
    }

    const isValid = (isTouched && (email !== '') && (validateEmail(email))) || (!isTouched);
    const validationError = email === '' ? 'E-mail address cannot be empty' : 'Not a valid e-mail address';
    const errorProps = !isValid ? { error: 'error' } : {};


    const onInvite = async (evt: any) => {
      evt.preventDefault();

      if (email !== '' && validateEmail(email)) {
        const response = await invite(email);
        if (response === 204) {
          dispatch(
            popupSetModelAction({
              title: "Success",
              body: ('Invitation successful'),
              type: PopupDialogType.Confirm,
              okButtonText: "Close",
              okButtonAction: async (evt) => {
                evt.preventDefault();
                evt.stopPropagation();

                popupService.hidePopup();

                history.push('/');
              },
            })
          );
          popupService.showPopup();

        } else if (response === 409) {
          dispatch(
            popupSetModelAction({
              title: "Invitation failed",
              body: ('This e-mail address is already registered in Gravity. Please add a new e-mail address.'),
              type: PopupDialogType.Confirm,
              okButtonText: "Close",
              okButtonAction: async (evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                setEmail('');
                popupService.hidePopup();
              },
            })
          );
          popupService.showPopup();
        } else {
          dispatch(
            popupSetModelAction({
              title: "Invitation failed",
              body: ('An error occured, please try again in later.'),
              type: PopupDialogType.Confirm,
              okButtonText: "Close",
              okButtonAction: async (evt) => {
                evt.preventDefault();
                evt.stopPropagation();

                popupService.hidePopup();
              },
            })
          );
          popupService.showPopup();
        }
      } else {
        setIsTouched(true);
      }
    }

    return (
      <>
        <div className="section section--header">
          <div className="container">
            <div className="stack stack-m">
              <div className="grid">
                <div className="gi-12 t-gi-8 m-gi-4">
                  <div className="h-stack h-stack-xs jc-sb m-fd-c">
                    <div>
                      <h1 className="title title-l">Invite a colleague</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="section section--content">
          <div className="container">
            <div className="stack stack-m">
              <nav className="tab-navigation ">
              </nav>
              <div className="tab-content">
                <div className="container">
                  <div className="stack stack-m">
                    <div className="grid">
                      <div className="gi-12">
                      </div>
                    </div>
                    <div className="grid">
                      <div className="gi-4"></div>
                      <div className="gi-4 col col--left">
                        <form className="stack stack-m">
                          <header className="col-header">
                            <div className="stack stack-xxs">
                            </div>
                          </header>
                          <div>
                            <div className="stack stack-xs">
                              <div>
                                <div className="field field--input" {...errorProps}>
                                  <input className="field__field"
                                    value={email}
                                    onChange={evt => setEmail(evt.target.value)}
                                    name="firstName" id="firstName" placeholder="E-mail address" />
                                </div>
                              </div>
                              {!isValid ?
                                <div className="error">
                                  {validationError}
                                </div> : null}
                            </div>
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            <button className="button" onClick={(evt) => onInvite(evt)}>
                              <span className="button__inner">
                                <span>Invite</span>
                              </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div >
                  </div >
                </div >
              </div >
            </div >
          </div >
        </div >
      </>
    );
  };

export const InvitationPage = withRouter(InvitationComponent);