import { SavedCompaniesActions, SavedCompaniesActionTypes } from './saved-companies.actions';
import { SavedCompanySearch, SavedCompaniesListModel, SearchResultsTabsHeader } from './saved-companies.models';

export interface SavedCompaniesState {
    isSavedCompaniesLoading: boolean;
    isSavedSearchesLoading: boolean;
    headers?: SearchResultsTabsHeader;
    savedCompaniesList?: SavedCompaniesListModel;
    uploadedCompanyList?: SavedCompaniesListModel;
    savedSearchesList?: SavedCompanySearch[];
    isUploadedCompaniesLoading: boolean;
    sourceData?: any;
    page: number;
    pageSize: number;
}

const initialState: SavedCompaniesState = {
    isSavedCompaniesLoading: false,
    isSavedSearchesLoading: false,
    savedCompaniesList: undefined,
    headers: undefined,
    isUploadedCompaniesLoading: false,
    uploadedCompanyList: undefined,
    savedSearchesList: undefined,
    sourceData: undefined,
    page: 0,
    pageSize: 10
};

export const savedCompaniesReducer = (state: SavedCompaniesState = initialState, action: SavedCompaniesActions): SavedCompaniesState => {
    switch (action.type) {
        case SavedCompaniesActionTypes.SAVED_COMPANIES_LOADING:
            return {
                ...state,
                isSavedCompaniesLoading: true,
                savedCompaniesList: undefined
            };
        case SavedCompaniesActionTypes.SEARCH_RESULTS_TABS_HEADER_LOADED_SUCCESS:
            return {
                ...state,
                isSavedCompaniesLoading: false,
                headers: action.payload
            };
        case SavedCompaniesActionTypes.SAVED_COMPANIES_LOADING_SUCCESS:
            return {
                ...state,
                isSavedCompaniesLoading: false,
                savedCompaniesList: action.payload
            };
        case SavedCompaniesActionTypes.SAVED_COMPANIES_LOADING_ERROR:
            return {
                ...state,
                isSavedCompaniesLoading: false,
                savedCompaniesList: undefined
            };
        case SavedCompaniesActionTypes.SAVED_SEARCH_EXPORT_LOADING:
            return {
                ...state,
                isSavedSearchesLoading: true
            };
        case SavedCompaniesActionTypes.SAVED_SEARCH_EXPORT_LOADED:
            return {
                ...state,
                isSavedSearchesLoading: false
            };
        case SavedCompaniesActionTypes.UPLOADED_COMPANIES_LOADING:
            return {
                ...state,
                isUploadedCompaniesLoading: true,
                uploadedCompanyList: undefined
            };
        case SavedCompaniesActionTypes.UPLOADED_COMPANIES_LOADING_SUCCESS:
            return {
                ...state,
                isUploadedCompaniesLoading: false,
                uploadedCompanyList: action.payload
            };
        case SavedCompaniesActionTypes.UPLOADED_COMPANIES_LOADING_ERROR:
            return {
                ...state,
                isUploadedCompaniesLoading: false,
                uploadedCompanyList: undefined
            };
        case SavedCompaniesActionTypes.SAVED_SEARCHES_LOADING:
            return {
                ...state,
                isSavedSearchesLoading: true,
                savedSearchesList: undefined
            };
        case SavedCompaniesActionTypes.SAVED_SEARCHES_LOADING_SUCCESS:
            return {
                ...state,
                isSavedSearchesLoading: false,
                savedSearchesList: action.payload
            };
        case SavedCompaniesActionTypes.SAVED_SEARCHES_LOADING_ERROR:
            return {
                ...state,
                isSavedSearchesLoading: false,
                savedSearchesList: undefined
            };
        case SavedCompaniesActionTypes.SOURCE_CLEAR:
        case SavedCompaniesActionTypes.SOURCE_LOADING:
            return {
                ...state,
                sourceData: undefined,
            };
        case SavedCompaniesActionTypes.SOURCE_LOADED_SUCCESS:
            return {
                ...state,
                sourceData: action.payload,
            };
        case SavedCompaniesActionTypes.PAGE_NEXT:
            return {
                ...state,
                page: action.payload >= state.page + 1 ? state.page + 1 : state.page
            };
        case SavedCompaniesActionTypes.PAGE_PREVIOUS:
            return {
                ...state,
                page: state.page - 1 === 0 ? 1 : state.page - 1
            };
        case SavedCompaniesActionTypes.SET_PAGE:
            return {
                ...state,
                page: action.payload,
            };
        case SavedCompaniesActionTypes.RESET_PAGE:
            return {
                ...state,
                page: 1
            };
        case SavedCompaniesActionTypes.SET_PAGESIZE:
            return {
                ...state,
                pageSize: action.payload,
            };
        default:
            return state;
    }
};