import React from "react";
import Header from "./header/Header.container";
import MobilMenu from "./header/MobilMenu.container";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./configureStore";
import { useEffect } from "react";
import { authenticationService } from "./auth/authentication.service";
import {
  authenticationLoadingAction,
  authenticationErrorAction,
  authenticationSuccessAction,
  changeUseGravityAsAction,
} from "./auth/authentication.actions";
import { Footer } from "./Footer.component";
import Main from "./Main.container";
import { SvgCollection } from "./SvgCollection.component";
import "./stylesheets/main.scss";
import "./app.scss";
import PopupDialog from "./popup/PopupDialog.container";
import { USE_WORKSPACE_AS_TOKEN } from "./auth/authentication.models";
import { registerAxiosInterceptor } from "./axios";
import { workspaceService } from './workspace-settings/workspace.service';
import { workspaceLoadedSuccessfulAction } from './workspace-settings/workspace.actions';
import { resetCurrentPageAction } from './saved-companies/saved-companies.actions';
import { accountService } from './account-settings/account.service';
import { accountLoadedSuccessfulAction } from './account-settings/account.actions';

export const App: React.FC = () => {
  const store = configureStore();

  useEffect(() => {
    const storedUseGravityAs = localStorage.getItem(USE_WORKSPACE_AS_TOKEN);
    const useGravityAs: number | undefined =
      storedUseGravityAs === null || storedUseGravityAs === ""
        ? undefined
        : Number(storedUseGravityAs);

    store.dispatch(changeUseGravityAsAction(useGravityAs));
    store.dispatch(resetCurrentPageAction());
    store.dispatch(authenticationLoadingAction());

    registerAxiosInterceptor(store);

    if (useGravityAs) {
      workspaceService
        .getWorkspaceSettings(useGravityAs)
        .then(workspace => {
          store.dispatch(workspaceLoadedSuccessfulAction(workspace));
        })
    } else {
      accountService.getAccountSettings()
        .then(profile => {
          store.dispatch(accountLoadedSuccessfulAction(profile));
        });
    }

    authenticationService
      .getUser()
      .then((user) => {
        store.dispatch(authenticationSuccessAction(user));
        
        if (useGravityAs !== undefined) {
          const hasSelectedWorkspace =
            user.workspaces &&
            user.workspaces.find((w) => w.id === useGravityAs);

          if (!hasSelectedWorkspace) {
            store.dispatch(changeUseGravityAsAction(undefined));
          }
        }
      })
      .catch(() => {
        store.dispatch(authenticationErrorAction(undefined));
      });
  });

  return (
    <Provider store={store}>
      <Router>
        <SvgCollection />
        <Header />
        <MobilMenu />
        <Main />
        <Footer />
        <PopupDialog />
      </Router>
    </Provider>
  );
};
