import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { LinkedInRegisterDispatchProps, LinkedInRegisterPage } from './LinkedInRegister.component';
import { AppState } from '../../root.reducer';
import { SocialRegisterFields } from './social-register.models';
import { authenticationService } from '../authentication.service';
import { RouteComponentProps } from 'react-router-dom';
import { LoginResponse } from '../authentication.models';
import { AppRoutes } from '../../routes';
import { authenticationLoadingAction } from '../authentication.actions';

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): LinkedInRegisterDispatchProps => ({
  register: async (model: SocialRegisterFields) => {
    dispatch(authenticationLoadingAction());
    const response = await authenticationService.registerLinkedin(model);

    const loginResponse = response.data as LoginResponse;

    if (response.status === 200) {
      if (loginResponse.hasWorkspaceInvites) {
        window.location.href = `${AppRoutes.WorkspaceResponse}`;
      } else {
        window.location.href = '/';
      }
    }
  }
});

export default connect<{}, LinkedInRegisterDispatchProps, RouteComponentProps, AppState>(
  null, mapDispatchToProps
)(LinkedInRegisterPage);
