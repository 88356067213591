import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { ManagementContactActiationComponentDispatchProps, ManagementContactActiationComponentStateProps } from './ManagementContactActiationPage.component';
import { AppState } from '../root.reducer';
import { isManagementContactActiationLoadingSelector, managementContactActivationSelector } from './activation.selector';
import { activationLoadedSuccessfulAction, activationLoadingAction } from './activation.actions';
import { activationService } from './activation.service';
import { ManagementContactActivationPage } from './ManagementContactActiationPage.component';
import { popupSetModelAction } from '../popup/popup.actions';
import { PopupDialogType } from '../popup/popup.models';
import { popupService } from '../popup/popup.service';

const mapStateToProps = (state: AppState): ManagementContactActiationComponentStateProps => ({
  isLoading: isManagementContactActiationLoadingSelector(state),
  activation: managementContactActivationSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): ManagementContactActiationComponentDispatchProps => ({
  getActivation: async (code: string) => {
    dispatch(activationLoadingAction());
    const response = await activationService.getActivation(code.trimEnd().replace(/\//g, ''))

    if (response.status === 200) {
      dispatch(activationLoadedSuccessfulAction(response.data));
    } else {
      history.push('/');
    }
  },

  response: async (code: string, accept: boolean) => {
    dispatch(activationLoadingAction());
    const response = await activationService.response(code, accept);

    if (response.status === 200 || response.status === 204) {
      const text = accept ? 'Managment contact successfully activated!' : 'Management contact successfully removed from Gravity!';
      dispatch(popupSetModelAction({
        title: 'Management contact activation',
        text: text,
        type: PopupDialogType.Dialog,
        okButtonText: 'Go to search results',
        okButtonAction: async (evt) => {
          evt.preventDefault();
          evt.stopPropagation();

          popupService.hidePopup();
          history.push('/saved-companies');
        }
      }));
      popupService.showPopup();
    } else {

    }
  }
});

export default withRouter(
  connect<ManagementContactActiationComponentStateProps, ManagementContactActiationComponentDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps, mapDispatchToProps
  )(ManagementContactActivationPage)
);
