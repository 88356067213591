import React from 'react';
import LoaderSVG from './gravity-loader-positive.svg';

export const FullPageLoadingComponent = () => (
    <div className="fullpage-loader">
        <div>
            <div>
                <img src={LoaderSVG} alt="Loading" height={120} />
            </div>
        </div>
    </div>
);