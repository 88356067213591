import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { AppState } from '../root.reducer';
import { CheckoutComponent, CheckoutDispatchProps, CheckoutStateProps } from './Checkout.component';
import { billingClientTokenSelector, billingDataSelector, isBillingCheckoutLoadingSelector, paypalClientIdSelector } from './billing.selector';
import { gravityUseAsSelector } from '../auth/authentication.selectors';
import { connect } from 'react-redux';
import { BillingIssuedType, BillingModel, UploadFinalizeCommand } from './billing.model';
import { billingService } from './billing.service';
import { billingCheckoutLoadingAction, billingPaypalClientLoadedAction } from './billing.actions';
import { authenticationService } from '../auth/authentication.service';
import { authenticationSuccessAction } from '../auth/authentication.actions';
import { popupSetModelAction } from '../popup/popup.actions';
import { PopupDialogType } from '../popup/popup.models';
import { popupService } from '../popup/popup.service';


const mapStateToProps = (state: AppState): CheckoutStateProps => ({
  useGravityAs: gravityUseAsSelector(state),
  clientToken: billingClientTokenSelector(state),
  billing: billingDataSelector(state),
  isCheckoutLoading: isBillingCheckoutLoadingSelector(state),
  paypalClientId: paypalClientIdSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): CheckoutDispatchProps => ({
  getPaypalClientId: async() => {
    dispatch(billingCheckoutLoadingAction());

    const clientId = await billingService.getPaypalClientId();
    dispatch(billingPaypalClientLoadedAction(clientId));
  },
  createOrder: (amount: number) => {
    return billingService.createOrder(amount);
  },
  startCheckout: () => {    
    dispatch(billingCheckoutLoadingAction());
  },
  checkout: async (billing: BillingModel, useGravityAs?: number) => {
    dispatch(billingCheckoutLoadingAction());
    const command: UploadFinalizeCommand = {
      billing: billing,
      amount: billing.totalAmount!,
    };

    if (useGravityAs) {
      const result = await billingService.workspaceUploadFinalize(command);

      const user = await authenticationService.getUser();
      dispatch(authenticationSuccessAction(user));
      if (result.status === 200) {
        history.push(`/workspace/${useGravityAs}`);
      } else {
        dispatch(popupSetModelAction({
          title: 'Something went wrong, please try again',
          type: PopupDialogType.Dialog,
          okButtonText: 'Yes',
          okButtonAction: async (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            popupService.hidePopup();
            history.push(`/workspace/${useGravityAs}`);
          }
        }));
        popupService.showPopup();
      }
    } else {
      const result = await billingService.accountUploadFinalize(command);
      const user = await authenticationService.getUser();
      dispatch(authenticationSuccessAction(user));
      if (result.status === 200) {
        history.push('/account');
      } else {
        dispatch(popupSetModelAction({
          title: 'Something went wrong, please try again',
          type: PopupDialogType.Dialog,
          okButtonText: 'Yes',
          okButtonAction: async (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            popupService.hidePopup();
            history.push('/account');
          }
        }));
        popupService.showPopup();
      }
    }
  }
});

export default withRouter(
  connect<CheckoutStateProps, CheckoutDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps, mapDispatchToProps
  )(CheckoutComponent)
);
