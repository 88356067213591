import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SearchCountResult } from '../search.models';
import { User } from '../../auth/authentication.models';
import { WorkspaceModel } from '../../workspace-settings/workspace.models';
import { Utils } from '../../utils/utils';

interface SearchResultCountPayComponentProps {
    countResult: SearchCountResult;
    user?: User;
    workspace?: WorkspaceModel;
    useGravityAs?: number;
    onPay(useGravityAs?: number): void;
}

const SearchResultCountPay: React.FC<SearchResultCountPayComponentProps & RouteComponentProps> = ({ history, user, workspace, countResult, useGravityAs, onPay }) => {
    const goToAdvancedSearch = () => {
        history.push('/search/advanced');
    };
    
    const [creditCount] = useState(useGravityAs ? workspace!.creditCount : user!.creditCount);
    const [currentCreditCount] = useState(useGravityAs ? countResult.workspaceCreditCount! : countResult.userCreditCount!);

    const emptyResult = countResult.resultCount === 0;
    const resultCount = emptyResult ?
        (<h3 className="title title-s">We didn't find any companies with the specified search criteries</h3>) :
        (<h3 className="title title-s">We found {Utils.formatNumber(countResult.resultCount)} companies</h3>);

    
    const creditLabel = currentCreditCount > creditCount ?
        <p className="annotation">
            You have {Utils.formatNumber(creditCount)} credits, but the search costs {Utils.formatNumber(currentCreditCount)} credits. <br/>
            {!useGravityAs ?
            `You don't have enough credit, please add ${Utils.formatNumber(currentCreditCount - creditCount)} credits to your account balance.` :
            "You don't have enough credit, contact your workspace administrator to add credits to the workspace balance."}
        </p>
        :
        <p className="annotation">You have {Utils.formatNumber(creditCount)} credits</p>;

    return (
        <div className="popup" >
            <div className="popup__content">
                <div className="container">
                    <div className="grid">
                        <div className="popup__popup gi-4 gi-s-5 gi-e-9">
                            <div className="stack stack-m">
                                <div className="top">
                                    <div className="stack stack-xs">
                                        {
                                            resultCount
                                        }
                                        <p>
                                            <svg className="popup__credits" viewBox="0 0 60 53" xmlns="http://www.w3.org/2000/svg">
                                                <g fill="#000421" fillRule="evenodd">
                                                    <path d="M6.492 17.258c5.105-2.49 11.26-.37 13.75 4.734 2.49 5.105.37 11.26-4.734 13.75-5.105 2.49-11.26.37-13.75-4.734-2.49-5.105-.37-11.26 4.734-13.75zm.634 8.544c.197 1.877 1.566 3.018 3.265 2.84.713-.075 1.304-.39 1.539-.787l.047-.005.008.172.15 1.445-2.53.266.188 1.794 4.562-.48-.938-8.92-1.888.198.024.237c.018.167.041.272.062.354l-.048.005c-.432-.459-1.104-.676-1.9-.593-1.663.175-2.74 1.573-2.541 3.474zm3.205-1.73c.76-.08 1.351.398 1.437 1.218.086.82-.39 1.422-1.151 1.502-.748.079-1.34-.4-1.427-1.231-.086-.82.393-1.41 1.141-1.489z" />
                                                    <path d="M27.917 22.392c8.41.441 14.87 7.616 14.429 16.025-.441 8.41-7.615 14.87-16.025 14.429-8.41-.441-14.87-7.615-14.429-16.025.441-8.41 7.616-14.87 16.025-14.429zm-5.32 11.537c-1.094 2.576-.139 5.04 2.193 6.03.978.415 1.97.432 2.56.086l.066.028-.114.228-.844 1.982-3.472-1.474-1.045 2.462 6.26 2.657 5.197-12.243-2.592-1.1-.139.325a3.543 3.543 0 0 0-.175.503l-.065-.027c-.23-.907-.945-1.672-2.037-2.135-2.283-.969-4.686.07-5.793 2.678zm5.4.06c1.043.443 1.465 1.488.988 2.613-.478 1.125-1.53 1.564-2.573 1.12-1.027-.435-1.45-1.48-.965-2.622.478-1.124 1.523-1.547 2.55-1.11z" />
                                                    <path d="M37.239 1.847c8.61-2.965 17.993 1.611 20.958 10.222 2.965 8.61-1.612 17.993-10.222 20.958S29.98 31.415 27.016 22.805c-2.964-8.61 1.612-17.994 10.223-20.958zm-.661 13.722c-.053 3.026 1.902 5.11 4.64 5.157 1.15.02 2.153-.365 2.603-.95h.077l-.022.276-.043 2.329-4.078-.071-.05 2.891 7.352.129.251-14.382-3.044-.053-.007.383c-.005.268.012.441.028.575l-.076-.001c-.599-.815-1.625-1.293-2.908-1.315-2.681-.047-4.67 1.968-4.723 5.032zm5.438-2.127c1.226.021 2.073.898 2.05 2.22-.023 1.32-.9 2.187-2.126 2.165-1.207-.02-2.053-.898-2.03-2.238.023-1.321.9-2.168 2.106-2.147z" />
                                                </g>
                                            </svg>
                                        </p>
                                        {
                                            !emptyResult ?
                                            <>
                                                <p>Reveal results and save them for later</p>
                                                <p>
                                                    <button className="button"
                                                        disabled={creditCount < currentCreditCount}
                                                        onClick={() => onPay(useGravityAs)}>
                                                        <span className="button__inner">
                                                            <span>Pay {Utils.formatNumber(currentCreditCount)} credits to reveal</span>
                                                        </span>
                                                    </button>
                                                </p>
                                                {creditLabel}
                                            </> : ""
                                        }
                                    </div>
                                </div>
                                <hr />
                                <div className="bottom">
                                    <div className="stack stack-xs">
                                        <p>{countResult.resultCount === 0 ? "Revise" : "Narrow"} your search</p>
                                        <p>
                                            <button className="button button--secondary" onClick={() => goToAdvancedSearch()}>
                                                <span className="button__inner">
                                                    <span>Advanced Search</span>
                                                </span>
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const SearchResultCountPayComponent = withRouter(SearchResultCountPay);
