import React, { useEffect } from "react";
import SearchFields from '../SearchFields.container';
import { SavedSearchResultsComponent } from "./SavedSearchResults.component";
import { FullPageLoadingComponent } from '../../FullpageLoading.component';
import { SavedCompanyListModel } from '../../saved-company/saved-company.models';
import { RouteComponentProps } from "react-router-dom";

export interface SavedSearchPageStateProps {
    isSearching: boolean;
    isLoadingSavedSearch: boolean;
    savedSearch?: SavedCompanyListModel;
    savedSearchId: number;
    useGravityAs?: number;
    page: number;
}

export interface SavedSearchPageDispatchProps {
    loadSavedSearch: (id: number, page: number, useGravityAs?: number) => void;
    nextPage(maxPage: number): void;
    previousPage(): void;
    resetCurrentPage(): void;
}

export const SavedSearchPageComponent: React.FC<SavedSearchPageStateProps & SavedSearchPageDispatchProps & RouteComponentProps> =
    ({ savedSearchId, isLoadingSavedSearch, page, nextPage, previousPage, resetCurrentPage, isSearching, savedSearch, loadSavedSearch, useGravityAs, history }) => {
        useEffect(() => {
            loadSavedSearch(savedSearchId, page, useGravityAs);
        }, [loadSavedSearch, savedSearchId, page, useGravityAs]);

        const goToUploadPage = () => {
            history.push('/upload-company');
        };

        const renderSearchResults = () => {
            if (isLoadingSavedSearch || isSearching) {
                return <FullPageLoadingComponent />;
            }

            if (savedSearch) {
                return <SavedSearchResultsComponent
                    savedSearchId={savedSearchId}
                    page={page}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    resetCurrentPage={resetCurrentPage}
                    results={savedSearch} 
                    loadSavedSearch={() => loadSavedSearch(savedSearchId, page, useGravityAs)}
                />;
            }

            return <p>Error.</p>;
        };

        const renderHeader = () => {
            return (
                <div className="section section--header">
                    <div className="container">
                        <div className="stack stack-m">
                            <div className="grid">
                                <div className="gi-12 t-gi-8 m-gi-4">
                                    <div className="h-stack h-stack-xs jc-sb m-fd-c">
                                        <div>
                                            <h1 className="title title-l">Search results for {savedSearch?.name}</h1>
                                            <p className="title fw-sb">Saved search results</p>
                                        </div>
                                        <div>
                                            <button className="button" onClick={() => goToUploadPage()}>
                                                <span className="button__inner">
                                                    <span>Upload a Company</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        };

        return (
            <>
                {renderHeader()}
                {renderSearchResults()}
            </>
        );
    };
