import { connect } from 'react-redux';
import { AppState } from '../root.reducer';
import { Dispatch } from 'redux';
import { HomePageHeaderComponent, HomePageHeaderComponentDispatchProps, HomePageHeaderComponentStateProps } from './HomePageHeader.component';

const mapStateToProps = (state: AppState): HomePageHeaderComponentStateProps => ({
});

const mapDispatchToProps = (dispatch: Dispatch): HomePageHeaderComponentDispatchProps => ({
});

export default connect<HomePageHeaderComponentStateProps, HomePageHeaderComponentDispatchProps, {}, AppState>(
  mapStateToProps, mapDispatchToProps
)(HomePageHeaderComponent);
