import { AccountActions, AccountActionTypes } from './account.actions';
import { GetAccountQueryModel } from './account.models';

export interface AuthState {
  isLoading: boolean;
  account?: GetAccountQueryModel;
}

const initialState: AuthState = {
  isLoading: false,
  account: undefined,
};

export const accountReducer = (state: AuthState = initialState, action: AccountActions): AuthState => {
  switch (action.type) {
    case AccountActionTypes.ACCOUNT_LOADING:
      return {
        ...state,
        isLoading: true,
        account: undefined,
      };
    case AccountActionTypes.ACCOUNT_LOADING_FINISHED:
      return {
        ...state,
        isLoading: false,
      };
    case AccountActionTypes.ACCOUNT_LOADED_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        account: action.payload
      };
    case AccountActionTypes.ACCOUNT_SAVED_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        account: undefined,
      };
    default:
      return state;
  }
};