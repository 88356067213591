export const AppRoutes = {
    AdvancedSearch: "/search/advanced",
    Search: "/search",
    UploadCompany: "/upload-company",
    EditCompany: (id?: string) => `/edit-company/${id === undefined ? ':id' : id}`,
    SavedCompanies: "/saved-companies",
    SavedCompany: "/company/:id",
    SavedSearch: "/saved-search/:id",
    SavedSearchTable: "/saved-search-table/:id",
    AccountSettings: "/account",
    WorkspaceDeleteConfirm: "/account/workspace-delete-confirm",
    WorkspaceAcceptOwnership: "/workspace/:id/accept-ownership",
    MarkedForDeleted: '/user-deleted',
    CreditHistory: "/credit-history",
    ContactUs: "/contact-us",
    ManagementContactActivation: "/management-contact-activation",
    HowItWorks: "/how-it-works",
    Faq: "/faq",
    TermsAndConditions: "/terms-and-conditions",
    Invite: "/invite",
    PrivacyPolicy: "/privacy-policy",
    WorkspaceForm: "/workspace-create",
    WorkspaceSettings: (id?: string) => `/workspace/${id === undefined ? ':id' : id}`,
    WorkspaceResponse: "/workspace-response",
    GoogleRegister: "/google/register",
    GoogleConfirm: "/google/confirm",
    LinkedInRegier: "/linkedin/register",
    LinkedInConfirm: "/linkedin/confirm",
    Register: "/register",
    ResetPassword: "/resetpassword",
    ResetPasswordConfirm: "/resetpassword-confirm",
    ForgotPassword: "/forgotpassword",
    ForgotPasswordConfirm: "/forgotpassword-confirm",
    BillingInformation: "/billing-information",
    Checkout: "/checkout",
    Root: "/"
};
