import { connect } from 'react-redux';
import { AppState } from '../root.reducer';
import { Dispatch } from 'redux';
import { HomePageSearchComponent, HomePageSearchComponentDispatchProps, HomePageSearchComponentStateProps } from './HomePageSearch.component';
import { searchCompanyAction as searchCompaniesAction, searchSuccessAction } from '../search/search.actions';
import { searchService } from '../search/search.service';
import { companiesSelector, countriesSelector } from '../meta/meta.selectors';
import { SimpleSearchFields } from '../search/search.models';

const mapStateToProps = (state: AppState): HomePageSearchComponentStateProps => ({
    countries: countriesSelector(state),
    companyCount: companiesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): HomePageSearchComponentDispatchProps => ({
    searchCompanies: async (filters: SimpleSearchFields) => {
        dispatch(searchCompaniesAction(filters));

        const result = await searchService.searchCompanies(filters);

        dispatch(searchSuccessAction(result));
    }
});

export default connect<HomePageSearchComponentStateProps, HomePageSearchComponentDispatchProps, {}, AppState>(
    mapStateToProps, mapDispatchToProps
)(HomePageSearchComponent);
