import { AxiosInstance, AxiosResponse } from 'axios';
import { WorkspaceModel, SaveWorkspaceModel, UpdateWorkspaceModel, InviteUserToWorkspaceModel, InvitedWorkspaceModel, WorkspaceDeleteConfirmationModel } from './workspace.models';
import { axiosInstance } from '../axios';
import { CreditHistoryModel } from '../credit-history/credit-history.models';

class WorkspaceService {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axiosInstance;
  }

  async getInvitedWorkspaceSettings(): Promise<AxiosResponse<InvitedWorkspaceModel[]>> {
    const response = await this.axios({
      url: '/api/workspaces/invited',
      method: 'GET'
    });

    return response;
  }

  async getWorkspaceSettings(workspaceId: number): Promise<WorkspaceModel> {
    const response = await this.axios({
      url: `/api/workspaces/${workspaceId}`,
      method: 'GET'
    });

    return response.data;
  }

  async saveWorkspace(workspace: SaveWorkspaceModel): Promise<any> {
    const response = await this.axios({
      url: '/api/workspaces',
      method: 'POST',
      data: workspace
    });

    return response;
  }

  async updateWorkspace(workspace: UpdateWorkspaceModel): Promise<number> {
    const response = await this.axios({
      url: '/api/workspaces',
      method: 'PUT',
      data: workspace
    });

    return response.status;
  }

  async deleteWorkspace(workspaceId: number): Promise<number> {
    const response = await this.axios({
      url: `/api/workspaces/${workspaceId}`,
      method: 'DELETE'
    }).catch(err => {
      return err.status;
    });

    return response.status;
  }

  async cancelMembership(): Promise<number> {
    const response = await this.axios({
      url: `/api/workspaces/cancel-membership`,
      method: 'POST'
    }).catch(err => {
      return err.status;
    });

    return response.status;
  }

  async inviteUser(workspaceId: number, user: InviteUserToWorkspaceModel): Promise<number> {
    const response = await this.axios({
      url: `/api/workspaces/${workspaceId}/invite`,
      method: 'POST',
      data: user
    });

    return response.status;
  }

  async removeTeamMember(workspaceId: number, email: string): Promise<number> {
    const response = await this.axios({
      url: `/api/workspaces/${workspaceId}/user?email=${email}`,
      method: 'DELETE'
    });

    return response.status;
  }

  async removeInvite(workspaceId: number, email: string): Promise<number> {
    const response = await this.axios({
      url: `/api/workspaces/${workspaceId}/invite?email=${email}`,
      method: 'DELETE'
    });

    return response.status;
  }

  async startUploadBalanceSession(workspaceId: number, amount: number, creditValue: number): Promise<string> {
    const response = await this.axios({
      url: `/api/workspaces/${workspaceId}/upload-credit?amount=${amount}&creditValue=${creditValue}`,
      method: 'POST'
    });

    return response.data;
  }

  async getCreditHistory(): Promise<CreditHistoryModel> {
    const response = await this.axios({
      url: `/api/workspaces/credit-history`,
      method: 'GET',
    });

    return response.data;
  }

  async getDeleteConfirmations(): Promise<AxiosResponse<WorkspaceDeleteConfirmationModel[]>> {
    const response = await this.axios({
      url: '/api/workspaces/delete-confirmation',
      method: 'GET'
    });

    return response;
  }

  async getAcceptOwnership(workspaceId: number): Promise<AxiosResponse<WorkspaceModel>> {
    const response = await this.axios({
      url: '/api/workspaces/accept-ownership/' + workspaceId,
      method: 'GET'
    });

    return response;
  }

  async acceptOwnership(workspaceId: number): Promise<AxiosResponse<boolean>> {
    const response = await this.axios({
      url: '/api/workspaces/accept-ownership/' + workspaceId,
      method: 'POST'
    });

    return response;
  }

  async isWorkspaceAdmin(): Promise<boolean> {
    const response = await this.axios({
      url: '/api/account/is-workspace-admin',
      method: 'GET'
    });

    return response.data;
  }
}
export const workspaceService = new WorkspaceService();