/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';

const FooterComp: React.FC<RouteComponentProps> = ({ history }) => {
    return (
        <footer className="footer" id="footer">
            <div className="container">
                <div className="grid">
                    <div className="gi-2 t-gi-3 m-gi-4">
                        <h2 className="visually-hidden">Sitemap</h2>
                        <div className="stack stack-s m-fd-r">
                            <ul className="stack stack-xs fill">
                                <li><a href="/invite">Invite a Colleague</a></li>
                                <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
                                <li style={{display: 'flex', alignItems: 'flex-start'}}>
                                    <a href="https://youtu.be/mXERZAbC4uo" target="_blank" rel="noopener noreferer">About Gravity</a>
                                    <img src="/assets/img/video.png" alt ="" style={{width: '20px'}} />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="gi-2 t-gi-3 m-gi-4">
                        <div className="stack stack-s m-fd-r">
                            <ul className="stack stack-xs fill">
                                <li><a href="/contact-us">Contact Us</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="gi-2 t-gi-2 m-gi-4">
                        <div className="stack stack-s m-fd-r">
                            <ul className="stack stack-xs fill">
                                <li><a href="/faq">Frequently Asked Questions</a></li>
                                <li><a href="/assets/docs/RewardAndAllocationTable.pdf" target="_blank" rel="noopener noreferer">Credit Reward and Allocation </a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer__details gi-6 t-gi-8 m-gi-4">
                        <div className="h-stack jc-fe">
                            <div className="stack stack-xs">
                                <span className="logo logo--full logo--white logo--color"><svg className="logo__logo">
                                    <use xlinkHref="#logo-full-white-color" />
                                </svg><span className="visually-hidden">Gravity</span></span>
                                <p>Copyright 2021 &copy;</p>
                                <p>Gravity Information Exchange</p>
                                <div className="h-stack h-stack-xs">
                                    <a className="icon-button" id="" href="/">
                                        <span className="icon-button__inner">
                                            <svg className="icon icon--l">
                                                <use xlinkHref="#icon-facebook" />
                                            </svg>
                                            <span className="visually-hidden">Facebook</span>
                                        </span>
                                    </a>
                                    <a className="icon-button" id="" href="/">
                                        <span className="icon-button__inner">
                                            <svg className="icon icon--l">
                                                <use xlinkHref="#icon-linkedin" />
                                            </svg>
                                            <span className="visually-hidden">LinkedIn</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </footer >
    );
};


export const Footer = withRouter(FooterComp);