import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { authenticationSuccessAction, changeUseGravityAsAction } from '../../auth/authentication.actions';
import { userSelector } from '../../auth/authentication.selectors';
import { authenticationService } from '../../auth/authentication.service';
import { AppState } from '../../root.reducer';
import { workspaceInitializedSuccessfulAction, workspaceLoadingAction, workspaceLoadedSuccessfulAction } from '../workspace.actions';
import { SaveWorkspaceModel } from '../workspace.models';
import { workspaceService } from '../workspace.service';
import { workspaceSelector } from '../workspace.selector';
import { WorkspaceFormDispatchProps, WorkspaceFormPage, WorkspaceFormStateProps } from './WorkspaceFormPage.component';

const mapStateToProps = (state: AppState): WorkspaceFormStateProps => ({
  workspace: workspaceSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): WorkspaceFormDispatchProps => ({
  initWorkspace: () => {
    dispatch(workspaceInitializedSuccessfulAction());
  },

  saveWorkspace: async (workspace: SaveWorkspaceModel) => {
    dispatch(workspaceLoadingAction());
    const response = await workspaceService.saveWorkspace(workspace);

    if (response.status === 200) {
      const workspaceId = response.data;
      dispatch(changeUseGravityAsAction(workspaceId))

      const _workspace = await workspaceService.getWorkspaceSettings(workspaceId);
      const user = await authenticationService.getUser();

      dispatch(workspaceLoadedSuccessfulAction(_workspace));
      dispatch(authenticationSuccessAction(user));
      
      history.push(`/workspace/${_workspace.id}`);
    }
  },
});


export default withRouter(
  connect<WorkspaceFormStateProps, WorkspaceFormDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps, mapDispatchToProps
  )(WorkspaceFormPage)
);