import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { CompanyPropertyRevealBadgeComponent, CompanyPropertyRevealBadgeDispatchProps, CompanyPropertyRevealBadgeProps, CompanyPropertyRevealBadgeStateProps } from './CompanyPropertyRevealBadge.component';
import { AppState } from '../../root.reducer';
import { addPropertyToCartAction } from '../../cart/cart.actions';
import { CartItem } from '../../cart/cart.models';
import { isItemInCartSelector } from '../../cart/cart.selectors';

const mapStateToProps = (state: AppState, ownProps: CompanyPropertyRevealBadgeProps): CompanyPropertyRevealBadgeStateProps => ({
    isInCartAlready: isItemInCartSelector(ownProps.attributeType, ownProps.attributeId)(state)
});

const mapDispatchToProps = (dispatch: Dispatch): CompanyPropertyRevealBadgeDispatchProps => ({
    addPropertyToCart: (item: CartItem) => {
        dispatch(addPropertyToCartAction(item));
        
        const cartStorage = localStorage.getItem('savedCompanyCart');
        const cart = JSON.parse(cartStorage || '{items: []}');
        cart.items.push(item);
        localStorage.setItem('savedCompanyCart', JSON.stringify(cart));
    }
});

export default connect<CompanyPropertyRevealBadgeStateProps, CompanyPropertyRevealBadgeDispatchProps, CompanyPropertyRevealBadgeProps, AppState>(
    mapStateToProps,
    mapDispatchToProps
)(CompanyPropertyRevealBadgeComponent);
