import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { LinkedInRegisterDispatchProps } from './LinkedInRegister.component';
import { AppState } from '../../root.reducer';
import { SocialRegisterFields } from './social-register.models';
import { authenticationService } from '../authentication.service';
import { RouteComponentProps } from 'react-router-dom';
import { GoogleRegisterPage, GoogleRegisterDispatchProps, GoogleRegisterStateProps } from './GoogleRegister.component';
import { AppRoutes } from '../../routes';
import { SocialLoginResponse } from './SocielRegister.models';
import { isAuthenticationLoadingSelector } from '../authentication.selectors';
import { authenticationLoadingAction } from '../authentication.actions';

const mapStateProps = (state: AppState): GoogleRegisterStateProps => ({
  isLoading: isAuthenticationLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): LinkedInRegisterDispatchProps => ({
  register: async (model: SocialRegisterFields) => {
    dispatch(authenticationLoadingAction());
    const response = await authenticationService.registerGoogle(model);

    const loginResponse = response.data as SocialLoginResponse;

    if (response.status === 200) {
      if (loginResponse.hasWorkspaceInvite) {
        window.location.href = `${AppRoutes.WorkspaceResponse}`;
      } else {
        window.location.href = '/';
      }
    }
  }
});

export default connect<GoogleRegisterStateProps, GoogleRegisterDispatchProps, RouteComponentProps, AppState>(
  mapStateProps, mapDispatchToProps
)(GoogleRegisterPage);
