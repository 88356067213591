import { CartItem } from './cart.models';
import { actionCreator } from '../actions';

export enum CartActionTypes {
    ADD_CART_ITEM = 'cart/addItem',
    REMOVE_ITEM = 'cart/removeItem',
    SUBMIT_CART = 'cart/save',
    SUBMIT_CART_SUCCESS = 'cart/save/success',
    SUBMIT_CART_FAIL = 'cart/save/fail',
    RESET_CART = 'cart/reset',
}

export const addPropertyToCartAction = actionCreator<CartActionTypes.ADD_CART_ITEM, CartItem>(CartActionTypes.ADD_CART_ITEM);
export const removeItemFromCartAction = actionCreator<CartActionTypes.REMOVE_ITEM, CartItem>(CartActionTypes.REMOVE_ITEM);

export const submitCart = actionCreator<CartActionTypes.SUBMIT_CART, void>(CartActionTypes.SUBMIT_CART);
export const submitCartSuccess = actionCreator<CartActionTypes.SUBMIT_CART_SUCCESS, void>(CartActionTypes.SUBMIT_CART_SUCCESS);
export const submitCartFail = actionCreator<CartActionTypes.SUBMIT_CART_FAIL, void>(CartActionTypes.SUBMIT_CART_FAIL);
export const resetCart = actionCreator<CartActionTypes.RESET_CART, void>(CartActionTypes.RESET_CART);

export type CartActions = 
    ReturnType<typeof addPropertyToCartAction> |
    ReturnType<typeof removeItemFromCartAction> |
    ReturnType<typeof submitCart> |
    ReturnType<typeof submitCartSuccess> |
    ReturnType<typeof submitCartFail> |
    ReturnType<typeof resetCart>;