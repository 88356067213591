import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { InvitedWorkspaceModel, WorkspaceDeleteConfirmationModel, WorkspaceModel } from '../workspace.models';
import { FullPageLoadingComponent } from '../../FullpageLoading.component';

export interface WorkspaceAcceptOwnershipComponentStateProps {
    workspaceId: number;
    isLoading: boolean;
    workspace?: WorkspaceModel;
}

export interface WorkspaceAcceptOwnershipComponentDispatchProps {
    loadWorkspace: (workppaceId: number) => Promise<void>;
    acceptOwnership: (workppaceId: number) => Promise<void>;
}

const WorkspaceAcceptOwnershipComp: React.FC<WorkspaceAcceptOwnershipComponentStateProps & WorkspaceAcceptOwnershipComponentDispatchProps & RouteComponentProps> = ({
    workspaceId,
    isLoading,
    workspace,
    loadWorkspace,
    acceptOwnership,
    history
}) => {
    useEffect(() => {
        loadWorkspace(workspaceId);
    }, [loadWorkspace, workspaceId]);

    if (isLoading || !workspace) {
        return <FullPageLoadingComponent />;
    }

    const onAccept = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();

        acceptOwnership(workspaceId);
    };

    return (
        <div className="section section--content">
            <div className="container">
                <div className="grid">
                    <div className="gi-12">
                        <div className="stack stack-m">
                            <div className="user-form">
                                <form className="stack stack-xl">
                                    <div>
                                        <h1 className="stack stack-xs ai-c">
                                            <span className="logo logo--full logo--grey logo--color">
                                                <svg className="logo__logo">
                                                    <use xlinkHref="#logo-full-grey-color" />
                                                </svg>
                                                <span className="visually-hidden">Gravity</span>
                                            </span>
                                            <span className="title title-m">Company Workspace ownership</span>
                                        </h1>
                                    </div>
                                    <fieldset>
                                        <div className="stack stack-xs">
                                            Are you sure to accept ownership of workspace {workspace.workspaceName}?
                                        </div>
                                    </fieldset>
                                    <div className="h-stack h-stack-xs m-fd-cr">
                                        <button className="button" onClick={(evt) => onAccept(evt)}>
                                            <span className="button__inner">
                                                <span>Yes</span>
                                            </span>
                                        </button>
                                        <button className="button button--secondary" onClick={(evt) => { history.push('/') }}>
                                            <span className="button__inner">
                                                <span>Cancel</span>
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
};

export const WorkspaceAcceptOwnershipComponent = withRouter(WorkspaceAcceptOwnershipComp);

