import { WorkspaceModel, SaveWorkspaceFormModel } from './workspace.models';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { popupService } from '../popup/popup.service';
import { PopupDialogType } from '../popup/popup.models';
import { popupSetModelAction } from '../popup/popup.actions';

export interface WorkspaceFormComponentProps {
  workspace: WorkspaceModel;
  saveWorkspace(workspace: SaveWorkspaceFormModel): void;
  deleteWorkspace(workspaceId: number): void;
}

export const WorkspaceFormComponent: React.FC<WorkspaceFormComponentProps> = ({ workspace, saveWorkspace, deleteWorkspace }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState<SaveWorkspaceFormModel>({
    workspaceName: workspace.workspaceName,
    companyName: workspace.companyName,
    department: workspace.department,
  });

  const [isTouched, setIsTouched] = useState(false);

  const requiredFields: (keyof SaveWorkspaceFormModel)[] = [
    'workspaceName',
  ];
  const actionButtons = workspace.isCurrentUserCreator ?
    <>
      <hr />
      <div >
        <button className="button" onClick={(evt) => onSave(evt)} style={{ marginRight: '15px' }} >
          <span className="button__inner">
            <span>Save Workspace</span>
          </span>
        </button>
        {
          workspace.id ?
            <button className="button button--terminate" onClick={(evt) => onDelete(evt)} style={{ marginTop: '15px' }}>
              <span className="button__inner">
                <span>Delete Workspace</span>
              </span>
            </button> : ""
        }
      </div>
    </> : "";

  const checkIsFormValid = () => {
    return requiredFields.reduce((isValidSoFar, fieldName) => {
      if (!isValidSoFar) {
        return false;
      }

      return !!form[fieldName];
    }, true);
  };

  const onSave = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setIsTouched(true);
    if (!checkIsFormValid()) {
      return;
    }

    saveWorkspace({
      workspaceName: form.workspaceName,
      companyName: form.companyName,
      department: form.department
    });
  };

  const onDelete = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    dispatch(popupSetModelAction({
      title: 'Are you sure, you want to delete this workspace?',
      body:' You will lose all information and credit contained in the Workspace. Make sure you save all searches, company profiles and spend all available credit before deleting.',
      type: PopupDialogType.Dialog,
      okButtonText: 'Delete',
      okButtonType: 'terminate',
      noButtonText: 'Cancel',
      buttonSpacing: 'jc-sa',
      okButtonAction: async (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        popupService.hidePopup();

        deleteWorkspace(workspace.id);
      },
      cancelButtonAction: (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        popupService.hidePopup();
      }
    }));
    popupService.showPopup();
  };

  const formProp = (field: keyof SaveWorkspaceFormModel) => {
    return hasError(field) ? { error: "error" } : {};
  };

  const hasError = (field: keyof SaveWorkspaceFormModel) => {
    const isRequired = requiredFields.includes(field);
    return isTouched && isRequired && !form[field];
  };

  return (
    <div>
      <div className="stack stack-xs">
        <div>
          <label className="label" htmlFor="field-1">Workspace Name
            <span className="required-star" title="Required">*</span>
          </label>
          <div className="field field--input" {...formProp('workspaceName')}>
            <input className="field__field"
              defaultValue={form.workspaceName}
              disabled={!workspace.isCurrentUserCreator}
              onChange={(evt) => setForm({
                ...form,
                workspaceName: evt.target.value
              })}
              name="workspace-name"
              id="workspace-name"
              placeholder="Workspace Name" />
          </div>
        </div>
        <div>
          <label className="label" htmlFor="field-1">Company Name</label>
          <div className="field field--input">
            <input className="field__field"
              value={form.companyName}
              disabled={!workspace.isCurrentUserCreator}
              onChange={(evt) => setForm({
                ...form,
                companyName: evt.target.value
              })}
              name="company-name"
              id="company-name"
              placeholder="Company Name" />
          </div>
        </div>
        <div>
          <label className="label" htmlFor="field-1">Department</label>
          <div className="field field--input">
            <input className="field__field"
              value={form.department}
              disabled={!workspace.isCurrentUserCreator}
              onChange={(evt) => setForm({
                ...form,
                department: evt.target.value
              })}
              name="department"
              id="department"
              placeholder="Department" />
          </div>
        </div>
        {actionButtons}
      </div>
    </div>
  );
};