import { ForgotPasswordComponentDispatchProps, ForgotPasswordPage, ForgotPasswordComponentStateProps } from './ForgotPassword.component';
import { AppState } from '../root.reducer';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { authenticationErrorAction, authenticationLoadingAction, forgotPasswordSuccessAction } from './authentication.actions';
import { authenticationService } from './authentication.service';
import { authenticationErrorSelector, isAuthenticationLoadingSelector } from './authentication.selectors';
import { RouteComponentProps } from 'react-router-dom';

const mapStateToProps = (state: AppState): ForgotPasswordComponentStateProps => ({
  error: authenticationErrorSelector(state),
  isLoading: isAuthenticationLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, {history}: RouteComponentProps): ForgotPasswordComponentDispatchProps => ({
  forgotPassword: async (email: string, captchaToken: string) => {
    dispatch(authenticationLoadingAction());

    try {
      const response = await authenticationService.forgotPassword(email, captchaToken);

      if (response.status === 200) {
        dispatch(forgotPasswordSuccessAction());
        history.push('/forgotpassword-confirm');
      }
    } catch {
      dispatch(authenticationErrorAction('We couldn’t find that email. Please try again.'));
    }
  },
});

export default connect<{}, ForgotPasswordComponentDispatchProps, RouteComponentProps, AppState>(
  mapStateToProps, mapDispatchToProps
)(ForgotPasswordPage);
