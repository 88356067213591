import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { GetAccountQueryModel, SaveAccountModel } from './account.models';
import { AccountSettingsComponent } from './AccountSettings.component';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import { User } from '../auth/authentication.models';
import { CountryModel } from '../meta/meta.models';
import { CreditMap } from '../company-upload/company-upload.models';

export interface AccountSettingsStateProps {
  user: User | undefined;
  isLoading: boolean;
  account: GetAccountQueryModel | undefined;
  countries: CountryModel[] | undefined;
  credits: CreditMap | undefined;
}

export interface AccountSettingsDispatchProps {
  getProfile(): Promise<void>;
  saveProfile(account: SaveAccountModel): Promise<void>;
  respondWorkspaceInvite(workspaceId: number, response: boolean): Promise<void>;
  uploadCredit(amount: number, creditValue: number): Promise<void>;
  initWorkspace(): void;
  loadRegisterCredits(): Promise<void>;
  changeUseGravityAs: (redirectUrl: string, id?: number) => Promise<void>;
}

const AccountSettingsPageComponent: React.FC<AccountSettingsStateProps & AccountSettingsDispatchProps & RouteComponentProps> =
  ({ isLoading, user, getProfile, saveProfile, respondWorkspaceInvite, countries, credits, loadRegisterCredits, uploadCredit, account, initWorkspace, changeUseGravityAs }) => {
    useEffect(() => {
      loadRegisterCredits();
      getProfile();
    }, [getProfile, loadRegisterCredits]);

    if (isLoading || account === undefined || !countries || !credits) {
      return <FullPageLoadingComponent />;
    }

    const goToWorkspace = async (workspaceId: number) => {
      await changeUseGravityAs(`/workspace/${workspaceId}`, workspaceId);
    }

    return (
      <AccountSettingsComponent
        credits={credits}
        user={user}
        countries={countries}
        account={account}
        saveProfile={saveProfile}
        respondWorkspaceInvite={respondWorkspaceInvite}
        uploadCredit={uploadCredit}
        initWorkspace={initWorkspace}
        goToWorkspace={goToWorkspace}
      />
    );

  };

export const AccountSettingsPage = withRouter(AccountSettingsPageComponent);