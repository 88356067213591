import { AxiosInstance, AxiosResponse } from 'axios';
import { axiosInstance } from '../axios';
import { ManagementContactActivationModel } from './models';

class ManagementContactActivationService {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axiosInstance;
  }

  async getActivation(code: string): Promise<AxiosResponse<ManagementContactActivationModel>> {
    return await this.axios({
      url: `/api/companies/management-contact-activation?code=${code}`,
      method: 'GET'
    }).catch(err => {
      return err.response.status;
    });
  }

  async response(code: string, accept: boolean): Promise<AxiosResponse<void>> {
    return await this.axios({
      url: `/api/companies/management-contact-activation?code=${code}&accept=${accept}`,
      method: 'POST'
    }).catch(err => {
      return err.response.status;
    });
  }
}

export const activationService = new ManagementContactActivationService();