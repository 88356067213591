import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { isAuthenticatedSelector } from '../auth/authentication.selectors';
import { changeMobilMenuState } from '../meta/meta.actions';
import { isMobilMenuOpen } from '../meta/meta.selectors';
import { AppState } from '../root.reducer';
import { resetCurrentPageAction } from '../saved-companies/saved-companies.actions';
import { MobilMenu, MobilMenuDispatchrops, MobilMenuStateProps } from './MobilMenu.component';

const mapStateToProps = (state: AppState): MobilMenuStateProps => ({
  isOpen: isMobilMenuOpen(state),
  isLoggedIn: isAuthenticatedSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): MobilMenuDispatchrops => ({
  close: () => {
    dispatch(changeMobilMenuState(false));
    document.body.removeAttribute('menuopen');
  },

  resetCurrentPage: () => {
      dispatch(resetCurrentPageAction());
  }
});


export default withRouter(
  connect<MobilMenuStateProps, MobilMenuDispatchrops, {}, AppState>(
    mapStateToProps,
    mapDispatchToProps
  )(MobilMenu)
);
