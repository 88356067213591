import { AppState } from '../root.reducer';

export const isCompanyUploadCreditsLoadingSelector = (state: AppState) => state.companyUpload.isCompanyUploadCreditsLoading;
export const isCompanyUploadedSuccessSelector = (state: AppState) => state.companyUpload.companyUploadedSuccess;
export const isCompanyUploadingSelector = (state: AppState) => state.companyUpload.isCompanyUploading;
export const isCompanyUploadCurrenciesLoadingSelector = (state: AppState) => state.companyUpload.isCurrenciesLoading;
export const companyUploadCurrenciesSelector = (state: AppState) => state.companyUpload.currencies;
export const companyUploadTouchedInputsSelector = (state: AppState) => state.companyUpload.touchedInputs;
export const companyUploadModelSelector = (state: AppState) => state.companyUpload.uploadModel;

export const companyUploadCredits = (state: AppState) => state.companyUpload.companyCredits;
