import React from "react";

export const TermsAndConditionsComponent: React.FC = () => {
  return (
    <div>
      <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
        <b>Terms and Conditions of Service</b>
      </h1>
      <br/>
      <h2 style={{ fontWeight: "bold" }}>1. General</h2>
      <br />
      <p>
        The website https://gravity.surf ("Website") and all the services
        provided directly on the site or by the (the "Service" ) are operated by
        Digital Media Kft. (1051 Budapest, Vörösmarty tér 1.), ("Service
        Provider," "us," or "we").
        <br />
        <br />
        Service Provider's mission is to provide a tool, via the Service, for
        companies and/or individuals to publish and share a broad range of
        descriptive information related to factual business data related to
        business entities listed within the database of the Service.
        <br />
        <br />
        Use of the Service provided by Service Provider is initiated by the
        registration of individuals ("User" or "you") to the Service.
        Unregistered Users will have very limited access to the Service. All
        rules regarding the relationship between User and Service Provider are
        included in the present Terms and Conditions and the relevant legal
        regulations. By registering to the Service, User represents and warrants
        to the Service Provider that he or she has read, understood, and agreed
        to be bound by these Terms and Conditions including any future
        modifications thereof.
        <br />
        <br />
        Service Provider may at any time unilaterally amend, update or change
        these Terms and Conditions in its sole discretion. Any revision to these
        Terms and Conditions will become effective upon the earlier of (i) the
        end expiry of a 15-day notice period or (ii) the first time User
        accesses or uses the Service after such changes have been made by the
        Service Provided. If User does not agree to abide by these Terms and
        Conditions, then User is not authorized to use, access or participate in
        the Service.
        <br />
        <br />
        When using the Service, you will be subject to all the guidelines, rules
        or information that are posted on the Service, made available to you on
        the Website. Service Provider offers part of the Service against payment
        and reserves the right to decide and publish the rules and terms
        concerning its paid services subject to the Upload Reward and Allocation
        Table that is disclosed to you on the Website. All such terms,
        guidelines, rules or other information constitute an inseparable part of
        the present Terms and Conditions.
        <br />
        <br />
        When you use the Service or initiate any modification or deletion
        process on behalf of an Enterprise (as defined below) (in which case you
        are an "Enterprise Representative"), you represent and warrant to the
        Service Provider that you: (i) are an authorized representative of that
        Enterprise with the authority to bind that organization to these Terms
        and Conditions; and (ii) agree to these Terms of Service on behalf of
        such Enterprise. Service provider reserves the right to deny your claim
        at any time to represent the Enterprise, in which case we will not
        consider you to be an Enterprise Representative.
        <br />
        <br />
        Service Provider may, in its sole discretion and at any time, update,
        change, suspend, make improvements to or discontinue any aspect of the
        Service, whether temporarily or permanently.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>2. Description of Service</h2>
      <br />
      <p>
        Service Provider operates the platform and the database forming the
        basis of the Service (the "Database") which is open to the User for
        uploading and publishing factual business data, other business-related
        information, contact details, management information, corporate
        structure, photo, etc. ("Company Information") related to certain
        companies, business entities, public bodies, international organizations, 
        and/or other organizations (collectively
        "Enterprises") creating an "Enterprise Profile". Any User can initiate
        the opening of any Enterprise Profile by uploading the name of the
        Enterprise or any other relevant Company Information to the Database, in
        which event the uploaded data will become part of the Database and will
        be published on the Website. Publication means that the data uploaded to
        the Database (i) is available for any User who made a query for such
        Company Information and buys it, and (ii) after payment the Purchased
        Company Information is permanently stored in the User's account.
        <br />
        <br />
        Service Provider's ultimate purpose is to build and maintain the
        Database as a trustworthy information center; therefore some of the
        Company Information relating to an Enterprise have been acquired from
        publicly available, official databases ("Public Data") by Service
        Provider and are integrated into the Database at the start of the
        operation of the Service. Service Provider also intends to add to the
        database publicly available information from stock market reports,
        Enterprise websites and Enterprise annual reports in the course of the
        operation of the Service. This type of information will be considered
        Public Data as well.
        <br />
        <br />
        When User intends to open an Enterprise Profile of an Enterprise in
        respect of which Public Data has been added to the Database, the Service
        automatically shows the existence of such data and User is only allowed
        to insert Company Information that completes the existing Company
        Information or buy the existing Company Information and start any
        modification process if necessary to correct the information. User is
        allowed to initiate the modification of the Company Information stored
        in the Database at any time. The Service automatically stores all
        Company Information uploaded and published by any User, but only
        releases the last 5 to 7 data/entries together with their sources among
        the Company Information. User is able to download the Purchased Company Information 
        in Excel format containing these entries, their sources and the date and time of the 
        entries of the Purchased Company Information. If
        the User uploads an e-mail address to the dedicated box while creating
        an Enterprise Profile, the Service automatically contacts the relevant
        Enterprise for the verification of the published Company Information.
        The relevant Enterprise Representative, on its own initiative, (i) can
        obtain the Company Information concerning the Enterprise for free, (ii)
        may start any modification of the existing Company Information, (iii)
        upload new data to the Enterprise Profile in line with the terms of the
        Upload Reward and Allocation Table or (iv) ask for the removal of the
        Enterprise Profile from the Service.
        <br />
        <br />
        The relevant Enterprise Representative may initiate modification or
        deletion of Company Information from the Database regarding the
        Enterprise using the pre-established form or contacting directly the
        Service Provider via e-mail or other documented means. The relevant
        Enterprise Representative shall demonstrate his/her authorization to act
        on behalf of the Enterprise either by presenting the Enterprise excerpt
        reflecting his/her signature right or a mandate issued by the person
        listed in the excerpt as having signatory rights on behalf of the
        Enterprise. Service Provider understands the importance of the
        protection of personal data when dealing with data concerning Users or
        personal data of individuals acting in a managerial position at an
        Enterprise ("Managers") whose data might be uploaded to the Database by
        any User. Please see our Privacy Notice for information relating to how
        we collect, use, and disclose Users' personal data. Managers' personal
        data will only be temporarily stored and published by the Service for 3
        months after the data's upload unless the Manager approves the use of
        personal data concerning him/her responding to Service Provider's e-mail
        or text notification. During the first 7 days of this 3 month- period
        the Service will not disclose Manager's personal data to the Public. The
        Manager may prohibit the future use of his/her personal data by the
        Service at any time by contacting the Service Provider.
        <br />
        <br />
        Any Upload of new Company Information to or any download of any Company
        Information from the Database will have an economic value which is
        reflected in pre-defined credit numbers (Credit). Please, see the Upload 
        Reward and Allocation Table for the exact information on the number of Credits
         associated with the various data types and activities. It is up to
        Service Provider's sole decision the determination of value (number of
        Credit) of the data and/or related activity always depending on their
        economic value and market conditions. Upload reward and download price
        of the same data can be different, so upload or download of different
        data will or might have different values.
        <br />
        <br />
        Users can earn Credit by uploading to the Database Company Information
        on any Enterprise, provided that the data has not yet been uploaded to
        the Database by another User or the Service Provider. User may also earn
        credit by updating or modifying incorrectly published Company
        Information. User shall confirm the incorrectness of the data updated,
        and the correctness of the new data uploaded by him/her by clicking on a
        confirmation box. User may use the earned and/or purchased credits on
        downloading Company Information from the Database. The Service always
        automatically shows the number of Credits generated (in case of data
        upload) or payable (in case of purchase) to the User depending on the
        actions of the User. If User intends to buy any Company Information and
        selects the type of data of its interest from the query results, the
        Service will automatically reflect the number of Credits to be paid and
        the transaction becomes completed when the User activates the "Reveal
        Company Information" button and pays the fee indicated by the Service
        Provider. User may change the range of selected Company Information
        until the start of the payment process.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>3. Registration</h2>
      <br />
      <p>
        In connection with registering for and using the Service, User agrees
        (i) to provide accurate, current and complete information; (ii) to
        maintain the confidentiality of the password and other information
        related to the security of the User account; (iii) to maintain and
        promptly update any registration information provided; and (iv) to be
        fully responsible for all use of the User account and for any actions
        that take place through the account.
        <br />
        <br />
        If User permit others to use his/her account credentials, he/she agree
        to these Terms of Service on behalf of all other persons who use the
        Service under that account or password, and he/she is responsible for
        all activities that occur under that account or password.
        <br />
        <br />
        User notes that registration or access to the Service requires a
        broadband internet connection, the use of a personal computer or a
        mobile device, as well as use of data storage space on such devices.
        User is responsible for any Internet connection or mobile fees and
        charges that he or she incurs when accessing the Service.
        <br />
        <br />
        User understands that upon registering for the Service the User will:
      </p>
      <p style={{ padding: "12px" }}>
        <br />
        a) be able to open an Enterprise Profile by entering the name and
        relevant business data of an Enterprise;
        <br />
        <br />
        b) be able to initiate the modification or completion of existing
        Company Information contained in an Enterprise Profile;
        <br />
        <br />
        c) have direct access to the search engine and query results presented
        in the pre-established form by the Service Provider. Please, note that
        the query results will never show the Company Information itself
        available within the Database, but only the existence or absence of the
        given Company Information;
        <br />
        <br />
        d) be able to buy specific Company Information by selecting from the
        search results and paying the fee indicated by the Service Provider by
        Credits or other generally accepted payment methods accepted by the
        Service;
        <br />
        <br />
        e) receive an account ("User Account") where (i) a copy of all Company
        Information purchased by the User ("Purchased Company Information") is
        stored and the User will have unlimited access, copying and downloading
        rights in respect of such Purchased Company Information; and (ii) the
        amount of available Credits to the User and information concerning past
        transactions of the User will be shown.
        <br />
        <br />
      </p>
      <p>
        If User indicates that he or she is an employee, officer or
        representative of an Enterprise during his/her registration for the
        Service, the source of any Service Provider Information added by the
        User to the Enterprise Profile will be labelled as "Enterprise".
        <br />
        <br />
        In the event that User terminates the use of the Service and deletes
        his/her account (registration) voluntarily, all Purchased Company
        Information stored on the User's account, the number of Credits and
        personal data generated during the use of the Service will be deleted
        accordingly within 30 days after termination. User accepts that Company
        Information uploaded and published by him/her will remain within the
        Database unless the Enterprise concerned requests its deletion and the
        Service Provider accepts that request. Company Information uploaded by a
        User may also be deleted if it has been modified by other Users a
        minimum of four times.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>4. Workspace</h2>
      <br />
      <p>
        "Workspace" is a group of Users where all the members of the group have
        access to
        <br />
        (i) all the Purchased Company Information bought by members of the same
        group and stored in the account of the group ("Workspace account") or
        <br />
        (ii) the amount of Credit owned by the group.
        <br />
        Any User is entitled to establish a Workspace (in which case such User
        will be a "Workspace Manager") or become a member of a Workspace upon
        acceptance of the Workspace Manager's invitation through the Service. A
        User can be a member of a maximum of 5 Workspaces at the same time.
        <br />
        <br />
        Workspace Managers may (i) invite Users to become members of the
        Workspaces administered by them, (ii) remove any members of those
        Workspaces at any time or (iii) buy Credit that is allocated to those Workspaces' accounts. 
        Credit stored in a Workspace's account may only be
        used by the members of that Workspace.
        <br />
        <br />
        Any Workspace member may terminate his or her membership of the
        Workspace through his or her User account. Disconnection of a User from
        a Workspace means that such User will have no further access to that
        Workspace account.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>5. Proprietary Rights</h2>
      <br />
      <p>
        Company Information available through the Service is the Service
        Provider's proprietary information and is the Service Provider's
        property. User understands that the data uploaded to the Database
        becomes an integrated part of the Database and will be stored there for
        up to 10 years after the upload unless deleted upon the relevant
        Enterprise's request.
        <br />
        <br />
        The source of Company Information ("Data Source") will be indicated next
        to the given Company Information labelled as "User", "Enterprise" or
        "Public Data". The history of any Company Information may be requested
        by the User that will reflect (i) the original data (first uploaded
        Company Information), (ii) maximum 6 of its modifications and (iii) the
        last modification requested by the Enterprise.
        <br />
        <br />
        Apart from making any structural or technical changes deemed necessary
        by it, Service Provider will not examine Public Data and does not make
        any representation or warranty of any kind or assumes any liability
        whatsoever regarding Public Data, including as to its accuracy or
        completeness.
        <br />
        <br />
        No Company Information may be modified, copied, distributed, framed,
        reproduced, republished, downloaded, sold, commercialized, used, or
        exploited. in any form or by any means, in whole or in part, other than
        in the form that it is stored in the Database. If User modifies the
        Purchased Company Information in any way, it shall ensure that the
        Service Provider or the Service are in no event indicated as the source
        of such information. In the event of any breach of this obligation, the
        User shall be liable for all resulting losses incurred by the Service
        Provider.
        <br />
        <br />
        User may not use any data mining, robots, scraping or similar data
        gathering or extraction methods to obtain any Company Information.
        <br />
        <br />
        Any use of the Service or the Company Information except as specifically
        authorized in these Terms of Service, may violate intellectual property
        rights or other laws.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>6. Company Information</h2>
      <br />
      <p>
        If you upload and publish any data or information to the Database, you
        understand and agree that Service Provider and any other User might have
        access to that data or information. Service Provider may freely use,
        reproduce, modify, adapt, translate, distribute and/or displays any data
        or information uploaded by you to the Database.
        <br />
        <br />
        Should the Company Information provided by you contain the name or
        contact details of third party individuals (e.g. of any Managers), you
        represent and warrant that you have obtained the appropriate consents
        and/or licenses for your use of such data and that Company and other
        Users are allowed to use them to the extent indicated in these Terms of
        Service.
        <br />
        <br />
        You are solely responsible for all data and information uploaded by you
        to the Database and the consequences of uploading and/or publishing
        them. You represent and warrant to the Service Provider that:
        <br />
        <br />
        (1) the data and information uploaded and/or published by you is based
        on and reflects data from a reliable source and respects the business
        interests of the Enterprise and other persons to which they relate; and
        <br />
        <br />
        (2) you have the right to upload and publish all data and information
        uploaded and/or published by you and the data and information uploaded
        and/or published provided by you does not and will not: (a) infringe
        upon, violate, or misappropriate any right of any person including any
        trade or business secrets, intellectual property rights, privacy rights
        or any other rights, or (b) defame any Enterprise or person.
        <br />
        <br />
        Service Provider reserves all rights and remedies against any User who
        breaches these representations and warranties. To the extent permitted
        by applicable law, Service Provider takes no responsibility and assumes
        no liability for any Company Information nor is Service Provider liable
        for any mistakes, defamation or misleading information when using the
        Service. User's use of the Services is at his/her own risk and Service
        Provider expressly disclaims any and all liability in connection with
        any Company Information.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>7. License to the Service</h2>
      <br />
      <p>
        Unless otherwise indicated, all content, information, and other
        materials of the Service (excluding Company Information), including,
        without limitation, Service Provider's trademarks, logos, slogans, the
        visual interfaces, graphics, design, software, computer code (including
        source code or object code), services, text, pictures, the Database, any
        data and the selection and arrangement thereof (collectively, the
        "Materials") are protected by relevant intellectual property and
        proprietary rights and laws. All Materials are the property of Service
        Provider. By agreeing to these Terms and Conditions you are granted a
        limited, non-sublicensable and non-transferable license (i.e., a
        personal and limited right) to access and use the Service for your
        personal use only.
        <br />
        <br />
        Service Provider owns and shall retain all intellectual property rights
        and other rights in and to the Service and Material and any changes,
        modifications or corrections thereto.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>8. Inappropriate Use</h2>
      <br />
      <p>
        User shall not:
      </p>
      <p style={{ padding: "12px" }}>
        a) upload any Company Information that is false, non-existent,
        inaccurate, unlawful, infringes upon or violates the rights of any
        person, defamatory, obscene, pornographic, invasive of privacy or
        publicity rights, or otherwise objectionable;
        <br /><br />
        b) impersonate any person or entity; falsely claim an affiliation with
        any person or entity; access the Service accounts of others without
        permission or perform any other similar or fraudulent activity;
        <br /><br />
        c) misrepresent the Company Information or its source;
        <br /><br />
        d) harvest or collect email addresses or other contact information of
        other Users;
        <br /><br />
        e) reverse engineer, decompile, disassemble, or otherwise attempt to
        discover the source code of the Services or any part thereof;
        <br /><br />
        f) use or attempt to use another User's account without authorization
        from that User;
        <br /><br />
        g) disturb or damage the smooth operation of the Service or any User's
        use of it, by any means, including uploading inappropriate Company
        Information, misuse of rights or data modification procedures or
        otherwise threatening the credibility, reliability or operation of the
        Service
        <br /><br />
        h) use the Service for any illegal purpose, or in violation of any
        local, state, national, supranational or international law or regulation
        of any country, including without limitation laws governing intellectual
        property and other proprietary rights, data protection, and privacy or
        business secrecy.
        <br /><br />
        i) upload information of an Enterprise to the Service if the Enterprise
        has not been registered in the official registry of its local
        jurisdiction, or if it is not under registration with the exception that
        if the Enterprise has already started economic activities, such as
        offering products or services, holding or investment activities, or any
        other activities creating economic value, regardless of their
        registration status.
        <br />
      </p>
      <p>
        A User failing to comply with the above provisions might face suspension
        or termination of his or her account upon decision of the Service
        Provider. Depending on the case, User may face financial or criminal
        liabilities or charges of its harmful activity to the Service or other
        User.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>
        9. User's Representations and Warranties
      </h2>
      <br />
      <p>
        User represents and warrants to Service Provider that his/her access and
        use of the Service will be in accordance with these Terms and Conditions
        and with all applicable laws, rules and regulations of Malta and any
        other relevant jurisdiction, including those regarding business secrecy.
        <br />
        <br />
        To use the Service, the User must be at least 16 years old. If Service
        Provider discovers that a User under 16 years is registered, Service
        Provider may cancel the User account and remove all data related to the
        User without notice, at is sole discretion.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>
        10. Service Provider's Representations and Warranties
      </h2>
      <br />
      <p>
        Service Provider declares and guarantees that, for the duration of the
        relationship with the User, the Service complies with the requirements
        defined in the Terms and Conditions and the statutory regulations.
        Furthermore, Service Provider declares and guarantees to provide the
        Service in compliance with the generally accepted professional standards
        and practice.
        <br />
        <br />
        The Service, including all images and other content therein, and any
        other information or functions are provided on an "as is" basis. Service
        Provider makes no representations or warranties of any kind with respect
        to the Service, either express or implied, and all such representations
        and warranties, including warranties of merchantability, fitness for a
        particular purpose or non-infringement, are expressly disclaimed.
        Without limiting the generality of the foregoing, Service Provider does
        not make any representation or warranty of any kind relating to
        accuracy, service availability, completeness, informational content,
        error-free operation, or non-infringement. Service Provider never
        develops any Company Information, and bears no liability for the
        correctness, completeness and up-to-dateness of any Company Information.
        <br />
        <br />
        Access and use of the Service may be unavailable during periods of peak
        demand, system upgrades, malfunctions or scheduled or unscheduled
        maintenance or for other reasons. Service Provider does not accept any
        liability for errors resulting from the malfunctioning of the hardware
        used by User or damages resulting from the improper or unlawful use of
        the Service, or data loss or data corruption attributable to user
        behavior.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>
        11. Indemnification of Service Provider
      </h2>
      <br />
      <p>
        User agrees to defend, indemnify and hold harmless Service Provider from
        and against any and all losses, claims, causes of action, obligations,
        liabilities and damages whatsoever, including attorneys' fees, arising
        out of or relating to User's access or use of the Service, any false
        representation made to us, breach of any of these Terms and Conditions,
        or any claim that any material that Service Provider provides is
        inaccurate, inappropriate or defective in any way whatsoever.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>
        12. Limitation on types of damages/limitation of liability
      </h2>
      <br />
      <p>
        In no event will Service Provider be liable to the User or any third
        party claiming through the User for indirect, incidental, special,
        consequential or exemplary damages arising out of or relating to the
        access or use of, or the inability to access or use the Service or any
        portion thereof, including but not limited to the loss of Company
        Information, data stored at User account, inaccurate results, or damages
        stemming from loss or corruption of data or data being rendered
        inaccurate.
        <br />
        <br />
        User understands that if he/she uploads an Enterprise Profile or Company
        Information through original upload, modification or otherwise, the
        Enterprise or its owner, representative, employee, etc. will be able to
        have a legal claim against him/her, and not against the Service
        Provider.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>13. Termination</h2>
      <br />
      <p>
        Service Provider may terminate User access to the Service at any time,
        with or without notice, at its sole discretion, if the User breaches any
        of the present Terms and Conditions, following which User will have no
        further right to use the Service and User loses the Purchased Company
        Information and amount of Credit stored at his/her account. Service
        Provider reserves the right to store identification personal data
        (e-mail address, IP address, etc.) of such User. Service Provider also
        has the right to exclude the individual identified by the above data
        from the Service whose past use of the Service resulted in serious
        breach of the present Terms and Conditions.
        <br />
        <br />
        User may terminate his/her User account at any time. The provisions of
        these Terms and Conditions relating to the protection and enforcement of
        User's representations and warranties, indemnities, limitations of
        liability, ownership of data, governing law and venue, and miscellaneous
        provisions shall survive any such termination.
        <br />
        <br />
        Service Provider shall also be entitled to discontinue the Service at
        any time in its sole discretion, in which case its only liability shall
        be to refund any money paid by the User for Services or periods not
        used. Any unused credit can be converted to cash and refunded to the
        User holding the credit.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>14. Governing Law and Arbitration</h2>
      <br />
      <p>
        These Terms and Conditions, including any non-contractual obligations
        arising out of or in connection with these Terms and Conditions, shall
        be governed by and construed in accordance with the laws of Malta,
        without regard to its provisions on the conflict of laws.
        <br />
        <br />
        Any dispute, controversy, or claim arising out of or in connection with
        these Terms and Conditions, including any question regarding their
        existence, formation, validity, enforceability, performance, breach or
        termination (including any non-contractual obligations arising out of or
        in connection with these terms and Conditions) shall be finally settled
        by the [Permanent Arbitration Court attached to the Maltese Chamber of
        Commerce] to settle the dispute, which shall proceed in accordance with
        its own Rules of Proceedings. The number of the arbitrators shall be
        three (3) and the language of the procedure shall be English.
        <br />
        <br />
      </p>
      <h2 style={{ fontWeight: "bold" }}>15. Miscellaneous</h2>
      <br />
      <p>
        These Terms and Conditions constitute the entire agreement between
        Service Provider and the User concerning the subject matter hereof. In
        the event that any part of the Terms and Conditions are held by a court
        or other tribunal of competent jurisdiction to be unenforceable, such
        provisions shall be limited or eliminated to the minimum extent
        necessary so that these Terms and Conditions shall otherwise remain in
        full force and effect. A waiver by Service Provider or User of any
        provision of these Terms and Conditions or any breach thereof, in any
        one instance, will not waive such term or condition or any subsequent
        breach thereof. Service Provider may assign its rights or obligations
        under these Terms and Conditions without condition.
      </p>
      <br />
      <h2 style={{ fontWeight: "bold" }}>Disclaimer</h2>
      <br />
      <p>
        User accepts that Service Provider is continuously developing the
        Service, thus its functions, surfaces, and design may change during the
        course of use. Furthermore, User accepts that Service Provider is
        permanently seeking to provide the most user-friendly functions of the
        Service and it is not providing any personal assistance in the course of
        use for the User, apart from eventually occurring technology related
        issues.
      </p>
    </div>
  );
};
