import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { fromSearchCompanyAction } from '../saved-company/saved-company.actions';
import { SavedCompanyModel } from '../saved-company/saved-company.models';

interface SearchResultComponentProps {
    isBlurred?: boolean;
    searchResult: SavedCompanyModel;
    savedSearchId: number;
}

const SearchResult: React.FC<SearchResultComponentProps & RouteComponentProps> = ({ savedSearchId, isBlurred, searchResult, history }) => {
    const selectedProp: any = { selected: 'selected', blurred: isBlurred ? 'blurred' : null };
    const dispatch = useDispatch();

    const openCompany = () => {
        dispatch(fromSearchCompanyAction(savedSearchId));
        history.push(`/company/${searchResult.id}`);
    };

    const totalNetRevenues = searchResult.financials?.find(f => f.incomeStatementTotalNetRevenues);

    return (
        <li className="company-list-item" onClick={() => openCompany()}>
            <div className="item item--com" {...selectedProp}>
                <div className="h-stack h-stack-xs ai-c">
                    <div className="col col--name">
                        <div className="stack stack-xs">
                            <h3 className="title title-s">
                                {
                                    searchResult.summary.fullName?.valueDescription!.length > 30 ?
                                        `${searchResult.summary.fullName?.valueDescription!.substr(0, 30)}...` : searchResult.summary.fullName?.valueDescription
                                }
                            </h3>
                            {searchResult.summary.industry ?
                                <ul className="h-stack h-stack-xs">
                                    <li>
                                        <span className="chip" >
                                            <a className="label" href="/">{searchResult.summary.industry?.valueDescription}</a>
                                        </span>
                                    </li>
                                </ul> : null
                            }

                        </div>
                    </div>
                    <div className="col col--data">
                        <div className="stack stack-xs">
                            <div className="h-stack h-stack-xs m-fd-c">
                                <div className="fill col--country">
                                    <span className="icon-label icon-label--label-right" >
                                        <span
                                            className="icon-label__inner">
                                            <svg className="icon icon--m">
                                                <use xlinkHref="#icon-location" />
                                            </svg>
                                            <span className="label">{searchResult.summary.countryOfFoundation?.valueDescription}</span>
                                        </span>
                                    </span>
                                </div>
                                <div className="fill col--revenue">
                                    <span className="icon-label icon-label--label-right" >
                                        <span
                                            className="icon-label__inner">
                                            <svg className="icon icon--m">
                                                <use xlinkHref="#icon-revenue" />
                                            </svg>
                                            <span className="label">{totalNetRevenues?.incomeStatementTotalNetRevenues?.valueDescription || 'Annual revenues not specified'}</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            {/* <div className="fw-b">
                                <span className="icon-label icon-label--label-right" >
                                    <span
                                        className="icon-label__inner">
                                        <svg className="icon icon--m">
                                            <use xlinkHref="#icon-gravity-credit" />
                                        </svg>
                                        <span className="label">{searchResult.updatesAvailableCount && searchResult.updatesAvailableCount > 0
                                            ? `${searchResult.updatesAvailableCount} more company data is available to reveal with your credits`
                                            : 'No updates available'}</span>
                                    </span>
                                </span>
                            </div> */}
                        </div>
                    </div>
                    <div className="col col--icon col--arrow" onClick={() => openCompany()}>
                        <svg className="icon icon--m">
                            <use xlinkHref="#icon-arrow-nav-forward" />
                        </svg>
                    </div>
                </div>
            </div >
        </li >
    );
};

export const SearchResultComponent = withRouter(SearchResult);