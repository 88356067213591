import { AppState } from '../root.reducer';
import { connect } from 'react-redux';
import { popupModelSelector } from './popup.selectors';
import { PopupDialogComponent, PopupDialogStateProps } from './PopupDialog.component';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const mapStateToProps = (state: AppState): PopupDialogStateProps => ({
  model: popupModelSelector(state),
});


export default withRouter(
  connect<PopupDialogStateProps, {}, RouteComponentProps, AppState>(
    mapStateToProps
  )(PopupDialogComponent)
);