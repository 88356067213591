import React, { useEffect, useState } from "react";

interface Props {
    label: string;
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
    required?: boolean;
    options: { name: string, value: any }[];
    touched?: boolean;
}

export const SelectWithLabelComponent: React.FC<Props> = ({ label, value, onChange, placeholder, required, options, touched }) => {
    const [localValue, setLocalValue] = useState<string>('');
    useEffect(() => {
        if (value !== localValue) {
            setLocalValue(value || '');
        }
    }, [value])
    
    const [isTouched, setTouched] = useState(false);
    const errorProps = required && (isTouched || touched) && !value ? { error: 'error' } : {};

    const _onChange = (newvalue: any) => {
        setLocalValue(newvalue);
        onChange(newvalue);
    }

    return (
        <div className="stack stack-xs">
            <div className="label">{label}{required ? <span className="required-star" title="Required">*</span> : null}</div>
            <div className="field field--input" {...errorProps}>
                <select
                    className="field__field"
                    value={localValue}
                    required={required}
                    onChange={(evt) => _onChange(evt.target.value)}
                    onBlur={() => setTouched(true)}
                >
                    <option value={""}>{placeholder}</option>
                    {options.map((option) => (
                        <option key={option.name} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};
