import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { accountService } from '../account-settings/account.service';
import { gravityUseAsSelector, userSelector } from '../auth/authentication.selectors';
import { countriesSelector } from '../meta/meta.selectors';
import { AppState } from '../root.reducer';
import { billingDataSuppliedAction, billingIpCheckedAction, billingLoadingAction, billingPaypalClientLoadedAction, paypalClientTokenGeneratedSuccessfulAction } from './billing.actions';
import { BillingModel } from './billing.model';
import { billingDataSelector, billingIpDetailsSelector, isBillingLoadingSelector, isBillingVatLoadingSelector } from './billing.selector';
import { billingService } from './billing.service';
import { BillingInformationDispatchProps, BillingInformationStateProps, BillingInformationComponent } from './BillingInformation.component';

const mapStateToProps = (state: AppState): BillingInformationStateProps => ({
  user: userSelector(state),
  useGravityAs: gravityUseAsSelector(state),
  isLoading: isBillingLoadingSelector(state),
  isVatLoading: isBillingVatLoadingSelector(state),
  countries: countriesSelector(state),
  billing: billingDataSelector(state),
  ipDetails: billingIpDetailsSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): BillingInformationDispatchProps => ({
  continueToCheckout: async (billingModel: BillingModel) => {    
    dispatch(billingLoadingAction());

    var token = await billingService.generatePaypalClientToken();
    var clientId = await billingService.getPaypalClientId();

    dispatch(billingDataSuppliedAction(billingModel));
    dispatch(paypalClientTokenGeneratedSuccessfulAction(token));
    dispatch(billingPaypalClientLoadedAction(clientId));

    history.push('/checkout');
  },

  vatCheck: async (vatNumber: string) => {
    return await billingService.vatCheck(vatNumber);
  },

  checkIp: async () => {
    dispatch(billingLoadingAction());
    
    const ipDetails = await accountService.checkIp();

    dispatch(billingIpCheckedAction(ipDetails));
  }
});


export default withRouter(
  connect<BillingInformationStateProps, BillingInformationDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps, mapDispatchToProps
  )(BillingInformationComponent)
);
