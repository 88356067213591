import React from 'react';
import { AttributeType, CompanyPropertyLabelMap, SavedCompanyAttributeModel, SavedCompanyModel } from '../saved-company.models';
import { CompanyPropertyComponent } from './CompanyProperty.component';

export interface ProductsAndServicesTabProps {
    addPropertyToCart: (attribute: SavedCompanyAttributeModel, label: any, attributeName: string) => void;
    savedCompany: SavedCompanyModel;
}

export const ProductsAndServicesTab: React.FC<ProductsAndServicesTabProps> = ({ savedCompany, addPropertyToCart }) => {
    const brandColorStyle = { '--background-color': 'var(--brand-color)' } as any as React.CSSProperties;


    const addPropertyToCartIfAvailable = (property: SavedCompanyAttributeModel, label: any, propertyName: string) => {
        if (property.value == null || property.value === "" || property.value === undefined || property.hasUpdate) {
            addPropertyToCart(property, label, propertyName);
        }
    };

    const { numberOfAvailableProperies, totalPriceOfProperties } = [
        "mainServices",
        "mainProducts",
        "brands"
    ].reduce((sum, propertyName) => {
        const model = savedCompany.productsAndServices as any;
        const isAvailableToBuy = model[propertyName] && (!model[propertyName]?.value || model[propertyName].hasUpdate);

        if (isAvailableToBuy) {
            sum.numberOfAvailableProperies += 1;
            sum.totalPriceOfProperties += model[propertyName]?.creditPrice || 0;
        }

        return sum;
    }, { numberOfAvailableProperies: 0, totalPriceOfProperties: 0 });

    const revealAll = () => {
        addPropertyToCartIfAvailable(savedCompany.productsAndServices.mainServices, CompanyPropertyLabelMap.mainServices, "mainServices");
        addPropertyToCartIfAvailable(savedCompany.productsAndServices.mainProducts, CompanyPropertyLabelMap.mainProducts, "mainProducts");
        addPropertyToCartIfAvailable(savedCompany.productsAndServices.brands, CompanyPropertyLabelMap.brands, "brands");
    };

    return (
        <>
            <div className="info-count">
                {numberOfAvailableProperies > 0 ?
                    <div className="h-stack h-stack-xs ai-c m-fd-c">
                        <p className="annotation">{numberOfAvailableProperies} {numberOfAvailableProperies === 1 ? "piece" : "pieces"} of information available on this page</p>
                        <button className="icon-chip icon-chip--label-left" style={brandColorStyle}
                            onClick={() => revealAll()}
                        >
                            <span className="icon-chip__inner">
                                <span className="label">Reveal all for <svg className="icon icon--m "><use xlinkHref="#icon-gravity-credit" /></svg>
                                    {totalPriceOfProperties}</span>
                            </span>
                        </button>
                    </div> : ""}
            </div>
            <div className="source-info">
                <p className="annotation">Move your cursor on <img className="source" src={`/assets/img/source-up.png`} alt="Source" /> to show the source of the last upload</p>
            </div>
            <div className="source-info">
                <p className="annotation">View previously entered values on revealed fields by clicking on <img className="source" src={`/assets/img/source-down.png`} alt="Source" /></p>
            </div>
            <div className="stack stack-m">
                <div>
                    <div className="stack stack-m">
                        {numberOfAvailableProperies > 0 ? 
                        <span className="icon-header icon-header--header-right">
                            <span className="icon-header__inner">
                                <svg className="icon icon--l ">
                                    <use xlinkHref="#icon-general-details" />
                                </svg>
                                <span className="title title-s">Products & Services</span>
                            </span>
                        </span> : null }
                        <ul className="space">
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                splitBySemicolon={true}
                                attributeType={AttributeType.ProductsAndServices}
                                key={"mainProducts"}
                                labelName="Main Products"
                                attribute={savedCompany.productsAndServices.mainProducts}
                                attributeName={"mainProducts"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                splitBySemicolon={true}
                                attributeType={AttributeType.ProductsAndServices}
                                key={"mainServices"}
                                labelName="Main Services"
                                attribute={savedCompany.productsAndServices.mainServices}
                                attributeName={"mainServices"} />
                            <CompanyPropertyComponent
                                savedCompanyId={savedCompany.id}
                                splitBySemicolon={true}
                                attributeType={AttributeType.ProductsAndServices}
                                key={"brands"}
                                labelName={CompanyPropertyLabelMap["brands"]}
                                attribute={savedCompany.productsAndServices.brands}
                                attributeName={"brands"} />
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
