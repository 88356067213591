import React from 'react';
import { CartItem } from '../../cart/cart.models';
import { AttributeType } from '../saved-company.models';

export interface CompanyPropertyRevealBadgeDispatchProps {
    addPropertyToCart: (item: CartItem) => void;
}

export interface CompanyPropertyRevealBadgeStateProps {
    isInCartAlready: boolean;
}

export interface CompanyPropertyRevealBadgeProps {
    attributeType: AttributeType;
    attributeId: number;
    label: string;
    price: number;
    isUpdate?: boolean;
}

export const CompanyPropertyRevealBadgeComponent: React.FC<
    CompanyPropertyRevealBadgeProps & CompanyPropertyRevealBadgeStateProps & CompanyPropertyRevealBadgeDispatchProps
> = ({ addPropertyToCart, isInCartAlready, attributeId, attributeType, label, price, isUpdate }) => {
    const brandColorStyle = { '--background-color': 'var(--brand-color)' } as any as React.CSSProperties;
    const updateColorStyle = { '--background-color': 'var(--toggle-selected)' } as any as React.CSSProperties;
    const warningColorStyle = { '--background-color': 'var(--warning-color)' } as any as React.CSSProperties;

    const renderAddToCartButton = () => (
        <span className="icon-chip icon-chip--label-left " style={isUpdate ? updateColorStyle : brandColorStyle}>
            <span className="icon-chip__inner" onClick={() => addPropertyToCart({ attributeType, attributeId, label, price })}>
                {
                    !isUpdate ?
                        <span className="label">Reveal for <svg className="icon icon--m "><use xlinkHref="#icon-gravity-credit" /></svg>{price}</span> :
                        <span className="label">Update for <svg className="icon icon--m "><use xlinkHref="#icon-gravity-credit" /></svg>{price}</span>
                }
            </span>
        </span>
    );

    const renderAlreadyInCartButton = () => (
        <span className="icon-chip icon-chip--label-left " style={warningColorStyle}>
            <span className="icon-chip__inner">
                <span className="label">In your cart</span>
                <svg className="icon icon--m ">
                    <use xlinkHref="#icon-cart" />
                </svg>
            </span>
        </span>
    );

    return isInCartAlready ? renderAlreadyInCartButton() : renderAddToCartButton();
};
