import React, { useState, useEffect, useRef } from "react";
import { User } from "../auth/authentication.models";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { FullPageLoadingComponent } from "../FullpageLoading.component";
import "./user-badge.scss";
import { WorkspaceModel } from '../workspace-settings/workspace.models';
import { Utils } from '../utils/utils';

export interface UserBadgeStateProps {
  user?: User;
  workspace?: WorkspaceModel;
  useGravityAs?: number;
}

export interface UserBadgeDispatchProps {
  logout: () => Promise<void>;
  changeUseGravityAs: (id?: number) => void;
  openWorkspaceSettings: (id: number) => Promise<void>;
}

const UserBadgeComponent: React.FC<
  UserBadgeStateProps & UserBadgeDispatchProps & RouteComponentProps
> = ({ user, workspace, logout, history, changeUseGravityAs, useGravityAs, openWorkspaceSettings }) => {
  const ref = useRef(null);
  const [isOpen, _setIsOpen] = useState(false);

  // create ref getter/setter to access state in eventlistener
  const isOpenRef = React.useRef(isOpen);
  const setIsOpen = (value: boolean) => {
    isOpenRef.current = value;
    _setIsOpen(value);
  };

  const hiddenProps: any = isOpen ? {} : { hidden: "hidden" };

  const onDocumentClick = (event: any) => {
    if (!isOpenRef.current) {
      return;
    }

    const domNode = ReactDOM.findDOMNode(ref.current);
    if (!domNode || !domNode.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const onEarnOrBuyCredits = () => {
    if (useGravityAs) {
      history.push(`/workspace/${useGravityAs}`);
    } else {
      history.push("/account");
    }
    setIsOpen(false);
  }

  const onAccountSettings = () => {
    history.push("/account");
    setIsOpen(false);
  };

  const onWorkspaceSettings = async () => {
    setIsOpen(false);
    await openWorkspaceSettings(useGravityAs!);
  };

  const onLogout = async () => {
    await logout();
  };

  const onChangeUseGravityAs = (useAsId?: number) => {
    changeUseGravityAs(useAsId);
  };

  // subscribe do document click event when component is mounting
  useEffect(() => {
    document.addEventListener("click", onDocumentClick, true);

    // unscubscribe from event when component is detached
    return () => {
      document.removeEventListener("click", onDocumentClick, true);
    };
    // i'm sure i dont want to listen to anything's change
    // eslint-disable-next-line
  }, []);

  if (!user) {
    return <FullPageLoadingComponent />;
  }

  const renderWorkspaceSettings = () => (
    <button className="icon-button" onClick={() => onWorkspaceSettings()}>
      <span className="icon-button__inner">
        <svg className="icon icon--m ">
          <use xlinkHref="#icon-workspace" />
        </svg>
        <span className="label">Workspace Settings</span>
      </span>
    </button>
  );

  const renderAccountSettings = () => (
    <button className="icon-button" onClick={() => onAccountSettings()}>
      <span className="icon-button__inner">
        <svg className="icon icon--m ">
          <use xlinkHref="#icon-users" />
        </svg>
        <span className="label">Account Settings</span>
      </span>
    </button>
  );

  const uploadCompany = (
    <div className="no-tablet no-mobile">
      <Link to="/upload-company">Upload a Company</Link>
    </div>
  );

  const renderDeactivatedWorkspaces =
    user.deactivatedWorkspaces?.length ? (
      <div className="use-gravity-as">
        <ul>
          {(user.deactivatedWorkspaces || []).map((workspace) => (
            <li
              key={workspace.id}
            >
              <label className="switch switch--checkbox switch--label-right" style={{background: '#73828d'}}>
                <input
                  className="visually-hidden"
                  type="checkbox"
                  id="cb-2"
                  disabled={true}
                  name="cb-2"
                />
                <span className="switch__switch">
                  <span className="dot"></span>
                </span>
                <span className="switch__label label">
                  <span className="fw-b">{workspace.name} (deactivated)</span> <br />
                  <span className="fw-el">(Workspace)</span>
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    ) : null;

  const renderUseGravityAs =
    user.workspaces?.length ? (
      <div className="use-gravity-as">
        <span className="title title-s">Use Gravity as ...</span>
        <hr />
        <ul>
          <li>
            {" "}
            <label className="switch switch--checkbox switch--label-right">
              <input
                className="visually-hidden"
                type="checkbox"
                id="cb-2"
                name="cb-2"
                checked={useGravityAs === undefined}
                onChange={(evt: any) => {
                  onChangeUseGravityAs(undefined);
                }}
              />
              <span className="switch__switch">
                <span className="dot"></span>
              </span>
              <span className="switch__label label">
                <span className="fw-b">{user.name}</span> <br />
                <span className="fw-el">(My Personal Account)</span>
              </span>
            </label>
          </li>
          {(user.workspaces || []).map((workspace) => (
            <li
              key={workspace.id}
            >
              <label className="switch switch--checkbox switch--label-right">
                <input
                  className="visually-hidden"
                  type="checkbox"
                  id="cb-2"
                  name="cb-2"
                  onChange={(evt: any) => {
                    onChangeUseGravityAs(workspace.id);
                  }}
                  checked={useGravityAs === workspace.id}
                />
                <span className="switch__switch">
                  <span className="dot"></span>
                </span>
                <span className="switch__label label">
                  <span className="fw-b">{workspace.name}</span> <br />
                  <span className="fw-el">(Workspace)</span>
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    ) : null;

  return (
    <div className="account" ref={ref}>
      <div className="h-stack h-stack-m ai-c">
        {uploadCompany}
        <button className="user">
          <span className="user__details">
            <span className="user__name no-mobile">
              {
                useGravityAs ? workspace?.workspaceName : user.name
              }
            </span>
            <span className="user__credits">
              <svg className="icon icon--s ">
                <use xlinkHref="#icon-gravity-credit" />
              </svg>
              <span>
                <span className="value" />
                <span className="no-mobile">
                  {
                    useGravityAs ? (workspace ? `${Utils.formatNumber(workspace.creditCount)} credits` : '') : `${Utils.formatNumber(user.creditCount)} credits`
                  }
                </span>
              </span>
            </span>
          </span>
          <span className="user__icons">
            <div className="inner">
              {
                useGravityAs ?
                  <svg className="icon icon--l ">
                    <use xlinkHref="#icon-workspace" />
                  </svg> :
                  <svg className="icon icon--l ">
                    <use xlinkHref="#icon-users" />
                  </svg>
              }
              <svg className="icon icon--l " onClick={() => setIsOpen(true)}>
                <use xlinkHref="#icon-arrow-down" />
              </svg>
            </div>
          </span>
        </button>
        <div className="user-popup f" {...hiddenProps}>
          <div className="stack stack-s">
            <div className="header">
              <div className="h-stack h-stack-xxs ai-c jc-sb">
                <div className="stack stack-xxs">
                  <p>
                    {
                      useGravityAs ? workspace?.workspaceName : user.name
                    }
                  </p>
                  <p className="annotation">
                    {
                      useGravityAs ? 'Workspace' : user.email
                    }
                  </p>
                </div>
                <span className="user__icons">
                  <div className="inner">
                    {
                      useGravityAs ?
                        <svg className="icon icon--l ">
                          <use xlinkHref="#icon-workspace" />
                        </svg> :
                        <svg className="icon icon--l ">
                          <use xlinkHref="#icon-users" />
                        </svg>
                    }
                  </div>
                </span>
              </div>
            </div>
            <div className="h-stack">
              {
                useGravityAs ?
                  <>
                    <span>Credit Balance:</span>
                    <span>
                      <svg className="icon icon--m ">
                        <use xlinkHref="#icon-gravity-credit" />
                      </svg>
                    </span>
                    <span>{Utils.formatNumber(workspace?.creditCount) ?? 0} credits</span>
                  </> :
                  <>
                    <span>Credit Balance:</span>
                    <span>
                      <svg className="icon icon--m ">
                        <use xlinkHref="#icon-gravity-credit" />
                      </svg>
                    </span>
                    <span>{Utils.formatNumber(user.creditCount) ?? 0} credits</span>
                  </>
              }

            </div>
            <p className="annotation">
              Help others by uploading company details or buy credits to reveal
              important information
            </p>
            <div className="actions stack stack-xs">
              <button className="icon-button" onClick={() => onEarnOrBuyCredits()}>
                <span className="icon-button__inner">
                  <svg className="icon icon--m ">
                    <use xlinkHref="#icon-gravity-credit" />
                  </svg>
                  <span className="label">Earn or buy credits</span>
                </span>
              </button>
            </div>
            {renderUseGravityAs}
            <hr />
            {renderDeactivatedWorkspaces}
            {useGravityAs === undefined
              ? renderAccountSettings()
              : renderWorkspaceSettings()}
            <button className="icon-button" onClick={() => onLogout()}>
              <span className="icon-button__inner">
                <svg className="icon icon--m ">
                  <use xlinkHref="#icon-sign-out" />
                </svg>
                <span className="label">Sign Out</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UserBadge = withRouter(UserBadgeComponent);
