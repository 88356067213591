export interface Address {
  Country: string;
  Zip: string;
  City: string;
  Street: string;
  HouseNumber: string;
  Floor: string;
  Door: string;
}

export interface Summary {
  FullName: string;
  ShortName: string;
  FormOfOrganization: string;
  CountryOfFoundation: string;
  NumberOfEmployees: string;
  FoundationYear: string;
  Introduction: string;
  IdentificationNumber: string;
  EntityRelationshipStatus: string;
  Industry: string;
  Sector: string;
}

export interface ProductsAndServices {
  MainServices: string;
  MainProducts: string;
  Brands: string;
}

export interface OtherContactDetails {
  Website: string;
  ContactEmailAddress: string;
  MainTelephoneNumber: string;
}

export interface Financials {
  FinancialItem: string;
  IncomeStatement: any;
}


export interface ManagementContactIndividual {
  Name: string;
  Position: string;
  Location: string;
  Functions: string;
  TelephoneNumber: string;
  EmailAddress: string;
  LinkedIn: string;
}

export interface ManagementContactFrontDeskOrDepartment {
  Location: string;
  TelephoneNumber: string;
  EmailAddress: string;
  Department: string;
  DepartmentOther: string;
}

export interface ManagementContact {
  Individual: ManagementContactIndividual;
  FrontDeskOrDepartment: ManagementContactFrontDeskOrDepartment;
}

export class CompanyAttributeKey {
  static Address: Address = {
    Country: "Country",
    Zip: "Zip",
    City: "City",
    Street: "Street",
    HouseNumber: "HouseNumber",
    Floor: "Floor",
    Door: "Door",
  }
  static mainAddress: string = "MainAddress"
  static businessAddress: string = "BusinessAddress"

  static MainAddress: Address = {
    Country: CompanyAttributeKey.mainAddress + CompanyAttributeKey.Address.Country,
    Zip: CompanyAttributeKey.mainAddress + CompanyAttributeKey.Address.Zip,
    City: CompanyAttributeKey.mainAddress + CompanyAttributeKey.Address.City,
    Street: CompanyAttributeKey.mainAddress + CompanyAttributeKey.Address.Street,
    HouseNumber: CompanyAttributeKey.mainAddress + CompanyAttributeKey.Address.HouseNumber,
    Floor: CompanyAttributeKey.mainAddress + CompanyAttributeKey.Address.Floor,
    Door: CompanyAttributeKey.mainAddress + CompanyAttributeKey.Address.Door,
  }

  static BusinessAddress: Address = {
    Country: CompanyAttributeKey.businessAddress + CompanyAttributeKey.Address.Country,
    Zip: CompanyAttributeKey.businessAddress + CompanyAttributeKey.Address.Zip,
    City: CompanyAttributeKey.businessAddress + CompanyAttributeKey.Address.City,
    Street: CompanyAttributeKey.businessAddress + CompanyAttributeKey.Address.Street,
    HouseNumber: CompanyAttributeKey.businessAddress + CompanyAttributeKey.Address.HouseNumber,
    Floor: CompanyAttributeKey.businessAddress + CompanyAttributeKey.Address.Floor,
    Door: CompanyAttributeKey.businessAddress + CompanyAttributeKey.Address.Door,
  }

  static OtherContactDetails: OtherContactDetails = {
    Website: "OtherContactDetailsWebsite",
    ContactEmailAddress: "OtherContactDetailsContactEmailAddress",
    MainTelephoneNumber: "OtherContactDetailsMainTelephoneNumber",
  }

  static Summary: Summary = {
    FullName: "FullName",
    ShortName: "ShortName",
    FormOfOrganization: "FormOfOrganization",
    CountryOfFoundation: "CountryOfFoundation",
    NumberOfEmployees: "NumberOfEmployees",
    FoundationYear: "FoundationYear",
    Introduction: "Introduction",
    IdentificationNumber: "IdentificationNumber",
    EntityRelationshipStatus: "EntityRelationshipStatus",
    Industry: "Industry",
    Sector: "Sector"
  }

  static ProductsAndServices: ProductsAndServices =
    {
      MainServices: "MainServices",
      MainProducts: "MainProducts",
      Brands: "Brands"
    }

  static Financials: Financials = {
    FinancialItem: "FinancialItem",
    IncomeStatement: {
      NetSales: "IncomeStatementNetSales",
      ExtraordinaryIncome: "IncomeStatementExtraordinaryIncome",
      FinancialIncome: "IncomeStatementFinancialIncome",
      OtherIncome: "IncomeStatementOtherIncome",
      Materials: "IncomeStatementMaterials",
      Personnel: "IncomeStatementPersonnel",
      Utilities: "IncomeStatementUtilities",
      Overhead: "IncomeStatementOverhead",
      FinancialExpenses: "IncomeStatementFinancialExpenses",
      SellingAndAdministrative: "IncomeStatementSellingAndAdministrative",
      DepreciationAndAmortization: "IncomeStatementDepreciationAndAmortization",
      OtherExpenses: "IncomeStatementOtherExpenses",
      TotalNetRevenues: "IncomeStatementTotalNetRevenues",
      TotalExpenses: "IncomeStatementTotalExpenses",
      NetIncomeBeforeTaxes: "IncomeStatementNetIncomeBeforeTaxes",
      CorporateIncomeTaxes: "IncomeStatementCorporateIncomeTaxes",
      NetIncomeAfterTaxes: "IncomeStatementNetIncomeAfterTaxes",
      DividensPaid: "IncomeStatementDividensPaid",
      RetainedEarningsForTheYear: "IncomeStatementRetainedEarningsForTheYear",
    }
  }

  static managementContact: string = "ManagementContact"
  static individual: string = "Individual"
  static frontDeskOrDepartment: string = "FrontDeskOrDepartment"

  static ManagementContact: ManagementContact = {
    Individual: {
      Name: CompanyAttributeKey.managementContact + CompanyAttributeKey.individual + "Name",
      Position: CompanyAttributeKey.managementContact + CompanyAttributeKey.individual + "Position",
      Location: CompanyAttributeKey.managementContact + CompanyAttributeKey.individual + "Location",
      Functions: CompanyAttributeKey.managementContact + CompanyAttributeKey.individual + "Functions",
      TelephoneNumber: CompanyAttributeKey.managementContact + CompanyAttributeKey.individual + "TelephoneNumber",
      EmailAddress: CompanyAttributeKey.managementContact + CompanyAttributeKey.individual + "EmailAddress",
      LinkedIn: CompanyAttributeKey.managementContact + CompanyAttributeKey.individual + "LinkedIn",
    },
    FrontDeskOrDepartment: {
      Location: CompanyAttributeKey.managementContact + CompanyAttributeKey.frontDeskOrDepartment + "Location",
      TelephoneNumber: CompanyAttributeKey.managementContact + CompanyAttributeKey.frontDeskOrDepartment + "TelephoneNumber",
      EmailAddress: CompanyAttributeKey.managementContact + CompanyAttributeKey.frontDeskOrDepartment + "EmailAddress",
      Department: CompanyAttributeKey.managementContact + CompanyAttributeKey.frontDeskOrDepartment + "Department",
      DepartmentOther: CompanyAttributeKey.managementContact + CompanyAttributeKey.frontDeskOrDepartment + "DepartmentOther",
    }
  }

  static Attachment: string = "Attachment";
  static CompanySearchListItem: string = "CompanySearchListItem";
}