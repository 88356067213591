import { PopupActions, PopUpActionTypes } from './popup.actions';
import { PopupDialogType, PopupModel } from './popup.models';
import { popupService } from './popup.service';

export interface PopupState {
  isShown: boolean;
  model: PopupModel;
}

const initialState: PopupState = {
    isShown: false,
    model: {
      type: PopupDialogType.Dialog,
      title: '',
      okButtonText: 'Ok',
      noButtonText: 'Cancel',
      cancelButtonAction: (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();

        popupService.hidePopup();
      }
    }
};

export const popupReducer = (state: PopupState = initialState, action: PopupActions): PopupState => {
    switch (action.type) {
        case PopUpActionTypes.POPUP_TOGGLE: {
          return {
            ...state,
            isShown: !state.isShown
          };
        }
        case PopUpActionTypes.POPUP_SET: {
          return {
            ...state,
            model: action.payload,
          };
        }
        default:
            return state;
    }
};