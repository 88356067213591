import { AppState } from '../../root.reducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from "react-redux";
import { isWorkspaceLoadingSelector, ownershipWorkspaceSelector } from '../workspace.selector';
import { Dispatch } from 'redux';
import { workspaceService } from '../workspace.service';
import { workspaceLoadingAction, workspaceOwnershipLoadedSuccessfulAction } from '../workspace.actions';
import { WorkspaceAcceptOwnershipComponent, WorkspaceAcceptOwnershipComponentDispatchProps, WorkspaceAcceptOwnershipComponentStateProps } from './WorkspaceAcceptOwnership.component';
import { authenticationSuccessAction, changeUseGravityAsAction } from '../../auth/authentication.actions';
import { authenticationService } from '../../auth/authentication.service';

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<{ id: string }>): WorkspaceAcceptOwnershipComponentStateProps => ({
    workspaceId: match.params.id as any as number,
    isLoading: isWorkspaceLoadingSelector(state),
    workspace: ownershipWorkspaceSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): WorkspaceAcceptOwnershipComponentDispatchProps => ({
    loadWorkspace: async (workspaceId: number) => {
        dispatch(workspaceLoadingAction());

        try {
            const response = await workspaceService.getAcceptOwnership(workspaceId);
            if (response.status === 200) {
                dispatch(workspaceOwnershipLoadedSuccessfulAction(response.data))
            } else {
                history.push('/');
            }
        } catch {
            history.push('/');
        }
    },

    acceptOwnership: async (workspaceId: number) => {
        dispatch(workspaceLoadingAction());

        try {
            const response = await workspaceService.acceptOwnership(workspaceId);

            if (response.status === 200) {
                const user = await authenticationService.getUser();
                dispatch(authenticationSuccessAction(user));
                
                dispatch(changeUseGravityAsAction(workspaceId));

                history.push(`/workspace/${workspaceId}`);
            }
        } catch {
            history.push('/');
        }
    },

});

export default withRouter(
    connect<WorkspaceAcceptOwnershipComponentStateProps, WorkspaceAcceptOwnershipComponentDispatchProps, RouteComponentProps<{ id: string }>, AppState>(
        mapStateToProps, mapDispatchToProps
    )(WorkspaceAcceptOwnershipComponent)
);