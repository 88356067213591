import React from "react";
import HomePageSearch from './HomePageSearch.container';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export interface HomePageHeaderComponentStateProps {
}

export interface HomePageHeaderComponentDispatchProps {
}

export const HomePageHeader: React.FC<HomePageHeaderComponentStateProps & RouteComponentProps> = ({ history }) => {
    const goToUploadPage = () => {
        history.push('/upload-company');
    };

    return (
        <div className="section section--header">
            <div className="container">
                <div className="stack stack-xl">
                    <div className="grid">
                        <div className="gi-4 t-gi-3 m-gi-4">
                            <h1 className="stack stack-xs">
                                <span className="logo logo--full logo--white logo--color logo--home-header">
                                    <svg
                                        className="logo__logo">
                                        <use xlinkHref="#logo-full-white-color" />
                                    </svg>
                                    <span className="visually-hidden">Gravity</span>
                                </span>
                                <span className="title title-l">Company information</span>
                                <span className="title title-xl">from you</span>
                            </h1>
                        </div>
                        <div className="gi-8 t-gi-5 m-gi-4">
                            <HomePageSearch />
                        </div>
                    </div>
                    <div className="grid no-tablet">
                        <div className="gi-6">
                            <div className="h-stack h-stack-m ai-fs">
                                <div className="credits">
                                    <div className="credits__inner">
                                        <svg viewBox="0 0 138 134">
                                            <g fill="#fff" fillRule="evenodd">
                                                <path d="M54.41 91.676c-13.547 11.776-34.075 10.34-45.851-3.206-11.776-13.547-10.34-34.075 3.206-45.85 13.546-11.777 34.074-10.341 45.85 3.205C69.391 59.372 67.955 79.9 54.41 91.676zm-7.486-13.415l-10.16-26.468-5.603 2.151.27.705c.19.493.347.797.477 1.03l-.141.054c-1.696-1.047-3.924-1.162-6.286-.256-4.934 1.894-7.08 7.045-4.916 12.684 2.137 5.568 7.25 7.932 12.29 5.998 2.115-.812 3.663-2.255 4.054-3.66l.141-.053a8.3 8.3 0 0 0 .287.85l1.518 3.956-7.507 2.882 2.043 5.322 13.533-5.195zm-14.892-9.933c-2.22.853-4.415-.125-5.362-2.592-.933-2.431.044-4.626 2.264-5.478 2.256-.866 4.45.11 5.384 2.543.933 2.432-.03 4.662-2.286 5.527z" />
                                                <path d="M51.62 132.149c-13.871-3.717-22.102-17.974-18.386-31.844 3.717-13.87 17.974-22.102 31.844-18.385 13.87 3.716 22.101 17.973 18.385 31.844-3.717 13.87-17.974 22.101-31.844 18.385zm6.317-11.74l13.009-18.579-3.934-2.754-.346.495a6.042 6.042 0 0 0-.47.777l-.1-.07c-.063-1.593-.983-3.122-2.641-4.283-3.463-2.425-7.84-1.545-10.612 2.414-2.737 3.908-2.017 8.357 1.52 10.834 1.485 1.04 3.135 1.42 4.24 1.052l.1.07a6.64 6.64 0 0 0-.436.57l-1.945 2.777-5.27-3.69-2.615 3.736 9.5 6.651zm-.075-14.32c-1.558-1.091-1.892-2.984-.68-4.716 1.196-1.707 3.088-2.04 4.647-.95 1.583 1.11 1.917 3.002.722 4.71-1.196 1.706-3.106 2.065-4.689.956zM95.029 84.883c-23.44 0-42.442-19.001-42.442-42.441S71.59 0 95.03 0s42.442 19.002 42.442 42.442-19.002 42.441-42.442 42.441zm4.002-19.333l12.663-34.79-7.366-2.68-.337.926a9.863 9.863 0 0 0-.413 1.423l-.186-.067c-.774-2.485-2.87-4.508-5.974-5.637-6.486-2.36-13.015.876-15.713 8.288-2.664 7.32.35 14.03 6.974 16.44 2.78 1.012 5.543.916 7.13-.132l.186.068a10.84 10.84 0 0 0-.446 1.084l-1.893 5.199-9.868-3.591-2.546 6.995 17.79 6.474zm-6.168-22.547c-2.918-1.063-4.244-3.906-3.064-7.149 1.164-3.196 4.007-4.522 6.926-3.46 2.964 1.08 4.29 3.923 3.127 7.119-1.164 3.196-4.024 4.569-6.989 3.49z" />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <div className="stack stack-xs">
                                        <p className="title title-l">Earn credits while helping others</p>
                                        <p className="title title-m">Upload information on any company you know and receive free downloads</p>
                                        <div>
                                            <button className="button" onClick={() => goToUploadPage()}>
                                                <span className="button__inner">
                                                    <span>Try it for Free</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gi-5 gi-s-8 gi-e-12 t-gi-4 t-gi-s-5 t-gi-e-9 m-gi-4 m-gi-s-1 m-gi-e-4">
                            <p className="title title-m">Share information on your own company, suppliers or customers to earn valuable credits and help others in their searches</p>
                        </div>
                    </div>
                    <div className="grid no-desktop">
                        <div className="t-gi-8 m-gi-4">
                            <p className="title title-l">Earn credits and help others.</p>
                        </div>
                        <div className="t-gi-4 m-gi-4">
                            <div className="h-stack h-stack-m ai-fs">
                                <div className="credits">
                                    <div className="credits__inner">
                                        <svg viewBox="0 0 138 134">
                                            <g fill="#fff" fillRule="evenodd">
                                                <path d="M54.41 91.676c-13.547 11.776-34.075 10.34-45.851-3.206-11.776-13.547-10.34-34.075 3.206-45.85 13.546-11.777 34.074-10.341 45.85 3.205C69.391 59.372 67.955 79.9 54.41 91.676zm-7.486-13.415l-10.16-26.468-5.603 2.151.27.705c.19.493.347.797.477 1.03l-.141.054c-1.696-1.047-3.924-1.162-6.286-.256-4.934 1.894-7.08 7.045-4.916 12.684 2.137 5.568 7.25 7.932 12.29 5.998 2.115-.812 3.663-2.255 4.054-3.66l.141-.053a8.3 8.3 0 0 0 .287.85l1.518 3.956-7.507 2.882 2.043 5.322 13.533-5.195zm-14.892-9.933c-2.22.853-4.415-.125-5.362-2.592-.933-2.431.044-4.626 2.264-5.478 2.256-.866 4.45.11 5.384 2.543.933 2.432-.03 4.662-2.286 5.527z" />
                                                <path d="M51.62 132.149c-13.871-3.717-22.102-17.974-18.386-31.844 3.717-13.87 17.974-22.102 31.844-18.385 13.87 3.716 22.101 17.973 18.385 31.844-3.717 13.87-17.974 22.101-31.844 18.385zm6.317-11.74l13.009-18.579-3.934-2.754-.346.495a6.042 6.042 0 0 0-.47.777l-.1-.07c-.063-1.593-.983-3.122-2.641-4.283-3.463-2.425-7.84-1.545-10.612 2.414-2.737 3.908-2.017 8.357 1.52 10.834 1.485 1.04 3.135 1.42 4.24 1.052l.1.07a6.64 6.64 0 0 0-.436.57l-1.945 2.777-5.27-3.69-2.615 3.736 9.5 6.651zm-.075-14.32c-1.558-1.091-1.892-2.984-.68-4.716 1.196-1.707 3.088-2.04 4.647-.95 1.583 1.11 1.917 3.002.722 4.71-1.196 1.706-3.106 2.065-4.689.956zM95.029 84.883c-23.44 0-42.442-19.001-42.442-42.441S71.59 0 95.03 0s42.442 19.002 42.442 42.442-19.002 42.441-42.442 42.441zm4.002-19.333l12.663-34.79-7.366-2.68-.337.926a9.863 9.863 0 0 0-.413 1.423l-.186-.067c-.774-2.485-2.87-4.508-5.974-5.637-6.486-2.36-13.015.876-15.713 8.288-2.664 7.32.35 14.03 6.974 16.44 2.78 1.012 5.543.916 7.13-.132l.186.068a10.84 10.84 0 0 0-.446 1.084l-1.893 5.199-9.868-3.591-2.546 6.995 17.79 6.474zm-6.168-22.547c-2.918-1.063-4.244-3.906-3.064-7.149 1.164-3.196 4.007-4.522 6.926-3.46 2.964 1.08 4.29 3.923 3.127 7.119-1.164 3.196-4.024 4.569-6.989 3.49z" />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <p className="title title-m">Upload information on any company you know and receive free downloads.</p>
                            </div>
                        </div>
                        <div className="t-gi-4 m-gi-4">
                            <p className="title title-m">Share information on your own company, suppliers or customers to earn valuable credits and help others in their searches.</p>
                        </div>
                        <div>
                            <button className="button" onClick={() => goToUploadPage()}>
                                <span className="button__inner">
                                    <span>Try it for Free</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const HomePageHeaderComponent = withRouter(HomePageHeader);