import React from 'react';

export interface SelectItem {
    label: string;
    value?: any;
}

export interface SelectFieldComponentProps {
    label: string;
    value: any | undefined;
    options: SelectItem[];
    disabled?: boolean;
    onChange: (value: any) => void;
    onBlur?(value: any): void;
    placeholder?: string;
}

export const SelectFieldComponent: React.FC<SelectFieldComponentProps> = ({ label, disabled, options, value, onChange, onBlur, placeholder }) => {
    return (
        <>
            <div className="label">{label}</div>
            <div className="field field--select ">
                <select className="field__field" name="sa-industry" id="sa-industry"
                    placeholder={placeholder ?? "Select an item..."}
                    disabled={disabled ?? false}
                    onBlur={(evt) => onBlur !== undefined ? onBlur(evt.target.value || undefined) : null}
                    value={value} onChange={(evt) => onChange(evt.target.value)}
                >
                    {
                        options.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)
                    }
                </select>
                <div className="field__icon field__icon--right">
                    <svg className="icon icon--m ">
                        <use xlinkHref="#icon-arrow-down" />
                    </svg>
                </div>
            </div>
        </>
    );
};
