import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RegisterModel } from './authentication.models';
import { IndustriesArray } from '../models';
import ReCAPTCHA from 'react-google-recaptcha';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import './register.scss';
import { CountryModel } from '../meta/meta.models';
import { CreditBadgeComponent } from '../company-upload/CreditBadge.component';
import { CreditMap } from '../company-upload/company-upload.models';
import PhoneNumberComponent from '../components/phone-number/PhoneNumberComponent';
import { useDispatch } from 'react-redux';
import { popupSetModelAction } from '../popup/popup.actions';
import { PopupDialogType } from '../popup/popup.models';
import { popupService } from '../popup/popup.service';
import CompanyAutoSuggestComponent from '../components/company-autosuggest/CompanyAutoSuggestComponent';
import queryString from 'query-string';
import { TermsAndConditionsComponent } from './TermsAndConditionsComponent';

export interface RegisterPageComponentStateProps {
    isLoading: boolean;
    isAuthenticatied: boolean;
    countries: CountryModel[] | undefined;
    credits: CreditMap | undefined;
}

export interface RegisterPageComponentDispatchProps {
    register(model: RegisterModel): Promise<number>;
    loadRegisterCredits(): Promise<void>;
}

const RegisterPageComponent: React.FC<RegisterPageComponentStateProps & RegisterPageComponentDispatchProps & RouteComponentProps>
    = ({ isLoading, isAuthenticatied, register, history, match, countries, credits, loadRegisterCredits }) => {
        useEffect(() => {
            loadRegisterCredits();
        }, [loadRegisterCredits])

        const dispatch = useDispatch();
        const [termsAndConditions, setTermsAndConditions] = useState(false);
        const [termsAndConditionTouched, setTermsAndConditionTouched] = useState(false);
        const [naturalPerson, setNaturalPerson] = useState(false);
        const [naturalPersonTouched, setNaturalPersonTouched] = useState(false);
        const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

        const [scrolledToEnd, setScrolledToEnd] = useState(false);

        const code = queryString.parse(window.location.search)?.code?.toString();

        const [model, setModel] = useState({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            captchaToken: '',
            industry: IndustriesArray[0].value,
            phoneNumber: '',
            employeeOfCompanyId: undefined,
            employeeOfCompanyName: '',
            employeeOfCompanyCountryOfFoundation: undefined,
            code: code,
        });

        const [isTouched, setIsTouched] = useState(false);
        const [isPhoneNumberTouched, setPhoneNumberTouched] = useState(false);

        const [validity, setValidity] = useState({
            firstName: true,
            lastName: true,
            email: true,
            password: true,
            passwordConfirm: true,
            industry: true,
            phoneNumber: true,
            employeeOfCompanyId: true,
            employeeOfCompanyName: true,
            employeeOfCompanyCountryOfFoundation: true,
            naturalPerson: undefined,
            termsAndConditions: undefined
        });

        const goToLogin = (evt: React.FormEvent) => {
            evt.preventDefault();
            evt.stopPropagation();

            popupService.hidePopup();
            history.push('/login');
        };

        const submitForm = async (evt: any) => {
            evt.preventDefault();
            evt.stopPropagation();

            setNaturalPersonTouched(true);
            setTermsAndConditionTouched(true);

            const isFormValid = Object.keys(validity).every((key: any) => (validity as any)[key]);
            if (!isFormValid) {
                return;
            }
            const status = await register(model);

            setNaturalPerson(false);
            setTermsAndConditions(false);
            closeTermsAndConditions(evt);

            if (status === 200 || status === 204) {
                dispatch(popupSetModelAction({
                    title: 'Registration successful',
                    body: (
                        <div>
                            We have sent you a confirmation e-mail. Please check your inbox, and confirm your registration.
                        </div>
                    ),
                    type: PopupDialogType.Confirm,
                    okButtonText: 'Ok',
                    okButtonAction: async (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        history.push('/login');
                        popupService.hidePopup();
                    },
                }));
                popupService.showPopup();
            } else if (status === 409) {
                dispatch(popupSetModelAction({
                    title: 'Error',
                    body: (
                        <div>
                            The e-mail address is already registered in Gravity<br />
                            Click <a onClick={(evt) => goToLogin(evt)} href="/">here</a> to sign in.
                        </div>
                    ),
                    type: PopupDialogType.Confirm,
                    okButtonText: 'Ok',
                    okButtonAction: async (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        setModel({
                            ...model,
                            captchaToken: '',
                        });
                        popupService.hidePopup();
                    },
                }));
                popupService.showPopup();
            } else {
                dispatch(popupSetModelAction({
                    title: 'Error',
                    text: 'An error occured during the operation, please try again later',
                    type: PopupDialogType.Confirm,
                    okButtonText: 'Ok',
                    okButtonAction: async (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        setModel({
                            ...model,
                            captchaToken: '',
                        });
                        popupService.hidePopup();
                    },
                }));
                popupService.showPopup();
            }
        }

        const registerUser = async (evt: React.FormEvent) => {
            evt.preventDefault();
            evt.stopPropagation();

            setIsTouched(true);
            setPhoneNumberTouched(true);

            const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]\|:;"'<,.>?\/])[\w~`!@#$%^&*()_\-+={[}\]\|:;"'<,.>?\/]{8,}$/;
            // eslint-disable-next-line no-control-regex
            const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

            const _validity = {
                ...validity,
                firstName: model.firstName !== "",
                lastName: model.lastName !== "",
                email: model.email !== "" && model.email.match(emailRegex) !== null,
                password: model.password !== "" && model.password.match(passwordRegex) !== null,
                passwordConfirm: model.passwordConfirm !== "" && model.passwordConfirm === model.password,
                industry: model.industry !== undefined,
                captchaToken: model.captchaToken !== '',
                naturalPerson: true,
                termsAndConditions: true
            };

            setValidity({
                ..._validity,
                naturalPerson: false,
                termsAndConditions: false
            } as any);

            const isFormValid = Object.keys(_validity).every((key: any) => (_validity as any)[key]);
            if (isFormValid) {
                openTermsAndConditions();
            }
        };

        const onToken = (token: string | null) => {
            if (token != null) {
                setModel({
                    ...model,
                    captchaToken: token,
                });
            }
        };

        let auth2: gapi.auth2.GoogleAuth;
        const loginWithLinkedin = (evt: any) => {
            evt.preventDefault();
            evt.stopPropagation();

            const host = window.location.origin;
            const redirectUri = host + `/api/account/linkedin/callback`;
            const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77u22bj8xfwl5e&redirect_uri=${redirectUri}&state=${code ?? ''}&scope=r_liteprofile%20r_emailaddress`;

            window.location.href = url;
        };

        const loginWithGoogle = (evt: any) => {
            evt.preventDefault();
            evt.stopPropagation();

            gapi.load('auth2', () => {
                auth2 = gapi.auth2.init({
                    client_id: '52656969569-hk3uk6it7k769ljmn4dchcbfvl461vu2.apps.googleusercontent.com',
                });
                const idToken = auth2.currentUser.get().getAuthResponse().id_token;
                if (idToken === undefined) {
                    auth2.signIn().then(user => {
                        const newIdToken = user.getAuthResponse().id_token;
                        const url = `/api/account/verify-google?idToken=${newIdToken}&code=${code ?? ''}`;
                        window.location.href = url;
                    });
                } else {
                    const url = `/api/account/verify-google?idToken=${idToken}&code=${code ?? ''}`;
                    window.location.href = url;
                }
            });
        };

        const isValid = (key: keyof typeof validity): any => {
            return !validity[key] ? { error: 'error' } : {};
        };

        const setValue = (key: keyof typeof validity, value: any): any => {
            setModel({
                ...model,
                [key]: value,
            });
            setValidity({
                ...validity,
                [key]: value !== "",
            });
        };

        if (isAuthenticatied) {
            history.push('/upload-company');
        }

        if (isLoading || !countries || !credits) {
            return (
                <div className="main">
                    <div className="container">
                        <FullPageLoadingComponent /></div>
                </div>
            );
        }

        const countriesOptions = countries.filter(c => c.id).map(c => ({
            name: c.name || '',
            value: c.id || '',
        }));


        const onSuggestionSelected = (event: any, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any) => {
            setModel({
                ...model,
                employeeOfCompanyId: suggestion.companyId,
                employeeOfCompanyName: suggestion.fullName,
                employeeOfCompanyCountryOfFoundation: suggestion.countryOfFoundation
            });
        }

        const openTermsAndConditions = () => {
            document.body.classList.add('register-popup');
            setShowTermsAndConditions(true);
        }

        const closeTermsAndConditions = (evt: any) => {
            evt.preventDefault();
            document.body.classList.remove('register-popup');
            setShowTermsAndConditions(false);
        }

        const handleScroll = (event: any) => {
            var node = event.target;
            const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
            if (bottom) {
                setScrolledToEnd(true);
            }
        }

        const termsAndConditionsPopUp =
            <div className="register-popup-dialog">
                <div className="dialog__dialog">
                    <div className="stack stack-xs">
                        <button className="icon-button dialog__close" id="" onClick={(evt) => closeTermsAndConditions(evt)}>
                            <span className="icon-button__inner">
                                <svg className="icon icon--m ">
                                    <use xlinkHref="#icon-close" />
                                </svg>
                                <span className="visually-hidden">Close</span>
                            </span>
                        </button>
                        <div className="terms-and-conditions" onScroll={(e) => handleScroll(e)}>
                            <TermsAndConditionsComponent />
                            <div>
                                <label className="switch switch--checkbox switch--label-right" {...(termsAndConditionTouched && !termsAndConditions ? { ...{ error: "error" } } : {})}>
                                    <input
                                        className="visually-hidden"
                                        type="checkbox"
                                        id="cb-2"
                                        name="cb-2"
                                        checked={termsAndConditions}
                                        onChange={evt => {
                                            setTermsAndConditions(evt.target.checked);
                                            setTermsAndConditionTouched(true);
                                            setValidity({
                                                ...validity,
                                                termsAndConditions: evt.target.checked as any,
                                            });
                                        }}
                                    />
                                    <span className="switch__switch">
                                        <span className="dot"></span>
                                    </span>
                                    <div className="switch__label label" style={{ fontSize: '12px', paddingTop: '10px', paddingBottom: '10px' }}>
                                        I have read and agree to Gravity's Terms and Conditions, including Privacy Policy and Cookie Policy.
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label className="switch switch--checkbox switch--label-right"  {...(naturalPersonTouched && !naturalPerson ? { ...{ error: "error" } } : {})}>
                                    <input
                                        className="visually-hidden"
                                        type="checkbox"
                                        id="cb-2"
                                        name="cb-2"
                                        checked={naturalPerson}
                                        onChange={evt => {
                                            setNaturalPerson(evt.target.checked);
                                            setNaturalPersonTouched(true);
                                            setValidity({
                                                ...validity,
                                                naturalPerson: evt.target.checked as any,
                                            });
                                        }}
                                    />
                                    <span className="switch__switch">
                                        <span className="dot"></span>
                                    </span>
                                    <div className="switch__label label" style={{ fontSize: '12px' }}>
                                        I hereby declare that I am a natural person and all my personal data provided herein is true and valid.
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="stack stack-m ai-c">
                            <button className="button" onClick={(evt) => submitForm(evt)}>
                                <span className="button__inner">
                                    <span>Register to Gravity</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        return (
            <div className="section section--content">
                <div className="container">
                    <div className="grid">
                        <div className="gi-4 gi-s-5 gi-e-9 t-gi-4 t-gi-s-3 t-gi-e-7 m-gi-4 m-gi-s-1 m-gi-e-4">
                            <div className="stack stack-m">
                                <div className="user-form">
                                    <form className="stack stack-xl">
                                        <div>
                                            <h1 className="stack stack-xs ai-c">
                                                <span className="logo logo--full logo--grey logo--color ">
                                                    <svg className="logo__logo">
                                                        <use xlinkHref="#logo-full-grey-color" />
                                                    </svg>
                                                    <span className="visually-hidden">Gravity</span>
                                                </span>
                                                <span
                                                    className="title title-m">Register</span>
                                            </h1>
                                        </div>
                                        <fieldset>
                                            <div className="stack stack-xs">
                                                <div>
                                                    <p className="label">First Name<span className="required-star" title="Required">*</span></p>
                                                    <div className="field field--input" {...isValid('firstName')}>
                                                        <input className="field__field" value={model.firstName} placeholder="First Name"
                                                            onChange={(evt) => {
                                                                setValue('firstName', evt.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="label">Last Name <span className="required-star" title="Required">*</span></p>
                                                    <div className="field field--input" {...isValid('lastName')}>
                                                        <input className="field__field" value={model.lastName} placeholder="Last Name"
                                                            onChange={(evt) => {
                                                                setValue('lastName', evt.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="label">Your Industry <span className="required-star" title="Required">*</span></p>
                                                    <div className="field field--input" {...isValid('industry')}>
                                                        <select className="field__field"
                                                            value={model.industry}
                                                            onChange={(evt) => {
                                                                setModel({
                                                                    ...model,
                                                                    industry: Number(evt.target.value)
                                                                });
                                                                setValidity({
                                                                    ...validity,
                                                                    industry: evt.target.value !== undefined,
                                                                });
                                                            }}
                                                            name="register-industry" id="register-industry"
                                                            placeholder="Any industry">
                                                            <option value="">Please select</option>
                                                            {
                                                                IndustriesArray.filter(i => i.value !== undefined).map((industryOption) => (
                                                                    <option key={industryOption.key} value={industryOption.value}>{industryOption.label}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <span className="country-lead">
                                                    You will receive additional credit for filling in the Company Name, Country and Phone Number
                                                </span>
                                                <div>
                                                    <CompanyAutoSuggestComponent
                                                        companyName={model.employeeOfCompanyName}
                                                        setCompanyName={(input: any) => {
                                                            setValue('employeeOfCompanyName', input.newValue);
                                                        }}
                                                        onSuggestionSelected={onSuggestionSelected}
                                                    />
                                                </div>
                                                <div>
                                                    <CreditBadgeComponent
                                                        price={credits['AccountCompanyName'].uploadValue}
                                                        hasValue={model.employeeOfCompanyName !== ''}
                                                    />
                                                </div>
                                                <div>
                                                    <label className="label" htmlFor="field-3">
                                                        Country
                                                    </label>
                                                    <div className="field field--input">
                                                        <select className="field__field"
                                                            value={model.employeeOfCompanyCountryOfFoundation}
                                                            onChange={(evt) => {
                                                                setModel({
                                                                    ...model,
                                                                    employeeOfCompanyCountryOfFoundation: Number(evt.target.value) as any
                                                                });
                                                            }}>
                                                            <option value={''}>Select Country</option>
                                                            {countriesOptions.map(option =>
                                                                <option key={option.name} value={option.value}>{option.name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <CreditBadgeComponent
                                                        price={credits['AccountCompanyCountryOfFoundation'].uploadValue}
                                                        hasValue={model.employeeOfCompanyCountryOfFoundation} />
                                                </div>
                                                <div>
                                                    <p className="label">E-mail Address <span className="required-star" title="Required">*</span></p>
                                                    <div className="field field--input" {...isValid('email')}>
                                                        <input className="field__field" value={model.email} placeholder="example@example.com"
                                                            onChange={(evt) => {
                                                                setValue('email', evt.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <PhoneNumberComponent
                                                        label={"Telephone Number"}
                                                        value={model.phoneNumber}
                                                        onBlur={(value: string, isValid: boolean, isAllTouched: boolean) => {
                                                            setPhoneNumberTouched(true);
                                                            setValue('phoneNumber', value);
                                                            setValidity({
                                                                ...validity,
                                                                phoneNumber: isAllTouched ? isValid : false,
                                                            });
                                                        }}
                                                        uploadValueKey={"AccountPhoneNumber"}
                                                        hasCredit={model.phoneNumber !== '' && validity.phoneNumber}
                                                        isTouched={isPhoneNumberTouched}
                                                    />
                                                </div>
                                                <div>
                                                    <p className="label">Set a Password <span className="required-star" title="Required">*</span></p>
                                                    <div className="field field--input" {...isValid('password')}>
                                                        <input className="field__field" value={model.password}
                                                            placeholder=""
                                                            type="password"
                                                            onChange={(evt) => {
                                                                const value = evt.target.value;
                                                                setModel({
                                                                    ...model,
                                                                    password: value,
                                                                });
                                                                setValidity({
                                                                    ...validity,
                                                                    password: value !== "" && (!isTouched || (isTouched && value.length > 8)),
                                                                });
                                                            }} />
                                                    </div>
                                                    <p className="hint annotation">Min. 8 characters, 1 uppercase letter, a special symbol and a number</p>
                                                </div>
                                                <div>
                                                    <p className="label">Your Password again <span className="required-star" title="Required">*</span></p>
                                                    <div className="field field--input" {...isValid('passwordConfirm')}>
                                                        <input className="field__field"
                                                            type="password"
                                                            value={model.passwordConfirm} placeholder=""
                                                            onChange={(evt) => {
                                                                const value = evt.target.value;
                                                                setModel({
                                                                    ...model,
                                                                    passwordConfirm: value,
                                                                });
                                                                setValidity({
                                                                    ...validity,
                                                                    passwordConfirm: value !== "" && (!isTouched || (isTouched && value.length > 8)),
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <ReCAPTCHA
                                                sitekey="6LftRKcZAAAAADUJmN5BRhYsgoUv-aHyCrctbBDu"
                                                onChange={onToken}
                                                size="compact"
                                                hl={'en'}
                                            />
                                            {
                                                isTouched && model.captchaToken === '' ?
                                                    <div className="error">
                                                        Please fill out the captcha
                                                    </div> : null
                                            }
                                        </div>
                                        <div className="buttons">
                                            <div className="stack stack-m">
                                                <div>
                                                    <div className="stack stack-xs">
                                                        <button className="button" onClick={(evt) => registerUser(evt)}>
                                                            <span className="button__inner">
                                                                <span>Register to Gravity</span>
                                                            </span>
                                                        </button>
                                                        <p className="sep">or Register with</p>
                                                        <div className="alternative-options h-stack h-stack-xs jc-c">
                                                            <button onClick={loginWithGoogle} className="button button--secondary ">
                                                                <span className="button__inner">
                                                                    <svg className="icon icon--m ">
                                                                        <use xlinkHref="#icon-google" />
                                                                    </svg>
                                                                    <span>Google</span>
                                                                </span>
                                                            </button>
                                                            <button onClick={loginWithLinkedin} className="button button--secondary ">
                                                                <span className="button__inner">
                                                                    <svg className="icon icon--m ">
                                                                        <use xlinkHref="#icon-linkedin" />
                                                                    </svg>
                                                                    <span>LinkedIn</span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div>
                                                    <div className="stack stack-xs ai-c">
                                                        <p className="title title-s">Already have an account?</p>
                                                        <button className="button button--secondary" onClick={(evt) => goToLogin(evt)}>
                                                            <span className="button__inner">
                                                                <span>Jump to Log In</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                {showTermsAndConditions ? termsAndConditionsPopUp : null}
            </div >
        );
    };

export const RegisterPage = withRouter(RegisterPageComponent);