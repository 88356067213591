import { AxiosInstance } from 'axios';
import { SimpleSearchFields, SearchCountResult, PayForSimpleSearch, SearchCompaniesResponse } from './search.models';
import { SavedCompanyListModel } from '../saved-company/saved-company.models';
import { axiosInstance } from '../axios';

class SearchService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    async getAllCompanyCount(): Promise<number> {
        const companyCount = await this.axios.get('/api/companies/all-count');

        return companyCount.data;
    }

    createSearchData(filter: SimpleSearchFields) {
        return {
            keyword: filter.keyword,
            industry: filter.industry,
            sector: filter.sector,
            revenueCategory: filter.revenueCategory,
            countryOfFoundationId: filter.countryOfFoundationId,
            entityRelationshipStatus: filter.entityRelationshipStatus ? Number(filter.entityRelationshipStatus) : null,
            searchInCompanyNames: filter.searchKeywordsInFields ? filter.searchKeywordsInFields.companynames : true,
            searchInContacts: filter.searchKeywordsInFields ? filter.searchKeywordsInFields.contacts : true,
            searchInAddresses: filter.searchKeywordsInFields ? filter.searchKeywordsInFields.addresses : true,
            searchInProductsAndServices: filter.searchKeywordsInFields ? filter.searchKeywordsInFields.productsAndServices : true,
            searchInAllDetails: filter.searchKeywordsInFields ? filter.searchKeywordsInFields.allOther : true,
            searchInAllAvailableData: filter.searchInAllAvailableData || false,
            numberOfEmployeesFrom: filter.numberOfEmployees?.from,
            numberOfEmployeesTo: filter.numberOfEmployees?.to,
            foundationYearFrom: filter.yearOfFundation?.from,
            foundationYearTo: filter.yearOfFundation?.to,
            revenueFrom: filter.revenue?.from,
            revenueTo: filter.revenue?.to,
            netIncomeAfterTaxesFrom: filter.netIncomeAfterTaxes?.from,
            netIncomeAfterTaxesTo: filter.netIncomeAfterTaxes?.to,
            partOfWebsite: filter.website,
            ceoName: filter.ceo,
            excludeWithNegativeEbit: filter.excludeWithNegativeEbit,
            withContactDetails: filter.withContacts,
            withFinancialReports: filter.withFinancials,
        
        };
    }

    async searchCompanies(filter: SimpleSearchFields): Promise<SearchCountResult> {
        const searchData = this.createSearchData(filter);

        const result = await this.axios.post('/api/companies/search-count', searchData);

        return {
            userCreditCount: result.data.userCreditCount,
            workspaceCreditCount: result.data.workspaceCreditCount,
            resultCount: result.data.resultCount,
            exampleCompany: result.data.exampleCompany
        };
    }

    async purchaseSearchResults(filter: PayForSimpleSearch, useGravityAs: number | undefined): Promise<SearchCompaniesResponse> {
        const searchData = {
            ...this.createSearchData(filter),
            creditCountToSpend: filter.creditCountToSpend
        };

        const route = useGravityAs ? '/api/companies/search-workspace' : '/api/companies/search-user';
        const result = await this.axios.post(route, searchData);

        return result.data;
    }

    async loadSavedUserSearch(id: number, page: number, pageSize: number): Promise<SavedCompanyListModel> {
        const result = await this.axios.get(`/api/companies/user/saved-searches/${id}?page=${page}&pageSize=${pageSize}`);

        return result.data;
    }

    async loadSavedWorkspaceSearch(id: number, page: number, pageSize: number): Promise<SavedCompanyListModel> {
        const result = await this.axios.get(`/api/companies/workspace/saved-searches/${id}?page=${page}&pageSize=${pageSize}`);

        return result.data;
    }

    async deleteSearch(savedSearchId: number) {
        await this.axios.delete(`/api/companies/saved-searches/${savedSearchId}`);
    }

    async exportSearch(savedSearchId: number): Promise<any> {
        return await this.axios.get(`/api/companies/saved-searches/${savedSearchId}/excel`, { responseType: 'blob'})
    }
}

export const searchService = new SearchService();