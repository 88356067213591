import { AxiosInstance } from 'axios';
import { axiosInstance } from '../axios';
import { CompanyAttributeKey } from '../company.constants';
import { CreditModel } from '../saved-company/saved-company.models';
import { CreditMap, PropertyWithPrice } from './company-upload.models';
import { CompanyUploadModel } from './CompanyUpload.model';

class CompanyCreditsService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axiosInstance;
    }

    private creditMap?: CreditMap;

    async loadCompanyCredits(): Promise<CreditMap> {
        const response = await this.axios({
            url: `/api/credits/company`,
            method: 'GET',
        });

        const credits = response.data.credits as CreditModel[];

        const creditMap = credits.reduce((creditMap, credit) => {
            creditMap[credit.key] = credit;
            return creditMap;
        }, {} as CreditMap);

        this.creditMap = {...this.creditMap, ...creditMap};

        return this.creditMap;
    }

    async getRegisterCredits() {
        const response = await this.axios({
            method: 'GET',
            url: '/api/account/register-credits'
        });

        const credits = response.data as CreditModel[];

        const creditMap = credits.reduce((creditMap, credit) => {
            creditMap[credit.key] = credit;
            return creditMap;
        }, {} as CreditMap);
        
        this.creditMap = {...this.creditMap, ...creditMap};

        return this.creditMap;
    }


    getUploadValue(key: string): number {
        if (this.creditMap![key] === undefined) {
            debugger;
        }
        return this.creditMap![key].uploadValue;
    }

    private comparePropertyDiffs(newValue: any, original: any, useArrayMultiplier?: boolean): number {
        return Object.keys(newValue).reduce((sum: number, propertyName: string) => {
            const newProperty = newValue[propertyName] as PropertyWithPrice<any>;
            const originalProperty = original[propertyName] as PropertyWithPrice<any>;
            if (newProperty.value && newProperty.value && newProperty.value !== originalProperty.value) {
                const multiplier = useArrayMultiplier ? (newProperty.value || []).length : 1;
                sum += newProperty.price * multiplier;
            }

            return sum;
        }, 0);
    }

    private getUploadValueSum(object: any, useArrayMultiplier?: boolean): number {
        return Object.values(object).reduce((sum: number, property) => {
            if ((property as PropertyWithPrice<any>).value) {
                const multiplier = useArrayMultiplier ? ((property as any).value || []).length : 1;
                if ((property as PropertyWithPrice<any>).isValid !== false) {
                    sum += (property as PropertyWithPrice<any>).price * multiplier;
                }
            }

            return sum;
        }, 0);
    }

    getTotalUploadValue(company: CompanyUploadModel): number {
        return (
            this.getUploadValueSum(company.summary.businessAddress) +
            this.getUploadValueSum(company.summary) +
            this.getUploadValueSum(company.summary.mainAddress) +
            this.getUploadValueSum(company.summary.otherContactDetails) +
            this.getUploadValueSum(company.productsAndServices) +
            company.financials.reduce((sum, year) => sum +
                this.getUploadValueSum(year.incomeStatement) +
                this.getUploadValueSum(year.balanceSheet),
                0
            ) +
            company.managementContacts.reduce((sum, managementContact) =>
                sum +
                this.getUploadValueSum(managementContact.individual) +
                this.getUploadValueSum(managementContact.frontDeskOrDepartment),
                0) +
            this.getUploadValueSum(company.documentsAndAttachments, true)
        );
    }

    getDiffUploadValue(newValue: CompanyUploadModel, originalValue: CompanyUploadModel): number {
        const addIfDiffers = (arg1: PropertyWithPrice<string | number> | undefined, arg2: PropertyWithPrice<string | number>, multiplierChanged: boolean = false) => {
            const isArg1Empty = arg1?.value === undefined || arg1.value === '';
            const isArg2Empty = arg2?.value === undefined || arg2?.value === '';

            if (!isArg1Empty && multiplierChanged) {
                return arg2.price;
            }

            if (isArg2Empty && arg2.required) return 0;

            const differs = (isArg1Empty && !isArg2Empty) || (!isArg1Empty && isArg2Empty) || (!isArg1Empty && !isArg2Empty && arg1?.value !== arg2?.value);

            return differs ? arg2?.price ?? 0 : 0;
        }

        const addIfArrayDiffers = (arg1: PropertyWithPrice<string[]> | undefined, arg2: PropertyWithPrice<string[]> | undefined) => {
            const isArg1Empty = arg1?.value === undefined || arg1.value.length === 0;
            const isArg2Empty = arg2?.value === undefined || arg2.value.length === 0;

            const differs = (isArg1Empty && !isArg2Empty) || (!isArg1Empty && isArg2Empty) || (arg1 && arg2 && !isArg1Empty && !isArg2Empty && arg2.value?.join(',') !== arg1.value?.join(','));

            return differs && !isArg2Empty ? arg2?.price ?? 0 : 0;
        }

        let price = 0;

        price += addIfDiffers(originalValue.summary.introduction, newValue.summary.introduction);
        price += addIfDiffers(originalValue.summary.industry, newValue.summary.industry);
        price += addIfDiffers(originalValue.summary.sector, newValue.summary.sector);
        price += addIfDiffers(originalValue.summary.formOfOrganization, newValue.summary.formOfOrganization);
        price += addIfDiffers(originalValue.summary.countryOfFoundation, newValue.summary.countryOfFoundation);
        price += addIfDiffers(originalValue.summary.entityRelationshipStatus, newValue.summary.entityRelationshipStatus);
        price += addIfDiffers(originalValue.summary.identificationNumber, newValue.summary.identificationNumber);
        price += addIfDiffers(originalValue.summary.foundationYear, newValue.summary.foundationYear);
        price += addIfDiffers(originalValue.summary.numberOfEmployees, newValue.summary.numberOfEmployees);

        price += addIfDiffers(originalValue.summary.mainAddress.countryId, newValue.summary.mainAddress.countryId);
        price += addIfDiffers(originalValue.summary.mainAddress.city, newValue.summary.mainAddress.city);
        price += addIfDiffers(originalValue.summary.mainAddress.zip, newValue.summary.mainAddress.zip);
        price += addIfDiffers(originalValue.summary.mainAddress.street, newValue.summary.mainAddress.street);
        price += addIfDiffers(originalValue.summary.mainAddress.floor, newValue.summary.mainAddress.floor);
        price += addIfDiffers(originalValue.summary.mainAddress.houseNumber, newValue.summary.mainAddress.houseNumber);
        price += addIfDiffers(originalValue.summary.mainAddress.door, newValue.summary.mainAddress.door);

        price += addIfDiffers(originalValue.summary.businessAddress.countryId, newValue.summary.businessAddress.countryId);
        price += addIfDiffers(originalValue.summary.businessAddress.city, newValue.summary.businessAddress.city);
        price += addIfDiffers(originalValue.summary.businessAddress.zip, newValue.summary.businessAddress.zip);
        price += addIfDiffers(originalValue.summary.businessAddress.street, newValue.summary.businessAddress.street);
        price += addIfDiffers(originalValue.summary.businessAddress.floor, newValue.summary.businessAddress.floor);
        price += addIfDiffers(originalValue.summary.businessAddress.houseNumber, newValue.summary.businessAddress.houseNumber);
        price += addIfDiffers(originalValue.summary.businessAddress.door, newValue.summary.businessAddress.door);

        price += addIfDiffers(originalValue.summary.otherContactDetails.website, newValue.summary.otherContactDetails.website);
        const mainTelephonePrice = addIfDiffers(originalValue.summary.otherContactDetails.mainTelephoneNumber, newValue.summary.otherContactDetails.mainTelephoneNumber);
        if (mainTelephonePrice > 0 && newValue.summary.otherContactDetails.mainTelephoneNumber.isValid) {
            price += mainTelephonePrice;
        }
        price += addIfDiffers(originalValue.summary.otherContactDetails.contactEmailAddress, newValue.summary.otherContactDetails.contactEmailAddress);


        price += addIfArrayDiffers(originalValue.productsAndServices.services, newValue.productsAndServices.services);
        price += addIfArrayDiffers(originalValue.productsAndServices.brands, newValue.productsAndServices.brands);
        price += addIfArrayDiffers(originalValue.productsAndServices.products, newValue.productsAndServices.products);

        newValue.managementContacts.forEach(managementContact => {
            const managementContactId = managementContact.id;
            const original = originalValue.managementContacts.find(mc => mc.id === managementContactId);
            price += addIfDiffers(original?.individual.name, managementContact.individual.name);
            price += addIfDiffers(original?.individual.emailAddress, managementContact.individual.emailAddress);

            price += addIfDiffers(original?.individual.position, managementContact.individual.position);
            price += addIfDiffers(original?.individual.location, managementContact.individual.location);
            price += addIfArrayDiffers(original?.individual.functions, managementContact.individual.functions);

            const individualPhone = addIfDiffers(original?.individual.telephoneNumber, managementContact.individual.telephoneNumber);
            const individualPhoneValue = managementContact.individual.telephoneNumber.value?.replace(/-/g, '');
            if (individualPhone > 0 && managementContact.individual.telephoneNumber.isValid && individualPhoneValue !== '') {
                price += individualPhone;
            }
            price += addIfDiffers(original?.individual.linkedIn, managementContact.individual.linkedIn);

            price += addIfDiffers(original?.frontDeskOrDepartment.location, managementContact.frontDeskOrDepartment.location);

            const frontDeskPhone = addIfDiffers(original?.frontDeskOrDepartment.telephoneNumber, managementContact.frontDeskOrDepartment.telephoneNumber);
            const frontDeskPhoneValue = managementContact.frontDeskOrDepartment.telephoneNumber.value?.replace(/-/g, '');
            if (frontDeskPhone > 0 && managementContact.frontDeskOrDepartment.telephoneNumber.isValid && frontDeskPhoneValue !== '') {
                price += frontDeskPhone;
            }
            price += addIfDiffers(original?.frontDeskOrDepartment.emailAddress, managementContact.frontDeskOrDepartment.emailAddress);
            price += addIfDiffers(original?.frontDeskOrDepartment.department, managementContact.frontDeskOrDepartment.department);
            price += addIfDiffers(original?.frontDeskOrDepartment.departmentOther, managementContact.frontDeskOrDepartment.departmentOther);
        });


        newValue.financials.forEach(financial => {
            const year = financial.year;
            const original = originalValue.financials.find(f => f.year === year);

            const multiplierChanged = newValue.financialMultiplier !== originalValue.financialMultiplier;

            // probalkozas...
            // if (newValue.financialMultiplier !== originalValue.financialMultiplier && original?.incomeStatement.financialExpenses.value) {
            //     financial.incomeStatement.financialExpenses = {
            //         ...financial.incomeStatement.financialExpenses,
            //         value: (parseInt(financial.incomeStatement.financialExpenses.value!) * newValue.financialMultiplier).toString(),
            //     };
            // }

            price += addIfDiffers(original?.incomeStatement.netSales, financial.incomeStatement.netSales, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.extraordinaryIncome, financial.incomeStatement.extraordinaryIncome, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.financialIncome, financial.incomeStatement.financialIncome, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.otherIncome, financial.incomeStatement.otherIncome, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.materials, financial.incomeStatement.materials, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.personnel, financial.incomeStatement.personnel, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.utilities, financial.incomeStatement.utilities, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.overhead, financial.incomeStatement.overhead, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.financialExpenses, financial.incomeStatement.financialExpenses, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.sellingAndAdministrative, financial.incomeStatement.sellingAndAdministrative, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.depreciationAndAmortization, financial.incomeStatement.depreciationAndAmortization, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.otherExpenses, financial.incomeStatement.otherExpenses, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.totalNetRevenues, financial.incomeStatement.totalNetRevenues, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.totalExpenses, financial.incomeStatement.totalExpenses, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.netIncomeBeforeTaxes, financial.incomeStatement.netIncomeBeforeTaxes, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.corporateIncomeTaxes, financial.incomeStatement.corporateIncomeTaxes, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.netIncomeAfterTaxes, financial.incomeStatement.netIncomeAfterTaxes, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.dividensPaid, financial.incomeStatement.dividensPaid, multiplierChanged);
            price += addIfDiffers(original?.incomeStatement.retainedEarningsForTheYear, financial.incomeStatement.retainedEarningsForTheYear, multiplierChanged);

            price += addIfDiffers(original?.balanceSheet.cashAndEquivalents, financial.balanceSheet.cashAndEquivalents, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.accountsReceivable, financial.balanceSheet.accountsReceivable, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.inventories, financial.balanceSheet.inventories, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.otherCurrentAssets, financial.balanceSheet.otherCurrentAssets, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.propertyPlantAndEquipment, financial.balanceSheet.propertyPlantAndEquipment, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.proprietaryRights, financial.balanceSheet.proprietaryRights, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.accumulatedDepreciation, financial.balanceSheet.accumulatedDepreciation, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.otherFixedAssets, financial.balanceSheet.otherFixedAssets, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.accountsPayable, financial.balanceSheet.accountsPayable, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.shortTermLoans, financial.balanceSheet.shortTermLoans, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.taxesPayable, financial.balanceSheet.taxesPayable, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.otherCurrentLiabilities, financial.balanceSheet.otherCurrentLiabilities, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.longTermLoanes, financial.balanceSheet.longTermLoanes, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.shareHoldersLoans, financial.balanceSheet.shareHoldersLoans, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.deferredIncomeTaxes, financial.balanceSheet.deferredIncomeTaxes, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.otherLongTermLiabilites, financial.balanceSheet.otherLongTermLiabilites, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.registeredShares, financial.balanceSheet.registeredShares, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.additionalPaidInCapital, financial.balanceSheet.additionalPaidInCapital, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.accumulatedRetainedEarnings, financial.balanceSheet.accumulatedRetainedEarnings, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.otherOwnersEquity, financial.balanceSheet.otherOwnersEquity, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.totalAssets, financial.balanceSheet.totalAssets, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.totalLiabilites, financial.balanceSheet.totalLiabilites, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.ownersEquity, financial.balanceSheet.ownersEquity, multiplierChanged);
            price += addIfDiffers(original?.balanceSheet.liabilitiesAndOwnersEquity, financial.balanceSheet.liabilitiesAndOwnersEquity, multiplierChanged);
        });

        const documentPrice = creditsService.getUploadValue(CompanyAttributeKey.Attachment);
        price += documentPrice * (newValue.documentsAndAttachments.managementStructure.value?.filter(d => d.isTemporary).length || 0);
        price += documentPrice * (newValue.documentsAndAttachments.otherDocuments.value?.filter(d => d.isTemporary).length || 0);
        price += documentPrice * (newValue.documentsAndAttachments.standardImages.value?.filter(d => d.isTemporary).length || 0);

        return price;
    }
}

export const creditsService = new CompanyCreditsService();