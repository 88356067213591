import { actionCreator } from '../actions';
import { GetAccountQueryModel } from './account.models';

export enum AccountActionTypes {
  ACCOUNT_LOADING = 'account/loading',
  ACCOUNT_LOADING_FINISHED = 'account/loading/finished',
  ACCOUNT_LOADED_SUCCESSFUL = 'account/loaded/successful',
  ACCOUNT_SAVED_SUCCESSFUL = 'account/save/successful',
}

export const accountLoadingAction = actionCreator<AccountActionTypes.ACCOUNT_LOADING, void>(AccountActionTypes.ACCOUNT_LOADING);
export const accountLoadingFinishedAction = actionCreator<AccountActionTypes.ACCOUNT_LOADING_FINISHED, void>(AccountActionTypes.ACCOUNT_LOADING_FINISHED);
export const accountLoadedSuccessfulAction = actionCreator<AccountActionTypes.ACCOUNT_LOADED_SUCCESSFUL, GetAccountQueryModel>(AccountActionTypes.ACCOUNT_LOADED_SUCCESSFUL);
export const accountSavedSuccessfulAction = actionCreator<AccountActionTypes.ACCOUNT_SAVED_SUCCESSFUL, void>(AccountActionTypes.ACCOUNT_SAVED_SUCCESSFUL);

export type AccountActions = ReturnType<typeof accountLoadingAction> |
  ReturnType<typeof accountLoadingFinishedAction> |
  ReturnType<typeof accountLoadedSuccessfulAction> |
  ReturnType<typeof accountSavedSuccessfulAction>;