import { actionCreator } from '../actions';
import { BillingModel, IpDetails } from './billing.model';

export enum BillingActionTypes {
    BILLING_LOADING = 'billing/loading',
    BILLING_DATA_SUPPLIED = 'billing/data/supplied',
    BILLING_TOKEN_GENERATED_SUCCESSFUL = 'billing/token-generated/successful',
    BILLING_VAT_LOADING = 'billing/vat/loading',
    BILLING_PAYPALCLIENTID = 'billing/vat/getPaypalClientId',
    BILLING_CHECKOUT_LOADING = 'billing/checkout/loading',
    BILLING_IP_CHECKED = 'billing/ipdetails/checked'
}

export const billingLoadingAction = actionCreator<BillingActionTypes.BILLING_LOADING, void>(BillingActionTypes.BILLING_LOADING);
export const billingCheckoutLoadingAction = actionCreator<BillingActionTypes.BILLING_CHECKOUT_LOADING, void>(BillingActionTypes.BILLING_CHECKOUT_LOADING);
export const billingDataSuppliedAction = actionCreator<BillingActionTypes.BILLING_DATA_SUPPLIED, BillingModel>(BillingActionTypes.BILLING_DATA_SUPPLIED);
export const paypalClientTokenGeneratedSuccessfulAction = actionCreator<BillingActionTypes.BILLING_TOKEN_GENERATED_SUCCESSFUL, string>(BillingActionTypes.BILLING_TOKEN_GENERATED_SUCCESSFUL);
export const billingVatLoadingAction = actionCreator<BillingActionTypes.BILLING_VAT_LOADING, void>(BillingActionTypes.BILLING_VAT_LOADING);
export const billingIpCheckedAction = actionCreator<BillingActionTypes.BILLING_IP_CHECKED, IpDetails>(BillingActionTypes.BILLING_IP_CHECKED);
export const billingPaypalClientLoadedAction = actionCreator<BillingActionTypes.BILLING_PAYPALCLIENTID, string>(BillingActionTypes.BILLING_PAYPALCLIENTID);

export type BillingActions = 
  ReturnType<typeof billingLoadingAction> |
  ReturnType<typeof billingCheckoutLoadingAction> |
  ReturnType<typeof billingDataSuppliedAction> |
  ReturnType<typeof paypalClientTokenGeneratedSuccessfulAction> |
  ReturnType<typeof billingIpCheckedAction> |
  ReturnType<typeof billingPaypalClientLoadedAction> |
  ReturnType<typeof billingVatLoadingAction>;