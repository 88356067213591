import { AppState } from "../root.reducer";

export const isAuthenticationLoadingSelector = (state: AppState) => state.authentication.isLoading;
export const authenticationErrorSelector = (state: AppState) => state.authentication.error;
export const isAuthenticatedSelector = (state: AppState) => state.authentication.user !== undefined;

export const resetPasswordEmailSelector = (state: AppState) => state.authentication.resetPasswordEmail;
export const isRegisterCreditsLoading = (state: AppState) => state.authentication.isregisterCreditsLoading;
export const registerCredits = (state: AppState) => state.authentication.registerCredits;

export const userSelector = (state: AppState) => state.authentication.user;
export const gravityUseAsSelector = (state: AppState) => state.authentication.useGravityAs;