import React, { useEffect } from 'react';
import { SavedCompanyModel } from './saved-company.models';
import { SavedCompanyDescription } from './SavedCompanyDescription.component';
import SavedCompanyTabs from './savedCompanyTabs/SavedCompanyTabs.container';
import Cart from '../cart/Cart.container';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import { CartItem } from '../cart/cart.models';

export interface SavedCompanyDispatchProps {
    loadCompany(id: number, useGravityAs?: number): void;
    loadDiscount(): Promise<void>;
    resetCompany(): void;
    addPropertiesToCart: (items: CartItem[]) => void;
}

export interface SavedCompanyStateProps {
    companyId: number;
    isLoading: boolean;
    savedCompany?: SavedCompanyModel;
    savedSearchId?: number;
    useGravityAs?: number | undefined;
    discount: number;
}

const SavedCompanyComp: React.FC<SavedCompanyStateProps & SavedCompanyDispatchProps & RouteComponentProps> = ({
    companyId,
    savedSearchId,
    isLoading,
    savedCompany,
    loadCompany,
    loadDiscount,
    resetCompany,
    history,
    useGravityAs,
    addPropertiesToCart
}) => {
    useEffect(() => {
        loadCompany(companyId, useGravityAs);
        loadDiscount();

        return () => {
            resetCompany();
        };
    }, [loadDiscount, loadCompany, resetCompany, companyId, useGravityAs]);

    useEffect(() => {
        if (savedCompany) {
            const cartStorage = localStorage.getItem('savedCompanyCart');
            const cart = JSON.parse(cartStorage || '{}');
            if (cart.savedCompanyId !== savedCompany.id) {
                const data = {
                    savedCompanyId: savedCompany.id,
                    items: []
                }
                localStorage.setItem('savedCompanyCart', JSON.stringify(data));
            } else {
                addPropertiesToCart(cart.items);
            }
        }
    }, [savedCompany, addPropertiesToCart]);

    const goToUploadPage = () => {
        history.push('/upload-company');
    };
    const goBackToSearch = () => {
        history.push(`/saved-search/${savedSearchId}`);
    };

    if (isLoading || !savedCompany) {
        return <FullPageLoadingComponent />;
    }

    return (
        <div className="section section--content">
            <div className="container">
                <div className="grid">
                    <div className="gi-8">
                        <div className="stack stack-m">
                            <div className="cp-header">
                                <div className="h-stack h-stack-xs ai-fe jc-fe m-fd-c">
                                    <div>
                                        <button className="button" onClick={() => goToUploadPage()}>
                                            <span className="button__inner">
                                                <span>Upload a Company</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <SavedCompanyDescription savedCompany={savedCompany} />
                            </div>
                            <div>
                                <SavedCompanyTabs savedCompany={savedCompany} />
                            </div>
                        </div>
                    </div>
                    <div className="gi-4">
                        <Cart savedCompanyId={savedCompany.id} />
                    </div>
                </div>
                <div className="gi-4 no-desktop no-tablet">
                    <Cart savedCompanyId={savedCompany.id} />
                </div>
            </div>
        </div>
    );
};

export const SavedCompanyComponent = withRouter(SavedCompanyComp);