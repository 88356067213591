import React, { useEffect, useState } from 'react';
import { CreditBadgeComponent } from './CreditBadge.component';
import { creditsService } from './credits.service';

export interface TextFieldWithPriceComponentProps {
    value?: string | number;
    disabled?: boolean;
    uploadValueKey: string;
    isMultiline?: boolean;
    label: string;
    isRequired?: boolean;
    isTouched?: boolean;
    customValidation?: (value: any) => boolean;
    type?: 'number' | 'text' | 'textarea';
    allowNegative?: boolean;
    onBlur: (value?: string) => void;
    originalValue?: any;
    isEditMode?: boolean;
    onlyAlphabet?: boolean;
    enabledRegex?: string;
    placeholder?: string;
}

export const TextFieldWithPriceComponent: React.FC<TextFieldWithPriceComponentProps> =
    ({
        label,
        type,
        allowNegative,
        isRequired,
        customValidation,
        value,
        originalValue,
        isEditMode,
        disabled,
        uploadValueKey,
        isMultiline,
        onBlur,
        onlyAlphabet,
        enabledRegex,
        isTouched,
        placeholder
    }) => {
        const [localValue, setLocalValue] = useState<string | number | undefined>('');

        useEffect(() => {
            if (value !== localValue) {
                setLocalValue(value || '');
            }
        }, [value])

        const containsAny = (str: any, substrings: string[]) => {
            for (let i = 0; i !== substrings.length; i++) {
                const substring = substrings[i];
                if (str.indexOf(substring) !== - 1) {
                    return substring;
                }
            }
            return null;
        };
        const invalidChars = ['~', '=', '^', '"', "'", '+', '!', '/', '%'];
        const validate = () => {
            if (customValidation) {
                return isTouched && !customValidation(value);
            }
            if (value && containsAny(value, invalidChars)) {
                return isTouched;
            }
            if (type === "number" && value && !allowNegative && value < 0) {
                return true;
            }
            return isRequired && isTouched && !value;
        };

        const errorProps = validate() ? { error: 'error' } : {};
        const requiredStar = isRequired ? <span className="required-star" title="Required">*</span> : null;

        const hasCredit = isEditMode
            ? !validate() && ((!value && originalValue) || (value && !originalValue) || (value !== undefined && originalValue !== undefined && value !== originalValue))
            : !!value;

        const onChange = (newvalue: string) => {
            if (newvalue === '') {
                setLocalValue(newvalue);
            } else if (enabledRegex && newvalue.match(enabledRegex)) {
                setLocalValue(newvalue);
            } else if (onlyAlphabet && newvalue.match(/^[A-Za-z ]+$/)) {
                setLocalValue(newvalue);
            } else if (!onlyAlphabet && !enabledRegex) {
                setLocalValue(newvalue);
            }
        }

        return (
            <div className="stack stack-xs">
                <div>
                    <div className="label">{label}{requiredStar}</div>
                    <div className={`field ${isMultiline ? 'field--multiline' : 'field--input'}`} {...errorProps as any}>
                        {
                            type === 'number' || type === 'text' ?
                                <input
                                    className="field__field"
                                    disabled={disabled}
                                    type={type}
                                    value={localValue}
                                    onChange={evt => onChange(evt.target.value)}
                                    min="0"
                                    placeholder={placeholder ?? `Enter ${label}`}
                                    onBlur={() => onBlur(localValue as any)}
                                />
                                : type === 'textarea' ?
                                    <textarea
                                        className="field__field"
                                        disabled={disabled}
                                        defaultValue={value}
                                        placeholder={placeholder ?? `Enter ${label}`}
                                        onBlur={(evt) => onBlur(evt.target.value)} /> : ""
                        }
                    </div>
                </div>
                <div>
                    <CreditBadgeComponent
                        price={creditsService.getUploadValue(uploadValueKey as string)}
                        hasValue={hasCredit} />
                </div>
            </div>
        );
    };
