import { connect } from 'react-redux';
import { AppState } from '../../root.reducer';
import { RouteProps, withRouter } from 'react-router-dom';
import { EmptySearchResultsComponent, EmptySearchResultsComponentProps } from './EmptySearchResults.component';
import { companyCountSelector } from '../search.selectors';
import { gravityUseAsSelector } from "../../auth/authentication.selectors";

const mapStateToProps = (state: AppState): EmptySearchResultsComponentProps => ({
  allCompanyCount: companyCountSelector(state),  
  useGravityAs: gravityUseAsSelector(state),
});

export default withRouter(connect<EmptySearchResultsComponentProps, {}, RouteProps, AppState>(
  mapStateToProps
)(EmptySearchResultsComponent));