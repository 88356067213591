import { actionCreator } from '../actions';
import { CreditHistoryModel } from './credit-history.models';

export enum CreditHistoryActionTypes {
    CREDIT_HISTORY_LOADING = 'credit-history/loading',
    CREDIT_HISTORY_LOADED_SUCCESSFUL = 'credit-history/loaded/successful'
}

export const creditHistoryLoadingAction = actionCreator<CreditHistoryActionTypes.CREDIT_HISTORY_LOADING, void>(CreditHistoryActionTypes.CREDIT_HISTORY_LOADING);
export const creditHistoryLoadedSuccessfulAction = actionCreator<CreditHistoryActionTypes.CREDIT_HISTORY_LOADED_SUCCESSFUL, CreditHistoryModel>(CreditHistoryActionTypes.CREDIT_HISTORY_LOADED_SUCCESSFUL);

export type CreditHistoryActions = 
  ReturnType<typeof creditHistoryLoadingAction> |
  ReturnType<typeof creditHistoryLoadedSuccessfulAction>;