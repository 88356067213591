import React, { useState } from 'react';
import { PropertyWithPrice, TouchedManagementContactsModel } from '../company-upload.models';
import { CompanyUploadModel } from '../CompanyUpload.model';
import { ManagementContactComponent } from './ManagementContact.component';

const MAX_NUMBER_OF_MANAGEMENT_CONTACTS = 10;

export interface ManagementContactInfoCompanyUploadTabProps {
  isEditMode?: boolean;
  originalValue?: CompanyUploadModel['managementContacts'];
  touchedInputs: TouchedManagementContactsModel;
  addManagementContact(): void;
  deleteManagementContact(index: number): void;
  managementContacts: CompanyUploadModel['managementContacts'];
  updateIndividual: (key: keyof CompanyUploadModel['managementContacts'][0]['individual'], index: number, value: any) => void;
  updateFrontDesk: (key: keyof CompanyUploadModel['managementContacts'][0]['frontDeskOrDepartment'], index: number, value: any) => void;
  isValid: () => boolean;
}

export const ManagementContactInfoCompanyUploadTabComponent: React.FC<ManagementContactInfoCompanyUploadTabProps> = ({
  isEditMode,
  originalValue,
  managementContacts,
  updateIndividual,
  updateFrontDesk,
  addManagementContact,
  deleteManagementContact,
  touchedInputs,
  isValid
}) => {
  const [openInfoIndex, setOpenInfoIndex] = useState(-1);

  const addContact = () => {
    addManagementContact();
    setTimeout(() => {
      setOpenInfoIndex(managementContacts.length - 1);
    });
  };

  const deleteContact = (index: number) => {
    managementContacts.splice(index, 1);
    deleteManagementContact(index);
    setOpenInfoIndex(index === openInfoIndex ? - 1 : index < openInfoIndex ? openInfoIndex - 1 : openInfoIndex);
  };

  const renderAddContactButton = () => (
    <p>
      <button className="button button--secondary" onClick={() => addContact()}>
        <span className="button__inner">
          <svg className="icon icon--m">
            <use xlinkHref="#icon-add" />
          </svg>
          <span>Add a Contact</span>
        </span>
      </button>
    </p>
  );

  const renderEmpty = () => (
    <div className="stack stack-xs">
      <p>
        Add a contact you know from this company to help others get in touch with this company's management.<br />You can add maximum {MAX_NUMBER_OF_MANAGEMENT_CONTACTS} contacts.
        </p>
      {renderAddContactButton()}
    </div >
  );

  const _updateIndividual = (key: keyof CompanyUploadModel['managementContacts'][0]['individual'], index: number, value: any) => {
    managementContacts[index]['individual'][key] = value;
    updateIndividual(key, index, value);
  };

  const _updateFrontDesk = (key: keyof CompanyUploadModel['managementContacts'][0]['frontDeskOrDepartment'], index: number, value: any) => {
    managementContacts[index]['frontDeskOrDepartment'][key] = value;
    updateFrontDesk(key, index, value);
  };

  const renderInfos = () => {
    return (
      <ul className="list">
        {
          managementContacts.map((contactInfo, index) => (
            <li key={index}>
              <ManagementContactComponent
                isEditMode={isEditMode}
                isValid={isValid}
                index={index + 1}
                originalValue={originalValue?.[index]}
                touchedInputs={touchedInputs.managementContacts[index]}
                isOpen={index === openInfoIndex}
                managementContact={contactInfo}
                updateIndividual={(key, value) => {
                  const info = contactInfo.individual[key] as PropertyWithPrice<string>;
                  info.value = value;
                  _updateIndividual(key, index, info);
                }}
                updateFrontDesk={(key, value) => {
                  const info = contactInfo.frontDeskOrDepartment[key] as PropertyWithPrice<string>;
                  info.value = value;
                  _updateFrontDesk(key, index, info);
                }}
                deleteContact={() => deleteContact(index)}
                addContact={addContact}
                toggleCollapse={() => {
                  setOpenInfoIndex(openInfoIndex === index ? -1 : index);
                }}
              />
            </li>
          ))
        }
        {openInfoIndex < 0 ? renderAddContactButton() : null}
      </ul>
    );
  };

  return (
    <div className="stack stack-m">
      <div className="upload-form__section">
        <div className="stack stack-m">
          <span className="icon-header icon-header--header-right">
            <span className="icon-header__inner">
              <svg className="icon icon--l ">
                <use xlinkHref="#icon-edit" />
              </svg>
              <span className="title title-s">Management Contacts</span>
            </span>
          </span>
          {
            managementContacts.length === 0 ? renderEmpty() : renderInfos()
          }
        </div>
      </div>
    </div>
  );
};