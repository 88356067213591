import React from 'react';

export const SvgCollection: React.FC = () => (
    <svg style={{ display: 'none' }}>
        <symbol id="icon-add" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M12 4.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15zm0-1.25a8.75 8.75 0 1 1 0 17.5 8.75 8.75 0 0 1 0-17.5zm3.75 8.125v1.25h-3.125v3.125h-1.25v-3.125H8.25v-1.25h3.125V8.25h1.25v3.125h3.125z" />
            </g>
        </symbol>
        <symbol id="icon-arrow-down" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M12 15.75L5.75 9.5l.875-.875L12 14l5.375-5.375.875.875z" />
            </g>
        </symbol>
        <symbol id="icon-arrow-left" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M8.438 12.188l6.25-6.25.874.875-5.374 5.375 5.374 5.374-.874.875z" />
            </g>
        </symbol>
        <symbol id="icon-arrow-nav-back" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M10.125 18.25l.881-.881-4.737-4.744h13.856v-1.25H6.269l4.737-4.744-.881-.881L3.875 12z" />
            </g>
        </symbol>
        <symbol id="icon-arrow-nav-forward" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M13.875 18.25l-.881-.881 4.737-4.744H3.875v-1.25h13.856l-4.737-4.744.881-.881 6.25 6.25z" />
            </g>
        </symbol>
        <symbol id="icon-arrow-right" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M15.563 12.188l-6.25-6.25-.876.875 5.376 5.375-5.376 5.374.876.875z" />
            </g>
        </symbol>
        <symbol id="icon-arrow-up" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M12 8.625l-6.25 6.25.875.875L12 10.375l5.375 5.375.875-.875z" />
            </g>
        </symbol>
        <symbol id="icon-available" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M10.4 15.2l-3.704-3.8-.9.9L9.5 16.1l.9.9 8.5-8.5-.9-.9z" />
            </g>
        </symbol>
        <symbol id="icon-bank-card-mastercard" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M19.53 5.625a1.538 1.538 0 0 1 1.558 1.386l.007.114v9.75a1.538 1.538 0 0 1-1.45 1.498l-.115.002H4.66a1.538 1.538 0 0 1-1.558-1.386l-.007-.114v-9.75a1.538 1.538 0 0 1 1.45-1.498l.115-.002h14.87zM9.415 8.491a3.884 3.884 0 0 0-.165 7.765l.164.003a3.834 3.834 0 0 0 2.216-.714l.158-.12.044-.025a.188.188 0 0 1 .144 0l.044.025.159.12a3.844 3.844 0 0 0 2.02.708l.2.006a3.884 3.884 0 0 0 .167-7.764l-.164-.004a3.847 3.847 0 0 0-2.217.715l-.159.12a.186.186 0 0 1-.187.025l-.044-.026-.159-.12a3.832 3.832 0 0 0-2.022-.709l-.2-.005zm4.983 1.125l.147.004a2.763 2.763 0 0 1 2.609 2.609l.004.146-.004.146a2.763 2.763 0 0 1-2.61 2.61l-.146.003-.172-.006a2.7 2.7 0 0 1-1.282-.43l-.141-.1-.034-.032a.188.188 0 0 1-.035-.172l.019-.043.09-.16a3.84 3.84 0 0 0 .005-3.624l-.095-.168-.02-.043a.189.189 0 0 1 .036-.172l.034-.033.141-.098a2.7 2.7 0 0 1 1.282-.43l.172-.007z" />
            </g>
        </symbol>
        <symbol id="icon-bank-card-visa" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M19.435 5.625H4.565A1.538 1.538 0 0 0 3 7.125v9.75c.02.845.72 1.515 1.565 1.5h14.87a1.537 1.537 0 0 0 1.565-1.5v-9.75a1.537 1.537 0 0 0-1.565-1.5zM9.39 10.661l-1.477 3.082a.563.563 0 0 1-.508.32H6.89a.563.563 0 0 1-.56-.506l-.235-2.325a.187.187 0 0 0-.186-.169h-.284a.562.562 0 1 1 0-1.125h.96c.29 0 .531.219.56.506l.126 1.233a.187.187 0 0 0 .355.062l.75-1.564a.563.563 0 0 1 1.015.487zm1.391 2.973a.562.562 0 0 1-1.103-.222l.61-3.048a.563.563 0 1 1 1.102.222l-.609 3.048zm3.907-3.05a.566.566 0 0 1-.684.402c-.194-.049-.585-.115-.75.046-.165.161.269.375.361.43.943.578 1.014 1.18.906 1.585a1.504 1.504 0 0 1-.744.944 1.676 1.676 0 0 1-.795.188 2.795 2.795 0 0 1-.912-.171.563.563 0 1 1 .375-1.062c.253.12.542.138.808.051a.254.254 0 0 0 .165-.237c-.016-.169-.262-.263-.387-.339-1.162-.712-1.05-1.455-.75-1.95.553-.921 1.86-.61 2.008-.571.297.08.475.385.399.683zm3.358 3.45a.559.559 0 0 1-.607-.515l-.014-.167a.188.188 0 0 0-.187-.172h-.584a.187.187 0 0 0-.17.106l-.224.477a.563.563 0 0 1-1.016-.487l1.45-3.031c.1-.196.3-.32.52-.32l.55.012a.563.563 0 0 1 .55.516l.245 2.977a.563.563 0 0 1-.514.607v-.004zM16.9 11.977a.192.192 0 0 0 .172.276c.17 0 .307-.137.307-.307v-.322a.145.145 0 0 0-.276-.064l-.203.417z" />
            </g>
        </symbol>
        <symbol id="icon-calendar" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M18.25 5.125h-2.5v-1.25H14.5v1.25h-5v-1.25H8.25v1.25h-2.5c-.69 0-1.25.56-1.25 1.25v12.5c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25v-12.5c0-.69-.56-1.25-1.25-1.25zm0 13.75H5.75v-8.75h12.5v8.75zm0-10H5.75v-2.5h2.5v1.25H9.5v-1.25h5v1.25h1.25v-1.25h2.5v2.5z" />
            </g>
        </symbol>
        <symbol id="icon-card-view" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M18.875 3.875H5.125c-.69 0-1.25.56-1.25 1.25v13.75c0 .69.56 1.25 1.25 1.25h13.75c.69 0 1.25-.56 1.25-1.25V5.125c0-.69-.56-1.25-1.25-1.25zm-6.25 1.353h6.25v6.176h-6.25V5.228zm-1.25 6.177h-6.25V5.228h6.25v6.177zm-6.25 1.184h6.25v6.286h-6.25v-6.286zm7.5 6.286v-6.3h6.25v6.3h-6.25z" />
            </g>
        </symbol>
        <symbol id="icon-cart" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M12.625 17h2.5v2.5h-2.5V17zM17 17h2.5v2.5H17V17zm-4.375-4.375h2.5v2.5h-2.5v-2.5zm4.375 0h2.5v2.5H17v-2.5zm2.5-3.75h-3.75v-2.5A1.842 1.842 0 0 0 13.875 4.5h-3.75A1.842 1.842 0 0 0 8.25 6.375v2.5H4.5c-.375 0-.625.25-.625.625v.125l1.188 7.563c.062.625.625 1.062 1.25 1.062h5.062V17H6.312L5.25 10.125H19.5v-1.25zm-10-2.5c0-.375.25-.625.625-.625h3.75c.375 0 .625.25.625.625v2.5h-5v-2.5z" />
            </g>
        </symbol>
        <symbol id="icon-close" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M18 7.05L16.95 6 12 10.95 7.05 6 6 7.05 10.95 12 6 16.95 7.05 18 12 13.05 16.95 18 18 16.95 13.05 12z" />
            </g>
        </symbol>
        <symbol id="icon-company" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M19.5 3.25c.647 0 1.179.492 1.243 1.122l.007.128v16.25H3.25V12c0-.647.492-1.179 1.122-1.243l.128-.007h6.25V4.5c0-.647.492-1.179 1.122-1.243L12 3.25h7.5zm-9.375 11.875h-2.5V19.5h2.5v-4.375zM19.5 4.5H12V12H4.5v7.5h1.875v-5c0-.314.231-.574.533-.618L7 13.875h3.75c.314 0 .574.231.618.533l.007.092v5H19.5v-15zm-5 10v1.25h-1.25V14.5h1.25zm3.75 0v1.25H17V14.5h1.25zm-3.75-3.75V12h-1.25v-1.25h1.25zm3.75 0V12H17v-1.25h1.25zM14.5 7v1.25h-1.25V7h1.25zm3.75 0v1.25H17V7h1.25z" />
            </g>
        </symbol>
        <symbol id="icon-disable" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M3.25 12a8.75 8.75 0 1 0 17.5 0 8.75 8.75 0 0 0-17.5 0zm14.469 4.844L7.156 6.28A7.5 7.5 0 0 1 17.72 16.844zm-10.569.881a7.5 7.5 0 0 1-.875-10.556l10.556 10.556a7.5 7.5 0 0 1-9.681 0z" />
            </g>
        </symbol>
        <symbol id="icon-edit" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M3.25 18.875h17.5v1.25H3.25v-1.25zm14.631-11.25a1.25 1.25 0 0 0 0-1.769L15.644 3.62a1.25 1.25 0 0 0-1.769 0L4.5 12.994V17h4.006l9.375-9.375zM14.756 4.5L17 6.744l-1.875 1.875-2.244-2.244L14.756 4.5zM5.75 15.75v-2.244L12 7.256 14.244 9.5l-6.25 6.25H5.75z" />
            </g>
        </symbol>
        <symbol id="icon-export" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M7.625 10.75V12H5.75v7.5h12.5V12h-1.875v-1.25h1.875c.69 0 1.25.56 1.25 1.25v7.5c0 .69-.56 1.25-1.25 1.25H5.75c-.69 0-1.25-.56-1.25-1.25V12c0-.69.56-1.25 1.25-1.25h1.875zm6.25 3.75v1.25h-3.75V14.5h3.75zm-1.25-11.25v6.356l2.244-2.237.881.881L12 12 8.25 8.25l.881-.881 2.244 2.237V3.25h1.25z" />
            </g>
        </symbol>
        <symbol id="icon-eye" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M21.337 11.787c.05.138.05.288 0 .425A10.431 10.431 0 0 1 12 18.876a10.431 10.431 0 0 1-9.338-6.662.625.625 0 0 1 0-.425A10.431 10.431 0 0 1 12 5.124a10.431 10.431 0 0 1 9.337 6.662zM12 17.625c3.313 0 6.813-2.456 8.081-5.625C18.813 8.831 15.313 6.375 12 6.375c-3.313 0-6.813 2.456-8.081 5.625 1.268 3.169 4.768 5.625 8.081 5.625zm0-9.375a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5zm0 6.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
            </g>
        </symbol>
        <symbol id="icon-favourite" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M12 6.075l1.725 3.487.287.626.625.093 3.85.556-2.737 2.688-.469.456.113.625.656 3.832-3.444-1.807-.606-.256-.581.306-3.444 1.781.625-3.83.113-.626-.463-.481-2.763-2.719 3.85-.556.626-.094.287-.625L12 6.075zm0-2.825L9.156 9.012l-6.356.92 4.6 4.487-1.088 6.331L12 17.762l5.688 2.988-1.088-6.331 4.6-4.482-6.356-.925L12 3.25z" />
            </g>
        </symbol>
        <symbol id="icon-filter" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M13.232 19.5h-2.5c-.69 0-1.25-.56-1.25-1.25v-4.744L4.851 8.875a1.25 1.25 0 0 1-.369-.881V5.75c0-.69.56-1.25 1.25-1.25h12.5c.69 0 1.25.56 1.25 1.25v2.244a1.25 1.25 0 0 1-.369.881l-4.63 4.631v4.744c0 .69-.56 1.25-1.25 1.25zm-7.5-13.75v2.244l5 5v5.256h2.5v-5.256l5-5V5.75h-12.5z" />
            </g>
        </symbol>
        <symbol id="icon-general-details" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M19.5 4.5c.647 0 1.18.492 1.244 1.122l.006.128v12.5a1.25 1.25 0 0 1-1.122 1.244l-.128.006h-15a1.25 1.25 0 0 1-1.244-1.122l-.006-.128V5.75c0-.647.492-1.18 1.122-1.244L4.5 4.5h15zm0 3.75h-15v10h15v-10zm-4.375 5.625v1.25H7v-1.25h8.125zm-1.25-3.125V12H7v-1.25h6.875zm5.625-5h-15V7h15V5.75z" />
            </g>
        </symbol>
        <symbol id="icon-google" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M19.119 10.637H12.13v2.894h4.025c-.375 1.844-1.943 2.9-4.025 2.9a4.431 4.431 0 1 1 0-8.862 4.375 4.375 0 0 1 2.763.987l2.181-2.181A7.5 7.5 0 1 0 12.131 19.5c3.75 0 7.157-2.725 7.157-7.5a5.975 5.975 0 0 0-.17-1.363z" />
            </g>
        </symbol>
        <symbol id="icon-gravity-credit" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M8.27 4.351a8.51 8.51 0 1 1 7.46 15.298A8.51 8.51 0 0 1 8.27 4.351zm.524 7.071c.163 1.554 1.296 2.498 2.702 2.35.59-.062 1.08-.322 1.273-.65l.04-.005.006.143.124 1.196-2.094.22.156 1.484 3.775-.397L14 8.38l-1.563.165.021.196c.015.138.034.225.05.293l-.039.004c-.358-.38-.913-.56-1.572-.49-1.376.144-2.268 1.301-2.103 2.874zm2.652-1.431c.63-.067 1.118.33 1.19 1.008.071.678-.324 1.177-.953 1.243-.62.065-1.108-.33-1.18-1.02-.072-.677.324-1.166.943-1.231z" />
            </g>
        </symbol>
        <symbol id="icon-help" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M12 3.25a8.75 8.75 0 1 0 0 17.5 8.75 8.75 0 0 0 0-17.5zm0 14.375a.937.937 0 1 1 0-1.875.937.937 0 0 1 0 1.875zm.713-4.844v1.563h-1.407v-2.969h1.319a1.488 1.488 0 0 0 0-2.969h-.938c-.819 0-1.484.662-1.487 1.481v.4H8.806v-.4A2.894 2.894 0 0 1 11.7 7h.925a2.894 2.894 0 0 1 .075 5.781h.013z" />
            </g>
        </symbol>
        <symbol id="icon-import" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M7.625 10.75V12H5.75v7.5h12.5V12h-1.875v-1.25h1.875c.69 0 1.25.56 1.25 1.25v7.5c0 .69-.56 1.25-1.25 1.25H5.75c-.69 0-1.25-.56-1.25-1.25V12c0-.69.56-1.25 1.25-1.25h1.875zm6.25 3.75v1.25h-3.75V14.5h3.75zm-1.25-2.5V5.644l2.244 2.237L15.75 7 12 3.25 8.25 7l.881.881 2.244-2.237V12h1.25z" />
            </g>
        </symbol>
        <symbol id="icon-info" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M12 3.25a8.75 8.75 0 1 0 0 17.5 8.75 8.75 0 0 0 0-17.5zm0 3.125a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zm2.5 10.7h-5v-1.4h1.8V11.45h-1.175v-1.4h2.581v5.625H14.5v1.4z" />
            </g>
        </symbol>
        <symbol id="icon-linkedin" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M18.381 4.522H5.62A1.112 1.112 0 0 0 4.5 5.603v12.794a1.106 1.106 0 0 0 1.119 1.081H18.38a1.106 1.106 0 0 0 1.119-1.081V5.603a1.112 1.112 0 0 0-1.119-1.081zM8.944 17.278h-2.2v-7.131h2.2v7.131zM7.869 9.153c-.703 0-1.277-.56-1.294-1.262a1.25 1.25 0 0 1 1.294-1.294 1.294 1.294 0 0 1 0 2.581v-.025zm9.431 8.081h-2.175v-3.487c0-.831 0-1.913-1.162-1.913-1.163 0-1.338.913-1.338 1.832v3.53H10.4v-7.05h2.075v.938h.044a2.325 2.325 0 0 1 2.118-1.162c2.244 0 2.663 1.5 2.663 3.406v3.906z" />
            </g>
        </symbol>
        <symbol id="icon-facebook" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M18.669 4.5H5.33a.838.838 0 0 0-.831.831V18.67a.837.837 0 0 0 .831.831h7.181v-5.8h-1.95v-2.269h1.95V9.763c0-1.938 1.182-2.994 2.92-2.994.58 0 1.162 0 1.743.087v2.019h-1.194c-.944 0-1.125.45-1.125 1.106v1.444h2.25l-.294 2.269h-1.956V19.5h3.813a.837.837 0 0 0 .831-.831V5.33a.837.837 0 0 0-.831-.831z" />
            </g>
        </symbol>
        <symbol id="icon-list-view" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M20.125 5.125c0-.69-.56-1.25-1.25-1.25H5.125c-.69 0-1.25.56-1.25 1.25v13.75c0 .69.56 1.25 1.25 1.25h13.75c.69 0 1.25-.56 1.25-1.25V5.125zm-1.25 0v2.5H5.125v-2.5h13.75zm0 13.75H5.125v-2.5h13.75v2.5zm0-3.75H5.125v-2.5h13.75v2.5zm0-3.75H5.125v-2.5h13.75v2.5z" />
            </g>
        </symbol>
        <symbol id="icon-location" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M12 3.25a6.787 6.787 0 0 1 6.875 6.694 6.581 6.581 0 0 1-1.202 3.785l-.173.234L12 22l-5.5-8.037a6.581 6.581 0 0 1-1.375-4.02A6.788 6.788 0 0 1 12 3.25zm0 1.25a5.55 5.55 0 0 0-5.625 5.444c0 1.109.347 2.187.99 3.085l.166.221L12 19.788l4.506-6.588a5.294 5.294 0 0 0 1.119-3.256A5.55 5.55 0 0 0 12.217 4.5L12 4.5zM12 7a3.125 3.125 0 1 1 0 6.25A3.125 3.125 0 0 1 12 7zm0 1.25A1.875 1.875 0 1 0 12 12a1.875 1.875 0 0 0 0-3.75z" />
            </g>
        </symbol>
        <symbol id="icon-menu" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M4 16.8h16V18H4v-1.2zm0-3.6h16v1.2H4v-1.2zm0-3.6h16v1.2H4V9.6zM4 6h16v1.2H4V6z" />
            </g>
        </symbol>
        <symbol id="icon-not-available" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M12 3.25A8.696 8.696 0 0 1 20.75 12 8.696 8.696 0 0 1 12 20.75 8.696 8.696 0 0 1 3.25 12 8.696 8.696 0 0 1 12 3.25zm0 1.25c-4.125 0-7.5 3.375-7.5 7.5s3.375 7.5 7.5 7.5 7.5-3.375 7.5-7.5-3.375-7.5-7.5-7.5zM8.553 7.695l7.778 7.778-.972.972-7.778-7.778.971-.972z" />
            </g>
        </symbol>
        <symbol id="icon-refresh" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M9.5 8.25H6.237A6.875 6.875 0 0 1 18.875 12h1.25A8.125 8.125 0 0 0 5.75 6.8V4.5H4.5v5h5V8.25zm5 7.5h3.262A6.875 6.875 0 0 1 5.125 12h-1.25a8.125 8.125 0 0 0 14.375 5.2v2.3h1.25v-5h-5v1.25z" />
            </g>
        </symbol>
        <symbol id="icon-revenue" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M4.5 3.25V19.5h16.25v1.25H4.5c-.69 0-1.25-.56-1.25-1.25V3.25H4.5zm16.25 4.375V12H19.5V9.756l-5.181 5.188a.625.625 0 0 1-.888 0l-2.681-2.688-4.119 4.119-.881-.881 4.556-4.563a.625.625 0 0 1 .888 0l2.681 2.688 4.744-4.744h-2.244v-1.25h4.375z" />
            </g>
        </symbol>
        <symbol id="icon-search" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M15.719 14.813a6.794 6.794 0 0 0 1.594-4.376 6.875 6.875 0 1 0-6.875 6.876c1.6 0 3.15-.564 4.374-1.594l4.744 4.719.881-.882-4.718-4.744zm-5.281 1.25a5.625 5.625 0 1 1 0-11.25 5.625 5.625 0 0 1 0 11.25z" />
            </g>
        </symbol>
        <symbol id="icon-settings" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M13.45 3.275a1.25 1.25 0 0 1 1.219.882l.031.124.319 1.575c.188.092.373.192.553.3l.266.169 1.518-.513a1.25 1.25 0 0 1 .4-.062c.397 0 .766.189 1 .502l.081.123 1.476 2.5a1.25 1.25 0 0 1-.139 1.496l-.099.098-1.2 1.05v.956l1.181 1.081c.415.364.539.953.319 1.447l-.063.122-1.425 2.5a1.25 1.25 0 0 1-1.35.598l-.13-.035-1.513-.513a7.175 7.175 0 0 1-.543.325l-.282.144-.319 1.575a1.25 1.25 0 0 1-1.122 1.002l-.128.004h-2.95a1.25 1.25 0 0 1-1.219-.882L9.3 19.72l-.319-1.575a7.094 7.094 0 0 1-.553-.3l-.266-.169-1.518.512a1.25 1.25 0 0 1-.4.063 1.25 1.25 0 0 1-1-.502l-.082-.123-1.474-2.5a1.25 1.25 0 0 1 .155-1.497l.1-.097 1.182-1.05v-.956l-1.181-1.081a1.25 1.25 0 0 1-.319-1.447l.063-.122 1.474-2.5a1.25 1.25 0 0 1 1.082-.625c.09-.006.18-.003.268.01l.132.027 1.487.538c.176-.116.358-.225.543-.325l.282-.144.319-1.575a1.25 1.25 0 0 1 1.122-1.002l.128-.004h2.925zm.025 1.225h-2.95l-.444 2.244a5.538 5.538 0 0 0-1.474.805l-.22.176L6.244 7 4.769 9.5l1.7 1.544c-.1.554-.113 1.12-.043 1.678L6.47 13l-1.7 1.5L6.244 17l2.15-.7c.42.35.885.638 1.384.858l.303.123.444 2.219h2.95l.444-2.244a5.538 5.538 0 0 0 1.474-.805l.22-.176 2.143.725 1.475-2.5-1.7-1.544c.1-.554.113-1.12.043-1.678L17.53 11l1.7-1.5L17.756 7l-2.15.7a5.85 5.85 0 0 0-1.384-.858l-.303-.123-.444-2.219zM12 8.25A3.75 3.75 0 0 1 15.75 12 3.713 3.713 0 0 1 12 15.75a3.75 3.75 0 0 1 0-7.5zm0 1.25A2.444 2.444 0 0 0 9.5 12a2.444 2.444 0 0 0 2.5 2.5 2.444 2.444 0 0 0 2.5-2.5A2.444 2.444 0 0 0 12 9.5z" />
            </g>
        </symbol>
        <symbol id="icon-sign-out" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M13.25 3.25c.647 0 1.179.492 1.243 1.122l.007.128v1.875h-1.25V4.5h-7.5v15h7.5v-1.875h1.25V19.5c0 .647-.492 1.179-1.122 1.243l-.128.007h-7.5a1.251 1.251 0 0 1-1.243-1.122L4.5 19.5v-15c0-.647.492-1.179 1.122-1.243l.128-.007h7.5zm2.5 5L19.5 12l-3.75 3.75-.884-.884 2.242-2.241H8.25v-1.25h8.858l-2.242-2.241.884-.884z" />
            </g>
        </symbol>
        <symbol id="icon-success" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M12 3c-4.9 0-9 4.1-9 9s4.1 9 9 9 9-4 9-9-4-9-9-9zm-1.3 12.5l-3.2-3.2 1-1 2.2 2.2 4.8-4.8 1 1-5.8 5.8z" />
            </g>
        </symbol>
        <symbol id="icon-text-editor-attachment" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M12.207 3A5.212 5.212 0 0 0 7 8.205v8.907a3.835 3.835 0 1 0 7.67 0v-6.849a2.463 2.463 0 0 0-4.926 0v5.823a.748.748 0 1 0 1.496 0v-5.823a.967.967 0 0 1 1.933 0v6.849a2.34 2.34 0 1 1-4.677 0V8.205a3.71 3.71 0 1 1 7.421 0v5.638a.748.748 0 1 0 1.496 0V8.205A5.212 5.212 0 0 0 12.207 3z" />
            </g>
        </symbol>
        <symbol id="icon-text-editor-image" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M5.25 3h13.5a2.25 2.25 0 0 1 2.245 2.096L21 5.25v13.5a2.25 2.25 0 0 1-2.096 2.245L18.75 21H5.25a2.25 2.25 0 0 1-2.245-2.096L3 18.75V5.25l.005-.154a2.25 2.25 0 0 1 2.084-2.09L5.25 3zm13.5 1.5H5.25a.75.75 0 0 0-.743.648L4.5 5.25v13.5c0 .38.282.693.648.743l.102.007h13.5a.75.75 0 0 0 .743-.648l.007-.102V5.25a.75.75 0 0 0-.75-.75zm-4.547 8.243a1.425 1.425 0 0 1 1.843.526l.066.12 2.223 4.443a.375.375 0 0 1-.26.535l-.075.008H6.375a.375.375 0 0 1-.37-.444l.022-.073.08-.189c.328-.755 1.507-3.186 3.096-3.186.951.09 1.832.53 2.471 1.232l.144.167.02.02.025.016c.071.041.159.03.217-.022l.038-.046 1.483-2.543c.141-.244.35-.44.602-.564zM9.76 6a2.25 2.25 0 0 1 .144 4.495l-.154.005H7.5a1.5 1.5 0 0 1-1.493-1.356L6 9l.007-.147a1.5 1.5 0 0 1 .525-1.001l.118-.09.128-.08c.176-.097.37-.157.57-.176L7.5 7.5l.056-.007a.19.19 0 0 0 .093-.06l.03-.05.07-.153A2.25 2.25 0 0 1 9.76 6z" />
            </g>
        </symbol>
        <symbol id="icon-trash" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M19.5 5.75V7h-1.25v12.5a1.25 1.25 0 0 1-1.122 1.244L17 20.75H7a1.25 1.25 0 0 1-1.244-1.122L5.75 19.5V7H4.5V5.75h15zM17 7H7v12.5h10V7zm-6.25 2.5V17H9.5V9.5h1.25zm3.75 0V17h-1.25V9.5h1.25zm0-6.25V4.5h-5V3.25h5z" />
            </g>
        </symbol>
        <symbol id="icon-users" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M12 3.25l.212.005a4.375 4.375 0 1 1-.424 0L12 3.25zm0 1.25a3.125 3.125 0 1 0 0 6.25 3.125 3.125 0 0 0 0-6.25zm6.25 16.25H17v-3.125c0-1.726-1.4-3.125-3.125-3.125h-3.75A3.125 3.125 0 0 0 7 17.625v3.125H5.75v-3.125a4.375 4.375 0 0 1 4.375-4.375h3.75a4.375 4.375 0 0 1 4.375 4.375v3.125z" />
            </g>
        </symbol>
        <symbol id="icon-workspace" viewBox="0 0 32 32">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="#000421"
                    d="M18.473 16.771c2.229 0 3.816 1.774 4.13 4.042l1.187 10.779c0 .446-1.256 1.417-1.702 1.417l-11.959-.23c-.446 0-1.922-.95-1.922-1.397l1.275-10.57c.383-2.234 1.813-4.04 4.041-4.04zm7.482 2.106c2.228 0 2.471.302 3.39 2.597L31 27.3c0 1.337-2.091 2.504-3.429 2.504-1.61.189-2.414.189-2.414 0l-1.026-8.991c-.118-1.097-.297-1.304-.5-1.936zm-17.495 0c-.253.456-.464 1.223-.595 1.936L6.64 29.804c0 .189-.739.189-2.215 0C3.088 29.804 1 28.782 1 27.445l1.51-5.557c.705-2.379 1.303-3.011 3.531-3.011zm-.996-8.291c2.004 0 3.634 1.63 3.634 3.634 0 .617-.154 1.199-.427 1.708-.388.726-1.015 1.305-1.775 1.633-.44.189-.924.294-1.432.294-2.005 0-3.635-1.63-3.635-3.635 0-2.004 1.63-3.634 3.635-3.634zm17.069 0c2.004 0 3.635 1.63 3.635 3.634 0 2.005-1.63 3.635-3.635 3.635-.509 0-.993-.105-1.433-.294-.76-.328-1.387-.907-1.775-1.633-.272-.51-.427-1.09-.427-1.708 0-2.004 1.63-3.634 3.635-3.634zm-8.535-4.53c2.68 0 4.86 2.18 4.86 4.86 0 1.818-1.003 3.405-2.485 4.239-.702.395-1.513.621-2.375.621-.862 0-1.672-.226-2.375-.621-1.482-.834-2.485-2.421-2.485-4.24 0-2.68 2.18-4.86 4.86-4.86z" mask="url(#pzf24vxe8b)" />
            </g>
        </symbol>
        <symbol id="icon-warning" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M20.679 19.514L12.554 3.889a.625.625 0 0 0-1.1 0L3.329 19.514a.625.625 0 0 0 0 .625.625.625 0 0 0 .55.3h16.25a.625.625 0 0 0 .55-.925zm-9.375-10.95h1.406v6.25h-1.406v-6.25zm.7 10a.937.937 0 1 1 0-1.875.937.937 0 0 1 0 1.875z" />
            </g>
        </symbol>
        <symbol id="icon-worksheet" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M14.727 12h1.091v1.143h-1.09V12zm-6.545 0h4.363v1.143H8.182V12zm6.545-2.286h1.091v1.143h-1.09V9.714zm-6.545 0h4.363v1.143H8.182V9.714zm0-2.285h7.636V8.57H8.182V7.43zM16.909 4H7.091C6.489 4 6 4.512 6 5.143v14.286c0 .315.244.571.545.571h.546a.537.537 0 0 0 .436-.229l1.2-1.676 1.2 1.676a.555.555 0 0 0 .437.217c.169 0 .329-.08.436-.217l1.2-1.676 1.2 1.676a.555.555 0 0 0 .436.217c.17 0 .33-.08.437-.217l1.2-1.676 1.2 1.676a.538.538 0 0 0 .436.229h.546a.559.559 0 0 0 .545-.571V5.143C18 4.512 17.511 4 16.91 4zm0 14.476l-1.2-1.676a.555.555 0 0 0-.436-.216c-.17 0-.33.08-.437.216l-1.2 1.676-1.2-1.676a.555.555 0 0 0-.436-.216c-.169 0-.33.08-.436.216l-1.2 1.676-1.2-1.676a.555.555 0 0 0-.437-.216c-.169 0-.329.08-.436.216l-1.2 1.676V5.143h9.818v13.333z" />
            </g>
        </symbol>
        <symbol id="icon-report" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path fill="currentColor"
                    d="M10.25 13.399h1.25v3.584h-1.25V13.4zm2.25-.585h1.25v4.17H12.5v-4.17zm2.25-.83H16v5h-1.25v-5zM8 10.733h1.25v6.25H8v-6.25zm9.625-5.609H15.75V4.5c0-.69-.56-1.25-1.25-1.25h-5c-.69 0-1.25.56-1.25 1.25v.625H6.375c-.69 0-1.25.56-1.25 1.25V19.5c0 .69.56 1.25 1.25 1.25h11.25c.69 0 1.25-.56 1.25-1.25V6.375c0-.69-.56-1.25-1.25-1.25zM9.5 4.5h5V7h-5V4.5zm8.125 15H6.375V6.375H8.25V8.25h7.5V6.375h1.875V19.5z" />
            </g>
        </symbol>
        <symbol id="logo-full-white-mono" viewBox="0 0 185 59">
            <g fill="#fff" fillRule="evenodd">
                <path
                    d="M32.597 58.593v-44.03h-9.322v1.173c0 .82.059 1.348.118 1.758h-.235c-1.876-2.462-5.042-3.869-8.97-3.869C5.98 13.625 0 19.898 0 29.279c0 9.263 6.097 15.536 14.481 15.536 3.518 0 6.566-1.231 7.915-3.049h.234c-.044.44-.055.748-.057 1.394l-.001 6.58H10.084v8.853h22.513zm-16.064-22.69c-3.693 0-6.332-2.638-6.332-6.742 0-4.045 2.639-6.683 6.332-6.683 3.752 0 6.39 2.638 6.39 6.683 0 4.046-2.638 6.743-6.39 6.743zm30.972 9.205V23.533h5.16v-8.97h-15.36v30.545h10.2zm20.361.938c3.987 0 7.211-1.407 9.087-3.87h.235c-.059.411-.117.997-.117 1.818v1.114h9.322V14.563H77.07v.997c0 .938.058 1.348.117 1.641h-.235c-1.7-2.228-4.69-3.576-8.618-3.576-8.501 0-14.891 6.566-14.891 16.24 0 9.615 6.038 16.181 14.422 16.181zm2.287-8.97c-3.753 0-6.45-2.931-6.45-7.387 0-4.338 2.697-7.211 6.45-7.211 3.81 0 6.566 2.873 6.566 7.211 0 4.456-2.756 7.387-6.566 7.387zm40.939 8.032l9.087-30.545h-10.553l-5.277 21.692h-.234L98.78 14.563H88.168l9.146 30.545h13.778zm21.122 0V14.563h-10.2v30.545h10.2zm21.182 0v-8.853h-4.983V23.3h5.862v-8.736h-5.862V7H138.21v7.563h-2.52v8.736h2.52v21.81h15.185zm31.148 13.485v-44.03h-10.201v17.882c0 2.169-1.524 3.752-3.635 3.752-2.17 0-3.694-1.583-3.694-3.752V14.563h-10.2v18.644c0 6.918 4.748 11.96 11.373 11.96 3.4 0 4.983-.997 6.039-1.994h.234a22.713 22.713 0 0 0-.117 2.463v4.104h-13.954v8.853h24.155zM126.921 11.784c3.284 0 5.98-2.58 5.98-5.921 0-3.225-2.696-5.863-5.98-5.863C123.638 0 121 2.638 121 5.863c0 3.342 2.638 5.921 5.921 5.921z" />
            </g>
        </symbol>
        <symbol id="logo-full-white-color" viewBox="0 0 185 59">
            <g fill="none" fillRule="evenodd">
                <path fill="#fff"
                    d="M32.597 58.593v-44.03h-9.322v1.173c0 .82.059 1.348.118 1.758h-.235c-1.876-2.462-5.042-3.869-8.97-3.869C5.98 13.625 0 19.898 0 29.279c0 9.263 6.097 15.536 14.481 15.536 3.518 0 6.566-1.231 7.915-3.049h.234c-.044.44-.055.748-.057 1.394l-.001 6.58H10.084v8.853h22.513zm-16.064-22.69c-3.693 0-6.332-2.638-6.332-6.742 0-4.045 2.639-6.683 6.332-6.683 3.752 0 6.39 2.638 6.39 6.683 0 4.046-2.638 6.743-6.39 6.743zm30.972 9.205V23.533h5.16v-8.97h-15.36v30.545h10.2zm20.361.938c3.987 0 7.211-1.407 9.087-3.87h.235c-.059.411-.117.997-.117 1.818v1.114h9.322V14.563H77.07v.997c0 .938.058 1.348.117 1.641h-.235c-1.7-2.228-4.69-3.576-8.618-3.576-8.501 0-14.891 6.566-14.891 16.24 0 9.615 6.038 16.181 14.422 16.181zm2.287-8.97c-3.753 0-6.45-2.931-6.45-7.387 0-4.338 2.697-7.211 6.45-7.211 3.81 0 6.566 2.873 6.566 7.211 0 4.456-2.756 7.387-6.566 7.387zm40.939 8.032l9.087-30.545h-10.553l-5.277 21.692h-.234L98.78 14.563H88.168l9.146 30.545h13.778zm21.122 0V14.563h-10.2v30.545h10.2zm21.182 0v-8.853h-4.983V23.3h5.862v-8.736h-5.862V7H138.21v7.563h-2.52v8.736h2.52v21.81h15.185zm31.148 13.485v-44.03h-10.201v17.882c0 2.169-1.524 3.752-3.635 3.752-2.17 0-3.694-1.583-3.694-3.752V14.563h-10.2v18.644c0 6.918 4.748 11.96 11.373 11.96 3.4 0 4.983-.997 6.039-1.994h.234a22.713 22.713 0 0 0-.117 2.463v4.104h-13.954v8.853h24.155z" />
                <path fill="#04dead"
                    d="M127.102 11.784c3.283 0 5.98-2.58 5.98-5.921 0-3.225-2.697-5.863-5.98-5.863-3.283 0-5.921 2.638-5.921 5.863 0 3.342 2.638 5.921 5.921 5.921z" />
            </g>
        </symbol>
        <symbol id="logo-full-grey-mono" viewBox="0 0 185 59">
            <g fill="#000421" fillRule="evenodd">
                <path
                    d="M32.597 58.593v-44.03h-9.322v1.173c0 .82.059 1.348.118 1.758h-.235c-1.876-2.462-5.042-3.869-8.97-3.869C5.98 13.625 0 19.898 0 29.279c0 9.263 6.097 15.536 14.481 15.536 3.518 0 6.566-1.231 7.915-3.049h.234c-.044.44-.055.748-.057 1.394l-.001 6.58H10.084v8.853h22.513zm-16.064-22.69c-3.693 0-6.332-2.638-6.332-6.742 0-4.045 2.639-6.683 6.332-6.683 3.752 0 6.39 2.638 6.39 6.683 0 4.046-2.638 6.743-6.39 6.743zm30.972 9.205V23.533h5.16v-8.97h-15.36v30.545h10.2zm20.361.938c3.987 0 7.211-1.407 9.087-3.87h.235c-.059.411-.117.997-.117 1.818v1.114h9.322V14.563H77.07v.997c0 .938.058 1.348.117 1.641h-.235c-1.7-2.228-4.69-3.576-8.618-3.576-8.501 0-14.891 6.566-14.891 16.24 0 9.615 6.038 16.181 14.422 16.181zm2.287-8.97c-3.753 0-6.45-2.931-6.45-7.387 0-4.338 2.697-7.211 6.45-7.211 3.81 0 6.566 2.873 6.566 7.211 0 4.456-2.756 7.387-6.566 7.387zm40.939 8.032l9.087-30.545h-10.553l-5.277 21.692h-.234L98.78 14.563H88.168l9.146 30.545h13.778zm21.122 0V14.563h-10.2v30.545h10.2zm21.182 0v-8.853h-4.983V23.3h5.862v-8.736h-5.862V7H138.21v7.563h-2.52v8.736h2.52v21.81h15.185zm31.148 13.485v-44.03h-10.201v17.882c0 2.169-1.524 3.752-3.635 3.752-2.17 0-3.694-1.583-3.694-3.752V14.563h-10.2v18.644c0 6.918 4.748 11.96 11.373 11.96 3.4 0 4.983-.997 6.039-1.994h.234a22.713 22.713 0 0 0-.117 2.463v4.104h-13.954v8.853h24.155zM127.074 11.784c3.283 0 5.98-2.58 5.98-5.921 0-3.225-2.697-5.863-5.98-5.863-3.283 0-5.921 2.638-5.921 5.863 0 3.342 2.638 5.921 5.921 5.921z" />
            </g>
        </symbol>
        <symbol id="logo-full-grey-color" viewBox="0 0 185 59">
            <g fill="none" fillRule="evenodd">
                <path fill="#000421"
                    d="M32.597 58.593v-44.03h-9.322v1.173c0 .82.059 1.348.118 1.758h-.235c-1.876-2.462-5.042-3.869-8.97-3.869C5.98 13.625 0 19.898 0 29.279c0 9.263 6.097 15.536 14.481 15.536 3.518 0 6.566-1.231 7.915-3.049h.234c-.044.44-.055.748-.057 1.394l-.001 6.58H5.084v8.853h27.513zm-16.064-22.69c-3.693 0-6.332-2.638-6.332-6.742 0-4.045 2.639-6.683 6.332-6.683 3.752 0 6.39 2.638 6.39 6.683 0 4.046-2.638 6.743-6.39 6.743zm30.972 9.205V23.533h5.16v-8.97h-15.36v30.545h10.2zm20.361.938c3.987 0 7.211-1.407 9.087-3.87h.235c-.059.411-.117.997-.117 1.818v1.114h9.322V14.563H77.07v.997c0 .938.058 1.348.117 1.641h-.235c-1.7-2.228-4.69-3.576-8.618-3.576-8.501 0-14.891 6.566-14.891 16.24 0 9.615 6.038 16.181 14.422 16.181zm2.287-8.97c-3.753 0-6.45-2.931-6.45-7.387 0-4.338 2.697-7.211 6.45-7.211 3.81 0 6.566 2.873 6.566 7.211 0 4.456-2.756 7.387-6.566 7.387zm40.939 8.032l9.087-30.545h-10.553l-5.277 21.692h-.234L98.78 14.563H88.168l9.146 30.545h13.778zm21.122 0V14.563h-10.2v30.545h10.2zm21.182 0v-8.853h-4.983V23.3h5.862v-8.736h-5.862V7H138.21v7.563h-2.52v8.736h2.52v21.81h15.185zm31.148 13.485v-44.03h-10.201v17.882c0 2.169-1.524 3.752-3.635 3.752-2.17 0-3.694-1.583-3.694-3.752V14.563h-10.2v18.644c0 6.918 4.748 11.96 11.373 11.96 3.4 0 4.983-.997 6.039-1.994h.234a22.713 22.713 0 0 0-.117 2.463v4.104h-13.954v8.853h24.155z" />
                <path fill="#04dead"
                    d="M127.085 12c3.283 0 5.98-2.58 5.98-5.921 0-3.225-2.697-5.863-5.98-5.863-3.284 0-5.922 2.638-5.922 5.863 0 3.341 2.638 5.921 5.922 5.921z" />
            </g>
        </symbol>
        <symbol id="logo-s-white-mono" viewBox="0 0 49 45">
            <g fill="#fff" fillRule="evenodd">
                <path
                    d="M32.646 44.984V.954h-9.321v1.173c0 .82.058 1.348.117 1.759h-.235c-1.876-2.463-5.042-3.87-8.97-3.87C6.03.016.05 6.29.05 15.67c0 9.263 6.098 15.536 14.481 15.536 3.518 0 6.567-1.23 7.915-3.048h.235c-.044.44-.055.747-.058 1.393l-.001 6.58H10.133v8.853h22.513zm-16.064-22.69c-3.693 0-6.331-2.637-6.331-6.741 0-4.046 2.638-6.684 6.331-6.684 3.753 0 6.39 2.638 6.39 6.684 0 4.045-2.637 6.742-6.39 6.742zM42.97 11.984c3.284 0 5.98-2.58 5.98-5.922C48.95 2.838 46.255.2 42.97.2c-3.282 0-5.92 2.638-5.92 5.862 0 3.342 2.638 5.922 5.92 5.922z" />
            </g>
        </symbol>
        <symbol id="logo-s-white-color" viewBox="0 0 49 45">
            <g fill="none" fillRule="evenodd">
                <path fill="#fff"
                    d="M32.646 44.984V.954h-9.321v1.173c0 .82.058 1.348.117 1.759h-.235c-1.876-2.463-5.042-3.87-8.97-3.87C6.03.016.05 6.29.05 15.67c0 9.263 6.098 15.536 14.481 15.536 3.518 0 6.567-1.23 7.915-3.048h.235c-.044.44-.055.747-.058 1.393l-.001 6.58H10.133v8.853h22.513zm-16.064-22.69c-3.693 0-6.331-2.637-6.331-6.741 0-4.046 2.638-6.684 6.331-6.684 3.753 0 6.39 2.638 6.39 6.684 0 4.045-2.637 6.742-6.39 6.742z" />
                <path fill="#04dead"
                    d="M42.97 11.984c3.284 0 5.98-2.58 5.98-5.922C48.95 2.838 46.255.2 42.97.2c-3.282 0-5.92 2.638-5.92 5.862 0 3.342 2.638 5.922 5.92 5.922z" />
            </g>
        </symbol>
        <symbol id="logo-s-grey-mono" viewBox="0 0 49 45">
            <g fill="#000421" fillRule="evenodd">
                <path
                    d="M32.646 44.984V.954h-9.321v1.173c0 .82.058 1.348.117 1.759h-.235c-1.876-2.463-5.042-3.87-8.97-3.87C6.03.016.05 6.29.05 15.67c0 9.263 6.098 15.536 14.481 15.536 3.518 0 6.567-1.23 7.915-3.048h.235c-.044.44-.055.747-.058 1.393l-.001 6.58H10.133v8.853h22.513zm-16.064-22.69c-3.693 0-6.331-2.637-6.331-6.741 0-4.046 2.638-6.684 6.331-6.684 3.753 0 6.39 2.638 6.39 6.684 0 4.045-2.637 6.742-6.39 6.742zM42.97 11.984c3.284 0 5.98-2.58 5.98-5.922C48.95 2.838 46.255.2 42.97.2c-3.282 0-5.92 2.638-5.92 5.862 0 3.342 2.638 5.922 5.92 5.922z" />
            </g>
        </symbol>
        <symbol id="logo-s-grey-color" viewBox="0 0 49 45">
            <g fill="none" fillRule="evenodd">
                <path fill="#000421"
                    d="M32.646 44.984V.954h-9.321v1.173c0 .82.058 1.348.117 1.759h-.235c-1.876-2.463-5.042-3.87-8.97-3.87C6.03.016.05 6.29.05 15.67c0 9.263 6.098 15.536 14.481 15.536 3.518 0 6.567-1.23 7.915-3.048h.235c-.044.44-.055.747-.058 1.393l-.001 6.58H10.133v8.853h22.513zm-16.064-22.69c-3.693 0-6.331-2.637-6.331-6.741 0-4.046 2.638-6.684 6.331-6.684 3.753 0 6.39 2.638 6.39 6.684 0 4.045-2.637 6.742-6.39 6.742z" />
                <path fill="#04dead"
                    d="M42.97 11.984c3.284 0 5.98-2.58 5.98-5.922C48.95 2.838 46.255.2 42.97.2c-3.282 0-5.92 2.638-5.92 5.862 0 3.342 2.638 5.922 5.92 5.922z" />
            </g>
        </symbol>
    </svg>
);
