import React from 'react';
import { CreditBadgeComponent } from '../CreditBadge.component';
import { creditsService } from '../credits.service';
import { uuid } from '../../utils/uuid';
import { fileUploadService } from '../file-upload.service';
import { AttachmentType, CompanyAttachmentModel } from '../company-upload.models';

export interface DocumentUploadWithPriceTagComponentProps {
    attachmentType: AttachmentType;
    label: string;
    uploadValueKey: string; // CompanyConstants
    value: CompanyAttachmentModel[] | undefined;
    disabled?: boolean;
    onFileUploaded: (fileData: CompanyAttachmentModel) => void;
    onDeleteItem: (index: number) => void;
    isEditMode?: boolean;
}

export const DocumentUploadWithPriceTagComponent: React.FC<DocumentUploadWithPriceTagComponentProps> = ({
    label, uploadValueKey, value, attachmentType, onFileUploaded, onDeleteItem, disabled, isEditMode
}) => {
    const uploadInputId = `upload-${uploadValueKey}-${uuid()}`;

    const uploadDocument = async (files: HTMLInputElement['files']) => {
        if (files && files[0]) {
            const fileData = await fileUploadService.uploadFile(attachmentType, files[0]);
            onFileUploaded(fileData);
        }
    };

    const renderAttachments = () => {
        if (!value || !value.length) {
            return null;
        }

        return (
            <>
                <hr />
                <ul className="list">
                    {value.filter(d => !d.isMarkedForDelete).map((uploadedAttachment, index) => (
                        <li key={uploadedAttachment.id}>
                            <div className="item item-scs">
                                <div className="h-stack h-stack-xs ai-c">
                                    <div className="col col--name" style={{ flex: '1' }}>
                                        <p className="fw-b">
                                            <span className="icon-button__inner">
                                                {uploadedAttachment.fileName}
                                            </span>
                                        </p>
                                    </div>
                                    {
                                        uploadedAttachment.isTemporary? (
                                            <div className="col col--icon">
                                                <button className="icon-button"
                                                    onClick={() => onDeleteItem(index)}>
                                                    <span className="icon-button__inner">
                                                        <svg className="icon icon--m ">
                                                            <use xlinkHref="#icon-trash" />
                                                        </svg>
                                                        <span>Delete this entry</span>
                                                    </span>
                                                </button>
                                            </div>
                                        ) : ""
                                    }
                                </div>
                            </div>
                            <hr />
                        </li>
                    ))}
                </ul>
            </>
        );
    };

    const temporaryCount = (value && value.filter(d => d.isTemporary).length) ?? 0;
    const hasCredit = temporaryCount > 0;
    const szorzo = temporaryCount === 0 ? 1 : temporaryCount;
    return (
        <div className="stack stack-m">
            <div>
                <span className="icon-header icon-header--header-right " id="">
                    <span className="icon-header__inner">
                        <span className="title title-s">{label}</span>
                    </span>
                </span>
                {renderAttachments()}
                <div>
                    <button className="button button--secondary">
                        <span className="button__inner">
                            <svg className="icon icon--m">
                                <use xlinkHref="#icon-add" />
                            </svg>
                            <label htmlFor={uploadInputId}>
                                <span className="label">Upload a New File</span>
                                <input type="file" id={uploadInputId}
                                    accept="image/x-png,image/gif,image/jpeg,application/pdf,image/gif,image/png,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    name={uploadInputId}
                                    style={{ display: 'none' }}
                                    onChange={evt => uploadDocument(evt.target.files)} />
                            </label>
                        </span>
                    </button>
                </div>
            </div>
            <div>
                <CreditBadgeComponent 
                price={szorzo * creditsService.getUploadValue(uploadValueKey as string)}
                hasValue={hasCredit} />
            </div>
        </div >
    );
};
