import { actionCreator } from '../actions';
import { PopupModel } from './popup.models';

export enum PopUpActionTypes {
    POPUP_TOGGLE = 'popup/toggle',
    POPUP_SET = 'popup/set',
}

export const popupToggleAction = actionCreator<PopUpActionTypes.POPUP_TOGGLE, void>(PopUpActionTypes.POPUP_TOGGLE);
export const popupSetModelAction = actionCreator<PopUpActionTypes.POPUP_SET, PopupModel>(PopUpActionTypes.POPUP_SET);

export type PopupActions =
    ReturnType<typeof popupToggleAction> |
    ReturnType<typeof popupSetModelAction>;