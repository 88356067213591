import React, { useEffect, useState } from "react";

export interface RangeControlProps {
    label: string;
    from?: number | string;
    to?: number;
    enabledRegex?: string;
    onFromChange: (value: number) => void;
    onToChange: (value: number) => void;
}

export const RangeControl: React.FC<RangeControlProps> = ({ label, from, to, enabledRegex, onFromChange, onToChange }) => { 
    const onChange = (oldvalue: string | number | undefined, newvalue: string, apply: any) => {
        if (newvalue == '' || (enabledRegex && newvalue.match(enabledRegex))) {
            apply(Number(newvalue));
        } else if (enabledRegex == null || !newvalue.match(enabledRegex)) {
            apply(oldvalue);
        }
    }

    return (
        <div className="stack stack-xs">
            <div className="label">{label}</div>
            <div className="interval grid">
                <div className="gi-3 t-gi-4">
                    <div className="field field--input">
                        <input className="field__field" 
                            value={from}                            
                            onChange={evt => onChange(from, evt.target.value, (val: any) => onFromChange(val))}
                            placeholder="from" />
                    </div>
                </div>
                <div className="gi-3 t-gi-4">
                    <div className="field field--input">
                        <input className="field__field"
                            value={to}
                            onChange={evt => onChange(to, evt.target.value, (val: any) => onToChange(val))}
                            placeholder="to" />
                    </div>
                </div>
            </div>
        </div>
    )
};
