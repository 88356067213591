import { createStore, applyMiddleware, Store } from "redux";
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AppState, rootReducer } from "./root.reducer";

export function configureStore(): Store<AppState> {
    const middlewares = [thunkMiddleware];
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

    return store as any;
}
