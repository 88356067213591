import { AppState } from '../root.reducer';
import { SourceIconComponent, SourceIconDispatchProps, SourceIconStateProps as SourceIconProps, SourceIconStateProps } from './SourceIcon.component';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { companyService } from './company.service';
import { sourceDataSelector } from '../saved-companies/saved-companies.selectors';
import { sourceClearAction, sourceLoadedSuccessAction, sourceLoadingAction } from '../saved-companies/saved-companies.actions';
import { gravityUseAsSelector } from '../auth/authentication.selectors';

const mapStateToProps = (state: AppState): SourceIconStateProps => ({
  sourceData: sourceDataSelector(state),
  useGravityAs: gravityUseAsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): SourceIconDispatchProps => ({
  getSource: async (savedCompanyId: number, attributeId: number, hasSavedAttribute: boolean, useGravityAs?: number) => {

    dispatch(sourceLoadingAction());

    if (hasSavedAttribute) {
      if (useGravityAs) {
        const source = await companyService.getWorkspaceSavedAttributeSource(savedCompanyId, attributeId);
        dispatch(sourceLoadedSuccessAction(source.data));
      } else {
        const source = await companyService.getUserSavedAttributeSource(savedCompanyId, attributeId);
        dispatch(sourceLoadedSuccessAction(source.data));
      }
    } else {
      if (useGravityAs) {
        const source = await companyService.getWorkspaceAttributeSource(attributeId);
        dispatch(sourceLoadedSuccessAction(source));
      } else {
        const source = await companyService.getUserAttributeSource(attributeId);
        dispatch(sourceLoadedSuccessAction(source));
      }
    }
  },
  clearSource: () => {
    dispatch(sourceClearAction());
  }
});

export default
  connect<SourceIconStateProps, SourceIconDispatchProps, SourceIconProps, AppState>(
    mapStateToProps,
    mapDispatchToProps
  )(SourceIconComponent);