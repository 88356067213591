import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SavedCompanyAttribute } from "./saved-company.models";
import { Tooltip } from "./Tooltip";
import parse from 'html-react-parser';

export interface SourceIconDispatchProps {
  getSource(savedCompanyId: number, attributeId: number, hasSavedAttribute: boolean, useGravityAs?: number): void;
  clearSource(): void;
}

export interface SourceIconStateProps {
  sourceData?: any;
  useGravityAs?: number;
  format?: (val: string) => string;
}

export interface SourceIconProps {
  savedCompanyId: number;
  attribute: SavedCompanyAttribute;
  attributeName?: string;
}

const SourceIconComp: React.FC<
  SourceIconStateProps &
  SourceIconProps &
  SourceIconDispatchProps &
  RouteComponentProps
> = ({ sourceData, attribute, savedCompanyId, useGravityAs, attributeName, getSource, clearSource, format }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(!sourceData);
  }, [sourceData]);


  if (!attribute) {
    return <div></div>;
  }

  const hasSavedAttribute = attribute.value !== null && attribute.value !== undefined && attribute.value !== "";
  const sourceDirection = hasSavedAttribute ? "down" : "up";
  const tooltipId = `tooltip_attribute_${attribute.attributeId}`;

  const onHide = () => {
    setOpen(false);
    setIsLoading(false);
    setTimeout(() => {
      clearSource();
    }, 10);
  };

  const onShow = () => {
    setOpen(true);
    getSource(savedCompanyId, attribute?.attributeId, hasSavedAttribute, useGravityAs);
    setIsLoading(true);
  };

  const renderSourceData = () => {
    try {
      return (
        <>
          {sourceData.toString()}
        </>
      );
    } catch {
      console.log(sourceData);
    }
  }

  const content = sourceData === undefined ? (
    <div className="source_main small">
      <table className="source_wrapper">
        <tbody>
          <tr>
            <td>
              <span className="source_value">Loading..</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    hasSavedAttribute && open ?
      <div className="source_main">
        <span className="source_name">{attributeName}</span>
        <hr />
        <table className="source_wrapper">
          <tbody>
            {sourceData?.items?.map((data: any) => (
              <tr key={data.id} className="line">
                <td>
                  <span className="source_value extended">{format ? format(data.value) : data.value}</span>
                  <table className="source_table">
                    <tbody>
                      <tr>
                        <th>Date</th>
                        <th>Source</th>
                        <th>Comment</th>
                      </tr>
                      <tr>
                        <td className="column-date">{data.date}</td>
                        <td className="column-source">{data.source}</td>
                        <td className="column-comment">{parse(data.comment)}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> :
      <div className="source_main small">
        <table className="source_wrapper">
          <tbody>
            <tr className="line">
              <td>
                <span className="source_value">
                  {renderSourceData()}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  );

  return (
    <>
      <Tooltip
        content={content}
        direction="right"
        hasSavedAttribute={hasSavedAttribute}
        onShow={onShow}
        onHide={onHide}
        isLoading={isLoading}
      >
        <img
          data-tip
          data-for={tooltipId}
          src={`/assets/img/source-${sourceDirection}.png`}
          alt="Source"
        />
      </Tooltip>
    </>
  );
};

export const SourceIconComponent = withRouter(SourceIconComp);
