import React from 'react';
import { TagAreaWithPriceComponent } from '../TagAreaWithPrice.component';
import { CompanyAttributeKey } from '../../company.constants';
import { CompanyUploadModel } from '../CompanyUpload.model';
import { TouchedProductsServicesInputsModel } from '../company-upload.models';

export interface ProductServicesCompanyUploadTabProps {
    originalValue?: CompanyUploadModel['productsAndServices'];
    isEditMode?: boolean;
    productsAndServices: CompanyUploadModel['productsAndServices'];
    updateDetail: (key: keyof CompanyUploadModel['productsAndServices'], value: any) => void;
    touchedInputs: TouchedProductsServicesInputsModel;
}

export const ProductServicesCompanyUploadTabComponent: React.FC<ProductServicesCompanyUploadTabProps> = ({
    isEditMode,
    originalValue,
    productsAndServices,
    updateDetail,
    touchedInputs
}) => {
    return (
        <div className="stack stack-m">
            <div className="upload-form__section">
                <div className="stack stack-m">
                    <span className="icon-header icon-header--header-right " id="">
                        <span className="icon-header__inner">
                            <svg className="icon icon--l ">
                                <use xlinkHref="#icon-edit" />
                            </svg>
                            <span className="title title-s">Products & Services</span>
                        </span>
                    </span>
                    <div className="grid">
                        <div className="gi-12">
                            <TagAreaWithPriceComponent label="Products"
                                isRequired={productsAndServices.products?.required}
                                isTouched={touchedInputs.products}
                                disabled={productsAndServices.products!.disabled}
                                uploadValueKey={CompanyAttributeKey.ProductsAndServices.MainProducts}
                                value={productsAndServices.products!.value || []} 
                                onChange={value => updateDetail('products', value)} 
                                isEditMode={isEditMode}
                                originalValue={originalValue?.products?.value}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="gi-12">
                            <TagAreaWithPriceComponent label="Services"
                                isRequired={productsAndServices.services?.required}
                                isTouched={touchedInputs.services}
                                disabled={productsAndServices.services!.disabled}
                                uploadValueKey={CompanyAttributeKey.ProductsAndServices.MainServices}
                                value={productsAndServices.services!.value || []}
                                onChange={value => updateDetail('services', value)}
                                isEditMode={isEditMode}
                                originalValue={originalValue?.services?.value}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="gi-12">
                            <TagAreaWithPriceComponent label="Brands"
                                isRequired={productsAndServices.brands?.required}
                                isTouched={touchedInputs.brands}
                                disabled={productsAndServices.brands!.disabled}
                                uploadValueKey={CompanyAttributeKey.ProductsAndServices.Brands}
                                value={productsAndServices.brands!.value || []}
                                onChange={value => updateDetail('brands', value)} 
                                isEditMode={isEditMode}
                                originalValue={originalValue?.brands?.value}
                            />
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};