import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest';
import { accountService } from '../../account-settings/account.service';
import { CompanyListModel } from '../../auth/authentication.models';

export interface CompanyAutoSuggestComponentProps {
  label?: string;
  companyName?: string;
  setCompanyName: (value: any) => void;
  onSuggestionSelected: (event: any, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any) => void;
}

export const CompanyAutoSuggestComponent: React.FC<CompanyAutoSuggestComponentProps> = ({
  label,
  companyName,
  setCompanyName,
  onSuggestionSelected
}) => {
  const [companies, setCompanies] = useState<CompanyListModel[]>([]);

  const onChange = (event: any, input: any) => {
    setCompanyName(input);
  };

  const inputProps = {
    placeholder: 'Start typing the name of your company...',
    value: companyName || '',
    onChange: onChange,
    autoComplete: 'nope'
  };

  const onSuggestionsFetchRequested = (input: any) => {
    if (input.value.length > 2) {
      accountService.loadCompanies(input.value)
        .then(companies => {
          setCompanies(companies);
        });
    } else {
      setCompanies([]);
    }
  };

  const onSuggestionsClearRequested = () => {
  };

  const getSuggestionValue = (suggestion: any) => {
    return suggestion.fullName;
  }

  const renderSuggestion = (suggestion: any) => (
    <div>
      {suggestion.fullName}
    </div>
  );

  return (
    <div>
      <p className="label">
        {label ?? "Company Name"}
      </p>
      <Autosuggest
        suggestions={companies}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
      />
    </div>
  );
}

export default CompanyAutoSuggestComponent;