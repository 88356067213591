import { combineReducers } from "redux";
import { authenticationReducer } from "./auth/authentication.reducer";
import { cartReducer } from "./cart/cart.reducer";
import { savedCompanyReducer } from "./saved-company/saved-company.reducer";
import { searchReducer } from "./search/search.reducer";
import { savedCompaniesReducer } from './saved-companies/saved-companies.reducer';
import { metaReducer } from "./meta/meta.reducer";
import { companyUploadReducer } from './company-upload/company-upload.reducer';
import { accountReducer } from './account-settings/account.reducer';
import { workspaceReducer } from './workspace-settings/workspace.reducer';
import { popupReducer } from './popup/popup.reducer';
import { creditHistoryReducer } from './credit-history/credit-history.reducer';
import { billingReducer } from './billing-information/billing.reducer';
import { managementContactActivationReducer } from './management-contact/activation.reducer';

export const rootReducer = combineReducers({
    authentication: authenticationReducer,
    account: accountReducer,
    billing: billingReducer,
    creditHistory: creditHistoryReducer,
    cart: cartReducer,
    savedCompany: savedCompanyReducer,
    companyUpload: companyUploadReducer,
    search: searchReducer,
    savedCompanies: savedCompaniesReducer,
    workspace: workspaceReducer,
    activation: managementContactActivationReducer,
    meta: metaReducer,
    popup: popupReducer
});

export type AppState = ReturnType<typeof rootReducer>;
