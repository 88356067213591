import { CompanyAttributeKey } from '../company.constants';
import { CompanyAttachmentModel, CreditMap, PropertyWithPrice } from './company-upload.models';


export class CompanyUploadModel {
  constructor(private credits: CreditMap) {
      const year = new Date().getFullYear();

      this.summary = {
          fullname: { price: credits[CompanyAttributeKey.Summary.FullName].uploadValue },
          shortname: { price: credits[CompanyAttributeKey.Summary.ShortName].uploadValue },
          industry: { price: credits[CompanyAttributeKey.Summary.Industry].uploadValue },
          sector: { price: credits[CompanyAttributeKey.Summary.Sector].uploadValue },
          introduction: { price: credits[CompanyAttributeKey.Summary.Introduction].uploadValue },
          entityRelationshipStatus: { price: credits[CompanyAttributeKey.Summary.EntityRelationshipStatus].uploadValue },
          countryOfFoundation: { price: credits[CompanyAttributeKey.Summary.CountryOfFoundation].uploadValue },
          formOfOrganization: { price: credits[CompanyAttributeKey.Summary.FormOfOrganization].uploadValue },
          foundationYear: { price: credits[CompanyAttributeKey.Summary.FoundationYear].uploadValue },
          identificationNumber: { price: credits[CompanyAttributeKey.Summary.IdentificationNumber].uploadValue },
          numberOfEmployees: { price: credits[CompanyAttributeKey.Summary.NumberOfEmployees].uploadValue },
          mainAddress: {
              countryId: { price: credits[CompanyAttributeKey.MainAddress.Country].uploadValue },
              city: { price: credits[CompanyAttributeKey.MainAddress.City].uploadValue },
              street: { price: credits[CompanyAttributeKey.MainAddress.Street].uploadValue },
              houseNumber: { price: credits[CompanyAttributeKey.MainAddress.HouseNumber].uploadValue },
              floor: { price: credits[CompanyAttributeKey.MainAddress.Floor].uploadValue },
              door: { price: credits[CompanyAttributeKey.MainAddress.Door].uploadValue },
              zip: { price: credits[CompanyAttributeKey.MainAddress.Zip].uploadValue },
          },
          businessAddress: {
              countryId: { price: credits[CompanyAttributeKey.BusinessAddress.Country].uploadValue },
              city: { price: credits[CompanyAttributeKey.BusinessAddress.City].uploadValue },
              street: { price: credits[CompanyAttributeKey.BusinessAddress.Street].uploadValue },
              houseNumber: { price: credits[CompanyAttributeKey.BusinessAddress.HouseNumber].uploadValue },
              floor: { price: credits[CompanyAttributeKey.BusinessAddress.Floor].uploadValue },
              door: { price: credits[CompanyAttributeKey.BusinessAddress.Door].uploadValue },
              zip: { price: credits[CompanyAttributeKey.BusinessAddress.Zip].uploadValue },
          },
          otherContactDetails: {
              contactEmailAddress: { price: credits[CompanyAttributeKey.OtherContactDetails.ContactEmailAddress].uploadValue },
              website: { price: credits[CompanyAttributeKey.OtherContactDetails.Website].uploadValue },
              mainTelephoneNumber: { price: credits[CompanyAttributeKey.OtherContactDetails.MainTelephoneNumber].uploadValue },
          },
      };
      this.productsAndServices = {
          products: { price: credits[CompanyAttributeKey.ProductsAndServices.MainProducts].uploadValue },
          services: { price: credits[CompanyAttributeKey.ProductsAndServices.MainServices].uploadValue },
          brands: { price: credits[CompanyAttributeKey.ProductsAndServices.Brands].uploadValue },
      };
      this.documentsAndAttachments = {
          standardImages: { price: credits[CompanyAttributeKey.Attachment].uploadValue, value: [] },
          managementStructure: { price: credits[CompanyAttributeKey.Attachment].uploadValue, value: [] },
          otherDocuments: { price: credits[CompanyAttributeKey.Attachment].uploadValue, value: [] }
      };
      this.managementContacts = [];
      this.financialCurrency = 'EUR';
      this.financialMultiplier = 1;
      this.financials = [
          this.getYearlyFinancialDetail(year - 1),
          this.getYearlyFinancialDetail(year - 2),
          this.getYearlyFinancialDetail(year - 3),
          this.getYearlyFinancialDetail(year - 4),
          this.getYearlyFinancialDetail(year - 5),
      ];
  }

  private getYearlyFinancialDetail(year: number) {
      return {
          year,
          incomeStatement: {
              netSales: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              extraordinaryIncome: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              financialIncome: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              otherIncome: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              materials: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              personnel: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              utilities: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              overhead: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              financialExpenses: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              sellingAndAdministrative: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              depreciationAndAmortization: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              otherExpenses: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              totalNetRevenues: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              totalExpenses: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              netIncomeBeforeTaxes: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              corporateIncomeTaxes: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              netIncomeAfterTaxes: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              dividensPaid: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              retainedEarningsForTheYear: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
          },
          balanceSheet: {
              cashAndEquivalents: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              accountsReceivable: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              inventories: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              otherCurrentAssets: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              propertyPlantAndEquipment: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              proprietaryRights: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              accumulatedDepreciation: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              otherFixedAssets: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              accountsPayable: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              shortTermLoans: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              taxesPayable: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              otherCurrentLiabilities: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              longTermLoanes: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              shareHoldersLoans: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              deferredIncomeTaxes: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              otherLongTermLiabilites: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              registeredShares: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              additionalPaidInCapital: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              accumulatedRetainedEarnings: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              otherOwnersEquity: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              totalAssets: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              totalLiabilites: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              ownersEquity: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
              liabilitiesAndOwnersEquity: { price: this.credits[CompanyAttributeKey.Financials.FinancialItem].uploadValue },
          }
      };
  }

  public static addManagementContact(model: CompanyUploadModel) {
      const newManagementContact: CompanyUploadModel['managementContacts'][0] = {
          id: 0,
          individual: {
              name: { price: model.credits[CompanyAttributeKey.ManagementContact.Individual.Name].uploadValue, required: true },
              position: { price: model.credits[CompanyAttributeKey.ManagementContact.Individual.Position].uploadValue, required: true },
              location: { price: model.credits[CompanyAttributeKey.ManagementContact.Individual.Location].uploadValue },
              functions: { price: model.credits[CompanyAttributeKey.ManagementContact.Individual.Functions].uploadValue },
              telephoneNumber: { price: model.credits[CompanyAttributeKey.ManagementContact.Individual.TelephoneNumber].uploadValue },
              emailAddress: { price: model.credits[CompanyAttributeKey.ManagementContact.Individual.EmailAddress].uploadValue },
              linkedIn: { price: model.credits[CompanyAttributeKey.ManagementContact.Individual.LinkedIn].uploadValue },
          },
          frontDeskOrDepartment: {
              location: { price: model.credits[CompanyAttributeKey.ManagementContact.FrontDeskOrDepartment.Location].uploadValue },
              telephoneNumber: { price: model.credits[CompanyAttributeKey.ManagementContact.FrontDeskOrDepartment.TelephoneNumber].uploadValue },
              emailAddress: { price: model.credits[CompanyAttributeKey.ManagementContact.FrontDeskOrDepartment.EmailAddress].uploadValue },
              department: { price: model.credits[CompanyAttributeKey.ManagementContact.FrontDeskOrDepartment.Department].uploadValue },
              departmentOther: { price: model.credits[CompanyAttributeKey.ManagementContact.FrontDeskOrDepartment.DepartmentOther].uploadValue },
          }
      };

      model.managementContacts.push(newManagementContact);

      return newManagementContact;
  }

  summary: {
      fullname: PropertyWithPrice<string>;
      shortname: PropertyWithPrice<string>;
      industry: PropertyWithPrice<number>;
      sector: PropertyWithPrice<string>;
      introduction: PropertyWithPrice<string>;
      formOfOrganization: PropertyWithPrice<string>;
      countryOfFoundation: PropertyWithPrice<string>;
      foundationYear: PropertyWithPrice<string>;
      numberOfEmployees: PropertyWithPrice<string>;
      entityRelationshipStatus: PropertyWithPrice<string>;
      identificationNumber: PropertyWithPrice<string>;
      mainAddress: {
          countryId: PropertyWithPrice<string>;
          zip: PropertyWithPrice<string>;
          city: PropertyWithPrice<string>;
          street: PropertyWithPrice<string>;
          houseNumber: PropertyWithPrice<string>;
          floor: PropertyWithPrice<string>;
          door: PropertyWithPrice<string>;
      },
      businessAddress: {
          countryId: PropertyWithPrice<string>;
          zip: PropertyWithPrice<string>;
          city: PropertyWithPrice<string>;
          street: PropertyWithPrice<string>;
          houseNumber: PropertyWithPrice<string>;
          floor: PropertyWithPrice<string>;
          door: PropertyWithPrice<string>;
      },
      otherContactDetails: {
          website: PropertyWithPrice<string>;
          contactEmailAddress: PropertyWithPrice<string>;
          mainTelephoneNumber: PropertyWithPrice<string>;
      }
  };
  productsAndServices: {
      products?: PropertyWithPrice<string[]>;
      brands?: PropertyWithPrice<string[]>;
      services?: PropertyWithPrice<string[]>;
  };
  managementContacts: {
      id?: number,
      individual: {
          name: PropertyWithPrice<string>;
          position: PropertyWithPrice<string>;
          location: PropertyWithPrice<string>;
          functions: PropertyWithPrice<string[]>;
          telephoneNumber: PropertyWithPrice<string>;
          emailAddress: PropertyWithPrice<string>;
          linkedIn: PropertyWithPrice<string>;
      },
      frontDeskOrDepartment: {
          location: PropertyWithPrice<string>;
          telephoneNumber: PropertyWithPrice<string>;
          emailAddress: PropertyWithPrice<string>;
          department: PropertyWithPrice<string>;
          departmentOther: PropertyWithPrice<string>;
      },
  }[];
  documentsAndAttachments: {
      standardImages: PropertyWithPrice<CompanyAttachmentModel[]>;
      managementStructure: PropertyWithPrice<CompanyAttachmentModel[]>;
      otherDocuments: PropertyWithPrice<CompanyAttachmentModel[]>;
  };
  financialCurrency: string;
  financialMultiplier: number;
  financials: {
      year: number,
      incomeStatement: {
          netSales: PropertyWithPrice<string>;
          extraordinaryIncome: PropertyWithPrice<string>;
          financialIncome: PropertyWithPrice<string>;
          otherIncome: PropertyWithPrice<string>;
          materials: PropertyWithPrice<string>;
          personnel: PropertyWithPrice<string>;
          utilities: PropertyWithPrice<string>;
          overhead: PropertyWithPrice<string>;
          financialExpenses: PropertyWithPrice<string>;
          sellingAndAdministrative: PropertyWithPrice<string>;
          depreciationAndAmortization: PropertyWithPrice<string>;
          otherExpenses: PropertyWithPrice<string>;
          totalNetRevenues: PropertyWithPrice<string>;
          totalExpenses: PropertyWithPrice<string>;
          netIncomeBeforeTaxes: PropertyWithPrice<string>;
          corporateIncomeTaxes: PropertyWithPrice<string>;
          netIncomeAfterTaxes: PropertyWithPrice<string>;
          dividensPaid: PropertyWithPrice<string>;
          retainedEarningsForTheYear: PropertyWithPrice<string>;
      },
      balanceSheet: {
          cashAndEquivalents: PropertyWithPrice<string>;
          accountsReceivable: PropertyWithPrice<string>;
          inventories: PropertyWithPrice<string>;
          otherCurrentAssets: PropertyWithPrice<string>;
          propertyPlantAndEquipment: PropertyWithPrice<string>;
          proprietaryRights: PropertyWithPrice<string>;
          accumulatedDepreciation: PropertyWithPrice<string>;
          otherFixedAssets: PropertyWithPrice<string>;
          accountsPayable: PropertyWithPrice<string>;
          shortTermLoans: PropertyWithPrice<string>;
          taxesPayable: PropertyWithPrice<string>;
          otherCurrentLiabilities: PropertyWithPrice<string>;
          longTermLoanes: PropertyWithPrice<string>;
          shareHoldersLoans: PropertyWithPrice<string>;
          deferredIncomeTaxes: PropertyWithPrice<string>;
          otherLongTermLiabilites: PropertyWithPrice<string>;
          registeredShares: PropertyWithPrice<string>;
          additionalPaidInCapital: PropertyWithPrice<string>;
          accumulatedRetainedEarnings: PropertyWithPrice<string>;
          otherOwnersEquity: PropertyWithPrice<string>;
          totalAssets: PropertyWithPrice<string>;
          totalLiabilites: PropertyWithPrice<string>;
          ownersEquity: PropertyWithPrice<string>;
          liabilitiesAndOwnersEquity: PropertyWithPrice<string>;
      }
  }[];
}
