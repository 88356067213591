import { CreditModel, SavedCompanyAttachmentModel, SavedCompanyAttributeModel } from '../saved-company/saved-company.models';

export type CreditMap = { [key: string]: CreditModel }; // keys are CompanyConstants values

export interface PropertyWithPrice<T> {
    readonly price: number;
    value?: T;
    disabled?: boolean;
    required?: boolean;
    isValid?: boolean;
    touched?: boolean;
}

export interface CompanyAttachmentModel {
    id: number;
    url: string;
    fileName: string;
    isTemporary: boolean;
    isMarkedForDelete?: boolean;
}

export interface CompanyUploadSummary {
    fullname: PropertyWithPrice<string>;
    shortname: PropertyWithPrice<string>;
    industry: PropertyWithPrice<number>;
    sector: PropertyWithPrice<string>;
    introduction: PropertyWithPrice<string>;
    formOfOrganization: PropertyWithPrice<string>;
    countryOfFoundation: PropertyWithPrice<string>;
    foundationYear: PropertyWithPrice<string>;
    numberOfEmployees: PropertyWithPrice<string>;
    entityRelationshipStatus: PropertyWithPrice<string>;
    identificationNumber: PropertyWithPrice<string>;
}

export interface TouchedSummaryInputsModel {
    summary: { [key: string]: boolean },
    mainAddress: { [key: string]: boolean },
    businessAddress: { [key: string]: boolean },
    otherContactDetails: { [key: string]: boolean },
}

export interface TouchedInputsModel {
    financials: TouchedFinancialInputsModel[];
}

export interface TouchedProductsServicesInputsModel {
    products: boolean;
    services: boolean;
    brands: boolean;
}

export interface TouchedManagementContactInputsModel {
    individual: { [key: string]: boolean },
    frontDeskOrDepartment: { [key: string]: boolean },
}

export interface TouchedManagementContactsModel {
    managementContacts: TouchedManagementContactInputsModel[];
}


export interface TouchedFinancialInputsModel {
    incomeStatement: { [key: string]: boolean },
    balanceSheet: { [key: string]: boolean },
}
export interface TouchedFinancialsModel {
    financials: TouchedFinancialInputsModel[];
}

export interface Address {
    countryId?: number | null;
    zip?: string | null;
    state?: string | null;
    city?: string | null;
    street?: string | null;
    streetNumber?: string | null;
    floor?: string | null;
    door?: string | null;
}

export enum EntityRelationshipStatus {
    Independent = 1,
    HoldingCompany = 2,
    Subsidiary = 3,
    PartOfGroup = 4,
    Franchise = 5,
}

export interface CompanyAttributeModel {
    attributeId?: number;
    value?: any;
}

export interface CompanyAddressModel {
    countryId: CompanyAttributeModel;
    zip: CompanyAttributeModel;
    city: CompanyAttributeModel;
    street: CompanyAttributeModel;
    houseNumber: CompanyAttributeModel;
    floor: CompanyAttributeModel;
    door: CompanyAttributeModel;
}

export interface CompanyOtherContactDetailsModel {
    website: CompanyAttributeModel;
    contactEmailAddress: CompanyAttributeModel;
    mainTelephoneNumber: CompanyAttributeModel;
}

export interface SummaryPublicModel {
    fullName: CompanyAttributeModel;
    shortName: CompanyAttributeModel;
    entityRelationshipStatus: CompanyAttributeModel;
    formOfOrganization: CompanyAttributeModel;
    countryOfFoundationId: CompanyAttributeModel;
    introduction: CompanyAttributeModel;
    industry: CompanyAttributeModel;
    sector: CompanyAttributeModel;
    foundationYear: CompanyAttributeModel;
    identificationNumber: CompanyAttributeModel;
    numberOfEmployees: CompanyAttributeModel;
    mainAddress: CompanyAddressModel;
    businessAddress?: CompanyAddressModel | undefined;
    otherContactDetails: CompanyOtherContactDetailsModel;
}

export interface CompanyUpdateAddressModel {
    countryId?: SavedCompanyAttributeModel;
    zip?: SavedCompanyAttributeModel;
    city?: SavedCompanyAttributeModel;
    street?: SavedCompanyAttributeModel;
    houseNumber?: SavedCompanyAttributeModel;
    floor?: SavedCompanyAttributeModel;
    door?: SavedCompanyAttributeModel;
}

export interface CompanyUpdateOtherContactDetailsModel {
    website?: SavedCompanyAttributeModel;
    contactEmailAddress?: SavedCompanyAttributeModel;
    mainTelephoneNumber?: SavedCompanyAttributeModel;
}

export interface SummaryUpdatePublicModel {
    entityRelationshipStatus: SavedCompanyAttributeModel;
    formOfOrganization: SavedCompanyAttributeModel;
    countryOfFoundationId: SavedCompanyAttributeModel;
    introduction: SavedCompanyAttributeModel;
    industry: SavedCompanyAttributeModel;
    sector?: SavedCompanyAttributeModel;
    foundationYear?: SavedCompanyAttributeModel;
    identificationNumber?: SavedCompanyAttributeModel;
    numberOfEmployees?: SavedCompanyAttributeModel;
    mainAddress: CompanyUpdateAddressModel;
    businessAddress: CompanyUpdateAddressModel;
    otherContactDetails: CompanyUpdateOtherContactDetailsModel;
}

export interface ProductsAndServicesUpdatePublicModel {
    mainProducts?: SavedCompanyAttributeModel;
    mainServices?: SavedCompanyAttributeModel;
    brands?: SavedCompanyAttributeModel;
}

export interface AttachmentsUpdatePublicModel {
    standardImages: SavedCompanyAttachmentModel[];
    managementStructure: SavedCompanyAttachmentModel[];
    otherDocuments: SavedCompanyAttachmentModel[];
}

export interface CompanyIncomeStatementUpdateModel {
    netSales?: SavedCompanyAttributeModel;
    extraordinaryIncome?: SavedCompanyAttributeModel;
    financialIncome?: SavedCompanyAttributeModel;
    otherIncome?: SavedCompanyAttributeModel;
    materials?: SavedCompanyAttributeModel;
    personnel?: SavedCompanyAttributeModel;
    utilities?: SavedCompanyAttributeModel;
    overhead?: SavedCompanyAttributeModel;
    financialExpenses?: SavedCompanyAttributeModel;
    sellingAndAdministrative?: SavedCompanyAttributeModel;
    depreciationAndAmortization?: SavedCompanyAttributeModel;
    otherExpenses?: SavedCompanyAttributeModel;
    totalNetRevenues?: SavedCompanyAttributeModel;
    totalExpenses?: SavedCompanyAttributeModel;
    netIncomeBeforeTaxes?: SavedCompanyAttributeModel;
    corporateIncomeTaxes?: SavedCompanyAttributeModel;
    netIncomeAfterTaxes?: SavedCompanyAttributeModel;
    dividensPaid?: SavedCompanyAttributeModel;
    retainedEarningsForTheYear?: SavedCompanyAttributeModel;
}

export interface CompanyBalanceSheetUpdateModel {
    cashAndEquivalents?: SavedCompanyAttributeModel;
    accountsReceivable?: SavedCompanyAttributeModel;
    inventories?: SavedCompanyAttributeModel;
    otherCurrentAssets?: SavedCompanyAttributeModel;
    propertyPlantAndEquipment?: SavedCompanyAttributeModel;
    proprietaryRights?: SavedCompanyAttributeModel;
    accumulatedDepreciation?: SavedCompanyAttributeModel;
    otherFixedAssets?: SavedCompanyAttributeModel;
    accountsPayable?: SavedCompanyAttributeModel;
    shortTermLoans?: SavedCompanyAttributeModel;
    taxesPayable?: SavedCompanyAttributeModel;
    otherCurrentLiabilities?: SavedCompanyAttributeModel;
    longTermLoanes?: SavedCompanyAttributeModel;
    shareHoldersLoans?: SavedCompanyAttributeModel;
    deferredIncomeTaxes?: SavedCompanyAttributeModel;
    otherLongTermLiabilites?: SavedCompanyAttributeModel;
    registeredShares?: SavedCompanyAttributeModel;
    additionalPaidInCapital?: SavedCompanyAttributeModel;
    accumulatedRetainedEarnings?: SavedCompanyAttributeModel;
    otherOwnersEquity?: SavedCompanyAttributeModel;
    totalAssets?: SavedCompanyAttributeModel;
    totalLiabilites?: SavedCompanyAttributeModel;
    ownersEquity?: SavedCompanyAttributeModel;
    liabilitiesAndOwnersEquity?: SavedCompanyAttributeModel;
}

export interface FinancialsUpdatePublicModel {
    year: number;
    incomeStatement: CompanyIncomeStatementUpdateModel;
    balanceSheet: CompanyBalanceSheetUpdateModel;
}

export interface CompanyManagementContactIndividualUpdateModel {
    name?: SavedCompanyAttributeModel;
    position?: SavedCompanyAttributeModel;
    location?: SavedCompanyAttributeModel;
    functions?: SavedCompanyAttributeModel;
    telephoneNumber?: SavedCompanyAttributeModel;
    emailAddress?: SavedCompanyAttributeModel;
    linkedIn?: SavedCompanyAttributeModel;
}

export interface CompanyManagementContactFrontDeskOrDepartmentUpdateModel {
    location?: SavedCompanyAttributeModel;
    telephoneNumber?: SavedCompanyAttributeModel;
    emailAddress?: SavedCompanyAttributeModel;
    department?: SavedCompanyAttributeModel;
    departmentOther?: SavedCompanyAttributeModel;
}

export interface CompanyManagementContactUpdateModel {
    id?: number;
    individual: CompanyManagementContactIndividualUpdateModel;
    frontDeskOrDepartment: CompanyManagementContactFrontDeskOrDepartmentUpdateModel;
}

export interface ProductsAndServicesPublicModel {
    mainProducts: CompanyAttributeModel;
    mainServices: CompanyAttributeModel;
    brands: CompanyAttributeModel;
}

export interface CompanyManagementContactIndividualModel {
    name?: CompanyAttributeModel;
    position?: CompanyAttributeModel;
    location?: CompanyAttributeModel;
    functions?: CompanyAttributeModel;
    telephoneNumber?: CompanyAttributeModel;
    emailAddress?: CompanyAttributeModel;
    linkedIn?: CompanyAttributeModel;
}

export interface CompanyManagementContactFrontDeskOrDepartmentModel {
    location?: CompanyAttributeModel;
    telephoneNumber?: CompanyAttributeModel;
    emailAddress?: CompanyAttributeModel;
    department?: CompanyAttributeModel;
    departmentOther?: CompanyAttributeModel;
}

export interface CompanyManagementContactModel {
    id?: number;
    individual: CompanyManagementContactIndividualModel;
    frontDeskOrDepartment: CompanyManagementContactFrontDeskOrDepartmentModel;
}

export interface AttachmentModel {
    id?: number;
}

export interface AttachmentsPublicModel {
    standardImages: AttachmentModel[];
    managementStructure: AttachmentModel[];
    otherDocuments: AttachmentModel[];
}

export interface IncomeStatementModel {
    netSales?: CompanyAttributeModel;
    extraordinaryIncome?: CompanyAttributeModel;
    financialIncome?: CompanyAttributeModel;
    otherIncome?: CompanyAttributeModel;
    materials?: CompanyAttributeModel;
    personnel?: CompanyAttributeModel;
    utilities?: CompanyAttributeModel;
    overhead?: CompanyAttributeModel;
    financialExpenses?: CompanyAttributeModel;
    sellingAndAdministrative?: CompanyAttributeModel;
    depreciationAndAmortization?: CompanyAttributeModel;
    otherExpenses?: CompanyAttributeModel;
    totalNetRevenues?: CompanyAttributeModel;
    totalExpenses?: CompanyAttributeModel;
    netIncomeBeforeTaxes?: CompanyAttributeModel;
    corporateIncomeTaxes?: CompanyAttributeModel;
    netIncomeAfterTaxes?: CompanyAttributeModel;
    dividensPaid?: CompanyAttributeModel;
    retainedEarningsForTheYear?: CompanyAttributeModel;
}

export interface BalanceSheetModel {
    cashAndEquivalents?: CompanyAttributeModel;
    accountsReceivable?: CompanyAttributeModel;
    inventories?: CompanyAttributeModel;
    otherCurrentAssets?: CompanyAttributeModel;
    propertyPlantAndEquipment?: CompanyAttributeModel;
    proprietaryRights?: CompanyAttributeModel;
    accumulatedDepreciation?: CompanyAttributeModel;
    otherFixedAssets?: CompanyAttributeModel;
    accountsPayable?: CompanyAttributeModel;
    shortTermLoans?: CompanyAttributeModel;
    taxesPayable?: CompanyAttributeModel;
    otherCurrentLiabilities?: CompanyAttributeModel;
    longTermLoanes?: CompanyAttributeModel;
    shareHoldersLoans?: CompanyAttributeModel;
    deferredIncomeTaxes?: CompanyAttributeModel;
    otherLongTermLiabilites?: CompanyAttributeModel;
    registeredShares?: CompanyAttributeModel;
    additionalPaidInCapital?: CompanyAttributeModel;
    accumulatedRetainedEarnings?: CompanyAttributeModel;
    otherOwnersEquity?: CompanyAttributeModel;
    totalAssets?: CompanyAttributeModel;
    totalLiabilites?: CompanyAttributeModel;
    ownersEquity?: CompanyAttributeModel;
    liabilitiesAndOwnersEquity?: CompanyAttributeModel;
}

export interface FinancialsModel {
    id?: number;
    year: number;
    incomeStatement?: IncomeStatementModel;
    balanceSheet?: BalanceSheetModel;
}

export interface CreateCompanyPublicModel {
    summary: SummaryPublicModel;
    productsAndServices: ProductsAndServicesPublicModel;
    managementContacts: CompanyManagementContactModel[];
    documentAndAttachments: AttachmentsPublicModel;
    financials: FinancialsModel[];
    financialCurrency: string;
    financialMultiplier: number;
}

export interface UpdateCompanyPublicModel {
    summary: SummaryPublicModel;
}

export interface CreateCompanyCommand extends CreateCompanyPublicModel {
    creditForUpload: number;
}

export interface UpdateCompanyCommand {
    savedCompanyId: number;
    creditForUpload: number;
    financialMultiplier: number;
    summary: SummaryUpdatePublicModel;
    productsAndServices: ProductsAndServicesUpdatePublicModel;
    documentAndAttachments: AttachmentsUpdatePublicModel;
    financials: FinancialsUpdatePublicModel[];
    managementContacts: CompanyManagementContactUpdateModel[];
}

export enum AttachmentUpdateStatus {
    Added = 1,
    Deleted = 2
}

export enum AttachmentType {
    StandardImage = 1,
    ManagementStructure = 2,
    OtherDocument = 3
}