import React, { useState } from "react";

interface Props {
    label: string;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    required?: boolean;
    touched?: boolean;
    onBlur?: () => void;
    invalid?: boolean;
    customErrorMessage?: string;
    enabledRegex?: string;
}

export const TextInputWithLabelComponent: React.FC<Props> = ({ label, value, onChange, placeholder, required, touched, onBlur, enabledRegex, invalid, customErrorMessage }) => {
    const [isTouched, setTouched] = useState(false);
    const errorProps = 
        (required && (isTouched || touched) && !value) || invalid ? 
            { error: 'error' } : 
            {};

    const _onChange = (newvalue: string) => {
        if (newvalue === ''){
            onChange(newvalue);
        } else if (enabledRegex && newvalue.match(enabledRegex)) {
            onChange(newvalue);
        } else if (!enabledRegex) {
            onChange(newvalue);
        }
    }

    return (
        <div className="stack stack-xs">
            <div className="label">{label}{required ? <span className="required-star" title="Required">*</span> : null}</div>
            <div className="field field--input" {...errorProps}>
                <input
                    className="field__field"
                    value={value}
                    onChange={evt => _onChange(evt.target.value)}
                    placeholder={placeholder}
                    required={required === true}
                    onBlur={() => {
                        setTouched(true);
                        if (onBlur) {
                            onBlur();
                        }
                    }}
                />
            </div>
            {
                customErrorMessage && invalid ?
                <div className="error">
                    {customErrorMessage}
                </div> : null
            }
        </div>
    );
};
