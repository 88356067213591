import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PopupDialogType, PopupModel } from './popup.models';
export interface PopupDialogStateProps {
    model: PopupModel;
}

const PopupDialogComp: React.FC<PopupDialogStateProps & RouteComponentProps> = ({ model }) => {
    const onOkClick = (evt: any) => {
        if (model.okButtonAction) {
            model.okButtonAction(evt);
        }
    };
    const onCancelClick = (evt: any) => {
        if (model.cancelButtonAction) {
            model.cancelButtonAction(evt);
        }
    };

    return (
        <div className="popup-dialog">
            <div className="dialog__dialog">
                <div className="stack stack-xs">
                    <div className="h-stack jc-sb">
                        {model.title ?
                            <p className="title title-s">{model.title}</p> :
                            null
                        }
                        {
                            model.cancelButtonAction && !model.hideCloseIcon ?
                                <button className="icon-button dialog__close" id="" onClick={(evt) => onCancelClick(evt)}>
                                    <span className="icon-button__inner">
                                        <svg className="icon icon--m ">
                                            <use xlinkHref="#icon-close" />
                                        </svg>
                                        <span className="visually-hidden">Close</span>
                                    </span>
                                </button> : null
                        }

                    </div>
                    <div>
                    {model.body || <p>{model.text}</p>}
                    </div>
                    {
                        model.type === PopupDialogType.Dialog ?
                            <div className={`h-stack h-stack-xs ${model.buttonSpacing ?? 'jc-fe'}`}>
                                <button className={`button button--${model.okButtonType ?? 'secondary'}`} onClick={(evt) => onOkClick(evt)}>
                                    <span className="button__inner">
                                        <span>{model.okButtonText}</span>
                                    </span>
                                </button>
                                {
                                    model.noButtonText ?
                                        <button className="button" onClick={(evt) => onCancelClick(evt)}>
                                            <span className="button__inner">
                                                <span>{model.noButtonText}</span>
                                            </span>
                                        </button> : null
                                }

                            </div> :
                            <div className={`h-stack h-stack-xs ${model.buttonSpacing ?? 'jc-fe'}`}>
                                <button className={`button button--${model.okButtonType ?? 'secondary'}`} onClick={(evt) => onOkClick(evt)}>
                                    <span className="button__inner">
                                        <span>{model.okButtonText}</span>
                                    </span>
                                </button>
                            </div>
                    }
                </div>
            </div>
        </div >
    );
};

export const PopupDialogComponent = withRouter(PopupDialogComp);