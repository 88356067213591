import { connect } from 'react-redux';
import { AppState } from '../../root.reducer';
import { Dispatch } from 'redux';
import { SavedSearchListItemDispatchProps, SavedSearchListItemComponentProps, SavedSearchListItemComponent } from './SavedSearchListItem.component';
import { searchService } from '../../search/search.service';
import { resetCurrentPageAction, savedSearchesLoadingAction, savedSearchesLoadingSuccessAction, savedSearchExportLoadedAction, savedSearchExportLoadingAction } from '../saved-companies.actions';
import { companyService } from '../../saved-company/company.service';
import fileDownload from 'js-file-download';

const mapDispatchToProps = (dispatch: Dispatch): SavedSearchListItemDispatchProps => ({
  deleteSearch: async (savedSearchId: number, useGravityAs?: number) => {
    dispatch(savedSearchesLoadingAction());

    await searchService.deleteSearch(savedSearchId);

    if (useGravityAs) {
      const savedSearches = await companyService.loadSavedWorkspaceSearches();
      dispatch(savedSearchesLoadingSuccessAction(savedSearches));
    } else {
      const savedSearches = await companyService.loadSavedUserCompanySearches();
      dispatch(savedSearchesLoadingSuccessAction(savedSearches));
    }
  },

  resetCurrentPage: () => {
      dispatch(resetCurrentPageAction());
  },

  exportSearch: async (savedSearchId: number) => {
    dispatch(savedSearchExportLoadingAction());
    const exported = await searchService.exportSearch(savedSearchId);
    console.log('exported', exported)
    fileDownload(exported.data, exported.headers['x-file-name']);

    dispatch(savedSearchExportLoadedAction());
  }
});

export default connect<{}, SavedSearchListItemDispatchProps, SavedSearchListItemComponentProps, AppState>(
  null,
  mapDispatchToProps
)(SavedSearchListItemComponent);