import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppState } from '../root.reducer';
import { isAuthenticatedSelector } from '../auth/authentication.selectors';
import { Header, HeaderDispatchProps, HeaderStateProps } from './Header.component';
import { Dispatch } from 'redux';
import { changeMobilMenuState } from '../meta/meta.actions';
import { resetCurrentPageAction } from '../saved-companies/saved-companies.actions';

const mapStateToProps = (state: AppState): HeaderStateProps => ({
    isLoggedIn: isAuthenticatedSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): HeaderDispatchProps => ({
    openMobilMenu: () => {
        dispatch(changeMobilMenuState(true));
        document.body.setAttribute('menuopen', 'true');
    },

    resetCurrentPage: () => {
        dispatch(resetCurrentPageAction());
    }
});

export default  withRouter(
    connect<HeaderStateProps, HeaderDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(Header)
);