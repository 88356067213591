import React from 'react';
import { SavedCompanyModel } from './saved-company.models';
import { AppRoutes } from '../routes';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './saved-company.scss';
import { companyService } from './company.service';
import { useDispatch } from 'react-redux';
import { savedCompanyLoadedAction, savedCompanyLoadingAction } from './saved-company.actions';

export interface SavedCompanyDescriptionProps {
    savedCompany: SavedCompanyModel;
}

const SavedCompanyDescriptionComponent: React.FC<SavedCompanyDescriptionProps & RouteComponentProps> = ({ savedCompany, history }) => {
    const dispatch = useDispatch();
    const goToEditPage = () => {
        history.push(AppRoutes.EditCompany(savedCompany.id.toString()));
    };

    const exportCsv = async (e: any) => {
        dispatch(savedCompanyLoadingAction());
        await companyService.exportSavedCompany(savedCompany.id);
        dispatch(savedCompanyLoadedAction(savedCompany));
    };

    const annualRevenue = savedCompany.financials.sort((a, b) => a.year > b.year ? -1 : 1)[0];

    const industrySpecified = savedCompany.summary.industry !== null;
    const industryPurchased = savedCompany.summary.industry?.value !== "";

    const shortNameSpecified = savedCompany.summary.shortName !== null;
    const shortNamePurchased = savedCompany.summary.shortName?.value !== "";

    return (
        <div className="company-details">
            <div className="h-stack h-stack-m jc-sb m-fd-c">
                <div className="stack stack-xs">
                    <h1 className="title title-l">{savedCompany.summary.fullName.valueDescription}</h1>
                    {
                        industrySpecified ?
                            industryPurchased ?
                                <ul className="h-stack h-stack-xs">
                                    <li>
                                        <span className="chip">
                                            <span className="label"> {savedCompany.summary.industry.valueDescription}</span>
                                        </span>
                                    </li>
                                </ul> : ""

                            :
                            <ul className="h-stack h-stack-xs">
                                <li>
                                    <span className="chip">
                                        <span className="label">Industry not specified</span>
                                    </span>
                                </li>
                            </ul>
                    }                    
                    {
                        shortNameSpecified ?
                            shortNamePurchased ?
                            <p className="title title-s">{savedCompany.summary.shortName?.valueDescription}</p> : ""

                            :
                            <p className="title title-s">Short Name not specified</p>
                    }
                    
                    {savedCompany.summary.introduction !== null ?
                        <p>{savedCompany.summary.introduction.valueDescription}</p> : <></>
                    }
                    <span className="icon-label icon-label--label-right">
                        <span className="icon-label__inner">
                            <svg className="icon icon--m">
                                <use xlinkHref="#icon-location" />
                            </svg>
                            <span className="label">{savedCompany.summary.countryOfFoundation.valueDescription}</span>
                        </span>
                    </span>
                    {
                        annualRevenue?.incomeStatementTotalNetRevenues?.valueDescription ?
                            <span className="icon-label icon-label--label-right">
                                <span
                                    className="icon-label__inner">
                                    <svg className="icon icon--m">
                                        <use xlinkHref="#icon-revenue" />
                                    </svg>
                                    <span className="label">{annualRevenue.incomeStatementTotalNetRevenues?.valueDescription.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} EUR annual revenue ({annualRevenue?.year})</span>
                                </span>
                            </span> :
                            <span className="icon-label icon-label--label-right">
                                <span
                                    className="icon-label__inner">
                                    <svg className="icon icon--m">
                                        <use xlinkHref="#icon-revenue" />
                                    </svg>
                                    <span className="label">Annual revenues not specified</span>
                                </span>
                            </span>
                    }
                </div>

                <div className="actions stack stack-xs jc-fe ai-fe">
                    {
                        savedCompany.isBlocked ?
                            <div className="actions stack stack-xs jc-fe ai-fe">
                                <div className="locked-company">
                                    <h1 className="bold">Locked company</h1>
                                    <h2>This profile cannot be modified upon the request of the uploaded company.</h2>
                                </div>
                                <button className="button button--secondary" onClick={(e) => exportCsv(e)} >
                                    <span className="button__inner">
                                        <span>Export</span>
                                    </span>
                                </button>
                            </div> :
                            <div className="actions stack stack-xs jc-fe ai-fe">
                                <button className="button button--secondary" onClick={() => goToEditPage()}>
                                    <span className="button__inner">
                                        <span>Add or Modify</span>
                                    </span>
                                </button>
                                <button className="button button--secondary" onClick={(e) => exportCsv(e)} >
                                    <span className="button__inner">
                                        <span>Export</span>
                                    </span>
                                </button>
                            </div>
                    }
                </div>
            </div>
        </div >
    );
};

export const SavedCompanyDescription = withRouter(SavedCompanyDescriptionComponent);