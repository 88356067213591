import { SearchActions, SearchActionTypes } from './search.actions';
import { SearchCountResult, SimpleSearchFields } from './search.models';
import { SavedCompanyListModel } from '../saved-company/saved-company.models';

export interface SearchState {
    isSearching: boolean;
    searchFields?: SimpleSearchFields;
    searchCountResult?: SearchCountResult;

    isLoadingSavedSearch: boolean;
    savedSearch?: SavedCompanyListModel;

    companyCount?: number;
}

const initialState: SearchState = {
    isSearching: false,
    searchFields: undefined,
    searchCountResult: undefined,

    isLoadingSavedSearch: false,
    savedSearch: undefined,

    companyCount: undefined,
};

export const searchReducer = (state: SearchState = initialState, action: SearchActions): SearchState => {
    switch (action.type) {
        case SearchActionTypes.PAY_SEARCH_LOADING:
            return {
                ...state,
                isSearching: true,
            };
        case SearchActionTypes.SEARCH_COMPANY:
            return {
                ...state,
                isSearching: true,
                searchFields: action.payload,
                searchCountResult: undefined
            };
        case SearchActionTypes.SEARCH_COMPANY_SUCCESS:
            return {
                ...state,
                isSearching: false,
                searchCountResult: action.payload
            };
        case SearchActionTypes.PAY_FOR_SEARCH_RESULTS_SUCCESS:
        case SearchActionTypes.PAY_FOR_SEARCH_RESULTS_ERROR:
            return {
                ...state,
                isSearching: false,
                searchFields: undefined,
                searchCountResult: undefined
            };
        case SearchActionTypes.LOAD_SAVED_SEARCH:
            return {
                ...state,
                savedSearch: undefined,
                isLoadingSavedSearch: true
            };
        case SearchActionTypes.LOAD_SAVED_SEARCH_SUCCESS:
            return {
                ...state,
                savedSearch: action.payload,
                isLoadingSavedSearch: false
            };
        case SearchActionTypes.LOAD_SAVED_SEARCH_ERROR:
            return {
                ...state,
                isLoadingSavedSearch: false,
                savedSearch: undefined
            };
        case SearchActionTypes.LOAD_ALL_COMPANY_COUNT:
            return {
                ...state,
                isLoadingSavedSearch: false,
                savedSearch: undefined,
                companyCount: action.payload,
            };
        default:
            return state;
    }
};