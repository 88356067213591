import { BillingActions, BillingActionTypes } from './billing.actions';
import { BillingModel } from './billing.model';

export interface BillingState {
  isLoading: boolean;
  isCheckoutLoading: boolean;
  isVatLoading: boolean;
  clientToken: string | undefined;
  billing: BillingModel | undefined;
  ipDetails: any | undefined;
  paypalClientId: string | undefined;
}

const initialState: BillingState = {
  isLoading: false,
  isCheckoutLoading: false,
  isVatLoading: false,
  clientToken: undefined,
  billing: undefined,
  ipDetails: undefined,
  paypalClientId: undefined
};

export const billingReducer = (state: BillingState = initialState, action: BillingActions): BillingState => {
  switch (action.type) {
    case BillingActionTypes.BILLING_LOADING:
      return {
        ...state,
        isLoading: true,
        isVatLoading: false,
      };
    case BillingActionTypes.BILLING_CHECKOUT_LOADING:
      return {
        ...state,
        isCheckoutLoading: true,
      };
    case BillingActionTypes.BILLING_DATA_SUPPLIED:
      return {
        ...state,
        isLoading: false,
        isVatLoading: false,
        billing: action.payload,
      };
    case BillingActionTypes.BILLING_IP_CHECKED:
      return {
        ...state,
        isLoading: false,
        ipDetails: action.payload
      };
    case BillingActionTypes.BILLING_TOKEN_GENERATED_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        clientToken: action.payload,
      };
    case BillingActionTypes.BILLING_VAT_LOADING:
      return {
        ...state,
        isLoading: false,
        isVatLoading: true,
      };
    case BillingActionTypes.BILLING_PAYPALCLIENTID:
      return {
        ...state,
        isLoading: false,
        paypalClientId: action.payload,
      };
    default:
      return state;
  }
};