import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SaveAccountModel, GetAccountQueryModel } from './account.models';
import { IndustriesArray } from '../models';
import { User } from '../auth/authentication.models';
import { AppRoutes } from '../routes';
import { CountryModel } from '../meta/meta.models';
import { CompanyAutoSuggestComponent } from '../components/company-autosuggest/CompanyAutoSuggestComponent';
import { CreditBadgeComponent } from '../company-upload/CreditBadge.component';
import { CreditMap } from '../company-upload/company-upload.models';
import { PhoneNumberComponent } from '../components/phone-number/PhoneNumberComponent';
import { Utils } from '../utils/utils';
import { PopupDialogType } from '../popup/popup.models';
import { popupService } from '../popup/popup.service';
import { accountService } from './account.service';
import { popupSetModelAction } from '../popup/popup.actions';
import { accountLoadingAction } from './account.actions';
import { logoutAction } from '../auth/authentication.actions';
import { workspaceService } from '../workspace-settings/workspace.service';
import { useDispatch } from 'react-redux';

export interface AccountSettingsComponentProps {
  user: User | undefined;
  account: GetAccountQueryModel;
  saveProfile(account: SaveAccountModel): Promise<void>;
  respondWorkspaceInvite(workspaceId: number, response: boolean): Promise<void>;
  uploadCredit(amount: number, creditValue: number): Promise<void>;
  initWorkspace(): void;
  goToWorkspace(id: number): Promise<void>;
  countries: CountryModel[];
  credits: CreditMap;
}

const AccountSettingsComp: React.FC<AccountSettingsComponentProps & RouteComponentProps> = ({
  user,
  credits,
  countries,
  account,
  history,
  saveProfile,
  uploadCredit,
  goToWorkspace,
  respondWorkspaceInvite,
  initWorkspace }) => {

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    firstName: account.firstName,
    lastName: account.lastName,
    phoneNumber: account.phoneNumber,
    industry: account.industry,
    companyName: account.employeeOfCompanyName,
    companyId: account.employeeOfCompanyId,
    companyCountryId: account.employeeOfCompanyCountryOfFoundation,
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    hasGoogle: account.hasGoogleConnected,
    hasLinkedIn: account.hasLinkedInConnected,
  });

  const [creditQuantity, setCreditQuantity] = useState(1000);
  const [creditValue, setCreditValue] = useState(creditQuantity * 0.01);

  const [canDisconnectGoogle, setCanDisconnectGoogle] = useState((form.hasGoogle && account.hasPasswordConnected) || (form.hasGoogle && form.hasLinkedIn));
  const [canDisconnectLinkedIn, setCanDisconnectLinkedIn] = useState((form.hasLinkedIn && account.hasPasswordConnected) || (form.hasLinkedIn && form.hasGoogle));

  const [passwordTouched, setPasswordTouched] = useState(false);
  const [isPhoneNumberTouched, setPhoneNumberTouched] = useState(false);
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);

  const getInvalidFields = () => {
    return {
      firstName: form.firstName === '',
      lastName: form.lastName === '',
      currentPassword: passwordTouched && (account.hasPasswordConnected ? form.currentPassword === '' : false),
      newPassword: passwordTouched && (account.hasPasswordConnected ? form.newPassword === '' || form.newPassword !== form.newPasswordConfirm : false),
      newPasswordConfirm: passwordTouched && (account.hasPasswordConnected ? form.newPasswordConfirm === '' || form.newPassword !== form.newPasswordConfirm : false),
      match: form.newPassword !== form.newPasswordConfirm,
    };
  };

  const onUploadBalance = async (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    await uploadCredit(creditValue, creditQuantity);
  };

  const openWorkspace = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    initWorkspace();

    history.push('/workspace-create');
  };

  const onGoToWorkspace = (evt: any, workspaceId: number) => {
    evt.preventDefault();
    evt.stopPropagation();
    goToWorkspace(workspaceId);
  };

  const [invalidFields, setInvalidFields] = useState(getInvalidFields());

  const error = { error: 'error' };

  const onUploadCompany = () => {
    history.push(AppRoutes.UploadCompany);
  };

  const onCreditHistory = () => {
    history.push(AppRoutes.CreditHistory);
  };

  const saveProfileInternal = async (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    const _invalidFields = getInvalidFields();
    setInvalidFields(_invalidFields);

    const isValid = Object.values(_invalidFields).every(item => !item) && isPhoneNumberValid;

    if (isValid) {
      saveProfile({
        firstName: form.firstName,
        lastName: form.lastName,
        industry: form.industry,
        companyName: form.companyName,
        companyId: form.companyId,
        phoneNumber: form.phoneNumber,
        companyCountryId: form.companyCountryId,
        currentPassword: form.currentPassword,
        newPassword: form.newPassword,
        newPasswordConfirm: form.newPasswordConfirm,
        hasGoogleConnected: form.hasGoogle,
        hasLinkedInConnected: form.hasLinkedIn,
      });
    }
  };

  const onGoogle = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setCanDisconnectLinkedIn(account.hasLinkedInConnected && (account.hasPasswordConnected || !(!account.hasPasswordConnected && form.hasLinkedIn)));

    setForm({
      ...form,
      hasGoogle: !form.hasGoogle
    });
  };

  const onLinkedin = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setCanDisconnectGoogle(account.hasGoogleConnected && (account.hasPasswordConnected || !(!account.hasPasswordConnected && form.hasLinkedIn)));

    setForm({
      ...form,
      hasLinkedIn: !form.hasLinkedIn
    });
  };

  const onWorkspaceInvite = async (evt: any, workspaceId: number, response: boolean) => {
    evt.preventDefault();
    evt.stopPropagation();

    await respondWorkspaceInvite(workspaceId, response);
  };

  const onDeleteAccount = async (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    dispatch(popupSetModelAction({
      title: 'Are you sure to delete account?',
      body: 'You will lose all information and credit contained in your account. Make sure you save all searches, company profiles and spend all available credit prior to deleting.',
      type: PopupDialogType.Dialog,
      okButtonText: 'Yes',
      noButtonText: 'Cancel',
      okButtonAction: (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        popupService.hidePopup();
        dispatch(accountLoadingAction());
        workspaceService.isWorkspaceAdmin().then(isWorkspaceAdmin => {
          if (isWorkspaceAdmin) {
            history.push(AppRoutes.WorkspaceDeleteConfirm);
          } else {
            accountService.deleteAccount().then(response => {
              if (response.status === 200) {
                dispatch(popupSetModelAction({
                  title: 'Account has been successfully deleted',
                  body: (
                    <div>
                      Check <a href="/privacy-policy#section7">Section 7</a> of the Privacy Policy for Gravity’s data retention procedures.
                    </div>
                  ),
                  type: PopupDialogType.Dialog,
                  okButtonText: 'Ok',
                  okButtonAction: async (evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();

                    popupService.hidePopup();
                    history.push('/');
                    dispatch(logoutAction());
                  }
                }));
                popupService.showPopup();
              }
            });
          }
        })

      },
      cancelButtonAction: (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        popupService.hidePopup();
      }
    }));
    popupService.showPopup();
  };

  const workspaceInvites = account.workspaceInvites && account.workspaceInvites.length > 0 ?
    <>
      <header className="col-header">
        <div className="stack stack-xxs">
          <p className="title title-m">Company Workspace invites</p>
        </div>
      </header>
      <p>You have invites to the following workspaces</p>
      <ul className="list">
        {account.workspaceInvites.map(invite => (
          <li key={invite.workspaceId}>
            <div className="item item--usr company-invite">
              <div className="h-stack h-stack-xs ai c">
                {/* <div className="col col--icon col--image">
                  <svg className="icon icon--l">
                    <use xlinkHref="#icon-users" />
                  </svg>
                </div> */}
                <div className="col col--name">
                  <p className="annotation">Workspace name - <span className="bold">{invite.workspaceName}</span></p>
                  <p className="annotation">Invited by - <span className="bold">{invite.inviterName}</span></p>
                  <p className="annotation">Invite available - <span className="bold">{invite.inviteAvailable}</span></p>
                </div>
                <div className="col col--icon vertical">
                  <button className="button" onClick={(evt) => onWorkspaceInvite(evt, invite.workspaceId, true)}>
                    <span className="button__inner">
                      <span>Accept</span>
                    </span>
                  </button>
                  <button className="button button--terminate" onClick={(evt) => onWorkspaceInvite(evt, invite.workspaceId, false)}>
                    <span className="button__inner">
                      <span>Discard</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </> : "";


  const workspaceList = user?.workspaces.map(workspace => (
    <button className="button button--secondary " onClick={(evt) => onGoToWorkspace(evt, workspace.id)} key={workspace.id}>
      <span className="button__inner">
        <span>{workspace.name}</span>
      </span>
    </button>
  ));

  const onSuggestionSelected = (event: any, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any) => {
    setForm({
      ...form,
      companyId: suggestion.companyId,
      companyName: suggestion.fullName,
      companyCountryId: suggestion.countryOfFoundation
    });
  }

  const countriesOptions = countries.filter(c => c.id).map(c => ({
    name: c.name || '',
    value: c.id || '',
  }));

  return (
    <div className="container">
      <div className="stack stack-m">
        <div className="grid">
          <div className="gi-12">
            <div className="h-stack h-stack-xs jc-sb m-fd-cr">
              <p className="title title-l">Account Settings</p>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="gi-4 col col--left">
            <form className="stack stack-m">
              <header className="col-header">
                <div className="stack stack-xxs">
                  <p className="title title-m">Profile</p>
                </div>
              </header>
              <div>
                <div className="stack stack-xs">
                  <div>
                    <label className="label">
                      First Name<span className="required-star" title="Required">*</span>
                    </label>
                    <div className="field field--input" {...(invalidFields.firstName ? error : {})} >
                      <input className="field__field"
                        value={form.firstName}
                        onChange={(evt) => setForm({
                          ...form,
                          firstName: evt.target.value
                        })}
                        name="firstName" id="firstName" placeholder="First Name" />
                    </div>
                  </div>
                  <div>
                    <label className="label">
                      Last Name<span className="required-star" title="Required">*</span>
                    </label>
                    <div className="field field--input" {...(invalidFields.lastName ? error : {})} >
                      <input className="field__field"
                        value={form.lastName}
                        onChange={(evt) => setForm({
                          ...form,
                          lastName: evt.target.value
                        })}
                        name="lastName" id="lastName" placeholder="Last Name" />
                    </div>
                  </div>
                  <div>
                    <PhoneNumberComponent
                      label={"Phone Number"}
                      value={form.phoneNumber}
                      onBlur={(value: string, isValid: boolean, isAllTouched: boolean) => {
                        setPhoneNumberTouched(true);
                        setPhoneNumberValid(isValid);
                        setForm({
                          ...form,
                          phoneNumber: value
                        })
                      }}
                      isTouched={isPhoneNumberTouched}
                      uploadValueKey={"AccountPhoneNumber"}
                      hasCredit={false}
                    />
                  </div>
                  <div>
                    <label className="label">Industry</label>
                    <div className="field field--select ">
                      <select className="field__field"
                        value={form.industry}
                        onChange={(evt) => setForm({
                          ...form,
                          industry: Number(evt.target.value)
                        })}
                        name="industry" id="industry"
                        placeholder="Any industry">
                        {
                          IndustriesArray.filter(i => i.value !== undefined).map((industryOption) => (
                            <option key={industryOption.key} value={industryOption.value}>{industryOption.label}</option>
                          ))
                        }
                      </select>
                      <div className="field__icon field__icon--right">
                        <svg className="icon icon--m ">
                          <use xlinkHref="#icon-arrow-down" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <span className="country-lead">You will receive additional credit for filling in the Company Name, Country and Phone Number</span>
                  <div>
                    <CreditBadgeComponent
                      price={credits['AccountCompanyName'].uploadValue}
                      hasValue={!!form.companyName && !account.receivedCreditForCompany}
                    />
                  </div>
                  <div>
                    <CompanyAutoSuggestComponent
                      companyName={form.companyName}
                      setCompanyName={(input: any) => {
                        setForm({
                          ...form,
                          companyName: input.newValue,
                          companyId: undefined
                        });
                      }}
                      onSuggestionSelected={onSuggestionSelected}
                    />
                  </div>
                  <div>
                    <label className="label" htmlFor="field-3">Country</label>
                    <div className="field field--input">
                      <select className="field__field"
                        value={form.companyCountryId || ''}
                        onChange={(evt: any) => {
                          setForm({
                            ...form,
                            companyCountryId: Number(evt.target.value) as any
                          });
                        }}>
                        <option value={''}>Select Country</option>
                        {countriesOptions.map(option =>
                          <option key={option.name} value={option.value}>{option.name}</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div>
                    <CreditBadgeComponent
                      price={credits['AccountCompanyCountryOfFoundation'].uploadValue}
                      hasValue={!!form.companyCountryId && !account.receivedCreditForCompanyCountry} />
                  </div>
                </div>
              </div>
              <hr />
              <header className="col-header">
                <div className="stack stack-xxs">
                  <p className="title title-m">Security Settings</p>
                </div>
              </header>
              <div>
                <div className="stack stack-xs">
                  <p className="fw-b">Change Login Details</p>
                  {
                    account.hasPasswordConnected ? (
                      <div className="stack stack-xs">
                        <div>
                          <label className="label">
                            Current Password <span className="required-star" title="Required">*</span>
                          </label>
                          <div className="field field--input" {...(invalidFields.currentPassword ? error : {})} >
                            <input className="field__field"
                              value={form.currentPassword}
                              type="password"
                              onChange={(evt) => {
                                setForm({
                                  ...form,
                                  currentPassword: evt.target.value
                                });
                                setPasswordTouched(true);
                              }}
                              name="currentPassword" id="currentPassword" placeholder="Current Password" />
                          </div>
                        </div>
                        <div>
                          <label className="label">
                            Set a New Password <span className="required-star" title="Required">*</span>
                          </label>
                          <div className="field field--input" {...(invalidFields.newPassword ? error : {})}>
                            <input className="field__field"
                              value={form.newPassword}
                              type="password"
                              onChange={(evt) => {
                                setForm({
                                  ...form,
                                  newPassword: evt.target.value
                                });
                                setPasswordTouched(true);
                              }}
                              name="newPassword" id="newPassword" placeholder="Set a New Password" />
                          </div>
                        </div>
                        <div>
                          <label className="label">
                            New Password again <span className="required-star" title="Required">*</span>
                          </label>
                          <div className="field field--input" {...(invalidFields.newPasswordConfirm ? error : {})}>
                            <input className="field__field"
                              value={form.newPasswordConfirm}
                              type="password"
                              onChange={(evt) => {
                                setForm({
                                  ...form,
                                  newPasswordConfirm: evt.target.value
                                });
                                setPasswordTouched(true);
                              }}
                              name="newPasswordConfirm" id="newPasswordConfirm" placeholder="New password Again" />
                          </div>
                        </div>
                      </div>
                    ) : ''
                  }

                  {
                    invalidFields.match ? (
                      <div className="error">
                        The two passwords does not match. <br />
                      </div>
                    ) : ''
                  }

                  {
                    canDisconnectGoogle ? (
                      <div>
                        <button className="button button--secondary" onClick={(evt) => onGoogle(evt)}>
                          <span className="button__inner">
                            <svg className="icon icon--m ">
                              <use xlinkHref="#icon-not-available" />
                            </svg>
                            <span>{form.hasGoogle ? 'Disconnect from Google' : 'Reconnect to Google'}</span>
                          </span>
                        </button>
                      </div>
                    ) : account.hasGoogleConnected ? (
                      <div>
                        <button className="button button--secondary " disabled={true}>
                          <span className="button__inner">
                            <svg className="icon icon--m ">
                              <use xlinkHref="#icon-not-available" />
                            </svg>
                            <span>Disconnect from Google</span>
                          </span>
                        </button>
                      </div>
                    ) : ''}

                  {
                    canDisconnectLinkedIn ? (
                      <div>
                        <button className="button button--secondary " onClick={(evt) => onLinkedin(evt)}>
                          <span className="button__inner">
                            <svg className="icon icon--m ">
                              <use xlinkHref="#icon-not-available" />
                            </svg>
                            <span>{form.hasLinkedIn ? 'Disconnect from LinkedIn' : 'Reconnect to LinkedIn'}</span>
                          </span>
                        </button>
                      </div>
                    ) : account.hasLinkedInConnected ? (
                      <div>
                        <button className="button button--secondary " disabled={true}>
                          <span className="button__inner">
                            <svg className="icon icon--m ">
                              <use xlinkHref="#icon-not-available" />
                            </svg>
                            <span>Disconnect from LinkedIn</span>
                          </span>
                        </button>
                      </div>
                    ) : ''
                  }
                </div>
              </div>
              <div>
                <button className="button" onClick={(evt) => saveProfileInternal(evt)}>
                  <span className="button__inner">
                    <span>Save Account</span>
                  </span>
                </button>
              </div>
              <span />
              <div className="split-bottom" onClick={(evt) => onDeleteAccount(evt)}>
                <button className="button button--terminate ">
                  <span className="button__inner">
                    <span>Delete Account</span>
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div className="gi-4 col col--center">
            <div className="stack stack-m">
              <header className="col-header">
                <div className="stack stack-xxs">
                  <p className="title title-m">Workspace</p>
                </div>
              </header>
              <p>Invite your colleagues and friends to your workspace to share credit. Suitable for companies working on joint projects.</p>
              {workspaceList}
              <button className="button button--secondary " onClick={(evt) => openWorkspace(evt)}>
                <span className="button__inner">
                  <span>Create Workspace</span>
                </span>
              </button>
              {workspaceInvites}
            </div>
          </div>
          <div className="gi-4 col col--right">
            <div className="stack stack-m">
              <header className="col-header">
                <div className="stack stack-xxs">
                  <p className="title title-m">Credit Balance</p>
                </div>
              </header>
              <div>
                <div className="stack stack-xs">
                  <span className="icon-header icon-header--header-right " id="">
                    <span className="icon-header__inner">
                      <svg className="icon icon--l ">
                        <use xlinkHref="#icon-gravity-credit" />
                      </svg>
                      <span className="title title-l">{Utils.formatNumber(account.creditCount)}</span>
                    </span>
                  </span>
                </div>
              </div>
              <hr />
              <div>
                <div className="stack stack-xs">
                  <p className="title title-s">Buy more Credit</p>
                  <p>Top up you credit balance using a debit or credit card</p>
                  <div className="h-stack h-stack-xs ai-fe">
                    <div className="quantity">
                      <div className="field field--input">
                        <input className="field__field"
                          type="number"
                          value={creditQuantity}
                          onChange={(evt) => {
                            const value = Number(evt.target.value);
                            const creditValue = Number((value * 0.01).toFixed(2));
                            setCreditQuantity(value);
                            setCreditValue(creditValue);
                          }}
                          name="worspace_credit_quantity"
                          id="worspace_credit_quantity"
                          placeholder="" />
                      </div>
                    </div>
                    <p className="fill annotation">EUR 0.01  / Credit</p>
                  </div>
                  <div>
                    <button className="button" onClick={(evt) => onUploadBalance(evt)} disabled={creditValue < 5}>
                      <span className="button__inner">
                        <span>Pay EUR {Utils.formatNumber(creditValue)} + VAT</span>
                      </span>
                    </button>
                  </div>
                  {creditValue < 5 ?
                    <div style={{ marginTop: 0 }}>
                      <span style={{ fontSize: '12px' }}>Min. upload is 5 EUR</span>
                    </div>
                    : null}
                  <div className="cards">
                    <div className="h-stack h-stack-xxs ai-c">
                      <svg className="icon icon--m ">
                        <use xlinkHref="#icon-bank-card-visa" />
                      </svg>
                      <svg className="icon icon--m ">
                        <use xlinkHref="#icon-bank-card-mastercard" />
                      </svg>
                      <img src="/assets/img/amex-24.png" alt="Amex" />
                      <img src="/assets/img/paypal-3-24.png" alt="PayPal" />
                    </div>
                  </div>
                </div>
              </div >
              <div>
                <div className="stack stack-xs">
                  <p className="title title-s">Earn credit by uploading information</p>
                  <div>
                    <button className="button button--secondary" onClick={onUploadCompany}>
                      <span className="button__inner">
                        <span>Upload a Company</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="stack stack-xs">
                  <div>
                    <button className="button button--secondary" onClick={onCreditHistory}>
                      <span className="button__inner">
                        <span>Credit History</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div >
          </div >
        </div >
      </div >
    </div >
  );
};


export const AccountSettingsComponent = withRouter(AccountSettingsComp);

