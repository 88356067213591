import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { AppState } from "../root.reducer";
import { LoginComponentDispatchProps, LoginPage, LoginComponentStateProps } from './LoginPage.component';
import { authenticationService } from './authentication.service';
import { authenticationSuccessAction, authenticationLoadingAction, authenticationErrorAction } from './authentication.actions';
import { isAuthenticatedSelector, isAuthenticationLoadingSelector, authenticationErrorSelector } from "./authentication.selectors";
import { User } from './authentication.models';

const mapStateToProps = (state: AppState): LoginComponentStateProps => ({
  isLoggedIn: isAuthenticatedSelector(state),
  isLoading: isAuthenticationLoadingSelector(state),
  error: authenticationErrorSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): LoginComponentDispatchProps => ({
  login: async (username: string, password: string, returnUrl?: string) => {
    dispatch(authenticationLoadingAction());
    try {
      const response = await authenticationService.login(username, password);
      
      if (response.hasWorkspaceInvite) {
        window.location.href = '/workspace-response';
      }

      console.log(response);

      dispatch(authenticationSuccessAction(response as User));
    } catch {
      dispatch(authenticationErrorAction("E-mail or password not correct"));
    }
  }
});

export default connect<LoginComponentStateProps, LoginComponentDispatchProps, {}, AppState>(
  mapStateToProps, mapDispatchToProps
)(LoginPage);
