import React, { useState } from "react";
import { SearchKeywordsFields, SimpleSearchFields } from "../search.models";
import { SearchInKeywordComponent } from "../SearchFields/SearchInKeyword.component";
import { SelectFieldComponent, SelectItem } from '../SearchFields/SelectField.component';
import { RangeControl } from "../SearchFields/RangeControl.component";
import { IndustriesArray, EntityRelationshipStatusArray, SectorModel } from '../../models';
import { CountryModel } from '../../meta/meta.models';
import { RouteComponentProps } from 'react-router-dom';
import { FullPageLoadingComponent } from '../../FullpageLoading.component';
import { companyEditService } from '../../company-edit/company-edit.service';

export interface AdvancedSearchPageComponentProps {
    countries?: CountryModel[];
    fields: SimpleSearchFields | undefined;
    isLoading?: boolean;
}
export interface AdvancedSearchPageDispatchProps {
    searchCompanies(model: SimpleSearchFields): Promise<void>;
}

export const AdvancedSearchPageComponent: React.FC<AdvancedSearchPageComponentProps & AdvancedSearchPageDispatchProps & RouteComponentProps> =
    ({ countries, searchCompanies, fields, history, isLoading }) => {
        // List of fields
        
        const getSectors = (_industry: number) => {
            const selectedSectors = companyEditService.selectSectorsByIndustry(_industry);
            const sectorItems = selectedSectors.map(s => ({ key: s.value, value: s.value, label: s.name }));
            return [
                { key: 0, value: 0, label: 'All sectors' },
                ...sectorItems
            ];
        }

        const [keyword, setKeyword] = useState(fields?.keyword || '');
        const [industry, setIndustry] = useState<number | undefined>(fields?.industry ?? undefined);
        const [sector, setSector] = useState<number | undefined>(fields?.sector ?? 0);
        const [sectorList, setSectorList] = useState<SelectItem[]>(fields?.industry ? getSectors(fields.industry) : [{ value: 0, label: 'Select industry first' }]);
        const [entityRelationshipStatus, setEntityRelationshipStatus] = useState(fields?.entityRelationshipStatus ?? undefined);
        const defaultCountry = countries ? countries[0].id : undefined;

        const [countryOfFoundationId, setCountry] = useState(fields?.countryOfFoundationId ?? defaultCountry);

        const [numberOfEmployeesFrom, setnumberOfEmployeesFrom] = useState<number | undefined>(fields?.numberOfEmployees?.from ?? undefined);
        const [numberOfEmployeesTo, setnumberOfEmployeesTo] = useState<number | undefined>(fields?.numberOfEmployees?.to ?? undefined);

        const [revenueFrom, setRevenueFrom] = useState<number | undefined>(fields?.revenue?.from ?? undefined);
        const [revenueTo, setRevenueTo] = useState<number | undefined>(fields?.revenue?.to ?? undefined);

        const [netIncomeAfterTaxesFrom, setNetIncomeAfterTaxesFrom] = useState<number | undefined>(fields?.netIncomeAfterTaxes?.from ?? undefined);
        const [netIncomeAfterTaxesTo, setNetIncomeAfterTaxesTo] = useState<number | undefined>(fields?.netIncomeAfterTaxes?.to ?? undefined);

        const [website, setWebsite] = useState(fields?.website ?? '');
        const [ceo, setCeo] = useState(fields?.ceo ?? '');

        const [searchInAllAvailableData, setSearchInAllAvailableData] = useState(fields?.searchInAllAvailableData ?? false);

        const [searchKeywordsInFields, setSearchKeywordsInFields] = useState<SearchKeywordsFields>({
            companynames: fields?.searchKeywordsInFields?.companynames ?? true,
            contacts: fields?.searchKeywordsInFields?.contacts ?? false,
            addresses: fields?.searchKeywordsInFields?.addresses ?? false,
            productsAndServices: fields?.searchKeywordsInFields?.productsAndServices ?? false,
            allOther: fields?.searchKeywordsInFields?.allOther ?? false
        });

        const [excludeWithNegativeEbit, setExcludeWithNegativeEbit] = useState<boolean>(fields?.excludeWithNegativeEbit ?? false);
        const [withContacts, setWithContacts] = useState<boolean>(fields?.withContacts ?? false);
        const [withFinancials, setWithFinancials] = useState<boolean>(fields?.withFinancials ?? false);

        if (isLoading) {
            return <FullPageLoadingComponent />;
        }        

        const renderSearchInKeywordCheckbox = (propertyName: keyof SearchKeywordsFields) => (
            <SearchInKeywordComponent
                propertyName={propertyName}
                checked={searchKeywordsInFields[propertyName]}
                onChange={(value) => {
                    if (propertyName === 'allOther') {
                        setSearchKeywordsInFields({
                            companynames: false,
                            addresses: false,
                            contacts: false,
                            productsAndServices: false,
                            allOther: value
                        });
                        if (value === false) {
                            setSearchKeywordsInFields({ ...searchKeywordsInFields, companynames: true, allOther: false });
                        }
                    } else {
                        setSearchKeywordsInFields({ ...searchKeywordsInFields, [propertyName]: value, allOther: false });
                    }

                    const fields = {
                        ...searchKeywordsInFields,
                        [propertyName]: value
                    };
                    if (Object.keys(fields).every((k) => !(fields as any)[k])) {
                        setSearchKeywordsInFields({
                            ...fields,
                            companynames: true,
                        });
                    }
                }}
            />
        );

        const searchByAllFields = () => {
            searchCompanies({
                keyword,
                industry,
                sector,
                searchKeywordsInFields,
                countryOfFoundationId,
                numberOfEmployees: {
                    from: numberOfEmployeesFrom,
                    to: numberOfEmployeesTo
                },
                netIncomeAfterTaxes: {
                    from: netIncomeAfterTaxesFrom,
                    to: netIncomeAfterTaxesTo
                },
                revenue: {
                    from: revenueFrom,
                    to: revenueTo
                },
                ceo,
                website,
                withContacts,
                withFinancials,
                excludeWithNegativeEbit,
                entityRelationshipStatus,
                searchInAllAvailableData
            });
        };

        const onClose = (evt: any) => {
            evt.preventDefault();
            evt.stopPropagation();

            history.push('/search');
        };

        const handleKeyPress = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                searchByAllFields();
            }
        }

        return (
            <div className="section section--content">
                <div className="container">
                    <div className="stack stack-m">
                        <div>
                            <div className="h-stack h-stack-xs jc-sb m-fd-cr">
                                <h1 className="title title-l">Advanced Search</h1>
                                <button className="icon-button " onClick={(evt) => onClose(evt)}>
                                    <span className="icon-button__inner">
                                        <svg className="icon icon--m ">
                                            <use xlinkHref="#icon-close" />
                                        </svg>
                                        <span className="label">Close Advanced Search</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="search-form--advanced">
                            <div className="stack stack-m">
                                <div className="grid">
                                    <div className="gi-9 t-gi-8">
                                        <div className="stack stack-xs">
                                            <div className="label">Keyword</div>
                                            <div className="field field--input field--icon-right ">
                                                <input className="field__field"
                                                    value={keyword}
                                                    onKeyPress={(evt) => handleKeyPress(evt)}
                                                    onChange={(evt) => setKeyword(evt.target.value)}
                                                    name="search-keyword" id="search-keyword"
                                                    placeholder="Type in words from name, address etc. Search will find companies that include all keywords in the selected fields." />
                                                <div className="field__icon field__icon--right">
                                                    <svg className="icon icon--m ">
                                                        <use xlinkHref="#icon-search" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="search-form__filter">
                                                <div className="h-stack h-stack-xs m-fd-c">
                                                    <div>
                                                        <div className="label fw-b">Search Keyword(s) in:</div>
                                                    </div>
                                                    <div className="search-form__filter-toggles h-stack h-stack-xs">
                                                        {renderSearchInKeywordCheckbox('companynames')}
                                                        {renderSearchInKeywordCheckbox('contacts')}
                                                        {renderSearchInKeywordCheckbox('addresses')}
                                                        {renderSearchInKeywordCheckbox('productsAndServices')}
                                                        {renderSearchInKeywordCheckbox('allOther')}
                                                    </div>
                                                </div>
                                                <div className="h-stack h-stack-xs m-fd-c">
                                                    <div>
                                                        <div className="label fw-b">Search in:</div>
                                                    </div>
                                                    <div className="search-form__filter-toggles h-stack h-stack-xs ">                                                        
                                                        <div>
                                                            <label className="switch switch--toggle switch--label-multiple">
                                                                <span className="switch__left_label label">Latest Modifications</span>
                                                                <input className="visually-hidden"
                                                                    checked={searchInAllAvailableData}
                                                                    onChange={evt => setSearchInAllAvailableData(evt.target.checked)}
                                                                    type="checkbox"
                                                                />
                                                                <span className="switch__switch" onTouchEnd={(evt) => {  setSearchInAllAvailableData(!searchInAllAvailableData) }}>
                                                                    <span className="dot" />
                                                                </span>
                                                                <span className="switch__right_label label">All Available Data</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="gi-3 t-gi-8">
                                        <SelectFieldComponent
                                            label="Industry"
                                            options={IndustriesArray}
                                            value={industry}
                                            onChange={(evt) => {
                                                const _industry = Number(evt);
                                                if (!isNaN(_industry)) {
                                                    setSector(0);
                                                    const selectedSectors = companyEditService.selectSectorsByIndustry(_industry);
                                                    const sectorItems = selectedSectors.map(s => ({ key: s.value, value: s.value, label: s.name }));
                                                    const _sectorList = [
                                                        { key: 0, value: 0, label: 'All sub-sectors' },
                                                        ...sectorItems
                                                    ];

                                                    setIndustry(_industry);
                                                    setSectorList(_sectorList);
                                                } else {
                                                    setIndustry(0);
                                                    setSector(0);
                                                    setSectorList([
                                                        { value: 0, label: 'Select industry first' }
                                                    ]);
                                                }
                                            }} />
                                    </div>
                                    <div className="gi-3 t-gi-8">
                                        <SelectFieldComponent
                                            label="Sub-Sector"
                                            disabled={isNaN(Number(industry))  || industry === 0}
                                            value={sector}
                                            options={sectorList}
                                            onChange={(evt) => {
                                                setSector(Number(evt));
                                            }} />
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="gi-3 t-gi-8">
                                        <SelectFieldComponent
                                            label="Entity Relationship Status"
                                            options={EntityRelationshipStatusArray}
                                            value={entityRelationshipStatus}
                                            onChange={value => setEntityRelationshipStatus(value)}
                                        />
                                    </div>
                                    <div className="gi-3 t-gi-8">
                                        <div className="label">Country</div>
                                        <div className="field field--select">
                                            {
                                                countries ? (
                                                    <select
                                                        className="field__field"
                                                        placeholder=""
                                                        value={countryOfFoundationId}
                                                        onChange={(evt) => setCountry(Number(evt.target.value) || undefined)}
                                                    >
                                                        {
                                                            countries.map((countryOption, index) => (
                                                                <option key={index} value={countryOption.id}>{countryOption.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                ) : null
                                            }
                                            <div className="field__icon field__icon--right">
                                                <svg className="icon icon--m">
                                                    <use xlinkHref="#icon-arrow-down" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="gi-2 t-gi-8 m-gi-4">
                                        <hr />
                                    </div>
                                </div>
                                <div>
                                    <RangeControl label="Revenues (million EUR)" 
                                        from={revenueFrom}
                                        to={revenueTo}
                                        enabledRegex={"^[0-9]+$"}
                                        onFromChange={value => value == 0 ? setRevenueFrom(undefined) : setRevenueFrom(value)}
                                        onToChange={value => value == 0 ? setRevenueTo(undefined) : setRevenueTo(value)}
                                    />
                                </div>
                                <div>
                                    <RangeControl label="Net Income After Taxes (million EUR)" 
                                        from={netIncomeAfterTaxesFrom} 
                                        to={netIncomeAfterTaxesTo}
                                        enabledRegex={"^[0-9]+$"}
                                        onFromChange={value => value == 0 ? setNetIncomeAfterTaxesFrom(undefined) : setNetIncomeAfterTaxesFrom(value)}
                                        onToChange={value => value == 0 ? setNetIncomeAfterTaxesTo(undefined) : setNetIncomeAfterTaxesTo(value)}
                                    />
                                </div>
                                <div>
                                    <RangeControl label="Number of Employees"
                                        from={numberOfEmployeesFrom} 
                                        to={numberOfEmployeesTo}
                                        enabledRegex={"^[0-9]+$"}
                                        onFromChange={value => value == 0 ? setnumberOfEmployeesFrom(undefined) : setnumberOfEmployeesFrom(value)}
                                        onToChange={value => value == 0 ? setnumberOfEmployeesTo(undefined) : setnumberOfEmployeesTo(value)}
                                    />
                                </div>
                                <div className="grid">
                                    <div className="gi-2 t-gi-8 m-gi-4">
                                        <hr />
                                    </div>
                                </div>
                                <div>
                                    <div className="grid">
                                        <div className="gi-3 t-gi-8">
                                            <div className="stack stack-xs">
                                                <div className="label">Part of Website Address</div>
                                                <div className="field field--input">
                                                    <input className="field__field" value={website} onChange={(evt) => setWebsite(evt.target.value)}
                                                        name="" placeholder="Type in any part of the Website address" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gi-3 t-gi-8">
                                            <div className="stack stack-xs">
                                                <div className="label">Name of Management Executive</div>
                                                <div className="field field--input">
                                                    <input className="field__field" value={ceo} onChange={(evt) => setCeo(evt.target.value)}
                                                        name="" placeholder="Type in any part of the Executive's Name" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="gi-2 t-gi-8 m-gi-4">
                                        <hr />
                                    </div>
                                </div>
                                <div>
                                    <div className="stack stack-xs">
                                        <p className="fw-b">Only search for</p>
                                        <label className="switch switch--toggle switch--label-right">
                                            <input className="visually-hidden"
                                                checked={excludeWithNegativeEbit}
                                                onChange={evt => setExcludeWithNegativeEbit(evt.target.checked)}
                                                type="checkbox"
                                            />
                                            <span className="switch__switch">
                                                <span className="dot" />
                                            </span>
                                            <span className="switch__label label">Exclude Companies with Negative EBIT</span>
                                        </label>
                                        <label className="switch switch--toggle switch--label-right">
                                            <input className="visually-hidden"
                                                checked={withContacts}
                                                onChange={evt => setWithContacts(evt.target.checked)}
                                                type="checkbox" id="as-cont" name="" />
                                            <span className="switch__switch">
                                                <span className="dot" />
                                            </span>
                                            <span className="switch__label label">Contact Details</span>
                                        </label>
                                        <label className="switch switch--toggle switch--label-right">
                                            <input className="visually-hidden"
                                                checked={withFinancials}
                                                onChange={evt => setWithFinancials(evt.target.checked)}
                                                type="checkbox" id="as-fina" name="" />
                                            <span className="switch__switch">
                                                <span className="dot" />
                                            </span>
                                            <span className="switch__label label">Uploaded Financials</span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <button className="button" onClick={() => searchByAllFields()}>
                                        <span className="button__inner">
                                            <span>Search</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    };
