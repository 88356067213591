import { actionCreator } from '../actions';
import { CountryModel } from './meta.models';

export enum MetaActionTypes {
    LOAD_COUNTRIES = 'countries/load',
    LOAD_COUNTRIES_SUCCESS = 'countries/load/success',
    LOAD_COMPANY_COUNT_SUCCESS = 'companycount/load/success',
    LOAD_COUNTRIES_ERROR = 'countries/load/error',
    MOBILE_MENU_CHANGE = 'mobil/menu/change',
}

export const loadCountriesAction = actionCreator<MetaActionTypes.LOAD_COUNTRIES, void>(MetaActionTypes.LOAD_COUNTRIES);
export const loadCountriesSuccessAction = actionCreator<MetaActionTypes.LOAD_COUNTRIES_SUCCESS, CountryModel[]>(MetaActionTypes.LOAD_COUNTRIES_SUCCESS);
export const loadCompanyCountSuccessAction = actionCreator<MetaActionTypes.LOAD_COMPANY_COUNT_SUCCESS, number>(MetaActionTypes.LOAD_COMPANY_COUNT_SUCCESS);
export const loadCountriesErrorAction = actionCreator<MetaActionTypes.LOAD_COUNTRIES_ERROR, void>(MetaActionTypes.LOAD_COUNTRIES_ERROR);
export const changeMobilMenuState = actionCreator<MetaActionTypes.MOBILE_MENU_CHANGE, boolean>(MetaActionTypes.MOBILE_MENU_CHANGE);

export type MetaActions =
    ReturnType<typeof loadCountriesAction> |
    ReturnType<typeof loadCountriesSuccessAction> |
    ReturnType<typeof loadCompanyCountSuccessAction> |
    ReturnType<typeof loadCountriesErrorAction> |
    ReturnType<typeof changeMobilMenuState>
;