import React from "react";
import {  SavedCompaniesListModel } from "../saved-companies.models";
import { SavedCompanyListItemComponent } from "./SavedCompanyListItem.component";

export interface SavedCompaniesListComponentProps {
  isLoading: boolean;
  savedCompanyList: SavedCompaniesListModel | undefined;
  page: number;
  nextPage: (maxPage: number) => void;
  previousPage: () => void;
}

export const SavedCompaniesListComponent: React.FC<SavedCompaniesListComponentProps> = ({ isLoading, savedCompanyList, page, nextPage, previousPage }) => {
  if (isLoading || !savedCompanyList) {
    return <p>Loading...</p>;
  }

  if (!savedCompanyList?.count) {
    return <p>No saved companies.</p>;
  }

  const pageSize = 50;
  const pagesLength = Math.trunc(savedCompanyList.count / pageSize);
  const maxPageNumber = savedCompanyList.count % pageSize > 0 ? pagesLength + 1 : pagesLength;

  const onNext = (evt: any) => {
    evt.preventDefault();
    nextPage(maxPageNumber);
  }

  const onPrev = (evt: any) => {
    evt.preventDefault();
    previousPage();
  }

  return (
    <div>
      <div className="grid">
        <div className="results__sort gi-12 t-gi-8">
          <div className="pager">
            <div className="2 h-stack h-stack-xs ai-c jc-c">
              <button className="icon-button"  onClick={(evt) => onPrev(evt)}>
                <span className="icon-button__inner">
                  <svg className="icon icon--m">
                    <use xlinkHref="#icon-arrow-nav-back" />
                  </svg>
                  <span className="visually-hidden">Back</span>
                </span>
              </button>
              <p className="annotation">Page {page}/{maxPageNumber}</p>
              <button className="icon-button" onClick={(evt) => onNext(evt)}>
                <span className="icon-button__inner">
                  <svg className="icon icon--m">
                    <use xlinkHref="#icon-arrow-nav-forward" />
                  </svg>
                  <span className="visually-hidden">Next</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ul className="list">
        {savedCompanyList?.list.map((company, index) => <SavedCompanyListItemComponent key={index} company={company} />)}
      </ul >
    </div>
  );
};
