import { CompanyUploadActions, CompanyUploadActionTypes } from './company-upload.actions';
import { CreditMap, TouchedFinancialInputsModel, TouchedInputsModel } from './company-upload.models';
import { CompanyUploadModel } from './CompanyUpload.model';

export interface CompanyUploadState {
    uploadModel?: CompanyUploadModel;
    isCompanyUploadCreditsLoading: boolean;
    isCompanyUploading: boolean;
    companyUploadedSuccess: boolean;
    companyCredits?: CreditMap;
    isCurrenciesLoading: boolean;
    currencies?: string[];
    touchedInputs?: TouchedInputsModel;
}

const initialState: CompanyUploadState = {
    uploadModel: undefined,
    isCompanyUploadCreditsLoading: false,
    isCompanyUploading: false,
    companyUploadedSuccess: false,
    companyCredits: undefined,
    isCurrenciesLoading: false,
    currencies: undefined,
    touchedInputs: undefined,
};

export const companyUploadReducer = (state: CompanyUploadState = initialState, action: CompanyUploadActions): CompanyUploadState => {
    switch (action.type) {
        case CompanyUploadActionTypes.COMPANY_UPLOAD_MODEL_INITIALIZE:
        case CompanyUploadActionTypes.COMPANY_UPLOAD_MODEL_UPDATE:
            return {
                ...state,
                uploadModel: action.payload as CompanyUploadModel
            };
        case CompanyUploadActionTypes.COMPANY_UPLOAD_CREDITS_LOADING:
            return {
                ...state,
                isCompanyUploadCreditsLoading: true,
                companyCredits: undefined,
            };
        case CompanyUploadActionTypes.COMPANY_UPLOAD_CREDITS_SUCCESS:
            return {
                ...state,
                isCompanyUploadCreditsLoading: false,
                isCompanyUploading: false,
                companyCredits: action.payload as CreditMap
            };
        case CompanyUploadActionTypes.COMPANY_UPLOADED_SUCCESS:
            return {
                ...state,
                uploadModel: undefined,
                isCompanyUploadCreditsLoading: false,
                isCompanyUploading: false,
                companyUploadedSuccess: true,
                companyCredits: undefined
            };
        case CompanyUploadActionTypes.COMPANY_UPLOADING:
            return {
                ...state,
                isCompanyUploading: true,
            };
        case CompanyUploadActionTypes.COMPANY_UPLOADED_FAILED:
            return {
                ...state,
                isCompanyUploading: false,
            };
        case CompanyUploadActionTypes.COMPANY_CURRENCIES_LOADING:
            return {
                ...state,
                isCurrenciesLoading: true
            };
        case CompanyUploadActionTypes.COMPANY_CURRENCIES_SUCCESS:
            return {
                ...state,
                isCurrenciesLoading: false,
                currencies: action.payload as string[]
            };
        case CompanyUploadActionTypes.COMPANY_UPLOAD_TOUCH_INPUTS:
            return {
                ...state,
                touchedInputs: action.payload as TouchedInputsModel
            };
        case CompanyUploadActionTypes.COMPANY_UPLOAD_TOUCH_FINANCIALS_INPUTS:
            const newTouchedInputs = action.payload as TouchedFinancialInputsModel[];
            return {
                ...state,
                touchedInputs: {
                    ...state.touchedInputs,
                    financials: newTouchedInputs.map((financial, index) => ({
                        incomeStatement: {
                            ...state.touchedInputs?.financials[index].incomeStatement,
                            ...financial.incomeStatement
                        },
                        balanceSheet: {
                            ...state.touchedInputs?.financials[index].balanceSheet,
                            ...financial.balanceSheet
                        },
                    })),
                }
            };
        default:
            return state;
    }
};