import React, { useEffect } from "react";
import SearchFields from '../SearchFields.container';
import { SavedSearchTableResultsComponent } from "./SavedSearchTableResults.component";
import { FullPageLoadingComponent } from '../../FullpageLoading.component';
import { SavedCompanyListModel } from '../../saved-company/saved-company.models';

export interface SavedSearchTableStateProps {
    isSearching: boolean;
    isLoadingSavedSearch: boolean;
    savedSearch?: SavedCompanyListModel;
    savedSearchId: number;
    useGravityAs?: number;
    pageIndex: number;
    pageSize: number;
}

export interface SavedSearchTableDispatchProps {
    loadSavedSearch: (id: number, page: number, pageSize: number, useGravityAs?: number) => void;
    setPage(page: number): void;
    setPageSize(pageSize: number): void;
    resetCurrentPage(): void;
}

export const SavedSearchTableComponent: React.FC<SavedSearchTableStateProps & SavedSearchTableDispatchProps> =
    ({ savedSearchId, isLoadingSavedSearch, pageIndex, pageSize, setPage, setPageSize, resetCurrentPage, isSearching, savedSearch, loadSavedSearch, useGravityAs }) => {
        useEffect(() => {
            loadSavedSearch(savedSearchId, pageIndex, pageSize, useGravityAs);
        }, [loadSavedSearch, savedSearchId, pageIndex, pageSize, useGravityAs]);

        const renderSearchResults = () => {
            if (isLoadingSavedSearch || isSearching) {
                return <FullPageLoadingComponent />;
            }

            if (savedSearch) {
                return <SavedSearchTableResultsComponent
                    savedSearchId={savedSearchId}
                    pageIndex={pageIndex}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    resetCurrentPage={resetCurrentPage}
                    results={savedSearch} 
                    loadSavedSearch={() => loadSavedSearch(savedSearchId, pageIndex, pageSize, useGravityAs)}
                />;
            }

            return <p>Error.</p>;
        };

        return (
            <>
                <SearchFields />
                {renderSearchResults()}
            </>
        );
    };
