import React, { useState, useEffect } from 'react';
import { CreditMap, CompanyUploadSummary, PropertyWithPrice, TouchedInputsModel, TouchedFinancialInputsModel } from '../company-upload/company-upload.models';
import { CompanyUploadPage } from '../company-upload/CompanyUploadPage.component';
import { SavedCompanyModel } from '../saved-company/saved-company.models';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import { companyEditService } from './company-edit.service';
import { creditsService } from '../company-upload/credits.service';
import { SectorModel } from '../models';
import { CompanyUploadModel } from '../company-upload/CompanyUpload.model';
import { useDispatch } from "react-redux";
import { popupSetModelAction } from '../popup/popup.actions';
import { popupService } from '../popup/popup.service';
import { PopupDialogType } from '../popup/popup.models';

export interface CompanyEditStateProps {
    companyId: number;
    company?: SavedCompanyModel;
    isSavedCompanyLoading: boolean;

    isCurrenciesLoading: boolean;
    currencies: string[] | undefined;

    companyCredits: CreditMap | undefined;
    isCompanyCreditsLoading: boolean;
    touchedInputs: TouchedInputsModel | undefined;

    companyUploadedSuccess: boolean;

    useGravityAs?: number;
}

export interface CompanyEditDispatchProps {
    loadCompany(id: number, useGravityAs?: number): void;
    loadCompanyCredits(): void;
    loadCurrencies(): void;
    updateCompany(originalCompany: SavedCompanyModel, editCompanyModel: CompanyUploadModel, creditReceived: number, useGravityAs?: number): void;
    setTouchedFinancials: (financials: TouchedFinancialInputsModel[]) => void;
}

export const CompanyEditComponent: React.FC<CompanyEditStateProps & CompanyEditDispatchProps> = ({
    companyId,
    company,
    loadCompany,
    isSavedCompanyLoading,
    companyCredits,
    loadCompanyCredits,
    loadCurrencies,
    touchedInputs,
    setTouchedFinancials,
    isCurrenciesLoading,
    isCompanyCreditsLoading,
    updateCompany,
    currencies,
    useGravityAs,
}) => {
    const [originalCompany, setOriginalCompany] = useState<CompanyUploadModel | undefined>();
    const [editCompanyModel, setEditCompanyModel] = useState<CompanyUploadModel | undefined>();
    const [isInitial, setIsInitial] = useState(true);
    const [isFormValid, setIsFormValid] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        loadCompany(companyId, useGravityAs);
        loadCompanyCredits();
        setIsInitial(false);
        loadCurrencies();
    }, [loadCompanyCredits, loadCompany, companyId, loadCurrencies, useGravityAs]);

    useEffect(() => {
        if (companyCredits && company) {
            const editModel = companyEditService.createEditModel(companyCredits, company);

            setOriginalCompany(JSON.parse(JSON.stringify(editModel)));
            setEditCompanyModel(editModel);
            setTouchedFinancials(
                editModel?.financials.map((_: any) => ({
                    incomeStatement: {},
                    balanceSheet: {}
                }))
            );
        }
    }, [companyCredits, company, setOriginalCompany, setTouchedFinancials]);

    if (isInitial || isCompanyCreditsLoading || isSavedCompanyLoading || !originalCompany || isCurrenciesLoading || !currencies || !touchedInputs) {
        return <FullPageLoadingComponent />;
    }

    if (!editCompanyModel || !companyCredits) {
        return <p>Something went wrong :(</p>;
    }


    const defaultRequiredSummaryFields: (keyof CompanyUploadSummary)[] = [
        "fullname",
        "countryOfFoundation",
        "industry",
    ];

    const allSummaryFields: (keyof CompanyUploadSummary)[] = [
        "fullname",
        "shortname",
        "industry",
        "sector",
        "introduction",
        "formOfOrganization",
        "countryOfFoundation",
        "foundationYear",
        "numberOfEmployees",
        "entityRelationshipStatus",
        "identificationNumber"
    ];

    const requiredSummaryFields: (keyof CompanyUploadSummary)[] =
        allSummaryFields.filter((fieldName: string) => {
            const field = fieldName as keyof CompanyUploadSummary;

            let isRequired = defaultRequiredSummaryFields.includes(field)
            if (originalCompany?.summary[field]?.disabled) {
                return false;
            }
            if (originalCompany?.summary[field].value && originalCompany?.summary[field].value !== '') {
                isRequired = true;
            }
            return isRequired;
        });

    const addressFields: string[] =
        [
            "countryId",
            "zip",
            "city",
            "street",
            "houseNumber",
            "floor",
            "door",
        ]

    const defaultRequiredMainAddressFields: (keyof CompanyUploadModel["summary"]["mainAddress"])[] = [
        "countryId",
        "city",
    ];

    const originalSummaryMainAddressFields = addressFields.filter((fieldName: string) => {
        const field = fieldName as keyof CompanyUploadModel["summary"]["mainAddress"];

        let isRequired = defaultRequiredMainAddressFields.includes(field)
        if (originalCompany?.summary.mainAddress[field]?.disabled) {
            return false;
        }
        if (originalCompany?.summary.mainAddress[field].value && originalCompany?.summary.mainAddress[field].value !== '') {
            isRequired = true;
        }
        return isRequired;
    });

    const originalSummaryBusinessAddressFields = addressFields.filter((fieldName: string) => {
        const field = fieldName as keyof CompanyUploadModel["summary"]["businessAddress"];

        let isRequired = false;
        if (originalCompany?.summary.businessAddress[field]?.disabled) {
            return false;
        }
        if (originalCompany?.summary.businessAddress[field].value && originalCompany?.summary.businessAddress[field].value !== '') {
            isRequired = true;
        }
        return isRequired;
    });

    const defaultRequiredOtherContactFields: (keyof CompanyUploadModel["summary"]["otherContactDetails"])[] = [
    ];

    const allOtherContactFields: (keyof CompanyUploadModel["summary"]["otherContactDetails"])[] =
        [
            "contactEmailAddress",
            "website",
            "mainTelephoneNumber",
        ];

    const requiredOtherContactsFields: (keyof CompanyUploadModel["summary"]["otherContactDetails"])[] =
        allOtherContactFields.filter((fieldName: string) => {
            const field = fieldName as keyof CompanyUploadModel["summary"]["otherContactDetails"];

            let isRequired = defaultRequiredOtherContactFields.includes(field)
            if (originalCompany?.summary.otherContactDetails[field]?.disabled) {
                return false;
            }
            if (originalCompany?.summary.otherContactDetails[field].value && originalCompany?.summary.otherContactDetails[field].value !== '') {
                isRequired = true;
            }
            return isRequired;
        });

    function checkIsSummaryFormValid(editModel: CompanyUploadModel) {
        if (!editModel) {
            return false;
        }

        const isSummaryValid = requiredSummaryFields.reduce(
            (isValidSoFar, fieldName) => {
                if (!isValidSoFar) {
                    return false;
                }
                if (requiredSummaryFields.includes(fieldName) && (editModel.summary[fieldName].value === '' || editModel.summary[fieldName].value === undefined)) {
                    return false;
                }
                return true;
            },
            true
        );

        return isSummaryValid;
    }

    function checkIsMainAddressFormValid(editModel: CompanyUploadModel) {
        if (!editModel) {
            return false;
        }

        const requiredFields = originalSummaryMainAddressFields as (keyof CompanyUploadModel["summary"]["mainAddress"])[];
        const isValid = requiredFields.reduce(
            (isValidSoFar, fieldName) => {
                if (!isValidSoFar) {
                    return false;
                }
                if (editModel.summary.mainAddress[fieldName].disabled) {
                    return true;
                }
                if (originalCompany?.summary.mainAddress[fieldName]?.value !== '' && editModel.summary.mainAddress[fieldName].value === '') {
                    return false;
                }
                return !!editModel.summary.mainAddress[fieldName].value;
            },
            true
        );

        return isValid;
    }

    function checkIsBusinessAddressFormValid(editModel: CompanyUploadModel) {
        if (!editModel) {
            return false;
        }
        const requiredFields = originalSummaryBusinessAddressFields as (keyof CompanyUploadModel["summary"]["businessAddress"])[];
        const isValid = requiredFields.reduce(
            (isValidSoFar, fieldName) => {
                if (!isValidSoFar) {
                    return false;
                }
                if (editModel.summary.businessAddress[fieldName].disabled) {
                    return true;
                }
                if (originalCompany?.summary.businessAddress[fieldName]?.value !== '' && editModel.summary.businessAddress[fieldName].value === '') {
                    return false;
                }
                return !!editModel.summary.businessAddress[fieldName].value;
            },
            true
        );

        return isValid;
    }

    function checkIsOtherContactDetailsFormValid(editModel: CompanyUploadModel) {
        if (!editModel) {
            return false;
        }

        const requiredFields = requiredOtherContactsFields as (keyof CompanyUploadModel["summary"]["otherContactDetails"])[];
        const isValid = requiredFields.reduce(
            (isValidSoFar, fieldName) => {
                if (!isValidSoFar) {
                    return false;
                }
                if (editModel.summary.otherContactDetails[fieldName].disabled) {
                    return true;
                }
                return !!editModel.summary.otherContactDetails[fieldName].value;
            },
            true
        );

        return isValid;
    }

    function checkFinancialsFormValid(editModel: CompanyUploadModel) {
        if (!editModel) {
            return false;
        }

        const allowNegativeKeys = [
            "netIncomeBeforeTaxes",
            "netIncomeAfterTaxes",
            'retainedEarningsForTheYear',
            "additionalPaidInCapital",
            "accumulatedRetainedEarnings",
            "otherOwnersEquity",
            "ownersEquity"
        ];


        const anyValueNegative = (obj: any) => {
            return (
                Object.keys(obj).filter(
                    (key) => obj[key] && obj[key].value && ((!allowNegativeKeys.includes(key) && obj[key].value < 0) || false)
                ).length !== 0
            );
        };

        const isFinancialsValid =
            editModel.financials.filter(
                (yearlyDetail) =>
                    anyValueNegative(yearlyDetail.incomeStatement) ||
                    anyValueNegative(yearlyDetail.balanceSheet)
            ).length === 0;

        return isFinancialsValid;
    }

    const checkManagementContactsValid = (editModel: CompanyUploadModel) => {
        if (!editModel) {
            return false;
        }

        const isValid =
            editModel.managementContacts.filter(
                (mc) => {
                    const phoneNumberDisabled = mc.individual.telephoneNumber.disabled === true;
                    const emailDisabled = mc.individual.emailAddress.disabled === true;
                    if(phoneNumberDisabled && emailDisabled)
                        return false;

                    const phoneNumberEmpty = mc.individual.telephoneNumber.value === '';
                    const phoneNumberValid = mc.individual.telephoneNumber.isValid === true;

                    const emailTouched = mc.individual.emailAddress.value !== undefined;
                    const phoneNumberTouched = mc.individual.telephoneNumber.value !== undefined;

                    const emailEmpty = mc.individual.emailAddress.value === '';
                    const emailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mc.individual.emailAddress.value!)

                    if (!mc.individual.name.disabled && (mc.individual.name?.value || '') === '') {
                        return true;
                    }

                    if (!mc.individual.position.disabled && (mc.individual.position?.value || '') === '') {
                        return true;
                    }

                    if (!phoneNumberDisabled && (emailEmpty || !emailValid)) {
                        mc.individual.emailAddress.required = true;
                        return true;
                    }

                    if (!emailDisabled && phoneNumberTouched && !phoneNumberValid) {
                        mc.individual.telephoneNumber.required = true;
                        return true;
                    }

                    if (!emailDisabled && emailTouched && !emailValid && !emailEmpty) {
                        return true;
                    }

                    if (!phoneNumberDisabled && !phoneNumberEmpty && !phoneNumberValid) {
                        return true;
                    }

                    if ((emailEmpty || !emailValid) && (phoneNumberEmpty || !phoneNumberValid)) {
                        return true;
                    }

                    return false;

                    // if (!phoneNumberFilled && !emailFilled && mc.individual.telephoneNumber.disabled !== true) {
                    //     mc.individual.telephoneNumber.required = true;
                    //     mc.individual.emailAddress.required = true;
                    // } else if (phoneNumberFilled) {
                    //     mc.individual.emailAddress.required = false;
                    // } else if (emailFilled) {
                    //     mc.individual.telephoneNumber.required = false;
                    // }

                    // const result = 
                    //     ((mc.individual.name?.value || '') === '' && mc.individual.name.disabled !== true)
                    //     || ((mc.individual.position?.value || '') === '' && mc.individual.position.disabled !== true)
                    //     || !(emailFilled || phoneNumberFilled);

                    // return result;
                }
            ).length === 0;
        return isValid;
    }

    const isArrayValid = (property: PropertyWithPrice<string[]> | undefined, newValue: string[] | undefined) => {
        return (property?.required && newValue && newValue.length > 0) || (!property?.required) || (property?.disabled || false);
    }

    const isPropertyValid = (property: PropertyWithPrice<string> | undefined, newValue: string | undefined, isRequired: boolean = false) => {
        const nonOfThemRequired = (!property?.required && !isRequired);
        const isDisabled = property?.disabled || false;
        const oneRequired = property?.required || isRequired;
        const newValueFilled = newValue && newValue !== '';

        return (oneRequired && newValueFilled) || nonOfThemRequired || isDisabled;
    }

    const trySaveCompany = (creditReward: number) => {
        if (!originalCompany || !company) {
            return;
        }

        const isSummaryValid =
            checkIsSummaryFormValid(editCompanyModel) &&
            checkFinancialsFormValid(editCompanyModel) &&
            checkIsMainAddressFormValid(editCompanyModel) &&
            checkIsOtherContactDetailsFormValid(editCompanyModel) &&
            checkIsBusinessAddressFormValid(editCompanyModel);

        const isProductsServicesValid =
            isArrayValid(originalCompany.productsAndServices.services, editCompanyModel.productsAndServices.services?.value) &&
            isArrayValid(originalCompany.productsAndServices.products, editCompanyModel.productsAndServices.products?.value) &&
            isArrayValid(originalCompany.productsAndServices.brands, editCompanyModel.productsAndServices.brands?.value);


        // const isManagementContacsValid =
        //     editCompanyModel.managementContacts.every(mc => {
        //         const original = originalCompany.managementContacts.find(og => og.id === mc.id);
        //         return isPropertyValid(original?.individual.name, mc.individual.name.value, true) &&
        //             isPropertyValid(original?.individual.position, mc.individual.position.value, true) &&
        //             isPropertyValid(original?.individual.location, mc.individual.location.value) &&
        //             isArrayValid(original?.individual.functions, mc.individual.functions.value) &&
        //             ((mc.individual.telephoneNumber?.isValid == null || mc.individual.telephoneNumber.isValid) && isPropertyValid(original?.individual.telephoneNumber, mc.individual.telephoneNumber.value)) &&
        //             isPropertyValid(original?.individual.emailAddress, mc.individual.emailAddress.value) &&
        //             isPropertyValid(original?.individual.linkedIn, mc.individual.linkedIn.value) &&
        //             isPropertyValid(original?.frontDeskOrDepartment.location, mc.frontDeskOrDepartment.location.value) &&
        //             isPropertyValid(original?.frontDeskOrDepartment.telephoneNumber, mc.frontDeskOrDepartment.telephoneNumber.value) &&
        //             isPropertyValid(original?.frontDeskOrDepartment.emailAddress, mc.frontDeskOrDepartment.emailAddress.value) &&
        //             isPropertyValid(original?.frontDeskOrDepartment.department, mc.frontDeskOrDepartment.department.value) &&
        //             isPropertyValid(original?.frontDeskOrDepartment.departmentOther, mc.frontDeskOrDepartment.departmentOther.value);
        //     });

        const isFinancialValid =
            editCompanyModel.financials.every(f => {
                const original = originalCompany.financials.find(of => of.year === f.year);
                const isIncomeStatementValid =
                    isPropertyValid(original?.incomeStatement.netSales, f.incomeStatement.netSales.value) &&
                    isPropertyValid(original?.incomeStatement.extraordinaryIncome, f.incomeStatement.extraordinaryIncome.value) &&
                    isPropertyValid(original?.incomeStatement.financialIncome, f.incomeStatement.financialIncome.value) &&
                    isPropertyValid(original?.incomeStatement.otherIncome, f.incomeStatement.otherIncome.value) &&
                    isPropertyValid(original?.incomeStatement.materials, f.incomeStatement.materials.value) &&
                    isPropertyValid(original?.incomeStatement.personnel, f.incomeStatement.personnel.value) &&
                    isPropertyValid(original?.incomeStatement.utilities, f.incomeStatement.utilities.value) &&
                    isPropertyValid(original?.incomeStatement.overhead, f.incomeStatement.overhead.value) &&
                    isPropertyValid(original?.incomeStatement.financialExpenses, f.incomeStatement.financialExpenses.value) &&
                    isPropertyValid(original?.incomeStatement.sellingAndAdministrative, f.incomeStatement.sellingAndAdministrative.value) &&
                    isPropertyValid(original?.incomeStatement.depreciationAndAmortization, f.incomeStatement.depreciationAndAmortization.value) &&
                    isPropertyValid(original?.incomeStatement.otherExpenses, f.incomeStatement.otherExpenses.value) &&
                    isPropertyValid(original?.incomeStatement.totalNetRevenues, f.incomeStatement.totalNetRevenues.value) &&
                    isPropertyValid(original?.incomeStatement.totalExpenses, f.incomeStatement.totalExpenses.value) &&
                    isPropertyValid(original?.incomeStatement.netIncomeBeforeTaxes, f.incomeStatement.netIncomeBeforeTaxes.value) &&
                    isPropertyValid(original?.incomeStatement.corporateIncomeTaxes, f.incomeStatement.corporateIncomeTaxes.value) &&
                    isPropertyValid(original?.incomeStatement.netIncomeAfterTaxes, f.incomeStatement.netIncomeAfterTaxes.value) &&
                    isPropertyValid(original?.incomeStatement.dividensPaid, f.incomeStatement.dividensPaid.value) &&
                    isPropertyValid(original?.incomeStatement.retainedEarningsForTheYear, f.incomeStatement.retainedEarningsForTheYear.value);

                const isBalanceSheetValid =
                    isPropertyValid(original?.balanceSheet.cashAndEquivalents, f.balanceSheet.cashAndEquivalents.value) &&
                    isPropertyValid(original?.balanceSheet.accountsReceivable, f.balanceSheet.accountsReceivable.value) &&
                    isPropertyValid(original?.balanceSheet.inventories, f.balanceSheet.inventories.value) &&
                    isPropertyValid(original?.balanceSheet.otherCurrentAssets, f.balanceSheet.otherCurrentAssets.value) &&
                    isPropertyValid(original?.balanceSheet.propertyPlantAndEquipment, f.balanceSheet.propertyPlantAndEquipment.value) &&
                    isPropertyValid(original?.balanceSheet.proprietaryRights, f.balanceSheet.proprietaryRights.value) &&
                    isPropertyValid(original?.balanceSheet.accumulatedDepreciation, f.balanceSheet.accumulatedDepreciation.value) &&
                    isPropertyValid(original?.balanceSheet.otherFixedAssets, f.balanceSheet.otherFixedAssets.value) &&
                    isPropertyValid(original?.balanceSheet.accountsPayable, f.balanceSheet.accountsPayable.value) &&
                    isPropertyValid(original?.balanceSheet.shortTermLoans, f.balanceSheet.shortTermLoans.value) &&
                    isPropertyValid(original?.balanceSheet.taxesPayable, f.balanceSheet.taxesPayable.value) &&
                    isPropertyValid(original?.balanceSheet.otherCurrentLiabilities, f.balanceSheet.otherCurrentLiabilities.value) &&
                    isPropertyValid(original?.balanceSheet.longTermLoanes, f.balanceSheet.longTermLoanes.value) &&
                    isPropertyValid(original?.balanceSheet.shareHoldersLoans, f.balanceSheet.shareHoldersLoans.value) &&
                    isPropertyValid(original?.balanceSheet.deferredIncomeTaxes, f.balanceSheet.deferredIncomeTaxes.value) &&
                    isPropertyValid(original?.balanceSheet.otherLongTermLiabilites, f.balanceSheet.otherLongTermLiabilites.value) &&
                    isPropertyValid(original?.balanceSheet.registeredShares, f.balanceSheet.registeredShares.value) &&
                    isPropertyValid(original?.balanceSheet.additionalPaidInCapital, f.balanceSheet.additionalPaidInCapital.value) &&
                    isPropertyValid(original?.balanceSheet.accumulatedRetainedEarnings, f.balanceSheet.accumulatedRetainedEarnings.value) &&
                    isPropertyValid(original?.balanceSheet.otherOwnersEquity, f.balanceSheet.otherOwnersEquity.value) &&
                    isPropertyValid(original?.balanceSheet.totalAssets, f.balanceSheet.totalAssets.value) &&
                    isPropertyValid(original?.balanceSheet.totalLiabilites, f.balanceSheet.totalLiabilites.value) &&
                    isPropertyValid(original?.balanceSheet.ownersEquity, f.balanceSheet.ownersEquity.value) &&
                    isPropertyValid(original?.balanceSheet.liabilitiesAndOwnersEquity, f.balanceSheet.liabilitiesAndOwnersEquity.value);

                return isBalanceSheetValid && isIncomeStatementValid;
            });

        const isValid =
            isSummaryValid && isProductsServicesValid && checkManagementContactsValid(editCompanyModel) && isFinancialValid;

        setIsFormValid(isValid);

        if (isValid) {
            dispatch(
                popupSetModelAction({
                    title: "Confirmation",
                    body: (<>
                        <p>I hereby confirm that to the best of my knowledge, all information provided by me is true and valid. The purpose of my upload is providing valuable information, not to discredit any person or company.</p>
                        <p className="bold">Please note</p>
                        <p className="italic">In the event you are uploading information on a natural person other than yourself, he or she will be notified prior to publishing any personal data. Your identity will not be disclosed.</p>
                    </>),
                    type: PopupDialogType.Dialog,
                    okButtonText: "Upload",
                    noButtonText: "Cancel",
                    okButtonAction: async (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        popupService.hidePopup();
                        updateCompany(company, editCompanyModel, creditReward, useGravityAs);
                    },
                    cancelButtonAction: (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        popupService.hidePopup();
                    },
                })
            );
            popupService.showPopup();
        }
    };

    let selectedSectors: SectorModel[] = [];
    const industry = company?.summary.industry;
    if (industry && industry.value !== '') {
        const selectedIndustry = company?.summary.industry.value;
        selectedSectors = companyEditService.selectSectorsByIndustry(Number(selectedIndustry));
    }

    const updateCurrency = (currency: string, multiplier: number) => {
        if (multiplier !== editCompanyModel.financialMultiplier) {
            setEditCompanyModel({
                ...editCompanyModel,
                financialMultiplier: multiplier,
            } as any);

            // setTouchedFinancials(editCompanyModel?.financials.map((financial, index) => ({
            //     incomeStatement: {
            //         ...touchedInputs.financials[index].incomeStatement,
            //     },
            //     balanceSheet: {
            //         ...touchedInputs.financials[index].balanceSheet,}
            // })));
            // editCompanyModel.financials.forEach(financial => {
            //     if (financial.incomeStatement.financialExpenses?.value !== undefined) {
            //         setTouchedFinancials({

            //         })
            //     }
            // });
        }
    };

    return <CompanyUploadPage
        originRoute={`/company/${companyId}`}
        company={editCompanyModel}
        originalCompany={originalCompany}
        isEditMode={true}
        setCompany={setEditCompanyModel}
        updateCurrency={updateCurrency}
        currencies={currencies}
        touchedInputs={touchedInputs}
        setTouchedFinancials={setTouchedFinancials}
        requiredFields={requiredSummaryFields}
        requiredMainAddressFields={originalSummaryMainAddressFields}
        requiredBusinessAddressFields={originalSummaryBusinessAddressFields}
        requiredOtherContactFields={requiredOtherContactsFields}
        isFormValid={isFormValid}
        checkIsSummaryFormValid={checkIsSummaryFormValid}
        checkIsMainAddressFormValid={checkIsMainAddressFormValid}
        checkIsBusinessAddressFormValid={checkIsBusinessAddressFormValid}
        checkIsOtherContactDetailsFormValid={checkIsOtherContactDetailsFormValid}
        checkIsManagementContactsFormValid={checkManagementContactsValid}
        sectors={selectedSectors}
        companyCredits={companyCredits}
        trySaveCompany={trySaveCompany}
        rewardSummaryCalculationMethod={(companyUploadModel) => creditsService.getDiffUploadValue(companyUploadModel, originalCompany)}
    />;
};
