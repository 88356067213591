import React, { useState } from "react";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import ReCAPTCHA from 'react-google-recaptcha';

export interface ForgotPasswordComponentStateProps {
  error?: any;
  isLoading: boolean;
}

export interface ForgotPasswordComponentDispatchProps {
  forgotPassword(email: string, captchaToken: string): Promise<void>;
}

const ForgotPassswordComponent: React.FC<ForgotPasswordComponentStateProps & ForgotPasswordComponentDispatchProps & RouteComponentProps> = ({
  isLoading,
  history,
  error,
  forgotPassword
}) => {
  const [email, setEmail] = useState<string>('');
  const [isTouched, setIsTouched] = useState(false);
  const [localError, setLocalError] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');

  const emailHasError = () => {
    return !email || !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
  };

  const emailErrorProps = () => {
    return emailHasError() && isTouched ? { error: "error" } : {};
  };

  const onSubmit = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!email || emailHasError()) {
      setIsTouched(true);
      setLocalError('E-mail address is invalid');
      return;
    }

    if (captchaToken === '') {
      setIsTouched(true);
      setLocalError('Please fill out the captcha');
      return;
    }

    setLocalError('');

    forgotPassword(email, captchaToken);
  };

  const onToken = (token: string | null) => {
    if (token != null) {
      setCaptchaToken(token);
    }
  };

  if (isLoading) {
    return (
      <div className="main">
        <div className="container">
          <FullPageLoadingComponent /></div>
      </div>
    );
  }

  return (
    <div className="section section--content">
      <div className="container">
        <div className="grid">
          <div className="gi-4 gi-s-5 gi-e-9 t-gi-4 t-gi-s-3 t-gi-e-7 m-gi-4 m-gi-s-1 m-gi-e-4">
            <div className="stack stack-m">
              <div className="user-form">
                <form className="stack stack-xl" onSubmit={(evt) => { onSubmit(evt); }}>
                  <div>
                    <h1 className="stack stack-xs ai-c">
                      <span className="logo logo--full logo--grey logo--color">
                        <svg className="logo__logo">
                          <use xlinkHref="#logo-full-grey-color" />
                        </svg>
                        <span className="visually-hidden">Gravity</span>
                      </span>
                      <span className="title title-m">Forgot my Password</span>
                    </h1>
                  </div>
                  <fieldset>
                    <div className="stack stack-xs">
                      <div>
                        <p className="label">Email Address</p>
                        <div className="field field--input" {...emailErrorProps()}>
                          <input className="field__field" name="email" id="email"
                            placeholder="example@example.com"
                            value={email}
                            onChange={(evt) => setEmail(evt.target.value)} />
                        </div>
                      </div>
                      {!error ? "" : <p {...{ style: { color: "red" } }}>{error}</p>}
                      {localError === "" ? "" : <p {...{ style: { color: "red" } }}>{localError}</p>}
                      <div>
                        <ReCAPTCHA
                          sitekey="6LftRKcZAAAAADUJmN5BRhYsgoUv-aHyCrctbBDu"
                          onChange={onToken}
                          hl={'en'}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <div className="buttons">
                    <div className="stack stack-m">
                      <div>
                        <div className="stack stack-xs">
                          <button className="button">
                            <span className="button__inner">
                              <span>Send</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="user-form">
                <div className="stack stack-m ai-c">
                  <h2 className="title title-m">New to Gravity?</h2>
                  <div>
                    <div className="stack stack-xs ai-c">
                      <p className="annotation">Receive 300 registration bonus credits for free</p>
                      <button className="button button--secondary" onClick={() => history.push('/register')}>
                        <span className="button__inner">
                          <span>Register for Free</span>
                        </span>
                      </button>
                      <button className="button button--neutral" onClick={() => history.push('/')}>
                        <span className="button__inner">
                          <span>Check out how it works</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export const ForgotPasswordPage = withRouter(ForgotPassswordComponent);