import React, { useEffect, useState } from 'react';
import { Cart, CartItem } from './cart.models';
import { RequestState } from '../models';
import { User } from '../auth/authentication.models';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import { WorkspaceModel } from '../workspace-settings/workspace.models';
import { RouteComponentProps } from 'react-router-dom';
import { Utils } from '../utils/utils';
import { AttributeType, SavedCompanyModel } from '../saved-company/saved-company.models';
import { savedCompanyService } from '../saved-company/savedCompany.service';

export interface CartProps {
    savedCompanyId: number;
}

export interface CartStateProps {
    cart?: Cart;
    user?: User;
    workspace?: WorkspaceModel;
    useGravityAs?: number;
    submissionState: RequestState;
    discount: number;
    savedCompany?: SavedCompanyModel;
}

export interface CartDispatchProps {
    removeItemFromCart: (item: CartItem) => void;
    submitCart: (cart: Cart, savedCompanyId: number, hasEnoughCredit: boolean, useGravityAs?: number, price?: number) => void;
    resetCart: () => void;
}

export const CartComponent: React.FC<CartProps & CartStateProps & CartDispatchProps & RouteComponentProps> = ({
    cart,
    user,
    workspace,
    savedCompanyId,
    removeItemFromCart,
    submitCart,
    useGravityAs,
    submissionState,
    resetCart,
    discount,
    savedCompany
}) => {
    useEffect(() => {
        return () => {
            resetCart();
        };
    }, [resetCart]);

    const [currentCredit] = useState(useGravityAs ? workspace!.creditCount : user!.creditCount);

    if (!cart || !savedCompany) {
        return null;
    }

    if (!user) {
        return null;
    }

    const isAllSummarySelected = savedCompanyService.isAllSummarySelected(savedCompany.summary, cart.items);
    const isAllProductsAndServicesSelected = savedCompanyService.isAllProductsAndServicesSelected(savedCompany.productsAndServices, cart.items);
    const isAllManagementContactSelected = savedCompanyService.isAllManagementContactSelected(savedCompany.managementContacts, cart.items);
    const isAllFinancialsSelected = savedCompanyService.isAllFinancialsSelected(savedCompany.financials, cart.items);
    const isAllDocumentsSelected = savedCompanyService.isAllDocumentsSelected(savedCompany.attachments, cart.items);

    const creditPriceWithoutDiscount = cart.items.reduce((sum, item) => sum + item.price, 0);

    const calculateTabPrice = (tab: AttributeType, isAllSelected: boolean) => {
        const creditPrice = cart.items.filter(i => i.attributeType === tab).reduce((sum, item) => sum + item.price, 0);

        if (isAllSelected) {
            const discountPercentage = discount / 100;
            return Math.round(discountPercentage > 0 ? creditPrice * (1 - discountPercentage) : creditPrice);
        }

        return creditPrice;
    }

    const summaryPrice = calculateTabPrice(AttributeType.Summary, isAllSummarySelected);
    const productsAndServicesPrice = calculateTabPrice(AttributeType.ProductsAndServices, isAllProductsAndServicesSelected);
    const managementContactsPrice = calculateTabPrice(AttributeType.ManagementContact, isAllManagementContactSelected);
    const financialsPrice = calculateTabPrice(AttributeType.Financial, isAllFinancialsSelected);
    const documentsPrice = calculateTabPrice(AttributeType.Attachment, isAllDocumentsSelected);

    const renderCartItem = (item: CartItem, index: number) => (
        <li key={index}>
            <div className="item item--crt">
                <div className="h-stack h-stack-xs ai-c">
                    <div className="col col--name">
                        <p className="fw-b">{item.label}</p>
                    </div>
                    <div className="col col--text cart-flex">
                        <svg className="icon icon--m">
                            <use xlinkHref="#icon-gravity-credit" />
                        </svg>
                        <span className="icon-label icon-label--label-left">
                            <span className="icon-label__inner">
                                <span className="label">{item.price}</span>
                            </span>
                        </span>
                    </div>
                    <div className="col col--icon">
                        <button className="icon-button" disabled={submissionState === RequestState.Loading} onClick={() => removeItemFromCart(item)}>
                            <span className="icon-button__inner">
                                <svg className="icon icon--m">
                                    <use xlinkHref="#icon-close" />
                                </svg>
                                <span className="visually-hidden">Remove</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </li>
    );

    const totalPrice = 
        summaryPrice +
        productsAndServicesPrice + 
        managementContactsPrice +
        financialsPrice +
        documentsPrice;

    const creditLabel = currentCredit < totalPrice ?
        !useGravityAs ?
            `You don't have enough credit, please add ${Utils.formatNumber(totalPrice - currentCredit)} credits to your account balance.` :
            "You don't have enough credit, contact your workspace administrator to add credits to the workspace balance." : "";

    const renderCartItems = () => (
        <>
            <div className="cart__content">
                <ul className="list">
                    {cart.items.map(renderCartItem)}
                </ul>
            </div>
            <div className="cart__content">
                <div className="stack stack-xs ai-fe">
                    <p className="title title-m cart-flex">
                        Total of
                        <svg className="icon icon--m">
                            <use xlinkHref="#icon-gravity-credit" />
                        </svg>
                        {creditPriceWithoutDiscount}
                    </p>
                    <p className="annotation">of your {Utils.formatNumber(currentCredit)} credits</p>
                    <button className="button"
                        disabled={submissionState === RequestState.Loading}
                        onClick={() => submitCart(cart, savedCompanyId, currentCredit >= totalPrice, useGravityAs, totalPrice)}>
                        <span className="button__inner">
                            <span className="btn-res">Reveal company data for {totalPrice} {totalPrice > 1 ? "credits" : "credit"}</span>
                        </span>
                    </button>
                    {
                        isAllSummarySelected && discount > 0 ?
                            <p className="annotation"><span style={{ fontWeight: 'bold' }}>Discount for Summary: {discount}%</span></p> : null
                    }
                    {
                        isAllProductsAndServicesSelected && discount > 0 ?
                            <p className="annotation"><span style={{ fontWeight: 'bold' }}>Discount for Products & Services: {discount}%</span></p> : null
                    }
                    {
                        isAllManagementContactSelected && discount > 0 ?
                            <p className="annotation"><span style={{ fontWeight: 'bold' }}>Discount for Management Contacts: {discount}%</span></p> : null
                    }
                    {
                        isAllFinancialsSelected && discount > 0 ?
                            <p className="annotation"><span style={{ fontWeight: 'bold' }}>Discount for Financials: {discount}%</span></p> : null
                    }
                    {
                        isAllDocumentsSelected && discount > 0 ?
                            <p className="annotation"><span style={{ fontWeight: 'bold' }}>Discount for Supporting Documents: {discount}%</span></p> : null
                    }
                    <p className="annotation">
                        {creditLabel}
                    </p>
                </div>
            </div>
        </>
    );

    const renderEmptyCart = () => (
        <>
            <div className="cart__content">
                <div className="stack stack-xs">
                    <p className="fw-sb">Your Cart is Empty.</p>
                    <p>Select Fields to be Revealed</p>
                </div>
            </div>
            <div className="cart__content">
                <div className="h-stack jc-fe">
                    <span className="image image--cart" role="presentation">
                        <span className="image__inner">
                            <img src="/assets/img/bg-cartv3.png" alt="" />
                        </span>
                    </span>
                </div>
            </div>
        </>
    );

    if (submissionState === RequestState.Loading) {
        return <FullPageLoadingComponent />;
    }

    if (submissionState === RequestState.Succeeded) {
        window.location.reload();
    }

    return (
        <div className={`cart ${cart.items.length === 0 ? 'cart--empty' : 'cart--items'}`}>
            <div className="stack stack-m">
                <header className="cart-header">
                    <div className="stack stack-xs">
                        <span className="icon-header icon-header--header-right">
                            <span className="icon-header__inner">
                                <svg className="icon icon--l">
                                    <use xlinkHref="#icon-cart" />
                                </svg>
                                <span className="title title-m">Your Cart</span>
                            </span>
                        </span>
                    </div>
                </header>
                {
                    submissionState !== RequestState.Succeeded && cart.items.length > 0 ? renderCartItems() : renderEmptyCart()
                }
            </div>
        </div>
    );
};