import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { LinkedInConfirmDispatchProps, LinkedInConfirmPage } from './LinkedInConfirm.component';
import { AppState } from '../../root.reducer';
import { SocialConfirmFields } from './social-register.models';
import { authenticationService } from '../authentication.service';
import { RouteComponentProps } from 'react-router-dom';
import { LoginResponse } from '../authentication.models';
import { AppRoutes } from '../../routes';

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): LinkedInConfirmDispatchProps => ({
  register: async (model: SocialConfirmFields) => {
    const response = await authenticationService.confirmLinkedin(model);

    const loginResponse = response.data as LoginResponse;

    if (response.status === 200) {
      if (loginResponse.hasWorkspaceInvites) {
        window.location.href = `${AppRoutes.WorkspaceResponse}`;
      } else {
        window.location.href = '/';
      }
    }
  }
});

export default connect<{}, LinkedInConfirmDispatchProps, RouteComponentProps, AppState>(
  null, mapDispatchToProps
)(LinkedInConfirmPage);
