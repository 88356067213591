import { WorkspaceActions, WorkspaceActionTypes } from './workspace.actions';
import { WorkspaceModel, InvitedWorkspaceModel, WorkspaceDeleteConfirmationModel } from './workspace.models';

export interface AuthState {
  isLoading: boolean;
  workspace?: WorkspaceModel;
  ownershipWorkspace?: WorkspaceModel;
  workspaceInvitations?: InvitedWorkspaceModel[];
  error?: any;
  workspacesConfirmation?: WorkspaceDeleteConfirmationModel[];
}

const initialState: AuthState = {
  isLoading: true,
  workspace: undefined,
  ownershipWorkspace: undefined,
  workspaceInvitations: undefined,
  workspacesConfirmation: undefined
};

export const workspaceReducer = (state: AuthState = initialState, action: WorkspaceActions): AuthState => {
  switch (action.type) {
    case WorkspaceActionTypes.WORKSPACE_LOADING:
      return {
        ...state,
        isLoading: true,
        workspace: undefined,
      };
    case WorkspaceActionTypes.WORKSPACE_LOADED_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        workspace: action.payload
      };
      case WorkspaceActionTypes.WORKSPACE_INVITATIONS_LOADED_SUCCESSFUL:
        return {
          ...state,
          isLoading: false,
          workspaceInvitations: action.payload
        };
    case WorkspaceActionTypes.WORKSPACE_LOADING_STOP:
      return {
        ...state,
        isLoading: false,
      };
    case WorkspaceActionTypes.WORKSPACE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case WorkspaceActionTypes.WORKSPACE_INITIALIZED_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        workspace: {
          id: 0,
          companyName: '',
          workspaceName: '',
          department: '',
          isDefault: true,
          creditCount: 100,
          users: [],
          invitedUsers: [],
          isCurrentUserCreator: true,
        }
      };
    case WorkspaceActionTypes.WORKSPACE_DELETE_SUCCESSFUL:
    case WorkspaceActionTypes.WORKSPACE_SAVED_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        workspace: undefined,
      };
    case WorkspaceActionTypes.WORKSPACE_DELETE_CONFIRM: 
      return {
        ...state,
        isLoading: false,
        workspacesConfirmation: action.payload,
      }
    case WorkspaceActionTypes.WORKSPACE_OWNERSHIP_LOADED_SUCCESSFUL: 
      return {
        ...state,
        isLoading: false,
        ownershipWorkspace: action.payload,
      }
    default:
      return state;
  }
};