import { connect } from 'react-redux';
import { AppState } from '../../root.reducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { isSearchingSelector } from '../search.selectors';
import { AdvancedSearchPageComponent, AdvancedSearchPageComponentProps, AdvancedSearchPageDispatchProps } from './AdvancedSearchPage.component';
import { countriesSelector } from '../../meta/meta.selectors';
import { Dispatch } from 'redux';
import { searchService } from '../search.service';
import { searchSuccessAction, searchCompanyAction } from '../search.actions';
import { searchFieldsSelector } from '../search.selectors';
import { SimpleSearchFields } from '../search.models';

const mapStateToProps = (state: AppState): AdvancedSearchPageComponentProps => ({
  countries: countriesSelector(state),
  fields: searchFieldsSelector(state),
  isLoading: isSearchingSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): AdvancedSearchPageDispatchProps => ({
  searchCompanies: async (filters: SimpleSearchFields) => {
    dispatch(searchCompanyAction(filters));

    console.log(filters);
    const result = await  searchService.searchCompanies(filters);

    dispatch(searchSuccessAction(result));

    history.push('/search');
  }
});

export default withRouter(connect<AdvancedSearchPageComponentProps, AdvancedSearchPageDispatchProps, RouteComponentProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSearchPageComponent));