import React from "react";
import { SearchResultCountPayComponent } from "./SearchResultCountPay.component";
import { SearchCountResult } from '../search.models';
import { SavedCompanyListItemComponent } from "../../saved-companies/saved-companies/SavedCompanyListItem.component";
import EmptySearchResults from '../empty-search-page/EmptySearchResults.container';
import { User } from '../../auth/authentication.models';
import { FullPageLoadingComponent } from '../../FullpageLoading.component';
import { WorkspaceModel } from '../../workspace-settings/workspace.models';

export interface SearchResultsComponentStateProps {
    isLoading: boolean;
    countResult?: SearchCountResult;
    user: User | undefined;
    workspace: WorkspaceModel | undefined;
    useGravityAs?: number;
}

export interface SearchResultsComponentDispatchProps {
    onPayForSearchResults(useGravityAs?: number): void;
    loadAllCompaniesCount(): void;
}

export const SearchResultsComponent: React.FC<SearchResultsComponentStateProps & SearchResultsComponentDispatchProps> = ({
    isLoading,
    user,
    workspace,
    useGravityAs,
    countResult,
    onPayForSearchResults,
    loadAllCompaniesCount
}) => {
    if (isLoading || !user) {
        return <FullPageLoadingComponent/>;
    }

    const renderResults = () => {

        if (countResult) {
            return <>
                <ul className="list">
                    <SavedCompanyListItemComponent company={countResult.exampleCompany} />
                    <SavedCompanyListItemComponent company={countResult.exampleCompany} isBlurred={true} />
                    <SavedCompanyListItemComponent company={countResult.exampleCompany} isBlurred={true} />
                    <SavedCompanyListItemComponent company={countResult.exampleCompany} isBlurred={true} />
                    <SavedCompanyListItemComponent company={countResult.exampleCompany} isBlurred={true} />
                </ul>
                <SearchResultCountPayComponent useGravityAs={useGravityAs} user={user} workspace={workspace} countResult={countResult} onPay={onPayForSearchResults} />
            </>;
        }

        loadAllCompaniesCount();

        const result: SearchCountResult = {
            userCreditCount: 0,
            workspaceCreditCount: 0,
            resultCount: 0,
            exampleCompany: {
                // savedCompanyId: 0,
                totalNetRevenues: 'Annual revenues not specified',
                countryOfFoundation: 'USA',
                industry: 'Agriculture',
                fullName: 'Sample Company',
                // updatesAvailable: 0
            }
        };

        return <>
            <ul className="list">
                <SavedCompanyListItemComponent company={result.exampleCompany} />
                <SavedCompanyListItemComponent company={result.exampleCompany} isBlurred={true} />
                <SavedCompanyListItemComponent company={result.exampleCompany} isBlurred={true} />
                <SavedCompanyListItemComponent company={result.exampleCompany} isBlurred={true} />
                <SavedCompanyListItemComponent company={result.exampleCompany} isBlurred={true} />
            </ul>
            <EmptySearchResults />
        </>;
    };

    return (
        <div className="section section--results">
            <div className="container">
                <div className="stack stack-xl">
                    {renderResults()}
                </div>
            </div>
        </div>
    );
};
