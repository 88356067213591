import { CreditMap } from '../company-upload/company-upload.models';
import { AuthActions, AuthActionTypes } from "./authentication.actions";
import { User, USE_WORKSPACE_AS_TOKEN } from "./authentication.models";

export interface AuthState {
    isLoading: boolean;
    user?: User;
    error?: any;
    resetPasswordEmail?: any;
    useGravityAs?: number; // workspace id or undefined for user account
    isregisterCreditsLoading: boolean;
    registerCredits: CreditMap | undefined;
}

const initialState: AuthState = {
    isLoading: true,
    user: undefined,
    error: undefined,
    resetPasswordEmail: undefined,
    useGravityAs: undefined,
    isregisterCreditsLoading: false,
    registerCredits: undefined
};

export const authenticationReducer = (
    state: AuthState = initialState,
    action: AuthActions
): AuthState => {
    switch (action.type) {
        case AuthActionTypes.AUTHENTICATION_LOADING:
            return {
                ...state,
                isLoading: true,
                user: undefined,
            };
        case AuthActionTypes.AUTHENTICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload,
            };
        case AuthActionTypes.REGISTER_SUCCESS:
        case AuthActionTypes.REGISTER_ERROR:
            return {
                ...state,
                isLoading: false,
            };
        case AuthActionTypes.REGISTER_CREDITS_LOADING:
            return {
                ...state,
                isregisterCreditsLoading: true
            };
        case AuthActionTypes.REGISTER_CREDITS_SUCCSESS:
            return {
                ...state,
                isregisterCreditsLoading: false,
                registerCredits: action.payload,
            };
        case AuthActionTypes.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case AuthActionTypes.WORKSPACE_CREATED:
            const user = state.user as User;
            return {
                ...state,
                isLoading: false,
                user: user
            };
        case AuthActionTypes.AUTHENTICATION_ERROR:
            return {
                ...state,
                isLoading: false,
                user: undefined,
                error: action.payload,
            };
        case AuthActionTypes.RESETPASSWORD_LOADED:
            return {
                ...state,
                isLoading: false,
                resetPasswordEmail: action.payload,
            };
        case AuthActionTypes.RESETPASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case AuthActionTypes.USE_GRAVITY_AS:
            localStorage.setItem(USE_WORKSPACE_AS_TOKEN, action.payload === undefined ? '' : action.payload.toString());

            return {
                ...state,
                useGravityAs: action.payload
            };
        case AuthActionTypes.LOGOUT:
            localStorage.removeItem(USE_WORKSPACE_AS_TOKEN);
            return {
                ...state,
                user: undefined,
                useGravityAs: undefined
            };
        default:
            return state;
    }
};
