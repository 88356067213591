import { SavedCompanyModel } from './saved-company.models';
import { actionCreator } from '../actions';

export enum CompanyActionTypes {
    SAVED_COMPANY_LOADING = 'saved-company/loading',
    SAVED_COMPANY_FROM_SEARCH = 'saved-company/fromsearch',
    SAVED_COMPANY_SUCCESS = 'saved-company/success',
    SAVED_COMPANY_UPLOAD_FAILED = 'saved-company/upload/failed',
    SAVED_COMPANY_UPLOAD_SUCCESS = 'saved-company/upload/success',
    RESET_COMPANY_PROFILE = 'saved-company/reset',
    DISCOUNT_LOADED = 'saved-company/discount/loaded'
}

export const savedCompanyLoadingAction = actionCreator<CompanyActionTypes.SAVED_COMPANY_LOADING, void>(CompanyActionTypes.SAVED_COMPANY_LOADING);
export const savedCompanyUploadFailedAction = actionCreator<CompanyActionTypes.SAVED_COMPANY_UPLOAD_FAILED, void>(CompanyActionTypes.SAVED_COMPANY_UPLOAD_FAILED);
export const savedCompanyUploadSuccessAction = actionCreator<CompanyActionTypes.SAVED_COMPANY_UPLOAD_SUCCESS, void>(CompanyActionTypes.SAVED_COMPANY_UPLOAD_SUCCESS);
export const savedCompanyLoadedAction = actionCreator<CompanyActionTypes.SAVED_COMPANY_SUCCESS, SavedCompanyModel>(CompanyActionTypes.SAVED_COMPANY_SUCCESS);
export const resetSavedCompanyAction = actionCreator<CompanyActionTypes.RESET_COMPANY_PROFILE, void>(CompanyActionTypes.RESET_COMPANY_PROFILE);
export const fromSearchCompanyAction = actionCreator<CompanyActionTypes.SAVED_COMPANY_FROM_SEARCH, number>(CompanyActionTypes.SAVED_COMPANY_FROM_SEARCH);
export const discountLoadedAction = actionCreator<CompanyActionTypes.DISCOUNT_LOADED, number>(CompanyActionTypes.DISCOUNT_LOADED);

export type SavedCompanyActions =
    ReturnType<typeof fromSearchCompanyAction> |
    ReturnType<typeof savedCompanyLoadingAction> |
    ReturnType<typeof savedCompanyUploadFailedAction> |
    ReturnType<typeof savedCompanyUploadSuccessAction> |
    ReturnType<typeof savedCompanyLoadedAction> |
    ReturnType<typeof resetSavedCompanyAction> |
    ReturnType<typeof discountLoadedAction>;
