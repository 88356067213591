import React, { useState } from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SavedCompanyListModel, SavedCompanyModel, SavedCompanySummaryModel } from '../../saved-company/saved-company.models';
import { CompanySearchState } from '../../saved-companies/saved-companies.models';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, makeStyles } from "@material-ui/core";

export interface SavedSearchTableResultsComponentStateProps {
  savedSearchId: number;
  results: SavedCompanyListModel;
  pageIndex: number;
  setPage(page: number): void;
  pageSize: number;
  setPageSize(pageSize: number): void;
  resetCurrentPage(): void;
  loadSavedSearch: () => void;
}

interface Column {
  id: 'fullName' | 'countryOfFoundation' | 'industry' | 'sector' | 'incomeStatementTotalNetRevenues' | 'incomeStatementNetIncomeAfterTaxes' | 'numberOfEmployees' | 'otherContactDetailsWebsite' | 'managementContactNames';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'fullName', label: 'Full\u00a0Name', minWidth: 170 },
  {
    id: 'countryOfFoundation',
    label: 'Country\u00a0of\u00a0Foundation',
    minWidth: 170,
  },
  { id: 'industry', label: 'Industry', minWidth: 100 },
  { id: 'sector', label: 'Sector', minWidth: 100 },
  {
    id: 'incomeStatementTotalNetRevenues',
    label: 'Total\u00a0Net\u00a0Revenues',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'incomeStatementNetIncomeAfterTaxes',
    label: 'After\u00a0Taxes',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  { id: 'numberOfEmployees', label: 'Number\u00a0of\u00a0Employees', minWidth: 100 },
  { id: 'otherContactDetailsWebsite', label: 'Website', minWidth: 100 },
  { id: 'managementContactNames', label: 'Management\u00a0Executive', minWidth: 100 },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const SavedSearchTableResults: React.FC<SavedSearchTableResultsComponentStateProps & RouteComponentProps> = ({ savedSearchId, results, pageIndex, pageSize, setPage, setPageSize, loadSavedSearch, history }) => {
  const sort = (a: SavedCompanyModel, b: SavedCompanyModel) => {
    const textA = a.summary.fullName?.valueDescription!.toUpperCase();
    const textB = b.summary.fullName?.valueDescription!.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  };
  const sortByDesc = (a: SavedCompanyModel, b: SavedCompanyModel) => -1 * sort(a, b);

  const [order, setOrder] = useState('asc');
  const [reloadCounter, setReloadCounter] = useState(10);

  const disabledColorStyle = {
    "--color": "var(--text-alt-color)",
    "--background-color": "var(--disabled-color)"
  } as any;


  const goToUploadPage = () => {
    history.push('/upload-company');
  };

  const classes = useStyles();

  const handleChangePage = (event: unknown, newPageIndex: number) => {
    // Convert index to page
    setPage(newPageIndex + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    // Convert index to page
    setPage(1);
  };


  const renderResults = () => {
    return <div className="stack stack-xl">
      <div className="grid">
        <div className="gi-6 t-gi-8">
          <div className="h-stack h-stack-xs ai-c">
            <h2 className="title title-s">Results</h2>
            <span className="counter counter--normal" style={disabledColorStyle}>{results.count}</span>
            <span className="counter counter--normal" style={disabledColorStyle}>{results.name}</span>
          </div>
        </div>
      </div>
      <div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {results.list.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>

                      <TableCell key={"fullName"} align={"left"}>
                        {row.summary.fullName.valueDescription}
                      </TableCell>

                      <TableCell key={"countryOfFoundation"} align={"left"}>
                        {row.summary.countryOfFoundation.valueDescription}
                      </TableCell>

                      <TableCell key={"industry"} align={"left"}>
                        {row.summary.industry?.valueDescription}
                      </TableCell>

                      <TableCell key={"sector"} align={"left"}>
                        {row.summary.sector?.valueDescription}
                      </TableCell>

                      <TableCell key={"incomeStatementTotalNetRevenues"} align={"right"}>
                        {row.financials.map((f) => (
                          f.incomeStatementTotalNetRevenues ? f.incomeStatementTotalNetRevenues.valueDescription + ";" : ""
                        ))}
                      </TableCell>

                      <TableCell key={"incomeStatementNetIncomeAfterTaxes"} align={"right"}>
                        {row.financials.map((f) => (
                          f.incomeStatementNetIncomeAfterTaxes ? f.incomeStatementNetIncomeAfterTaxes?.valueDescription + ";" : ""
                        ))}
                      </TableCell>

                      <TableCell key={"numberOfEmployees"} align={"right"}>
                        {row.summary.numberOfEmployees?.valueDescription}
                      </TableCell>

                      <TableCell key={"otherContactDetailsWebsite"} align={"left"}>
                        {row.summary.otherContactDetailsWebsite?.valueDescription}
                      </TableCell>

                      <TableCell key={"managementContactIndividualName"} align={"left"}>
                        {row.managementContacts.map((m) => (
                          m.individual.name.valueDescription + ";"
                        ))}
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[ 10, 25, 100]}
            component="div"
            count={results.count}
            rowsPerPage={pageSize}
            page={pageIndex}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  }

  const reload = (evt: any) => {
    evt.preventDefault();
    loadSavedSearch();
  }

  const renderRunningState = () => {
    return <div style={{ 'textAlign': 'center' }}>
      Results will be available shortly. <br /> Your browser will reload automatically in {reloadCounter} seconds... <br /><br />
      <button className="button button--secondary" onClick={(evt) => reload(evt)}>
        <span className="button__inner">
          <span>Reload</span>
        </span>
      </button>
    </div>
  }

  if (results.state === CompanySearchState.Created) {
    setTimeout(() => {
      setReloadCounter(reloadCounter - 1);
      if (reloadCounter === 1) {
        loadSavedSearch();
        setReloadCounter(10);
      }
    }, 1000);
  }

  return (
    <div className="section section--results">
      <div className="container">
        {
          results.state === CompanySearchState.Completed ? renderResults() : renderRunningState()
        }
      </div>
    </div>
  );
};

export const SavedSearchTableResultsComponent = withRouter(SavedSearchTableResults);