import React, { useEffect, useState } from 'react'
import { PropertyWithPrice } from '../../company-upload/company-upload.models';
import { CreditBadgeComponent } from '../../company-upload/CreditBadge.component';
import { creditsService } from '../../company-upload/credits.service';
import './phoneNumber.scss';

export interface PhoneNumberComponentProps {
  value: string | undefined;
  originalValue?: any;
  label: string;
  hasCredit?: boolean;
  onBlur: (value: string, isValid: boolean, isAllTouched: boolean) => void;
  isTouched: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  isEditMode?: boolean;
  uploadValueKey: string;
  property?: PropertyWithPrice<string>;
}

export const PhoneNumberComponent: React.FC<PhoneNumberComponentProps> = ({
  value,
  originalValue,
  onBlur,
  label,
  isRequired,
  isTouched,
  disabled,
  uploadValueKey,
  isEditMode,
  hasCredit,
  property
}) => {
  const getPart = (input: string = '', index: number) => {
    const part = input.split('-')?.[index] ?? '';
    return part;
  }

  const [isCountryCodeTouched, setCountryCodeTouched] = useState(false);
  const [isAreaCodeTouched, setAreaCodeTouched] = useState(false);
  const [isPhoneNumberTouched, setPhoneNumberTouched] = useState(false);

  const _countryCode = getPart(value, 0);
  const [countryCode, setCountryCode] = useState(getPart(value, 0));
  const [areaCode, setAreaCode] = useState(getPart(value ?? '', 1));
  const [phoneNumber, setPhoneNumber] = useState(getPart(value ?? '', 2));

  const validate = (_countryCode: string, _areaCode: string, _phoneNumber: string) => {
    return (_areaCode !== '' && _phoneNumber !== '' && _countryCode !== '') ||
      (!isRequired && isAllTouched() && _areaCode === '' && _phoneNumber === '' && _countryCode === '') ||
      (!isAllTouched() && (_areaCode === '' || _phoneNumber === '' || _countryCode === '')) || disabled === true;
  }

  useEffect(() => {
    if (_countryCode !== countryCode) {
      setCountryCode(_countryCode);
      setCountryCodeTouched(true);
      const _value = `${_countryCode}-${areaCode}-${phoneNumber}`;
      const isValid = validate(_countryCode, areaCode, phoneNumber);
      const isAllTouched = isTouched || (true && isPhoneNumberTouched);
      onBlur(_value, isValid, isAllTouched);
    }
  }, [countryCode, _countryCode, areaCode, phoneNumber, isPhoneNumberTouched, isTouched, onBlur, validate])

  const _setValue = (_countryCode: string, _areaCode: string, _phoneNumber: string) => {
    const isEmpty = _countryCode === '' && _areaCode === '' && _phoneNumber === '';
    const _value = isEmpty ? '' : `${_countryCode}-${_areaCode}-${_phoneNumber}`;
    const isValid = (isTouched && validate(_countryCode, _areaCode, _phoneNumber)) || !isTouched;
    const isAllTouched = isTouched || (isCountryCodeTouched && isPhoneNumberTouched && isPhoneNumberTouched);
    onBlur(_value, isValid, isAllTouched);
  }

  const isAllTouched = () => {
    return isTouched || isEditMode || (isCountryCodeTouched && isAreaCodeTouched && isPhoneNumberTouched);
  }

  const validCountryCode = (input: string) => {
    const regex = /^(\d{1}-)?(\d{1,6})$/;
    return input.match(regex);
  }

  const validAreaCode = (input: string) => {
    const regex = /^(\d{1,4})$/;
    return input.match(regex);
  }

  const validPhoneNumber = (input: string) => {
    const regex1 = /^\d{1,4}$/;
    const regex2 = /^(\d{5,10})$/;
    return input.match(regex1) || input.match(regex2);
  }

  const errorProps = validate(countryCode, areaCode, phoneNumber) ? {} : { error: 'error' };
  const requiredStar = isRequired ? <span className="required-star" title="Required">*</span> : null;

  const _hasCredit = hasCredit ? hasCredit :
    isAllTouched() && areaCode !== '' && phoneNumber !== '' && countryCode !== '' && value !== originalValue;

  if (property) {
    property.isValid = validate(countryCode, areaCode, phoneNumber) !== false;
  }

  return (
    <div className="stack stack-xs">
      <div>
        <div className="label">{label}{requiredStar}</div>
        <div className="phone_number-container">
          <div className="field field--input country"  {...errorProps as any}>
            <input className="field__field"
              disabled={disabled}
              value={countryCode}
              onChange={(evt) => {
                const input = evt.target.value;
                const isValid = validCountryCode(input);
                if (isValid || input === '') {
                  setCountryCode(input);
                  setCountryCodeTouched(true);
                  _setValue(input, areaCode, phoneNumber);
                }
              }} />
          </div>
          <span>-</span>
          <div className="field field--input area-code"  {...errorProps as any}>
            <input className="field__field"
              value={areaCode}
              placeholder="Enter Area Code"
              disabled={disabled}
              onChange={(evt) => {
                const input = evt.target.value;
                const isValid = validAreaCode(input);
                if (isValid || input === '') {
                  setAreaCode(input);
                  setAreaCodeTouched(true);
                  _setValue(countryCode, input, phoneNumber);
                }
              }} />
          </div>
          <span>-</span>
          <div className="field field--input telephone-number"  {...errorProps as any}>
            <input className="field__field"
              value={phoneNumber}
              placeholder="Enter Telephone Number"
              disabled={disabled}
              onChange={(evt) => {
                const input = evt.target.value;
                const isValid = validPhoneNumber(input);
                if (isValid || input === '') {
                  setPhoneNumber(input);
                  setPhoneNumberTouched(true);
                  _setValue(countryCode, areaCode, input);
                }
              }} />
          </div>
        </div>
      </div>
      <div>
        <CreditBadgeComponent
          price={creditsService.getUploadValue(uploadValueKey as string)}
          hasValue={_hasCredit} />
      </div>
    </div>
  );
}

export default PhoneNumberComponent;

