import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { InvitedWorkspaceModel } from '../workspace.models';
import { FullPageLoadingComponent } from '../../FullpageLoading.component';

export interface WorkspaceResponseComponentStateProps {
  isLoading: boolean;
  workspaceInvitations?: InvitedWorkspaceModel[];
}

export interface WorkspaceResponseComponentDispatchProps {
  getInvitedWorkspaces: () => Promise<void>;
  respondWorkspaceInvite(workspaceId: number, response: boolean): Promise<void>;
}

const WorkspaceResponseComp: React.FC<WorkspaceResponseComponentStateProps & WorkspaceResponseComponentDispatchProps & RouteComponentProps> = ({
  isLoading,
  history,
  workspaceInvitations,
  getInvitedWorkspaces,
  respondWorkspaceInvite
}) => {

  useEffect(() => {
    getInvitedWorkspaces();
  }, [getInvitedWorkspaces]);

  if (isLoading || !workspaceInvitations) {
    return <FullPageLoadingComponent />;
  }

  const onWorkspaceInvite = async (evt: any, workspaceId: number, accept: boolean) => {
    evt.preventDefault();
    evt.stopPropagation();

    await respondWorkspaceInvite(workspaceId, accept);
  };

  const workspaceInvites = workspaceInvitations.length > 0 ?
    <ul className="list">
      {workspaceInvitations.map(invite => (
        <li key={invite.id}>
          <div className="item item--usr company-invite">
            <div className="h-stack h-stack-xs ai c">
              <div className="col col--name">
                <p className="annotation">Workspace name <br /> <span className="bold">{invite.workspaceName}</span></p>
              </div>
              <div className="col col--icon vertical">
                <button className="button" onClick={(evt) => onWorkspaceInvite(evt, invite.id, true)}>
                  <span className="button__inner">
                    <span>Accept</span>
                  </span>
                </button>
                <button className="button button--terminate" onClick={(evt) => onWorkspaceInvite(evt, invite.id, false)}>
                  <span className="button__inner">
                    <span>Discard</span>
                  </span>
                </button>
                <button className="button button--neutral" onClick={(evt) => history.push('/')}>
                  <span className="button__inner">
                    <span>Skip</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
    : "";


  return (
    <div className="section section--content">
      <div className="container">
        <div className="grid">
          <div className="gi-4 gi-s-5 gi-e-9 t-gi-4 t-gi-s-3 t-gi-e-7 m-gi-4 m-gi-s-1 m-gi-e-4">
            <div className="stack stack-m">
              <div className="user-form">
                <form className="stack stack-xl">
                  <h1 className="stack stack-xs">
                    <span className="title title-m">Company Workspace Invitation</span>
                  </h1>
                  <fieldset>
                    <div className="stack stack-xs">
                      <p>You have invitation to the following company workspace(s):</p>
                      <div>
                        {workspaceInvites}
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export const WorkspaceResponseComponent = withRouter(WorkspaceResponseComp);

