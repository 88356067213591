import { actionCreator } from '../actions'
import { CreditMap } from '../company-upload/company-upload.models'
import { User } from './authentication.models'

export enum AuthActionTypes {
    AUTHENTICATION_LOADING = 'auth/load',
    AUTHENTICATION_SUCCESS = 'auth/success',
    REGISTER_SUCCESS = 'register/success',
    RESETPASSWORD_LOADED = 'resetpassword/loaded',
    RESETPASSWORD_SUCCESS = 'resetpassword/success',
    AUTHENTICATION_ERROR = 'auth/error',
    FORGOT_PASSWORD_SUCCESS = 'forgotpassword/success',
    WORKSPACE_CREATED = 'auth/workspace/created',
    REGISTER_CREDITS_LOADING = 'register/credits/loading',
    REGISTER_CREDITS_SUCCSESS = 'register/credits/success',
    REGISTER_ERROR = 'register/error',
    LOGOUT = 'auth/logout',
    USE_GRAVITY_AS = 'auth/useGravityAs',
}

export const authenticationLoadingAction = actionCreator<AuthActionTypes.AUTHENTICATION_LOADING, void>(AuthActionTypes.AUTHENTICATION_LOADING)
export const forgotPasswordSuccessAction = actionCreator<AuthActionTypes.FORGOT_PASSWORD_SUCCESS, void>(AuthActionTypes.FORGOT_PASSWORD_SUCCESS)
export const authenticationSuccessAction = actionCreator<AuthActionTypes.AUTHENTICATION_SUCCESS, User>(AuthActionTypes.AUTHENTICATION_SUCCESS)

export const registerSuccessAction = actionCreator<AuthActionTypes.REGISTER_SUCCESS, void>(AuthActionTypes.REGISTER_SUCCESS)
export const registerErrorAction = actionCreator<AuthActionTypes.REGISTER_ERROR, void>(AuthActionTypes.REGISTER_ERROR)
export const registerCreditsLoadingAction = actionCreator<AuthActionTypes.REGISTER_CREDITS_LOADING, void>(AuthActionTypes.REGISTER_CREDITS_LOADING)
export const registerCreditsSuccessAction = actionCreator<AuthActionTypes.REGISTER_CREDITS_SUCCSESS, CreditMap>(AuthActionTypes.REGISTER_CREDITS_SUCCSESS)

export const resetPasswordSuccessAction = actionCreator<AuthActionTypes.RESETPASSWORD_SUCCESS, void>(AuthActionTypes.RESETPASSWORD_SUCCESS)
export const resetPasswordLoadedAction = actionCreator<AuthActionTypes.RESETPASSWORD_LOADED, string>(AuthActionTypes.RESETPASSWORD_LOADED)
export const authenticationErrorAction = actionCreator<AuthActionTypes.AUTHENTICATION_ERROR, any>(AuthActionTypes.AUTHENTICATION_ERROR)

export const workspaceCreatedAction = actionCreator<AuthActionTypes.WORKSPACE_CREATED, void>(AuthActionTypes.WORKSPACE_CREATED)
export const logoutAction = actionCreator<AuthActionTypes.LOGOUT, void>(AuthActionTypes.LOGOUT)
export const changeUseGravityAsAction = actionCreator<AuthActionTypes.USE_GRAVITY_AS, number | undefined>(AuthActionTypes.USE_GRAVITY_AS)

export type AuthActions =
    | ReturnType<typeof authenticationLoadingAction>
    | ReturnType<typeof authenticationSuccessAction>
    | ReturnType<typeof forgotPasswordSuccessAction>
    | ReturnType<typeof registerSuccessAction>
    | ReturnType<typeof registerErrorAction>
    | ReturnType<typeof registerCreditsLoadingAction>
    | ReturnType<typeof registerCreditsSuccessAction>
    | ReturnType<typeof authenticationErrorAction>
    | ReturnType<typeof workspaceCreatedAction>
    | ReturnType<typeof resetPasswordSuccessAction>
    | ReturnType<typeof resetPasswordLoadedAction>
    | ReturnType<typeof logoutAction>
    | ReturnType<typeof changeUseGravityAsAction>
