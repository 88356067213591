import { CreditHistoryActions, CreditHistoryActionTypes } from './credit-history.actions';
import { CreditHistoryModel } from './credit-history.models';

export interface CreditHistoryState {
  isLoading: boolean;
  creditHistory?: CreditHistoryModel;
}

const initialState: CreditHistoryState = {
  isLoading: true,
  creditHistory: undefined,
};

export const creditHistoryReducer = (state: CreditHistoryState = initialState, action: CreditHistoryActions): CreditHistoryState => {
  switch (action.type) {
    case CreditHistoryActionTypes.CREDIT_HISTORY_LOADING:
      return {
        ...state,
        isLoading: true,
        creditHistory: undefined,
      };
    case CreditHistoryActionTypes.CREDIT_HISTORY_LOADED_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        creditHistory: action.payload
      };
    default:
      return state;
  }
};