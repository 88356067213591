import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { AppState } from "../root.reducer";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { WorkspaceSettingsStateProps, WorkspaceSettingsDispatchProps, WorkspaceSettingsPage } from './WorkspaceSettingsPage.component';
import { isWorkspaceLoadingSelector, workspaceSelector, workspaceErrorSelector } from './workspace.selector';
import { workspaceService } from './workspace.service';
import { workspaceLoadedSuccessfulAction, workspaceSavedSuccessfulAction, workspaceLoadingAction, workspaceErrorAction } from './workspace.actions';
import { UpdateWorkspaceModel, InviteUserToWorkspaceModel } from './workspace.models';
import { userSelector } from '../auth/authentication.selectors';
import { BillingModel } from '../billing-information/billing.model';
import { billingDataSuppliedAction } from '../billing-information/billing.actions';
import { AppRoutes } from '../routes';
import { authenticationSuccessAction, changeUseGravityAsAction } from '../auth/authentication.actions';
import { authenticationService } from '../auth/authentication.service';

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<{ id: string }>): WorkspaceSettingsStateProps => ({
  workspaceId: match.params.id as any as number,
  isLoading: isWorkspaceLoadingSelector(state),
  workspace: workspaceSelector(state),
  user: userSelector(state),
  error: workspaceErrorSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: RouteComponentProps): WorkspaceSettingsDispatchProps => ({
  getWorkspace: async (workspaceId: number) => {
    try {
      dispatch(workspaceLoadingAction());
      const workspace = await workspaceService.getWorkspaceSettings(workspaceId);
      dispatch(workspaceLoadedSuccessfulAction(workspace));
    } catch (e) {
      const user = await authenticationService.getUser();
      dispatch(authenticationSuccessAction(user));
      history.push('/');
    }
  },

  updateWorkspace: async (workspace: UpdateWorkspaceModel) => {
    dispatch(workspaceLoadingAction());
    const status = await workspaceService.updateWorkspace(workspace);

    if (status === 204) {
      dispatch(workspaceSavedSuccessfulAction());

      const _workspace = await workspaceService.getWorkspaceSettings(workspace.id);

      dispatch(workspaceLoadedSuccessfulAction(_workspace));
    }
  },

  deleteWorkspace: async (workspaceId: number) => {
    dispatch(workspaceLoadingAction());
    const status = await workspaceService.deleteWorkspace(workspaceId);

    if (status === 204) {
      dispatch(changeUseGravityAsAction(undefined));

      const user = await authenticationService.getUser();
      dispatch(authenticationSuccessAction(user));
      history.push('/account');
    }
  },

  cancelMembership: async () => {
    dispatch(workspaceLoadingAction());
    const status = await workspaceService.cancelMembership();

    if (status === 204) {
      dispatch(changeUseGravityAsAction(undefined));

      const user = await authenticationService.getUser();
      dispatch(authenticationSuccessAction(user));
      history.push('/account');
    }
  },

  inviteUser: async (workspaceId: number, user: InviteUserToWorkspaceModel) => {
    dispatch(workspaceLoadingAction());

    try {
      const status = await workspaceService.inviteUser(workspaceId, user);
      if (status === 204) {
        dispatch(workspaceSavedSuccessfulAction());

        const _workspace = await workspaceService.getWorkspaceSettings(workspaceId);

        dispatch(workspaceLoadedSuccessfulAction(_workspace));
      }
    } catch {
      dispatch(workspaceErrorAction({
        text: "Error occured, try again"
      }));
    }
  },

  removeTeamMember: async (workspaceId: number, email: string) => {
    dispatch(workspaceLoadingAction());

    const status = await workspaceService.removeTeamMember(workspaceId, email);
    if (status === 204) {
      dispatch(workspaceSavedSuccessfulAction());

      const _workspace = await workspaceService.getWorkspaceSettings(workspaceId);

      dispatch(workspaceLoadedSuccessfulAction(_workspace));
    }
  },

  removeInvite: async (workspaceId: number, email: string) => {
    dispatch(workspaceLoadingAction());

    const status = await workspaceService.removeInvite(workspaceId, email);
    if (status === 204) {
      dispatch(workspaceSavedSuccessfulAction());

      const _workspace = await workspaceService.getWorkspaceSettings(workspaceId);

      dispatch(workspaceLoadedSuccessfulAction(_workspace));
    }
  },

  uploadCredit: async (workspaceId: number, amount: number, creditValue: number) => {
    const billing: BillingModel = {
      amount,
      creditValue
    };

    dispatch(billingDataSuppliedAction(billing));
    history.push(AppRoutes.BillingInformation);
  },

});

export default withRouter(
  connect<WorkspaceSettingsStateProps, WorkspaceSettingsDispatchProps, RouteComponentProps<{ id: string }>, AppState>(
    mapStateToProps, mapDispatchToProps
  )(WorkspaceSettingsPage)
);
