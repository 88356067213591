import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { User } from '../auth/authentication.models';
import { FullPageLoadingComponent } from '../FullpageLoading.component';
import { CountryModel } from '../meta/meta.models';
import { BillingIssuedType, BillingModel, IpDetails, ViesCheckVatResponse } from './billing.model';
import { BillingInformation } from "./BillingInformation.models";
import { SelectWithLabelComponent } from "./SelectWithlabel.component";
import { TextInputWithLabelComponent } from "./TextInputWithLabel.component";

export interface BillingInformationStateProps {
    useGravityAs?: number;
    user?: User;
    isLoading: boolean;
    isVatLoading: boolean;
    countries: CountryModel[] | undefined,
    billing?: BillingModel;
    ipDetails: IpDetails | undefined;
}

export interface BillingInformationDispatchProps {
    continueToCheckout(billing: BillingModel): Promise<void>;
    vatCheck(vatNumber: string): Promise<AxiosResponse<ViesCheckVatResponse>>;
    checkIp(): Promise<void>;
}

export const BillingInformationComponent: React.FC<BillingInformationStateProps & BillingInformationDispatchProps & RouteComponentProps> = (
    { user, isLoading, billing, countries, isVatLoading, useGravityAs, continueToCheckout, vatCheck, checkIp, ipDetails, history }
) => {
    useEffect(() => {
        checkIp();
    }, [checkIp]);

    const [issuedTo, setIssuedTo] = useState<"me" | "company" | undefined>(undefined);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [vatNumber, setVatNumber] = useState<string>("");

    const [vatValid, setVatValid] = useState<boolean | undefined>(true);
    const [vatValidationError, setVatValidationError] = useState<string>('');


    const [countryId, setCountryId] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [ZIP, setZIP] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<string>("");
    const [ipCountry, setIpCountry] = useState<string>("");

    const [vatRate, setVatRate] = useState(0);
    const [continued, setContinued] = useState(false);
    const [showIpCountryPopup, setShowIpCountryPopup] = useState(false);

    if (!billing) {
        history.push('/');
        return null;
    }

    if (!ipDetails) {
        return <FullPageLoadingComponent />
    }

    const vatCharged = billing.amount * vatRate / 100;
    const totalCharged = billing.amount + vatCharged;

    const requiredKeys: (keyof BillingInformation)[] = ['issuedTo', 'firstName', 'lastName', 'companyName', 'countryId', 'city', 'ZIP', 'street', 'houseNumber'] as (keyof BillingInformation)[];
    const required: { [key in keyof BillingInformation]?: boolean } =
        requiredKeys
            .reduce((result, key) => {
                result[key] = true;
                return result;
            }, {} as any);

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setCompanyName('');
        setCountryId('');
        setState('');
        setCity('');
        setZIP('');
        setStreet('');
        setHouseNumber('');
        setVatNumber('');
        setVatRate(0);
    }

    const countriesOptions = countries
        ? countries
            .filter((c) => c.id)
            .map((c) => ({
                name: c.name || "",
                value: c.id || "",
            }))
        : [];

    const isValid = () => {
        const valid =
            firstName !== '' &&
            lastName !== '' &&
            countryId !== '' &&
            city !== '' &&
            ZIP !== '' &&
            street !== '' &&
            houseNumber !== '';

        if (issuedTo === 'company') {
            return valid && companyName !== '' && vatValid;
        }

        return valid;
    }

    if (isLoading || isVatLoading || !countries) {
        return <FullPageLoadingComponent />
    }

    const openIpCountry = () => {
        document.body.classList.add('register-popup');
        setShowIpCountryPopup(true);
    }

    const closeIpCountry = () => {
        document.body.classList.remove('register-popup');
        setState('');
        setCity('');
        setZIP('');
        setStreet('');
        setHouseNumber('');
        setShowIpCountryPopup(false);
    }

    const ipCountryPopUp =
        <div className="register-popup-dialog">
            <div className="dialog__dialog">
                <div className="stack stack-xs">
                    <div >
                        <div className="ip-popup">
                            Your selected country does not match your IP address.<br />
                            Select your country of TAX residence
                            <SelectWithLabelComponent
                                onChange={value => {
                                    setIpCountry(value);
                                }}
                                label=""
                                value={ipCountry}
                                placeholder="Select a country"
                                options={countriesOptions}
                            />
                        </div>
                    </div>
                    <div className="stack stack-m ai-c">
                        <button className="button" onClick={(evt) => {
                            evt.preventDefault();
                            evt.stopPropagation();
                            setCountryId(ipCountry);
                            onCountrySelect(ipCountry);
                            closeIpCountry();
                        }}>
                            <span className="button__inner">
                                <span>Select</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div >


    const onCountrySelect = async (value: any) => {
        setCountryId(value);
        const countryId = Number(value);
        const country = countries.find(c => c.id === countryId);
        if (country) {
            if (ipDetails.countryCode && country.isoCode !== ipDetails.countryCode && ipCountry === '') {
                openIpCountry();
            }
            if (issuedTo === 'me' && (country.isEuMember || country.code === '36')) {
                setVatRate(country.vat);
                setVatValid(true);
                setVatNumber('');
            } else if (issuedTo === 'me' && !country.isEuMember) {
                setVatRate(0);
                setVatValid(true);
                setVatNumber('');
            } else if (issuedTo === 'company' && country.code === '36') {
                setVatRate(country.vat);
                setVatValid(true);
            } else if (issuedTo === 'company' && country.isEuMember) {
                await vatValidate(country.id);
            } else if (issuedTo === 'company' && !country.isEuMember) {
                setVatRate(0);
                setVatValid(true);
                setVatNumber('');
            }
        }
    }

    const setZeroVatIfNotHungary = (country: CountryModel) => {
        if (country.code !== '36') {
            setVatRate(0);
        }
    }

    const vatValidate = async (newCountryId?: number) => {
        if (issuedTo === 'company' && (countryId || newCountryId)) {
            const country = countries.find(c => c.id === Number(newCountryId ?? countryId));
            if (country && !country.isEuMember) {
                setVatRate(0);
                setVatValid(true);
                setVatValidationError('');
            } else if (country && country.isEuMember) {
                if (vatNumber === '') {
                    setVatRate(country.vat);
                    setVatValid(true);
                    setVatValidationError('');
                } else {
                    const vatCheckResponse = await vatCheck(vatNumber);
                    if (vatCheckResponse.status === 200) {
                        if (vatCheckResponse.data.isValid &&
                            vatCheckResponse.data.name?.toLowerCase().replace(/&amp;/g, '&').substr(0, 5) === companyName?.toLowerCase().substr(0, 5)) {
                            setZeroVatIfNotHungary(country);
                            setVatValid(true);
                            setVatValidationError('');
                        } else if (vatCheckResponse.data.isValid && companyName !== '') {
                            setVatValidationError('Invalid Company Name to valid VAT number. Correct it or leave the box blank and applicable VAT will be charged.');
                            setVatValid(false);
                        } else if (!vatCheckResponse.data.isValid) {
                            setVatValidationError('Invalid VAT number. Correct it or leave the box blank and applicable VAT will be charged.');
                            setVatValid(false);
                        }
                    } else if (vatCheckResponse.status === 400) {
                        setVatValidationError('Invalid VAT number. Correct it or leave the box blank and applicable VAT will be charged.');
                        setVatValid(false);
                    } else if (vatCheckResponse.status === 503 || vatCheckResponse.status === 500) {
                        setVatValidationError('VIES VAT number validation service not available');
                        setZeroVatIfNotHungary(country);
                        setVatValid(true);
                    }
                }
            }
        }
    }

    const createBillingModel = () => {
        const country = countries.find(c => c.id === Number(countryId));
        return {
            ...billing,
            totalAmount: Number(totalCharged?.toFixed(2)),
            vatRate: vatRate,
            vatCharged: vatCharged,
            vatNumber: vatNumber,
            firstName: firstName,
            lastName: lastName,
            companyName: companyName,
            city: city,
            zip: ZIP,
            countryCode: country?.isoCode || 'US',
            email: user?.email,
            street: street,
            state: state,
            houseNumber: houseNumber,
            issuedType: issuedTo === 'company' ? BillingIssuedType.Company : BillingIssuedType.Personal,
        } as BillingModel;
    };

    const form = (
        <div className="stack stack-m">
            <div className="grid">
                <div className="gi-2 m-gi-0"></div>
            </div>
            <div className="grid">
                <div className="gi-2 m-gi-0"></div>
                <div className="gi-4 t-gi-12 m-gi-12">
                    <TextInputWithLabelComponent
                        label="First Name"
                        touched={continued}
                        value={firstName}
                        onChange={(value) => setFirstName(value)}
                        required={required.firstName}
                        enabledRegex={"^[A-Za-z.-]+$"}
                    />
                </div>
                <div className="gi-4 t-gi-12  m-gi-12">
                    <TextInputWithLabelComponent
                        label="Last Name"
                        touched={continued}
                        value={lastName}
                        required={required.lastName}
                        onChange={(value) => setLastName(value)}
                        enabledRegex={"^[A-Za-z.-]+$"}
                    />
                </div>
            </div>
            {issuedTo === "company" ? (
                <>
                    <div className="grid">
                        <div className="gi-2 m-gi-0"></div>
                        <div className="gi-8">
                            <TextInputWithLabelComponent
                                label="Company Name"
                                touched={continued}
                                value={companyName}
                                required={required.companyName}
                                onChange={(value) => setCompanyName(value)}
                                onBlur={() => vatValidate()}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="gi-2 m-gi-0"></div>
                        <div className="gi-8">
                            <TextInputWithLabelComponent
                                label="European Union Text (VAT) Identification Number (if applicable)"
                                touched={continued}
                                value={vatNumber}
                                invalid={!vatValid}
                                customErrorMessage={vatValidationError}
                                required={required.VATNumber}
                                onChange={(value) => setVatNumber(value)}
                                onBlur={() => vatValidate()}
                            />
                            {vatValid && vatValidationError !== '' ?
                                <div className="error">{vatValidationError}</div> : null
                            }
                            <span>(It is generally issued to companies registered in an EU country)</span>
                        </div>
                    </div>
                </>
            ) : null}
            <div className="grid">
                <div className="gi-2 m-gi-0"></div>
                <div className="gi-4">
                    <SelectWithLabelComponent
                        label="Country"
                        touched={continued}
                        onChange={value => onCountrySelect(value)}
                        value={countryId}
                        placeholder="Select a country"
                        required={required.countryId}
                        options={countriesOptions}
                    />
                </div>
                <div className="gi-4">
                    <TextInputWithLabelComponent
                        label="State or Province (if applicable)"
                        touched={continued}
                        value={state}
                        required={required.state}
                        onChange={(value) => setState(value)}
                        enabledRegex={"^[A-Za-z-]+$"}
                    />
                </div>
            </div>
            <div className="grid">
                <div className="gi-2 m-gi-0"></div>
                <div className="gi-4">
                    <TextInputWithLabelComponent
                        label="City"
                        touched={continued}
                        value={city}
                        required={required.city}
                        onChange={(value) => setCity(value)}
                        enabledRegex={"^[A-Za-z-]+$"}
                    />
                </div>
                <div className="gi-4">
                    <TextInputWithLabelComponent
                        label="Postal (ZIP) Code"
                        touched={continued}
                        value={ZIP}
                        required={required.ZIP}
                        onChange={(value) => setZIP(value)}
                    />
                </div>
            </div>
            <div className="grid">
                <div className="gi-2 m-gi-0"></div>
                <div className="gi-4">
                    <TextInputWithLabelComponent
                        label="Street"
                        touched={continued}
                        value={street}
                        required={required.street}
                        onChange={(value) => setStreet(value)}
                    />
                </div>
                <div className="gi-4">
                    <TextInputWithLabelComponent
                        label="House Number"
                        touched={continued}
                        value={houseNumber}
                        required={required.houseNumber}
                        onChange={(value) => setHouseNumber(value)}
                    />
                </div>
            </div>
            <div className="grid">
                <div className="gi-2 m-gi-0"></div>
                <div className="gi-8">
                    <hr />
                </div>
            </div>
            <div className="stack stack-m">
                <div className="stack stack-m bold billing-summary">
                    <div className="grid">
                        <div className="gi-2 m-gi-0"></div>
                        <div className="gi-8">
                            <p>Number of Credits Purchased: {billing.creditValue}</p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="gi-2 m-gi-0"></div>
                        <div className="gi-3 m-gi-12">
                            <p>
                                <span>Net Amount:</span>
                                <span className="fill"></span>
                                <span>EUR {billing.amount.toFixed(2)}</span>
                            </p>
                            <p>
                                <span>VAT Rate:</span>
                                <span className="fill"></span>
                                <span>{vatRate} %</span>
                            </p>
                            <p>
                                <span>VAT Charged:</span>
                                <span className="fill"></span>
                                <span>EUR {vatCharged.toFixed(2)}</span>
                            </p>
                            <p>
                                <span>Total Amount Charged:</span>
                                <span className="fill"></span>
                                <span>EUR {totalCharged.toFixed(2)}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="gi-2 m-gi-0"></div>
                <div className="gi-8">
                    <button className="button"
                        onClick={(evt) => {
                            if (isValid()) {
                                const billingModel = createBillingModel();
                                continueToCheckout(billingModel);
                            } else {
                                setContinued(true);
                            }
                        }}
                    >
                        <span className="button__inner">
                            <span>Continue to payment</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="container">
            <div className="stack stack-m">
                <div className="grid">
                    <div className="gi-12">
                        <div className="stack stack-xs jc-sb ai-c m-fd-cr">
                            <p className="title title-l">Billing Information</p>
                            <p>If you are a private individual resident in the European Union, you will be charged VAT applicable in your jurisdiction.</p>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="gi-12">
                        <div className="h-stack jc-c">
                            <label className="switch switch--checkbox switch--label-left">
                                <input
                                    className="visually-hidden"
                                    type="checkbox"
                                    id="cb-2"
                                    name="cb-2"
                                    checked={issuedTo === "me"}
                                    onChange={evt => {
                                        setIssuedTo("me");
                                        resetForm();
                                    }}
                                />
                                <span className="switch__switch">
                                    <span className="dot"></span>
                                </span>
                                <span className="switch__label label">Invoice issued to me</span>
                            </label>
                            <label className="switch switch--checkbox switch--label-left">
                                <input
                                    className="visually-hidden"
                                    type="checkbox"
                                    id="cb-2"
                                    name="cb-2"
                                    checked={issuedTo === "company"}
                                    onChange={evt => {
                                        setIssuedTo("company");
                                        resetForm();
                                    }}
                                />
                                <span className="switch__switch">
                                    <span className="dot"></span>
                                </span>
                                <span className="switch__label label">to my company</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            {
                issuedTo ? form : ""
            }
            {showIpCountryPopup ? ipCountryPopUp : null}
        </div>
    );
};
