import { CreditMap, TouchedFinancialInputsModel, TouchedInputsModel } from './company-upload.models';
import { CompanyUploadModel } from './CompanyUpload.model';

export enum CompanyUploadActionTypes {
    COMPANY_UPLOAD_MODEL_INITIALIZE = 'companyUpload/model/initialize',
    COMPANY_UPLOAD_MODEL_UPDATE = 'companyUpload/model/update',
    COMPANY_UPLOAD_CREDITS_LOADING = 'companyUpload/credits/loading',
    COMPANY_UPLOAD_CREDITS_SUCCESS =  'companyUpload/credits/success',
    COMPANY_UPLOADING = 'companyUpload/uploading',
    COMPANY_UPLOADED_SUCCESS = 'companyUpload/uploaded/success',
    COMPANY_UPLOADED_FAILED = 'companyUpload/uploaded/failed',
    COMPANY_CURRENCIES_LOADING = 'companyUpload/currencies/loading',
    COMPANY_CURRENCIES_SUCCESS =  'companyUpload/currencies/success',
    COMPANY_UPLOAD_TOUCH_INPUTS = 'companyUpload/touch/inputs',
    COMPANY_UPLOAD_TOUCH_FINANCIALS_INPUTS = 'companyUpload/touch/inputs/financials',
}

export const companyUploadCreditsLoadingAction = () => ({
    type: CompanyUploadActionTypes.COMPANY_UPLOAD_CREDITS_LOADING,
    payload: undefined
});

export const companyUploadCreditsLoadedAction = (companyCredits: CreditMap) => ({
    type: CompanyUploadActionTypes.COMPANY_UPLOAD_CREDITS_SUCCESS,
    payload: companyCredits
});

export const companyUploadFailedAction = () => ({
    type: CompanyUploadActionTypes.COMPANY_UPLOADED_FAILED,
    payload: undefined
});

export const companyUploadedSuccessAction = () => ({
    type: CompanyUploadActionTypes.COMPANY_UPLOADED_SUCCESS,
    payload: undefined
});

export const companyUploadingAction = () => ({
    type: CompanyUploadActionTypes.COMPANY_UPLOADING,
    payload: undefined
});

export const companyUploadCurrenciesLoadingAction = () => ({
    type: CompanyUploadActionTypes.COMPANY_CURRENCIES_LOADING,
    payload: undefined
});

export const companyUploadCurrenciesLoadedAction = (currencies: string[]) => ({
    type: CompanyUploadActionTypes.COMPANY_CURRENCIES_SUCCESS,
    payload: currencies
});

export const companyUploadTouchInputsAction = (touchedInputs: TouchedInputsModel) => ({
    type: CompanyUploadActionTypes.COMPANY_UPLOAD_TOUCH_INPUTS,
    payload: touchedInputs
});

export const companyUploadTouchFinancialInputsAction = (touchedInputs: TouchedFinancialInputsModel[]) => ({
    type: CompanyUploadActionTypes.COMPANY_UPLOAD_TOUCH_FINANCIALS_INPUTS,
    payload: touchedInputs
});

export const companyUploadModelInitializeAction = (model: CompanyUploadModel) => ({
    type: CompanyUploadActionTypes.COMPANY_UPLOAD_MODEL_INITIALIZE,
    payload: model
});
export const companyUploadModelUpdateAction = (model: CompanyUploadModel) => ({
    type: CompanyUploadActionTypes.COMPANY_UPLOAD_MODEL_UPDATE,
    payload: model
});

export type CompanyUploadActions =
    ReturnType<typeof companyUploadModelInitializeAction> |
    ReturnType<typeof companyUploadModelUpdateAction> |
    ReturnType<typeof companyUploadTouchInputsAction> |
    ReturnType<typeof companyUploadTouchFinancialInputsAction> |
    ReturnType<typeof companyUploadCreditsLoadingAction> |
    ReturnType<typeof companyUploadingAction> |
    ReturnType<typeof companyUploadFailedAction> |
    ReturnType<typeof companyUploadCreditsLoadedAction>|
    ReturnType<typeof companyUploadedSuccessAction> |
    ReturnType<typeof companyUploadCurrenciesLoadingAction>|
    ReturnType<typeof companyUploadCurrenciesLoadedAction>;