import React from "react";
import { useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";
import { FullPageLoadingComponent } from '../FullpageLoading.component';

export const MarkedForDeletedUserComponent: React.FC = () => {
  const isLoading = useSelector(state => (state as any).authentication.isLoading);
  const user = useSelector(state => (state as any).authentication.user);
  
  if (isLoading) {
    return <FullPageLoadingComponent />
  }

  if (user) {
    return <Redirect to={"/"} />
  }

  return (
    <div className="container">
      <div className="stack stack-m">
        <div className="grid">
          <div className="gi-12">
            <div className="h-stack h-stack-xs jc-sb m-fd-cr">
              <p className="title title-l">User deleted</p>
            </div>
          </div>
        </div>
      </div>
      <div className="stack stack-m">
        <div className="grid">
          <div className="gi-12">
            <div className="h-stack h-stack-xs jc-sb m-fd-cr">
              <p>The user deletion is in progress, please try again later.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};